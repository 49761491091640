import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Grid,Tabs,Tab } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';
import { Check, ArrowLeft, ExitToApp, Ring, TextBoxSearch, CreditCardOutline, PrinterPos, Printer, Gift, Cash100,Pencil } from 'mdi-material-ui';

import XGrid from '../../../components/XGrid';
import IdentitySelector from '../../../components/IdentitySelector';
import XStepper from '../../../components/XStepper';

import CashProducts from './CashProducts';
import CashIR from './CashIR';
import CashTotals from './CashTotals';
import CashPayment from './CashPayment';
import CashDateEditor from './CashDateEditor';
import XNavigation from '../../../components/XNavigation';
import XMessageBox from '../../../components/XMessageBox';
import XSwitch from '../../../components/XSwitch';
import PrintReceipt from './PrintReceipt';
import XOXLoader from 'components/XOXLoader';
import IREdit from 'views/IR/IREdit';
import RepairCausal from '../Repair/RepairCausal';

import moment from 'moment';
import CashDeposit from './CashDeposit';
import CashRegisterSelector from './CashRegisterSelector';
import CashRelationSelector from './CashRelationSelector';
import XPDFViewer from 'components/XPDFViewer';

const styles = theme => ({
    root: {
    },
    panelContainer: {
        width: '100%',
        margin: 0,
        position: 'relative',
    },
    leftPanel: {
        height: 'calc(100vh - 145px)',
    },
    rightPanel: {
        position: 'relative',
        height: 'calc(100vh - 145px)',
        boxShadow: '0px 10px 10px rgba(0,0,0,0.5)',
    },
    printPreview: {
        height: 'calc(100vh - 151px)',
        width: '100%'
    }
});


class CashEditor extends Component {
    constructor(props) {
        super(props);

        this.CashTotals = React.createRef();
        this.Payment = React.createRef();
        this.PrintReceipt = React.createRef();
        this.IREdit = React.createRef();
        this.CashIR = React.createRef();
        this.XMessage = React.createRef();
        this.CashDateEditor = React.createRef();
        this.state = {
            FIDIdentity: undefined,
            showIdentitySelector: false,
            showIdentityEditor: false,
            showSearch: false,
            showPayment: false,
            view: "BOPSTOCK",
            docview: "CASH",
            printReceipt: false,
            data: {},
            isLoading: true,
            printPreview: false,
            conectionManaged: false,
            docIRFilter: 1,
            dueDate: moment(new Date()),
            showAmounts: true,
            tab: 0,
        }
    }
    static contextTypes = {
        router: PropTypes.object
    }
    componentDidMount() {
        if (this.props.match.params.id && parseInt(this.props.match.params.id, 10) > 0) {
            var ID = parseInt(this.props.match.params.id, 10);
            this.loadCashDocument(ID);
        }
        else {
            this.newCashDocument();
        }
        this.props.startConnection();

        
        var pageTitle = this.props.isReturnCash ? "Reso cassa" : "Cassa";
        this.props.setTitle(pageTitle);
    }
    componentWillUnmount() {
        this.props.stopConnection();
    }
    componentDidUpdate(props){
        if(this.props.connection && this.props.connection !== props.connection && !this.state.conectionManaged){
            this.manageConnectionEvents();
        }
    }
    manageConnectionEvents = async () => {
        this.setState({ connectionManaged: true });
        this.props.connection.on("OnClientStart", this.onClientStart)
        this.props.connection.on("OnReceiptOutput", this.onReceiptOutput)
        this.props.connection.on("OnCashRegisterError", this.onCashRegisterError)
    }
    onClientStart = () => {
        console.log("client start");
    }
    onReceiptOutput = (output) => {
        this.setState({ printingReceipt: false, printReceipt: false},() => {
            var { data } = this.state;
            if(!data.ProtocolNumber){
                this.XMessageBox("Stampa scontrino","Procedere con la stampa dello scontrino di cortesia?", this.printGiftReceipt);
                this.loadCashDocument(data.ID);
            }
        });
    }
    onCashRegisterError = (errorCode, errorDescription) => {
        console.error(errorCode,errorDescription);
        this.setState({ printingReceipt: false });
    }
    loadCashDocument = async (ID) => {
        
        var data = await fetch(`/DOC/GetDOC/${ID}`).then(res => res.json());
        this.setState({data, FIDIdentity: data.FIDIdentity, isLoading: false});
    }
    newCashDocument = async () => {
        var url = `/DOC/NewCashDocument`;
        if(this.props.isReturnCash){
            url = `/DOC/NewReturnCashDocument`;
        }
        var data = await fetch(url).then(res => res.json());
        this.setState({data, isLoading: false});
    }

    onChangeIRRequest = () => {
        this.setState({ showIdentitySelector: true, customActions:[],showSearch: false });
    }
    onClearIR = () => {
        this.setState(state => {
            state.FIDIdentity = undefined;
            state.data.FIDIRRelation = undefined;
            state.isValidIR = true;
            state.docIRFilter = 1;
            state.hasDeposits = false;
            return state;
        })
        //this.setState({FIDIdentity: undefined, data: {FIDIRRelation: undefined}, isValidIR: true, docIRFilter: 1, hasDeposits: false});
    }
    onEditIR = () => {
        this.setState({showIdentityEditor: true, showPrivacy: false});
    }
    onEditPrivacyIR = () => {
        this.setState({showIdentityEditor: true, showPrivacy: true});
    }
    onIdentitySelect = (FIDIdentity) => {
        this.setState(state => {
            state.FIDIdentity = FIDIdentity;
            state.data.FIDIRRelation = undefined;
            state.showIdentitySelector = false;
            state.customActions = [];
            state.showSearch = false;
            state.docIRFilter = 0;
            return state;
        });
        //this.setState({FIDIdentity, showIdentitySelector: false, customActions:[], showSearch: false, docIRFilter: 0});
    }
    onSearchShowChange = (show, onClose) => {
        this.setState({ showSearch: show, closeSearch: onClose});
    }
    onProductsChange = () => {
        this.CashTotals.current.loadData();
    
        if(this.state.FIDIdentity && this.CashIR.current && this.CashIR.current.loadData) {
            this.CashIR.current.loadData();
        }
    }
    onExitPage = () => {
        //window.history.push
        
        this.context.router.history.push(`/documents`);
    }
    onPay = () => {
        this.setState({ showPayment: true, paymentStep: 1});
    }
    onPaymentBack = () => {
        if(!this.Payment.current.onBack()){
            this.setState({ showPayment: false, paymentStep: 0});
        }
    }
    onPaymentConfirm = () => {
        this.Payment.current && this.Payment.current.onCofirmPayment();
    }
    changeView = (view) => () => {
        this.setState({view});
    }
    onConfirm = async () => {
        var { data, FIDIdentity,setDueDate } = this.state;

        if(setDueDate) {
            //chiedo la conferma della data
            debugger;
            this.CashDateEditor.current.onSubmit();
            return;
        }
       
        var mainAction = async () => {
            var totalToPay = await fetch(`/DOC/GetCashTotalToPay/${data.ID}`).then(res => res.json());
            var payments = await fetch(`/DOC/GetCashPayments/${data.ID}`).then(res => res.json());
            if(totalToPay > 0){
                var message = `Attenzione! È presento un sospeso di ${totalToPay} €. Vuoi impostare la data di incasso del sospeso?`;
                var buttons = [
                    {
                        label: "Si",
                        action: () => {
                            this.setState({ setDueDate: true },this.XMessage.current.hideDialog);
                        }
                    },
                    {
                        label: "Procedi senza impostare una data",
                        action: () => {
                            this.XMessage.current.hideDialog();
                            this.doSaveCashDocument();
                        }
                    },
                    {
                        label: "Annulla",
                        action: () => {
                            this.XMessage.current.hideDialog();
                        }
                    },
                ];
                if(payments.length === 0){
                    message = `Attenzione! Non è stato gestito nessun pagamento. Confermare senza gestire i pagamenti?`;
                    buttons = [
                        {
                            label: "Si",
                            action: () => {
                                this.XMessage.current.hideDialog();
                                this.doSaveCashDocument();
                            }
                        },
                        {
                            label: "No",
                            action: () => {
                                this.setState({ showPayment: true, paymentStep: 1,printPreview: false },this.XMessage.current.hideDialog);
                            }
                        },
                    ];
                }
                
                this.XMessage.current.showMessage('Conferma documento',message,buttons);
            }
            else {
                this.doSaveCashDocument();
            }
        } 


        var totRows = await fetch(`/DOC/GetCashRowsCount/${data.ID}?FIDCausal=${data.FIDCausal}`).then(res => res.json());
        if(totRows === 0){
            this.XMessage.current.showMessageOk('Conferma documento','Non è presente nessun prodotto nel documento');
            return;
        }
        
        if(!this.state.FIDIdentity || this.state.isValidIR){
            mainAction();
        }
        else {
            this.XMessage.current.showMessageYesNo("Conferma documento",'Attenzione! Trattamento dei dati privacy mancante. Continuare con i dati mancanti?',mainAction);
            return;
        }

        //});
    }

    doSaveCashDocument = async () => {
        var { data, FIDIdentity } = this.state;
        this.setState({isLoading: true});
        data.FIDIdentity = FIDIdentity;
        data.FIDDestination = FIDIdentity;
        var doc = await fetch(`/DOC/SaveCashDocument`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(res => res.json());
        //this.setState({ data: doc,isLoading: false, showPayment: false, paymentStep: 0,showIdentitySelector: false, showSearch: false,printPreview: true }, () => {
        await this.loadCashDocument(doc.ID);
        this.doConfirmCash();
    }
    confirmCash = () => {
    }
    doConfirmCash = async () => {
        var { data, FIDIdentity, dueDate } = this.state;
        this.setState({isLoading: true});
        data.FIDIdentity = FIDIdentity;
        data.FIDDestination = FIDIdentity;
        var doc = await fetch(`/DOC/ConfirmCashDocument?DueDate=${dueDate.format('YYYY-MM-DD')}`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(res => res.json());
        if(this.props.isReturnCash){
            window.history.pushState('','',`/documents/render/cash/${data.ID}`);
        }
        else {
            window.history.pushState('','',`/documents/issue/cash/${data.ID}`);
        }
        this.setState({data: doc, isLoading: false, showPayment: false, paymentStep: 0,showIdentitySelector: false, showSearch: false, printPreview: false },() => {
            this.loadCashDocument(doc.ID);
            this.manageFirstReceiptPrint();
        });
    }
    manageFirstReceiptPrint = () => {
        var { data } = this.state;
        if(!data.ProtocolNumber) {
            this.XMessage.current.showMessageYesNo("Stampa scontrino","Procedere con la stampa dello scontrino fiscale?",() => {
                this.setState({printReceipt: true},this.onPrintFirstReceipt);
            });
        }
    }
    cancelPrint = () => {
        this.setState({ printPreview: false });
    }
    onPrint = () => {
        this.setState({ printPreview: true });
    }
    onPrintReceipt = () => {
        this.setState({ printReceipt: true });
    }
    onCancelPrintReceipt = () => {
        this.setState({ printReceipt: false });
    }
    onPrintFirstReceipt = () => {

        this.setState({printingReceipt: true});
        var {FIDDocument,FIDCashRegister,GiftReceipt} = this.PrintReceipt.current.getInfo();
        this.props.printReceipt(FIDDocument,FIDCashRegister);
    }
    onReprintReceipt = () => {
        
        this.setState({printingReceipt: true});
        var {FIDDocument,FIDCashRegister,GiftReceipt} = this.PrintReceipt.current.getInfo();
        this.props.reprintReceipt(FIDDocument,FIDCashRegister);
    }
    onPrintGiftReceipt = () => {
        
        this.setState({printingReceipt: true});
        var {FIDDocument,FIDCashRegister,GiftReceipt} = this.PrintReceipt.current.getInfo();
        this.props.printGiftReceipt(FIDDocument,FIDCashRegister);
    }
    onDOCIRFilterChange = (step) => {
        this.setState({docIRFilter: step});
    }
    onEditIRExit = () => {
        this.setState({showIdentityEditor: false});
    }
    onSaveIR = () => {
        this.setState({showIdentityEditor: false}, async () => {
            if(this.CashIR.current && this.CashIR.current.loadData) {
                await this.CashIR.current.loadData();
            }
        });
    }
    onPaymentStepChange = (step) => {
        this.setState({ paymentStep: step});
    }
    onProductsonRequestActions = (actions) => {
        this.setState({ customActions: actions});
    }
    onDateEditorSubmit = (key, value) => {
        this.setState({ dueDate: value, setDueDate: false },this.doConfirmCash);
    }
    onIRLoaded = (data) => {
        this.setState({ isValidIR: !data.Incomplete, hasDeposits: data.hasDeposits });
    }
    onCausalChange = (id) => {
        this.setState(state => {
            state.data.FIDCausal = id;
            state.customActions=[];
            state.showSearch= false
            return state;
        });
    }
    onCashRegisterChange = (id) => {
        this.setState(state => {
            state.data.FIDCashRegister = id;
            state.customActions=[];
            state.showSearch= false
            return state;
        });
    }
    onRelationChange = (id) => {
        this.setState(state => {
            state.data.FIDIRRelation = id;
            state.customActions=[];
            state.showSearch= false
            return state;
        },this.CashIR.current.loadData);
    }
    
    onUpdateShowAmounts = (id) => (event,value) => {
        this.setState({showAmounts: value});
    }
    manageExistingDeposits = () => {
        var { hasDeposits,tab } = this.state;
        if(tab === 2){
            if(hasDeposits){
                this.XMessage.current.showMessageYesNo("Acconti","Esistono degli acconti per l'anagrafica selezionata. Passare alla gestione degli acconti?",() => {
                    this.setState({tab: 1});
                });
            }
        }
    }
    
    handleChangeTab = (_, value) => {
        this.setState({ tab: value }, this.manageExistingDeposits);
    }
    changeDocView = (docview) => () => {
        this.setState({docview});
    }
    modifyDocument = () => {
        var { data } = this.state;
        fetch(`/DOC/ModifyDocument/${data.ID}`).then(res => {
            this.loadCashDocument(data.ID);
        });
    }
    
    renderPrintPreview = (exitAction,printReceiptAction) => {
        var { classes, width } = this.props;
        var { showAmounts, data, docview} = this.state;
        var content = undefined;
        var key = `ID=${data.ID}&showAmounts=${showAmounts}`;
        switch(docview){
            case "CASH":
                var url = `/DOC/PrintDocument/?ID=${data.ID}&showAmounts=${showAmounts}`;
                //content = <iframe id="PrintPreview" className={classes.printPreview} key={key} src={`https://docs.google.com/gview?url=${window.location.origin}${url}&embedded=true`}></iframe> 
				content = <XPDFViewer key={key} url={url} width="100%" height={document.body.offsetHeight - 151} />;
                break;
            case "DOCS":
                var gridName = "tblDOCProcessed";
                var dataRoute = `/DOC/GetProcessedDocsExt/${data.ID}?grid=${gridName}`;

                content = <XGrid
                    key={gridName}
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    dataRouteDelete={'/DOC/DeleteDocument/'}
                    onDoubleClick={this.handleGridDOCDoubleClick}
                    onEditButton={this.handleGridDOCDoubleClick}
                    showColumnsSelector={true}
                    showFilterActivator={true}
                />;
                break;
            // case "STOCK":
            //     var urlData = "/BOP/GetBopsStock?grid=tblBOPStock";
            //     urlData += `&IsRepair=true&FIDDocument=${data.ID}`;

            //     content = <XGrid 
            //         key={docview}
            //         //label={label}
            //         innerRef={this.grdStock}
            //         usePrimaryColor={false}
            //         dataRoute={urlData}
            //         dataRouteColumns="/Base/GetColumns?grid=tblBOPStock"
            //         dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblBOPStock"
            //         dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblBOPStock"              
            //         onDoubleClick={this.handleGridStockDoubleClick}
            //         onEditButton={this.handleGridStockDoubleClick}
            //         //customContent={customContent}
            //         //actions={actions}
            //         //showColumnsSelector={showColumnsSelector}
            //         //showFilterActivator={showFilterActivator}
            //         cardWidth={4}
            //         cardHeight={4.5}
            //         showChangeView={true}
            //         width={this.props.width}
            //         showLoader
            //     />;
            //     break;
        }
        
        var actionConfirmOnPrint = {
            group: 'actions',
            name: 'Conferma',
            action: this.confirmCash,
            icon: <Check />
        };
        var actionCancelPrint = {
            group: 'actions',
            name: 'Modifica',
            action: this.modifyDocument,
            icon: <Pencil />
        };
        
        var direction = "right";
        if (["xs"].includes(width)) {
            direction = "down";
        }
        var actions=[];
        //!confirmed && actions.push(actionConfirmOnPrint);
        actions.push(exitAction);
        actions.push(actionCancelPrint);
        actions.push(printReceiptAction);
        var navActions= [];
        navActions.push({ name: getTranslation(4,"DOC_CASH",this.props.translations,"Cassa"), icon: <TextBoxSearch />, action: this.changeDocView("CASH"), selected: docview === "CASH" });
        navActions.push({ name: getTranslation(4,"DOC_DOCUMENTS",this.props.translations,"Documenti collegati"), icon: <TextBoxSearch />, action: this.changeDocView("DOCS"), selected: docview === "DOCS" });
        //navActions.push({ name: getTranslation(4,"DOC_STOCK",this.props.translations,"Articoli"), icon: <TextBoxSearch />, action: this.changeDocView("STOCK"), selected: docview === "STOCK" });

        var label = (
            <span>
                <span style={{marginTop:8, display: "inline-block"}}><XNavigation actions={navActions} direction={direction} showLabel /></span>
                {docview === "REPAIR" && <span style={{ display: "inline-block", position: "relative", bottom: 8}}><XSwitch label="Mostra importi" id="ShowAmounts"  color="default" value={showAmounts} onChange={this.onUpdateShowAmounts} /></span>}
            </span>
         );

         return { content, label, actions };
    }
    render() {
        var { classes, width } = this.props;
        var { data, FIDIdentity, showIdentitySelector,showSearch,closeSearch, view,showPayment, printReceipt, printingReceipt, 
            isLoading, printPreview,docIRFilter, showIdentityEditor, paymentStep,productsStep, customActions, setDueDate, showPrivacy, showAmounts } = this.state;
        if(isLoading){
            return <XOXLoader />;
        }
        if(data.FIDCausal === 38) {
            view = "DOCS";
        }
        data.FIDIdentity = FIDIdentity;
        showPayment = this.state.tab === 2;
        var confirmed = data.FIDStatus === 3;
        if(confirmed){
            printPreview = true;
        }
        
        var IDFilter = [16,43];
        if(data.FIDCycle === 3)
        {
            IDFilter = [38];
        }

        var showDepositPayment = false;
        if(data.FIDCausal === 43){
            showDepositPayment = true;
        }

        var content = undefined;

        var confirmAction = {
            group: 'actions',
            name: 'Conferma',
            icon: <Check />,
            action: this.onConfirm
        };
        var exitAction = {
            group: 'actions',
            name: 'Esci',
            icon: <ExitToApp />,
            action: this.onExitPage
        };
        // var payAction = {
        //     group: 'actions',
        //     name: 'Pagamento',
        //     icon: <CreditCardOutline />,
        //     action: this.onPay
        // };
        var printAction = {
            group: 'actions',
            name: 'Stampa',
            icon: <Printer />,
            action: this.onPrint
        };
        var printReceiptAction = {
            group: 'actions',
            name: 'Scontrino',
            icon: <PrinterPos />,
            action: this.onPrintReceipt
        }

        var actions = [];
        actions.push(exitAction);
        !confirmed && actions.push(confirmAction);
        confirmed && actions.push(printReceiptAction);
        confirmed && actions.push(printAction);
        // !confirmed && actions.push(payAction);

        if(customActions && customActions.length > 0){
            actions = customActions;
        }
        var label = undefined;
        if(setDueDate){
            
            content = <div className={classes.editor}>
                <CashDateEditor innerRef={this.CashDateEditor} fieldLabel={"Data scadenza"} id={"dueDate"} value={this.state.dueDate} onSubmit={this.onDateEditorSubmit}/>
            </div>;
        }
        else if(showSearch) {
            actions = [
            {
                group: 'actions',
                name: 'Indietro',
                icon: <ArrowLeft />,
                action: closeSearch
            }];

            
            var navActions = [];
            // navActions.push({ name: getTranslation(4,"DOC_CATALOG",this.props.translations,"Catalogo"), icon: <Ring />, action: this.changeView("BOPS"), selected: view === "BOPS" });
            data.FIDCausal !== 38 && navActions.push({ name: getTranslation(4,"DOC_STOCK",this.props.translations,"Magazzino"), icon: <Ring />, action: this.changeView("BOPSTOCK"), selected: view === "BOPSTOCK" });
            navActions.push({ name: getTranslation(4,"DOC_DOCUMENTS",this.props.translations,"Documenti"), icon: <TextBoxSearch />, action: this.changeView("DOCS"), selected: view === "DOCS" });
            data.FIDCausal !== 38 && navActions.push({ name: getTranslation(4,"DOC_DEPOSIT",this.props.translations,"Acconti"), icon: <Cash100 />, action: this.changeView("DEPOSIT"), selected: view === "DEPOSIT" });

            var direction = "right";
            if (["xs"].includes(width)) {
                direction = "down";
            }
            var label = <span><XNavigation actions={navActions} direction={direction} showLabel={true} /></span>;


            if(data.FIDCausal === 38) {
                var style= {
                    display: 'inline-block',
                    verticalAlign: 'top'
                };
                var fieldShowAll = <XStepper label="Anagrafica" step={docIRFilter} steps={[{label:"Assegnata"},{label:"Non assegnata"},{label: "Tutti"}]} onStepClick={this.onDOCIRFilterChange} />;
                label = <Fragment><span style={style}>{label}</span><span style={style}>{fieldShowAll}</span></Fragment>
            }
            
        }
        else if(showPayment){
            var actionPaymentBack = 
            {
                group: 'actions',
                name: 'Indietro',
                icon: <ArrowLeft />,
                action: this.onPaymentBack
            };
            var actionPaymentConfirm = 
            {
                group: 'actions',
                name: 'Conferma pagamento',
                icon: <Check />,
                action: this.onPaymentConfirm
            };
            if(paymentStep > 1){
                actions = [];
                if(paymentStep === 3){
                    actions.push(actionPaymentConfirm);
                }
                if(paymentStep <= 1){
                    actions.push(confirmAction);
                }
                if(paymentStep > 1){ 
                    actions.push(actionPaymentBack);
                }
            }
        }
        else if(printReceipt){
            
        }
        else if(printPreview) {
            //content = <embed src={`/DOC/PrintDocument/?ID=${data.ID}`} type="application/pdf" width="100%" height={} />;
            
            // var key = `ID=${data.ID}&showAmounts=${showAmounts}`;
            // content = <iframe id="PrintPreview" className={classes.printPreview} key={key} src={`/DOC/PrintDocument/?ID=${data.ID}&showAmounts=${showAmounts}`}></iframe> 
            // var actionConfirmOnPrint = {
            //     group: 'actions',
            //     name: 'Conferma',
            //     action: this.confirmCash,
            //     icon: <Check />
            // };
            // var actionCancelPrint = {
            //     group: 'actions',
            //     name: 'Indietro',
            //     action: this.cancelPrint,
            //     icon: <ArrowLeft />
            // };
            // actions=[];
            // !confirmed && actions.push(actionConfirmOnPrint);
            // actions.push(actionCancelPrint);
            
            // label=<Grid item xs={12} container>
            //     <Grid item xs={12} container direction="row" spacing={2}>
            //         <Grid item xs={12} md={3}><XSwitch label="Mostra importi" id="ShowAmounts" value={showAmounts} onChange={this.onUpdateShowAmounts} /></Grid>
            //     </Grid>
            // </Grid>;
        }
        if(printReceipt){
            content= <PrintReceipt innerRef={this.PrintReceipt} FIDLicensee={data.FIDOwner} FIDDestination={data.FIDOwnerDestination} FIDDocument={data.ID}  printing={printingReceipt}/>;
            actions = [];
            var actionPrintReceipt = {
                group: 'actions',
                name: 'Stampa scontrino',
                action: this.onPrintFirstReceipt,
                icon: <PrinterPos />
            };
            var actionReprintReceipt = {
                group: 'actions',
                name: 'Ristampa scontrino',
                action: this.onReprintReceipt,
                icon: <PrinterPos />
            };
            var actionGiftReceipt = {
                group: 'actions',
                name: 'Scontrino di cortesia',
                action: this.onPrintGiftReceipt,
                icon: <Gift />
            };
            var actionCancelPrintReceipt = {
                group: 'actions',
                name: 'Indietro',
                action: this.onCancelPrintReceipt,
                icon: <ArrowLeft />
            };
            if(!printingReceipt){
                data.ProtocolNumber && actions.push(actionGiftReceipt);
                !data.ProtocolNumber && actions.push(actionPrintReceipt);
                data.ProtocolNumber && actions.push(actionReprintReceipt);
                actions.push(actionCancelPrintReceipt);
            }
        }
        else if(printPreview) {
            var printPreview = this.renderPrintPreview(exitAction,printReceiptAction);
            content = printPreview.content;
            label = printPreview.label;
            actions = printPreview.actions;
        }
        else if(setDueDate){
            content = <div className={classes.editor}>
                <CashDateEditor innerRef={this.CashDateEditor} fieldLabel={"Data scadenza"} id={"dueDate"} value={this.state.dueDate} onSubmit={this.onDateEditorSubmit}/>
            </div>;
        }
        else {
            label = (
                <Tabs
                    value={this.state.tab}
                    onChange={this.handleChangeTab}
                    variant="scrollable"
                    scrollButtons="on"
                    className={classes.tab}
                >
                    <Tab
                        key={"General"}
                        label={"Generale"}
                    />
                    <Tab
                        key={"Deposits"}
                        label={"Acconti"}
                    />
                    <Tab
                        key={"Payment"}
                        label={"Pagamento"}
                    />
                </Tabs>
            );
            content = <Grid container spacing={2} className={classes.panelContainer}>
                <Grid xs={12} md={9} item className={classes.leftPanel} id="leftPanel">
                    {!showPayment && !showDepositPayment && <CashProducts onRequestActions={this.onProductsonRequestActions} onSearchShowChange={this.onSearchShowChange} onProductsChange={this.onProductsChange} searchView={view} doc={data} confirmed={confirmed} IRFilter={docIRFilter} showDeposits={this.state.tab === 1} />}
                    {!showPayment && showDepositPayment && <CashDeposit confirmed={confirmed} data={data} onRequestActions={this.onProductsonRequestActions} onUpdate={this.onProductsChange} />}
                    {showPayment && <CashPayment innerRef={this.Payment} doc={data} onStepChange={this.onPaymentStepChange}/>}
                </Grid>
                <Grid xs={12} md={3} item className={classes.rightPanel}>
                    <CashRegisterSelector confirmed={confirmed} FIDLicensee={data.FIDOwner} FIDDestination={data.FIDOwnerDestination} FIDCashRegister={data.FIDCashRegister} onCashRegisterChange={this.onCashRegisterChange} />
                    <RepairCausal confirmed={confirmed} CausalsFilter={IDFilter} FIDDocumentType={data.FIDDocumentType} FIDDocumentSubtype={data.FIDDocumentSubtype} FIDCycle={data.FIDCycle} FIDCausal={data.FIDCausal} onCausalChange={this.onCausalChange} />
                    <CashIR innerRef={this.CashIR} doc={data} FIDIR={FIDIdentity} onChangeRequest={this.onChangeIRRequest} onClearIR={this.onClearIR} onEditIR={this.onEditIR} onEditPrivacyIR={this.onEditPrivacyIR} confirmed={confirmed} onDataLoaded={this.onIRLoaded} />
                    {FIDIdentity && <CashRelationSelector confirmed={confirmed} doc={data} FIDIRRelation={data.FIDIRRelation} onRelationChange={this.onRelationChange}/> }
                    <CashTotals confirmed={confirmed} innerRef={this.CashTotals} doc={data}  confirmed={confirmed} />
                </Grid>
            </Grid>;
        }


        if(showIdentitySelector){
            content = <IdentitySelector onIdentitySelected={this.onIdentitySelect} onCancel={() => { this.setState({ showIdentitySelector: false }) }} showIncompleteSelector={true} />;
            actions= undefined;
        }
        else if(showIdentityEditor){
            content = <IREdit id={FIDIdentity} innerRef={this.IREdit} topMargin="0px" headerColor="info" noRedirect={true} showExit={true} showSave={true} showDelete={false} showNew={false} onExit={this.onEditIRExit} onSave={this.onSaveIR} showPrivacy={showPrivacy} />;
            
            actions=[];
        }

        return <Fragment>
                <XGrid
                label={label}
                actions={actions}
                usePrimaryColor={true}
                customContent={content}
            />
            <XMessageBox innerRef={this.XMessage} />
        </Fragment>
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(CashEditor);