import React from 'react';
import { FormControl, MenuItem, Typography, Select } from '@material-ui/core';
import LocalAudioLevelIndicator from '../LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import { makeStyles } from '@material-ui/core/styles';
import { useAudioInputDevices } from '../deviceHooks/deviceHooks';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import DeviceInput from 'views/Videochat/DeviceInput';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
});

interface IAudioInputProps {
  handleDisableDevice(b: boolean): void;
  disableAudio:boolean;
}

export default function AudioInputList(props: IAudioInputProps) {
  const classes = useStyles();
  const audioInputDevices = useAudioInputDevices();
  const {
    room: { localParticipant },
    localTracks,
    getLocalAudioTrack,
  } = useVideoContext();

  const localAudioTrack = localTracks.find(track => track.kind === 'audio');
  const localAudioInputDeviceId = localAudioTrack ? localAudioTrack.mediaStreamTrack.getSettings().deviceId : undefined;

  function replaceTrack(newDeviceId: string) {
    if (localAudioTrack)
      localAudioTrack.stop();
    getLocalAudioTrack(newDeviceId).then(newTrack => {
      if (localParticipant) {
        if (localAudioTrack) {

          const localTrackPublication = localParticipant.unpublishTrack(localAudioTrack);
          // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
          localParticipant.emit('trackUnpublished', localTrackPublication);
        }

        if (localParticipant)
          localParticipant.publishTrack(newTrack);
      }
    });
  }

  var audioInputs: MediaDeviceInfo[] = [];
  if (audioInputDevices.length >= 1) {
    var alreadyUsedinputGroups: string[] = [];
    audioInputDevices.forEach(device => {
      if (alreadyUsedinputGroups.findIndex(group => group === device.groupId) === -1) {
        alreadyUsedinputGroups.push(device.groupId);
        audioInputs.push(device);
      }
    });
  }
  const stopAudioTrack = (device: MediaDeviceInfo) => {
    if (localAudioInputDeviceId === device.deviceId) {
      props.handleDisableDevice(false);
    }
  }
  const onDeviceSelected = (device: MediaDeviceInfo) => {
    replaceTrack(device.deviceId);
  }
  const onDeviceOff = (device: MediaDeviceInfo) => {
    stopAudioTrack(device);
  }

  let content = <Typography>Microfoni non disponibili</Typography>;
  if(props.disableAudio){
    content = <Typography>Microfoni disabilitati</Typography>;
  }
  else if(audioInputs.length >= 1){
    content = <FormControl fullWidth>
      {audioInputs.map(device => (<DeviceInput key={device.deviceId} device={device} onDeviceSelected={onDeviceSelected} onDeviceOff={onDeviceOff} selected={localAudioInputDeviceId === device.deviceId} type="AUDIO_INPUT" />))}
    </FormControl>;
  }

  return (
    <div className={classes.container}>
      <div className="inputSelect">
        {content}
      </div>
    </div>
  );
}
