import React, { Component } from 'react';
import { withStyles, withTheme, Grid } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';
import XButton from 'components/XButton';
import XDateField from 'components/XDateField';
import XField from 'components/XField';
import { Pencil, Delete } from 'mdi-material-ui';
import XMessageBox from 'components/XMessageBox';
import CashNumberEditor from './CashNumberEditor';
import CashDateEditor from './CashDateEditor';
import moment from 'moment';

import classNames from 'classnames';
import CashRow from './CashRow';
import CashCell from './CashCell';

const styles = theme => ({
    root: {
        padding: 10,
    },
    payMethod: {
        display: 'inline-block',
        margin: 5,
        padding: 20,
        background: theme.palette.secondary.main,
        color: '#fff',
        cursor: 'pointer',
    },
    payMethodSelected: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    item: {
        display: 'inline-block',
        verticalAlign: 'top',
        padding: '0 15px',
        '&::before': {
            display: 'block',
            content: 'attr(data-label)',
        }
    },
});


class CashPayment extends Component {
    constructor(props) {
        super(props);
        this.XMessage = React.createRef();
        this.state = {
            totalDocument: 0,
            totalToPay: 0,
            payments: [],
            deposits: [],
            showPaymentMethods: false,
            paymentMethods:[],
            paymentId: undefined,
            paymentDate: new Date(),
            paymentAmount: 0,
            id: undefined,
            step: 1,
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        var { doc } = this.props;
        var params = `${doc.ID}?FIDCausal=${doc.FIDCausal}`;
        //var totalDocument = await fetch(`/DOC/GetCashTotalFinalDiscounted/${params}`).then(res => res.json());
        //var totalToPay = await fetch(`/DOC/GetCashTotalToPay/${params}`).then(res => res.json());
        //var payments = await fetch(`/DOC/GetCashPayments/${params}`).then(res => res.json());
        //var deposits = await fetch(`/DOC/GetCashDeposits/${params}`).then(res => res.json());
		
		await Promise.all([
			fetch(`/DOC/GetCashTotalFinalDiscounted/${params}`), 
			fetch(`/DOC/GetCashTotalToPay/${params}`),
			fetch(`/DOC/GetCashPayments/${params}`),
			fetch(`/DOC/GetCashDeposits/${params}`),
			fetch(`/DOC/GetCashPaymentMethods`)
		]).then(([totalDocument, totalToPay, payments, deposits, paymentMethods]) => { 
               return Promise.all([totalDocument.json(), totalToPay.json(), payments.json(), deposits.json(), paymentMethods.json()]) 
		}).then(([totalDocument, totalToPay, payments, deposits, paymentMethods]) => {
			this.setState({
				totalDocument,totalToPay, payments, deposits, paymentMethods
			});
		});

        //this.setState({totalDocument,totalToPay, payments, deposits});
        //
        //var paymentMethods = await fetch(`/DOC/GetCashPaymentMethods`).then(res => res.json());
        //this.setState({paymentMethods});
    }
    onBack = () => {
        var {showPaymentMethods,paymentId, step,showEditorDate, showEditor} = this.state;
        if(showEditor){
            this.setState({showEditor: false,editKey: undefined, editValue: undefined});
            return true;
        }
        if(showEditorDate){
            this.setState({showEditorDate: false,editKey: undefined, editValue: undefined});
            return true;
        }
        step--;
        if(paymentId || showPaymentMethods) {
            this.setState({showPaymentMethods: false, paymentId: undefined, paymentDate: new Date(), paymentAmount: null, step: 1}, this.onStepChange)
            return true;
        }
        
        return false;
    }
    onStepChange = () => {
        this.props.onStepChange && this.props.onStepChange(this.state.step);
    }
    onAddPayment = () => {
        var { totalToPay } = this.state;
        this.setState({ showPaymentMethods: true,paymentAmount: 0, oldPaymentAmount: 0, step: 2 }, this.onStepChange);
    }
    onPaymentMethodSelect = (paymentId) => () => {
        this.setState({paymentId, step: 3}, this.onStepChange);
    }
    onCofirmPayment = async () => {
        var { paymentId, paymentDate, paymentAmount, oldPaymentAmount, id,totalToPay } = this.state;
        if(!paymentAmount) {
            paymentAmount = totalToPay;
        }
        var payment = {
            ID: id,
            FIDPaymentMethod: paymentId,
            PaymentDate: paymentDate,
            Amount: paymentAmount
        }
		debugger;
        oldPaymentAmount = oldPaymentAmount || 0;
        if(paymentAmount > totalToPay + oldPaymentAmount){
            //message
            this.XMessage.current.showMessageOk("Inserimento pagamento","È stato inserito un valore maggiore del totale residuo da pagare");
            return;
        }
        await fetch(`DOC/SaveDocumentPayment`,{
            method: 'POST',
            body: JSON.stringify(payment)
        });
        this.loadData();
        if(totalToPay + oldPaymentAmount - paymentAmount === 0){
            this.setState({paymentId: undefined, paymentDate: new Date(), paymentAmount: null,showPaymentMethods: false, step: 1}, this.onStepChange);
        }
        else {
            this.XMessage.current.showMessageYesNo("Inserimento pagamento","Vuoi gestire un altro pagamento?",() =>{
                this.setState({paymentId: undefined, paymentDate: new Date(), paymentAmount: null, step: 2}, this.onStepChange);
            },() => {
                this.setState({paymentId: undefined, paymentDate: new Date(), paymentAmount: null,showPaymentMethods: false, step: 1}, this.onStepChange);
            });
        }


    }
    onDateChange = () => (value) => {
        this.setState({paymentDate: value});
    }
    onAmountChange = () => (event, value) => {
        this.setState({paymentAmount: value});
    }
    onEditPayment = (item) => () => {
        this.setState({
            id: item.ID,
            oldPaymentAmount: item.Amount,
            paymentAmount: item.Amount,
            paymentDate: item.PaymentDate,
            paymentId: item.FIDPaymentMethod,
            showPaymentMethods: true,
            step: 3
        }, this.onStepChange);
    }
    onDeletePayment = (item) => async () => {
        var { doc } = this.props;
        await fetch(`/DOC/DeleteCashPayment/${doc.ID}?FIDPayment=${item.ID}`);
        this.loadData();
    }
    onDeleteDeposit = (item) => async () => {
        var { doc } = this.props;
        await fetch(`/DOC/DeleteCashDeposit/${doc.ID}?FIDDeposit=${item.ID}`);
        this.loadData();
    }
    renderPaymentDetails = () => {
        var { classes } = this.props;
        var { totalDocument,totalToPay,payments, deposits } = this.state;
        var now = moment(new Date());
        
        return <div style={{ padding: "0 35px" }}>
            <CashRow>
                <CashCell width={250} totalWidth={750} label={"Totale documento"}>{totalDocument}</CashCell>
                <CashCell width={250} totalWidth={750} label={"Gestito"}>{totalDocument - totalToPay}</CashCell>
                <CashCell width={250} totalWidth={750} label={"Sospeso"}>{totalToPay}</CashCell>
            </CashRow>

            {deposits && deposits.map(item => {
                if(item.OriginDocDate && !item.OriginDocDate._isAMomentObject){
                    item.OriginDocDate = moment(new Date(item.OriginDocDate));
                }
                if(item.TotalDiscountedAmount < 0) {
                    item.TotalDiscountedAmount = item.TotalDiscountedAmount  * -1;
                }
                return (<CashRow>
                <CashCell width={250} totalWidth={1000}>{item.OriginDocDate && item.OriginDocDate.format('DD/MM/YYYY')}</CashCell>
                <CashCell width={250} totalWidth={1000}>{item.Description} n. {item.OriginDocNumber}</CashCell>
                <CashCell width={250} totalWidth={1000}>{item.TotalDiscountedAmount}</CashCell>
                <CashCell width={250} totalWidth={1000}><XButton onClick={this.onDeleteDeposit(item)}><Delete /></XButton></CashCell>
            </CashRow>)})}
            {payments && payments.map(item => {
                if(item.PaymentDate && !item.PaymentDate._isAMomentObject){
                    item.PaymentDate = moment(new Date(item.PaymentDate));
                }
                return (<CashRow>
                <CashCell width={250} totalWidth={1000}>{item.PaymentDate.format('DD/MM/YYYY')}</CashCell>
                <CashCell width={250} totalWidth={1000}>{item.Description}</CashCell>
                <CashCell width={250} totalWidth={1000}>{item.Amount}</CashCell>
                <CashCell width={250} totalWidth={1000}><XButton onClick={this.onEditPayment(item)}><Pencil /></XButton><XButton onClick={this.onDeletePayment(item)}><Delete /></XButton></CashCell>
            </CashRow>)})}
            {totalToPay > 0 && <CashRow>
                <CashCell width={250} totalWidth={1000}>{now.format('DD/MM/YYYY')}</CashCell>
                <CashCell width={250} totalWidth={1000}>{"SOSPESO"}</CashCell>
                <CashCell width={250} totalWidth={1000}>{totalToPay}</CashCell>
                <CashCell width={250} totalWidth={1000}><XButton onClick={this.onAddPayment}>Gestisci</XButton></CashCell>
            </CashRow>}
        
            <XMessageBox innerRef={this.XMessage} />
        </div>;
    }
    onEditorClick = (key,label) => () => {
        this.setState({
            showEditor: true, editKey: key, editLabel: label
        });
    }
    onEditorDateClick = (key,label) => () => {
        this.setState({
            showEditorDate: true, editKey: key, editLabel: label
        });
    }
    onEditorSubmit = (fields) => {
        var key = "paymentAmount";
        var value = fields.filter(item => item.id === key)[0].value;
        this.setState(state => {
            state[key] = value;
            state.showEditor= false;
            state.showEditorDate= false;
            state.editKey= undefined;
            state.editLabel= undefined;
            return state;
        },this.onCofirmPayment);
    }
    onDateEditorSubmit = (key,value) => {
        this.setState(state => {
            state[key] = value;
            state.showEditor= false;
            state.showEditorDate= false;
            state.editKey= undefined;
            state.editLabel= undefined;
            return state;
        });
    }
    onEditorRecalculate = (fields) => {
         //keys
         var TotToPay = "TotToPay";
         var paymentAmount = "paymentAmount";
         var Suspended = "Suspended";
 
         var elTotToPay = fields.filter(item => item.id === TotToPay)[0];
         var elpaymentAmount = fields.filter(item => item.id === paymentAmount)[0];
         var elSuspended = fields.filter(item => item.id === Suspended)[0];
         
         elSuspended.value = (elTotToPay.value - elpaymentAmount.value).toFixed(2);
 
         fields = [elTotToPay,elpaymentAmount,elSuspended];
         return fields;
    }
    renderNewPayment = () => {
        var { classes } = this.props;
        var { paymentMethods,paymentId, paymentDate,paymentAmount,totalToPay } = this.state;
        if(!paymentAmount){
            paymentAmount = totalToPay;
        }
        if(paymentDate && !paymentDate._isAMomentObject){
            paymentDate = moment(new Date(paymentDate));
        }
        var payment = paymentMethods.filter(item => item.ID === paymentId)[0];
        return <div style={{ padding: "0 35px" }}>
            {paymentMethods.map(item =>(<span className={classNames(classes.payMethod, {[classes.payMethodSelected]: paymentId === item.ID })} onClick={this.onPaymentMethodSelect(item.ID)}>{item.Description}</span>))}
            {paymentId && <div>
                <CashRow>
                    <CashCell label={"Pagamento"} width={300} totalWidth={701}>{payment.Description}</CashCell>
                    <CashCell label={"Data"} width={200} totalWidth={701} onClick={this.onEditorDateClick("paymentDate","Data pagamento")}>{paymentDate && paymentDate.format('DD/MM/YYYY')}</CashCell>
                    <CashCell label={"Importo"} width={200} totalWidth={701} onClick={this.onEditorClick("paymentAmount","Importo")}>{paymentAmount}</CashCell>
                    <CashCell width={1} totalWidth={701}></CashCell>
                </CashRow>
            </div>}
            {/* {paymentId && <Grid container spacing={2}>
                <Grid item xs={12}><span>{payment.Description}</span></Grid>
                <Grid item xs={12}><span className={classes.item} data-label={"Data pagamento"}>{paymentDate && paymentDate.format('DD/MM/YYYY')}</span></Grid>
                <Grid item xs={12}><span className={classes.item} data-label={"Importo"} >{paymentAmount}</span></Grid>
                <Grid item xs={12}></Grid>
            </Grid>} */}
            <XMessageBox innerRef={this.XMessage} />
        </div>;
    }
    render() {
        var { classes } = this.props;
        var { showPaymentMethods,showEditor, editLabel, editKey,showEditorDate, totalToPay } = this.state;
        if(showEditor){
            var value = this.state[editKey];
            if(!value){
                value = totalToPay;
            }
            else {
                totalToPay += value;
            }
            var fields = [
                {
                    label: "Totale da pagare",
                    id: "TotToPay",
                    value: totalToPay,
                    readOnly: true
                },
                {
                    label: editLabel,
                    id: editKey,
                    value: value,
                },
                {
                    label: "Sospeso",
                    id: "Suspended",
                    value: (totalToPay - value),
                    readOnly: true
                },
            ];
            return <div className={classes.editor}>
                <CashNumberEditor fieldLabel={editLabel} id={editKey} value={value} fields={fields} onSubmit={this.onEditorSubmit} onRecalculate={this.onEditorRecalculate}/>
            </div>;
        }
        else if(showEditorDate){
            return <div className={classes.editor}>
                <CashDateEditor fieldLabel={editLabel} id={editKey} value={this.state[editKey]} onSubmit={this.onDateEditorSubmit}/>
            </div>;
        }
        else if(showPaymentMethods){
            return this.renderNewPayment();
        }
        else{
            return this.renderPaymentDetails();
        }
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(CashPayment);