import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import Videocam from '@material-ui/icons/Videocam';
import Message from '@material-ui/icons/Message';
import { Tooltip, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import SimpleErrorDialog from '../../videochat/components/SimpleErrorDialog';
import UserBox from './UserBox';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			margin: "0 -24px",
			width: "calc(100% + 48px)"
		},
		fab: {
			margin: theme.spacing(1),
		},
		available: {
			width: '32px',
			height: '32px',
			borderRadius: '50%',
			backgroundColor: '#81f542',
		},
		busy: {
			width: '32px',
			height: '32px',
			borderRadius: '50%',
			backgroundColor: '#f5e042',
		},
		title: {
			boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
			width: '100%',
			padding: 5,
			background: '#fff'
		}
	})
);

function UserListInner(props) {
	const classes = useStyles(props);
	return (
		<List dense>
			{/* {props.users.length > 0 && <ListItem key='title'
				dense
				style={{ paddingLeft: 6, paddingRight: 6 }}>
				<div className={classes.title}>
					<Typography variant="h6" align="center">{props.title}</Typography>
				</div>
			</ListItem>} */}
			{props.users.map((user) => {
				const labelId = `checkbox-list-label-${user.id}`;
				return (
					<ListItem key={user.id}
						dense
						style={{ paddingLeft: 6, paddingRight: 6 }}>
						<UserBox
							user={user}
							checked={props.checked}
							onToggle={props.onToggle}
							openMessageChat={props.openMessageChat}
							callUser={props.callUser}
							inCall={props.inCall}
						/>
					</ListItem>
				);
			})}
		</List>);
}

export default function UserList(props) {
	const classes = useStyles(props);
	const [checked, setChecked] = React.useState([]);
	const [dialogMsg, setDialogMsg] = React.useState(undefined);

	const handleToggle = (user) => () => {
		if (user.availability === 0) {
			const currentIndex = checked.findIndex(check => check.id === user.id);
			const newChecked = [...checked];

			if (currentIndex === -1)
				newChecked.push(user);
			else
				newChecked.splice(currentIndex, 1);

			setChecked(newChecked);
		}
		else {
			notifyBusyUser(user);
		}
	};
	const notifyBusyUser = (user) => {
		setDialogMsg("L'utente selezionato è impegnato in un'altra call");
		props.notifyBusyUser(user);
	}

	const handleCall = () => {
		if (checked.length > 0) {
			props.callUser(checked.map(check => check.id));
			setChecked([]);
		}
	}

	const callable = (props.roomParticipants ?
		props.users.filter(user => !props.roomParticipants.includes(user.id))
		: props.users) || [];

	return (
		<div className={classes.root}>
			{dialogMsg &&
				<SimpleErrorDialog dismissError={() => { setDialogMsg(undefined) }} message={dialogMsg} />}
			{/* {!props.inCall && <Tooltip title="Call" placement="top">
				<Fab className={classes.fab} color="primary" disabled={checked.length === 0} onClick={handleCall}>
					<Videocam />
				</Fab>
			</Tooltip>} */
			//gestione chiamate di gruppo
			}

			{(props.companyUsers || props.showAll) && <UserListInner
				checked={checked}
				onToggle={handleToggle}
				users={callable.filter(user => user.isLicensee === true)}
				showControlButton={false}
				showAvailability={true}
				openMessageChat={props.openMessageChat}
				callUser={props.callUser}
				inCall={props.inCall}
			/>}
			{(!props.companyUsers || props.showAll) && <UserListInner
				checked={checked}
				onToggle={handleToggle}
				users={callable.filter(user => user.isLicensee === false)}
				showControlButton={false}
				showAvailability={true}
				openMessageChat={props.openMessageChat}
				callUser={props.callUser}
				inCall={props.inCall}
			/>}
		</div>
	);
}