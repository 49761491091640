import React, { Component } from 'react';
import { withStyles, withTheme, makeStyles, Collapse, IconButton, Grid, Tooltip } from '@material-ui/core';
import { Microphone, MicrophoneOff, Video, VideoOff, ExitToApp, Bell, MapMarker, MapMarkerOff, Pin, PinOff } from 'mdi-material-ui';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';
import Participant from 'videochat/components/Participant/Participant';
import XButton from 'components/XButton';
import XRoundButton from './XRoundButton';
import useTrack from 'videochat/hooks/useTrack/useTrack';
import usePublications from 'videochat/hooks/usePublications/usePublications';
import useIsTrackEnabled from 'videochat/hooks/useIsTrackEnabled/useIsTrackEnabled';

const useStyles = makeStyles(theme => ({
    container: {
        margin: '0 -6px',
    },
    root: {
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
        width: '100%',
        padding: 5,
        background: '#fff'
    },
    collapse: {
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
        background: '#fff',
        width: '100%',
        padding: 5,
    },
    username: {
        display: 'block',
        fontSize: 15,
        fontWeight: 'bold',
    },
    company: {
        display: 'block',
        fontSize: 12,
        color: '#999'
    },
    action: {
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.80)',
        background: '#fff',
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.08)',
            color: theme.palette.secondary.main,
            boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.80)',
        }
    }
}));


const ParticipantBox = (props) => {
    const classes = useStyles(props);
    const { isOpen, user, participant, isSelected, selectParticipant,
        handleUnmuteMic, handleMuteMic, handleEnableMic, handleDisableMic,
        handleUnmuteVideo, handleMuteVideo, handleEnableVideo, handleDisableVideo,
        handleForceQuit, showUserRequest, handleFixPartecipant, handleUnfixPartecipant
    } = props;
    const publications = usePublications(participant);

    const audioPublication = publications.find(p => p.kind === 'audio');
    const videoPublication = publications.find(p => p.trackName.includes('camera'));

    const isVideoEnabled = Boolean(videoPublication);
    const audioTrack = useTrack(audioPublication);
    const isAudioEnabled = useIsTrackEnabled(audioTrack);

    return (
        <div className={classes.container}>
            <div className={classes.root}>
                <div className={classes.title}>
                    <span className={classes.username}>{user.userDenomination}</span>
                    <span className={classes.company}>{user.denomination}</span>
                </div>
                <div className={classes.video}>
                    {participant &&
                        <Participant
                            key={participant.sid}
                            participant={participant}
                            isSelected={isSelected}
                            onClick={selectParticipant}
                        />
                    }
                </div>
            </div>
            <Collapse in={isOpen} timeout="auto" className={classes.collapse}>

                <div className={classes.controlButtons}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <XRoundButton toolTip="Attiva" placement="bottom" onClick={() => handleUnmuteMic(user)}><Microphone style={{ color: isAudioEnabled ? "green" : "inherit" }} /></XRoundButton>
                        </Grid>
                        <Grid item xs={3}>
                            <XRoundButton toolTip="Disattiva" placement="bottom" onClick={() => handleMuteMic(user)}><MicrophoneOff style={{ color: !isAudioEnabled ? "red" : "inherit" }} /></XRoundButton>
                        </Grid>
                        <Grid item xs={3}>
                            <XRoundButton toolTip="Sblocca" placement="bottom" onClick={() => handleEnableMic(user)}><Microphone style={{ color: 'inherit' }} /></XRoundButton>
                        </Grid>
                        <Grid item xs={3}>
                            <XRoundButton toolTip="Blocca" placement="bottom" onClick={() => handleDisableMic(user)}><MicrophoneOff style={{ color: 'inherit' }} /></XRoundButton>
                        </Grid>
                        <Grid item xs={3}>
                            <XRoundButton toolTip="Attiva" placement="bottom" onClick={() => handleUnmuteVideo(user)}><Video style={{ color: isVideoEnabled ? "green" : "inherit" }} /></XRoundButton>
                        </Grid>
                        <Grid item xs={3}>
                            <XRoundButton toolTip="Disattiva" placement="bottom" onClick={() => handleMuteVideo(user)}><VideoOff style={{ color: !isVideoEnabled ? "red" : "inherit" }} /></XRoundButton>
                        </Grid>
                        <Grid item xs={3}>
                            <XRoundButton toolTip="Sblocca" placement="bottom" onClick={() => handleEnableVideo(user)}><Video style={{ color: 'inherit' }} /></XRoundButton>
                        </Grid>
                        <Grid item xs={3}>
                            <XRoundButton toolTip="Blocca" placement="bottom" onClick={() => handleDisableVideo(user)}><VideoOff style={{ color: 'inherit' }} /></XRoundButton>
                        </Grid>

                        <Grid item xs={3}>
                            <XRoundButton toolTip="Fissa" placement="bottom" onClick={() => handleFixPartecipant(user)}><Pin style={{ color: props.isFixed ? "green" : 'inherit' }} /></XRoundButton>
                        </Grid>
                        <Grid item xs={3}>
                            <XRoundButton toolTip="Live" placement="bottom" onClick={() => handleUnfixPartecipant(user)}><PinOff style={{ color: !props.isFixed ? "red" : 'inherit' }} /></XRoundButton>
                        </Grid>
                        <Grid item xs={12}>
                            <XButton className={classes.action} fullWidth color={"secondary"} onClick={() => handleForceQuit(user)}>ESCLUDI</XButton>
                        </Grid>
                        {/* <Grid item xs={3}>
                            {showUserRequest &&
                                <IconButton disabled={true}><Bell /></IconButton>
                            }
                        </Grid> */}
                    </Grid>
                </div>

            </Collapse>
        </div>
    );
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withTheme
);
export default enhance(ParticipantBox);