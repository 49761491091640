import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const style = () => ({
});

class ShopArea extends Component {
    constructor(props) {
        super(props);
        this.Editor = React.createRef();
		this.state = {
			shoparea: { FIDStore: 0 },
		}
    }
    checkUsed = async (FIDArea) => {
        return await fetch('/BOP/ShopAreaIsUsed/' + FIDArea).then(res => res.json());
        
    }
    deleteArea = (FIDArea) => {
        fetch('/BOP/ShopAreaDelete/' + FIDArea, { method: "POST" }).then(res => {
            this.Editor.current.doExit();
        });
    }
    onDeleteClick = async() => {
        var id = this.props.match.params.id;

        var isUsed = await this.checkUsed(id);

        var label = "Confermi la cancellazione della shop area?";
        if (isUsed) {
            label = "La shop area è utilizzata nelle distinte. Continuando veranno rimosse dalle distinte le informazioni relative alla shop area. " + label;
        }

        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.Editor.current.hideDialog();
                    this.deleteArea(id);
                }
            },
            {
                label: "NO",
                action: () => {
                    this.Editor.current.hideDialog();
                }
            },
        ];

        this.Editor.current.showDialog(buttons, "Cancellazione", label);
        
    }
    render() {
        return <GenericEditor
            innerRef={this.Editor}
            id={this.props.match.params.id}
            FIDParentColumnName='FIDStore'
            FIDParentColumnCaption='Store'
			FIDParentUrlCreate="/gc/stores/Create"
            urlGetParents='/gc/stores/'
            urlSave='/BOP/SaveShopArea/'
            urlDelete='/BOP/DeleteShopArea/'
            urlRedirect='/shoparea/'
            urlUploadFiles='/BOP/ShopAreaFileUpload/'
            urlDeleteFiles='/BOP/DeleteShopAreaFile/'
            urlGetFiles='/BOP/GetShopAreaFiles/'
            urlCheckDefaultFiles='/BOP/CheckDefaultShopAreaFiles/'
            urlExit='/shopareas/'
            urlNew="/shoparea/new"
            urlGetData='/gc/storeareas/'
            //urlCheckChanges='/BOP/ShopAreaHasChanges/'
            urlClearSession='/BOP/ClearSession/'
            onDeleteClick={this.onDeleteClick}
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(ShopArea);
