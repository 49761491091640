import React, { Component } from 'react';
import { withStyles, withTheme, Grid } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';

import XNumpadKeyboard from '../../../components/XNumpadKeyboard';
import XField from '../../../components/XField';
import XButton from '../../../components/XButton';
import CashRow from './CashRow';
import CashCell from './CashCell';
const styles = theme => ({
    root: {

    },
    numpad: {
        position: 'relative',
        left: '50%',
        transform: 'translate(-50%)'
    },
    center: {
        maxWidth: 600,
        position: 'relative',
        left: '50%',
        transform: 'translate(-50%)',
    }
});


class CashTextEditor extends Component {
    constructor(props) {
        super(props);
        this.state= {
            value: '',
            firstPress: true,
        }
    }
    componentDidMount() {
        this.setState({fields: this.props.fields, id: this.props.id},() => {
            this.setFocus(true);
        });
    }
    setFocus = (selectAll) => {
        var el = document.getElementById(this.state.id);
        if(el){
            el.focus();
            if(selectAll){
                el.setSelectionRange(0,el.value.length);
            }
        }
    }
    onBlur = (key) => (event) => {
        this.setFocus();
		//document.getElementById(key).focus();
    }
    onNumpadSubmit = () => {
        var { onSubmit } = this.props;
        var { fields } = this.state;
        var hasNegatives = fields.filter(item => item.value < 0).length > 0;
        if(hasNegatives){
            return;
        }
        onSubmit && onSubmit(fields);
    }
    onFocusChange = (id) => () => {
        this.setState({ id, firstPress: true },() => {
            this.setFocus(true);
        });
    }
    onChange = (id) => (event,value) => {
        this.setState(state => {
            var field = state.fields.filter(item => item.id === id)[0];
            field.value = value;

            if(this.props.onRecalculate ){
                state.fields = this.props.onRecalculate(state.fields, id);
            }
            return state;
        })
    }

    render() {
        var { classes } = this.props;
        var { fields } = this.state;


        var width = fields ? `${(100 / fields.length)}%` : '100%';

        return <div>
            
            <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={classes.center}>
                            {fields && <CashRow>
                                {fields.map(item => <CashCell width={width}><XField fontSize={18} disabled={item.readOnly} labelFontSize={16} label={item.label} id={item.id} value={item.value} onClick={!item.readOnly && this.onFocusChange(item.id)} onChange={this.onChange} onBlur={this.onBlur} onKeyDown={this.onKeyDown} onChange={this.onChange}/></CashCell>)}
                            </CashRow>}
                            <CashRow>
                                <CashCell width={"100%"}><XButton onClick={this.onNumpadSubmit}>CONFERMA</XButton></CashCell>
                            </CashRow>
                        </div>
                    </Grid>
                </Grid>
            
        </div>;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(CashTextEditor);