import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    inputRoot: {
        color: 'inherit',
    },
    inputFixed: {
        marginLeft: '5px',
        padding: theme.spacing(1, 1, 1, 0),
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}));

export default function InputSimple(props) {
    const classes = useStyles();

    return (
        <InputBase
            fullWidth
            placeholder={props.placeholder}
            classes={{
                root: classes.inputRoot,
                input: classes.inputFixed,
            }}
            inputProps={{ 'aria-label': 'search' }}
            value = {props.value}
            onChange = {(e) => props.onChange(e.target.value)}
            onKeyPress = {props.onKeyPress}
            disabled = {props.disabled ? props.disabled : false}
        />
    )
}