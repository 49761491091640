import React, { useRef, useEffect } from 'react';
import { IVideoTrack } from '../../types';
import { styled } from '@material-ui/core/styles';
import { Track } from 'twilio-video';

const Video = styled('video')({
  objectFit: 'contain',
  minWidth:  '100%',
  minHeight: '100%',
  maxHeight: 'calc(100vh - 64px)',
  maxWidth: 'min(100%, 177.78vh)', 
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  position: 'relative'
});
const VideoPreview = styled('video')({
  objectFit: 'contain',
  height: '100%',
  width: '100%', 
});

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
  isMain: boolean;
}

export default function VideoTrack({ track, isLocal, priority, isMain }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if(priority === undefined){
      priority = null;
    }
    if (track.setPriority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);
      if (track.setPriority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  // The local video track is mirrored.
  console.log(JSON.stringify(track));
  const isFrontFacing = track.mediaStreamTrack.getSettings().facingMode !== 'environment';
  const style = isLocal && isFrontFacing ? { transform: 'translate(-50%,-50%) rotateY(180deg)' } : {};
  const ret = isMain ? (<Video ref={ref} style={style} />) : (<VideoPreview ref={ref} style={style} />);
  return ret;
}
