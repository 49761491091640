import React, { Component, Fragment } from 'react';
import Card from '@material-ui/core/Card';
import classnames from 'classnames';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import IconButton from '@material-ui/core/IconButton';
//import MoreVertIcon from '@material-ui/icons/MoreVert';
import { MoreVert, KeyboardArrowLeft, KeyboardArrowRight, ExpandMore } from '@material-ui/icons';

import ReactSpeedometer from 'react-d3-speedometer';

import PropTypes from 'prop-types';

//import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
//import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';


import { withStyles } from '@material-ui/core/styles';
import numeral from 'numeral';

import { Pencil, StarOutline, BellRingOutline, LockOpenOutline, LockOutline, CartOutline, Star } from 'mdi-material-ui';
import { PieChart, Pie, Cell } from 'recharts';

import CatalogueActions from 'components/CatalogueActions';
import XOXLoader from './XOXLoader';
import { Collapse, CardActions } from '@material-ui/core';
import { actionCreators as localizationCreators, getTranslation } from 'store/Localization';

import {
    grayColor,
} from "assets/jss/material-dashboard-pro-react.jsx";
import { getDiscount } from 'utils';


const styles = theme => ({
    card: {
        position: "relative",
        margin: 5,
    },
    header: {
        maxHeight: 80,
        minHeight: 80,
        overflow: "hidden",
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        backgroundSize: 'contain',
    },
    slider: {
        //maxHeight: 220,
        //minHeight: 220,
        overflow: "hidden !important",
        position: "relative",
        width: "100%",
        cursor: "pointer",
    },
    actions: {
        display: 'flex',
    },
    //expand: {
    //    transform: 'rotate(0deg)',
    //    transition: theme.transitions.create('transform', {
    //        duration: theme.transitions.duration.shortest,
    //    }),
    //    marginLeft: 'auto',
    //    [theme.breakpoints.up('sm')]: {
    //        marginRight: -8,
    //    },
    //},
    //expandOpen: {
    //    transform: 'rotate(180deg)',
    //},
    avatar: {
        backgroundColor: theme.palette.primary,
    },
    price: {
        fontSize: "14px",
        fontWeight: "bold",
    },
    mediaButtonLeft: {
        position: "absolute",
        left: "0",
        top: "50%",
        transform: "translateY(-50%)",
    },
    mediaButtonRight: {
        position: "absolute",
        right: "0",
        top: "50%",
        transform: "translateY(-50%)",
    },
    cardTitle: {
        fontSize: "18px",
    },
    cardContent: {
        //maxHeight: 80,
        //minHeight: 80,
        //marginTop: 136,
        //transition: "all 0.3s ease-in",
        marginTop: 0,
        //marginBottom: 136,
    },
    cardContentHover: {
        marginTop: 0,
        //marginBottom: 136,
    },
    cardContentInfo: {
        maxHeight: 42,
        minHeight: 42,
    },
    cardActions: {
        //position: "absolute",
        //transition: "all 0.3s ease-in",
        //bottom: -136,
        height: 136,
        textAlign: "center",
        "&> div:nth-child(1)": {
            marginTop: "0 !important",
        },
        bottom: 0,
    },
    cardActionsHover: {
        bottom: 0,

    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    expandActions: {
        padding: "8px 0",
        width: "calc(100% + 16px)",
        margin: "-8px",
    },
    docInfo: {
        marginRight: 5,
    },
    infoLabels: {
        fontSize: 12,
    },
    speedometer: {
        position: 'relative',
        width: 100,
        height: 67,
        marginLeft: 'auto',
        marginRight: 'auto',
        '& .current-value': {
            transform: 'translateY(-60px)',
            fontSize: '12px !important',
        }
    }
});

class VisionAccountCard extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    _isMounted = false;
    state = {
        imageIndex: 0,
        documents: [],
        data: {},
        isLoading: true,
        expanded: false,
    };
    componentDidMount() {
        this._isMounted = true;
        this.loadData(this.getItemStatus);
        //this.getItemStatus();
        this.setState({ expanded: this.props.isExpanded });
    }
    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.loadData(this.getItemStatus);
        }
        if (this.props.isExpanded !== prevProps.isExpanded) {
            this.setState({ expanded: this.props.isExpanded });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    loadData = async (callback) => {
        var { data, FIDIdentity, year } = this.props;
        if (data && data.ID > 0) {
            var result1 = await fetch(`/BOP/GetCatalogueData/${data.ID}?customer=${FIDIdentity}&visionCount=1&year=${year}`).then(res => res.json());
            if (this._isMounted) {
                this.setState({ data: result1, isLoading: false }, callback);
            }
        }
        else if (data.Code || data.Position) {
            var url = '/BOP/GetCatalogueData/?Code=' + data.Code;
            if (data.Position) {
                url += "&Position=" + data.Position;
            }
            var result2 = await fetch(url).then(res => res.json());
            if (this._isMounted) {
                this.setState({ data: result2, isLoading: false }, callback);
            }
        }
    }

    getItemStatus = async () => {
        var { FIDIdentity, FIDDocument } = this.props;
        var { data } = this.state;
        if(data) {
            var result = await fetch('/IR/BOPStatus/' + data.ID + '?FIDIdentity=' + FIDIdentity + '&FIDDocument=' + FIDDocument).then(res => res.json());
            if (this._isMounted) {
                this.setState(state => {
                    state.IsBooked = result.IsBooked;
                    state.IsReserved = result.IsReserved;
                    state.IsBookedOther = result.IsBookedOther;
                    state.IsReservedOther = result.IsReservedOther;
                    state.documents = result.documents;
                    var cart = state.documents.filter(f => f.FIDCausal === 10)[0];
                    if (cart && cart.Discount && cart.Discount > 0) {
                        state.discount = cart.Discount;
                        //state.Price = this.props.data.SellPrice || state.Price;
                        state.totalPrice = (data.Price * (100 - state.discount) / 100).round(2);
                        state.showPrices = true;
                    }
                    if (result.documents && result.documents.length > 0) {
                        state.internalRemark = result.documents[0].InternalRemarks;
                        state.publicRemark = result.documents[0].PublicRemarks;
                    }
                    return state;
                });
            }
        }
    }
    handleNext = () => {

        var { data, imageIndex } = this.state;
        var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = files.length;
        if (imageIndex < imagesCount - 1) {
            this.setState({ imageIndex: imageIndex + 1 });
        }
        else {
            this.setState({ imageIndex: 0 });
        }
    }
    handlePrevious = () => {
        var { data, imageIndex } = this.state;
        var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = files.length;
        if (imageIndex > 0) {
            this.setState({ imageIndex: imageIndex - 1 });
        }
        else {
            this.setState({ imageIndex: imagesCount - 1 });
        }
    }
    handleChangeIndex = (index) => {
        this.setState({ imageIndex: index });
    }
    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }), () => {
            if (this.props.onExpand) {
                this.props.onExpand(this.state.expanded);
            }
        });
    }
    onItemEditClick = (data) => {
        this.context.router.history.push('/BOPEdit/' + data.ID);
    }
    renderDocInfos = () => {
        var { classes } = this.props;
        var { documents } = this.state;

        var isPresentation = documents.filter(i => i.FIDCausal === 11).length > 0;
        var isCart = documents.filter(i => i.FIDCausal === 10).length > 0;
        var isNotice = documents.filter(i => i.FIDCausal === 12).length > 0;
        var isReservation = documents.filter(i => i.FIDCausal === 13).length > 0;
        var isBooking = documents.filter(i => i.FIDCausal === 14).length > 0;
        var HoverStar = 0;
        var color = grayColor;
        if (isPresentation) {
            HoverStar = documents.filter(i => i.FIDCausal === 11)[0].Stars;
        }
        switch (HoverStar) {
            case 1:
                color = "#E74C3C";
                break;
            case 2:
                color = "#F39C12";
                break;
            case 3:
                color = "#F7DC6F";
                break;
            case 4:
                color = "#82E0AA";
                break;
            case 5:
                color = "#27AE60";
                break;
            default:
                break;
        }

        return <Fragment>
            {isPresentation && HoverStar > 0 && <Star className={classes.docInfo} htmlColor={color} fontSize="small" />}
            {isPresentation && HoverStar <= 0 && <StarOutline className={classes.docInfo} htmlColor={color} fontSize="small" />}
            {isNotice && <BellRingOutline className={classes.docInfo} fontSize="small"/>}
            {isReservation && <LockOpenOutline className={classes.docInfo} fontSize="small"/>}
            {isBooking && <LockOutline className={classes.docInfo} fontSize="small"/>}
            {isCart && <CartOutline className={classes.docInfo} fontSize="small"/>}
        </Fragment>;
    }
    render() {

        var { data, imageIndex, hover, internalRemark, publicRemark, isLoading  } = this.state;
        var { classes, onItemClick, isCross, grouped } = this.props;
        var propsData = this.props.data;
        var isLoadingPage = this.props.isLoading;
        if (!data) {
            data = {};
            isLoadingPage = true;
        }
        if (isLoadingPage) {
            isLoading = isLoadingPage;
        }
        data.internalRemark = internalRemark;
        data.publicRemark = publicRemark;

        var subTitle = "";
        if (data.Brand) {
            subTitle = data.Brand;
        }
        if (data.Line) {
            if (subTitle !== "") {
                subTitle += " - ";
            }
            subTitle += data.Line
        }
        var pieData2 = [];
        if (data.Value) {
            pieData2 = [{ value: 100 - (100 - data.Value + 2) }, { value: 4 }, { value: 100 - data.Value - 2 }];
        }
        var pieData = [{ name: "1", value: 33.33 }, { name: "2", value: 33.33 }, { name: "3", value: 33.33 }];
        var price = data.PriceCurrency + " " + numeral((propsData.SellPrice || 0)).format("0,0.00")
        var priceList = data.PriceCurrency + " " + numeral((propsData.PriceList || 0)).format("0,0.00");
        if (isCross) {
            price = data.PriceCurrency + " " + numeral((data.Price || 0)).format("0,0.00")
        }
        var discountNumber = propsData.Discount || 0;
        var discount = ((getDiscount(`${propsData.DiscountDesc}+${propsData.ExtraDiscountDesc}`) || 0)*100).toFixed(2) + " %";


        var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        if (files.length > 1) {
            files = [files[0]];
        }
        var mediaFiles = files.length > 0 ? files.map((file, index) => (<CardMedia key={index} className={classes.media} image={"/Base/Image/" + file + "?size=S"} />)) : (<CardMedia className={classes.media} image="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E" />);
        if (data.File64) {
            mediaFiles = [(<CardMedia className={classes.media} image={data.File64} />)];
        }

        var content = <Fragment>
            <CardHeader
                className={classes.header}
                title={grouped ? (data.CatalogCode || data.Code) : data.Code}
                subheader={subTitle}
                classes={{
                    title: classes.cardTitle
                }}
                action={
                    <Fragment>
                        {this.props.data.Correspondece && (
                            <div className={classes.speedometer} >
                                <ReactSpeedometer
                                    value={Math.round(this.props.data.Correspondece * 100)}
                                    minValue={0}
                                    maxValue={100}
                                    fluidWidth
                                    startColor="#FF0000"
                                    endColor="#00FF00"
                                    segments={5}
                                    ringWidth={5}
                                    maxSegmentLabels={0}
                                    needleColor="#383b3d"
                                    textColor="#383b3d"
                                    needleHeightRatio={0.6}
                                    needleTransition="easeBounceOut"
                                    needleTransitionDuration={1000}
                                    currentValueText="${value}%"
                                />
                            </div>
                        )}
                    </Fragment>
                }
            />
            <div className={classes.slider} onClick={() => { if(onItemClick) onItemClick(data) }}>
                <SwipeableViews axis="x" resistance={false} index={imageIndex} onChangeIndex={this.handleChangeIndex}>
                    {mediaFiles}
                </SwipeableViews>
                {files.length > 1 &&
                    (
                        <Fragment>
                            <Button className={classes.mediaButtonLeft} size="small" onClick={this.handlePrevious} ><KeyboardArrowLeft /></Button>
                            <Button className={classes.mediaButtonRight} size="small" onClick={this.handleNext}><KeyboardArrowRight /></Button>
                        </Fragment>
                    )
                }
            </div>
            <CardContent className={classes.cardContent + " " + (hover ? classes.cardContentHover : "")}>
                <Grid container spacing={16}>
                    <Grid container item xs={12} >
                        <Grid container item xs={12} className={classes.cardContentInfo}>
                            <Grid item xs={12}>
                                {data.SerialNumber}
                            </Grid>                           
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={4} container className={classes.infoLabels}>
                            {getTranslation(1,"CAT_PRICELIST",this.props.translations, "Listino")}
                        </Grid>
                        <Grid item xs={4} container alignContent="center" justify="center" className={classes.infoLabels}>
                          {discountNumber > 0 ? "Sc. %" : ""}
                        </Grid>
                        <Grid item xs={4} container alignContent="flex-end" justify="flex-end" className={classes.infoLabels}>
                            {getTranslation(4,"IR_SALE",this.props.translations, "Vendita")}
                        </Grid>
                        <Grid item xs={4} container className={classes.price}>
                            {priceList}
                        </Grid>
                {discountNumber > 0 ? <Grid item xs={4} container alignContent="center" justify="center" className={classes.price}>{discount}</Grid> :
                  <Grid item xs={4} container alignContent="center" justify="center" className={classes.price}>&nbsp;</Grid>
                }
                        <Grid item xs={4} container alignContent="flex-end" justify="flex-end" className={classes.price}>
                            {price}
                        </Grid>
                    </Grid>
                    {propsData.DocumentDate && (<Grid container item xs={12} className={classes.infoLabels} style={{ marginTop: 8 }}>
                        <Grid container item xs={8}>
                            <Grid item xs={12}>
                                {getTranslation(2,"DOC_DOCDATE",this.props.translations, "Data documento")}
                            </Grid>
                            <Grid item xs={12} className={classes.price}>
                              {propsData.DocumentDate}
                            </Grid>
                        </Grid>

                {propsData.Qty1 && <Grid container item xs={4} alignContent="flex-end" justify="flex-end">
                    <Grid item container xs={12} alignContent="flex-end" justify="flex-end">
                      {getTranslation(5,"GC_QTY",this.props.translations, "Quantità")}
                    </Grid>
                    <Grid item xs={12} container className={classes.price} alignContent="flex-end" justify="flex-end">
                      {propsData.Qty1} {propsData.UM1}
                    </Grid>
                  </Grid>}
                    </Grid>)}
                    {data.VisitDate && (<Grid container item xs={6}>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography>Data visita:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {data.VisitDate.toLocaleDateString()}
                            </Grid>
                        </Grid>
                    </Grid>)}
                    {data.Operator && (<Grid container item xs={6}>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography>Operatore:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {data.Operator}
                            </Grid>
                        </Grid>
                    </Grid>)}
                </Grid>
            </CardContent>
            {this.props.showExpansionPanel &&
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    {
                        (this.props.onNoticeItem || this.props.onStarItem || this.props.onBookItem || this.props.onReserveItem || this.props.onBuyItem || this.props.onRemoveItem) &&
                        <Grid container item xs={12} className={classes.cardActions + " " + (hover ? classes.cardActionsHover : "")}>
                            <CatalogueActions data={data} documents={this.state.documents} onNoticeItem={this.props.onNoticeItem} onStarItem={this.props.onStarItem} onBookItem={this.props.onBookItem} onReserveItem={this.props.onReserveItem} onBuyItem={this.props.onBuyItem} onRemoveItem={this.props.onRemoveItem} />
                        </Grid>
                    }
                </Collapse>
            }
            <div></div>
        </Fragment>;
        //if (this.state.isLoading) {
        //    content = <XOXLoader onlyO={true} />
        //}
        return (
            <Card style={this.props.style} className={classes.card} onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })}>
                {content}
                {isLoading && <XOXLoader onlyO={true} height="100%" />}
            </Card>
        );
    }
}

export default withStyles(styles)(VisionAccountCard);