import React from 'react';
import{ withStyles } from '@material-ui/core';
import{ Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import { withSnackbar } from 'notistack';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as userSignalCreators } from './UserSignaler';
import { actionCreators as userAuthCreators} from '../../store/Auth';
import { green, red } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import MessageChat from './MessageChat';
import SimpleErrorDialog from '../../videochat/components/SimpleErrorDialog';
import { ensureMediaPermissions } from '../../videochat/utils';
import CallEnd from '@material-ui/icons/CallEnd';
import VideoCall from '@material-ui/icons/VideoCall';
import Home from '@material-ui/icons/Home';
import { MonitorScreenshot, ProjectorScreen } from 'mdi-material-ui';
import XButton from 'components/XButton';

const style = theme => ({
    floatingChats:{
        zIndex:1000,
    },
    topButtonChat: {
      width: 'fit-content',
      top: 0,
      right: 100,
      position: 'absolute',
      padding: 6,
      zIndex: 20000
    },
    topButtonChatXOX: {
      width: 'fit-content',
      top: 0,
      right: 200,
      position: 'absolute',
      padding: 6,
      zIndex: 20000
    }
});

class VideoChatManager extends React.Component {
    constructor(props)
    {
        super(props)
        this.state = {
            rooms: [],
            snackKeys: [],
            requests_useridtoKey: {},
        };
    }
    static contextTypes = {
        router: PropTypes.object
    }
    notifyAction = (room) => key => (
        <React.Fragment>
            <IconButton edge="end" aria-label="comments" 
                        onClick={() => {this.eraseSnackBar(key); this.acceptUserIdCall(room.owner.id); this.cleanup(room);}} 
                        style = {{margin:'auto', backgroundColor: green[500]}}>
                <CallIcon />
            </IconButton>
            <IconButton edge="end" aria-label="comments" 
                        onClick={() => {this.eraseSnackBar(key); this.rejectUserIdCall(room.owner.id)}}
                        style = {{margin:'auto', backgroundColor: red[500]}}>
                <CallEndIcon />
            </IconButton>
        </React.Fragment>
    );
    busyNotifyAction = key => (
        <React.Fragment>
            <Button edge="end" aria-label="comments" 
                        onClick={() => {this.eraseSnackBar(key);}} 
                        color="primary">
                            OK
            </Button>
        </React.Fragment>
    );
    enable_requestNotifyAction = (user) => key => (
        <React.Fragment>
            <Button edge="end" aria-label="comments" 
                        onClick={() => {this.props.clearEnableRequest(user.id);}} 
                        color="primary">
                            OK
            </Button>
        </React.Fragment>
    );

    acceptUserIdCall = (userId) => {
        const room = this.state.rooms.find(r => r.owner.id === userId);
        this.props.acceptCall(room.detail.roomName)
    }
    rejectUserIdCall = (userId) => {
        const room = this.state.rooms.find(r => r.owner.id === userId);
        if(room)
        {
            this.props.rejectCall(room.detail.roomName);
            const newRooms = this.state.rooms.filter(r => r.owner.id !== room.owner.id);
            this.setState({rooms: newRooms});
        }
    }

    fireNotification = (message, room) => {
        if(this.state.rooms.find(r => r.owner.id === room.owner.id))
        {
           const newRooms = [...this.state.rooms.filter(r => r.owner.id !== room.owner.id), room];
           this.setState({rooms: newRooms});
        }
        else
        {
            this.setState({rooms: [...this.state.rooms, room]});
            const key = this.props.enqueueSnackbar(message, { 
                variant: 'default',
                persist: true,
                action: this.notifyAction(room)
            });
            this.setState({snackKeys: [...this.state.snackKeys, key]});
        }
    }
    fireBusyNotification = (username) => {
        const message = "["+username + "] - tried to call you while you were busy."
        this.props.enqueueSnackbar(message, { 
            variant: 'default',
            persist: true,
            action: this.busyNotifyAction
        }); 
    }
    eraseSnackBar = (key) => {
        this.props.closeSnackbar(key);
        
        this.setState({snackKeys: this.state.snackKeys.filter(skey => skey !== key)});
    }

    cleanup = (except) => {
        this.state.rooms.forEach(room => {
            if(except === undefined || room.owner.id !== except.owner.id)
                this.props.rejectCall(room.detail.roomName)
        });
        this.setState({rooms: []});
        this.state.snackKeys.forEach(key => {this.props.closeSnackbar(key)});
        this.setState({snackKeys: []});
    }
    fireRequestNotification = (user) => {
        const message = "["+user.username + "] - request to speak."
        const key = this.props.enqueueSnackbar(message, { 
            variant: 'default',
            persist: true,
            action: this.enable_requestNotifyAction(user)
        }); 
        var new_map = {...this.state.requests_useridtoKey}
        if(new_map[user.id] === undefined)
            new_map[user.id] = [];
        new_map[user.id] = new_map[user.id].concat(key);
        this.setState({requests_useridtoKey : new_map}) 
    }

    eraseRequestNotification = (userId) => {
        const keys = this.state.requests_useridtoKey[userId]
        if(keys !== undefined){
            keys.map(key => this.props.closeSnackbar(key));
            var new_map = {...this.state.requests_useridtoKey};
            new_map[userId] = undefined;
            this.setState({requests_useridtoKey : new_map}) 
        }
    }

    componentDidMount(){
        ensureMediaPermissions();
        window.addEventListener('beforeunload',(event) => {
            // Cancel the event as stated by the standard.
            event.preventDefault();
            // Chrome requires returnValue to be set.
            //event.returnValue = '';
            this.cleanup();
          });

        if(this.props.userID){
            this.props.startConnection(this.props.userID);
            // fetch('/Payments/GetPaymentsStatus')
            // .then(result => result.json())
            // .then(result => this.props.setPaymentsStatus(result.active, result.errorDetail))
        }      
    }

    componentWillUnmount(){
        this.cleanup();
        this.props.stopConnection();
    }
    componentDidUpdate(props) {
        if(this.props.userID 
            && this.props.userID !== props.userID){
            this.props.startConnection(this.props.userID);
        }      
        if(this.props.redirect !== props.redirect && this.props.redirect !== undefined)
        {                
            this.props.redirectDone();
            this.context.router.history.push(`/VideoChat`);
        }
        else{
            const currentPath = this.context.router.history.location.pathname;
            if(currentPath === "/VideoChat" && !this.props.roomToken){
                this.context.router.history.push(`/home`);
            }
        }
        
        if(this.props.showNotification
            && this.props.showNotification !== props.showNotification){
            const room = this.props.showNotification;
            this.fireNotification('Incoming call: '+room.owner.username, room);
            this.props.clearNotification();
        }  
        if(this.props.showBusyNotification
            && this.props.showBusyNotification !== props.showBusyNotification){
            const user = this.props.showBusyNotification;
            this.fireBusyNotification(user);
            this.props.clearBusyNotification();
        } 


        if(this.props.usersWithRequest){
            if(props.usersWithRequest === undefined 
                || this.props.usersWithRequest.length > props.usersWithRequest.length){//nuova notifica
                const user = this.props.usersWithRequest[this.props.usersWithRequest.length - 1];               
                this.fireRequestNotification(user);
            }
            else if(this.props.usersWithRequest.length < props.usersWithRequest.length){//una da eliminare
                props.usersWithRequest.map(user => {
                    if(!this.props.usersWithRequest.includes(user)){
                        this.eraseRequestNotification(user.id);
                    }
                })
            }
        } 
        if(this.props.leaveRoom
            && this.props.leaveRoom !== props.leaveRoom){
                this.props.callLeft(this.props.roomName);
        }      
    }
    closeMessageChat = (user) => {
        this.props.closeMessageChat(user);
    }
    sendDirectMessage = (userId, message) => {
        this.props.sendDirectMessage(userId, message);
    }

    leaveRoom = () => {
        this.props.leaveCurrentRoom(); 
    }
    returnToRoom = () => {
        this.context.router.history.push(`/VideoChat`);
    }
    returnToHome = () => {
        this.context.router.history.push(`/home`);
    }
	returnToHomeSharing = () => {
        this.props.startScreenShare();
        this.returnToHome();
    }

    render(){  
        const currentPath = this.context.router.history.location.pathname;
        const showControls = currentPath !== "/VideoChat" && this.props.roomName !== undefined && this.props.isLicensee;
        const showChatControls = currentPath === "/VideoChat" && this.props.roomName !== undefined && this.props.isLicensee;

        return (<div>
            {showControls && (
            <div className={this.props.classes.topButtonChatXOX}>
                <XButton color="primary" onClick={this.returnToRoom}>MEETING</XButton>
                {/* <Tooltip title={'End Call'} placement="bottom">
                    <IconButton onClick={() => {this.leaveRoom();}} color="primary">
                        <CallEnd/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Return to Call'} placement="bottom">
                    <IconButton onClick={() => {this.returnToRoom()}} color="primary">
                        <VideoCall/>
                    </IconButton>
                </Tooltip> */}
            </div>
            )}
            {showChatControls && (
            <div className={this.props.classes.topButtonChat}>
                <XButton color="primary" onClick={this.returnToHome}>XOX</XButton>
                {/* <Tooltip title={'Return to XTRA'} placement="bottom">
                    <IconButton onClick={() => {this.returnToHome();}} color="primary">
                        <MonitorScreenshot />
                    </IconButton>
                </Tooltip> */}
                {/* <XButton color="primary" onClick={this.returnToHomeSharing}>XOX - SHARE</XButton> */}
                {/* <Tooltip title={'Return to XTRA and enable ScreenShare'} placement="bottom">
                    <IconButton onClick={() => {this.returnToHomeSharing()}} color="primary">
                        <ProjectorScreen />
                    </IconButton>
                </Tooltip> */}
            </div>
            )}
            {(this.props.leaveRoom && this.props.leaveRoom.length > 0) &&
                <SimpleErrorDialog dismissError={() => {this.props.clearLeaveRoom()}} message={this.props.leaveRoom} />}
            <div className={this.props.classes.floatingChats}>
                {this.props.showMessageChats
                && this.props.showMessageChats.map((user,index) => {
                    return (
                    <MessageChat
                                key={`chat_${user.id}`}
                                position={index}
                                userId={user.id}
                                username={user.username}
                                messages={this.props.directMessages ? this.props.directMessages[user.id] : undefined}
                                closeMessageChat={this.closeMessageChat}
                                sendMessage={this.sendDirectMessage}
                    />
                    )
                })
                }
            </div>
        </div>);
    }
}


const enhance = compose(
    connect(
        state => state.userSignaler,
        dispatch => bindActionCreators(userSignalCreators, dispatch)
    ),
    connect(
       state => state.auth,
        dispatch => bindActionCreators(userAuthCreators, dispatch)
    ),
    withStyles(style)
);
export default withSnackbar(enhance(VideoChatManager));