import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import { Document, Page } from 'react-pdf';
//import html2canvas from 'html2canvas';
//import jsPDF from 'jspdf';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';
import XPDFViewer from 'components/XPDFViewer';


const styles = theme => ({
});

class GDPRPDF extends Component {
    state = {
        numPages: null,
        pageNumber: 1,
    };

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    goToPrevPage = () =>
        this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
    goToNextPage = () =>
        this.setState(state => ({ pageNumber: state.pageNumber + 1 }));


    componentDidMount() {
        //html2canvas(document.body, { width: 595, windowWidth: 595}).then(canvas => {
        //    document.body.appendChild(canvas);
        //    ///this.setState({ image: canvas });
        //    const imgData = canvas.toDataURL('image/png');
        //    //const pdf = new jsPDF();
        //    //pdf.addImage(imgData, 'PNG', 0, 0);
        //    //pdf.save("download.pdf"); 
        //});
    }
    render() {
        var { FIDIdentity,FIDAgreement, inIR } = this.props;
        const { pageNumber, numPages } = this.state;
        var url = "/IR/GetGDPRPdf/" + (FIDIdentity || 0);
        if (FIDAgreement) {
            url += "?FIDAgreement=" + FIDAgreement;
        }
        

        //return (<embed src={"/IR/GetGDPRPdf/" + data.ID} type="application/pdf" width="100%" height="100%"/>);
        //return (<iframe src={`https://docs.google.com/gview?url=${window.location.origin}${url}&embedded=true`} type="application/pdf" width="100%" height={document.body.offsetHeight - 259} />);
        return <XPDFViewer url={url} width="100%" height={window.innerHeight - 90 - 60 - 6 - (inIR ? 85 : 0)}/>
        //return (
        //    <div>
        //        <nav>
        //            {pageNumber > 1 && <button onClick={this.goToPrevPage}>Prev</button>}
        //            {pageNumber < numPages && <button onClick={this.goToNextPage}>Next</button>}
        //        </nav>
        //        <div>
        //            <Document
        //                file={"/IR/GetGDPRPdf/" + data.ID}
        //                onLoadSuccess={this.onDocumentLoadSuccess}
        //            >
        //                <Page pageNumber={pageNumber} />
        //            </Document>
        //            <p>Page {pageNumber} of {numPages}</p>
        //        </div>
        //    </div>
        //);
    }
}



GDPRPDF.propTypes = {
    classes: PropTypes.object.isRequired,
};


//export default withStyles(styles)(GDPRPDF);



// const enhance = compose(
//     withStyles(styles)
// );

const enhance = compose(
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withStyles(styles)
);

export default enhance(GDPRPDF);