import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment';

import styles from "assets/jss/material-dashboard-pro-react/customTextFieldStyle.jsx"


class XDateField extends Component {

    handleChange = (value) => {
        var { id, onChange } = this.props;

        if(value && value._isAMomentObject){
            value = value.toDate();
        }

        if (onChange) {
            onChange(id)(value);
        }
    }
    handleBlur = (event) => {
        var { id,onBlur } = this.props;
        if (onBlur) {
            onBlur(id)(event);
        }
    }
    render() {
        var { classes, id, value, label, field, style, readOnly } = this.props;

        if (value === undefined || value === null) {
            value = null;
        }
        if(value){
            if(!value._isAMomentObject) {
                value = moment(new Date(value));
            }
        }
        //var error = false;
        var errorClass = classes.error;
        if (field) {
            if ((field.Required === 2 || (field.Required === 1)) && !value) {
                //error = true;
                if (field.Required === 1) {
                    errorClass = classes.errorOptional;
                }
            }
        }

		if (!readOnly)
			readOnly = false;

        return (
            <KeyboardDatePicker
				autoOk
				// variant="inline"
                clearable
                keyboard
                id={id}
                name={id}
                label={label}
                value={value}
                onChange={this.handleChange}
                format="DD/MM/YYYY"
                style={style}
                clearLabel="Pulisci"
                cancelLabel="Annulla"
                InputLabelProps={{
                    shrink: true,
                    classes: {
                        root: classes.textFieldLabel,
                    },
                }}
                InputProps={{
                    classes: {
                        root: classes.textFieldInput,
                        error: errorClass
                    },
					readOnly: Boolean(readOnly),
					disabled: Boolean(readOnly),
                }}
                onBlur={this.handleBlur}
                className={classes.textField}
                // mask={value =>
                //     // handle clearing outside if value can be changed outside of the component
                //     value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
                // }
            />
        );
    }
}
XDateField.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
};
export default withStyles(styles)(XDateField);