import React, { Component } from 'react';
import { withStyles, withTheme, Drawer } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        height: 'calc(100vh - 64px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        background: "#fff",//theme.palette.secondary.main,
        //color: theme.palette.secondary.contrastText,
        position: 'relative',
        top: 0,
        transform: props => props.left ? 'translateX(-100%)' : 'translateX(100%)',
        width: 240,
        display: 'none',
        boxShadow: '0px 10px 10px rgba(0,0,0,0.5)',
    },
    open: {
        transform: 'translateX(0) !important',
        display: 'initial',
    },
    left: {
        left: 0,
    },
    right: {
        right: 0,
    }
});


class VideochatSidebar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var { classes, left, right, open, className, ...props } = this.props;
        return <div className={classNames(classes.root,className,{[classes.open]: open,[classes.left]: left,[classes.right]: right})}>
            {this.props.children}
        </div>;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(VideochatSidebar);