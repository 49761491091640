import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Grid, Tabs, Tab } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';
import { Check, ArrowLeft, ExitToApp, Ring, TextBoxSearch, CreditCardOutline, PrinterPos, Printer, Gift, Plus, AlphaDcircle, AccountSearch, Pencil, Receipt } from 'mdi-material-ui';

import XGrid from '../../../components/XGrid';
import IdentitySelector from '../../../components/IdentitySelector';
import XStepper from '../../../components/XStepper';

import CashProducts from '../Cash/CashProducts';
import CashIR from '../Cash/CashIR';
import CashTotals from '../Cash/CashTotals';
import CashPayment from '../Cash/CashPayment';
import CashDateEditor from '../Cash/CashDateEditor';
import XNavigation from '../../../components/XNavigation';
import XMessageBox from '../../../components/XMessageBox';
import XSwitch from '../../../components/XSwitch';
import PrintReceipt from '../Cash/PrintReceipt';
import CashRelationSelector from '../Cash/CashRelationSelector';
import XOXLoader from 'components/XOXLoader';
import IREdit from 'views/IR/IREdit';

import RepairCausal from './RepairCausal';

import moment from 'moment';

const styles = theme => ({
    root: {
    },
    panelContainer: {
        width: '100%',
        margin: 0,
        position: 'relative',
    },
    leftPanel: {
        height: 'calc(100vh - 145px)',
    },
    rightPanel: {
        position: 'relative',
        height: 'calc(100vh - 145px)',
        boxShadow: '0px 10px 10px rgba(0,0,0,0.5)',
    },
    printPreview: {
        height: 'calc(100vh - 151px)',
        width: '100%'
    }
});


class RepairEditor extends Component {
    constructor(props) {
        super(props);

        this.CashTotals = React.createRef();
        this.Products = React.createRef();
        this.Payment = React.createRef();
        this.PrintReceipt = React.createRef();
        this.IREdit = React.createRef();
        this.CashIR = React.createRef();
        this.XMessage = React.createRef();
        this.grdDocuments = React.createRef();
        this.state = {
            FIDIdentity: undefined,
            showIdentitySelector: false,
            showIdentityEditor: false,
            showSearch: false,
            showPayment: false,
            view: undefined,
            printReceipt: false,
            data: {},
            isLoading: true,
            printPreview: false,
            conectionManaged: false,
            docIRFilter: 1,
            dueDate: moment(new Date()),
            showAmounts: true,
            docview: 'REPAIR',
            tab: 0,
        }
    }
    static contextTypes = {
        router: PropTypes.object
    }
    componentDidMount() {
        if (this.props.match.params.id && parseInt(this.props.match.params.id, 10) > 0) {
            var ID = parseInt(this.props.match.params.id, 10);
            this.loadCashDocument(ID);
        }
        else {
            this.newCashDocument();
        }
        this.props.startConnection();
        var pageTitle = this.props.isReturnRepair ? "Consegna riparazione" : "Ricezione riparazione";
        this.props.setTitle(pageTitle);
        
    }
    componentWillUnmount() {
        this.props.stopConnection();
    }
    componentDidUpdate(props){
        if(this.props.connection && this.props.connection !== props.connection && !this.state.conectionManaged){
            this.manageConnectionEvents();
        }
    }
    manageConnectionEvents = async () => {
        this.setState({ connectionManaged: true });
        this.props.connection.on("OnClientStart", this.onClientStart)
        this.props.connection.on("OnReceiptOutput", this.onReceiptOutput)
        this.props.connection.on("OnCashRegisterError", this.onCashRegisterError)
    }
    onClientStart = () => {
        console.log("client start");
    }
    onReceiptOutput = (output) => {
        this.setState({ printingReceipt: false, printReceipt: false},() => {
            var { data } = this.state;
            if(!data.ProtocolNumber){
                this.XMessageBox("Stampa scontrino","Procedere con la stampa dello scontrino di cortesia?", this.printGiftReceipt);
                this.loadCashDocument(data.ID);
            }
        });
    }
    onCashRegisterError = (errorCode, errorDescription) => {
        console.error(errorCode,errorDescription);
        this.setState({ printingReceipt: false });
    }
    loadCashDocument = async (ID) => {
        
        var data = await fetch(`/DOC/GetDOC/${ID}`).then(res => res.json());
        this.setState({data, FIDIdentity: data.FIDIdentity, isLoading: false});
    }
    newCashDocument = async () => {
        var url = `/DOC/NewRepairDocument`;
        if(this.props.isReturnRepair){
            url = `/DOC/NewReturnRepairDocument`;
        }
        var data = await fetch(url).then(res => res.json());
        this.setState({data, isLoading: false});
    }

    onChangeIRRequest = () => {
        this.setState({ showIdentitySelector: true, customActions:[],showSearch: false  });
    }
    // onClearIR = () => {
    //     this.setState({FIDIdentity: undefined, isValidIR: true, docIRFilter: 1});
    // }
    
    onClearIR = () => {
        this.setState(state => {
            state.FIDIdentity = undefined;
            state.data.FIDIRRelation = undefined;
            state.isValidIR = true;
            state.docIRFilter = 1;
            return state;
        })
        //this.setState({FIDIdentity: undefined, data: {FIDIRRelation: undefined}, isValidIR: true, docIRFilter: 1, hasDeposits: false});
    }
    onEditIR = () => {
        this.setState({showIdentityEditor: true, showPrivacy: false});
    }
    onEditPrivacyIR = () => {
        this.setState({showIdentityEditor: true, showPrivacy: true});
    }
    
    onChangeIRSupplierRequest = () => {
        this.setState({ showSupplierSelect: true, customActions:[],showSearch: false  });
    }
    onClearIRSupplier = () => {
        this.setState(state => {
            state.data.FIDRepairSupplier = undefined;
            return state;
        },this.updateRepairSupplier);
    }
    updateRepairSupplier = () => {
        var { data } = this.state;
        fetch(`/DOC/UpdateRepairSupplier/${data.ID}?FIDSupplier=${data.FIDRepairSupplier}`).then(res => {
            this.loadCashDocument(data.ID);
            if(data.FIDRepairSupplier){
                this.XMessage.current.showMessageYesNo('Generazione DDT C/Riparazione','Generare il DDT C/Riparazione?',this.generateDDT);
            }
        });
    }
    onIdentitySelect = (FIDIdentity) => {
       
        this.setState(state => {
            state.FIDIdentity = FIDIdentity;
            state.data.FIDIRRelation = undefined;
            state.showIdentitySelector = false;
            state.customActions = [];
            state.showSearch = false;
            state.docIRFilter = 0;
            return state;
        });
        //this.setState({FIDIdentity, showIdentitySelector: false, docIRFilter: 0, customActions:[],showSearch: false });
    }
    onSupplierSelect = (FIDIdentity) => {
        this.setState(state => {
            state.data.FIDRepairSupplier = FIDIdentity;
            state.showSupplierSelect = false;
            return state;
        },this.updateRepairSupplier);//{FIDSupplier: FIDIdentity, showSupplierSelect: false},this.updateRepairSupplier);
    }
    
    onUpdateShowAmounts = (id) => (event,value) => {
        this.setState({showAmounts: value});
    }
    generateDDT = async () => {
        var FIDDDT = await fetch(`/DOC/DDTFromRepair`,{
            method: 'POST',
            body: JSON.stringify([this.state.data.ID])
        }).then(res => res.json());
        if(FIDDDT && FIDDDT.length > 0){
            this.setState({ docview:"DOCS"},() => {
                this.grdDocuments.current.refresh();
            });
            this.XMessage.current.showMessageOk('Generazione DDT C/Riparazione','Operazione effettuata');
        }
        else {
            this.XMessage.current.showMessageOk('Generazione DDT C/Riparazione','Nessun documento generato');
        }
    }
    generateCash = async () => {
        var FIDDDT = await fetch(`/DOC/CashFromRepair`,{
            method: 'POST',
            body: JSON.stringify([this.state.data.ID])
        }).then(res => res.json());
        if(FIDDDT){
            this.XMessage.current.showMessageOk('Generazione Scontrino','Operazione effettuata');
            this.context.router.history.push('/documents');
            this.context.router.history.push('/documents/issue/cash/' + FIDDDT);
        }
        else {
            this.XMessage.current.showMessageOk('Generazione Scontrino','Nessun documento generato');
        }
    }
    onSearchShowChange = (show, onClose) => {
        this.setState({ showSearch: show, closeSearch: onClose});
    }

    onProductsChange = () => {
        this.CashTotals.current.loadData();
    }
    onExitPage = () => {
        //window.history.back();
        this.context.router.history.push(`/documents`);
    }
    onPay = () => {
        this.setState({ showPayment: true, paymentStep: 1});
    }
    onPaymentBack = () => {
        if(!this.Payment.current.onBack()){
            this.setState({ showPayment: false, paymentStep: 0});
        }
    }
    onPaymentConfirm = () => {
        this.Payment.current && this.Payment.current.onCofirmPayment();
    }
    changeView = (view) => () => {
        this.setState({view});
    }
    changeDocView = (docview) => () => {
        this.setState({docview});
    }
    onConfirm = async () => {
        var { data, FIDIdentity } = this.state;
        if(!FIDIdentity) {
            this.XMessage.current.showMessageOk('Conferma documento',`Selezionare un'anagrafica cliente prima di procedere.`);
            return;
        }
        var totRows = await fetch(`/DOC/GetCashRowsCount/${data.ID}?FIDCausal=${data.FIDCausal}`).then(res => res.json());
        if(totRows === 0){
            this.XMessage.current.showMessageOk('Conferma documento','Non è presente nessun prodotto nel documento');
            return;
        }
        var checkRepairSuppliers = await fetch(`/DOC/CheckRepairSupplier/${data.ID}?FIDCausal=${data.FIDCausal}`).then(res => res.json());
        if(checkRepairSuppliers){
            this.XMessage.current.showMessageYesNo('Conferma riparazione','Attenzione! Esiste una o più righe senza riparatore selezionate. Continuare comunque?', this.doConfirmRepair);
        }
        else {
            this.doConfirmRepair();
        }

    }
    doConfirmRepair = async () => {
        var { data, FIDIdentity } = this.state;
        this.setState({isLoading: true});
        data.FIDIdentity = FIDIdentity;
        data.FIDDestination = FIDIdentity;
        var doc = await fetch(`/DOC/SaveCashDocument`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(res => res.json());
        if(this.props.isReturnRepair){
            window.history.pushState('','',`/documents/issue/repair/${doc.ID}`);
        }
        else {
            window.history.pushState('','',`/documents/receive/repair/${doc.ID}`);
        }
        await this.loadCashDocument(doc.ID);
        this.doConfirmCash();
    }
    confirmCash = () => {
       this.doConfirmCash();
    }
    doConfirmCash = async () => {
        var { data, FIDIdentity, dueDate } = this.state;
        this.setState({isLoading: true});
        data.FIDIdentity = FIDIdentity;
        data.FIDDestination = FIDIdentity;
        var doc = await fetch(`/DOC/ConfirmCashDocument?DueDate=${dueDate.format('YYYY-MM-DD')}`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(res => res.json());
        //this.setState({ data: doc,isLoading: false, showPayment: false, paymentStep: 0,showIdentitySelector: false, showSearch: false,printPreview: true }, () => {
             //this.loadCashDocument(doc.ID);
        //});
        this.setState({data: doc, isLoading: false, showPayment: false, paymentStep: 0,showIdentitySelector: false, showSearch: false, printPreview: false,printReceipt: false},() => {
            this.loadCashDocument(doc.ID);
            !this.props.isReturnRepair && this.manageDDT();
        });
    }
    manageDDT = () => {
        this.XMessage.current.showMessageYesNo('Generazione DDT C/Riparazione','Generare il DDT C/Riparazione?',this.generateDDT);
    }
    manageSupplier = () => {
        this.XMessage.current.showMessageYesNo("Selezione riparatore","Vuoi procedere alla selezione di un riparatore?",this.onShowSupplierSelect);
    }
    manageFirstReceiptPrint = () => {
        var { data } = this.state;
        if(!data.ProtocolNumber) {
            this.XMessage.current.showMessageYesNo("Stampa scontrino","Procedere con la stampa dello scontrino fiscale?",this.onPrintFirstReceipt);
        }
    }
    onShowSupplierSelect = () => {
        this.setState({showSupplierSelect:true});
    }
    cancelPrint = () => {
        this.setState({ printPreview: false });
    }
    onPrint = () => {
        this.setState({ printPreview: true });
    }
    onPrintReceipt = () => {
        this.setState({ printReceipt: true });
    }
    onCancelPrintReceipt = () => {
        this.setState({ printReceipt: false });
    }
    onPrintFirstReceipt = () => {

        this.setState({printingReceipt: true});
        var {FIDDocument,FIDCashRegister,GiftReceipt} = this.PrintReceipt.current.getInfo();
        this.props.printReceipt(FIDDocument,FIDCashRegister);
    }
    onReprintReceipt = () => {
        
        this.setState({printingReceipt: true});
        var {FIDDocument,FIDCashRegister,GiftReceipt} = this.PrintReceipt.current.getInfo();
        this.props.reprintReceipt(FIDDocument,FIDCashRegister);
    }
    onPrintGiftReceipt = () => {
        
        this.setState({printingReceipt: true});
        var {FIDDocument,FIDCashRegister,GiftReceipt} = this.PrintReceipt.current.getInfo();
        this.props.printGiftReceipt(FIDDocument,FIDCashRegister);
    }
    onDOCIRFilterChange = (step) => {
        this.setState({docIRFilter: step});
    }
    onEditIRExit = () => {
        this.setState({showIdentityEditor: false});
    }
    onSaveIR = () => {
        this.setState({showIdentityEditor: false}, async () => {
            if(this.CashIR.current && this.CashIR.current.loadData) {
                await this.CashIR.current.loadData();
            }
        });
    }
    onPaymentStepChange = (step) => {
        this.setState({ paymentStep: step});
    }
    onProductsonRequestActions = (actions) => {
        this.setState({ customActions: actions});
    }
    onDateEditorSubmit = (key, value) => {
        this.setState({ dueDate: value, setDueDate: false },this.doConfirmCash);
    }
    onIRLoaded = (data) => {
        this.setState({ isValidIR: !data.Incomplete });
    }
    onCausalChange = (id) => {
        this.setState(state => {
            state.data.FIDCausal = id;
            state.customActions=[];
            state.showSearch= false
            return state;
        });
    }
    modifyDocument = () => {
        var { data } = this.state;
        fetch(`/DOC/ModifyDocument/${data.ID}`).then(res => {
            this.loadCashDocument(data.ID);
        });
    }
    

    handleGridDOCDoubleClick = (event, data) => {
        var { view } = this.state;
        this.context.router.history.push(`/doc/edit/${data.ID}`);
    }
    
    handleGridStockDoubleClick = (event, data) => {
        this.context.router.history.push('/stock/' + data.ID);
    }
    handleChangeTab = (_, value) => {
        this.setState({ tab: value });
    }
    renderPrintPreview = (exitAction, generateDDTAction) => {
        var { classes, width } = this.props;
        var { showAmounts, data, docview, printDoc, hideDiscounts } = this.state;
        var content = undefined;
        var key = `ID=${data.ID}&showAmounts=${showAmounts}`;
        switch(docview){
            case "REPAIR":
                var url = `/DOC/PrintDocument/?ID=${data.ID}&showAmounts=${showAmounts}`;
                content = <iframe id="PrintPreview" className={classes.printPreview} key={key} src={`https://docs.google.com/gview?url=${window.location.origin}${url}&embedded=true`}></iframe> 
                break;
            case "DOCS":
                var gridName = "tblDOCProcessed";
                var dataRoute = `/DOC/GetProcessedDocsExt/${data.ID}?grid=${gridName}`;
                var actions = [];
                var customContent = undefined;
                var label = undefined;
                actions.push(
                    {
                        group: 'actions',
                        name: 'Stampa',
                        icon: <Printer />,
                        action: this.printDocument,
                        condition: r => r.length === 1 && this.grdDocuments.current.getRowData(r[0]).FIDStatus === 3
                    }
                );

                if(printDoc){
                    var key = `ID=${printDoc}&showAmounts=${showAmounts}`;
                    var printUrl = `/DOC/PrintDocument/?ID=${printDoc}&showAmounts=${showAmounts}`;
                    
                    if (showAmounts) {
                        key += `&hideDiscounts=${hideDiscounts}`
                        printUrl += `&hideDiscounts=${hideDiscounts}`
                    }
                    
                    customContent = <iframe key={key} src={`https://docs.google.com/gview?url=${window.location.origin}${printUrl}&embedded=true`} type="application/pdf" width="100%" height={document.body.offsetHeight - 241} />;
                    actions = [
                        {
                            group: 'actions',
                            name: 'Indietro',
                            icon: <ArrowLeft />,
                            action: this.cancelPrint
                        }
                    ];
                    label=<Grid item xs={12} container>
                        <Grid item xs={12} container direction="row" spacing={2}>
                            <Grid item xs={12} md={3}><XSwitch label="Mostra importi" color="default" id="ShowAmounts" value={showAmounts} onChange={this.onUpdateShowAmounts} /></Grid>
                            {showAmounts ? <Grid item xs={12} md={3}><XSwitch label="Nascondi sconti" color="default" id="HideDiscounts" value={hideDiscounts} onChange={this.onUpdateHideDiscounts} /></Grid> : <Fragment />}
                        </Grid>
                    </Grid>;
                }



                content = <XGrid
                    innerRef={this.grdDocuments}
                    key={gridName}
                    //containerID={`gridExpandContainer_${data.ID}`}
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    dataRouteDelete={'/DOC/DeleteDocument/'}
                    onDoubleClick={this.handleGridDOCDoubleClick}
                    onEditButton={this.handleGridDOCDoubleClick}
                    showColumnsSelector={true}
                    showFilterActivator={true}
                    label={label}
                    customContent={customContent}
                    actions={actions}
                />;
                break;
            case "STOCK":
                var urlData = "/BOP/GetBopsStock?grid=tblBOPStock";
                urlData += `&IsRepair=true&FIDDocument=${data.ID}`;

                content = <XGrid 
                    key={docview}
                    //label={label}
                    innerRef={this.grdStock}
                    usePrimaryColor={false}
                    dataRoute={urlData}
                    dataRouteColumns="/Base/GetColumns?grid=tblBOPStock"
                    dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblBOPStock"
                    dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblBOPStock"              
                    onDoubleClick={this.handleGridStockDoubleClick}
                    onEditButton={this.handleGridStockDoubleClick}
                    //customContent={customContent}
                    //actions={actions}
                    //showColumnsSelector={showColumnsSelector}
                    //showFilterActivator={showFilterActivator}
                    cardWidth={4}
                    cardHeight={4.5}
                    showChangeView={true}
                    width={this.props.width}
                    showLoader
					showImgColumn={true}
                />;
                break;
        }
        
        var actionConfirmOnPrint = {
            group: 'actions',
            name: 'Conferma',
            action: this.confirmCash,
            icon: <Check />
        };
        var actionCancelPrint = {
            group: 'actions',
            name: 'Modifica',
            action: this.modifyDocument,
            icon: <Pencil />
        };
        var actionGenerateCash = {
            group: 'actions',
            name: 'Genera scontrino',
            action: this.generateCash,
            icon: <Receipt />
        };
        
        var direction = "right";
        if (["xs"].includes(width)) {
            direction = "down";
        }
        var actions=[];
        //!confirmed && actions.push(actionConfirmOnPrint);
        actions.push(exitAction);
        actions.push(actionCancelPrint);
        data.FIDCausal !== 36 && actions.push(generateDDTAction);
        data.FIDCausal === 36 && actions.push(actionGenerateCash);
        var navActions= [];
        navActions.push({ name: getTranslation(4,"DOC_REPAIR",this.props.translations,"Riparazione"), icon: <TextBoxSearch />, action: this.changeDocView("REPAIR"), selected: docview === "REPAIR" });
        navActions.push({ name: getTranslation(4,"DOC_DOCUMENTS",this.props.translations,"Documenti collegati"), icon: <TextBoxSearch />, action: this.changeDocView("DOCS"), selected: docview === "DOCS" });
        navActions.push({ name: getTranslation(4,"DOC_STOCK",this.props.translations,"Articoli"), icon: <TextBoxSearch />, action: this.changeDocView("STOCK"), selected: docview === "STOCK" });

        var label = (
            <span>
                <span style={{marginTop:8, display: "inline-block"}}><XNavigation actions={navActions} direction={direction} showLabel /></span>
                {docview === "REPAIR" && <span style={{ display: "inline-block", position: "relative", bottom: 8}}><XSwitch label="Mostra importi" id="ShowAmounts" value={showAmounts} color="default" onChange={this.onUpdateShowAmounts} /></span>}
            </span>
         );

         return { content, label, actions };
    }
    
	cancelPrint = () => {
		this.setState({
			printDoc: undefined
		});
	}
	printDocument = () => {
		var printDoc = this.grdDocuments.current.getSelectedData()[0];
		this.grdDocuments.current.clearSelection();
		if (printDoc) {
			this.setState({
				printDoc
			});
		}
    }
    
    onRelationChange = (id) => {
        this.setState(state => {
            state.data.FIDIRRelation = id;
            state.customActions=[];
            state.showSearch= false
            return state;
        },this.CashIR.current.loadData);
    }
    
    onUpdateShowAmounts = (id) => (event,value) => {
        this.setState({showAmounts: value});
    }
	onUpdateHideDiscounts = (id) => (event, value) => {
		this.setState({ hideDiscounts: value});
	}
    render() {
        var { classes, width } = this.props;
        var { data, FIDIdentity, showIdentitySelector,showSearch,closeSearch, view,showPayment, printReceipt, printingReceipt, 
            isLoading, printPreview,docIRFilter, showIdentityEditor, paymentStep,productsStep, customActions, setDueDate, 
            showPrivacy,showSupplierSelect, showAmounts, docview } = this.state;
        if(isLoading){
            return <XOXLoader />;
        }
        if(!view && data.FIDCycle === 3) {
            view = "BOPSTOCK";
        }
        else if(!view && data.FIDCycle === 2){
            view = "DOCS";
        }

        data.FIDIdentity = FIDIdentity;
        
        var confirmed = data.FIDStatus === 3;
        if(confirmed) {
            printPreview = true;
        }
        var onlyPieces = false;
        var isReceiveDoc = true;
        if(data.FIDCycle === 2){
            isReceiveDoc = false;
        }
        
        var IDFilter = [35,36];
        if(data.FIDCycle === 3)
        {
            IDFilter = [32,33,34];
        }

        var content = undefined;

        var confirmAction = {
            group: 'actions',
            name: 'Conferma',
            icon: <Check />,
            action: this.onConfirm
        };
        var exitAction = {
            group: 'actions',
            name: 'Esci',
            icon: <ExitToApp />,
            action: this.onExitPage
        };
        var payAction = {
            group: 'actions',
            name: 'Pagamento',
            icon: <CreditCardOutline />,
            action: this.onPay
        };
        var printAction = {
            group: 'actions',
            name: 'Stampa',
            icon: <Printer />,
            action: this.onPrint
        };
        var printReceiptAction = {
            group: 'actions',
            name: 'Scontrino',
            icon: <PrinterPos />,
            action: this.onPrintReceipt
        }
        var generateDDTAction = {
            group: 'actions',
            name: 'Genera DDT',
            icon: <AlphaDcircle />,
            action: this.generateDDT
        };

        var actions = [];
        actions.push(exitAction);
        !confirmed && actions.push(confirmAction);
        confirmed && actions.push(printAction);
        //!confirmed && !this.props.isReturnRepair && actions.push(newRowAction);
        confirmed && !this.props.isReturnRepair && data.FIDRepairSupplier && data.FIDCausal !== 36 && actions.push(generateDDTAction);

        if(customActions && customActions.length > 0){
            actions = customActions;
        }
        var label = undefined;
        if(setDueDate){
            
            content = <div className={classes.editor}>
                <CashDateEditor fieldLabel={"Data scadenza"} id={"dueDate"} value={this.state.dueDate} onSubmit={this.onDateEditorSubmit}/>
            </div>;
        }
        else if(showSearch) {
            actions = [];
            //!confirmed && !this.props.isReturnRepair && actions.push(newRowAction);
            actions.push({
                group: 'actions',
                name: 'Indietro',
                icon: <ArrowLeft />,
                action: closeSearch
            });

            
            var navActions = [];
            data.FIDCycle === 3 && navActions.push({ name: getTranslation(4,"DOC_CATALOG",this.props.translations,"Catalogo"), icon: <Ring />, action: this.changeView("BOPS"), selected: view === "BOPS" });
            data.FIDCycle === 3 && navActions.push({ name: getTranslation(4,"DOC_HISTORICSTOCK",this.props.translations,"Storico"), icon: <Ring />, action: this.changeView("BOPSTOCK"), selected: view === "BOPSTOCK" });
            navActions.push({ name: getTranslation(4,"DOC_DOCUMENTS",this.props.translations,"Documenti"), icon: <TextBoxSearch />, action: this.changeView("DOCS"), selected: view === "DOCS" });

            var direction = "right";
            if (["xs"].includes(width)) {
                direction = "down";
            }
            var label = <span><XNavigation actions={navActions} direction={direction} showLabel={true} /></span>;


            if(data.FIDCausal === 36) {
                var style= {
                    display: 'inline-block',
                    verticalAlign: 'top'
                };
                var fieldShowAll = <XStepper label="Anagrafica" step={docIRFilter} steps={[{label:"Assegnata"},{label:"Non assegnata"},{label: "Tutti"}]} onStepClick={this.onDOCIRFilterChange} />;
                label = <Fragment><span style={style}>{label}</span><span style={style}>{fieldShowAll}</span></Fragment>
            }
            
        }
        else if(showPayment){
            var actionPaymentBack = 
            {
                group: 'actions',
                name: 'Indietro',
                icon: <ArrowLeft />,
                action: this.onPaymentBack
            };
            var actionPaymentConfirm = 
            {
                group: 'actions',
                name: 'Conferma pagamento',
                icon: <Check />,
                action: this.onPaymentConfirm
            };
            actions = [];
            if(paymentStep === 3){
                actions.push(actionPaymentConfirm);
            }
            if(paymentStep === 1){
                actions.push(confirmAction);
            }
            actions.push(actionPaymentBack);
        }
        else if(printReceipt){
            content= <PrintReceipt innerRef={this.PrintReceipt} FIDLicensee={data.FIDOwner} FIDDestination={data.FIDOwnerDestination} FIDDocument={data.ID}  printing={printingReceipt}/>;
            actions = [];
            var actionPrintReceipt = {
                group: 'actions',
                name: 'Stampa scontrino',
                action: this.onPrintFirstReceipt,
                icon: <PrinterPos />
            };
            var actionReprintReceipt = {
                group: 'actions',
                name: 'Ristampa scontrino',
                action: this.onReprintReceipt,
                icon: <PrinterPos />
            };
            var actionGiftReceipt = {
                group: 'actions',
                name: 'Scontrino di cortesia',
                action: this.onPrintGiftReceipt,
                icon: <Gift />
            };
            var actionCancelPrintReceipt = {
                group: 'actions',
                name: 'Indietro',
                action: this.onCancelPrintReceipt,
                icon: <ArrowLeft />
            };
            if(!printingReceipt){
                actions.push(actionGiftReceipt);
                {!data.ProtocolNumber && actions.push(actionPrintReceipt)}
                {data.ProtocolNumber && actions.push(actionReprintReceipt)}
                actions.push(actionCancelPrintReceipt);
            }
        }
        else if(printPreview) {
            //content = <embed src={`/DOC/PrintDocument/?ID=${data.ID}`} type="application/pdf" width="100%" height={} />;
        }


        if(printPreview){
            var printPreview = this.renderPrintPreview(exitAction,generateDDTAction);
            content = printPreview.content;
            label = printPreview.label;
            actions = printPreview.actions;
        }
        else if(printReceipt){

        }
        else {
            label = (
                <Tabs
                    value={this.state.tab}
                    onChange={this.handleChangeTab}
                    variant="scrollable"
                    scrollButtons="on"
                    className={classes.tab}
                >
                    <Tab
                        key={"General"}
                        label={"Generale"}
                    />
                </Tabs>
            );
            content = <Grid container spacing={2} className={classes.panelContainer}>
                <Grid xs={12} md={9} item className={classes.leftPanel} id="leftPanel">
                    {!showPayment && <CashProducts innerRef={this.Products} confirmed={confirmed} isRepair onRequestActions={this.onProductsonRequestActions} onSearchShowChange={this.onSearchShowChange} onProductsChange={this.onProductsChange} searchView={view} doc={data} confirmed={confirmed} IRFilter={docIRFilter}/>}
                    {showPayment && <CashPayment confirmed={confirmed} innerRef={this.Payment} doc={data} onStepChange={this.onPaymentStepChange}/>}
                </Grid>
                <Grid xs={12} md={3} item className={classes.rightPanel}>
                    <RepairCausal confirmed={confirmed} CausalsFilter={IDFilter} FIDDocumentType={data.FIDDocumentType} FIDDocumentSubtype={data.FIDDocumentSubtype} FIDCycle={data.FIDCycle} FIDCausal={data.FIDCausal} onCausalChange={this.onCausalChange} />
                    <CashIR innerRef={this.CashIR} doc={data} isRepair={true} FIDIR={FIDIdentity} onChangeRequest={this.onChangeIRRequest} onClearIR={this.onClearIR} onEditIR={this.onEditIR} onEditPrivacyIR={this.onEditPrivacyIR} confirmed={confirmed} onDataLoaded={this.onIRLoaded} />
                    {FIDIdentity && <CashRelationSelector confirmed={confirmed} doc={data} FIDIRRelation={data.FIDIRRelation} onRelationChange={this.onRelationChange}/> }
                    {confirmed && <CashIR innerRef={this.CashIRSupplier} doc={data} FIDIR={data.FIDRepairSupplier} onChangeRequest={this.onChangeIRSupplierRequest} onClearIR={this.onClearIRSupplier} label={"Riparatore"} genericLabel={"Riparatore non selezionato"} clearLabel={"TOGLI"} />}
                    <CashTotals confirmed={confirmed} innerRef={this.CashTotals} doc={data}  confirmed={confirmed} onlyTotalPieces={onlyPieces}/>
                </Grid>
            </Grid>;
        }


        if(showIdentitySelector){
            content = <IdentitySelector onIdentitySelected={this.onIdentitySelect} onCancel={() => { this.setState({ showIdentitySelector: false }) }} showIncompleteSelector={true} />;
            actions= undefined;
        }
        else if(showIdentityEditor){
            content = <IREdit id={FIDIdentity} innerRef={this.IREdit} topMargin="0px" headerColor="info" noRedirect={true} showExit={true} showSave={true} showDelete={false} showNew={false} onExit={this.onEditIRExit} onSave={this.onSaveIR} showPrivacy={showPrivacy} />;
            
            actions=[];
        }
        else if(showSupplierSelect){
            content = <IdentitySelector onIdentitySelected={this.onSupplierSelect} onCancel={() => { this.setState({ showSupplierSelect: false }) }} showIncompleteSelector={false} />;
            actions= undefined;
        }

        return <Fragment>
                <XGrid
                label={label}
                actions={actions}
                usePrimaryColor={true}
                customContent={content}
            />
            <XMessageBox innerRef={this.XMessage} />
        </Fragment>
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(RepairEditor);