import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';
import CashRow from './CashRow';
import CashCell from './CashCell';
import XButton from '../../../components/XButton';

const styles = theme => ({
    root: {

    },
    cashRegisterSelector: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#fff',
        zIndex: 1,
        margin: 8,
    },
    button: {
        width: '100%',
    }
});


class CashRegisterSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:[],
            showCashRegistersSelector: false,
        }
    }

    componentDidMount() {
        this.loadData();
    }
    loadData = async () => {
        var { FIDLicensee,FIDDestination } = this.props;
        var registers = await fetch(`/cash/GetCashRegistersSelect?FIDLicensee=${FIDLicensee}&FIDDestination=${FIDDestination}`).then(res => res.json());
        
        var data = registers.filter(item => item.FIDLicensee === FIDLicensee && item.FIDLicenseeDestination === FIDDestination);
        this.setState({data});
    }
    onCashRegisterClick = () => {
        this.setState({showCashRegistersSelector: true});
    }
    onCancelClick = () => {
        this.setState({showCashRegistersSelector: false});
    }
    onCashRegisterSelection = (id) => () => {
        var { onCashRegisterChange } = this.props;
        onCashRegisterChange && onCashRegisterChange(id);
        this.setState({showCashRegistersSelector: false});
    }
    render() {
        var { FIDCashRegister, classes, confirmed } = this.props;
        var { data, showCashRegistersSelector } = this.state;

        var cashRegister = data.filter(item => item.ID === FIDCashRegister)[0];
        if(showCashRegistersSelector){
            return (
                <div className={classes.cashRegisterSelector}>
                    {data && data.map(item => <CashRow key={item.ID} onClick={this.onCashRegisterSelection(item.ID)}><CashCell>{item.Denomination}</CashCell></CashRow>)}
                    <CashRow>
                        <CashCell width={"100%"} totalWidth={"100%"}>
                            <XButton className={classes.button} onClick={this.onCancelClick}>CHIUDI</XButton>
                        </CashCell>
                    </CashRow>
                </div>
            );
        }
        return <CashRow>
            <CashCell label="Registratore" width="100%" key={cashRegister && cashRegister.ID || 0} onClick={!confirmed && this.onCashRegisterClick}>{cashRegister && cashRegister.Denomination || '...'}</CashCell>
        </CashRow>;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(CashRegisterSelector);