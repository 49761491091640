import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';

const style = () => ({
});

class Line extends Component {
    constructor(props) {
        super(props);
        //this.setState({ FIDParentColumnName: "Brand" });
        this.Editor = React.createRef();
    }
    state = {
        line: { FIDParent: 0 },
    };
    checkUsed = async (FIDLine) => {
        return await fetch('/BOP/LineIsUsed/' + FIDLine).then(res => res.json());
        
    }
    deleteLine = (FIDLine) => {
        fetch('/BOP/LineDelete/' + FIDLine, { method: "POST" }).then(res => {
            this.Editor.current.doExit();
        });
    }
    onDeleteClick = () => {
        var id = this.props.match.params.id;

        var isUsed = this.checkUsed(id);

        var label = "Confermi la cancellazione della linea?";
        if (isUsed) {
            label = "La linea è utilizzata nelle distinte oppure ha delle Serie collegate. Continuando verranno cancellato le Serie collegate e sulle distinte veranno tolte le informazioni su Linea e Serie. " + label;
        }

        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.Editor.current.hideDialog();
                    this.deleteLine(id);
                }
            },
            {
                label: "NO",
                action: () => {
                    this.Editor.current.hideDialog();
                }
            },
        ];

        this.Editor.current.showDialog(buttons, "Cancellazione", label);
        
    }
    render() {
        var { line } = this.state;

        var fields = this.props.userID === -1990 ? [
            {
                key: 'FIDBrand',
                label: 'Brand',
                type: 'XSelect',
                getUrl: '/Base/GetBOPBrands',
                saveUrl: undefined,
                idKey: 'ID',
                valueKey: 'Denomination',
                abbreviationKey:'Abbreviation',
                Required: 2
            },
			{
				key: "Deactivated",
				label: getTranslation(5,"GC_DEACTIVATED", this.props.translations, "Disattivato") || "Disattivato",
				type: "XSwitch"
			},
        ] : [
            {
                key: 'FIDBrand',
                label: 'Brand',
                type: 'XSelect',
                getUrl: '/Base/GetBOPBrands',
                saveUrl: undefined,
                idKey: 'ID',
                valueKey: 'Denomination',
                abbreviationKey:'Abbreviation',
                Required: 2
            }
        ];

        return <GenericEditor
            innerRef={this.Editor}
            id={this.props.match.params.id}
            //FIDParentColumnName='FIDBrand'
            //FIDParentColumnCaption='Brand'
            //urlGetParents='Base/GetBOPBrands'
            urlSave='/BOP/SaveLine/'
            urlDelete='/BOP/LineDelete/'
            urlRedirect='/Line/'
            urlUploadFiles='/BOP/LineFileUpload/'
            urlDeleteFiles='/BOP/DeleteLineFile/'
            urlGetFiles='/BOP/GetLineFiles/'
            urlCheckDefaultFiles='/BOP/CheckDefaultLineFiles/'
            urlExit='/Lines/'
            urlNew="/line/new"
            urlGetData='/BOP/GetLine/'
            urlCheckChanges='/BOP/LineHasChanges/'
            urlClearSession='/BOP/ClearSession/'
            onDeleteClick={this.onDeleteClick}
            fields={fields}
            translate={true}
            routeTranslatableFields="/bop/line/translatableFields/"
            routeLanguages="/bop/line/languages/"
            routeDataT="/bop/line/translations/"
            routeSaveData="/bop/line/savet/"
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(Line);
