import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const style = () => ({
});

class Store extends Component {
    constructor(props) {
        super(props);
        this.Editor = React.createRef();
    }
    checkUsed = async (FIDStore) => {
        return await fetch('/gc/stores/IsUsed/' + FIDStore).then(res => res.json());
        
    }
    deleteStore = (FIDStore) => {
        fetch('/gc/stores/Delete/' + FIDStore, { method: "POST" }).then(res => {
            this.Editor.current.doExit();
        });
    }
    onDeleteClick = async() => {
        var id = this.props.match.params.id;

        var isUsed = await this.checkUsed(id);

        var label = "Confermi la cancellazione dello store?";
        if (isUsed) {
            label = "Lo store utilizzato nelle distinte e ha delle store area abbinate. Continuando verranno rimosse dalle distinte le informazioni relative allo store e le store area abbinate. " + label;
        }

        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.Editor.current.hideDialog();
                    this.deleteStore(id);
                }
            },
            {
                label: "NO",
                action: () => {
                    this.Editor.current.hideDialog();
                }
            },
        ];

        this.Editor.current.showDialog(buttons, "Cancellazione", label);
        
    }
    render() {
		
		var fields = [
            {
                key: 'FIDPlatform',
                label: 'Piattaforma eCommerce',
                type: 'XSelect',
                getUrl: '/gc/platform/?eCommerce=true',
                saveUrl: undefined,
                idKey: 'ID',
                valueKey: 'Denomination',
                abbreviationKey:'Abbreviation',
                Required: 1,
                subtitleKey:'Url'
            }
		];
		
        return <GenericEditor
            innerRef={this.Editor}
            id={this.props.match.params.id}
			fields={fields}
            urlSave='/gc/stores/Save/'
            urlDelete='/gc/stores/Delete/'
            urlRedirect='/store/'
            urlUploadFiles='/gc/stores/FileUpload/'
            urlDeleteFiles='/gc/stores/DeleteFile/'
            urlGetFiles='/gc/stores/GetFiles/'
            urlCheckDefaultFiles='/gc/stores/CheckDefaultFiles/'
            urlExit='/stores/'
            urlNew="/store/new"
            urlGetData='/gc/stores/'
            //urlCheckChanges='/BOP/ShopAreaHasChanges/'
            urlClearSession='/BOP/ClearSession/'
            onDeleteClick={this.onDeleteClick}
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(Store);
