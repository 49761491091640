import React from 'react';
import { FormControl, MenuItem, Typography, Select } from '@material-ui/core';
import { useAppState } from '../../../../state';
import { useAudioOutputDevices } from '../deviceHooks/deviceHooks';
import DeviceInput from 'views/Videochat/DeviceInput';

export default function AudioOutputList() {
  const audioOutputDevices = useAudioOutputDevices();
  const { activeSinkId, setActiveSinkId } = useAppState();
  const x = audioOutputDevices.find(device => device.deviceId === activeSinkId);
  const activeOutputLabel = x ? x.label : undefined;

  var audioOutputs: MediaDeviceInfo[] = [];
  if(audioOutputDevices.length > 1)
  {
    var alreadyUsedinputGroups: string[] = [];
    audioOutputDevices.forEach(device => {
      if(alreadyUsedinputGroups.findIndex(group => group === device.groupId) === -1)
      {
        alreadyUsedinputGroups.push(device.groupId);
        audioOutputs.push(device);
      }
    });
  }
  const onDeviceSelected = (device:MediaDeviceInfo) => {
    setActiveSinkId(device.deviceId);
  }
  return (
    <div className="inputSelect">
      {audioOutputs.length >= 1 ? (
        <FormControl fullWidth>
          {audioOutputs.map(device => (<DeviceInput key={device.deviceId} device={device} onDeviceSelected={onDeviceSelected} selected={activeSinkId === device.deviceId} type="AUDIO_OUTPUT"/>))}
{/*             
          <Select onChange={e => setActiveSinkId(e.target.value as string)} value={activeSinkId}>
            {audioOutputs.map(device => {
                console.log('adding audio output. ID:['+device.deviceId+'] - label:['+device.label+'] - groupId:['+device.groupId);
              return(
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            )})}
          </Select> */}
        </FormControl>
      ) : (
        <>
          <Typography>Dispositivi audio non disponibili</Typography>
        </>
      )}
    </div>
  );
}
