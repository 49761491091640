import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Checkbox, IconButton, List, ListItem, ListItemText } from '@material-ui/core';

const useStyles = makeStyles(theme =>
    createStyles({
        cardContainer: {
            display: 'flex',
            minWidth: '500px',
            margin: '6px',
        },
        productConditions: {
            display: 'flex',
            margin: '6px',
        },
        card: {
            minHeight: '30px',
            minWidth: '500px',
            border: '1px solid gray',
            borderRadius: '15px',
            margin: '6px',
            backgroundColor: "white",
        },
        cardContent: {
            margin: '12px',
        },
        button: {
            margin: '6px',
            backgroundColor: "#e2b231",
            borderRadius: '15px'
        }
    }));

function CreditCardInsertImpl(props) {
    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();
    const [registered, setRegistered] = useState(false);
    const customerId = props.customerId;
    let [errorToDisplay, setErrorToDisplay] = useState('');
    const [acceptedProducts, setAcceptedProducts] = useState([])
    const [products, setProducts] = useState(undefined)

    useEffect(() => {
        fetch('/Payments/GetAvailableProducts')
        .then(res=>res.json())
        .then(result => {setProducts(result.products)})
    }, [])

    function handlePaymentThatRequiresCustomerAction({
        subscription,
        invoice,
        priceId,
        paymentMethodId,
        isRetry,
    }) {
        if (subscription && subscription.status === 'active') // subscription is active, no customer actions required.
            return { subscription, priceId, paymentMethodId };

        // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
        // If it's a retry, the payment intent will be on the invoice itself.
        let paymentIntent = invoice
            ? invoice.payment_intent
            : subscription.latest_invoice.payment_intent;

        if (paymentIntent.status === 'requires_action'
            || (isRetry === true
                && paymentIntent.status === 'requires_payment_method')) {
            return stripe.confirmCardPayment(paymentIntent.client_secret, {
                payment_method: paymentMethodId,
            })
                .then((result) => {
                    if (result.error) {
                        // start code flow to handle updating the payment details
                        // Display error message in your UI.
                        // The card was declined (i.e. insufficient funds, card has expired, etc)
                        throw result;
                    }
                    else {
                        if (result.paymentIntent.status === 'succeeded') {
                            // There's a risk of the customer closing the window before callback
                            // execution. To handle this case, set up a webhook endpoint and
                            // listen to invoice.payment_succeeded. This webhook endpoint
                            // returns an Invoice.
                            return {
                                priceId: priceId,
                                subscription: subscription,
                                invoice: invoice,
                                paymentMethodId: paymentMethodId,
                            };
                        }
                    }
                });
        }
        else// No customer action needed
            return { subscription, priceId, paymentMethodId };
    }

    function onSubscriptionComplete(result) {
        console.log(result);
        // Payment was successful. Provision access to your service.

        if (result && !result.subscription) {
            const subscription = { id: result.invoice.subscription };
            result.subscription = subscription;
        }

        setRegistered(true);
        props.onSavedCard(result.paymentMethodId)
    }

    function createSubscription({ paymentMethodId }) {
        const options = {
                    customerId: customerId,
                    paymentMethodId: paymentMethodId,
                    acceptedProducts: [...acceptedProducts]
                    };
        return (
            //fetch(`/Payments/CreateSubscription?customerId=${customerId}&paymentMethodId=${paymentMethodId}`)
            fetch(`/Payments/CreateSubscription`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(options)
              })
                .then(response => response.json())
                .then((result) => {// If the card is declined, display an error to the user.
                    console.log(result);
                    if (result.error || result.stripeError)
                        throw result;// The card had an error when trying to attach it to a customer
                    return result;
                })
                .then((result) => {// Normalize the result to contain the object returned
                    return {// by Stripe. Add the addional details we need.
                        subscription: result,
                        paymentMethodId: paymentMethodId,
                    };
                })
                // Some payment methods require a customer to do additional
                // authentication with their financial institution.
                // Eg: 2FA for cards.
                .then(handlePaymentThatRequiresCustomerAction)
                .then(onSubscriptionComplete)// No more actions required. Provision your service for the user.
                .catch((error) => {// An error has happened. Display the failure to the user here.
                    console.log(error);
                    setErrorToDisplay(error.message || error.error.decline_code);
                })
        );
    }
    const handleSubmit = async (event) => {
        event.preventDefault();// Block native form submission.

        if (!stripe || !elements)// Stripe.js has not loaded yet. Make sure to disable
            return;              // form submission until Stripe.js has loaded.

        if(acceptedProducts.length <= 0)
        {
            setErrorToDisplay("You have to accept at least one product");
            return;   
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({// Use your card Element with other Stripe.js APIs
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.log('[createPaymentMethod error]', error);
            setErrorToDisplay(error && error.message);
        }
        else {
            console.log('[PaymentMethod]', paymentMethod);
            const paymentMethodId = paymentMethod.id;
            createSubscription({ paymentMethodId: paymentMethodId });// Create the subscription
        }
    };

    const toggleAcceptedCondition = (condition) => {
        if(acceptedProducts.findIndex(check => check === condition.key) === -1)
            setAcceptedProducts([...acceptedProducts, condition.key])
        else
            setAcceptedProducts(acceptedProducts.filter(check => check !== condition.key))
    }
    const renderCondition = (condition) => {
        return (
            <ListItem key = {condition.key}>
                <Checkbox
                    checked={acceptedProducts.findIndex(check => check === condition.key) !== -1}
                    onClick={() => {toggleAcceptedCondition(condition)}}
                />
                <ListItemText>{condition.name}</ListItemText>
            </ListItem>
        )
    }

    return (
        <div>
            <div className={classes.productConditions}>
                {products && 
                <List dense>
                    {products.map(condition => renderCondition(condition))}
                </List>
                }
            </div>
            <div className={classes.cardContainer}>
                <Typography className={classes.cardContent} variant="h6">Card details:</Typography>
                <div className={classes.card}
                    id="card-element">
                    <CardElement className={classes.cardContent}
                        options={{
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#32325d',
                                    fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
                                    '::placeholder': { color: '#a0aec0', },
                                },
                                invalid: { color: '#9e2146', },
                            },
                        }}
                    />
                </div>
                <Button id="register"
                    className={classes.button}
                    onClick={handleSubmit}
                    disabled={!stripe || !elements || registered}>
                    Register
                </Button>
            </div>
            <div className="text-gray-700 text-base mt-2" role="alert">
                {errorToDisplay ? errorToDisplay : null}
            </div>
        </div>
    )
}

export default function CreditCardInsert(props)
{    
    return (
        <div>        
            <Elements stripe={loadStripe(props.stripeSecret)}>
                <CreditCardInsertImpl customerId={props.customerId}
                                        onSavedCard={props.onSavedCard}/>
            </Elements>
        </div>
    )
}