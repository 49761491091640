import React, {useState, useEffect} from 'react';
import Tabs from "../../../components/CustomTabs/CustomTabs.jsx";
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import TwilioUsageList from "./TwilioUsage";
import GoogleTranslateUsageList from "./GoogleTranslateUsage";
import UsageTab from "./UsageTab";
import WhatsAppUsageList from "./WhatsAppUsage"
import SMSUsageList from "./SMSUsage"

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
}


export default function BillingView()
{
    const renderTabXLive = () => {
        const usageList = props => <TwilioUsageList {...props}/>
        return (<TabContainer>
                    <UsageTab
                        url="/XLIVE/GetTwilioUsage"
                        usageList={usageList}/>
                </TabContainer>)
    }
    const renderTabTranslations = () => {
        const usageList = props => <GoogleTranslateUsageList {...props}/>
        return (<TabContainer>
                    <UsageTab
                        url="/Translate/GetUsage"
                        usageList={usageList}/>
                </TabContainer>)
    }
    const renderTabSMS= () => {
        const usageList = props => <SMSUsageList {...props}/>
        return (<TabContainer>
                    <UsageTab
                        url="/SMS/GetUsage"
                        usageList={usageList}/>
                </TabContainer>)
    }
    const renderTabWhatsApp= () => {
        const usageList = props => <WhatsAppUsageList {...props}/>
        return (<TabContainer>
                    <UsageTab
                        url="/WhatsApp/GetUsage"
                        usageList={usageList}/>
                </TabContainer>)
    }

    var loctabs = [
        {
            tabName: "XLive",
            tabContent: renderTabXLive()
        },
        {
            tabName: "Translations",
            tabContent: renderTabTranslations()
        },
        {
            tabName: "SMS",
            tabContent: renderTabSMS()
        },
        {
            tabName: "WhatsApp",
            tabContent: renderTabWhatsApp()
        },
    ];

    return (
        <Tabs
            headerColor="primary"
            plainTabs={true}
            tabs={loctabs}
        />
    )
}