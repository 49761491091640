import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';
import classNames from 'classnames';
import { Pencil } from 'mdi-material-ui';

const styles = theme => ({
    
    cell: {
        fontSize: props => props.fontSize || 20,
        position: 'relative',
        display: 'inline-block',
        verticalAlign: props => !!props.label ? 'top' : 'middle',
        padding: props => props.padding,
        width: props => props.width,
        cursor: props => props.onClick ? "pointer" : "inherit",
        fontWeight: props => props.bold ? "bold" : "inherit",
        '&:last-child': {
            width: props => `calc(100% - ${(props.totalWidth - props.width)}px)`,
        },
        '&::before': {
            display: 'block',
            content: 'attr(data-label)',
            fontSize: props => props.labelFontSize || 15,
        }
    },
    error: {
        color: theme.palette.error.main
    },
    icon : {
        //float: 'right',
        margin: '0 10px',
        color: '#616161',
        position: 'absolute',
        top: 0,
        right: 0,
        fontSize: props => props.labelFontSize + 2 || 17,
        cursor: 'pointer'
    }
});



const CashCell = withStyles(styles)(({classes, label, children,error,onClick, ...props}) => {
    var icon = undefined;
    if(onClick){
        icon = <Pencil className={classes.icon}/>;
    }
    return <span className={classNames(classes.cell, {[classes.error]: error})} data-label={label} onClick={onClick} {...props}>{children} {icon}</span>;
});
export default CashCell;