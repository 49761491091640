import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles, withWidth } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import FilterListIcon from '@material-ui/icons/FilterList';
import ColumnsSelector from './ColumnsSelector';
import XNavigation from 'components/XNavigation';
import { ViewList, ViewGrid, ViewHeadline, FileExcelOutline, ViewColumn, HexagonOutline } from 'mdi-material-ui';
import { Filter, FilterRemove } from 'mdi-material-ui';
import { Tabs, Tab } from '@material-ui/core';
import { RefreshRounded } from '@material-ui/icons';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
        minWidth: "100%",
        minHeight: 85
    },
    primaryColor: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    secondaryColor: {
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                //color: theme.palette.secondary.main,
                //backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                //color: theme.palette.text.primary,
                //backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 30%',
    },
    actions: {
        "& span": {
            display: "inline-flex"
        },
        // color: theme.palette.text.secondary,
        //width: "100%",
        textAlign: "right",
        position: "absolute",
        right: 8,
    },
    title: {
        flex: '0 0 auto',
        minWidth: 370,
    },
    table: {
        minWidth: "100%",
    },
    changeViewClose: {
        marginLeft: -170,
        transition: "margin .3s",
    },
});

class EnhancedTableToolbar extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        changeViewClose: true
    };
    handleColumnsButton = (event) => {
        const { onColumnsButton } = this.props;
        if (onColumnsButton) {
            onColumnsButton(event);
        }
    };
    handleFilterButton = (event) => {
        const { onFilterButton } = this.props;
        if (onFilterButton) {
            onFilterButton(event);
        }
    };
    handleAdvancedFilterButton = (event) => {
        const { onAdvancedFilterButton } = this.props;
        if (onAdvancedFilterButton) {
            onAdvancedFilterButton(event);
        }
    };
    handleClearFilterButton = (event) => {
        const { onClearFilterButton } = this.props;
        if (onClearFilterButton) {
            onClearFilterButton(event);
        }
    };
    handleDeleteButton = (event) => {
        const { onDeleteButton } = this.props;
        if (onDeleteButton) {
            onDeleteButton(event);
        }
    };
    handleEditButton = (event) => {
        const { onEditButton } = this.props;
        if (onEditButton) {
            onEditButton(event);
        }
    };
    handleNewButton = (event) => {
        const { onNewButton } = this.props;
        if (onNewButton) {
            onNewButton(event);
        }
    }
    handleChangeViewOpen = () => {
        this.setState({
            changeViewClose: false
        });
    };
    handleChangeViewClose = () => {
        this.setState({
            changeViewClose: true
        });
    };

    render() {
      const { numSelected, classes, grid, selectedAll, count, showDeleteButton, onAdvancedFilterButton, onClearFilterButton, onFilterButton, onExcelExportButton, onEditButton, label, additionalLabel, showFilterActivator, showColumnsSelector, showChangeViewButton, onChangeView,onDeleteButton, onRefresh } = this.props;
      var { selectedActions, usePrimaryColor, useSecondaryColor, tabs, selectedTab, onTabChange, viewType, actionsInverted, actions, hasCustomContent, hideRefreshBtn, changeViewHideCard } = this.props;

        var actionCount = label ? 1 : 0;
        var navActions = [];
        var direction = "left";
        var actionsChangeView = undefined;

        var changeViewClass = undefined;

        if (this.state.changeViewClose && this.props.width !== "xs")
            changeViewClass = classes.changeViewClose;

        if (showChangeViewButton && onChangeView && !hasCustomContent) {
            var xNavIcon = undefined;
            switch(viewType){
                case "grid":
                    xNavIcon = <ViewHeadline />;
                    break;
                case "list":
                    xNavIcon = <ViewList />;
                    break;
                case "card":
                    xNavIcon = <ViewGrid />;
                    break;
                default:
                    break;
            }
            navActions.push({ name: getTranslation(5,"GRID VIEW",this.props.translations, "GRID VIEW"), icon: <ViewHeadline />, action: onChangeView("grid"), selected: viewType === "grid" });
            navActions.push({ name: getTranslation(5,"GC_LISTVIEW",this.props.translations, "LIST VIEW"), icon: <ViewList />, action: onChangeView("list"), selected: viewType === "list" });
            navActions.push({ name: getTranslation(5,"GC_CARDVIEW",this.props.translations, "CARD VIEW"), icon: <ViewGrid />, action: onChangeView("card"), selected: viewType === "card", disabled: changeViewHideCard });

            actionsChangeView = <span class={changeViewClass}><XNavigation menuLabel={getTranslation(5,"GC_VIEW",this.props.translations, "Visualizzazione")} startClosed={true} autoClose={true} actions={navActions} direction={direction} onClose={this.handleChangeViewClose} onOpen={this.handleChangeViewOpen} icon={xNavIcon} /></span>;
            actionCount++;
        }

        var xNavActions = [];
        var xNavFilters = [];
		
		if (this.state.changeViewClose) {
			if(this.props.onColumnsButton) {
				xNavActions.push({ name: getTranslation(5,"GC_CONFIGCOLUMNS", this.props.translations, "Configura colonne"), icon: <ViewColumn />, action: this.handleColumnsButton, selected: false  });
				actionCount++;
			}
			if(onExcelExportButton) {
				xNavActions.push({ name: getTranslation(5,"GC_EXPORTEXCEL", this.props.translations, "Esporta in excel"), icon: <FileExcelOutline />, action: onExcelExportButton, selected: false  });
				actionCount++;
			}
		}
        if (!hideRefreshBtn && onRefresh && !hasCustomContent) {
            xNavActions.push({ name: getTranslation(5,"GC_REFRESH", this.props.translations, "Aggiorna"), icon: <RefreshRounded />, action: onRefresh, selected: false  });
            actionCount++;
        }
        if(numSelected > 0){
            if(onDeleteButton) {
                xNavActions.push({ name: getTranslation(5,"GC_DELETE", this.props.translations, "Elimina"), icon: <DeleteIcon />, action: this.handleDeleteButton, selected: false  });
                actionCount++;
            }
            if(onEditButton){
                xNavActions.push({ name: getTranslation(5,"GC_EDIT", this.props.translations, "Modifica"), icon: <EditIcon />, action: this.handleEditButton, selected: false  });
                actionCount++;
            }
        }

        if(this.props.onNewButton && numSelected === 0) {
            xNavActions.push({ name: getTranslation(5,"GC_NEW", this.props.translations, "Nuovo"), icon: <AddIcon />, action: this.handleNewButton, selected: false  });
            actionCount++;
        }
        
        if(actions){
            for(var i in actions){
                var action = actions[i];
                if(action && action.group && (!action.condition  || action.condition(grid.getSelectedData()))) {
                    switch(action.group){
                        case "actions":
                                xNavActions.push(action);
                                actionCount++;
                            break;
                        case "filters":
                                xNavFilters.push(action);
                                actionCount++;
                            break;
                    }
                }
            }
        }
        var xNavActionButton = <span><XNavigation isHidden={true} menuLabel={"Azioni"} actions={xNavActions} direction={"left"} forceDirection={true} maxItemsForce={1} icon={<HexagonOutline />} /></span>;

        if(onClearFilterButton && showFilterActivator){
            xNavFilters.push({ name: getTranslation(5,"GC_CLEARFILTERS", this.props.translations, "Pulisci filtri"), icon: <FilterRemove />, action: this.handleClearFilterButton, selected: false  });
            actionCount++;
        }
        if(onAdvancedFilterButton){
            xNavFilters.push({ name: getTranslation(5,"GC_ADVFILTERS", this.props.translations, "Filtri avanzati"), icon: <Filter />, action: this.handleAdvancedFilterButton, selected: false  });
            actionCount++;
        }
        if(onFilterButton && showFilterActivator){
            xNavFilters.push({ name: getTranslation(5,"GC_FILTERS", this.props.translations, "Filtri"), icon: <FilterListIcon />, action: this.handleFilterButton, selected: false  });
            actionCount++;
        }
        
        if (selectedActions) {
            for(var i in selectedActions){
                var action = selectedActions[i];
                if(action && action.group && (!action.condition  || action.condition(grid.getSelectedData()))) {
                    switch(action.group){
                        case "actions":
                                xNavActions.push(action);
                                actionCount++;
                            break;
                        case "filters":
                                xNavFilters.push(action);
                                actionCount++;
                            break;
                    }
                }
            }
        }
        var xNavFiltersButton = <span><XNavigation isHidden={true} menuLabel={"Filtri"} actions={xNavFilters} direction={direction} icon={<HexagonOutline />} /></span>;

        if (selectedActions) {
            selectedActions = selectedActions.map(item => {
                if (item.component) {
                    if (item.condition(grid.getSelectedData())) {
                        actionCount++;
                        return item.component;
                    }
                    return false;
                }
                else {
                    actionCount++;
                    return item;
                }
            }).filter(item => item !== false);
        }
        if(actions) {
            actions = actions.filter(item => item && !item.group).map(item => {
                if (item && item.component) {
                    if (item.condition(grid.getSelectedData())) {
                        actionCount++;
                        return item.component;
                    }
                    return false;
                }
                else {
                    actionCount++;
                    return item;
                }
            }).filter(item => item !== false);
        }

        var renderedActions = <div className={classes.actions}>
            <span>
                {//!actionsInverted && numSelected > 0 && (
                //    <Fragment>
                //        {selectedActions}
                //    </Fragment>
                //)
                }
                {actions}
                {grid.state.allColumns && showColumnsSelector && this.props.onColumnsButton && <ColumnsSelector reactGrid={grid} />}
            </span>
            {<Fragment>{xNavActionButton}</Fragment>}
            {<Fragment>{xNavFiltersButton}</Fragment>}
            {actionsChangeView && (<Fragment>{actionsChangeView}</Fragment>)}
        </div>;

        var tabsUI = undefined;
        if (tabs && tabs.length > 0) {
            tabsUI = (
                <Tabs
                    value={selectedTab}
                    onChange={onTabChange}
                    className={classes.tab}
                    plainTabs={true}
                    variant="scrollable"
                >
                    {tabs.map((prop, key) => {
                        return (<Tab key={key} label={prop.tabName} />);
                    })}
                </Tabs>
            );
				}

				var addLabel = additionalLabel ? ": " + additionalLabel : "";

        var title = <div className={classes.title}>
            {numSelected > 0 ? (
                <Typography color="inherit" variant="subtitle1">
                    {selectedAll ? count : numSelected} {(selectedAll ? count : numSelected) > 1 ? getTranslation(1, "CAT_SELECTEDROW_COUNT", this.props.translation, "righe selezionate") + addLabel : getTranslation(1, "CAT_SELECTEDROW", this.props.translation, "riga selezionata") + addLabel}
                        </Typography>
            ) : (
                    <Typography variant="body1" id="tableTitle">
                        {label}
                    </Typography>
                )}
        </div>;
        var ui = tabsUI || title;

        return (
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0,
                    [classes.primaryColor]: !!usePrimaryColor,
                    [classes.secondaryColor]: !!useSecondaryColor,
                })}
                style={
                    actionCount === 0 ? { height: 0, minHeight: 0 } : {}
                }
            >
                {ui}   
                <div className={classes.spacer} />
                {renderedActions}
            </Toolbar>
        );
    }
}
EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onFilterButton: PropTypes.func.isRequired,
    onColumnsButton: PropTypes.func,
    grid: PropTypes.object.isRequired,
    selectedAll: PropTypes.bool.isRequired,
    count: PropTypes.number.isRequired,
};
EnhancedTableToolbar.defaultProps = {
    showColumnsSelector: true,
    showFilterActivator: true,
}


const enhance = compose(
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withWidth(),
    withStyles(toolbarStyles)
);


export default enhance(EnhancedTableToolbar);
// export default withStyles(toolbarStyles)(EnhancedTableToolbar);