import React, { useState } from 'react';
import { createStyles, makeStyles, IconButton, Grid } from '@material-ui/core';
import { Microphone, MicrophoneOff, VolumeHigh, VolumeOff, Video, VideoOff, Camcorder, CamcorderOff, Pin, PinOff } from 'mdi-material-ui';
import LocalAudioLevelIndicator from 'videochat/components/MenuBar/DeviceSelector/LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import XRoundButton from './XRoundButton';
import useVideoContext from 'videochat/hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
            padding: 5,
            margin: "5px -18px"
        },
        actions: {
            textAlign: 'center',
        },
        video: {
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
        }
    })
);

export default function DeviceInput(props) {
    const classes = useStyles();

    const { localTracks } = useVideoContext();
    const audioTrack = localTracks.find(track => track.kind === 'audio');

    const { device, type, stream, selected, isFixed } = props;
    const onDeviceSelected = () => {
        props.onDeviceSelected && props.onDeviceSelected(device);
    }
    const onDeviceOff = () => {
        props.onDeviceOff && props.onDeviceOff(device);
    }
    const onDeviceFix = () => {
        props.onDeviceFix && props.onDeviceFix(selected, device);
    }
    const onDeviceUnfix = () => {
        props.onDeviceUnfix && props.onDeviceUnfix(device);
    }
    const renderAudioInput = () => {
        return <>
            <span>{device.label}</span>
            <Grid container spacing={2} className={classes.actions}>

                <Grid item xs={3}></Grid>
                <Grid item xs={3}><XRoundButton toolTip="Attiva" onClick={onDeviceSelected}><Microphone style={{ color: selected && audioTrack.isEnabled ? 'green' : 'inherit' }} /></XRoundButton></Grid>
                <Grid item xs={3}><XRoundButton toolTip="Disattiva" onClick={onDeviceOff}><MicrophoneOff style={{ color: !selected || !audioTrack.isEnabled ? 'red' : 'inherit' }} /></XRoundButton></Grid>
                <Grid item xs={3}></Grid>
                {/* <Grid item xs={3}></Grid>
                {!selected && <Grid item xs={12}><IconButton onClick={onDeviceSelected}><Microphone style={{color: '#0f0'}} /></IconButton></Grid>}
                {selected && <Grid item xs={6}><IconButton onClick={onDeviceOff}><MicrophoneOff style={{color: '#f00'}} /></IconButton></Grid>}
                {selected && <Grid item xs={6}><LocalAudioLevelIndicator /></Grid>} */}
            </Grid>
        </>;
    }
    const renderAudioOutput = () => {
        return <>
            <span>{device.label}</span>
            <Grid container spacing={2} className={classes.actions}>

                <Grid item xs={4}></Grid>
                <Grid item xs={4}><XRoundButton toolTip="Attiva" onClick={onDeviceSelected}><VolumeHigh style={{ color: selected ? 'green' : 'inherit' }} /></XRoundButton></Grid>
                {/* <Grid item xs={4}><XRoundButton toolTip="Disattiva" onClick={onDeviceOff}><VolumeOff style={{ color: !selected ? 'red' : 'inherit' }} /></XRoundButton></Grid> */}
                <Grid item xs={4}></Grid>
                {/* {!selected && <Grid item xs={12}><IconButton onClick={onDeviceSelected}><VolumeHigh /></IconButton></Grid>}
                {selected && <Grid item xs={12}><VolumeHigh style={{ color: '#0f0' }} /></Grid>} */}
            </Grid>
        </>;
    }
    const renderVideoInput = () => {



        return <>
            <Grid container spacing={2} className={classes.actions}>
                <Grid item xs={12}>
                    <video className={classes.video} autoPlay playsInline muted ref={video => { if (video) video.srcObject = stream.stream }} />
                </Grid>

                {!props.iAmSuperUser && <Grid item xs={3} />}
                <Grid item xs={3}><XRoundButton toolTip="Attiva" onClick={onDeviceSelected}><Video style={{ color: selected ? 'green' : 'inherit' }} /></XRoundButton></Grid>
                <Grid item xs={3}><XRoundButton toolTip="Disattiva" onClick={onDeviceOff}><VideoOff style={{ color: !selected ? 'red' : 'inherit' }} /></XRoundButton></Grid>
                {props.iAmSuperUser && <Grid item xs={3}><XRoundButton toolTip="Fissa" onClick={onDeviceFix}><Pin style={{ color: selected && isFixed ? 'green' : 'inherit' }} /></XRoundButton></Grid>}
                {props.iAmSuperUser && <Grid item xs={3}><XRoundButton toolTip="Live" onClick={onDeviceUnfix}><PinOff style={{ color: (!selected || !isFixed) ? 'red' : 'inherit' }} /></XRoundButton></Grid>}
                {!props.iAmSuperUser && <Grid item xs={3} />}
            </Grid>
        </>;
    }
    return (
        <div className={classes.root}>
            {type === "AUDIO_INPUT" && renderAudioInput()}
            {type === "AUDIO_OUTPUT" && renderAudioOutput()}
            {type === "VIDEO_INPUT" && renderVideoInput()}
        </div>
    );
}