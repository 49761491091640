import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';
import CashRow from './CashRow';
import CashCell from './CashCell';
import CashNumberEditor from './CashNumberEditor';
import { ArrowLeft } from 'mdi-material-ui';

const styles = theme => ({
    root: {

    },
    editor: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: '100%',
        zIndex: 2000,
        background: '#fff'
    },
});


class CashDeposit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditor: false,
            totalDeposit: 0,
        }
    }
    componentDidMount() {
        this.loadData();
    }
    loadData = async () => {
        var {data} = this.props;
        var totalDeposit = await fetch(`/DOC/GetCashTotalDeposit/${data.ID}`).then(res => res.json());
        this.setState({totalDeposit});
    }
    onEditDeposit = () => {
        this.setState({showEditor: true});
    }
    onEditorSubmit = async (fields) => {
        var { onUpdate, data } = this.props;

        var url = `/DOC/UpdateDeposit/${data.ID}`;

        var keys =["totalDeposit"];

        var first = true;
        for(var k in keys){
            var key = keys[k];
            var el = fields.filter(item => item.id === key)[0];
            if(el){
                if(first){
                    url += '?';
                    first = false;
                }
                else {
                    url += '&';
                }
                url += `${key}=${el.value}`;
            }
        }

        await fetch(url,{
            method: 'POST',
            body: JSON.stringify(fields)
        });
        this.loadData();
        this.setState({
            showEditor: false
        },this.requestActions);
        onUpdate && onUpdate();
    }
    
    requestActions = () => {
        var actions = [];
        if(this.state.showEditor){
            var actionProductsBack = 
            {
                group: 'actions',
                name: 'Indietro',
                icon: <ArrowLeft />,
                action: this.onEditorBack
            };
            actions.push(actionProductsBack);
        }
        this.props.onRequestActions && this.props.onRequestActions(actions);
    }
    onEditorBack = () => {
        this.setState({showEditor: false});
    }
    render() {
        var { classes, confirmed } = this.props;
        var { totalDeposit, showEditor } = this.state;
        if(showEditor){
            var fields = [
                {
                    label: "Acconto",
                    id: "totalDeposit",
                    value: totalDeposit || 0
                }
            ];
            return <div className={classes.editor}>
                    <CashNumberEditor id={"totalDeposit"} value={totalDeposit} fields={fields} onSubmit={this.onEditorSubmit} />
                </div>;
        }
        return <CashRow>
            <CashCell width="50%">Acconto</CashCell>
            <CashCell width="50%" onClick={!confirmed && this.onEditDeposit}>{(totalDeposit && totalDeposit.toFixed(2)) || 0} €</CashCell>
        </CashRow>;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(CashDeposit);