import React, { Component } from 'react';
import { withStyles, withTheme, Grid } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';
import XKeyboard from '../../../components/XKeyboard';
import XField from '../../../components/XField';
import XButton from '../../../components/XButton';

import CashRow from '../Cash/CashRow';
import CashCell from '../Cash/CashCell';

const styles = theme => ({
    root: {

    }
});


class Template extends Component {
    constructor(props) {
        super(props);
        this.state= {
            value: '',
            firstPress: true,
        }
    }

    componentDidMount() {
        this.setState({fields: this.props.fields, id: this.props.id},() => {
            this.setFocus(true);
        });
    }
    
    setFocus = (selectAll) => {
        var el = document.getElementById(this.state.id);
        if(el){
            el.focus();
            if(selectAll){
                el.setSelectionRange(0,el.value.length);
            }
        }
    }
    
    onBlur = (key) => (event) => {
        this.setFocus();
		//document.getElementById(key).focus();
    }
    onFocusChange = (id) => () => {
        this.setState({ id, firstPress: true },() => {
            this.setFocus(true);
        });
    }

    render() {
        var { classes } = this.props;
        var { fields, id } = this.state;

        var rows = fields && fields.map(item => item.row).filter((item, index,self) => self.indexOf(item) === index);
        var currentField = fields && fields.filter(item => item.id === id)[0];
        return <div>
            
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className={classes.center}>
                        {rows && rows.map(row => {
                            var rowFields = fields.filter(item => item.row === row);
                            return (<CashRow>
                                {rowFields.map(item => <CashCell width={item.width}><XField fontSize={18} disabled={item.readOnly} labelFontSize={16} label={item.label} id={item.id} value={item.value} onClick={!item.readOnly && this.onFocusChange(item.id)} onChange={this.onChange} onBlur={this.onBlur} onKeyDown={this.onKeyDown} /></CashCell>)}
                            </CashRow>);
                        })}
                        <CashRow>
                            <CashCell width={"100%"}><XButton onClick={this.onNumpadSubmit}>CONFERMA</XButton></CashCell>
                        </CashRow>
                    </div>
                </Grid>
            </Grid>
            <XKeyboard type={currentField && currentField.type || "full"}/>
        </div>;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(Template);