import React, {Component} from 'react';

import IREdit from 'views/IR/IREdit';
import XGrid from 'components/XGrid';
import XMessageBox from 'components/XMessageBox';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import { ArrowRight, ArrowLeft } from 'mdi-material-ui';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';

const styles = theme => ({
});

class CausalSelector extends Component {
    constructor(props) {
        super(props);

        this.grdCausals = React.createRef();
        this.MessageBox = React.createRef();
    }
    state = {
        data: {},
    };

    causalSelected = () => {
        var { onCausalSelected } = this.props;
        var { data } = this.state;
        var FIDCausal = data.ID;
        if (FIDCausal && onCausalSelected) {
            onCausalSelected(FIDCausal);
        }
    }
    loadCausal = () => {
        var selected = this.grdCausals.current.getWrappedInstance().getSelectedData();
        if (selected.length > 1) {
            this.MessageBox.current.getWrappedInstance().showMessageOk("Selezione causali", <Typography>Selezionate più causali. Per continuare selezionare una sola causale.</Typography>);
            return;
        }
        else {
            var selectedData = this.grdCausals.current.getWrappedInstance().getRowData(this.grdCausals.current.getWrappedInstance().getSelectedData()[0]);
            this.setState({ data: selectedData }, this.causalSelected);
        }
    }
    selectCausal = () => {
        this.loadCausal();
    }
    render() {
        var { classes, onCancel, causals } = this.props;
        var { data } = this.state;
        var previousStep = undefined;
        if (onCancel) {
            // previousStep = <Tooltip title="Indietro">
            //     <IconButton aria-label="Indietro" onClick={onCancel} >
            //         <ArrowLeft />
            //     </IconButton>
            // </Tooltip>;
            previousStep = {
                group: 'actions',
                name: getTranslation(5,"GC_BACK",this.props.translations, 'Indietro'),
                icon: <ArrowLeft />,
                action: onCancel
            }
        } 
        // var nextStep = <Tooltip title="Avanti">
        //     <IconButton aria-label="Avanti" onClick={this.selectCausal} >
        //         <ArrowRight />
        //     </IconButton>
        // </Tooltip>;
        var nextStep = {
            group: 'actions',
            name:getTranslation(5,"GC_NEXT",this.props.translations, 'Avanti'),
            icon: <ArrowRight />,
            action: this.selectCausal,
            condition: rows => rows && rows.length === 1,
        };


        // var customActions = [previousStep,{
        //     component: nextStep,
        //     condition: rows => rows && rows.length === 1
        // }];
        
        var customActions = [nextStep, previousStep];
        //var selectedActions = [nextStep];
        var selectedActions = undefined;
        var dataRoute = "/doc/causalgrid?grid=tblDOCCausals";
        if (causals && causals.length > 0) {
            //for (var i = 0; i < causals.length; i++) {
            //    dataRoute += "&causals=" + causals[i];
            //}
			dataRoute += ("&causals="+causals.join(","));
        }
        var dataRouteColumns = "/Base/GetColumns?grid=tblDOCCausals";
        var dataRouteAvailableColumns = "/Base/GetAvailableColumns?grid=tblDOCCausals";
        var dataRouteUpdateColumns = "/Base/UpdateColumns?grid=tblDOCCausals";
        var onDoubleClick = undefined;
        var customContent = undefined;
        var showFilterActivator = true;
        var showColumnsSelector = true;
        var newAction = undefined;

        var label = getTranslation(5,"GC_SELECTCAUSAL",this.props.translations, "Seleziona una causale");

        return (
            <div className={classes.root}>
                <XGrid
                    ref={this.grdCausals}
                    label={label}
                    dataRoute={dataRoute}
                    dataRouteColumns={dataRouteColumns}
                    dataRouteAvailableColumns={dataRouteAvailableColumns}
                    dataRouteUpdateColumns={dataRouteUpdateColumns}
                    showFilterActivator={showFilterActivator}
                    showColumnsSelector={showColumnsSelector}
                    onNewButton={newAction}
                    //dataRouteDelete={""}
                    actions={customActions}
                    selectedActions={selectedActions}
                    customContent={customContent}
                    onDoubleClick={onDoubleClick}
                />
                <XMessageBox ref={this.MessageBox} />
            </div>
        );
    }
}
export default withStyles(styles)(CausalSelector);