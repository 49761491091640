import React, { Component, Fragment } from 'react';
import { withStyles, withTheme, Grid } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';

import { DatePicker } from "@material-ui/pickers";
import XButton from 'components/XButton';

const styles = theme => ({
    root: {
        padding: 10,
    },
    numpad: {
        position: 'relative',
        left: '50%',
        transform: 'translate(-50%)'
    },
    center: {
        maxWidth: 600,
        position: 'relative',
        left: '50%',
        transform: 'translate(-50%)',
    },
    datePicker: {
        '&>div>div':{
            justifyContent: 'center'
        }
    }
});


class CashDateEditor extends Component {
    constructor(props) {
        super(props);
        this.state= {
            value: '',
        }
    }
    componentDidMount() {
        this.setState({value: this.props.value.toString()});
    }
    onSubmit = () => {
        var { id, onSubmit } = this.props;
        var { value } = this.state;

        onSubmit && onSubmit(id,value);
    }
    changeDate = (date) => {
        this.setState({ value: date});
    }

    render() {
        var { classes, fieldLabel,id, hideConfirmButton } = this.props;
        var { value } = this.state;
        return <div className={classes.root}>
            
            <Grid container spacing={2}>
                <Grid xs={12} md={3}></Grid>
                <Grid xs={12} md={6} className={classes.datePicker}>
                    <DatePicker
                        autoOk
                        orientation="landscape"
                        variant="static"
                        openTo="date"
                        value={value}
                        onChange={this.changeDate}
                    />
                </Grid>
                <Grid xs={12} md={3}></Grid>
                {!hideConfirmButton && <Fragment><Grid xs={12} md={3}></Grid>
                <Grid xs={12} md={6}>
                    <XButton onClick={this.onSubmit}>Conferma</XButton>
                </Grid>
                <Grid xs={12} md={3}></Grid></Fragment>}              
            </Grid>
            
        </div>;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(CashDateEditor);