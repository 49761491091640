import {HubConnectionBuilder} from "@microsoft/signalr";


const START_CONNECTION = "START_CONNECTION";
const START_CONNECTION_COMPLETE = "START_CONNECTION_COMPLETE";
const STOP_CONNECTION = "STOP_CONNECTION";
const STOP_CONNECTION_COMPLETE = "STOP_CONNECTION_COMPLETE";
const PRINT_RECEIPT = "PRINT_RECEIPT";
const PRINT_RECEIPT_COMPLETE = "PRINT_RECEIPT_COMPLETE";
const REPRINT_RECEIPT = "REPRINT_RECEIPT";
const REPRINT_RECEIPT_COMPLETE = "REPRINT_RECEIPT_COMPLETE";
const PRINT_GIFT_RECEIPT = "PRINT_GIFT_RECEIPT";
const PRINT_GIFT_RECEIPT_COMPLETE = "PRINT_GIFT_RECEIPT_COMPLETE";


const initialState = {
    connection: undefined
};

export const actionCreators = {
    startConnection: () => async (dispatch, getState) => {
        dispatch({ type: START_CONNECTION });
        var connection = new HubConnectionBuilder().withUrl("/cashhub").build();
        await connection.start();
        connection.invoke("StartClient");
        dispatch({ type: START_CONNECTION_COMPLETE, connection });
    },
    stopConnection: () => async (dispatch, getState) => {
        dispatch({ type: STOP_CONNECTION });
        var connection = getState().cashRegister.connection;
        connection && await connection.stop();
        dispatch({ type: STOP_CONNECTION_COMPLETE });
    },
    printReceipt: (ID,FIDCashRegister) => async (dispatch, getState) => {
        dispatch({ type: PRINT_RECEIPT});
        var connection = getState().cashRegister.connection;
        connection && connection.invoke("PrintReceipt",ID,FIDCashRegister);
        dispatch({ type: PRINT_RECEIPT_COMPLETE });
    },
    reprintReceipt: (ID,FIDCashRegister) => async (dispatch, getState) => {
        dispatch({ type: REPRINT_RECEIPT});
        var connection = getState().cashRegister.connection;
        connection && connection.invoke("ReprintReceipt",ID,FIDCashRegister);
        dispatch({ type: REPRINT_RECEIPT_COMPLETE });
    },
    printGiftReceipt: (ID,FIDCashRegister) => async (dispatch, getState) => {
        dispatch({ type: PRINT_GIFT_RECEIPT});
        var connection = getState().cashRegister.connection;
        connection && connection.invoke("PrintGiftReceipt",ID,FIDCashRegister);
        dispatch({ type: PRINT_GIFT_RECEIPT_COMPLETE });
    }
}

export const reducer = (state, action) => {
    state = state || initialState;
    if (action.type === START_CONNECTION_COMPLETE) {
        return { ...state, connection: action.connection };
    }
    if (action.type === STOP_CONNECTION_COMPLETE) {
        return { ...state };
    }
    if (action.type === PRINT_RECEIPT_COMPLETE) {
        return { ...state };
    }
    return state;
}