import React,  {Fragment, useRef, useEffect} from 'react';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import XSelect from 'components/XSelect';
import XField from 'components/XField';
import XFieldAutoComplete from 'components/XFieldAutoComplete';
import XTag from 'components/XTag';
import XSwitch from 'components/XSwitch';
import XDateField from 'components/XDateField';
import XDateTimeField from 'components/XDateTimeField';


import { Barcode, CodeString, Alphabetical, Check, ArrowLeft } from 'mdi-material-ui';

const MaterialIcon = ({ icon, className, fontSize }) => {
    //let iconName = icon.replace(/Icon$/, '')
	try {
		let resolved = require(`mdi-material-ui/${icon}`).default
		var iconProps = {};
		
		if (className) {
			iconProps.class = className + " MuiSvgIcon-root";
		}
		if (fontSize === "small") {
			iconProps.class += " MuiSvgIcon-fontSizeSmall";
		}

		return React.createElement(resolved, iconProps);
	}
	catch {
		try {
			let resolved = require(`components/Icons/${icon}`).default
			var iconProps = {};
		
			if (className) {
				iconProps.class = className + " MuiSvgIcon-root";
			}
			if (fontSize === "small") {
				iconProps.class += " MuiSvgIcon-fontSizeSmall";
			}
	
			return React.createElement(resolved, iconProps);
		}
		catch {
			let resolved = require('mdi-material-ui/Database').default;
			var iconProps = {};
		
			if (className) {
				iconProps.class = className + " MuiSvgIcon-root";
			}
			if (fontSize === "small") {
				iconProps.class += " MuiSvgIcon-fontSizeSmall";
			}
	
			return React.createElement(resolved, iconProps);
		}
	}
}

const getField = (fields, field) => {
    var filtered = fields.filter((item) => item.Field.toLowerCase() === field.toLowerCase());
    if (filtered.length > 0) {
        return filtered[0];
    }

    return undefined;
}

const XSelectX = ({data, fields, xs, md, id, label, handleChange, urlData,urlCreate, params}) => {
    return (getField(fields, id) && getField(fields, id).Visibility && (<Grid item xs={xs} md={md}><XSelect key={data[id]} field={getField(fields, id)} label={label} id={id} urlData={urlData} urlCreate={urlCreate} value={data[id]} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={handleChange} {...params} /></Grid>)) || <Fragment></Fragment>;
}
const getXSelect = (data, fields, xs, md, id, label, handleChange, urlData,urlCreate, params) => {
    return getField(fields, id) && getField(fields, id).Visibility && (<Grid item xs={xs} md={md}><XSelect key={data[id]} field={getField(fields, id)} label={label} id={id} urlData={urlData} urlCreate={urlCreate} value={data[id]} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={handleChange} {...params} /></Grid>);
}
const getXField = (data, fields, xs, md, id, label, handleChange, params) => {
    return getField(fields, id) && getField(fields, id).Visibility && (<Grid item xs={xs} md={md}><XField field={getField(fields, id)} label={label} id={id} value={data[id]} onChange={handleChange} {...params}/></Grid>);
}
const getXFieldAutocomplete = (data, fields, xs, md, id, label, handleChange, renderOption, suggestionsLoad, suggestionURLBase, suggestionSearchField, params) => {
    var searchField = suggestionSearchField || id;

    return getField(fields, id) && getField(fields, id).Visibility && (<Grid item xs={xs} md={md}><XFieldAutoComplete field={getField(fields, id)} label={label} id={id} value={data[id]} onChange={handleChange} renderOption={renderOption} suggestionsLoad={suggestionsLoad} searchField={searchField} suggestionURLBase={suggestionURLBase} {...params}/></Grid>);
}
const getXSwitch = (data, fields, xs, md, id, label, handleChange, params) => {
    
    return getField(fields, id) && getField(fields, id).Visibility && (<Grid item xs={xs} md={md}><XSwitch field={getField(fields, id)} label={label} id={id} value={data[id]} onChange={handleChange} {...params} /></Grid>);
}
const getXTag = (data, fields, xs, md, id, label, handleChange, urlData, urlCreate, params) => {
    return getField(fields, id) && getField(fields, id).Visibility && (<Grid item xs={xs} md={md}><XTag field={getField(fields, id)} label={label} id={id} urlData={urlData} urlCreate={urlCreate} value={data[id]} idKey="ID" valueKey="Denomination" abbreviationKey="Abbreviation" onChange={handleChange} {...params} /></Grid>);
}
const getXDateField = (data, fields, xs, md, id, label, handleChange, params) => {
    return getField(fields, id) && getField(fields, id).Visibility && (<Grid item xs={xs} md={md}><XDateField field={getField(fields, id)} label={label} id={id} value={data[id]} onChange={handleChange} {...params} /></Grid>);
}
const getXDateTimeField = (data, fields, xs, md, id, label, handleChange, params) => {
    return getField(fields, id) && getField(fields, id).Visibility && (<Grid item xs={xs} md={md}><XDateTimeField field={getField(fields, id)} label={label} id={id} value={data[id]} onChange={handleChange} {...params} /></Grid>);
}

const handleErrors = (response) => {
    if (!response.ok) {
        response.body.getReader().read().then(({ done, value }) => { console.log([done, value]); });
        throw Error(response);
    }
    return response;
}

const getCookie = (cname) => {
    return window.localStorage.getItem(cname);
    //var name = cname + "=";
    //var decodedCookie = document.cookie;//decodeURIComponent(document.cookie);
    //var ca = decodedCookie.split(';');
    //for (var i = 0; i < ca.length; i++) {
    //    var c = ca[i];
    //    while (c.charAt(0) === ' ') {
    //        c = c.substring(1);
    //    }
    //    if (c.indexOf(name) === 0) {
    //        return c.substring(name.length, c.length);
    //    }
    //}
    //return "";
}
const setCookie = (cname, cvalue) => {
    window.localStorage.setItem(cname, cvalue);
//    var d = new Date();
//    d.setTime(d.getTime() + (60 * 60 * 1000));
//    document.cookie = cname + "=" + cvalue + ";expires=" + d.toUTCString() + ";path=/";
}

const deleteCookie = (cname) => {
    window.localStorage.removeItem(cname);
    //var d = new Date();
    //d.setTime(d.getTime() - (1000 * 24 * 60 * 60 * 1000));
    //document.cookie = cname + "=;expires=" + d.toUTCString() + ";path=/";
}

const popupBOPSearch = (codeEvent,barcodeEvent,serialEvent) => {
    
    var actions = [];
    if (codeEvent) {
        actions.push(<Tooltip title="Referenza">
            <IconButton aria-label="Referenza" onClick={codeEvent}>
                <Alphabetical />
            </IconButton>
        </Tooltip>);
    }
    if (barcodeEvent) {
        actions.push(<Tooltip title="Barcode">
            <IconButton aria-label="Barcode" onClick={barcodeEvent}>
                <Barcode />
            </IconButton>
        </Tooltip>);
    }
    if (serialEvent) {
        actions.push(<Tooltip title="Seriale">
            <IconButton aria-label="Seriale" onClick={serialEvent}>
                <CodeString />
            </IconButton>
        </Tooltip>);
    }

    return actions;
}
const getBOPSearchContent = (ref, bopSearchType, bopSearchValue, onSearchBlur, onSearchKeyDown, onSearchChange, onSearchCancel, onSearchConfirm, translateCallback) => {
    var label = (translateCallback && translateCallback(5,"GC_RESEARCH","Ricerca")) || "Ricerca";
    var customContent = <div style={{ padding: "10px 20% 40px" }}>
        <XField autoFocus inputRef={ref} label={label} id="grdDOCBopsSearch" value={bopSearchValue} onBlur={onSearchBlur} onKeyDown={onSearchKeyDown} onChange={onSearchChange} />
    </div>;
    var showFilterActivator = false;
    var showColumnsSelector = false;
    var handleNewRow = undefined;
    var actions = [{
        group: 'actions',
        name: (translateCallback && translateCallback(5,"GC_SEARCH","Cerca")) || "Cerca",
        icon: <Check />,
        action: onSearchConfirm
    },{
        
        group: 'actions',
        name: (translateCallback && translateCallback(5,"GC_CANCEL","Annulla")) || "Annulla",
        icon: <ArrowLeft />,
        action: onSearchCancel
    }];
    // var actions = [<Tooltip title="Annulla">
    //     <IconButton aria-label="Annulla" onClick={onSearchCancel}>
    //         <ArrowLeft />
    //     </IconButton>
    // </Tooltip>,
    // <Tooltip title="Cerca">
    //     <IconButton aria-label="Cerca" onClick={onSearchConfirm}>
    //         <Check />
    //     </IconButton>
    //     </Tooltip>];
    return { customContent, showFilterActivator, showColumnsSelector, handleNewRow, actions };
}

function getScrollbarWidth() {

    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    outer.style.width = 100;
    outer.style.height = 100;
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    inner.style.width = 200;
    inner.style.height = 200;
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;

}
function parseParam(json) {
    if (json) {
        try {
            return JSON.parse(json);
        }
        catch{
            return undefined;
        }
    }
    return undefined;
}
const getDiscount = (discountDesc) => {
    if(!discountDesc){
        return 0;
    }
    var arrDisc = discountDesc.replace(/%/g,"").replace(',','.').split("+").map(Number);
    var result = 1;
    for(var i=0;i<arrDisc.length;i++){
        var d = arrDisc[i];
        result = result * (100-d) / 100;
    }
    result = Math.round((1 - result)*100000000)/100000000;
    return result;
}
const getRecharge = (rechargeDesc) => {
    if(!rechargeDesc){
        return 0;
    }
    var arrDisc = [];
    if(typeof rechargeDesc === "number"){
        arrDisc = [rechargeDesc];
    }
    else {
        arrDisc = rechargeDesc.replace(/%/g,"").replace(',','.').split("+").map(Number);
    }
    var result = 1;
    for(var i=0;i<arrDisc.length;i++){
        var d = arrDisc[i];
        result = result * (100+d) / 100;
    }
    result = Math.round((result-1)*100000000)/100000000;
    return result;
}
const fixTagValues = (value) =>{
    if (value && typeof value === "string") {
        return value.split(",").map(Number);
    }
    return value
}
const round = (number, decimals) => {
    var r = Math.pow(10, decimals);
    return Math.round(number * r) / r;
}

const format = (number, currency='€') =>{
    if(number || number === 0){
        return `${(number).toLocaleString('it-IT', {minimumFractionDigits: 2})} ${currency}`
    }
    return "";
}

const formatDate = (dateTime) => {
	var date = new Date(dateTime);

	var dd = date.getDate();
	var mm = date.getMonth() + 1; //January is 0! 
	var yyyy = date.getFullYear();
	if (dd < 10) { dd = '0' + dd }
	if (mm < 10) { mm = '0' + mm }
	return dd + '/' + mm + '/' + yyyy;
}

const formatTime = (seconds) => {
	if (!seconds || seconds === 0)
		return "00:00:00";
	else {
		var sec_num = seconds;
		var hours = Math.floor(sec_num / 3600);
		var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
		var seconds = sec_num - (hours * 3600) - (minutes * 60);

		if (hours < 10) { hours = "0" + hours; }
		if (minutes < 10) { minutes = "0" + minutes; }
		if (seconds < 10) { seconds = "0" + seconds; }
		return hours + ':' + minutes + ':' + seconds;
	}
}
     
const formatDateTime = (dt) => {
	var formattedString = `${
		dt.getDate().toString().padStart(2, '0')}/${(dt.getMonth() + 1).toString().padStart(2, '0')
		}/${
		dt.getFullYear().toString().padStart(4, '0')} ${
		dt.getHours().toString().padStart(2, '0')
		}:${
		dt.getMinutes().toString().padStart(2, '0')
		}:${
		dt.getSeconds().toString().padStart(2, '0')
		}`;

	return formattedString;
}
const distinct = (item,index,self) => self.indexOf(item) === index;

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() =>{
        ref.current = value;
    });
    return ref.current;
}

const isJObjectEmpty = (obj) => {
	for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

const asyncForEach = async (array, callback, asyncCallback) => {
  if (!callback && !asyncCallback) return;
  for (let index = 0; index < array.length; index++) {
	
	if(asyncCallback)
		await asyncCallback(array[index], index, array);
	else
		callback(array[index], index, array);
  }
}


export { 
    getXSelect, 
    XSelectX, 
    getXField, 
    getXFieldAutocomplete,
    getXSwitch, 
    getXTag, 
    getXDateField, 
    getXDateTimeField, 
    handleErrors, 
    getCookie, 
    setCookie, 
    deleteCookie, 
    popupBOPSearch, 
    getBOPSearchContent, 
    getScrollbarWidth, 
    parseParam,
    getDiscount,
    fixTagValues,
    getRecharge,
    round,
    format,
    formatDate,
    formatTime,
    formatDateTime,
    getField,
    distinct,
    usePrevious,
    isJObjectEmpty,
	asyncForEach,
	MaterialIcon
};