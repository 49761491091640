import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { withTheme, makeStyles, Grid } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';
import CashRow from './Cash/CashRow';
import CashCell from './Cash/CashCell';

import moment from 'moment';
import CashNumberEditor from './Cash/CashNumberEditor';
import CashDateEditor from './Cash/CashDateEditor';

import classNames from 'classnames';
import XMessageBox from 'components/XMessageBox';

const useStyles = makeStyles(theme => ({
    root: {

    },
    
    payMethod: {
        display: 'inline-block',
        margin: 5,
        padding: 20,
        background: theme.palette.secondary.main,
        color: '#fff',
        cursor: 'pointer',
    },
    payMethodSelected: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
}));


const DOCDeadLineManage = forwardRef((props,ref) => {
    const classes = useStyles(props);
    let { data } = props;

    const dateEditor = useRef(null);
    const amountEditor = useRef(null);
    const messageBox = useRef(null);

    const [ editPaymentType, setEditPaymentType ] = useState(false);
    const [ editPaymentDate, setEditPaymentDate ] = useState(false);
    const [ editPaymentAmount, setEditPaymentAmount ] = useState(false);

    const [ paymentType, setPaymentType ] = useState(data.FIDPaymentMethod);
    const [ paymentDate, setPaymentDate ] = useState(moment(new Date()));
    const [ paymentAmount, setPaymentAmount ] = useState(data.RemainingAmount);

    const [ paymentMethods, setPaymentMethods ] = useState([]);
    const [ payment, setPayment ] = useState(undefined);

    useEffect(() => {
        fetch('/DOC/GetCashPaymentMethods').then(res => res.json()).then(data => setPaymentMethods(data));
    },[])
    useEffect(() => {
        let payment = paymentMethods.filter(item => item.ID === paymentType)[0];
        setPayment(payment);
    },[paymentMethods, paymentType]);

    useImperativeHandle(ref,() => ({
        confirm: () => {
            if(editPaymentDate){
                dateEditor.current.onSubmit();
                return false;
            }
            else if(editPaymentAmount) {
                amountEditor.current.onNumpadSubmit();
                return false;
            }
            else {
                return confirmPayment();
            }
        },
        back: () => {
            if(editPaymentDate){
                setEditPaymentDate(false);
                return true;
            }
            else if(editPaymentAmount){
                setEditPaymentAmount(false);
                return true;
            }

            return false;
        }
    }));

    const onPaymentDateEdit = () => {
        setEditPaymentDate(true);
    }
    const onPaymentAmountEdit = () => {
        setEditPaymentAmount(true);
    }
    const onEditorSubmit = (fields) => {
        var value = fields[1].value;
        setPaymentAmount(value);
        setEditPaymentAmount(false);
    }
    const onDateEditorSubmit = (_,value) => {
        if(value && !value._isAMomentObject) {
            value = moment(new Date(value));
        }
        setPaymentDate(value);
        setEditPaymentDate(false);
    }
    const onPaymentMethodSelect = (paymentId) => () => {
        //this.setState({paymentId, step: 3}, this.onStepChange);
        setPaymentType(paymentId);
    }
    const confirmPayment = async () => {
        var payment = {
            FIDDueRegisterRow: data.ID,
            FIDDocumentHeader: data.FIDDocumentHeader,
            Amount: paymentAmount,
            FIDPaymentMethod: paymentType,
            PaymentDate: paymentDate
        };

        var result = await fetch('/DOCDeadLine/SavePayment',{
            method: 'POST',
            body: JSON.stringify(payment)
        }).then(res => res.json());
        return !!result;
    }
    

    const onEditorRecalculate = (fields) => {
        //keys
        var TotToPay = "ImportRate";
        var paymentAmount = "PaymentAmount";
        var Suspended = "Remaining";

        var elTotToPay = fields.filter(item => item.id === TotToPay)[0];
        var elpaymentAmount = fields.filter(item => item.id === paymentAmount)[0];
        var elSuspended = fields.filter(item => item.id === Suspended)[0];

        elSuspended.value = (elTotToPay.value - elpaymentAmount.value).toFixed(2);

        fields = [elTotToPay, elpaymentAmount, elSuspended];
        return fields;
    }
    const onNegativeAmounts = () => {
        messageBox.current.showMessageOk("Incasso/pagamento", "L'importo inserito è maggiore del totale da pagare");
    }



    if(data.DocumentDate && !data.DocumentDate._isAMomentObject){
        data.DocumentDate = moment(new Date(data.DocumentDate));
    }
    if(data.DueDate && !data.DueDate._isAMomentObject){
        data.DueDate = moment(new Date(data.DueDate));
    }
    data.PaymentType = paymentType;
    data.PaymentDate = paymentDate;
    data.PaymentAmount = paymentAmount;

    if(editPaymentAmount) {
        
        var fields = [
            {
                label: "Totale da pagare",
                id: "ImportRate",
                value: data.RemainingAmount,
                readOnly: true
            },
            {
                label: "Importo pagamento",
                id: "PaymentAmount",
                value: data.PaymentAmount,
            },
            {
                label: "Rimanente",
                id: "Remaining",
                value: (data.RemainingAmount -data.PaymentAmount),
                readOnly: true
            },
        ];
        return <div>
            <CashNumberEditor innerRef={amountEditor} id={"PaymentAmount"} value={data.PaymentAmount} fields={fields} onSubmit={onEditorSubmit} onRecalculate={onEditorRecalculate} onNegativeAmounts={onNegativeAmounts}  fixSpacing />
            <XMessageBox innerRef={messageBox} />
        </div>
    }
    else if(editPaymentDate) {
        return <div>
            <CashDateEditor innerRef={dateEditor} fieldLabel={"Data pagamento"} id={"PaymentDate"} value={data.PaymentDate} onSubmit={onDateEditorSubmit} hideConfirmButton/>
            <XMessageBox innerRef={messageBox} />
        </div>
    }

    return <div>
        <CashRow>
            <CashCell width="50%" label="Anagrafica">{data.Denomination}</CashCell>
            <CashCell width="16.6%" label="Data documento">{data.DocumentDate && data.DocumentDate.format('DD/MM/YYYY')}</CashCell>
            <CashCell width="16.6%" label="Num. documento">{data.Number}</CashCell>
            <CashCell width="16.6%" label="Totale documento">{data.TotalDiscountedAmount}</CashCell>
        </CashRow>
        <CashRow>
            <CashCell width="50%" label="Scadenza">{data.DueDate && data.DueDate.format('DD/MM/YYYY')}</CashCell>
            <CashCell width="50%" label="Importo">{data.ImportRate}</CashCell>
        </CashRow>
        <CashRow>
            <CashCell width="100%">
            {paymentMethods.map(item =>(<span className={classNames(classes.payMethod, {[classes.payMethodSelected]: paymentType === item.ID })} onClick={onPaymentMethodSelect(item.ID)}>{item.Description}</span>))}
            </CashCell>
        </CashRow>
        <CashRow>
            <CashCell width="33.33%" label="Modalità di pagamento">{payment && payment.Description}</CashCell>
            <CashCell width="33.33%" label="Data pagamento" onClick={onPaymentDateEdit}>{data.PaymentDate && data.PaymentDate.format('DD/MM/YYYY')}</CashCell>
            <CashCell width="33.33%" label="Importo pagamento" onClick={onPaymentAmountEdit}>{data.PaymentAmount}</CashCell>
        </CashRow>
        <XMessageBox innerRef={messageBox} />
    </div>
});



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withTheme
);
export default enhance(DOCDeadLineManage);