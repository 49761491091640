import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Stepper,Step,StepButton,StepLabel, withStyles,makeStyles,StepConnector } from '@material-ui/core';

import { MapMarker, AccountGroup } from 'mdi-material-ui';
import classNames from 'classnames';

import Check from '@material-ui/icons/Check';


const useQontoStepIconStyles = theme => ({
    root: {
      color: '#eaeaf0',
      display: 'flex',
      height: 16,
      alignItems: 'center',
    },
    active: {
      color: theme.palette.secondary.main,
    },
    circle: {
      width: 6,
      height: 6,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
    completed: {
      color: theme.palette.secondary.main,
      zIndex: 1,
      fontSize: 10,
    },
  });
  

  const QontoConnector = withStyles(theme => ({
    alternativeLabel: {
      top: 6.5,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
      color: theme.palette.primary.contrastText,
      fontWeight: 100
    },
    active: {
      fontWeight: 700,
      '& $line': {
        //borderColor: theme.palette.secondary.main,
      },
    },
    completed: {
      '& $line': {
        //borderColor: theme.palette.secondary.main,
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 2,
      borderRadius: 1,
    },
  }))(StepConnector);

class QontoStepIcon extends Component {
    render() {
        const { active, completed, classes } = this.props;
    
        return (
            <div
                className={classNames(classes.root, {
                [classes.active]: active,
                })}
            >
                {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
            </div>
        );
    }
}
QontoStepIcon = withStyles(useQontoStepIconStyles)(QontoStepIcon);

const stepperStyle = theme => ({
    root: {
        display: 'inline-block',
        verticalAlign: 'middle',
        textAlign: 'center',
    },
    stepperLabel: {
        textTransform: 'uppercase',
        fontSize: 10,
        fontWeight: 'bold',
    },
    stepper: {
        backgroundColor: 'transparent',
        //display: 'inline-flex',
        //width: props => 110 * props.steps.lenght,
        padding: 0,
        verticalAlign: 'middle',
    },
    active: {
        color: theme.palette.primary.contrastText,
    },
    label: {
        cursor: 'pointer',
    },
    alternativeLabel: {
        color: `${theme.palette.primary.contrastText} !important`,
        marginTop: "0 !important",
        fontSize: 10,
        textTransform: 'uppercase',
        fontWeight: 100
        /*lineHeight: '8px',
        marginBottom: 4*/
    }
});
class XStepper extends Component {
    onStepClick = (index) => () =>{
        var {onStepClick} = this.props;
        onStepClick && onStepClick(index);
    }
    render() {
        var {step, steps, classes, label} = this.props;
        var singleW = 80;
        return (
            <div className={classes.root}>
                {label && <span className={classes.stepperLabel}>{label}</span>}
                <Stepper style={{width: singleW * steps.length}} nonLinear activeStep={step} alternativeLabel className={classes.stepper} connector={<QontoConnector />}>
                    {steps.map((s, index) =>{
                        return <Step key={index} completed={index === step}>
                            <StepLabel className={classes.label} classes={{alternativeLabel: classes.alternativeLabel}} StepIconComponent={QontoStepIcon} onClick={this.onStepClick(index)}>{s.label}</StepLabel>
                        </Step>;
                    })}
                </Stepper>
            </div>
        );
    }
}
export default withStyles(stepperStyle)(XStepper);


