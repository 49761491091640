import React, { Component,Fragment } from 'react';
import { withStyles, withTheme,Grid, Drawer } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';

import { CodeBrackets, ChartPie } from 'mdi-material-ui';
import XExpansionPanel from '../../../components/XExpansionPanel';
import XGrid from '../../../components/XGrid';
import classNames from 'classnames';
import { getXTag, getXDateField, distinct, getScrollbarWidth } from 'utils';
import moment from 'moment';
import CashRow from './CashRow';
import CashCell from './CashCell';
import AppBar from '@material-ui/core/AppBar';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';
import CashReportDetail from './CashReportDetail';
import PerfectScrollbar from "perfect-scrollbar";
import '../../../assets/noScrollBarStyle.css';
import PropTypes from 'prop-types';

var ps;
const drawerWidth = 240;
var scrollbarWidth;
const styles = theme => ({
    root: {
        overflowY: 'hidden',
        overflowX: 'hidden',
    },

    scrollableElement:{
        scrollbarWidth: 'none',
    },

    cashDetail: {
        width: '100%'
    },


    //Drawer Styles
    
    gridStyle: {
        margin: '0',
    },

    loadButton: {
        "& > button": {
            position: 'sticky',
            left: '50%',
            transform: 'translate(-50%)'
        },
    },

    gridLoadButton: {
        position: 'sticky',
            bottom: 0,
            left: 0,
            right: 0,
            background: '#fff',
            boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
            textAlign: 'center',
    },


    container: {
        width: '100%',
    },

    containerFilters: {
        width: 'calc(100% - 240px)',
    },

    tooltipRoot: {
        backgroundColor: 'white',
        borderRadius: 1,
        borderWidth: 20,
        borderColor: '#fff',
        width: 'auto',
        height: 'auto',
        border: '25px',
        textAlign: 'center',
    },

    DenominationValue: {
        fontWeight: 'bold',
        color: '#141823',
        display: 'inline-block',
    },


    ViewsValue: {
        fontWeight: 'bold',
        color: '#F4B50D',
        textAlign: 'center',
    },

    tabContainer: {
        
    },
    drawer: {
        width: drawerWidth,
        minWidth: drawerWidth,
        maxWidth: drawerWidth,
        flexShrink: 0,
    },

    drawerPaper: {
        width: drawerWidth,
        minWidth: drawerWidth,
        maxWidth: drawerWidth,
        top: '147px',
        height: 'calc(100vh - 161px)',
        padding: 0,
        right: `calc( 0 + ${scrollbarWidth})`,
        zIndex: 1,
        //background: '#EEEEEE',
        paddingTop: '40px',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    tabContent: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: "100%",
        transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    },
    tabContentOpen: {
        width: `calc(100% - ${drawerWidth}px)`,
    },
    contentShift: {

        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    
    sidebarWrapper: {
        position: "relative",
        height: "calc(100vh - 75px)",
        overflow: "auto",
        zIndex: "4",
        overflowScrolling: "touch",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        color: "inherit",
    },
    sidebarWrapperWithPerfectScrollbar: {
        overflow: "hidden !important"
    },

    noResult: {
        textAlign: "center",
        paddingTop: '6%',
        paddingBottom: '10%',
    },
});


const UIFields = [
	{
		Field: "FIDLicensee",
		Visibility: true,
		Required: 3
	},
	{
		Field: "FIDLicenseeDestination",
		Visibility: true,
		Required: 3
	},
	{
		Field: "FIDCashRegister",
		Visibility: true,
		Required: 3
	},
	{
		Field: "DateFrom",
		Visibility: true,
		Required: 3
	},
	{
		Field: "DateTo",
		Visibility: true,
		Required: 3
	},
];

class SidebarWrapper extends React.Component {
    
    componentDidMount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
				suppressScrollX: true,
				suppressScrollY: false
			});
		}
    }
    
	componentWillUnmount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps.destroy();
		}
	}
	render() {
		const { className, children } = this.props;
		return (
			<div className={className} ref="sidebarWrapper">
				{children}
			</div>
		);
	}
}

class CashReportExpandRows extends Component {
    constructor(props){
        super(props);
        this.grdCashReportDetailsExtraRow = React.createRef();
        
        this.state = {
            reverseAmounts: false,
        }
    }

    static contextTypes = {
        router: PropTypes.object
    }

    handleEventRC = (event, data) => {
        this.context.router.history.push(`/doc/edit/${data.ID}`);
    }

    
    render () {
        var { width,data, FIDCycle, FIDIdentity, parameters } = this.props;
        var { view, reverseAmounts } = this.state;
        
        var actions = [];
       
        


        var gridName = "grdCashReportDetailsExtraRow";
        var dataRoute = `/cash/GetReportRowsGrid?grid=${gridName}&FIDOwner=${data.LicenseeID}&FIDOwnerDestination=${data.DestinationLicenseeID}&FIDPaymentType=${data.FIDPaymentType}&FIDCycle=${data.FIDCycle}&FIDCausal=${data.FIDCausal}&FIDLicensee=${parameters.FIDLicensee}&FIDLicenseeDestination=${parameters.FIDLicenseeDestination}&FIDCashRegister=${parameters.FIDCashRegister}&FromDate=${parameters.DateFrom.toISOString()}&ToDate=${parameters.DateTo.toISOString()}`;
        var deleteUrl = undefined;


        return <XGrid
            key={gridName}
            //label={label}
            innerRef={this.grdCashReportDetailsExtraRow}
            containerID={`gridExpandContainer_${data.ID}`}
            dataRoute={dataRoute}
            dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
            dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid==${gridName}`}
            dataRouteUpdateColumns={`/Base/UpdateColumns?grid==${gridName}`}
            showColumnsSelector={true}
            onDoubleClick={this.handleEventRC}
            rowsVisible={10}
            showFilterActivator={true}
        />;
    }
}


class CashReport extends Component {
    constructor(props) {
        super(props);
        var now = new Date();
        this.state = {
            parameters: {
                FIDLicensee: null,
                FIDLicenseeDestination: null,
                FIDCashRegister: null,
                DateFrom: now,
                DateTo: now,
                dueFilters: false,
                isExpanded: false,
            },
            paymentTypes:[],
        }
    }


    static contextTypes = {
        router: PropTypes.object
    }
    
    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        var { parameters } = this.state;
        if(parameters.DateFrom && !parameters.DateFrom._isAMomentObject) {
            parameters.DateFrom = moment(new Date(parameters.DateFrom));
        }
        if(parameters.DateTo && !parameters.DateTo._isAMomentObject) {
            parameters.DateTo = moment(new Date(parameters.DateTo));
        }
        var filters = `?FromDate=${parameters.DateFrom.format("YYYY-MM-DD")}&ToDate=${parameters.DateTo.format("YYYY-MM-DD")}`;
        
        
        if(parameters.FIDLicensee){
            filters += parameters.FIDLicensee.map((item,index) => `&FIDLicensee[${index}]=${item}`).join("");// `&FIDLicensee=${parameters.FIDLicensee}`
        }
        if(parameters.FIDLicenseeDestination){
            filters += parameters.FIDLicenseeDestination.map((item,index) => `&FIDLicenseeDestination[${index}]=${item}`).join("");// `&FIDLicenseeDestination=${parameters.FIDLicenseeDestination}`
        }
        if(parameters.FIDCashRegister){
            filters += `&FIDCashRegister=${parameters.FIDCashRegister}`
        }

        var data = await fetch(`/cash/CashReportData/${filters}`).then(res => res.json());
        var paymentTypes = await fetch(`/cash/CashReportPaymentTypes/${filters}`).then(res => res.json());
        this.setState({data,paymentTypes});
    }


    onChange = (key,value, items) => {
        this.setState(state => {
            state.parameters[key] = value;

            if(key === "FIDCashRegister" && value) {
                if(items) {

                    var FIDLicensee =items.map(item => item.FIDLicensee).filter(distinct);
                    if(!state.parameters.FIDLicensee) {
                        state.parameters.FIDLicensee = [];
                    }
                    FIDLicensee = state.parameters.FIDLicensee.concat(FIDLicensee);
                    

                    var FIDLicenseeDest =items.map(item => item.FIDLicenseeDestination).filter(distinct);
                    if(!state.parameters.FIDLicenseeDestination) {
                        state.parameters.FIDLicenseeDestination = [];
                    }
                    FIDLicenseeDest = state.parameters.FIDLicenseeDestination.concat(FIDLicenseeDest);

                    state.parameters.FIDLicensee = FIDLicensee;//item.FIDLicensee;
                    state.parameters.FIDLicenseeDestination = FIDLicenseeDest;//item.FIDLicenseeDestination;
                }
                
            }
            if(key === "FIDLicenseeDestination"){
                if(items) {
                    var FIDLicensee =items.map(item => item.FIDIdentity).filter(distinct);
                    if(!state.parameters.FIDLicensee) {
                        state.parameters.FIDLicensee = [];
                    }
                    FIDLicensee = state.parameters.FIDLicensee.concat(FIDLicensee);
                    state.parameters.FIDLicensee = FIDLicensee;
                }
                state.parameters.FIDCashRegister = null;
            }
            if(key === "FIDLicensee"){
                state.parameters.FIDLicenseeDestination = null;
                state.parameters.FIDCashRegister = null;
            }


            return state;
        }, this.loadData);
    }

    onSelectChange = (key) => (event,value, items) =>{
        if(value.length > 0){
            //this.onChange(key, value[0], value[0]);
            this.onChange(key,value, items);
        }
        else{
            this.onChange(key,undefined);
        }
    }
    onDateChange =(key) => (value) => {
        this.onChange(key,value);
    }
    renderPanelParams = () => {

        var { parameters,data } = this.state;

        // [{ key: "FIDIdentity", value: parameters.FIDLicensee }]
        var licenseeFilters = parameters.FIDLicensee && parameters.FIDLicensee.map(item => ({ key:"FIDLicensee", value: item}));
        var licenseeDestFilters = parameters.FIDLicenseeDestination && parameters.FIDLicenseeDestination.map(item => ({ key:"FIDLicenseeDestination", value: item}));

        var cashFilters = [];
        if(licenseeFilters){
            cashFilters = cashFilters.concat(licenseeFilters);
        }
        if(licenseeDestFilters){
            cashFilters = cashFilters.concat(licenseeDestFilters);
        }


        var licenseeDestinationFilter = { filtersOr: parameters.FIDLicensee && parameters.FIDLicensee.map(item => ({ key:"FIDIdentity", value: item})), subtitleKey: "Address" };
        var cashRegisterFilter = { filtersOr: cashFilters };

        var fieldFIDLicensee = getXTag(parameters,UIFields,12,12,"FIDLicensee","Licenziatario",this.onSelectChange,"/IR/GetIRLicenseesSelect","",{subtitleKey:"Address" });
        var fieldFIDLicenseeDestination = getXTag(parameters,UIFields,12,12,"FIDLicenseeDestination","Filiale",this.onSelectChange,"/IR/GetIRLicenseeDestinationsSelect","",licenseeDestinationFilter);
        var fieldFIDCashRegister = getXTag(parameters,UIFields,12,12,"FIDCashRegister","Cassa",this.onSelectChange,"/Cash/GetCashRegistersSelect","", cashRegisterFilter);
        var fieldDateFrom = getXDateField(parameters,UIFields,12,12,"DateFrom","Da data",this.onDateChange);
        var fieldDateTo = getXDateField(parameters,UIFields,12,12,"DateTo","A data",this.onDateChange);

        return (
            <Grid  style={{margin: 0}} container xs={12}>
            <Grid item xs={12}>{fieldFIDLicensee}</Grid>
            <Grid item xs={12}>{fieldFIDLicenseeDestination}</Grid>
            <Grid item xs={12}>{fieldFIDCashRegister}</Grid>
            <Grid item xs={12}>{fieldDateFrom}</Grid>
            <Grid item xs={12}>{fieldDateTo}</Grid>
        </Grid>
        );
    }
    renderPanelCashReport = () => {
        var { classes } = this.props;
        var { paymentTypes } = this.state;
        return (
            <div className={classes.cashDetail}>
                {paymentTypes.map(item => (
                    <div className={classes.cashDetail} key={item.FIDPaymentType}>
                        <CashRow><CashCell width={"100%"} bold>{item.Method}</CashCell></CashRow>
                        {this.renderCashReportItem(item.FIDPaymentType)}
                    </div>
                ))}
                <CashRow><CashCell width={"100%"} bold>Totale periodo</CashCell></CashRow>
                {this.renderCashReportItem()}
            </div>
        );
    }
    
    renderCashReportItem = (FIDPaymentType) => {
        var { parameters } = this.state;
        return (<CashReportDetail FIDPaymentType={FIDPaymentType} FIDOwner={parameters.FIDLicensee} FIDOwnerDestination={parameters.FIDLicenseeDestination} FIDCashRegister={parameters.FIDCashRegister} DateFrom={parameters.DateFrom} DateTo={parameters.DateTo} />);
    }
    rowExpandFormat = (data) => {
        var { parameters } = this.state;
        return <CashReportExpandRows data={data} parameters={parameters} translations={this.props.translations}/>;
    }

    


    renderCashReportDetails = (FIDPaymentType) => {
        var { parameters } = this.state;
        var { classes } = this.props;
        if(parameters.DateFrom && !parameters.DateFrom._isAMomentObject) {
            parameters.DateFrom = moment(new Date(parameters.DateFrom));
        }
        if(parameters.DateTo && !parameters.DateTo._isAMomentObject) {
            parameters.DateTo = moment(new Date(parameters.DateTo));
        }
        var filters = `&FIDPaymentType=${FIDPaymentType}&FromDate=${parameters.DateFrom.format("YYYY-MM-DD")}&ToDate=${parameters.DateTo.format("YYYY-MM-DD")}`;
        // if(parameters.FIDLicensee){
        //     filters += `&FIDLicensee=${parameters.FIDLicensee}`
        // }
        // if(parameters.FIDLicenseeDestination){
        //     filters += `&FIDLicenseeDestination=${parameters.FIDLicenseeDestination}`
        // }
        
        if(parameters.FIDLicensee){
            filters += parameters.FIDLicensee.map((item,index) => `&FIDLicensee[${index}]=${item}`).join("");// `&FIDLicensee=${parameters.FIDLicensee}`
        }
        if(parameters.FIDLicenseeDestination){
            filters += parameters.FIDLicenseeDestination.map((item,index) => `&FIDLicenseeDestination[${index}]=${item}`).join("");// `&FIDLicenseeDestination=${parameters.FIDLicenseeDestination}`
        }
        if(parameters.FIDCashRegister){
            filters += `&FIDCashRegister=${parameters.FIDCashRegister}`
        }

        var key= `${FIDPaymentType}_${filters}`
        var gridName = "grdCashReportDetails";
        var urlData = `/cash/GetReportGrid?grid=${gridName}${filters}`;
        return <XGrid
            // key={key}
            containerID={`panel${FIDPaymentType}`}
            usePrimaryColor={false}
            dataRoute={urlData}
            dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
            dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
            dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
            rowExpandFormat={this.rowExpandFormat}
            cardWidth={4}
            cardHeight={4.5}
        />;
    }

    
    updateFilters = () => {
        var { dueFilters } = this.state;
        scrollbarWidth = getScrollbarWidth();
        this.setState({ dueFilters: !dueFilters });
    }

    render() {
        var { paymentTypes } = this.state;
        var { classes } = this.props;
        var filtersPanels = [ 
            {
                icon:<CodeBrackets />,
                label:"Parametri",
                content: this.renderPanelParams(),
            },
        ];

        var panels = [
            {
                icon:<CodeBrackets />,
                label:"Report",
                content: this.renderPanelCashReport()
            }
        ];

        panels = panels.concat(paymentTypes.map(item => ({
            id: `panel${item.FIDPaymentType}`,
            key: item.FIDPaymentType,
            icon: <CodeBrackets />,
            label: `Dettaglio ${item.Method}`,
            content: this.renderCashReportDetails(item.FIDPaymentType)
        })));

        var customActions = [
            {
                group: 'actions',
                name: "Filtri", 
                icon: <ChartPie />,
                action: this.updateFilters,
            },
        ];

        return (
            <Fragment>
                <div className="noscroll">
                    <AppBar style={{marginBottom: '16px'}} position="sticky">
                    <EnhancedTableToolbar
                        onFilterButton={false}
                        onAdvancedFilterButton={false}
                        onClearFilterButton={false}
                        showColumnsSelector={false}
                        numSelected={0}
                        selectedAll={false}
                        count={0}
                        actions={customActions}
                        grid={this}
                    />
                    </AppBar>

                    <div className={classes.tabContainer}>
                        <div className={classNames(classes.tabContent,{[classes.tabContentOpen]: this.state.dueFilters})}>
                            <XExpansionPanel panels={panels} />
                        </div>
                    
                        <Drawer
                        className={classNames(classes.drawer,{[classes.drawerOpen]: this.state.dueFilters})}
                        variant="persistent"
                        anchor="right"
                        open={this.state.dueFilters}
                        classes={{ paper: classes.drawerPaper, }}>
                            <SidebarWrapper>
                                <XExpansionPanel panels={filtersPanels}/>
                            </SidebarWrapper>
                        </Drawer>
                    </div>
                </div>
            </Fragment>
        );
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(CashReport);