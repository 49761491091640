import React, { useRef, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import UserList from './UserList';

const useStyles = makeStyles((theme) =>
    createStyles({
        bar: {
            width: '100%',
            height: '100%',
            [theme.breakpoints.down('xs')]: {
                width: 'calc(100vw - 32px)',
            },
            backgroundColor: "white",
        },
        button: {
            position: 'absolute',
            right: '0',
            bottom: '0',
        },
    })
);

export default function ParticipantSidebar(props) {
    const classes = useStyles();

    return (
        <div className={classes.bar}>
            <UserList
                inCall={true}
                users={props.users}
                callUser={props.callUser}
                notifyBusyUser={props.notifyBusyUser}
                roomParticipants={props.roomParticipants}
                openMessageChat={props.openMessageChat}
                showAll={props.showAll}
            />
        </div>
    )
}