import React, { useState, useEffect } from 'react';
import CreditCardInsert from './CreditCardInsert';
import EmailInsert from './EmailInsert';
import ShowSavedData from './ShowSavedData';
import CostPreview from './CostPreview';
import ProductTermsAcceptance from './ProductTermsAcceptance';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme =>
    createStyles({
        button: {
            margin: '6px',
            backgroundColor: "#e2b231",
            borderRadius: '15px',
        },
    }));
export default function SaveCreditCard(){
    const classes = useStyles();
    const [emailAddress, setEmailAddress] = useState(null)
    const [customer, setCustomer] = useState(null)
    const [creditCard, setCreditCard] = useState(null)
    const [stripeSecret, setStripeSecret] = useState(null)
    
    useEffect(() => {
        fetchPaymentInfo(); 
    }, []);

    const fetchPaymentInfo = () => {
        fetch('/Payments/GetSavedPaymentsInfo')
        .then(response => response.json())
        .then(savedData => {
            if(savedData){
                setEmailAddress(savedData.email)
                setCustomer(savedData.customerId)
                setStripeSecret(savedData.stripePublicKey)
                setCreditCard(savedData.paymentMethodId)
            }
        })
    }
    const refetchPaymentInfo = () => {
        fetchPaymentInfo();
    }

    const handleCancelSubscription = () => {
        fetch('/Payments/CancelSubscription')
        .then(result => {
            console.log(result)
            if(result && result.ok)
            {
                alert("Subscription cancelled correctly");
                refetchPaymentInfo()
            }
            else{
                alert("Error cancelling subscription")
            }
        })
    }

    if(customer && emailAddress && creditCard)
    {
        return (<div>
            <CostPreview></CostPreview>
            <ShowSavedData email={emailAddress}
                                creditCard={creditCard}/>
            <ProductTermsAcceptance
                                cancelSubscription = {handleCancelSubscription}
                                customerId={customer}
                                paymentMethodId={creditCard} />           
            <Button className={classes.button}
                    onClick={handleCancelSubscription}>
                Cancel Subscription
            </Button>
        </div>)
    }
    else
    {
        return (<div>
            <EmailInsert email={emailAddress}
                        disabled={emailAddress}
                        onSavedCustomer={refetchPaymentInfo}/>
            <CostPreview></CostPreview>
            {(stripeSecret && customer!==null) &&
                <CreditCardInsert stripeSecret={stripeSecret}
                                    customerId={customer}
                                    onSavedCard={setCreditCard}/>
            }
            </div>
        );
    }
}