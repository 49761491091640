import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { grayColorVeryLight } from "assets/jss/material-dashboard-pro-react.jsx";

const styles = theme => ({
	dataWrapper: {
		padding: "20px 0",
		marginBottom: "10px",
		backgroundColor: grayColorVeryLight,
		display: "inline-block",
		marginLeft: "10px",
		marginRight: "10px",
		width: "calc(33% - 20px)",
		/*"&:nth-of-type(5n+1)": {
			marginLeft: "0px",
			marginRight: "0px",
			width: "20%"
		},*/
	},
	dataLabel: {
		color: "white",
		textAlign: "center"
	},
	dataValue: {
		width: "100%",
		textAlign: "center",
		color: "white",
		"& > p": {
			fontSize: "1rem",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			overflow: "hidden",
		}
	}
})

class XProductionOrderRowLabel extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		var { classes, label, value } = this.props;

		var wrapperClass = classes.dataWrapper;

		var valueClass = classes.dataValue;

		return (
			<div className={wrapperClass}>
				<div className={classes.dataLabel}><Typography>{label}</Typography></div>
				<div className={valueClass}><Typography>{value}</Typography></div>
			</div>
		);
	}
}
export default withStyles(styles)(XProductionOrderRowLabel);