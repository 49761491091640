import React, { Component, Fragment } from 'react';
import { withStyles, withTheme } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';
import { actionCreators as localizationCreator,getTranslation } from 'store/Localization';

import { Ring,TextBoxSearch,Plus} from 'mdi-material-ui';

import CashProductSearch from './CashProductSearch';
import CashProductRow from './CashProductRow';
import XGrid from '../../../components/XGrid';
import XNavigation from '../../../components/XNavigation';
import XStepper from '../../../components/XStepper';

const styles = theme => ({
    root: {
        height: '100%',
        position: 'relative',
    },
    productsContainer: {
        width: '100%',
        height: 'calc(100% - 47px)',
        overflowX: 'hidden',
        overflowY: 'auto',
    }
});


class CashProducts extends Component {
    constructor(props) {
        super(props);
        this.ProductSearch = React.createRef();
        this.state = {
            data:[],
            view: undefined,
            docIRFilter: 1,
        }
    }
    componentDidMount() {
        this.loadData();
    }
    componentDidUpdate(props) {
        if(this.props.doc.FIDIdentity !== props.doc.FIDIdentity){
            if(this.props.doc.FIDIdentity) {
                this.setState({docIRFilter: 0});
            }
            else {
                this.setState({docIRFilter: 1});
            }
        }
    }
    loadData = async () => {
        var { doc } = this.props;
        var result = await fetch(`/DOC/GetCashRows/${doc.ID}?FIDIdentity=${doc.FIDIdentity}`).then(res => res.json());
        this.setState({data: result});
        if(this.props.doc.FIDIdentity) {
            this.setState({docIRFilter: 0});
        }
        else {
            this.setState({docIRFilter: 1});
        }
    }
    onAddProduct = async (id, callback) => {
        var { onProductsChange,searchView,doc, showDeposits } = this.props;
        var { view } = this.state;
        var url = `/DOC/AddCashProduct/`;
        if(view === "DOCS"){
            url = `/DOC/AddCashProcessRow/`;
        }
        if(showDeposits){
            url = `/DOC/AddDepositRow/`;
        }
        await fetch(`${url}${id}?FIDIdentity=${doc.FIDIdentity}&FIDCausal=${doc.FIDCausal}`);
        this.loadData();
        onProductsChange && onProductsChange();
        callback && callback();
    }
    onRemoveProduct = async (id) => {
        var { onProductsChange } = this.props;
        await fetch(`/DOC/RemoveCashProduct/${id}`);
        this.loadData();
        onProductsChange && onProductsChange();
    }
    onUpdateProduct = () => {
        var { onProductsChange } = this.props;
        onProductsChange && onProductsChange();
    }
    changeView = (view) => () => {
        this.setState({view});
    }
    onEditorClose = () => {
        this.setState({editorOpen: false});
    }
    onEditorOpen = () => {
        
        this.setState({editorOpen: true});
    }
    
    onDOCIRFilterChange = (step) => {
        this.setState({docIRFilter: step});
    }
    newProduct = () => {
        fetch('/DOC/AddCashNewRow').then(res => {
            var { onProductsChange } = this.props;
            onProductsChange && onProductsChange();
            this.setState({view: undefined},() =>{
                this.ProductSearch.current && this.ProductSearch.current.loadDataSearch();
            });
        })
    }
    render() {
        var { classes, searchView, doc, confirmed, IRFilter, onRequestActions, isRepair, width, showDeposits } = this.props;
        var { data, view, docIRFilter,editorOpen } = this.state;

        view = view || "ROWS";


        var newRowAction = {
            group: 'actions',
            name: 'Nuova riga',
            icon: <Plus />,
            action: this.newProduct
        };
        if(showDeposits){
            view = "DEPOSIT";
        }


        var content = <div className={classes.root}>
            <CashProductSearch 
                innerRef={this.ProductSearch}
                onSearchShowChange={this.props.onSearchShowChange} 
                onBuy={this.onAddProduct} 
                onRemove={this.onRemoveProduct} 
                onUpdate={this.onUpdateProduct} 
                onRequestActions={onRequestActions}
                onEditorOpen={this.onEditorOpen}
                onEditorClose={this.onEditorClose}
                view={view} 
                doc={doc} 
                IRFilter={docIRFilter} 
                isRepair={isRepair} 
                confirmed={confirmed} 
            />
        </div>

        var label = undefined;
        var navActions = [];

        var style= {
            display: 'inline-block',
            verticalAlign: 'top'
        };
        
        var fieldShowAll = <XStepper label="Anagrafica" step={docIRFilter} steps={[{label:"Assegnata"},{label:"Non assegnata"},{label: "Tutti"}]} onStepClick={this.onDOCIRFilterChange} />;

        if(!editorOpen && !showDeposits){
            navActions.push({ name: getTranslation(4,"DOC_ROWS",this.props.translations,"Righe"), icon: <Ring />, action: this.changeView("ROWS"), selected: view === "ROWS" });
            !confirmed && doc.FIDCycle === 3 && doc.FIDDocumentType === 4 && navActions.push({ name: getTranslation(4,"DOC_CATALOG",this.props.translations,"Catalogo"), icon: <Ring />, action: this.changeView("BOPS"), selected: view === "BOPS" });
            !confirmed && doc.FIDCycle === 3 && doc.FIDDocumentType === 4 && navActions.push({ name: getTranslation(4,"DOC_HISTORICSTOCK",this.props.translations,"Storico"), icon: <Ring />, action: this.changeView("BOPSTOCK"), selected: view === "BOPSTOCK" });
            !confirmed && doc.FIDCausal !== 38 && doc.FIDCycle === 2 && navActions.push({ name: getTranslation(4,"DOC_STOCK",this.props.translations,"Magazzino"), icon: <Ring />, action: this.changeView("BOPSTOCK"), selected: view === "BOPSTOCK" });
            !confirmed && navActions.push({ name: getTranslation(4,"DOC_DOCUMENTS",this.props.translations,"Documenti"), icon: <TextBoxSearch />, action: this.changeView("DOCS"), selected: view === "DOCS" });
        
            var direction = "right";
            if (["xs"].includes(width)) {
                direction = "down";
            }
            var label = <span><XNavigation actions={navActions} direction={direction} showLabel={true} /></span>;
        }

        if((doc.FIDCausal === 38 && view==="DOCS" )|| showDeposits) {
            label = <Fragment><span style={style}>{label}</span><span style={style}>{fieldShowAll}</span></Fragment>
        }
        var actions = [];
        isRepair && actions.push(newRowAction);

        return <XGrid
                containerID={"leftPanel"}
                usePrimaryColor={false}
                customContent={content}
                customHeight={true}
                actions={actions}
                label={label}
            />;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreator, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(CashProducts);