import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';

import { Document,Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = theme => ({
    root: {

    }
});


class XPDFViewer extends Component {
    constructor(props) {
        super(props);
        this.state ={
            totalPages: 0,
        }
    }
    onPdfLoad = ({ numPages }) => {
        this.setState({ totalPages: numPages });
    }
    onError = (error) => {
        console.error(error);
    }

    render() {
        var { url, ...rest } = this.props;
        var { totalPages } = this.state;
        var pages = [...Array(totalPages).keys()];

        // return <Document file={`${window.location.origin}${url}`} onLoadSuccess={this.onPdfLoad} {...rest} onLoadError={this.onError}>
        //     {pages.map(item => <Page width={"100%"} key={item} pageNumber={item+1} />)}
        // </Document>
		var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        var finalUrl = `https://docs.google.com/gview?url=${window.location.origin}${url}&embedded=true`;
        if(window.location.host.includes("localhost") || isChrome){
            finalUrl = url;
        }

        return <iframe src={finalUrl} type="application/pdf" {...rest}/>;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(XPDFViewer);