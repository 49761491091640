import React, { Component, Fragment } from 'react';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import AppBar from '@material-ui/core/AppBar';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import ReactGA from 'react-ga';
import { Tracker, TrackerProvider } from 'react-tracker';

import { AlphaBcircleOutline, AlphaLcircleOutline, AlphaPcircleOutline, AlphaScircleOutline } from 'mdi-material-ui';

//import XDialog from 'components/XDialog';
import XOXLoader from 'components/XOXLoader';


import CustomerCatalogueCard from 'components/CustomerCatalogueCard';
import CustomerCatalogueItem from 'components/CustomerCatalogueItem';
import CollectionCard from 'components/CollectionCard';
import XFilters from 'components/XFilters';
import XField from 'components/XField';
import XMessageBox from 'components/XMessageBox';

import EnhancedTableToolbar from 'components/EnhancedTableToolbar';

import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';
import XNavigation from '../components/XNavigation';
import { getCookie, setCookie, deleteCookie, popupBOPSearch, getBOPSearchContent, getScrollbarWidth } from 'utils';
//import { Typography } from '@material-ui/core';

import { Collection } from 'react-virtualized';

const styles = theme => ({
    root: {
        position: 'relative'
    },
    content: {
        marginTop: "0",
        padding: "0px 0px",
        minHeight: "100vh",
        maxHeight: "100vh",
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
    },
    collectionWrap: {
        width: '100% !important',
        height: 'calc(100vh - 64px) !important',
    },
    collectionCardAlt: {
        position: 'relative',
        padding: '0 !important',
        cursor: 'pointer',
    },
    collectionCardAltBackground: {
        paddingTop: '100%',
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        border: '1px solid #bbb',
        width: '100%',
    },
    collectionCardAltContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        maxWidth: '100%',
        maxHeight: '100%',
        textAlign: 'center',
        fontSize: 20,
        color: '#fff',
        fontWeight: 'bold',
    },
    
});
const defaultFilters = {
    ShowModels: true,
    ShowStock: true,
    ImageView: 2,
    GroupType: 1,
    Sort: [],
};
const analytics = ReactGA.ga;
const trackPageView = async () => {
    analytics(async function (tracker) {
        var type = "PAGE_VIEW";
        var clientId = tracker.get('clientId');

        var ipaddress = undefined;

        await fetch('https://api.ipify.org?format=json').then(response => response.json()).then(function (data) {
            ipaddress = data.ip;
            }
        );

        var queryString = "?EventType=" + type + "&SessionID=" + clientId;

        if (ipaddress)
            queryString += "&IPAddress=" + ipaddress;

        await fetch('/Tracking/TrackEvent/' + queryString, { method: 'POST' }).then(res => console.log(res.json())).catch(error => console.error('Error: ', error))
            .then(response => console.log('Success: ', response));
    });
}
trackPageView.eventType = "PAGE_VIEW";

const trackProductClick = async (eventData) => {
    if (!eventData)
        return;
    var eventType = "PRODUCT_CLICK";

    var type = eventData.type;
    var data = eventData.data;

    if (!data)
        return;

    if (eventType !== type)
        return;

    if (!data.sessionID)
        return;

    var queryString = "?EventType=" + type + "&SessionID=" + data.sessionID;

    if (data.bopID)
        queryString += "&BOPID=" + data.bopID;
    else
        return;

    var ipaddress = undefined;

    await fetch('https://api.ipify.org?format=json').then(response => response.json()).then(function (data) {
        ipaddress = data.ip;
    }
    );
    if (ipaddress)
        queryString += "&IPAddress=" + ipaddress;

    await fetch('/Tracking/TrackEvent/' + queryString, { method: 'POST' }).then(res => console.log(res.json())).catch(error => console.error('Error: ', error))
        .then(response => console.log('Success: ', response));;
    return eventData;
}
trackProductClick.eventType = "PRODUCT_CLICK";

class CustomerCatalogue extends Component {
    constructor(props) {
        super(props);
        this.MessageBox = React.createRef();
        this.CatalogueItem = React.createRef();
        this.CardsCollection = React.createRef();
        this.BLSCollection = React.createRef();
        this.bopsSearch = React.createRef();

        this.state = {
            data: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            count: 0,
            page: 1,
            itemsperPage: 24,
            showFilters: false,
            showFiltersRow: false,
            currentFilters: Object.assign({}, defaultFilters),
            tempFilters: Object.assign({}, defaultFilters),
            oldFilters: undefined,
            oldTempFilters: undefined,
            filtersData: {
                categories: [],
                groups: [],
                brands: [],
                prices: [],
                lines: [],
                series: [],
                positions: [],
                templates: [],
            },
            currentItem: {},
            showItem: false,
            isLoading: true,
            isLoadingPage: false,
            dialog: {},
            showSort: false,
            sortColumns: [],
            viewType: null,
            scrollTop: false,
            contentWidth: 100,
            rowsMaxHeight: {},
            firstLoad: true,
            FIDBrand: undefined,
            FIDLine: undefined,
            FIDSeries: undefined
        };
    }
    async componentDidMount() {
        if (window.ResizeObserver !== undefined) {
            window.resizeObserver = new window.ResizeObserver((entries) => {
                this.setState({
                    contentWidth: entries[0].contentRect.width,
                    lastWidthChange: Date.now()
                }, () => {
                    setTimeout(() => {
                        if (this.CardsCollection.current && (Date.now() - this.state.lastWidthChange > 100)) {
                            this.CardsCollection.current.recomputeCellSizesAndPositions();
                        }
                        if (this.BLSCollection.current && (Date.now() - this.state.lastWidthChange > 100)) {
                            this.BLSCollection.current.recomputeCellSizesAndPositions();
                        }
                    }, 100);
                });
            });
            window.resizeObserver.observe(document.getElementById('mainContent'));
        }
        else {
            this.setState({ contentWidth: document.getElementById('mainContent').clientWidth });
            window.addEventListener('resize', this.mainContentResize);
        }

        this.mainContentResize();
        /*var tempState = getCookie('catalogue_state');
        if (tempState) {
            var newState = JSON.parse(tempState);
            this.setState(state => {
                state.currentFilters = Object.assign({}, newState.currentFilters);
                state.tempFilters = Object.assign({}, newState.tempFilters);
                state = newState;
                return state;
            }, () => {
                deleteCookie('catalogue_state');
                if (newState.reloadData) {
                    this.loadData();
                }
            });
        }
        else {*/

            var { advancedNavigation } = this.props;

            this.setState(state => {
                state.currentFilters = Object.assign({}, defaultFilters);
                state.tempFilters = Object.assign({}, defaultFilters);
                return state;
            }, () => {
                if (advancedNavigation) {

                    this.getCollections();
                }
                else {
                    this.loadData();
                }
                this.loadFiltersData();
            });
        //}
    }
    componentWillMount() {
        trackPageView();
    }
    componentDidUpdate = () => {
        //if (true) {
        //    window.onbeforeunload = () => true;
        //}
        //else {
        //    window.onbeforeunload = undefined;
        //}
    }
    componentWillUnmount() {
        this.props.setTitle(undefined);
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        window.removeEventListener('resize', this.mainContentResize);
    }
    mainContentResize = () => {
        var element = document.getElementById('mainContent');

        this.setState({
            contentWidth: element.clientWidth,
            lastWidthChange: Date.now()
        }, () => {
            setTimeout(() => {
                if (this.CardsCollection.current && (Date.now() - this.state.lastWidthChange > 100)) {
                    this.CardsCollection.current.recomputeCellSizesAndPositions();
                }
                if (this.BLSCollection.current && (Date.now() - this.state.lastWidthChange > 100)) {
                    this.BLSCollection.current.recomputeCellSizesAndPositions();
                }
            }, 100);
        });
    }
    getCollections = () => {
        var prms = '?showDB=' + true +
            '&showDM=' + true +
            '&groupType=' + 1 +
            '&imageView=' + 2 +
            '&customer=' + this.props.customer;

        if (this.props.storeid) {
            prms += '&store=' + this.props.storeid;
            if (this.props.storeareaid)
                prms += '&storearea=' + this.props.storeareaid;
        }

        fetch('/BOP/GetCatalogueCollections/' + prms).then(res => res.json()).then(data => {
            this.setState({ collections: data, isLoading: false }, () => {
                if (this.state.firstLoad) {
                    this.setState({ firstLoad: false }, () => {
                        //check if has params
                        var url_string = window.location.href;
                        var url = new URL(url_string);
                        var brands = url.searchParams.get('brands');
                        var lines = url.searchParams.get('lines');
                        var series = url.searchParams.get('series');
                        var products = url.searchParams.get('products');
                        var news = url.searchParams.get('new');
                        if (!!brands) {
                            this.onCollectionCardClick('brand', { FIDBrand: parseInt(brands) });
                        }
                        else if (!!lines) {
                            this.onCollectionCardClick('line', { FIDLine: parseInt(lines) });
                        }
                        else if (!!series) {
                            this.onCollectionCardClick('series', { FIDSeries: parseInt(series) });
                        }
                        else if (!!news) {
                            this.showNews();
                        }
                        if (!!products) {
                            this.showProducts();
                        }
                    });
                }
                
            });
        });
    }
    showNews = () => {
        this.setState(state => {
            state.currentFilters.ShowNews = true;
        }, this.showProducts);
    }
    loadData = (callback) => {
        var { page, itemsperPage, currentFilters, viewType, FIDBrand, FIDLine, FIDSeries } = this.state;
        if (viewType !== 'product') {
            currentFilters = {};
        }

        var showNews = currentFilters.ShowNews ? true : false;
        var filters = this.getFiltersArray(currentFilters);

        if (FIDBrand) {
            filters.push({
                key: "FIDBrand",
                term: FIDBrand,
                comparison: "=",
                type: "int"
            });
        }
        if (FIDLine) {
            filters.push({
                key: "FIDLine",
                term: FIDLine,
                comparison: "=",
                type: "int"
            });
        }
        if (FIDSeries) {
            filters.push({
                key: "FIDSeries",
                term: FIDSeries,
                comparison: "=",
                type: "int"
            });
        }

        if (!currentFilters.Sort) {
            currentFilters.Sort = [];
        }
        var sort = currentFilters.Sort.map(item => ({ Field: item.Value.ID, Direction: item.Sort }));
        var time = Date.now();
        this.setState({ isLoadingPage: true, loadTimeStamp: time}, () => {
            var link = '/BOP/GetCatalogue?sortColumn=Code&sortDirection=ASC&skip=' + ((page - 1) * itemsperPage) +
                '&customer=' + this.props.customer +
                (this.props.storeid ? '&store=' + this.props.storeid : '') +
                (this.props.storeid && this.props.storeareaid ? '&storearea=' + this.props.storeareaid : '') +
                '&items=' + itemsperPage +
                '&filters=' + JSON.stringify(filters) +
                '&showDB=' + true +
                '&showDM=' + true +
                '&showNews=' + showNews +
                '&groupType=' + 1 +
                '&imageView=' + 2 +
                '&sort=' + JSON.stringify(sort);
            fetch(link).then(res => res.json())
                .then(data => {
                    if (this.state.loadTimeStamp === time) {
                        this.setState({ data: data.data, count: data.count, isLoadingPage: false, isLoading: false }, () => {
                            if (callback) {
                                callback();
                            }
                        });
                    }
                });
        });
    }
    getFiltersArray = (tempFilters, noPrice = false) => {
        var filters = [];
        if (tempFilters.FIDBrand) {
            filters.push({
                key: "FIDBrand",
                term: tempFilters.FIDBrand,
                comparison: "=",
                type: "int"
            });
        }
        if (tempFilters.FIDGroup) {
            filters.push({
                key: "FIDGroup",
                term: tempFilters.FIDGroup,
                comparison: "=",
                type: "int"
            });
        }
        if (tempFilters.FIDCategory) {
            filters.push({
                key: "FIDCategory",
                term: tempFilters.FIDCategory,
                comparison: "=",
                type: "int",
            });
        }
        if (tempFilters.FIDLine) {
            filters.push({
                key: "FIDLine",
                term: tempFilters.FIDLine,
                comparison: "=",
                type: "int",
            });
        }
        if (tempFilters.FIDSeries) {
            filters.push({
                key: "FIDSeries",
                term: tempFilters.FIDSeries,
                comparison: "=",
                type: "int",
            });
        }
        if (tempFilters.FIDPosition) {
            filters.push({
                key: "FIDPosition",
                term: tempFilters.FIDPosition,
                comparison: "=",
                type: "int",
            });
        }
        if (!noPrice && tempFilters.Price && tempFilters.Price.length === 2) {
            filters.push({
                key: "price",
                term: tempFilters.Price,
                type: 'limits_int'
            });
        }
        var likeSearch = ["Group", "Brand", "Category", "Typology", "Code", "Description", "SerialNumber", "Barcode", "Position", "Line"];
        for (var i = 0; i < likeSearch.length; i++) {
            var key = likeSearch[i];
            if (tempFilters[key]) {
                filters.push({
                    key: key,
                    term: tempFilters[key],
                    type: 'text',
                    comparison: 'like'
                });
            }
        }
        return filters;
    }
    loadFiltersData = () => {

        var { tempFilters } = this.state;
        var filters = this.getFiltersArray(tempFilters);
        var filtersNoPrice = this.getFiltersArray(tempFilters, true);

        var showNews = tempFilters.ShowNews ? true : false;


        var paramsNoPrice = '?filters=' + JSON.stringify(filtersNoPrice) + '&showDB=' + true + '&showDM=' + true + '&showNews=' + showNews + '&imageView=' + 2 + '&customer=' + this.props.customer;
        var params = '?filters=' + JSON.stringify(filters) + '&showDB=' + true + '&showDM=' + true + '&showNews=' + showNews + '&imageView=' + 2 + '&customer=' + this.props.customer;

        if (this.props.storeid) {
            params += '&store=' + this.props.storeid;
            if (this.props.storeareaid)
                params += '&storearea' + this.props.storeareaid;
        }

        fetch('/BOP/GetBOPGroups' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.groups = data;
                    return state;
                })
            });
        fetch('/BOP/GetBOPCategories' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.categories = data;
                    return state;
                })
            });
        fetch('/BOP/GetBOPBrands' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.brands = data;
                    return state;
                })
            });
        fetch('/BOP/GetBOPPrices' + paramsNoPrice).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.prices = data;
                    return state;
                })
            });
        fetch('/BOP/GetBOPLines' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.lines = data;
                    return state;
                })
            });
        fetch('/BOP/GetBOPSeries' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.series = data;
                    return state;
                })
            });
        fetch('/BOP/GetBOPPositions' + params).then(res => res.json())
            .then(data => {
                this.setState(state => {
                    state.filtersData.positions = data;
                    return state;
                })
            });
    }
    previousPage = () => {
        this.setState((state, props) => {
            state.page--;
            return state;
        }, this.loadData);
    }
    nextPage = () => {
        this.setState((state, props) => {
            state.page++;
            return state;
        }, this.loadData);
    }
    handleFilterButton = (event) => {
        this.setState(state => {
            state.showFiltersRow = !state.showFiltersRow;
            return state;
        });
    }
    onClearFilterButton = (event) => {
        this.setState(state => {
            state.tempFilters = Object.assign({}, defaultFilters);
            state.currentFilters = Object.assign({}, defaultFilters);
            state.page = 1;
            state.viewType = undefined;
            return state;
        }, () => {
            this.loadData();
                this.loadFiltersData();
                this.setCollectionTitle();
        });
    }

    onClearTempFilterButton = (event) => {
        this.setState(state => {
            state.tempFilters = Object.assign({}, defaultFilters);
            return state;
        }, () => {
            this.loadFiltersData();
        });
    }
    onFilterClose = () => {
        var filters = this.state.currentFilters;
        this.setState(state => {
            state.showFilters = false;
            state.tempFilters = Object.assign({}, filters);
            return state;
        }, () => {
            this.loadFiltersData();
        });
    }
    onSetFilters = () => {
        var filters = this.state.tempFilters
        this.setState(state => {
            state.showFilters = false;
            state.currentFilters = Object.assign({}, filters);
            state.page = 1;
            state.viewType = "product";
            return state;
        }, () => {
            this.getCollections();
            this.loadData();
        });
    }
    onSortChange = (newSort) => {
        this.setState(state => {
            state.tempFilters.Sort = newSort;
            return state;
        });
    }
    onFilterChange = async (key, value) => {
        this.setState(state => {
            state.tempFilters[key] = value;
            return state;
        }, () => {
            this.loadFiltersData();
        });
    }
    onFieldFilterChange = (key) => (event, value) => {
        this.setState(state => {
            state.currentFilters[key] = value;
            state.tempFilters[key] = value;
            state.filterChanged = true;
            return state;
        }, () => {
            //this.loadData();
            //this.loadFiltersData();
        });
    }
    onFieldFilterBlur = (id) => () => {
        if (this.state.filterChanged) {
            this.setState({ filterChanged: false }, () => {
                this.loadData();
                this.loadFiltersData();
            });
        }
    }
    onFieldFilterKeyDown = (id) => (event) => {
        if (event.keyCode === 13) {
            this.setState({ filterChanged: false }, () => {
                this.loadData();
                this.loadFiltersData();
            });
        }
    }
    onItemClick = (index) => (item) => {
        this.setState({ currentIndex: index, currentItem: item, showItem: true/*, scrollTop: this.CardsCollection.current.scrollTop /*document.getElementById('mainPanel').scrollTop*/ }, () => { trackProductClick(this.getProductClickEvent(item.ID)); });
    }
    handleCatalgoItemClose = () => {

        this.setState({ currentItem: {}, itemStar: {}, showItem: false }, () => {
            document.getElementById('mainContent').scrollTo(0, this.state.scrollTop);
        });
    }
    onShowRemarks = () => {
        this.setState({ showRemarks: true });
    }
    onHideRemarks = () => {
        this.setState({ showRemarks: false });
    }
    handleSort = () => {
        this.setState(sort => {
            sort.showSort = !sort.showSort;
            return sort;
        });
    }

    static contextTypes = {
        router: PropTypes.object
    }

    renderLoading = () => {
        return <XOXLoader />
    }
    renderMobileStepper = (stepperVariant, pages, page) => {
        return (<MobileStepper
            variant={stepperVariant}
            steps={pages}
            position="static"
            activeStep={page - 1}
            backButton={
                <Button size="small" onClick={this.previousPage} disabled={page === 1 || this.state.isLoadingPage}>
                    <KeyboardArrowLeft />
                </Button>
            }
            nextButton={
                <Button size="small" onClick={this.nextPage} disabled={page === pages || this.state.isLoadingPage}>
                    <KeyboardArrowRight />
                </Button>
            }

        />)
    }
    showBrands = () => {
        if (this.state.viewType === "brand") {
            return;
        }
        this.setState(state => {
            state.viewType = "brand";
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            state.currentFilters = Object.assign({}, defaultFilters);
            
            state.FIDBrand = undefined;
            state.FIDLine = undefined;
            state.FIDSeries = undefined;

            state.tempFilters = Object.assign({}, state.currentFilters);
            state.FIDCausalView = undefined;
            state.showItem = false;

            state.page = 1;
            return state;
        }, () => {
            this.loadFiltersData();
            this.loadData();
            this.setCollectionTitle();
        });
    }
    showLines = () => {
        if (this.state.viewType === "line") {
            return;
        }
        this.setState(state => {
            state.viewType = "line";
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            var FIDBrand = state.FIDBrand;
            state.currentFilters = Object.assign({}, defaultFilters);
            state.FIDBrand = FIDBrand;
            state.FIDLine = undefined;
            state.FIDSeries = undefined;
            state.tempFilters = Object.assign({}, state.currentFilters);
            state.FIDCausalView = undefined;
            state.page = 1;
            return state;
        }, () => {
            this.loadFiltersData();
            this.loadData();
            this.setCollectionTitle();
        });
    }
    showSeries = () => {
        if (this.state.viewType === "series") {
            return;
        }
        this.setState(state => {
            state.viewType = "series";
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;

            var FIDBrand = state.FIDBrand;
            var FIDLine = state.FIDLine;

            state.currentFilters = Object.assign({}, defaultFilters);
            state.FIDBrand = FIDBrand;
            state.FIDLine = FIDLine;
            state.FIDSeries = undefined;
            state.tempFilters = Object.assign({}, state.currentFilters);
            state.page = 1;
            state.showItem = false;
            return state;
        }, () => {
            this.loadFiltersData();
            this.loadData();
            this.setCollectionTitle();
        });
    }
    showProducts = () => {
        if (this.state.viewType === "product" && !this.state.FIDCausalView) {
            return;
        }
        this.setState(state => {
            state.viewType = "product";
            state.page = 1;
            state.FIDCausalView = undefined;
            if (state.oldFilters) {
                state.currentFilters = Object.assign({}, state.oldFilters);
            }
            if (state.oldTempFilters) {
                state.tempFilters = Object.assign({}, state.oldTempFilters);
            }
            state.oldFilters = undefined;
            state.oldTempFilters = undefined;
            state.showItem = false;
        }, () => {
            this.loadData();
        });
    }
    onItemEditClick = (data) => {
        //save cookies
        setCookie('catalogue_state', JSON.stringify(this.state));
        this.context.router.history.push('/BOPEdit/' + data.ID);
    }
    onCollectionCardClick = (viewType, data) => {
        var { collections } = this.state;
        switch (viewType) {
            case "brand":
                this.setState(state => {
                    var count = collections.lines.filter(item => item.FIDBrand === data.FIDBrand).length;
                    if (count <= 1) {
                        state.viewType = "product";
                    }
                    else {
                        state.viewType = "line";
                    }
                    state.FIDBrand = data.FIDBrand;
                    //state.tempFilters = Object.assign({}, state.currentFilters);
                    return state;
                }, () => {
                    this.loadFiltersData();
                    if (this.state.viewType === "product") {
                        this.loadData();
                    }
                    this.setCollectionTitle();
                });
                break;
            case "line":
                this.setState(state => {
                    var count = collections.series.filter(item => item.FIDLine === data.FIDLine).length;
                    if (count <= 1) {
                        state.viewType = "product";
                    }
                    else {
                        state.viewType = "series";
                    }
                    state.FIDLine = data.FIDLine;
                    //state.tempFilters = Object.assign({}, state.currentFilters);
                    return state;
                }, () => {
                    this.loadFiltersData();
                    if (this.state.viewType === "product") {
                        this.loadData();
                    }
                    this.setCollectionTitle();
                });
                break;
            case "series":
                this.setState(state => {
                    state.viewType = "product";
                    state.FIDSeries = data.FIDSeries;
                    //state.tempFilters = Object.assign({}, state.currentFilters);
                    return state;
                }, () => {
                    this.loadFiltersData();
                    if (this.state.viewType === "product") {
                        this.loadData();
                    }
                    this.setCollectionTitle();
                });
                break;
            default:
                break;
        }
    }
    setCollectionTitle = async () => {
        var { FIDBrand, FIDLine, FIDSeries } = this.state;
        var title = "";
        if (FIDBrand) {
            var brandInfo = await fetch('/BOP/GetBrandInfo/' + FIDBrand).then(res => res.json());
            title = brandInfo.Brand;
        }
        if (FIDLine) {
            var lineInfo = await fetch('/BOP/GetLineInfo/' + FIDLine).then(res => res.json());
            if (title !== "") {
                title += " / ";
            }
            title += lineInfo.Line;
        }
        if (FIDSeries) {
            var seriesInfo = await fetch('/BOP/GetSeriesInfo/' + FIDSeries).then(res => res.json());
            if (title !== "") {
                title += " / ";
            }
            title += seriesInfo.Series;
        }
        if (title !== "") {
            this.props.setTitle(title);
        }
        else {
            this.props.setTitle(undefined);
        }
    }
    onExpand = (index) => (isExpanded) => {
        this.setState(state => {
            state.data[index].cardIsExpanded = isExpanded;
            return state;
        }, () => {

            this.CardsCollection.current.recomputeCellSizesAndPositions();
                index++;
        });
    }
    getRowTop = (row, cols, index) => {
        var { data } = this.state;
        //574.23
        var sum = 0;

        var { height } = this.getCardSize();
        var maxRow = height;
        var expPanel = 139.62;
        var lastIndex = (index - (index % cols));
        for (var i = 0; i < lastIndex; i++) {
            if (data[i].cardIsExpanded) {
                maxRow = height + expPanel;
            }
            if (i % cols === 0) {
                sum += maxRow;
                maxRow = height;
            }
        }
        sum += maxRow - height;
        
        return sum;
    }
    getNumberOfCols = () => {
        var cols = 4;
        switch (this.props.width) {
            case "xl":
                cols = 4;
                break;
            case "lg":
                cols = 3;
                break;
            case "md":
            case "sm":
                cols = 2;
                break;
            case "xs":
                cols = 1;
                break;
            default:
                break;
        }
        return cols;
    }
    getCardSize = () => {
        var { contentWidth } = this.state;
        var cols = this.getNumberOfCols();
        var tempWidth = 405.75;
        var tempHeight = 434.61;
        var width = (contentWidth - getScrollbarWidth()) / cols;
        
        var height = width * tempHeight / tempWidth;
        if (!this.props.showExpansionPanel) {
            height = height - 48;
        }
        return { width, height };
    }
    getStandardContent = () => {
        var { customer, storeid, storeareaid } = this.props;
        var { data, currentFilters, showFiltersRow, contentWidth } = this.state;

        var height = 100;
        var width = contentWidth;
        var mainContent = document.getElementById('mainContent');
        
        if (mainContent) {
            height = mainContent.clientHeight - 64;
        }
       
        height = window.innerHeight - 72.5;
        var collection = <Collection
            onScroll={event => {
                //this.setState({ scrollTop: event.scrollTop });
            }}
            scrollToCell={this.state.currentIndex}
            ref={this.CardsCollection}
            cellCount={data.length}
            cellRenderer={({ index, key, style }) => {
                var item = data[index];
                key = item.ID;
                if (!key) {
                    key = index;
                }
                var tempItem = { };
                if (!this.state.isLoadingPage) {
                    tempItem = item;
                }
                style.margin = 8;
                style.height = undefined;
                return (
                    <CustomerCatalogueCard showExpansionPanel={this.props.showExpansionPanel} isExpanded={tempItem.cardIsExpanded} style={style} key={key} isLoading={this.state.isLoadingPage} data={tempItem} onItemClick={this.onItemClick(index)} onExpand={this.onExpand(index)} customer={customer} storeid={storeid} storeareaid={storeareaid} />
                );
            }}
            cellSizeAndPositionGetter={({ index }) => {
                var cols = this.getNumberOfCols();

                var { width, height } = this.getCardSize();
                var top = this.getRowTop((index - (index % cols)), cols, index);
                return {
                    height: height - 16,
                    width: width - 16,
                    x: (index % cols) * width,
                    y: top,
                };
            }}
            height={height}
            width={width}
        />;
        return (<Fragment>

            {showFiltersRow && (
                <Paper style={{ padding: "10px", position: "sticky", zIndex: 1050, top: 62, left: 0 }}>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Group} label="Gruppo" id="Group" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Typology} label="Tipologia" id="Typology" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Category} label="Tag" id="Category" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Brand} label="Marca" id="Brand" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Line} label="Collezione" id="Line" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Code} label="Referenza" id="Code" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Description} label="Descrizione" id="Description" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.SerialNumber} label="Seriale" id="SerialNumber" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Barcode} label="Barcode" id="Barcode" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                        <Grid xs={12} sm={6} md={3} lg={2} xl={1} item><XField value={currentFilters.Position} label="Punto vendita" id="Position" onChange={this.onFieldFilterChange} onBlur={this.onFieldFilterBlur} onKeyDown={this.onFieldFilterKeyDown} /></Grid>
                    </Grid>
                </Paper>
            )}
            {collection}
        </Fragment>);
    }
    getSingleItemContent = () => {
        var { classes, customer, storeid, storeareaid } = this.props;
        var { FIDIdentity, currentItem, itemStar } = this.state;
        var onItemEditClick = this.onItemEditClick;
        if (!this.props.menus.includes("bopedit")) {
            onItemEditClick = undefined;
        }
        
        //if (JSON.stringify(itemStar) !== JSON.stringify({})) {
        //    currentItem = Object.assign({}, itemStar);
        //}
        
        var content = (
            <Paper className={classes.root}>
                <CustomerCatalogueItem ref={this.CatalogueItem} isCatalog={this.props.isCatalog} onEditClick={false} data={currentItem} open={this.state.showItem} onClose={this.handleCatalgoItemClose} onShowRemarks={this.onShowRemarks} showRemarks={this.state.showRemarks} onHideRemarks={this.onHideRemarks} customer={customer} storeid={storeid} storeareaid={storeareaid} />
            </Paper>
        );
        return content;
    }
    getProductClickEvent = (id) => {
        var clientId = undefined;
        analytics(function (tracker) {
            clientId = tracker.get('clientId');
        });

        return {
            type: 'PRODUCT_CLICK',
            data: {
                bopID: id,
                sessionID: clientId
            }
        };
    }
    getFiltersContent = (viewType) => {
        var { showFilters, tempFilters } = this.state;
        //var filters = this.getFilters();
        var content = <XFilters open={showFilters} showOrder={true} filters={false} sort={tempFilters.Sort} onClose={this.onFilterClose} onFilterChange={this.onFilterChange} onFilterSet={this.onSetFilters} onClearFilters={this.onClearTempFilterButton} onSaveTemplate={false} onDeleteTemplate={false} onSortChange={this.onSortChange} />;
        return content;
    }
    getCollectionContent = (viewType) => {
        var { classes, customer, storeid, storeareaid } = this.props;
        var { collections, currentFilters, contentWidth } = this.state;

        var data = [];
        var showLabel = false;
        var key = viewType;
        var backgroundSize = "cover";
        switch (viewType) {
            case "series":
                
                data = collections.series;
                showLabel = true;
                break;
            case "line":
                data = collections.lines;
                showLabel = true;
                break;
            case "brand":
            default:
                data = collections.brands;
                backgroundSize = "contain";
                break;
        }

        if (currentFilters.FIDBrand) {
            data = data.filter(i => i.FIDBrand === currentFilters.FIDBrand);
        }
        if (currentFilters.FIDLine) {
            data = data.filter(i => i.FIDLine === currentFilters.FIDLine);
        }
        var height = window.innerHeight - 72.5 - 64 - 64;
        var width = contentWidth;

        var collection = <Collection
            key={key}
            ref={this.BLSCollection}
            className={classes.collectionWrap}
            cellCount={data.length}
            cellRenderer={({ index, key, style }) => {

                style.margin = 8;
                var item = data[index];
                key = item.ID;
                if (!key) {
                    key = index;
                }
                var tempItem = item;
                //if (!this.state.isLoadingPage) {
                //    tempItem = item;
                //}
                return (
                    <CollectionCard className={classes.collectionCard} key={key} style={style} showLabel={showLabel} backgroundSize={backgroundSize} viewType={viewType} isLoading={this.state.isLoadingPage} data={tempItem} onClick={this.onCollectionCardClick} customer={customer} storeid={storeid} storeareaid={storeareaid} />
                );
            }}
            cellSizeAndPositionGetter={({ index }) => {

                var cols = 4;
                switch (this.props.width) {
                    case "xl":
                        cols = 4;
                        break;
                    case "lg":
                        cols = 4;
                        break;
                    case "md":
                    case "sm":
                        cols = 3;
                        break;
                    case "xs":
                        cols = 2;
                        break;
                    default:
                        cols = 4;
                        break;
                }

                var width = (contentWidth - getScrollbarWidth()) / cols;
                var height = width;
                return {
                    height: height - 16,
                    width: width - 16,
                    x: (index % cols) * width,
                    y: ((index - (index % cols)) / cols) * height
                };
            }}
            height={height}
            width={width}
        />;

        return (<Fragment>
            {collection}
        </Fragment>);
    }

    onCodeSearch = () => {
        this.setState({ bopsSearch: true, bopsSearchType: "code", bopsSearchValue: "" });
    }
    onBarcodeSearch = () => {
        this.setState({ bopsSearch: true, bopsSearchType: "barcode", bopsSearchValue: "" });
    }
    onSerialSearch = () => {
        this.setState({ bopsSearch: true, bopsSearchType: "serial", bopsSearchValue: "" });
    }
    onSearchCancel = () => {
        this.setState({ bopsSearch: false, bopsSearchType: "", bopsSearchValue: "" });
    }
    onSearchConfirm = () => {
        this.doSearchConfirm();
    }
    onSearchBlur = (id) => (event) => {
        this.bopsSearch.current.focus();
    }
    onSearchChange = (id) => (event, value) => {
        this.setState({ bopsSearchValue: value });
    }
    onSearchKeyDown = (id) => (event, value) => {
        if (event.keyCode === 13) {
            this.doSearchConfirm();
        }
    }
    doSearchConfirm = () => {
        var { bopsSearchType, bopsSearchValue } = this.state;

        var key = "";
        switch (bopsSearchType) {
            case "code":
                key = "Code";
                break;
            case "barcode":
                key = "Barcode";
                break;
            case "serial":
                key = "SerialNumber";
                break;
            default:
                break;
        }

        var currentFilters = Object.assign({}, defaultFilters);
        var tempFilters = Object.assign({}, defaultFilters);
        currentFilters[key] = bopsSearchValue;
        tempFilters[key] = bopsSearchValue;
        this.setState({ currentFilters, tempFilters, filterChanged: true, viewType: 'product' }, () => {
            this.loadData();
            this.loadFiltersData();
            this.setState({ bopsSearch: false, bopsSearchType: "", bopsSearchValue: "" });
        });
    }

    render() {
        const tracker = new Tracker();

        var { width, classes, advancedNavigation } = this.props;
        var { count, showFilters, currentFilters, isLoading, collections, viewType, bopsSearch, bopsSearchType, bopsSearchValue } = this.state;

        if (isLoading) {
            //return this.renderLoading();
        }

        var filtersArray = this.getFiltersArray(currentFilters);


        var filterButtonHandler = this.handleFilterButton;
        if (["xs", "sm"].includes(width)) {
            filterButtonHandler = undefined;
        }

        var customActions = [];
       
        var content = this.getStandardContent();

        var showButtons = true;
        var showAppBar = true;

        var navActions = [];
        if (collections && advancedNavigation) {
            if (collections.brands.length > 1) {
                if (!viewType) {
                    viewType = "brand";
                }
                navActions.push({ name: this.props.labels.brands, icon: <AlphaBcircleOutline />, action: this.showBrands, selected: viewType === "brand" });
            }
            var linesCount = collections.lines.filter(item => {
                var result = true;
                if (this.state.FIDBrand)
                    result = result && item.FIDBrand === this.state.FIDBrand;
                /*if (this.state.oldFilters && this.state.oldFilters.FIDBrand)
                    result = result && item.FIDBrand === this.state.oldFilters.FIDBrand;*/
                return result;
            }).length;
            if (linesCount > 1) {
                if (!viewType) {
                    viewType = "line";
                }
                navActions.push({ name: this.props.labels.lines, icon: <AlphaLcircleOutline />, action: this.showLines, selected: viewType === "line" });
            }

            var seriesCount = collections.series.filter(item => {
                var result = true;
                if (this.state.FIDBrand)
                    result = result && item.FIDBrand === this.state.currentFilters.FIDBrand;
                if (this.state.FIDLine)
                    result = result && item.FIDLine === this.state.currentFilters.FIDLine;
                /*if (this.state.oldFilters && this.state.oldFilters.FIDBrand)
                    result = result && item.FIDBrand === this.state.oldFilters.FIDBrand;
                if (this.state.oldFilters && this.state.oldFilters.FIDLine)
                    result = result && item.FIDLine === this.state.oldFilters.FIDLine;*/
                return result;
            }).length;
            if (seriesCount > 1) {
                if (!viewType) {
                    viewType = "series";
                }
                navActions.push({ name: this.props.labels.series, icon: <AlphaScircleOutline />, action: this.showSeries, selected: viewType === "series" });
            }
            if (navActions.length >= 1) {
                var isSelected = viewType === "product" && !this.state.FIDCausalView;
                navActions.push({ name: this.props.labels.products, icon: <AlphaPcircleOutline />, action: this.showProducts, selected: isSelected });
            }
        }
        else {
            if (!viewType) {
                viewType = "product";
            }
        }
        if (viewType !== "product") {
            content = this.getCollectionContent(viewType);
        }


        if (showFilters) {
            showAppBar = false;
            content = this.getFiltersContent(viewType);
        }
        if (this.state.showItem) {
            showButtons = false;
            content = this.getSingleItemContent();
        }

        var direction = "right";
        if (["xs"].includes(width)) {
            direction = "down";
        }


        var label = <span><XNavigation actions={navActions} direction={direction} /></span>;
        if (showButtons) {
            var showCode = this.props.catalogueButtons.includes("code");
            var showBarcode = this.props.catalogueButtons.includes("barcode");
            var showSerial = this.props.catalogueButtons.includes("serial");
            var searchActions = popupBOPSearch(showCode && this.onCodeSearch, showBarcode && this.onBarcodeSearch, showSerial && this.onSerialSearch);
            customActions = customActions.concat(searchActions);
        }
        if (bopsSearch) {
            //content = getSearchContent();
            var { customContent, actions } = getBOPSearchContent(this.bopsSearch, bopsSearchType, bopsSearchValue, this.onSearchBlur, this.onSearchKeyDown, this.onSearchChange, this.onSearchCancel, this.onSearchConfirm)
            content = customContent;
            customActions = actions;
        }
        if (!this.state.showItem && isLoading) {
            content = this.renderLoading();
        }
        return (
            <div id="mainContent" className={classes.content}>
                <Paper className={classes.root}>
                    <Prompt when={true} message={this.handlePageExit} />
                    <XMessageBox ref={this.MessageBox} />
                    {showAppBar && <AppBar position="sticky">
                        <EnhancedTableToolbar
                            onFilterButton={showButtons && filterButtonHandler}
                            onAdvancedFilterButton={false}
                            onClearFilterButton={showButtons && filtersArray && filtersArray.length > 0 && this.onClearFilterButton}
                            numSelected={0}
                            selectedAll={false}
                            count={count}
                            actions={customActions}
                            grid={this}
                            label={label}
                        />
                    </AppBar>}
                    <TrackerProvider tracker={tracker}>
                        {content}
                    </TrackerProvider>
                </Paper>
            </div>
        );
    }
}
const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withWidth(),
    withStyles(styles)
);


export default enhance(CustomerCatalogue);