import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme =>
    createStyles({
        container: {
            // display: 'flex',
        },
        label: {
            margin: '12px',
        },
        button: {
            margin: '6px',
            backgroundColor: "#e2b231",
            borderRadius: '15px',
        },
        relevantWhiteSpaces: {
            whiteSpace: 'pre',
        },
    }));

export default function ShowSavedData(props){
    const classes = useStyles();
    let [customerPaymentMethod, setCustomerPaymentmethod] = useState(null);

    useEffect(() => {
        fetch(`/Payments/GetCreditCardDetails?paymentMethodId=${props.creditCard}`)
        .then(response => response.json())
        .then(responseBody => setCustomerPaymentmethod(responseBody.card))
    }, [props.creditCard]);


    return (
        <div className={classes.container}>
            <Typography variant="h6">Email: {props.email}</Typography>
            <Typography className={classes.relevantWhiteSpaces}
                         variant="h6">
                                        Credit card:
                                        {customerPaymentMethod ? 
                                            ('   ' + customerPaymentMethod.brand + '     •••• •••• •••• ' + customerPaymentMethod.last4 + 
                                            '   ' + customerPaymentMethod.exp_month + '/' + customerPaymentMethod.exp_year)
                                        :   '*     •••• •••• •••• ••••    */*'
                                        }
            </Typography>
        </div>
    );
}