import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';

import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import BandwidthWarning from '../BandwidthWarning/BandwidthWarning';
import NetworkQualityLevel from '../NewtorkQualityLevel/NetworkQualityLevel';
import ParticipantConnectionIndicator from './ParticipantConnectionIndicator/ParticipantConnectionIndicator';
import PinIcon from './PinIcon/PinIcon';
import ScreenShare from '@material-ui/icons/ScreenShare';
import VideocamOff from '@material-ui/icons/VideocamOff';

import useParticipantNetworkQualityLevel from '../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';
import usePublications from '../../hooks/usePublications/usePublications';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import useTrack from '../../hooks/useTrack/useTrack';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      margin: 'auto',
      //height: `${(theme.sidebarWidth * 9) / 16}px`,
      height: 130, //(16/9 rispetto alla larghezza)
      width: 250,
      overflow: 'hidden',
      cursor: 'pointer',
      '& video': {
        filter: 'none',
      },
      '& svg': {
        stroke: 'black',
        strokeWidth: '0.8px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '0',
        width: '0',
      },
    },
    isVideoSwitchedOff: {
      '& video': {
        filter: 'blur(4px) grayscale(1) brightness(0.5)',
      },
    },
    infoContainer: {
      // position: 'absolute',
      zIndex: 1,
      display: 'flex',
      // flexDirection: 'column',
      // justifyContent: 'space-between',
      height: '100%',
      padding: '0.4em',
      width: '100%',
      background: 'transparent',
      flex: 1,
      maxWidth: 30,
      minWidth: 30,
    },
    hideVideo: {
      // background: 'black',
    },
    identity: {
      margin: 0,
      display: 'block',
      alignItems: 'center',
      position: 'relative',
    },
    infoRow: {
      display: 'flex',
      flexFlow: 'column'
      // justifyContent: 'space-between',
    },
    video: {
      flex: 3,
      width: 'unset'
    }
  })
);

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick: () => void;
  isSelected: boolean;
}

export default function ParticipantInfo({ participant, onClick, isSelected, children }: ParticipantInfoProps) {
  const publications = usePublications(participant);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => p.trackName.includes('camera'));

  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);
  const isVideoEnabled = Boolean(videoPublication);
  const isScreenShareEnabled = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack;

  const classes = useStyles();

  return (
    <div className={clsx(classes.container, { [classes.isVideoSwitchedOff]: isVideoSwitchedOff })} onClick={onClick} data-cy-participant={participant.identity}>
      <div className={classes.video}>
        {isVideoSwitchedOff && <BandwidthWarning />}
        {children}
      </div>
      <div className={clsx(classes.infoContainer, { [classes.hideVideo]: !isVideoEnabled })}>
        <div className={classes.infoRow}>
          <h4 className={classes.identity}>
            <ParticipantConnectionIndicator participant={participant} />
          </h4>
          <NetworkQualityLevel qualityLevel={networkQualityLevel} />
          <AudioLevelIndicator audioTrack={audioTrack} background="white" />
          {/* {!isVideoEnabled && <VideocamOff />} */}
          {/* {isScreenShareEnabled && <ScreenShare />} */}
          {/* {isSelected && <PinIcon />} */}
        </div>
      </div>
    </div>
  );
}
