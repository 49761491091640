import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core';

import classNames from 'classnames';
import color from 'color';

const styles = theme => ({
    
    row: {
        position: 'relative',
        width: '100%',
        fontSize: 0,
        padding: '5px 0',
        cursor: props => props.onClick ? "pointer" : "initial",
        '& *': {
            cursor: props => props.onClick ? "pointer" : "initial",
        },
        '&:nth-child(odd)': {
            backgroundColor: color(theme.palette.secondary.light).hsl().alpha(0.05).string()
        },
        '&:after': {
            content: '" "',
            display: "block",
            width: "100%",
            height: "1px",
            backgroundColor: color(theme.palette.secondary.light).hsl().alpha(0.2).string(),
            position: 'absolute',
            bottom: 0,
        },
        '&:before': {
            content: '" "',
            display: "inline-block",
            verticalAlign: 'middle',
            width: 0,
            height: 42,
        }
    },
});



const CashRow = withStyles(styles)(({classes, children, onClick, className}) => {
    return <div className={classNames(classes.row,className)} onClick={onClick}>{children}</div>;
});
export default CashRow;