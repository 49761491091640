import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';
import CashRow from '../Cash/CashRow';
import CashCell from '../Cash/CashCell';
import XButton from '../../../components/XButton';

const styles = theme => ({
    root: {

    },
    causalSelector: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#fff',
        zIndex: 1,
        margin: 8,
    },
    button: {
        width: '100%',
    }
});


class RepairCausal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            causals:[],
            showCausalSelector: false,
        }
    }

    componentDidMount() {
        this.loadData();
    }
    loadData = async () => {
        var { FIDDocumentType,FIDDocumentSubtype,FIDCycle, CausalsFilter } = this.props;
        var data = await fetch('/doc/causal').then(res => res.json());


        var causals = data.filter(item => item.FIDDocumentType === FIDDocumentType && item.FIDDocumentSubtype === FIDDocumentSubtype && item.FIDCycles.includes(FIDCycle) && CausalsFilter.includes(item.ID));
        this.setState({causals});
    }
    onCausalClick = () => {
        this.setState({showCausalSelector: true});
    }
    onCancelClick = () => {
        this.setState({showCausalSelector: false});
    }
    onCausalSelection = (id) => () => {
        var { onCausalChange } = this.props;
        onCausalChange && onCausalChange(id);
        this.setState({showCausalSelector: false});
    }
    render() {
        var { FIDCausal, classes, confirmed } = this.props;
        var { causals, showCausalSelector } = this.state;

        var causal = causals.filter(item => item.ID === FIDCausal)[0];
        if(showCausalSelector){
            return (
                <div className={classes.causalSelector}>
                    {causals && causals.map(item => <CashRow key={item.ID} onClick={this.onCausalSelection(item.ID)}><CashCell>{item.Denomination}</CashCell></CashRow>)}
                    <CashRow>
                        <CashCell width={"100%"} totalWidth={"100%"}>
                            <XButton className={classes.button} onClick={this.onCancelClick}>CHIUDI</XButton>
                        </CashCell>
                    </CashRow>
                </div>
            );
        }
        return <CashRow>
            {causal && <CashCell label="Causale" width="100%" key={causal.ID} onClick={!confirmed && this.onCausalClick}>{causal.Denomination}</CashCell>}
        </CashRow>;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(RepairCausal);