import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

export default function GoogleTranslateUsageList(props) {
    if(props.data)
    {
        if(props.data.translations.length <= 0)
        {
            return <Typography>No cost retrivied for this month.</Typography>;
        }
        else
        return (
            <List dense>
                <ListItem divider>
                    <ListItemText id="price-label" primary={`Total price: ${props.data.totalPrice}$   Characters count: ${props.data.charCount}`}/>
                </ListItem>
                <ListItem divider>
                    <ListItemText id="date-header" primary="Date"/>
                    <ListItemText id="from-header" primary="From"/>
                    <ListItemText id="to-header" primary="To"/>
                    <ListItemText id="count-header" primary="Character Count"/>
                </ListItem>
                {props.data.translations.map(trans => {
                    return(
                    <div key={`div-${trans.id}`}>
                        <ListItem key={trans.id}>
                            <ListItemText primary={trans.date} />
                            <ListItemText primary={trans.languageFrom} />
                            <ListItemText primary={trans.languageTo} />
                            <ListItemText primary={`${trans.charCount} characters`} />                            
                        </ListItem>
                    </div>
                    )})
                }
            </List>
        )
    }
}