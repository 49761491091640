import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const style = () => ({
});

class Position extends Component {
    constructor(props) {
        super(props);
        //this.setState({ FIDParentColumnName: "Brand" });
        this.Editor = React.createRef();
    }
    state = {
        line: { FIDParent: 0 },
    };
    checkUsed = async (FIDPos) => {
		var isUsed = await fetch('/mag/position/IsUsed?id=' + FIDPos).then(res => res.json());
		debugger;
        return isUsed;     
    }
    deletePosition = (FIDPos) => {
        fetch('/mag/position/Delete/' + FIDPos, { method: "POST" }).then(res => {
			if (res) {
				this.Editor.current.setState({ snackbar: { open: true, message: "Posizione cancellata con successo" } }, () => {
					setTimeout(() => { this.Editor.current.doExit(); }, 1500)
				})
    }
        });
    }
    onDeleteClick = async() => {
        var id = this.props.match.params.id;

        var isUsed = await this.checkUsed(id);

        var label = "Confermi la cancellazione della posizione?";
        var buttons = [
            {
                label: "SI",
                action: () => {
                    this.Editor.current.hideDialog();
						this.deletePosition(id);
                }
            },
            {
                label: "NO",
                action: () => {
                    this.Editor.current.hideDialog();
                }
            },
        ];
        if (isUsed) {
            label = "Questa posizione contiene delle matricole di magazzino, o è utilizzata nei documenti, impossibile proseguire nella cancellazione.";
			buttons= [ 
				{
					label: "OK",
					action: () => {
						this.Editor.current.hideDialog();
					}
				},
			];
        } 

        this.Editor.current.showDialog(buttons, "Cancellazione", label);
        
    }
    render() {
        var fields = [
            {
                key: 'Abbreviation',
                label: 'Sigla',
                type: 'XField',
            },
            {
                key: 'Description',
                label: 'Descrizione',
                type: 'XField',
            },
            {
                key: 'FIDIdentity',
                label: 'Anagrafica',
                type: 'XSelect',
                getUrl: '/IR/GetIRLicenseeDestinationsSelect',
                saveUrl: undefined,
                idKey: 'ID',
                valueKey: 'Denomination',
                abbreviationKey: 'Abbreviation',
            },
            {
                key: 'Default',
                label: 'Predefinito',
                type: 'XSwitch',
						},
						{
							key: 'IsProduction',
							label: 'Produzione',
							type: 'XSwitch'
						}
        ];

        return <GenericEditor
            innerRef={this.Editor}
            id={this.props.match.params.id}
            urlSave='/mag/position/Save/'
            urlDelete='/mag/position/Delete/'
            urlRedirect='/magposition/'
			onDeleteClick={this.onDeleteClick}
            //urlUploadFiles='/BOP/LineFileUpload/'
            //urlDeleteFiles='/BOP/DeleteLineFile/'
            //urlGetFiles='/BOP/GetLineFiles/'
            //urlCheckDefaultFiles='/BOP/CheckDefaultLineFiles/'
            urlExit='/magpositions/'
            urlNew="/magposition/new"
            urlGetData='/mag/position/'
            urlCheckChanges='/mag/position/HasChanges/'
            urlClearSession='/mag/position/ClearSession/'
            //onDeleteClick={this.onDeleteClick}
            onlyCustomFields={true}
            fields={fields}
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(Position);
