import React, {useState, useEffect} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MeetingRoom from '@material-ui/icons/MeetingRoom';

export default function Meetings(props) {
    return (
        <List dense>
			{props.meetings.map(meeting => {
                const keyId = `Meeting${meeting.RoomName}`;
                return (
                <ListItem key={keyId}>
                    <ListItemText id={keyId+'_name'} primary={meeting.Name} />
                    <ListItemText id={keyId+'_desc'} primary={meeting.Description} />
                    <ListItemText id={keyId+'_time'} primary={meeting.StartTime} />
                    <IconButton onClick={() => props.joinMeeting(meeting)}><MeetingRoom /></IconButton>
                </ListItem>
                )})
            }
        </List>
    )
}
