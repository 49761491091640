import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, styled } from '@material-ui/core/styles';
import { Button, Tooltip, Grid } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Message from '@material-ui/icons/Message';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Participant from '../../videochat/components/Participant/Participant';
import useParticipants from '../../videochat/hooks/useParticipants/useParticipants';
import useSelectedParticipant from '../../videochat/components/VideoProvider/useSelectedParticipant/useSelectedParticipant';


import { Microphone, MicrophoneOff, Video, VideoOff, ExitToApp, Bell, Pin, PinOff } from 'mdi-material-ui';
import XRoundButton from './XRoundButton';
import XButton from 'components/XButton';
import useVideoContext from 'videochat/hooks/useVideoContext/useVideoContext';



const useStyles = makeStyles((theme) =>
    createStyles({
        bar: {
            width: '100%',
            height: '100%',
            backgroundColor: "white",
        },
        button: {
            position: 'absolute',
            right: '0',
            bottom: '0',
        },
        controlButtons: {
            display: 'flex',
        },
        action: {
            boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.80)',
            background: '#fff',
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
            '&:hover': {
                background: 'rgba(0, 0, 0, 0.08)',
                color: theme.palette.secondary.main,
                boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.80)',
            }
        }
    })
);

export default function VideochatActions(props) {
    const classes = useStyles();
    const [lastAction, setLastAction] = useState("");

    const participants = (props.roomParticipants && props.users) ?
        props.users.filter(user => props.roomParticipants.includes(user.id))
        : undefined
    const nonLicensee = participants ? participants.filter(user => user.isLicensee === false) : undefined


    const {
        room: { localParticipant },
        localTracks,
        getLocalVideoTrack,
      } = useVideoContext();
    
    
      const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
    const onFix = () => {
        props.forcePinChange(true)
        if(localParticipant !== selectedParticipant){
            setSelectedParticipant(localParticipant);
          }
    }
    const onUnfix = () => {
        props.forcePinChange(false)
        setSelectedParticipant(null);
    }

    return (
        <div className={classes.bar}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <XRoundButton toolTip="Attiva" placement="bottom" onClick={() => { setLastAction("UNMUTE_MIC"); nonLicensee.map((user) => { props.handleUnmuteMic(user) }) }}><Microphone style={{ color: lastAction === "UNMUTE_MIC" ? 'green' : "inherit" }} /></XRoundButton>
                </Grid>
                <Grid item xs={3}>
                    <XRoundButton toolTip="Disattiva" placement="bottom" onClick={() => { setLastAction("MUTE_MIC"); nonLicensee.map((user) => { props.handleMuteMic(user) }) }}><MicrophoneOff style={{ color: lastAction === "MUTE_MIC" ? 'red' : "inherit" }} /></XRoundButton>
                </Grid>
                <Grid item xs={3}>
                    <XRoundButton toolTip="Sblocca" placement="bottom" onClick={() => { setLastAction("ENABLE_MIC"); nonLicensee.map((user) => { props.handleEnableMic(user) }) }}><Microphone style={{ color: lastAction === "ENABLE_MIC" ? 'green' : "inherit" }} /></XRoundButton>
                </Grid>
                <Grid item xs={3}>
                    <XRoundButton toolTip="Blocca" placement="bottom" onClick={() => { setLastAction("DISABLE_MIC"); nonLicensee.map((user) => { props.handleDisableMic(user) }) }}><MicrophoneOff style={{ color: lastAction === "DISABLE_MIC" ? 'red' : "inherit" }} /></XRoundButton>
                </Grid>
                <Grid item xs={3}>
                    <XRoundButton toolTip="Attiva" placement="bottom" onClick={() => { setLastAction("UNMUTE_VIDEO"); nonLicensee.map((user) => { props.handleUnmuteVideo(user) }) }}><Video style={{ color: lastAction === "UNMUTE_VIDEO" ? 'green' : "inherit" }} /></XRoundButton>
                </Grid>
                <Grid item xs={3}>
                    <XRoundButton toolTip="Disattiva" placement="bottom" onClick={() => { setLastAction("MUTE_VIDEO"); nonLicensee.map((user) => { props.handleMuteVideo(user) }) }}><VideoOff style={{ color: lastAction === "MUTE_VIDEO" ? 'red' : "inherit" }} /></XRoundButton>
                </Grid>
                <Grid item xs={3}>
                    <XRoundButton toolTip="Sblocca" placement="bottom" onClick={() => { setLastAction("ENABLE_VIDEO"); nonLicensee.map((user) => { props.handleEnableVideo(user) }) }}><Video style={{ color: lastAction === "ENABLE_VIDEO" ? 'green' : "inherit" }} /></XRoundButton>
                </Grid>
                <Grid item xs={3}>
                    <XRoundButton toolTip="Blocca" placement="bottom" onClick={() => { setLastAction("DISABLE_VIDEO"); nonLicensee.map((user) => { props.handleDisableVideo(user) }) }}><VideoOff style={{ color: lastAction === "DISABLE_VIDEO" ? 'red' : "inherit" }} /></XRoundButton>
                </Grid>
                <Grid item xs={3}>
                    <XRoundButton toolTip="Fissa" placement="bottom" onClick={() => { setLastAction("DISABLE_LIVE"); onFix() }}><Pin style={{ color: lastAction === "DISABLE_LIVE" ? 'green' : "inherit" }} /></XRoundButton>
                </Grid>
                <Grid item xs={3}>
                    <XRoundButton toolTip="Live" placement="bottom" onClick={() => { setLastAction("ENABLE_LIVE"); onUnfix() }}><PinOff style={{ color: lastAction === "ENABLE_LIVE" ? 'red' : "inherit" }} /></XRoundButton>
                </Grid>
                <Grid item xs={12}>
                    <XButton className={classes.action} fullWidth color={"secondary"} onClick={() => participants.map((user) => { props.handleForceQuit(user) })}>TERMINA MEETING</XButton>
                </Grid>

            </Grid>
        </div>
    )
}