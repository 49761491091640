import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';

import Keyboard from 'react-simple-keyboard';
import layoutItalian from "simple-keyboard-layouts/build/layouts/italian";

import 'react-simple-keyboard/build/css/index.css';

const styles = theme => ({
    root: {

    }
});


class XKeyboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shift: false,
        }
    }
    
    onChange = (input) => {
        console.log("Input changed", input);
    }

    onKeyPress = (button) => {
        if(button === "shift"){
            this.setState(state => {
                state.shift = !state.shift;
                return state;
            })
        }
    }


    render() {
        var {type} = this.props;
        var { shift } = this.state;
        var layoutName = "default";
        var layoutToUse = layoutItalian;
        if(type === "numeric"){
            //layoutName=type;
            layoutToUse= {
                default: ["1 2 3", "4 5 6", "7 8 9", "{shift} 0 _", "{bksp}"],
                shift: ["! / #", "$ % ^", "& * (", "{shift} ) +", "{bksp}"]
              };
        }
        if(shift){
            layoutName = "shift";
        }
        return <Keyboard
            layoutName={layoutName}
            layout={layoutToUse}
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
        />;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(XKeyboard);