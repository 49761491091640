import React from 'react';
import XDateTimeField from '../../components/XDateTimeField'

export default function MeetingDatePicker(props){
    return (<div>
            <XDateTimeField 
                            label="Meeting Start Date" 
                            id="start" 
                            value={props.startDate} 
                            onChange={id => date => props.handleStartChange(date)} />
            <XDateTimeField 
                            label="Meeting End Date" 
                            id="end" 
                            value={props.endDate} 
                            onChange={id => date => props.handleEndChange(date)} />
        </div>
    )
}