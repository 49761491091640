import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import InputSimple from '../InputSimple';

const useStyles = makeStyles(theme =>
    createStyles({
        emailContainer: {
            display: 'flex',
            minWidth: '500px',
            margin: '6px',
        },
        label: {
            margin: '12px',
        },
        input: {
            border: '1px solid gray',
            margin: '12px',
            backgroundColor: "white",
            borderRadius: '15px',
            width: '500px',
        },
        button: {
            margin: '6px',
            backgroundColor: "#e2b231",
            borderRadius: '15px'
        }
    }));

export default function EmailInsert(props){    
    const classes = useStyles();

    const [validEmail, setValidEmail] = useState(false);
    const [email, setEmail] = useState(null);

    useEffect(()=> {
        setEmail(props.email)
    }, [props.email])

    const handleSubmitEmail = (evt) => {
        evt.preventDefault();
        fetch(`/Payment/CreateCustomer?email=${email}`)
            .then(() => props.onSavedCustomer())
            .catch(err => console.log(err))
    };

    const validateEmail = (email) => {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(email);
    }

    return (        
        <div>
            <div className={classes.emailContainer}>
                <Typography className={classes.label} variant="h6">EMAIL:</Typography>
                <div className={classes.input}>
                    <InputSimple
                        id="email"
                        value={email}
                        onChange={(value) => {
                            setValidEmail(validateEmail(value))
                            setEmail(value)
                        }}
                        placeholder="Email address"
                        disabled = {props.email}
                    />
                </div>
                <Button
                    id="email-submit"
                    className= {classes.button}
                    disabled = {!validEmail || props.email}
                    onClick={handleSubmitEmail}>
                    Sign up
                </Button>
            </div>
        </div>
    )
}