import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as userSignalCreators } from './UserSignaler';
import { actionCreators as userAuthCreators} from '../../store/Auth';
import { withStyles } from '@material-ui/core/styles';
import MeetingDatePicker from './MeetingDatePicker';
import MeetingUserGrid from './MeetingUserGrid';
import Event from '@material-ui/icons/Event';
import { Tooltip } from '@material-ui/core';
import { fade, createStyles, makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import InputSimple from './InputSimple';


const style = (theme) =>({
    inputContainer:{
        display: 'flex',
    },
    inputs: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.25),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.45),
        },
        margin: theme.spacing(1),
        width: '40%',
    },
    fab:{
        margin: theme.spacing(1),
    },
});

class MeetingManagement extends React.Component
{
    initState = {
        name: "",
        description: "",
        users: [],
        selectedUsers: [],
        startDate: new Date(),
        endDate: new Date()
    }
    constructor(props){
        super(props)
        
        this.state = {...this.initState}
    }    

    componentDidMount() {
        fetch('/XLIVE/GetAllUsers')
            .then(res => res.json()
                .then(data => {
                    this.initState.users = data;
                    this.setState({users:data})
                })
        );
    }

    handleSetMeeting = () => {
        const res = this.props.setupMeeting(this.state.name, 
                                this.state.description, 
                                this.state.startDate, 
                                this.state.startDate, 
                                this.state.endDate, 
                                this.state.selectedUsers.map(user => user.id))
        if(res){
            //tutto ok
            this.setState({...this.initState})
        }
        else{
            //errore registrando il meeting
        }
    }

    selectUsers = (users) => {
        var newSelected = []
        users.map(user => {
            if(this.state.selectedUsers.findIndex(check => check.id === user.id) === -1){
                newSelected = [...newSelected, user]
            }
        })
        this.setState({selectedUsers:[...this.state.selectedUsers, ...newSelected]});
    }
    deselectUsers = (users) => {
        const newSelected = this.state.selectedUsers.filter(check => {
            return (users.findIndex(user => check.id === user.id) === -1)
        })
        this.setState({selectedUsers:newSelected});
    }

    setStartDate=(date)=>{
        this.setState({startDate:date});
    }
    setEndDate=(date)=>{
        this.setState({endDate:date});
    }

    render(){
    return  (<>
                <div className={this.props.classes.inputContainer}>
                    <div className={this.props.classes.inputs}>
                        <InputSimple
                            placeholder="Meeting name"
                            inputProps={{ 'aria-label': 'search' }}
                            value = {this.state.name}
                            onChange = {(value) => this.setState({name:value})}
                        />
                        <InputSimple
                            placeholder="Meeting description"
                            inputProps={{ 'aria-label': 'search' }}
                            value = {this.state.description}
                            onChange = {(value) => this.setState({description:value})}
                        />
                    </div>                
                    <div style={{width:'40%'}}>
                        <MeetingDatePicker 
                            startDate = {this.state.startDate}
                            handleStartChange = {this.setStartDate}
                            endDate = {this.state.endDate}
                            handleEndChange = {this.setEndDate}
                        />
                    </div>
                    <Tooltip title="SetMeeting" placement="top" onClick={this.handleSetMeeting}>
                        <Fab className={this.props.classes.fab} color="primary">
                            <Event />
                        </Fab>
                    </Tooltip>
                </div>
                <MeetingUserGrid
                    allUsers = {this.state.users}
                    selectedUsers = {this.state.selectedUsers}
                    selectUsers = {this.selectUsers}
                    deselectUsers = {this.deselectUsers}
                />
            </>)
    }
}

const enhance = compose(
    connect(
        state => state.userSignaler,
        dispatch => bindActionCreators(userSignalCreators, dispatch)
    ),
    connect(
       state => state.auth,
        dispatch => bindActionCreators(userAuthCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(MeetingManagement);