import React, {useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MonthYearSelector from './MonthYearSelector';

const useStyles = makeStyles(theme =>
    createStyles({
        loadingSpinner: {
            left: '50%',
            top: '50%',
            padding: '12px'
        },
        nested: {
          paddingLeft: theme.spacing(4),
        },
}));
    
export default function UsageTab(props){
    const classes = useStyles();
    const [data, setData] = useState(undefined)	
    const [fetching, setFetching] = useState(false)	
    
    const requireUsage = (month, year) => {
        setFetching(true);
        setData(undefined);
        fetch(`${props.url}?month=${month}&year=${year}`)
        .then(res => res.json())
        .then(jdata => {
            setData(jdata);
        })
        .catch(err => {
            setData([]);
            console.log(`Error fetching ${props.url}: ${err}`)
        })
        .finally(() => {
            setFetching(false);
        })
    };

    const UsageList = props.usageList;

	return (
        <div>
            <MonthYearSelector
                onOK = {requireUsage}
            />
            <div>
                {fetching ? 
                    (<CircularProgress className={classes.loadingSpinner} />)
                    :
                    data ? 
                        <UsageList data={data} /> 
                        : null
                }
            </div>
        </div>
    )
}