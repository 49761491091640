import React, { Component } from 'react';

import PropTypes from 'prop-types';
import XGrid from '../../components/XGrid';
import XSelect from '../../components/XSelect';
import XSwitch from '../../components/XSwitch';
import XField from '../../components/XField';
import XDialog from '../../components/XDialog';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import { ArrowLeft } from 'mdi-material-ui'
import { Save } from '@material-ui/icons';
import { Tooltip, IconButton } from '@material-ui/core';
import { getXField, getXDateField, getXSelect, getXSwitch } from '../../utils';
import { actionCreators as localizationCreators, getTranslation } from 'store/Localization';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

const styles = theme => ({
    root: {
        width: "100%",
    },
    button: {
        marginTop: 16,
        marginBottom: 14,
    }
});
const initialData = {
    Description: "",
    RegistrationDate: new Date(),
    DueDate: new Date(),
    Plafond: 0,
    Number: "",
	Vat: "",
	VatPercentage: 0
}
class IRIntendDeclarations extends Component {
    constructor(props) {
        super(props);
        this.grid = React.createRef();
    }
    state = {
        data: Object.assign({}, initialData),
        isEditing: false,
        contactLabel: 'Recapito',
        dialog: {},
    };


    showDialog = (buttons,title, message) => {
        var dialog = {
            message,
            title,
            buttons,
            show: true
        };
        this.setState({ dialog });
    }

    saveIntent = (FIDIdentity, isDestination, isRelation, data) => {
        fetch('/IR/SaveIntentDeclaration/' + FIDIdentity + (isDestination ? "?IsDestination=true" : "") + (isRelation ? "?IsRelation=true" : ""), {
            body: JSON.stringify(data),
            method: 'POST'
        }).then(res => res.json())
            .then(result => {
               
                this.setState({ data: Object.assign({}, initialData), isEditing: false });
                //this.grid.current.getWrappedInstance().refresh();
            });
    }

    handleSaveContact = (event, caller) => {
        var { data } = this.state;
        var { FIDIdentity, isDestination, isRelation } = this.props;
        if (data.Description && data.RegistrationDate && data.Number) {
            this.saveIntent(FIDIdentity, isDestination, isRelation, data);
        }
    }
	
	handleFieldBlur = (key) => (event) => {
		if (key === "Plafond") {
			this.setState((state, props) => {
				if (!state.data.OriginalPlafond) {
					state.data.OriginalPlafond = state.data.Plafond;
				}
				
				return state;
			});
		}
	}

    handleFieldChange = (key) => (event, value) => {
        //this.props.onChange(event, value, key);
        this.setState((state, props) => {
            state.data[key] = value;
            return state;
        });
    }
    handleDataFieldChange = (key) => (value) => {
        this.setState((state, props) => {
            state.data[key] = value;
            return state;
        });
    }


    handleSelectChange = (key) => (event, value) => {
        var newVal = undefined;
        if (value.length > 0) {
            newVal = value[0].ID;
        }
        this.setState(state =>{
            state.data[key] = newVal
			
			if (key === "FIDVat") {
				if (value.length > 0)
					state.data.Vat = value[0].Denomination;
				else
					state.data.Vat = "";
			}
			
            return state;
        });
    }

    handleGridDoubleClick = (event, data) => {
		/*if (data.Used) {
			var buttons = [
                {
                    label: "OK",
                    action: () => {
                        this.setState({ dialog: {} });
                    }
                }
            ];
			this.showDialog(buttons, getTranslation(4, "IR_USEDINTENT", this.props.translations, "Dichiarazione d'intento già utilizzata, modifica non permessa"));
		}
		else {*/
		this.setState({ data, isEditing: true });
		/*}*/
    }
    handleGridEditButton = (event, data) => {
        this.handleGridDoubleClick(event, data);
    }
    handleCancelContact = (event) => {
        this.setState({ isEditing: false, data: Object.assign({}, initialData) });
    }
    handleGridNewButton = (event) => {
        this.setState({ isEditing: true });
    }
    refresh = () => {
        this.grid.current.getWrappedInstance().refresh();
    }
    render() {
        var { data, contactLabel } = this.state;
        var { FIDIdentity, isDestination, isRelation,readOnly } = this.props;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }
        var fields = [
            {
                Field: "FIDLicensee",
                Visibility: true,
                Required: 2,
            },
			{
				Field: "FIDVat",
				Visibility: true,
				Required: 2,
			},
            {
                Field: "Number",
                Visibility: true,
                Required: 2,
            },
            {
                Field: "RegistrationDate",
                Visibility: true,
                Required: 2,
            },
            {
                Field: "DueDate",
                Visibility: true,
                Required: 0,
            },
            {
                Field: "Plafond",
                Visibility: true,
                Required: 0,
            },
            {
                Field: "Description",
                Visibility: true,
                Required: 2,
            },
            {
                Field: "OriginalPlafond",
                Visibility: true,
                Required: 3,
            },
            {
                Field: "DeclarationType",
                Visibility: true,
                Required: 2,
            },
            {
                Field: "Closed",
                Visibility: true,
                Required: 3,
            },
        ];
        var customActions = [];
        var style = { margin: '-28px', width: 'calc(100% + ' + (28 * 2) + 'px)' };
        var divStyle = { width: 'calc(100% - 16px)', padding: 16 };
        var customContent = undefined; var showColumnsSelector = true;
        var showFilterActivator = true;
        var onNewButton = this.handleGridNewButton;
        var key = "grdIRContacts";
        var label = undefined;



        if (this.state.isEditing) {

            var fieldLicensee = getXSelect(data, fields, 12, 4, "FIDLicensee", "Licenziatario", this.handleSelectChange, "/IR/GetIRLicenseesSelect", "", { subtitleKey: "Address" });
			var fieldVat = getXSelect(data, fields, 12, 4, "FIDVat", "Aliquota IVA", this.handleSelectChange, "/gc/vat", "");
            var fieldNumber = getXField(data, fields, 12, 4, "Number", "Numero", this.handleFieldChange);
            var fieldRegistrationDate = getXDateField(data, fields, 12, 4, "RegistrationDate", "Data registrazione", this.handleDataFieldChange);
            var fieldDueDate = getXDateField(data, fields, 12, 4, "DueDate", "Data scadenza", this.handleDataFieldChange);
            var fieldPlafond = getXField(data, fields, 12, 4, "Plafond", "Plafond", this.handleFieldChange, { type: "number", readOnly: data.Used, onBlur: this.handleFieldBlur });
            var fieldOriginalPlafond = getXField(data, fields, 12, 4, "OriginalPlafond", "Plafond originale", this.handleFieldChange, { type: "number" });
            var fieldDescription = getXField(data, fields, 12, 12, "Description", "Descrizione", this.handleFieldChange);
            var fieldClosed = getXSwitch(data, fields, 12, 4, "Closed", "Chiuso", this.handleFieldChange);
            var fieldDeclarationType = getXSelect(data, fields, 12, 4, "DeclarationType", "Tipo dichiarazione", this.handleSelectChange,"/enums/intentdeclarationtype");

            customContent = <div style={divStyle}><Grid item container spacing={2} direction="row" sx={12}>
                {fieldLicensee}
				{fieldVat}
                {fieldNumber}
                {fieldRegistrationDate}
                {fieldDueDate}
                {fieldPlafond}
                {fieldOriginalPlafond}
                {fieldDeclarationType}
                {fieldClosed}
                {fieldDescription}
            </Grid></div>;

            key = "frmIRIntent";
            showColumnsSelector = false;
            showFilterActivator = false;
            customActions = [
				{
					group: 'actions',
					name: getTranslation(5,"GC_BACK", this.props.translations,'Indietro'),
					icon: <ArrowLeft />,
					action: this.handleCancelContact
				},
				{
					group: 'actions',
					name: getTranslation(5,"GC_SAVE", this.props.translations,'Salva'),
					icon: <Save />,
					action: this.handleSaveContact
				}
            ];
            onNewButton = undefined;
            label = getTranslation(4,"IR_NEW_INTENTDEC", this.props.translations,"Nuova dichiarazione d'intento")
        }
        var ui = <Grid item xs={12}>
            <XGrid
                containerID="IRIntentContainer"
                key={key}
                label={label}
                ref={this.grid}
                dataRoute={"/IR/GetIRIntentDeclarations/" + FIDIdentity + "?grid=tblIRIntendDeclarations" + (isDestination ? "&IsDestination=true" : "") + (isRelation ? "&IsRelation=true" : "")}
                dataRouteColumns="/Base/GetColumns?grid=tblIRIntendDeclarations"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRIntendDeclarations"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRIntendDeclarations"
                dataRouteDelete={!readOnly && "/IR/DeleteIntentDeclaration/" + FIDIdentity + "?grid=tblIRIntendDeclarations" + (isDestination ? "&IsDestination=true" : "") + (isRelation ? "&IsRelation=true" : "")}
                onDoubleClick={!readOnly && this.handleGridDoubleClick}
                onEditButton={!readOnly && this.handleGridEditButton}
                onNewButton={!readOnly && onNewButton}
                customContent={customContent}
                actions={customActions}
                rowsVisible={10}
                showColumnsSelector={showColumnsSelector}
                showFilterActivator={showFilterActivator}
            />
        </Grid>;

        return (
            <Grid container spacing={2} style={style}>
                {ui}  
                <XDialog message={this.state.dialog.message} title={this.state.dialog.title} open={this.state.dialog.show} buttons={this.state.dialog.buttons} />
            </Grid>
        );
    }
}


IRIntendDeclarations.propTypes = {
    classes: PropTypes.object.isRequired,
    FIDIdentity: PropTypes.number.isRequired,
    isIdentity: PropTypes.bool,
    isDestination: PropTypes.bool,
    isRelation: PropTypes.bool,
};


const enhance = compose(
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withStyles(styles)
);

export default enhance(IRIntendDeclarations);
