import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

export default function SMSUsageList(props) {
    const sendTestSMS = () => {
        const sms = {
            body: "messagio di test da numero twilio",
            onlySMS: true,
            userIds: [1]
        }

        fetch("/Notifier/Notify", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(sms)})
    }

    if(props.data && props.data.price && props.data.count)
    {   
        return (
            <List dense>
                <ListItem divider>
                    <ListItemText id="price-label" primary={`Total price: ${props.data.price}$  -  SMS count: ${props.data.count}`}/>
                </ListItem>
            </List>
        )
    }

    return (<div>
        <Typography>No cost retrivied for this month.</Typography>
        <button onClick={sendTestSMS}>
            Send test SMS
        </button>
    </div>)
}