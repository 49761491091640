import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Checkbox, IconButton, List, ListItem, ListItemText } from '@material-ui/core';

const useStyles = makeStyles(theme =>
    createStyles({
        productConditions: {
            display: 'flex',
            margin: '6px',
        },
        button: {
            margin: '6px',
            backgroundColor: "#e2b231",
            borderRadius: '15px'
        }
}));

export default function ProductTermsAcceptance(props) {
    const classes = useStyles();
    const [acceptedProducts, setAcceptedProducts] = useState([])
    const [products, setProducts] = useState(undefined)

    const fetchProducts = () => {
        setAcceptedProducts([])
        fetch('/Payments/GetAvailableProducts')
        .then(res=>res.json())
        .then(result => {
            setProducts(result.products)
        })
    }
    useEffect(() => {
        fetchProducts();
    }, [])

    const handleUpdateTerms = () => {
        const options = {
            customerId: props.customerId,
            paymentMethodId: props.paymentMethodId,
            acceptedProducts: [...acceptedProducts]
        };
        fetch(`/Payments/UpdateSubscription`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(options)})
            .then(response => response.json())
            .then((result) => {// If the card is declined, display an error to the user.
                console.log(result);
                if (result.error || result.stripeError)
                    throw result;// The card had an error when trying to attach it to a customer
                
                fetchProducts();
                return result;
            })
    }

    
    const toggleAcceptedCondition = (condition) => {
        if(acceptedProducts.findIndex(check => check === condition.key) === -1)
            setAcceptedProducts([...acceptedProducts, condition.key])
        else
            setAcceptedProducts(acceptedProducts.filter(check => check !== condition.key))
    }
    const renderCondition = (condition) => {
        const alreadyAccepted = products.findIndex(check => check.accepted && check.key === condition.key) !== -1;
        const newlyAccepted = acceptedProducts.findIndex(check => check === condition.key) !== -1
        return (
            <ListItem key = {condition.key}
                        disabled = {alreadyAccepted}>
                <Checkbox
                    checked={alreadyAccepted || newlyAccepted}
                    disabled = {alreadyAccepted}
                    onClick={() => {toggleAcceptedCondition(condition)}}
                />
                <ListItemText>{condition.name}</ListItemText>
            </ListItem>
        )
    }

    return (
        <div>
            <div className={classes.productConditions}>
                {products && 
                <List dense>
                    {products.map(condition => renderCondition(condition))}
                </List>
                }
            </div>
            
            <Button id="updateterms"
                className={classes.button}
                onClick={handleUpdateTerms}
                disabled = {acceptedProducts.length <= 0}>
                Accept new Terms
            </Button>
        </div>
    )
}