import React, {useState, useEffect} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import { FixedSizeList } from 'react-window';
import SearchBar from './SearchBar';

function MeetingUserGrid(props) {
    const [filter, setFilter] = useState('');
    const [visible, setVisible] = useState([]);

    useEffect(() => {
        setVisible(props.allUsers.filter(user => {
            return filter.length <= 0 ? true : user.username.includes(filter)
        }));
    }, [props.allUsers, filter]);


    const visibleSelected = visible.map(user => {
        return (props.selectedUsers.findIndex(check => check.id === user.id) >= 0)
    });
    const atLeastOneMissing = visibleSelected.includes(false);
    const atLeastOneSelected = visibleSelected.includes(true);
    const allVisibleSelected = props.selectedUsers.length>0 && !atLeastOneMissing;
    const checkInderminate = atLeastOneMissing && atLeastOneSelected;

    const selectAllVisibile = () => {
        if(allVisibleSelected)
            props.deselectUsers(visible);
        else
            props.selectUsers(visible);
    }

    const renderRow = ({ index, style }) => {
        const user = visible[index];
        const checked = (props.selectedUsers.findIndex(check => check.id === user.id) >= 0)
        const labelId = `checkbox-list-label-${user.id}`;
        return (
            <div style={style}>
                <ListItem key={user.id}>
                    <ListItemIcon onClick={() => {
                            if(checked)
                                props.deselectUsers([user])
                            else
                                props.selectUsers([user])
                        }}>
                        <Checkbox
                            edge="start"
                            checked={checked}
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={user.username} />
                </ListItem>
            </div>
        )  
    }

    return (
        <div>
            <ListItem key={'control-each-user'}>
                <ListItemIcon onClick={() => selectAllVisibile()}>
                    <Checkbox
                        edge="start"
                        checked={allVisibleSelected}
                        indeterminate = {checkInderminate}
                        inputProps={{ 'aria-labelledby': 'control-each-user-item' }}
                    />
                </ListItemIcon>
                <SearchBar
                    placeholder="Filter users"
                    onChangeText={(search) => {setFilter(search)}}
                    value={filter}/>
            </ListItem>
            <FixedSizeList height={500} width={"100%"} itemSize={30} itemCount={visible.length}>
                {renderRow}
            </FixedSizeList>
        </div>
    )
}

export default React.memo(MeetingUserGrid);//?????