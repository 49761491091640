import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import ScreenShare from '@material-ui/icons/ScreenShare';
import StopScreenShare from '@material-ui/icons/StopScreenShare';
import Tooltip from '@material-ui/core/Tooltip';
import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import RecordVoiceOver from '@material-ui/icons/RecordVoiceOver';


const useStyles = makeStyles((theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      '&[disabled]': {
        color: 'rgba(225, 225, 225, 0.8)',
        backgroundColor: 'rgba(175, 175, 175, 0.6);',
      },
    },
  })
);

export default function ToggleButton(props) 
{
  const classes = useStyles();
  
    var Icon = <div/>
    var title = "unknown button"
    if(props.kind === 'Audio'){
        Icon = props.isActive ? <Mic /> : <MicOff />
        title = (props.isActive ? 'Mute ' : 'Unmute ')+props.kind
    }
    else if(props.kind === 'Video'){
        Icon = props.isActive ? <Videocam /> : <VideocamOff />
        title = (props.isActive ? 'Mute ' : 'Unmute ')+props.kind
    }
    else if(props.kind === 'Screen'){
        Icon = props.isActive ? <StopScreenShare /> : <ScreenShare />
        title = (props.isActive ? 'Stop Screen Share' : 'Active Screen Share')
    }
    else if (props.kind === 'Request'){
        Icon = <RecordVoiceOver />
        title = 'Request to be able to speak'
    }

    return (
    <Tooltip
        title={title}
        placement="top"
        PopperProps={{ disablePortal: true }}
        style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}
    >
      <div>
        {/* The div element is needed because a disabled button will not emit hover events and we want to display
          a tooltip when screen sharing is disabled */}
        <Fab className={classes.fab} onClick={props.toggle} disabled={props.disabled}>
          {Icon}
        </Fab>
      </div>
    </Tooltip>
    );
}
