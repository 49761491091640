import React, { Component, Fragment} from 'react';
import { withStyles } from '@material-ui/core/styles';


import Grid from '@material-ui/core/Grid';

import Tabs from "components/CustomTabs/CustomTabs.jsx";
import TabContainer from 'components/CustomTabs/TabContainer';
import XDialog from 'components/XDialog';
import XGrid from 'components/XGrid';
import XExpansionPanel from 'components/XExpansionPanel';
import XSelect from 'components/XSelect';
import XField from 'components/XField';
import XTag from 'components/XTag';
import XSwitch from 'components/XSwitch';
import XButton from './XButton';
import { CodeBrackets } from 'mdi-material-ui';
import XGallery from './XGallery';
import { getXField, getXSelect, getXSwitch, getDiscount, getRecharge, round } from '../utils';
import { bindActionCreators, compose } from 'redux';
import { actionCreators as globalCreators } from 'store/Global';
import { connect } from 'react-redux';


const styles = theme => ({
    root: {
        position: "absolute",
        top: "50%",
        left: "50%",
        zIndex: "1100",
        background: "rgba(0,0,0,0.5)",
        transform: "translate(-50%,-50%)",
        width: "100%",
        height: "100%",
    },
    container: {

        width: "100%",
        maxWidth: "900px",
        left: "50%",
        transform: "translate(-50%,-50%)",
        position: "fixed",
        top: "50%",
        background: "#fff",
        padding: 5,
        boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
    },
});

var originalFields = [
    {
        Field: "Code",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "CatalogCode",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "Description",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "ExtendedDescription",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "Qty1",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDMeasureUnit1",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "Qty2",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "FIDMeasureUnit2",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "FIDPricelist",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "PriceType",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "RechargePercentage",
        Visibility: false,
        Required: 3,
    },
    {
        Field: "FIDVatPricelist",
        Visibility: true,
        Required: 3,
    },
	{
		Field: "FIDMarkup",
		Visibility: true,
		Required: 2,
	},
    {
        Field: "SellPrice",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "PublicPrice",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "PublicTaxablePrice",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "RetailDiscountDesc",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "FIDVat",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "SupplierCode",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "SupplierCatalogCode",
        Visibility: true,
        Required: 3,
    },
	{
        Field: "SupplierLabel",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "SupplierSN",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "TaxablePrice",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "TotalPrice",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "DiscountDesc",
        Visibility: true,
        Required: 3,
    },
	{
		Field: "ExtraDiscountDesc",
		Visibility: true,
		Required: 3,
	},
    {
        Field: "TaxableDiscount",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "DiscountedTaxablePrice",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "DiscountedTotalPrice",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "TotalTaxableAmount",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "TotalAmount",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "TotalDiscount",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "TotalDiscountedTaxableAmount",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "TotalDiscountedAmount",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "CalculationType",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDDOCRowType",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "InternalRemarks",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "PublicRemarks",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "Barcode",
        Visibility: false,
        Required: 3,
    },
    {
        Field: "SerialNumber",
        Visibility: false,
        Required: 3,
    },
    {
        Field: "PositionStr",
        Visibility: false,
        Required: 3,
    },
    {
        Field: "DestinationStr",
        Visibility: false,
        Required: 3,
    },
    {
        Field: "IsInWarranty",
        Visibility: false,
        Required: 3,
    }
];
var defaultFields = [];
class DocumentRowEditor extends Component {
    constructor(props) {
        super(props);
        this.cboFIDVat = React.createRef();
    }
    state = {
        data: {
            FIDDOCRowType: 1,
        },
        setVat: false,
    };
    componentWillMount() {
        this.reloadData();
    }
    componentDidMount() {
        defaultFields =JSON.parse(JSON.stringify( originalFields));
        this.hideField("IsInWarranty");

        if(this.props.data.FIDCycle === 2){
			if (this.props.FIDDocumentType === 4) {
				this.props.data.CalculationType = 1;
				this.hideField("CalculationType");
			}
            this.hideField("Barcode");
            this.hideField("SerialNumber");
            this.hideField("PositionStr");
            this.hideField("DestinationStr");
            if(this.props.FIDDocumentType === 4){//riparazione
                this.setField("IsInWarranty",true,3);
            }
			
			if ((this.state.data.PriceType||1) < 2)
				this.hideField("CalculationType");
        }
        else if(this.props.data.FIDCycle === 1 && this.props.data.FIDCausal === 8){
            this.hideField("PriceType");
            this.hideField("FIDPricelist");
            this.hideField("FIDVatPricelist");
            this.hideField("SellPrice");
            this.hideField("PublicPrice");
            this.hideField("PublicTaxablePrice");
            this.hideField("RetailDiscountDesc");
            this.hideField("FIDVat");
			this.hideField("FIDMarkup");
            this.hideField("SupplierCode");
            this.hideField("SupplierCatalogCode");
			this.hideField("SupplierLabel");
            this.hideField("SupplierSN");
            this.hideField("TaxablePrice");
            this.hideField("TotalPrice");
            this.hideField("DiscountDesc");
            this.hideField("TaxableDiscount");
            this.hideField("DiscountedTaxablePrice");
            this.hideField("DiscountedTotalPrice");
            this.hideField("TotalTaxableAmount");
            this.hideField("TotalAmount");
            this.hideField("TotalDiscount");
            this.hideField("TotalDiscountedTaxableAmount");
            this.hideField("TotalDiscountedAmount");
            this.hideField("CalculationType");
            this.hideField("Barcode");
            this.hideField("SerialNumber");
            this.hideField("PositionStr");
            this.hideField("Destination");
            //this.hideField("CalculationType");
        }
        else if(this.props.data.FIDCausal === 6) {
            this.hideField("CalculationType");
            this.setField("Barcode",true, 3);
            this.setField("SerialNumber",true, 3);
            this.setField("PositionStr",true, 3);
            this.setField("DestinationStr",true, 3);
        }
        if(this.props.data.FIDCycle === 3) {//passivo
            if(!(!this.props.data.Barcode || this.props.data.FIDCausal === 1)){
                this.hideField("CalculationType");
            }
        }
        this.setPriceType(this.props.data.PriceType);
		this.setCalculationType(this.props.data.CalculationType);
		//if (!this.props.data.PriceType || this.props.data.PriceType < 3) {
			
		//}

        this.reloadData();
    }
    hideField = (field) => {
        this.setField(field,false,3);
    }
    setField = (field, visibility, required) => {
        var fields = defaultFields.filter(f => f.Field === field);
        for(var i in fields){
            fields[i].Visibility = visibility;
            fields[i].Required = required;
        }
    }
    getDefaultData = async () => {
        var { FIDDOCIdentity,data,FIDPricelist,FIDVat,DiscountDesc,FIDMarkupDefault } = this.props;
        //Default pricelist
        //var FIDPricelist = await fetch('/DOC/GetDefaultPricelist').then(res => res.json());
        //var FIDPricelist = 
        //Default vat
        var FIDVatPricelist = FIDVat || this.props.params.STDFIDVat;
		if (!FIDPricelist)
			FIDPricelist = this.props.params.DEF_PRICELIST;
        //Default UM1
        var FIDUM1 = 6;
        //Default discount
        //var DefaultDiscount = await fetch(`/DOC/GetSupplierDiscount/${FIDDOCIdentity}`).then(res => res.json());
        var DefaultDiscount = DiscountDesc;

        var DistributionInfo = await fetch(`/IR/GetDistributionInfoByBOP/${FIDDOCIdentity}?FIDBOP=${data.FIDBOP}`).then(res => res.json());

        return { FIDPricelist, FIDVatPricelist, FIDUM1, DefaultDiscount, DistributionInfo };
    }
    reloadData = async () => {
        var { FIDPricelist,FIDVatPricelist, FIDUM1, DefaultDiscount,FIDMarkupDefault, DistributionInfo } = await this.getDefaultData();
        this.setState(async state => {
            var { data } = this.props;
            var newData = Object.assign({}, data);
            if (JSON.stringify(newData) !== JSON.stringify({})) {
                state.data = newData;
                if(state.data.FIDCausal === 6 && !state.data.FIDBOP && state.data.ID === 0){//inventario
                    state.data.Qty1 = state.data.MeasureUnitQty1;
                }
                if(!state.data.FIDDOCRowType){
                    state.data.FIDDOCRowType = 1;
                }
                if (!state.data.FIDBOP && state.data.ID === 0) {
                    state.data.FIDPricelist = data.FIDSalesPricelist || FIDPricelist;
                    state.data.FIDVatPricelist = FIDVatPricelist;
                    //newData.RetailDiscountDesc = DefaultDiscount;
                    state.data.FIDMeasureUnit1 = FIDUM1;
                    //state.data.RetailDiscountDesc = DistributionInfo.formula || DefaultDiscount;
                    state.data.RetailDiscountDesc = DistributionInfo.formula;
                    state.data.DiscountDesc = DefaultDiscount;
					state.data.ExtraDiscountDesc = "";
                    state.data.CalculationType = DistributionInfo.calculationType;
					state.data.FIDMarkup = DistributionInfo.FIDMarkup;
					state.data.MarkupMultiplier = DistributionInfo.markupMultiplier;
                    state.data.Qty1 = 1;
                    state.data.TaxablePrice = 0;
                }
                else {
                    if(state.data.ID === 0 && !state.data.FIDDocumentRow){
                        state.data.FIDPricelist =  data.FIDSalesPricelist || FIDPricelist;
                        state.data.RetailDiscountDesc = state.data.SupplierDiscountDesc;
                        
                        state.data.DiscountDesc = DefaultDiscount;
						state.data.FIDMarkup = FIDMarkupDefault || state.data.FIDMarkup;
                    }
                }
				if (!state.data.PriceType) {
					state.data.PriceType = 1;
				}
                if(this.props.FIDCycle === (this.props.params.PassiveCycleId || 3)){
					//if (this.props.FIDDocumentType === (this.props.params.DOCPreliminaryTypeId||2)) {
						if (!state.data.UnitCost) {
							state.data.UnitCost = 0;
						}
						
						if (!state.data.SupplierCost) {
							state.data.SupplierCost = 0;
						}
						
						var discount = getDiscount(state.data.DiscountDesc);
						var extraDiscount = getDiscount(state.data.ExtraDiscountDesc);
						
						if (!state.data.TaxablePrice) {
							state.data.TaxablePrice = state.data.UnitCost||state.data.SupplierCost;;
						}
						state.data.DiscountedTaxablePrice = (state.data.TaxablePrice * (1 - (discount||0)) * (1 - (extraDiscount||0))).toFixed(2);
						state.data.TotalPrice = Math.round(state.data.TaxablePrice * (100 + (state.data.VatPerc))) / 100;
						state.data.DiscountedTotalPrice = Math.round(state.data.DiscountedTaxablePrice * (100 + (state.data.VatPerc||0))) / 100;
						
						if (state.data.CalculationType <= 2 && state.data.FIDBOP > 0) {
							var pricelist = state.data.FIDBOP ? await fetch(`/BOP/GetPricelistInfo/${state.data.FIDPricelist}?FIDBOP=${state.data.FIDBOP}`).then(res => res.json()) : {};						
							state.data.PublicTaxablePrice = pricelist.discountedTaxablePrice||0;
							state.data.PublicPrice = pricelist.discountedTotalPrice||0;
						}
					//} else 
					//if (state.data.PriceType) {
					//	switch(state.data.PriceType) {
					//		case 1:
					//		if (![1,2].includes(state.data.CalculationType)) {
					//			state.data.CalculationType = 2;
					//		}
					//		break;
					//		case 2:
					//		if (![3,4].includes(state.data.CalculationType)) {
					//			state.data.CalculationType = 4;
					//			state.data.RetailDiscountDesc = data.RechargePercentage;
					//			state.data.RetailDiscountDescLock = false;
					//		}
					//		break;
					//		case 3:
					//		if (![5,6].includes(state.data.CalculationType)) {
					//			state.data.CalculationType = 6;
					//		}
					//		break;
					//	}
					//}
					
					if ([3,4,5,6].includes(state.data.CalculationType)) {
						state.data.TaxablePriceLock = false;
						state.data.PublicTaxablePriceLock = true;
						state.data.PublicPriceLock = true;
						if ([3,4].includes(state.data.CalculationType)) {
							state.data.RetailDiscountDescLock = false;
						}
						//state.data.TaxablePrice = (state.data.UnitCost || state.data.SupplierCost || 0);					
						if (this.props.FIDCycle == this.props.params.PassiveCycleId) {
							state.data = await this.calculateRetailInfo(state.data);
						}
					}
                    
                }
                else {
                    //state.data = this.calculateTotals(state.data);
                    state.data.TotalPrice = state.data.TotalAmount;
                    if(!state.data.TotalPrice){
                        state.data.TotalPrice = 0;
                    }
                }
				
				state.data = this.calculateTotals(state.data);
                state.data = this.calculateDiscounts(state.data);
				state.data = this.calculateDiscountedTotals(state.data);
                state.data = this.calculateAll(state.data);
				
                if([undefined, null,''].includes(state.data.Qty2)) {
                    state.data.Qty2 = 0;
                }
				if (!state.data.UnitCost) {
                    state.data.UnitCost = round(state.data.TotalCost / state.data.Qty1, 2);
				}
            }
            else {
                state.data.FIDPricelist = data.FIDSalesPricelist || FIDPricelist;
                state.data.FIDVatPricelist = FIDVatPricelist;
            }
            return state;
        },() =>{
            this.fixLocks();
            if([2,3].includes(this.state.data.PriceType)){
				if(this.props.data.FIDCycle == this.props.params.ActiveCycleId) {
					this.setCostInfo();
				}
            }
            else {
                if(!this.state.data.ID && !this.state.data.FIDDocumentRow && (this.state.data.CalculationType||1) <= 2) {
                    this.setPricelistInfo();
                }
            }
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.reloadData();
        }
    }
    onBlur = (key) => (event, value) => {
        if (key === "Code") {
            if (!this.state.data.CatalogCode) {
                this.setState(state => {
                    state.data.CatalogCode = state.data.Code;
                    return state;
                });
            }
        }
        // else if (key === "Description") {
        //     if (!this.state.data.ExtendedDescription) {
        //         this.setState(state => {
        //             state.data.ExtendedDescription = state.data.Description;
        //             return state;
        //         });
        //     }
        // }
    }
    calculateRetailInfo = async(data) => {
        var case1=[1,2];
        var case2 =[3,4];
		
		if (data.CalculationType >= 5 && data.FIDMarkup && (!data.MarkupMultiplier || data.MarkupMultiplier === 1)) {
			// SE L'ORIGINE PREZZO E' DA FORMULA, CONTROLLO CHE IL MOLTIPLICATORE SIA VALORIZZATO
			data.MarkupMultiplier = await fetch("/gc/markup/GetMultiplier/"+data.FIDMarkup).then(res => res.json());
		}
		
        switch (data.CalculationType){
            case 1:
                if(data.RetailDiscountDescLock) {
                    data.RetailDiscountDesc = round((1 - data.TaxablePrice / data.PublicPrice) * 100,4).toString(10);
                }
                break;
            case 2:
                if(data.RetailDiscountDescLock) {
                    data.RetailDiscountDesc = round((1 - data.TaxablePrice / data.PublicTaxablePrice) * 100,4).toString(10);
                }
                //
                break;
            case 3:
                if(data.RetailDiscountDescLock) {
                    data.RetailDiscount =data.PublicPrice / data.TaxablePrice - 1;
                    data.RetailDiscountDesc =round((data.RetailDiscount * 100),4).toString(10);
                }
                else {
                    var getD = getRecharge(data.RetailDiscountDesc);
                    data.PublicPrice = round(data.TaxablePrice * (1 + getD),2);
                    data.PublicTaxablePrice = round(data.PublicPrice /  (1 + ((data.VatPercPricelist||0)/100)),2);
                }
                break;
            case 4:
                if(data.RetailDiscountDescLock) {
                    data.RetailDiscount =data.PublicTaxablePrice / data.TaxablePrice - 1;
                    data.RetailDiscountDesc =round((data.RetailDiscount * 100),4).toString(10);
                }
                else {
                    var getD = getRecharge(data.RetailDiscountDesc);
                    data.PublicTaxablePrice = round(data.TaxablePrice * (1 + getD),2);
                    data.PublicPrice = round(data.PublicTaxablePrice * (1 + ((data.VatPercPricelist||0)/100)),2);
                }
                break;
			case 5:
				data.PublicPrice = round(data.TaxablePrice * (data.MarkupMultiplier || 1),2);
				data.PublicTaxablePrice = round(data.PublicPrice /  (1 + ((data.VatPercPricelist||0)/100)),2);
				break;
			case 6:
				data.PublicTaxablePrice = round(data.TaxablePrice * (data.MarkupMultiplier || 1),2);
                data.PublicPrice = round(data.PublicTaxablePrice * (1 + ((data.VatPercPricelist||0)/100)),2);
				break;
        }
        return data;
    }
    calculateRechargePercentage = (data) => {

        if(data.PriceType === 2){
			if (data.CalculationType === 3)
				data.RechargePercentage = ((data.TaxablePrice / (data.SupplierCost||data.UnitCost) - 1) * 100).toFixed(4);
			else if (data.CalculationType === 4)
				data.RechargePercentage = ((data.TotalPrice / (data.SupplierCost||data.UnitCost) - 1) * 100).toFixed(4);
			else
				data.RechargePercentage = 0;
        } else {
			data.RechargePercentage = 0;
		}
        return data;
    }
    onChange = (key) => (event, value) => {
        this.setState(state => {
            state.data[key] = value;
            if(["Description","ExtendedDescription"].includes(key)){
                state.data["Edited"] = true;   
            }
            return state;
        }, () => {
            if (key === "TaxablePrice") {
                this.setState(async state => {
                    var { data } = state;
                    state.data = this.calculateTotals(data);
                    state.data = await this.calculateRetailInfo(data);
                    state.data = this.calculateRechargePercentage(data);
                    return state;
                });
            }
            else if (["PublicPrice","PublicTaxablePrice"].includes(key)) {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateRetailPrice(data, key);
                    return state;
                });
            }
            else if (key === "RetailDiscountDesc") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateRetailPrice(data);
                    return state;
                });
            }
            else if (["DiscountDesc","ExtraDiscountDesc"].includes(key)) {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateDiscountedTotals(data);
                    return state;
                });
            }
            else if (key === "TaxableDiscount") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateDiscountedTotals2(data);
                    return state;
                });
            }
            else if (key === "DiscountedTaxablePrice") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateDiscountTaxable(data);
                    return state;
                });
            }
            else if (key === "DiscountedTotalPrice") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateDiscountTotal(data);
                    return state;
                });
            }
            else if (key === "TotalPrice") {
                this.setState(async state => {
                    var { data } = state;
                    state.data = this.calculateTaxablePrice(data);
					
					if (this.props.FIDCycle === (this.props.params.PassiveCycleId||3))
						state.data = await this.calculateRetailInfo(data);

                    state.data = this.calculateRechargePercentage(data);
                    return state;
                });
            }
            else if (key === "FIDVat") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateTotals(data);
                    
                    state.data = this.calculateRechargePercentage(data);
                    return state;
                });
            }
            else if (key === "Qty1") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateAll(data);
                    return state;
                },this.fixLocks);
            }
            else if (key === "TotalTaxableAmount") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateTotals(data, true);
                    
                    state.data = this.calculateRechargePercentage(data);
                    return state;
                });
            }
            else if (key === "TotalAmount") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateTaxablePrice(data, true);
                    
                    state.data = this.calculateRechargePercentage(data);
                    return state;
                });
            }
            else if (key === "TotalDiscount") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateDiscountedTotals2(data, true);
                    return state;
                });
            }
            else if (key === "TotalDiscountedTaxableAmount") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateDiscountTaxable(data, true);
                    return state;
                });
            }
            else if (key === "TotalDiscountedAmount") {
                this.setState(state => {
                    var { data } = state;
                    state.data = this.calculateDiscountTotal(data, true);
                    return state;
                });
            }
            else if(["RechargePercentage","FIDMarkup"].includes(key)){
                this.setCostInfo();
            }

        });
    }
    setPriceType = (PriceType) => {
        if(PriceType === 1){
            this.setField("FIDPricelist",true,2);
            this.setField("RechargePercentage",false,2);
			this.setField("FIDMarkup",false,2);
			
			if (this.props.data.FIDCycle == this.props.params.ActiveCycleId) {
				this.setField("CalculationType", false, 2);
			}
        }
        else if (PriceType === 2){
			if (this.props.data.FIDCycle == this.props.params.ActiveCycleId) {
				this.setField("FIDPricelist",false,3);
				this.setField("CalculationType", true, 2);
			}
			if (this.state.data.CalculationType > 2 && this.state.data.CalculationType < 5) {
				this.setField("RechargePercentage",true,2);
				this.setField("FIDMarkup",false,2);
				this.setState({
					FIDMarkup: undefined,
					MarkupMultiplier: 1
				});
			} else if (this.state.data.CalculationType >= 5) {
				this.setField("RechargePercentage",false,2);
				this.setField("FIDMarkup",true,2);
				
				this.setState({
					RechargePercentage: undefined
				});
			}
        } /*else if (PriceType === 3){
			if (this.props.data.FIDCycle == this.props.params.ActiveCycleId) {
				this.setField("FIDPricelist",false,3);
			}
            this.setField("RechargePercentage",false,3);
			this.setField("FIDMarkup",true,2);
		}*/
    }
    setCalculationType = (CalculationType) => {
        switch(CalculationType){
			case 3: case 4:
				this.setField("FIDMarkup",false,2);
				this.setField("RechargePercentage", true,2);
				break;
            case 5:
            case 6:
                this.setField("FIDMarkup",true,2);
				this.setField("RechargePercentage", false,2);
                break;
            default:
				this.setField("RechargePercentage", false,2);
                this.setField("FIDMarkup",false,2);
                break;

        }
    }
    onSelectChange = (id) => (event, value) => {
		var prevCalcType = this.state.data.CalculationType;
        if(id === "PriceType" && value && value.length > 0){
            this.setPriceType(value[0].ID);
        }
        if(id === "CalculationType" && value && value.length > 0){
            this.setCalculationType(value[0].ID)
        }
        this.setState(state => {
            if (value && value.length > 0) {
                state.data[id] = value[0].ID;
				
				if (id === "FIDVatPricelist") {
					state.data.VatPercPricelist = value[0].Percentage;
				}
            }
            else {
                state.data[id] = undefined;
				
				if (id === "FIDVatPricelist") {
					state.data.VatPercPricelist = 0;
				}
            }
            return state;
        }, () => {
                if (id === "FIDVat") {
                    this.setState(state => {
                        var { data } = state;
                        data.VatPerc = value[0].Percentage;
                        state.data = this.calculateTotals(data);
                        return state;
                    });
                }
                else if (id === "FIDPricelist") {
                    this.setPricelistInfo();
                }
                else if(id === "CalculationType"){
					if (value && value.length > 0) {
						if (value[0].ID <= 2) {
							this.setState(state => {
								state.data.FIDMarkup = undefined;
								state.data.MarkupMultiplier = undefined;
								state.data.RechargePercentage = undefined; 
								state.data = this.calculateTotals(state.data);
								return state;
							});
							if (prevCalcType > 2) {
								this.setPricelistInfo();
							}
						}
						if (value[0].ID <= 4) {
							this.setState(async state => {
								state.data.FIDMarkup = undefined;
								state.data.MarkupMultiplier = undefined;
								if (this.props.FIDCycle === (this.props.params.PassiveCycleId||3))
									state.data = await this.calculateRetailInfo(state.data);
									
								state.data = this.calculateTotals(state.data);
								return state;
							}, () => {
								if (this.props.FIDCycle === this.props.params.ActiveCycleId) {
									this.setCostInfo();
								}
							});
						} else {
							this.setState(async state => {
								state.data.RechargePercentage = undefined; 
								if (this.props.FIDCycle === (this.props.params.PassiveCycleId||3))
									state.data = await this.calculateRetailInfo(state.data);
								
								state.data = this.calculateTotals(state.data);
								return state;
							}, () => {
								if (this.props.FIDCycle === this.props.params.ActiveCycleId) {
									this.setCostInfo();
								}
							});
						}						
						this.fixLocks();
					}
                } else if (id === "FIDMarkup") {
					this.setState(async state => {
						var { data } = state;
						//data.DiscountDesc = "";
						//data.Discount = 0;
						data.RechargePercentage = undefined; 
						data.MarkupMultiplier = value && value.length > 0 ? value[0].Multiplier : 1;
						state.data = this.calculateTotals(data);
						if(this.props.FIDCycle === this.props.params.PassiveCycleId)
							state.data = await this.calculateRetailInfo(data);
						return state;
					}, () => {
						if (this.props.FIDCycle === this.props.params.ActiveCycleId) {
							this.setCostInfo();
						}
					});
				}
                else if(id === "FIDDOCRowType"){
                    var req = 3;
                    if(this.props.data.FIDCycle === 3 && value[0].ID === 1){
                        req = 2;
                    }
                    for(var i=0;i<defaultFields.length;i++){
                        if(defaultFields[i].Field === "CalculationType"){
                            defaultFields[i].Visibility = false;
                            defaultFields[i].Required = req;
                        }
                    }
                }
                else if (id === "PriceType"){
                    var { data } = this.state;
                    if(data.PriceType === 1){
                        this.setPricelistInfo();
                    }
                    else if([2,3].includes(data.PriceType)){
                        this.setCostInfo();
                    }
                }
        });
    }
    getPricelistInfo = async(state) => {
        var pricelist = await fetch(`/BOP/GetPricelistInfo/${state.data.FIDPricelist}?FIDBOP=${state.data.FIDBOP}`).then(res => res.json());
        var case1 = [1, 3];
        var case2 = [2, 4];
        state.data.PublicPrice = pricelist.discountedTotalPrice;
        state.data.PublicTaxablePrice = pricelist.discountedTaxablePrice;
        state.data = this.calculateRetailPrice(state.data);
        state.data = this.calculateAll(state.data);

        return state;
    }
    setCostInfo = () => {
        this.setState(state => {
			var discount = getDiscount(state.data.DiscountDesc);
			var extraDiscount = getDiscount(state.data.ExtraDiscountDesc);

			if (state.data.PriceType == 2) {
				if (!state.data.UnitCost)
					state.data.UnitCost = state.data.SupplierCost||0;
				if ([3,4].includes(state.data.CalculationType)) {
					var r = getRecharge(state.data.RechargePercentage);

					if (state.data.CalculationType === 4) {
						state.data.TaxablePrice = ((state.data.SupplierCost||state.data.UnitCost) * (1 + r)).toFixed(2);
						state.data.TotalPrice = Math.round(state.data.TaxablePrice * (100 + state.data.VatPerc)) / 100;
					}
					else {
						state.data.TotalPrice = ((state.data.SupplierCost||state.data.UnitCost) * (1 + r)).toFixed(2);
						state.data.TaxablePrice = Math.round(state.data.TotalPrice / (100 + state.data.VatPerc)) * 100;
					}
				}
				else if ([5,6].includes(state.data.CalculationType)) {
					if (state.data.CalculationType === 6) {
						state.data.TaxablePrice = (state.data.UnitCost * (state.data.MarkupMultiplier || 1)).toFixed(2);
						state.data.TotalPrice = Math.round(state.data.TaxablePrice * (100 + state.data.VatPerc)) / 100;
					} else {
						state.data.TotalPrice = (state.data.UnitCost * (state.data.MarkupMultiplier || 1)).toFixed(2);
						state.data.TaxablePrice = Math.round(state.data.TotalPrice / (100 + state.data.VatPerc)) * 100;
					}
				}				
			}
            state.data.DiscountedTaxablePrice = (state.data.TaxablePrice * (1 - (discount||0)) * (1 - (extraDiscount||0))).toFixed(2);
            //state.data.TotalPrice = pricelist.discountedTotalPrice;
            state.data.DiscountedTotalPrice = Math.round(state.data.DiscountedTaxablePrice * (100 + state.data.VatPerc)) / 100;
            state.data = this.calculateDiscounts(state.data);
            state.data = this.calculateAll(state.data);
            return state;
        });
    }
    setPricelistInfo = async () => {
        var { data } = this.state;

        var pricelist = await fetch(`/BOP/GetPricelistInfo/${data.FIDPricelist}?FIDBOP=${data.FIDBOP}`).then(res => res.json());
        this.setState(state => {
            if(data.FIDCycle === 3){
                state.data.PublicTaxablePrice = pricelist.discountedTaxablePrice;
				
				if (state.data.FIDVat !== pricelist.fidVat) {
					state.data.PublicPrice = (state.data.PublicTaxablePrice * (100 + state.data.VatPerc) / 100).toFixed(2);
				} else {
					state.data.PublicPrice = pricelist.discountedTotalPrice;
				}
                state.data = this.calculateRetailPrice(state.data);
				state.data = this.calculateDiscountedTotals(state.data);
                state.data = this.calculateDiscounts(state.data);
                state.data = this.calculateAll(state.data);
            }
            else {
                var discount = getDiscount(state.data.DiscountDesc);
				var extraDiscount = getDiscount(state.data.ExtraDiscountDesc);
				state.data.Discount = discount;
				state.data.ExtraDiscount = extraDiscount;
                state.data.TaxablePrice = pricelist.discountedTaxablePrice;
                //state.data.DiscountedTaxablePrice = (state.data.TaxablePrice * (1 - discount)).toFixed(2);
				if (state.data.FIDVat !== pricelist.fidVat) {
					state.data.TotalPrice = (state.data.TaxablePrice * (100 + state.data.VatPerc) / 100).toFixed(2);
				} else {
					state.data.TotalPrice = pricelist.discountedTotalPrice;
				}
                //state.data.TotalPrice = (state.data.TaxablePrice * (100 + state.data.VatPerc) / 100).toFixed(2);
                //state.data.TotalPrice = Math.round(state.data.TaxablePrice * (100 + state.data.VatPerc)) / 100;
                //state.data.DiscountedTotalPrice = Math.round(state.data.DiscountedTaxablePrice * (100 + state.data.VatPerc)) / 100;
				state.data = this.calculateDiscountedTotals(state.data);
                state.data = this.calculateDiscounts(state.data);
                state.data = this.calculateAll(state.data);
            }
    
            return state;
         });
    }
    calculateAll = (data) => {
        data.TotalTaxableAmount = (data.TaxablePrice * data.Qty1).toFixed(2);
        data.TotalAmount = (data.TotalPrice * data.Qty1).toFixed(2);
        data.TotalDiscountedTaxableAmount = (data.DiscountedTaxablePrice * data.Qty1).toFixed(2);
        data.TotalDiscountedAmount = (data.DiscountedTotalPrice * data.Qty1).toFixed(2);
        data.TotalDiscount = (data.TaxableDiscount * data.Qty1).toFixed(2);
        return data;
    }
    calculateRetailPrice = (data, key) => {
        if (key) {
            if (key == "PublicPrice") {
                data.PublicTaxablePrice = (data.PublicPrice / ((100 + parseFloat((data.VatPercPricelist||0))) / 100)).toFixed(2);
            } else {
                data.PublicPrice = (data.PublicTaxablePrice * ((100 + parseFloat((data.VatPercPricelist||0))) / 100)).toFixed(2);
            }
        }
        var case1 = [1, 2];
        var case2 = [3, 4];
		var case3 = [5, 6];
        var caseTaxable = [2,4,6];
        var price = data.PublicPrice;
        if(caseTaxable.includes(data.CalculationType)){
            price = data.PublicTaxablePrice;
        }
        if(case1.includes(data.CalculationType)){
            if(data.RetailDiscountDescLock){
                data.RetailDiscountDesc = ((1 -data.TaxablePrice / price) * 100).toFixed(4);
            }
            else {
                data.RetailDiscount = getDiscount(data.RetailDiscountDesc);
                data.TaxablePrice = (price * (1 - data.RetailDiscount)).toFixed(2);
            }
        }
        else if(case2.includes(data.CalculationType)){
            if(data.RetailDiscountDescLock){
                data.RetailDiscountDesc = (( price / data.TaxablePrice - 1) * 100).toFixed(4);
            }
            else {
                data.RetailDiscount = getRecharge(data.RetailDiscountDesc);
                data.TaxablePrice = (price / (1 + data.RetailDiscount)).toFixed(2);
            }
        } else if (case3.includes(data.CalculationType)) {
			data.TaxablePrice = (price / (data.MarkupMultiplier || 1)).toFixed(2);
		}
        if(!data.TaxablePrice){
            data.TaxablePrice = 0;
        }
        
        data = this.calculateTotals(data);
        return data;
    }
    calculateTotals = (data, multiple = false) => {
        var tempTotalTaxableAmount = data.TotalTaxableAmount;
        if (multiple) {
            data.TaxablePrice = (data.TotalTaxableAmount / data.Qty1).toFixed(2);
        }
        if(data.PublicTaxablePriceLock) {
            var publicPrice = data.PublicPrice;

            if (!publicPrice){
                publicPrice = 0;
            }
            data.PublicTaxablePrice = (publicPrice / ((100 + parseFloat((data.VatPercPricelist||0))) / 100)).toFixed(2);
        }
        else {
            var publicTaxablePrice = data.PublicTaxablePrice;

            if (!publicTaxablePrice){
                publicTaxablePrice = 0;
            }
            data.PublicPrice = (publicTaxablePrice * ((100 + parseFloat((data.VatPercPricelist||0))) / 100)).toFixed(2);
        }
        
		if (data.TotalPriceLock) {
			var tTaxablePrice = data.TaxablePrice;
			if(!tTaxablePrice){
				tTaxablePrice = 0;
			}
			data.TotalPrice = (tTaxablePrice * ((100 + parseFloat(data.VatPerc)) / 100)).toFixed(2);
			if(!data.TotalPrice){
				data.TotalPrice = 0;
			}
			//if(!data.DiscountDescLock) {
			data.Discount = getDiscount(data.DiscountDesc);
			data.ExtraDiscount = getDiscount(data.ExtraDiscountDesc);
			if(!data.DiscountDescLock) {
				data.Discount = getDiscount(data.DiscountDesc);
				data.ExtraDiscount = getDiscount(data.ExtraDiscountDesc);
				if (data.Discount > 0 || data.ExtraDiscount > 0) {
					if(data.TaxablePriceLock){
						data.DiscountedTotalPrice = (data.TotalPrice * (1 - data.Discount) * (1 - data.ExtraDiscount)).toFixed(2);
						data.DiscountedTaxablePrice = (data.DiscountedTotalPrice / ((100 + parseFloat(data.VatPerc))/100)).toFixed(2);
					}
					else {
						data.DiscountedTaxablePrice = (data.TaxablePrice * (1 - data.Discount) * (1 - data.ExtraDiscount)).toFixed(2);
						data.DiscountedTotalPrice = (data.DiscountedTaxablePrice * ((100 + parseFloat(data.VatPerc))/100)).toFixed(2);
						if(!data.DiscountedTotalPrice){
							data.DiscountedTotalPrice = 0;
						}
					}
				}
				else {
					data.DiscountedTaxablePrice = data.TaxablePrice;
					data.DiscountedTotalPrice = data.TotalPrice;
					if(!data.DiscountedTotalPrice){
						data.DiscountedTotalPrice = 0;
					}
				}
			}
			//}
		} else if (data.TaxablePriceLock) {
			var tPrice = data.TotalPrice;
			
			if (!tPrice) {
				tPrice = 0;
			}
			
			data.TaxablePrice = (tPrice / (100 + parseFloat(data.VatPerc)) * 100).toFixed(2);
			
			if (!data.TaxablePrice) {
				data.TaxablePrice = 0;
			}
			
			//if(!data.DiscountDescLock) {
			data.Discount = getDiscount(data.DiscountDesc);
			data.ExtraDiscount = getDiscount(data.ExtraDiscountDesc);
			if (data.Discount > 0 || data.ExtraDiscount > 0) {
				if(data.TaxablePriceLock){
					data.DiscountedTotalPrice = (data.TotalPrice * (1 - data.Discount) * (1 - data.ExtraDiscount)).toFixed(2);
					data.DiscountedTaxablePrice = (data.DiscountedTotalPrice / ((100 + parseFloat(data.VatPerc))/100)).toFixed(2);
				}
				else {
					data.DiscountedTaxablePrice = (data.TaxablePrice * (1 - data.Discount) * (1 - data.ExtraDiscount)).toFixed(2);
					data.DiscountedTotalPrice = (data.DiscountedTaxablePrice * ((100 + parseFloat(data.VatPerc))/100)).toFixed(2);
					if(!data.DiscountedTotalPrice){
						data.DiscountedTotalPrice = 0;
					}
				}
			}
			else {
				data.DiscountedTaxablePrice = data.TaxablePrice;
				data.DiscountedTotalPrice = data.TotalPrice;
				if(!data.DiscountedTotalPrice){
					data.DiscountedTotalPrice = 0;
				}
			}
			//}
		}
        else if(!data.DiscountedTotalPriceLock || !data.DiscountedTaxablePriceLock) {
			data = this.calculateExtraDiscount(data);
        }
        data = this.calculateDiscounts(data);
        data = this.calculateAll(data);
        if (multiple) {
            data.TotalTaxableAmount = tempTotalTaxableAmount;
        }
        return data;
    }
    calculateDiscountedTotals = (data, recalculateDiscounts = true) => {
        data.Discount = getDiscount(data.DiscountDesc);
		data.ExtraDiscount = getDiscount(data.ExtraDiscountDesc);
        
        if (data.Discount > 0 || data.ExtraDiscount > 0) {
            if(data.TaxablePriceLock){
                data.DiscountedTotalPrice = (data.TotalPrice * (1 - data.Discount) * (1 - data.ExtraDiscount)).toFixed(2);
                data.DiscountedTaxablePrice = (data.DiscountedTotalPrice / ((100 + parseFloat(data.VatPerc))/100)).toFixed(2);
            }
            else {
                data.DiscountedTaxablePrice = (data.TaxablePrice * (1 - data.Discount) * (1 - data.ExtraDiscount)).toFixed(2);
                data.DiscountedTotalPrice = (data.DiscountedTaxablePrice * ((100 + parseFloat(data.VatPerc))/100)).toFixed(2);
                if(!data.DiscountedTotalPrice){
                    data.DiscountedTotalPrice = 0;
                }
            }
        }
        else {
            data.DiscountedTaxablePrice = data.TaxablePrice;
            data.DiscountedTotalPrice = data.TotalPrice;
            if(!data.DiscountedTotalPrice){
                data.DiscountedTotalPrice = 0;
            }
        }
        if (recalculateDiscounts) {
            data = this.calculateDiscounts(data);
        }
        data = this.calculateAll(data);
        return data;
    }
	calculateExtraDiscount = (data) => {
		if (!data.DiscountedTotalPriceLock) {
			var discountedPartial = data.TotalPrice * (1 - data.Discount);
			var amountsDiff = discountedPartial - data.DiscountedTotalPrice;
			data.ExtraDiscountDesc = ((amountsDiff/discountedPartial)*100).toFixed(4);
		} else if (!data.DiscountedTaxablePriceLock) {
			var discountedPartial = data.TaxablePrice * (1 - data.Discount);
			var amountsDiff = discountedPartial - data.DiscountedTaxablePrice;
			data.ExtraDiscountDesc = ((amountsDiff/discountedPartial)*100).toFixed(4);
		}
		
		return data;
	}
    calculateDiscountedTotals2 = (data, multiple = false) => {
        var tempTotalDiscount = data.TotalDiscount;
        if (multiple) {
            if (!data.TotalDiscount) {
                data.TotalDiscount = 0;
            }
            data.TaxableDiscount = data.TotalDiscount / data.Qty1;
        }
        if (!data.TaxableDiscount) {
            data.TaxableDiscount = 0;
        }
        data = this.calculateExtraDiscount(data);
        data = this.calculateAll(data);
        data = this.calculateDiscountedTotals(data, false);
        if (multiple) {
            data.TotalDiscount = tempTotalDiscount;
        }
        return data;
    }
    calculateDiscountTaxable = (data, multiple = false) => {
        var tempTotalDiscountedTaxableAmount = data.TotalDiscountedTaxableAmount;
        if (multiple) {
            data.DiscountedTaxablePrice = data.TotalDiscountedTaxableAmount / data.Qty1;
        }
        data = this.calculateExtraDiscount(data);
        data.Discount = getDiscount(data.DiscountDesc);
		data.ExtraDiscount = getDiscount(data.ExtraDiscountDesc);

        data.DiscountedTotalPrice = (data.DiscountedTaxablePrice * ((100 + parseFloat(data.VatPerc)) / 100)).toFixed(2);

        if (data.Discount > 0) {
            data.DiscountedTotalPrice = (data.DiscountedTaxablePrice * ((100 + parseFloat(data.VatPerc)) / 100)).toFixed(2);
        }
        else {
            data.DiscountedTotalPrice = data.TotalPrice;
        }
        if(!data.DiscountedTotalPrice){
            data.DiscountedTotalPrice = 0;
        }
        data = this.calculateDiscounts(data);
        data = this.calculateAll(data);
        if (multiple) {
            data.TotalDiscountedTaxableAmount = tempTotalDiscountedTaxableAmount;
        }
        return data;
    }
    calculateDiscountTotal = (data, multiple = false) => {
        var tempTotalDiscountedAmount = data.TotalDiscountedAmount;
        if (multiple) {
            data.DiscountedTotalPrice = data.TotalDiscountedAmount / data.Qty1;
        }
        if(!data.DiscountedTotalPrice){
            data.DiscountedTotalPrice = 0;
        }

        data = this.calculateExtraDiscount(data);
        data.Discount = getDiscount(data.DiscountDesc);
		data.ExtraDiscount = getDiscount(data.ExtraDiscountDesc);
        if (data.Discount > 0) {
            data.DiscountedTaxablePrice = (data.TaxablePrice * (1 - data.Discount)).toFixed(2);
        }
        else {
            data.DiscountedTaxablePrice = data.TaxablePrice;
        }
        data = this.calculateDiscounts(data);
        data = this.calculateAll(data);
        if (multiple) {
            data.TotalDiscountedAmount = tempTotalDiscountedAmount;
        }
        return data;
    }
    calculateTaxablePrice = (data, multiple = false) => {
        var tempTotalAmount = data.TotalAmount;
        if (multiple) {
            data.TotalPrice = (data.TotalAmount / data.Qty1).toFixed(2);
            if(!data.TotalPrice){
                data.TotalPrice = 0;
            }
        }
        
		//if (data.CalculationType <= 4) {
		data.Discount = getDiscount(data.DiscountDesc) + getDiscount(data.ExtraDiscountDesc);
		data.TaxablePrice = (data.TotalPrice / ((100 + parseFloat(data.VatPerc))/100)).toFixed(2);
		if(!data.TaxablePrice){
			data.TaxablePrice = 0;
		}
		if(!data.DiscountDescLock) {
			if (data.Discount > 0) {
				data.DiscountedTotalPrice = (data.TotalPrice * (1 - data.Discount)).toFixed(2);
				data.DiscountedTaxablePrice = (data.DiscountedTotalPrice / ((100 + parseFloat(data.VatPerc)) / 100)).toFixed(2);
				//data.DiscountedTaxablePrice = (data.TaxablePrice * (1 - data.Discount)).toFixed(2);
				//data.DiscountedTotalPrice = (data.DiscountedTaxablePrice * ((100 + parseFloat(data.VatPerc)) / 100)).toFixed(2);
			}
			else {
				data.DiscountedTaxablePrice = data.TaxablePrice;
				data.DiscountedTotalPrice = data.TotalPrice;
			}
		}
		else if(!data.DiscountedTaxablePriceLock || !data.DiscountedTotalPriceLock) {
			data = this.calculateExtraDiscount(data);
		}
		if(!data.DiscountedTotalPrice){
			data.DiscountedTotalPrice = 0;
		}
		data = this.calculateDiscounts(data);
		//} else {
		//	data.Discount = 0;
		//	
		//}
        data = this.calculateAll(data);
        if (multiple) {
            data.TotalAmount = tempTotalAmount;
        }
        return data;
    }
    calculateDiscounts = (data) => {
        
        data.TaxableDiscount = (data.TaxablePrice - data.DiscountedTaxablePrice).toFixed(2);

        if(!data.TaxableDiscount){
            data.TaxableDiscount = 0;
        }

        return data;
    }
    getData = () => {
        return this.state.data;
    }
    validate = () => {
        var propsData = this.props.data;
        var { data } = this.state;
        var fields = Object.assign([], defaultFields);
        var isValid = true;
        var requiredFields = [];
        var optionalFields = [];
        var extraMessages = [];
        for (var i = 0; i < fields.length; i++) {
            var field = fields[i];
            if (((field.Required === 2 && field.Visibility) || (field.Required === 1 && field.Visibility && !field.Validated)) && ((!data[field.Field] && data[field.Field] !== 0 ) || data[field.Field].length === 0)) {
                isValid = false;
                if (field.Required === 1) {
                    fields[i].Validated = true;
                    optionalFields.push(field.Field);
                }
                else {
                    requiredFields.push(field.Field);
                }
            }
        }

        
        if((data.FIDDocumentRow > 0 && data.Qty1 > propsData.Qty1 && propsData.FIDTypology !== 6) /*|| (propsData.MaxQty1 && data.Qty1 > propsData.MaxQty1)*/ ){
            isValid = false;
            //if(data.FIDCycle === 3) {//passivo
                extraMessages.push(`La q.tà inserita è superiore alla q.tà della riga evasa (${propsData.Qty1}). Inserire una riga nuova con la quantità eccedente.`);
            //}
            //else {
                //extraMessages.push(`La q.tà inserita è superiore alla q.tà disponibile (${propsData.MaxQty1 || propsData.Qty1}).`);
            //}
            
        }
        if(data.TaxableDiscount < 0) {
            isValid = false;
            extraMessages.push(`Verificare e compilare gli importi con dei valori validi!`);
        }

        return { isValid, requiredFields, optionalFields, extraMessages,fields };
    }
    
    fixLocks() {
        var { data } = this.state;
        var case1 = [1, 2];
        var case2 = [3, 4];
		var case3 = [5, 6];
        if(case1.includes(data.CalculationType)){
            if(!data.RetailDiscountDescLock) {
                this.onLockChange1("RetailDiscountDesc")(false);
            }
            else if(!data.TaxablePriceLock) {
                this.onLockChange1("TaxablePrice")(false);
            }
            else if(!data.TotalPriceLock) {
                this.onLockChange1("TotalPrice")(false);
            }
            else if(!data.TotalTaxableAmountLock && data.Qty1 !== 1) {
                this.onLockChange1("TotalTaxableAmount")(false);
            }
            else if(!data.TotalAmountLock && data.Qty1 !== 1) {
                this.onLockChange1("TotalAmount")(false);
            }
            else {
                this.onLockChange1("RetailDiscountDesc")(false);
            }

            if(!data.PublicTaxablePriceLock) {
                this.onLockChange3("PublicTaxablePrice")(false);
            }
            else if(!data.PublicPriceLock) {
                this.onLockChange3("PublicPrice")(false);
            }
            else {
                this.onLockChange3("PublicTaxablePrice")(false);
            }
        }
        if(case2.includes(data.CalculationType)){
            if(!data.TaxablePriceLock) {
                this.onLockChange1("TaxablePrice")(false);
            }
            else if(!data.TotalPriceLock) {
                this.onLockChange1("TotalPrice")(false);
            }
            else if(!data.TotalTaxableAmountLock && data.Qty1 !== 1) {
                this.onLockChange1("TotalTaxableAmount")(false);
            }
            else if(!data.TotalAmountLock && data.Qty1 !== 1) {
                this.onLockChange1("TotalAmount")(false);
            }
            else {
                this.onLockChange1("RetailDiscountDesc")(false);
            }


            if(!data.PublicTaxablePriceLock) {
                this.onLockChange3("PublicTaxablePrice")(true);
            }
            else if(!data.PublicPriceLock) {
                this.onLockChange3("PublicPrice")(true);
            }
            //else {
            //    this.onLockChange3("PublicPrice")(false);
            //}
			
			if(data.RetailDiscountDescLock) {
                this.onLockChange3("RetailDiscountDesc")(false);
            }
			if (data.TaxablePriceLock) {
				this.onLockChange3("TaxablePrice")(false);
			}
        }
		if (case3.includes(data.CalculationType)) {
			if(data.TaxablePriceLock) {
                this.onLockChange1("TaxablePrice")(false);
            }
			else if(!data.TotalPriceLock) {
                this.onLockChange1("TotalPrice")(false);
            }
            else if(!data.TotalTaxableAmountLock && data.Qty1 !== 1) {
                this.onLockChange1("TotalTaxableAmount")(false);
            }
            else if(!data.TotalAmountLock && data.Qty1 !== 1) {
                this.onLockChange1("TotalAmount")(false);
            }
			//this.onLockChange3("TaxablePrice")(false);
            //else {
            //    this.onLockChange1("RetailDiscountDesc")(false);
            //}

            //if(!data.PublicTaxablePriceLock) {
            //    this.onLockChange3("PublicTaxablePrice")(false);
            //}
            //else if(!data.PublicPriceLock) {
            //    this.onLockChange3("PublicPrice")(false);
            //}
            //else if(!data.RetailDiscountDescLock) {
            //    this.onLockChange3("RetailDiscountDesc")(false);
            //}
            //else {
            //    this.onLockChange3("PublicPrice")(false);
            //}
		}
        if(!data.DiscountDescLock) {
            this.onLockChange2("DiscountDesc")(false);
        }
        else if(!data.TaxableDiscountLock) {
            this.onLockChange2("TaxableDiscount")(false);
        }
        else if(!data.DiscountedTaxablePriceLock) {
            this.onLockChange2("DiscountedTaxablePrice")(false);
        }
        else if(!data.DiscountedTotalPriceLock) {
            this.onLockChange2("DiscountedTotalPrice")(false);
        }
        else if(!data.TotalDiscountLock && data.Qty1 !== 1) {
            this.onLockChange2("TotalDiscount")(false);
        }
        else if(!data.TotalDiscountedTaxableAmountLock && data.Qty1 !== 1) {
            this.onLockChange2("TotalDiscountedTaxableAmount")(false);
        }
        else if(!data.TotalDiscountedAmountLock && data.Qty1 !== 1) {
            this.onLockChange2("TotalDiscountedAmount")(false);
        }
        else {
            this.onLockChange2("DiscountDesc")(false);
        }
    }
    onLockChange1 = (id) => (value) => {
        this.setState(state => {
            var recalculateDiscounts = false;
            var case1 = [1,2];
            var case2 = [3,4];
			var case3 = [5,6];
            if((id === "RetailDiscountDesc"  && case1.includes(state.data.CalculationType)) || id !== "RetailDiscountDesc") {
                if(case1.includes(state.data.CalculationType)) { 
                    state.data.RetailDiscountDescLock = true;
                }
                state.data.TaxablePriceLock = true;
                state.data.TotalPriceLock = true;
                state.data.TotalTaxableAmountLock = true;
                state.data.TotalAmountLock = true;
                state.data.RechargePercentageLock = true;

                recalculateDiscounts = true;
                
            }
            else if(id==="RetailDiscountDesc" && case2.includes(state.data.CalculationType)) {
                state.data.PublicTaxablePriceLock = true;
                state.data.PublicPriceLock = true;
                state.data.RetailDiscountDescLock = false;
				state.data.TaxablePriceLock = false;
            }
			else if (case3.includes(state.data.CalculationType)) {
				state.data.TaxablePriceLock = true;
				state.data.PublicTaxablePriceLock = true;
				state.data.RetailDiscountDescLock = true;
				state.data.PublicPriceLock = true;
			}


            state.data[`${id}Lock`] = value;

            if(recalculateDiscounts){
                state.data = this.calculateDiscountedTotals(state.data);
            }
            return state;
        });
    }
    onLockChange2 = (id) => (value) => {
        this.setState(state => {
            state.data.DiscountDescLock = true;
            state.data.TaxableDiscountLock = true;
            state.data.DiscountedTaxablePriceLock = true;
            state.data.DiscountedTotalPriceLock = true;
            state.data.TotalDiscountLock = true;
            state.data.TotalDiscountedTaxableAmountLock = true;
            state.data.TotalDiscountedAmountLock = true;
			
			if (id === "ExtraDiscountDesc" && !value) {
				state.data.DiscountDescLock = false;
			}

            state.data[`${id}Lock`] = value;
            return state;
        });
    }
    onLockChange3 = (id) => (value) => {
        this.setState(state => {
            var case1 = [1,2];
            var case2 = [3,4];
			var case3 = [5,6];
            if(case1.includes(state.data.CalculationType)) {
				state.data.TaxablePriceLock = true;
                state.data.PublicTaxablePriceLock = true;
                state.data.PublicPriceLock = true;
            }
            else if(case2.includes(state.data.CalculationType)) {
				state.data.TaxablePriceLock = true;
                state.data.PublicTaxablePriceLock = true;
                state.data.PublicPriceLock = true;
                //state.data.RetailDiscountDescLock = true;
            } else if (case3.includes(state.data.CalculationType)) {
				state.data.TaxablePriceLock = false;
				state.data.RetailDiscountDescLock = true;
				state.data.PublicTaxablePriceLock = true;
                state.data.PublicPriceLock = true;
			}
            state.data[`${id}Lock`] = value;
            return state;
        });
    }
    render() {
        var { data } = this.state;
		
		var case1 = [1,2];
        var case2 = [3,4];
		var case3 = [5,6];

        var pricelistsUrl = "/GC/pricelist/";
        if (data.FIDBOP && data.FIDBOP > 0) {
            //pricelistsUrl += "?FIDBOP=" + data.FIDBOP;
        }
        
        var lbl = data.FIDCycle===3 ? "Costo" : "Prezzo";
        var lblPublicPrice = "Prezzo vendita";
        var lblPublicTaxablePrice = "Prezzo vendita imponibile";
        
        var qty1Props = { type: 'number',decimalScale: 2 };
        var qty2Props = { type: 'number',decimalScale: 2, disabled: !data.FIDMeasureUnit2 };
        

        var qty1 = data.Qty1;
        if(!qty1){
            qty1 = 0;
        }
        qty1 = parseFloat(qty1);

        var files = data.Files ? data.Files.split(',').map(m => parseInt(m, 10)) : [];

        var fieldCode = getXField(data, defaultFields, 12, 3, "Code", this.props.labels.Code || "Codice XOX", this.onChange, { onBlur: this.onBlur, disabled: data.FIDBOP > 0 && !data.Incomplete });
        var fieldCatalogCode = getXField(data, defaultFields, 12, 3, "CatalogCode", "Codice commerciale XOX", this.onChange, { disabled: data.FIDBOP > 0 && !data.Incomplete });

        var fieldBarcode = getXField(data, defaultFields, 12, 3, "Barcode", "Barcode", this.onChange, { disabled: true });
        var fieldSerialNumber = getXField(data, defaultFields, 12, 3, "SerialNumber", "Seriale", this.onChange, { disabled: true });
        var fieldPosition = getXField(data, defaultFields, 12, 6, "PositionStr", "Posizione", this.onChange, { disabled: true });
        var fieldDestination = getXField(data, defaultFields, 12, 6, "DestinationStr", "Destinazione", this.onChange, { disabled: true });

        var fieldSupplierCode = getXField(data, defaultFields, 12, 3, "SupplierCode", "Codice fornitore", this.onChange, { onBlur: this.onBlur, disabled: data.FIDCycle!==3 });
        var fieldSupplierCatalogCode = getXField(data, defaultFields, 12, 3, "SupplierCatalogCode", "Codice commerciale fornitore", this.onChange, { disabled: data.FIDCycle!==3 });
		var fieldSupplierLabel = getXField(data, defaultFields, 12, 3, "SupplierLabel", "Etichetta fornitore", this.onChange, { onBlur: this.onBlur, disabled: data.FIDCycle!==3 });
        var fieldSupplierSerial = getXField(data, defaultFields, 12, 3, "SupplierSN", "Matricola fornitore", this.onChange, { disabled: data.FIDCycle!==3 });


        var fieldDescription = getXField(data, defaultFields, 12, 12, "Description", "Descrizione", this.onChange, { onBlur: this.onBlur, disabled: data.FIDCausal !== 6 && (data.FIDBOP > 0 && !data.Incomplete) });
        var fieldExtendedDescription = getXField(data, defaultFields, 12, 12, "ExtendedDescription", "Descrizione estesa", this.onChange, { disabled: data.FIDCausal !== 6 && (data.FIDBOP > 0 && !data.Incomplete) });
        
        var fieldQty1 = getXField(data, defaultFields, 12, 3, "Qty1", "Q.tà principale", this.onChange, qty1Props);
        var fieldQty2 = getXField(data, defaultFields, 12, 3, "Qty2", "Q.tà secondaria", this.onChange, qty2Props);
        var fieldMeasureUnit1 = getXSelect(data, defaultFields, 12, 3, "FIDMeasureUnit1", "U.M. principale", this.onSelectChange, "/Base/GetGCMeasureUnits","",{disabled: data.FIDCausal > 0});
        var fieldMeasureUnit2 = getXSelect(data, defaultFields, 12, 3, "FIDMeasureUnit2", "U.M. secondaria", this.onSelectChange, "/Base/GetGCMeasureUnits","",{disabled: data.FIDCausal > 0});

        var fieldCostCalculationType = getXSelect(data, defaultFields, 12, 6, "CalculationType", "Tipo calcolo costo/prezzo", this.onSelectChange, "/enums/boppricecalculationtype");

        var fieldPriceType = getXSelect(data, defaultFields, 12, 3, "PriceType", "Tipo prezzo", this.onSelectChange, '/enums/docpricetype');
       
        var fieldPricelist = getXSelect(data, defaultFields, 12, 6, "FIDPricelist", "Listino vendita", this.onSelectChange, pricelistsUrl);
        var fieldVatPricelist = getXSelect(data, defaultFields, 12, 6, "FIDVatPricelist", "IVA Listino vendita", this.onSelectChange, "/GC/vat","",{innerRef: this.cboFIDVatPricelist });

        var fieldVat = getXSelect(data, defaultFields, 12, 6, "FIDVat", "IVA", this.onSelectChange, "/GC/vat","",{innerRef: this.cboFIDVat });
		var fieldMarkup = getXSelect(data, defaultFields, 12, 6, "FIDMarkup", "Formula di ricarico", this.onSelectChange, "/gc/markup" );

        var lblTaxPrice = qty1 === 1 ? `${lbl} netto` : "Prezzo unitario";
        if(data.FIDDOCRowType === 7) {
            lblTaxPrice = "Sconto (importo)";
        }

        var hasSupplierData = data.FIDCycle === 3 && data.FIDDOCRowType === 1 && this.props.FIDDocumentType !== 4

        if(!data.TaxablePriceLock && !data.TotalPriceLock
             && (data.Qty1 === 1 || (data.Qty1 !== 1 && !data.TotalTaxableAmountLock && !data.TotalAmountLock))
             && (!hasSupplierData || ( hasSupplierData && !data.RetailDiscountDescLock ))

        ) {
            if(hasSupplierData) {
                data.RetailDiscountDescLock = false;
                data.TaxablePriceLock = true;
                data.TotalPriceLock = true;
                data.TotalTaxableAmountLock = true;
                data.TotalAmountLock = true;
            }
            else {
                data.TaxablePriceLock = false;
                data.TotalPriceLock = true;
                data.TotalTaxableAmountLock = true;
                data.TotalAmountLock = true;
                data.RechargePercentageLock = true;
            }
        }
        if(hasSupplierData && !data.PublicPriceLock && !data.PublicTaxablePriceLock) {
            data.PublicTaxablePriceLock = true;
            data.PublicPriceLock = false;
        }
        if(!data.DiscountDescLock && !data.TaxableDiscountLock && !data.DiscountedTaxablePriceLock && !data.DiscountedTotalPriceLock && 
        (data.Qty1 === 1 || (data.Qty1 !== 1 && !data.TotalDiscountLock && !data.TotalDiscountedTaxableAmountLock && !data.TotalDiscountedAmountLock))) {
            data.DiscountDescLock = false;    
            data.TaxableDiscountLock = true;    
            data.DiscountedTaxablePriceLock = true;    
            data.DiscountedTotalPriceLock = true;    
            data.TotalDiscountLock = true;    
            data.TotalDiscountedTaxableAmountLock = true;    
            data.TotalDiscountedAmountLock = true;    
            
        }
        if(!data.TaxablePriceLock || !data.TotalPriceLock){
            data.RechargePercentageLock = true;
        }

        //var fieldSellPrice = getXField(data, defaultFields, 12, 2, "SellPrice",lblPrice, this.onChange, {type: 'number',decimalScale: 2});
        var fieldPublicPrice = getXField(data, defaultFields, 12, 6, "PublicPrice",lblPublicPrice, this.onChange, {type: 'number',decimalScale: 2, lockable: data.PublicPriceLock, isLocked: data.PublicPriceLock, readOnly: data.PublicPriceLock, onLockChange: this.onLockChange3});
        var fieldPublicTaxablePrice = getXField(data, defaultFields, 12, 6, "PublicTaxablePrice",lblPublicTaxablePrice, this.onChange, {type: 'number',decimalScale: 2, lockable: data.PublicTaxablePriceLock, isLocked: data.PublicTaxablePriceLock, readOnly: data.PublicTaxablePriceLock, onLockChange: this.onLockChange3});
        var fieldRetailDiscountDesc = getXField(data, defaultFields, 12, 6, "RetailDiscountDesc", (data.CalculationType <= 2 ? "Sc % retail" : "Ricarico %"), this.onChange, { lockable: data.RetailDiscountDescLock, isLocked: data.RetailDiscountDescLock, readOnly: data.RetailDiscountDescLock, onLockChange: this.onLockChange1 });
        
        

        var fieldTaxablePrice = getXField(data, defaultFields, 12, 2, "TaxablePrice",lblTaxPrice, this.onChange, {type: 'number',decimalScale: 2, lockable: data.TaxablePriceLock, isLocked: data.TaxablePriceLock, readOnly: data.TaxablePriceLock, onLockChange: this.onLockChange1});
        var fieldTotalPrice = getXField(data, defaultFields, 12, 2, "TotalPrice",qty1 === 1 ? `${lbl} ivato` : "Prezzo unitario ivato", this.onChange, {type: 'number',decimalScale: 2, lockable: data.TotalPriceLock, isLocked: data.TotalPriceLock, readOnly: data.TotalPriceLock, onLockChange: this.onLockChange1});
        var fieldTotalTaxableAmount = getXField(data, defaultFields, 12, 2, "TotalTaxableAmount",`${lbl} netto`, this.onChange, {type: 'number',decimalScale: 2, lockable: data.TotalTaxableAmountLock, isLocked: data.TotalTaxableAmountLock, readOnly: data.TotalTaxableAmountLock, onLockChange: this.onLockChange1});
        var fieldTotalAmount = getXField(data, defaultFields, 12, 2, "TotalAmount",`${lbl} Ivato`, this.onChange, {type: 'number',decimalScale: 2, lockable: data.TotalAmountLock, isLocked: data.TotalAmountLock, readOnly: data.TotalAmountLock, onLockChange: this.onLockChange1});
        var fieldRechargePercentage = getXField(data, defaultFields, 12, 3, "RechargePercentage", "Percentuale ricarico", this.onChange, { lockable: data.RechargePercentageLock, isLocked: data.RechargePercentageLock, readOnly: data.RechargePercentageLock, onLockChange: this.onLockChange1});

        var fieldDiscountDesc = getXField(data, defaultFields, 6, 4, "DiscountDesc","Sc. %", this.onChange, {lockable: data.DiscountDescLock, isLocked: data.DiscountDescLock, readOnly: data.DiscountDescLock, onLockChange: this.onLockChange2});
		var fieldExtraDiscountDesc = getXField(data, defaultFields, 6, 4, "ExtraDiscountDesc","Ex. Sc. %", this.onChange, {lockable: data.DiscountDescLock, isLocked: data.DiscountDescLock, readOnly: data.DiscountDescLock, onLockChange: this.onLockChange2});
        var fieldTaxableDiscount = getXField(data, defaultFields, 12, 4, "TaxableDiscount","Sc. netto", this.onChange, {type: 'number',decimalScale: 2,lockable: data.TaxableDiscountLock, isLocked: data.TaxableDiscountLock, readOnly: data.TaxableDiscountLock, onLockChange: this.onLockChange2});
        var fieldDiscountedTaxablePrice = getXField(data, defaultFields, 12, 2, "DiscountedTaxablePrice",qty1 === 1 ? "Imponibile" : "Imponibile unitario", this.onChange, {type: 'number',decimalScale: 2,lockable: data.DiscountedTaxablePriceLock, isLocked: data.DiscountedTaxablePriceLock, readOnly: data.DiscountedTaxablePriceLock, onLockChange: this.onLockChange2});
        var fieldDiscountedTotalPrice = getXField(data, defaultFields, 12, 2, "DiscountedTotalPrice",qty1 === 1 ? "Totale ivato" : "Unitario ivato", this.onChange, {type: 'number',decimalScale: 2,lockable: data.DiscountedTotalPriceLock, isLocked: data.DiscountedTotalPriceLock, readOnly: data.DiscountedTotalPriceLock, onLockChange: this.onLockChange2});
        var fieldTotalDiscount = getXField(data, defaultFields, 12, 4, "TotalDiscount","Sc. tot.", this.onChange, {type: 'number',decimalScale: 2,lockable: data.TotalDiscountLock, isLocked: data.TotalDiscountLock, readOnly: data.TotalDiscountLock, onLockChange: this.onLockChange2});
        var fieldTotalDiscountedTaxableAmount = getXField(data, defaultFields, 12, 2, "TotalDiscountedTaxableAmount","Imponibile", this.onChange, {type: 'number',decimalScale: 2,lockable: data.TotalDiscountedTaxableAmountLock, isLocked: data.TotalDiscountedTaxableAmountLock, readOnly: data.TotalDiscountedTaxableAmountLock, onLockChange: this.onLockChange2});
        var fieldTotalDiscountedAmount = getXField(data, defaultFields, 12, 2, "TotalDiscountedAmount","Totale ivato", this.onChange, {type: 'number',decimalScale: 2,lockable: data.TotalDiscountedAmountLock, isLocked: data.TotalDiscountedAmountLock, readOnly: data.TotalDiscountedAmountLock, onLockChange: this.onLockChange2});


        
        var fieldInternalRemark = getXField(data, defaultFields, 12, 6, "InternalRemarks", "Nota interna", this.onChange, { multiline: true });
        var fieldPublicRemark = getXField(data, defaultFields, 12, 6, "PublicRemarks", "Nota pubblica", this.onChange, { multiline: true });


        var fieldRowType = getXSelect(data, defaultFields, 12, 12, "FIDDOCRowType", "Tipo riga", this.onSelectChange, "/DOC/GetRowTypes","",{disabled: data.FIDBOP > 0 || data.ID > 0 });

        var fieldIsInWarranty = getXSwitch(data, defaultFields, 12, 2, "IsInWarranty","In garanzia", this.onChange);
        var panels = [];
        if(data.FIDCausal !== 6 && !data.FIDBOP){
            panels.push({
                icon: <CodeBrackets />,
                label: "Riga",
                content: <Grid item xs={12} container direction="row" spacing={2}>
                    <Grid container spacing={2} xs={12} md></Grid>
                    <Grid container spacing={2} xs={12} md={4}>
                        {fieldRowType}
                    </Grid>
                    <Grid container spacing={2} xs={12} md></Grid>
                </Grid>
            });
        }
        panels.push({
            icon: <CodeBrackets />,
            label: "Codifica",
            content: <Grid item xs={12} container direction="row" spacing={2}>
                <Grid container spacing={2} xs={12} md style={{padding:25}}>
                    {data.FIDDOCRowType !== 7 && <XGallery imageIds={files} />}
                </Grid>
                <Grid container spacing={2} xs={12} md={8}>
                    {data.FIDDOCRowType !== 7 && fieldCode}
                    {data.FIDDOCRowType !== 7 && fieldCatalogCode}
                    {data.FIDDOCRowType !== 7 && fieldBarcode && fieldSerialNumber && <Fragment>{fieldBarcode}{fieldSerialNumber}</Fragment>}
                    {data.FIDDOCRowType !== 7 && !(fieldBarcode && fieldSerialNumber) && <Grid item xs={12} md={6}></Grid>}
                    {data.FIDDOCRowType !== 7 && data.FIDDOCRowType === 1 && <Fragment>
                        {fieldSupplierCode}
                        {fieldSupplierCatalogCode}
                        {fieldSupplierSerial}
						{fieldSupplierLabel}
                    </Fragment>}
                    {fieldDescription}
                    {data.FIDDOCRowType !== 7 && fieldExtendedDescription}
                    {data.FIDDOCRowType !== 7 && fieldQty1}
                    {data.FIDDOCRowType !== 7 && fieldMeasureUnit1}
                    {data.FIDDOCRowType !== 7 && fieldQty2}
                    {data.FIDDOCRowType !== 7 && fieldMeasureUnit2}

                    {data.FIDDOCRowType !== 7 && fieldDestination}
                    {data.FIDDOCRowType !== 7 && fieldPosition}
                </Grid>
            </Grid>
        });
        if(hasSupplierData && (!data.Barcode || data.FIDCausal === 1)) {
			var fieldCost = getXField(data, defaultFields, 12, 6, "TaxablePrice", "Costo", this.onChange, {type: 'number',decimalScale: 2, lockable: data.TaxablePriceLock, isLocked: data.TaxablePriceLock, readOnly: data.TaxablePriceLock, onLockChange: this.onLockChange1});
            panels.push({
                icon: <CodeBrackets />,
                label: "Fornitore - costo",
                content: <Grid item xs={12} container direction="row" spacing={2}>
                    <Grid container spacing={2} xs={12} md></Grid>
                    <Grid container spacing={2} xs={12} md={8}>
                        {fieldPricelist}
                        {fieldVatPricelist}
                        {fieldCostCalculationType}
						<Grid item xs={12} md={6} />
						{case1.includes(data.CalculationType) && <Fragment>
							{fieldPublicTaxablePrice}
							{fieldPublicPrice}
							{fieldRetailDiscountDesc}
							{fieldCost}
						</Fragment>}
						{case2.includes(data.CalculationType) && <Fragment>
							{fieldCost}
							{fieldRetailDiscountDesc}
							{fieldPublicTaxablePrice}
							{fieldPublicPrice}
						</Fragment>}
						{case3.includes(data.CalculationType) && <Fragment>							
							{fieldCost}
							{fieldMarkup}
							{fieldPublicTaxablePrice}
							{fieldPublicPrice}
						</Fragment>}
						{//data.CalculationType > 4 ? fieldMarkup : <Grid item xs={12} md={6} />
						}
						{/*fieldCost*/}
                        {/*fieldPublicTaxablePrice*/}
                        {/*fieldPublicPrice*/}
                        {/*fieldRetailDiscountDesc*/}
                    </Grid>
                    <Grid container spacing={2} xs={12} md></Grid>
                </Grid>
            });
        }
        if(fieldPricelist || fieldVat || fieldTaxablePrice || fieldDiscountDesc || fieldTaxableDiscount || fieldDiscountedTaxablePrice || fieldDiscountedTotalPrice
            || fieldTotalTaxableAmount || fieldTotalAmount || fieldTotalDiscount || fieldTotalDiscountedTaxableAmount || fieldTotalDiscountedAmount)   {
				fieldPricelist = getXSelect(data, defaultFields, 12, 4, "FIDPricelist", "Listino vendita", this.onSelectChange, pricelistsUrl);
				//fieldVat = getXSelect(data, defaultFields, 12, 4, "FIDVat", "IVA", this.onSelectChange, "/GC/vat","",{innerRef: this.cboFIDVatPricelist });
				fieldMarkup = getXSelect(data, defaultFields, 12, 4, "FIDMarkup", "Formula di ricarico", this.onSelectChange, "/gc/markup" );
				
				if (data.FIDCycle === 2) {
					fieldPriceType = getXSelect(data, defaultFields, 12, 4, "PriceType", "Tipo prezzo", this.onSelectChange, '/enums/docpricetype');
					fieldPricelist = getXSelect(data, defaultFields, 12, 4, "FIDPricelist", "Listino vendita", this.onSelectChange, pricelistsUrl);
					fieldVat = getXSelect(data, defaultFields, 12, 6, "FIDVat", "IVA", this.onSelectChange, "/GC/vat","",{innerRef: this.cboFIDVat });
					fieldCostCalculationType = getXSelect(data, defaultFields, 12, 6, "CalculationType", "Tipo calcolo prezzo", this.onSelectChange, "/enums/boppricecalculationtype", "", { filtersExclude: [ { key: "ID", value: 1 }, { key: "ID", value: 2 } ]});
					fieldRechargePercentage = getXField(data, defaultFields, 12, 2, "RechargePercentage", "Ricarico %", this.onChange, { lockable: data.RechargePercentageLock, isLocked: data.RechargePercentageLock, readOnly: data.RechargePercentageLock, onLockChange: this.onLockChange1});
					fieldMarkup = getXSelect(data, defaultFields, 12, 2, "FIDMarkup", "Formula di ricarico", this.onSelectChange, "/gc/markup" );
				}
				
				panels.push({
					icon: <CodeBrackets />,
					label: "Dati riga",
					content: <Grid item xs={12} container direction="row" spacing={2}>
						
						<Grid container spacing={2} xs={12} md></Grid>
						<Grid container spacing={1} xs={12} md={8}>
							{data.FIDDOCRowType !== 7 && fieldVat}
							{data.FIDDOCRowType !== 7 && <Grid item xs={12} md={6}/>}
							{data.FIDDOCRowType !== 7 && data.FIDCycle !== 3 && data.FIDDOCRowType === 1 && fieldPriceType}
							{data.FIDDOCRowType !== 7 && data.FIDCycle !== 3 && data.FIDDOCRowType === 1 && (data.PriceType||1) === 1 && fieldPricelist}
							{data.FIDDOCRowType !== 7 && data.FIDCycle !== 3 && data.FIDDOCRowType === 1 && (data.PriceType||1) === 1 && <Grid item xs={12} md={4} />}
							{data.FIDDOCRowType !== 7 && data.FIDCycle !== 3 && data.FIDDOCRowType === 1 && data.PriceType === 2 && fieldCostCalculationType}
							{data.FIDDOCRowType !== 7 && data.FIDCycle !== 3 && data.FIDDOCRowType === 1 && data.PriceType === 2 && [3,4].includes(data.CalculationType) && fieldRechargePercentage}
							{data.FIDDOCRowType !== 7 && data.FIDCycle !== 3 && data.FIDDOCRowType === 1 && data.PriceType === 2 && [5,6].includes(data.CalculationType) && fieldMarkup}
							{data.FIDDOCRowType !== 7 && data.FIDCycle !== 3 && data.FIDDOCRowType === 1 && ((data.PriceType == 2 && !data.CalculationType)) && <Grid item xs={12} md={2} />}
							{/* {data.FIDDOCRowType !== 7 && data.FIDCycle !== 3 && data.FIDDOCRowType === 1 && <Grid item xs={12} md={3}></Grid>} */}
							{/*data.FIDDOCRowType !== 7 && (data.FIDCycle === 3 || data.FIDDOCRowType !== 1) && <Grid item xs={12} md={6}/>*/}
							
							{fieldTaxablePrice}
							{data.FIDDOCRowType !== 7 && fieldTotalPrice}
							<Grid item container spacing={1} xs={12} md={4}>
								{data.FIDDOCRowType !== 7 && fieldDiscountDesc}
								{data.FIDDOCRowType !== 7 && fieldExtraDiscountDesc}
								{data.FIDDOCRowType !== 7 && fieldTaxableDiscount}
							</Grid>
							{data.FIDDOCRowType !== 7 && fieldDiscountedTaxablePrice}
							{data.FIDDOCRowType !== 7 && fieldDiscountedTotalPrice}
							{qty1 !== 1 && fieldTotalTaxableAmount}
							{qty1 !== 1 && fieldTotalAmount}
							<Grid item container spacing={1}>
								{qty1 !== 1 && fieldDiscountDesc}
								{qty1 !== 1 && fieldExtraDiscountDesc}
								{qty1 !== 1 && fieldTotalDiscount}
							</Grid>
							{qty1 !== 1 && fieldTotalDiscountedTaxableAmount}
							{qty1 !== 1 && fieldTotalDiscountedAmount}
							{fieldIsInWarranty}
						</Grid>
						<Grid container spacing={2} xs={12} md></Grid>
					</Grid>
				});
			}
        panels.push({
            icon: <CodeBrackets />,
            label: "Note",
            content: <Grid item xs={12} container direction="row" spacing={2}>
                
                <Grid container spacing={2} xs={12} md></Grid>
                <Grid container spacing={2} xs={12} md={8}>
                    {fieldInternalRemark}
                    {fieldPublicRemark}
                </Grid>
                <Grid container spacing={2} xs={12} md></Grid>
            </Grid>
        });
        var ui = <XExpansionPanel panels={panels} />;
        return (
            <TabContainer padding={0}>
                {ui}
            </TabContainer>
        );
    }
}

const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(styles),
);
    
export default enhance(DocumentRowEditor);