import React, { Component, Fragment } from 'react';

import { Prompt } from 'react-router';
import PropTypes from 'prop-types';

import { withStyles, withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';
import { FileExcelBox, PrinterPos, Printer } from 'mdi-material-ui';
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import TabContainer from 'components/CustomTabs/TabContainer';
import XExpansionPanel from 'components/XExpansionPanel';
import XGrid from 'components/XGrid';
import XMessageBox from 'components/XMessageBox';
import XButton from 'components/XButton';
import XProgress from 'components/XProgress';
import XSelect from 'components/XSelect';
import XSwitch from 'components/XSwitch';
import axios from 'axios';

import { getXSelect, getXField, getXSwitch, getXDateField, getXDateTimeField, handleErrors, popupBOPSearch, getBOPSearchContent, parseParam, getDiscount, getRecharge, round, getField } from 'utils';

import { ArrowLeft, ArrowRight, Check, Pencil, RedoVariant, Database, ClipboardTextPlayOutline, ClipboardTextOutline, TextBox, TextBoxSearch, AlphaDcircle, AlphaFcircle, Magnify, FileCheckOutline, TruckDeliveryOutline } from 'mdi-material-ui';
import { Ring } from 'components/Icons';
import DocumentRowEditor from '../../components/DocumentRowEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';
import XNavigation from '../../components/XNavigation';
import { Typography } from '@material-ui/core';

import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import XStepper from 'components/XStepper';
import XGridSelectorFilter from 'components/XGridSelectorFilter';
import BOPEdit from 'layouts/BOPEdit';
import XOXLoader from 'components/XOXLoader';
import moment from 'moment';
import PrintReceipt from './Cash/PrintReceipt';
import CashPayment from './Cash/CashPayment';
import XPDFViewer from 'components/XPDFViewer';

const styles = theme => ({
});

const defaultFields = [
    {
        Field: "FIDOwner",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDOwnerDestination",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDOwnerPosition",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDCycle",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDDocumentType",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDDocumentSubtype",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDCausal",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "Description",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDRegister",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "Number",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "DocumentDate",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "RegisterDate",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDIdentity",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDDestination",
        Visibility: true,
        Required: 2,
    },
    {
        Field: "FIDIRRelation",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "ShowAllDestinations",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "InternalRemarks",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "PublicRemarks",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "FIDCurrency",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "CurrencyChange",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "FIDVat",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "FIDPaymentMethod",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "DiscountDesc",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "ExtraDiscountDesc",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "FIDBank",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "Amount",
        Visibility: true,
        Required: 1,
    },
    {
        Field: "Taxes",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "TotalAmount",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "FIDPosition",
        Visibility: false,
        Required: 3,
    },
    {
        Field: "FIDPricelist",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "CalculationType",
        Visibility: true,
        Required: 3,
    },
    {
        Field: "PriceType",
        Visibility: true,
        Required: 3,
    },
    {
		Field: "PricelistDiscountDesc",
		Visibility: true,
		Required: 3
	},
	{
		Field: "FIDMarkup",
		Visibility: false,
		Required: 3,
	},
    {
        Field: "RechargePercentage",
        Visibility: false,
        Required: 3,
    },
	{
		Field: "FIDIdentityVector",
		Visibility: true,
		Required: 3
	},
	{
		Field: "TransportRef",
		Visibility: true,
		Required: 3
	},
	{
		Field: "FIDTransportPort",
		Visibility: true,
		Required: 3
	},
	{
		Field: "FIDTransportGoodsLook",
		Visibility: true,
		Required: 3
	},
	{
		Field: "TransportPackages",
		Visibility: true,
		Required: 3
	},
	{
		Field: "TransportSize",
		Visibility: true,
		Required: 3
	},
	{
		Field: "TransportDatetime",
		Visibility: true,
		Required: 3
	},
	{
		Field: "TransportWeight",
		Visibility: true,
		Required: 3
	},
	{
		Field: "TransportNote",
		Visibility: true,
		Required: 3
	},
];
const IntentDeclarationFields = [
	{
		Field: "FIDIntentDeclaration",
		Visibility: true,
		Required: 2
	},
	{
		Field: "Amount",
		Visibility: true,
		Required: 2
	},
];
const FieldLabels = {
    FIDOwner: "Intestatario documento",
    FIDOwnerDestination: "Sede di destinazione",
    FIDOwnerPosition: "Magazzino di destinazione",
    FIDCausal: "Causale",
    Description: "Descrizione",
    FIDRegister: "Registro",
    Number: "Numero",
    DocumentDate: "Data documento",
    RegisterDate: "Data registrazione",
    FIDIdentity: "Anagrafica",
    FIDDestination: "Destinazione",
    FIDRelation: "Relazione",
    Amount: "Imponibile",
    Taxes: "Imposte",
    TotalAmount:"Totale",
    CalculationType:"Tipo calcolo costo",
	FIDPaymentMethod: "Metodo di pagamento",
    FIDDOCRowType:"Tipo riga",
	FIDIdentityVector: "Anagrafica vettore",
	FIDTransportPort: "Porto",
	FIDTransportGoodsLook: "Aspetto beni",
	TransportPackages: "Numero colli",
	TransportSize: "Misura",
	TransportWeight: "Peso lordo",
	TransportDatetime: "Data/ora trasporto",
	TransportNote: "Annotazioni trasporto",
	TransportRef: "Riferimento trasporto"
};


class DOCEdit extends Component {
    constructor(props) {
        super(props);
        this.tabs = React.createRef();
        this.MessageBox = React.createRef();
        this.grdDOCBops = React.createRef();
        this.grdDOCRows = React.createRef();
        this.grdDOCProcessRows = React.createRef();
        this.DocumentRowEditor = React.createRef();
        this.cboVat = React.createRef();
		this.cboIdentity = React.createRef();
        this.grdDOCBopsSearch = React.createRef();
        this.bopsSearch = React.createRef();
        this.printReceipt = React.createRef();
        this.grdIntentDeclarations = React.createRef();
        this.CashPayment = React.createRef();
        this.state = {
            fields: defaultFields,
            data: {
                FIDCurrency: this.props.params.STDFIDCurrency,
                CurrencyChange: this.props.params.STDCurrencyChange,
                FIDVat: this.props.params.STDFIDVat,
                FIDPricelist: this.props.params.STDFIDPricelist,
                DocumentDate: new Date(),
                RegisterDate: new Date(),
                FIDStatus: this.props.params.DOCStatusNewid || 1,				
            },
			paramsLocks: {
				DiscountDescLock: true,
				ExtraDiscountDescLock: true,
				RechargePercentageLock: true,
				PricelistDiscountDescLock: true
			},
            initialData: {},
            firstLoad: true,
            selectedRows: [],
            addNewRow: false,
            newRowType: 1,
            addRows: false,
            viewRows: "BOPS",
            snackbar: {
                open: false,
                message: undefined,
            },
            IdentityDiscount: 0,
            HasRows: false,
            docIRFilter: 1,
            gridDestinationFilter: undefined,
            gridPositionFilter: undefined,
            printReceipt: false,
            showAmounts: true,
			hideDiscounts: false
        };
    }
    static contextTypes = {
        router: PropTypes.object
    }
    resetFields = (FIDCycle,isInternalDoc,FIDDocumentType,FIDDocumentSubtype,FIDCausal) =>{
		this.setField("FIDPricelist", true, 3);
		this.setField("FIDMarkup", false, 3);
		this.setField("RechargePercentage", false, 3);
		
		if (FIDCycle === (this.props.params.PassiveCycleId || 3)) {
			this.setField("PriceType", false, 3);
			this.setField("CalculationType", true, 3);
			
			switch (this.state.data.CalculationType) {
				case 1: case 2:
					this.setField("PricelistDiscountDesc", true, 3);
					this.setField("RechargePercentage", false, 3);
					this.setField("FIDMarkup", false, 3);
					break;
				case 3: case 4:
					this.setField("PricelistDiscountDesc", false, 3);
					this.setField("RechargePercentage", true, 3);
					this.setField("FIDMarkup", false, 3);
					break;
				case 5: case 6:
					this.setField("PricelistDiscountDesc", false, 3);
					this.setField("RechargePercentage", false, 3);
					this.setField("FIDMarkup", true, 3);
					break;
			}
		} else {
			this.setField("PriceType", true, 3);
			this.setField("CalculationType", false, 3);
		}
		
        if (FIDCycle === (this.props.params.PassiveCycleId || 3) && !isInternalDoc && FIDDocumentType !== 4 && FIDDocumentSubtype !== 7) {
            this.setField("Number",true,2);
        }
        else {
            //defaultFields[9].Required = 3;
            this.setField("Number",true,3);
        }
        if(FIDDocumentType === 2 && FIDDocumentSubtype === 3){//commerciale
            this.setField("Number",true,defaultFields[9].Required === 2 ? 3 : 1);
        }
        if(isInternalDoc){

            this.setField("ShowAllDestinations",false,3);
            this.setField("FIDPaymentMethod",false,3);
            this.setField("FIDBank",false,3);

            this.setField("Amount",false,3);
            this.setField("Taxes",false,3);
            this.setField("TotalAmount",false,3);
            
            if(FIDCausal === 8){
                this.setField("FIDPosition",true,1);
            }
            else {
                
                this.setField("FIDPosition",false,3);
            }
        }
        else {
            
            this.setField("ShowAllDestinations",true,3);
			
			if (FIDDocumentSubtype === (this.props.params.DOCSalePurchSubTypeId||5))
				this.setField("FIDPaymentMethod",true,2);
			else
			    this.setField("FIDPaymentMethod",true,3);
            this.setField("FIDBank",true,3);

            this.setField("Amount",true,1);
            this.setField("Taxes",true,3);
            this.setField("TotalAmount",true,3);
            
            this.setField("FIDPosition",false,3);
        }
        if(FIDCausal === 16 || FIDCausal === 38) {//cassa o reso cassa
            
            this.setField("FIDIdentity",true,1);
            this.setField("FIDDestination",true,1);
        }
        else if (FIDCausal == 4) {
            this.setField("FIDOwnerDestination", true, 1);
            this.setField("FIDOwnerPosition", true, 1);
            this.setField("FIDDestination", true, 1);
        } else if (FIDCausal == 10) {
            this.setField("FIDOwnerDestination", true, 1);
            this.setField("FIDOwnerPosition", true, 1);
            this.setField("FIDDestination", true, 1);
        }
        else {
            this.setField("FIDIdentity",true,2);
            this.setField("FIDDestination",true,2);
        }
    }
    componentWillMount = async() => {
        var { FIDCycle, FIDDocumentType, FIDDocumentSubtype, FIDCausal, FIDRegister, FIDLicensee, FIDLicenseeDestination } = this.props;
        
        var isInternalDoc = FIDDocumentType === this.props.params.DOCInternalTypeId;
        if (FIDCycle && FIDDocumentType && FIDDocumentSubtype && FIDCausal && FIDRegister) {
            this.setState(state => {
                state.data.FIDCycle = FIDCycle;
                state.data.FIDDocumentType = FIDDocumentType;
                state.data.FIDDocumentSubtype = FIDDocumentSubtype;
                state.data.FIDCausal = FIDCausal;
                state.data.FIDRegister = FIDRegister;
                state.data.FIDOwner = FIDLicensee;
                state.data.FIDOwnerDestination = FIDLicenseeDestination;
                this.resetFields(FIDCycle,isInternalDoc,FIDDocumentType,FIDDocumentSubtype,FIDCausal);
                this.setDocTitleTitle();
                if(isInternalDoc){
                    state.data.FIDIdentity = FIDLicensee;
                    state.data.FIDDestination = FIDLicenseeDestination;
                }
                //state.initialData = Object.assign({}, state.data);
                return state;
            }, async() => {
                this.clearSession();
                this.setDefaultPosition();
                this.setDescription();
                await this.getVatData();
                this.setIRDefaultPosition();
                //this.loadData();
            })
        }
        else {
            this.clearSession();
            await this.getVatData();
            this.loadData(() => {
                var { data } = this.state;
                
                var isInternalDoc = data.FIDDocumentType === this.props.params.DOCInternalTypeId;
                
                this.resetFields(data.FIDCycle,isInternalDoc,data.FIDDocumentType,data.FIDDocumentSubtype,data.FIDCausal);
                this.setPriceType(data.PriceType);
                this.setDocTitleTitle();
            });
        }
    }
    componentDidMount = () => {
        //this.props.startConnection();
        //this.manageConnectionEvents();
    }
    componentWillUnmount = () => {
        //this.props.stopConnection();
    }
    timeout = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    // manageConnectionEvents = async () => {
    //     while(!this.props.connection) {
    //         await this.timeout(100);
    //     }
    //     this.props.connection.on("OnClientStart", this.onClientStart)
    //     this.props.connection.on("OnReceiptOutput", this.onReceiptOutput)
    //     this.props.connection.on("OnCashRegisterError", this.onCashRegisterError)
    // }
    // onClientStart = () => {
    //     console.log("client start");
    // }
    // onReceiptOutput = (output) => {
    //     this.setState({ printingReceipt: false, printReceipt: false});
    // }
    // onCashRegisterError = (errorCode, errorDescription) => {
    //     console.error(errorCode,errorDescription);
    //     this.setState({ printingReceipt: false });
    // }
    onUpdateShowAmounts = (id) => (event,value) => {
        this.setState({showAmounts: value});
    }
	onUpdateHideDiscounts = (id) => (event, value) => {
		this.setState({ hideDiscounts: value});
	}
	setCalculationType = (CalcType) => {
		var case1 = [1,2];
        var case2 = [3,4];
		var case3 = [5,6];
		
		if (case1.includes(CalcType)) {
			this.setField("PricelistDiscountDesc", true, 3);
            this.setField("RechargePercentage", false, 3);
			this.setField("FIDMarkup", false, 3);
		} else if (case2.includes(CalcType)) {
			this.setField("PricelistDiscountDesc", false, 3);
            this.setField("RechargePercentage", true, 2);
			this.setField("FIDMarkup", false, 3);
		}
		else if (case3.includes(CalcType)) {
			this.setField("PricelistDiscountDesc", false, 3);
            this.setField("RechargePercentage", false, 3);
			this.setField("FIDMarkup", true, 2);
		}
	}
    setPriceType = (PriceType) => {
        if(PriceType === 1){
            this.setField("FIDPricelist", true, 3);
			this.setField("CalculationType", false, 3);
			
			if (this.state.data.FIDCycle !== 3) {
				this.setField("RechargePercentage", false, 3);
				this.setField("FIDMarkup", false, 3);
			}
        }
        else if(PriceType === 2){
            this.setField("FIDPricelist", false, 3);
            this.setField("CalculationType", true, 3);
			this.setCalculationType(this.state.data.CalculationType);
			//this.setField("FIDMarkup", false, 3);
        } /*else if(PriceType === 3){
			this.setField("FIDPricelist", false, 3);
            this.setField("RechargePercentage", false, 3);
			this.setField("FIDMarkup", true, 3);
		}*/
        }
    setDocTitleTitle = async () => {
		var cycleStr = "";
		
		if (this.state.data.FIDCycle === 2) {
			if (this.state.data.FIDCausal === 4)
				cycleStr = "Ricezione";
			else
				cycleStr = "Emissione";
		} else if (this.state.data.FIDCycle === 3) {
			if (this.state.data.FIDCausal === 4)
				cycleStr = "Emissione";
			else if (this.state.data.FIDCausal === 20)
				cycleStr = "Documento interno";
			else
				cycleStr = "Ricezione";
		} else if (this.state.data.FIDCycle === 1)
		{
			cycleStr = "Documento interno";
    }
		
        var causalStr = await fetch(`/DOC/GetCausalDescription/${this.state.data.FIDCausal}`).then(res => res.json());
        var identityStr = await fetch(`/DOC/GetIRDenomination/${this.state.data.FIDIdentity}`).then(res => res.json());
        var destinationStr = await fetch(`/DOC/GetIRAlias/${this.state.data.FIDDestination}`).then(res => res.json());;
        var title = `${cycleStr} - ${causalStr}`;
		
		if (identityStr) {
			title += ` - ${identityStr}`;
			
			if (destinationStr && destinationStr !== identityStr)
				title += ` - ${destinationStr}`;
		}
		
        this.props.setTitle(title);
    }
    
    setField = (field, visibility, required) => {
        var fields = defaultFields.filter(f => f.Field === field);
        for(var i in fields){
            fields[i].Visibility = visibility;
            fields[i].Required = required;
        }
    }

    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.props.setTitle(undefined);
        this.exit = true;
        this.mounted = false;
    }
    setDescription = () => {
        var { data } = this.state;
        fetch(`/DOC/GetCausalDescription/${data.FIDCausal}`).then(res => res.json()).then(data => {
            this.setState(state => {
                state.data.Description = data;
                return state;
            });
        });
    }
    getVatData = async(initialData = false) => {
        var { data } = this.state;
        var vatData = await fetch(`/DOC/GetVatData/${data.FIDVat}`).then(res => res.json());
            this.setState(state => {
            state.data.VatPerc = vatData.VatPerc;
			state.data.VatDescription = vatData.VatDescription;
			
			if (initialData) {
				state.initialData.VatPerc = vatData.VatPerc;
				state.initialData.VatDescription = vatData.VatDescription;
			}
			
                return state;
        }/*, () => {
				if (this.state.firstLoad) {
					this.loadData();
				}
		}*/);
        //});
    }
    setDefaultPosition = () => {
        var { data, fields } = this.state;
        fetch(`/mag/position/GetDefaultPosition/${data.FIDOwnerDestination}`).then(res => res.json()).then(data => {
            this.setState(state => {
                if (data > 0) {
                    state.data.FIDOwnerPosition = data;
                }
                else {
                    state.data.FIDOwnerPosition = undefined;
                }
                return state;
            });
            
        });
    }
    setIRDefaultPosition = () => {
        var { data, fields } = this.state;
        var field = getField(fields,"FIDPosition");
        if(!field || !field.Visibility){
            return;
        }

        fetch(`/mag/position/GetDefaultPosition/${data.FIDDestination}`).then(res => res.json()).then(FIDPosition => {
            this.setState(state => {
                if (FIDPosition > 0) {
                    state.data.FIDPosition = FIDPosition;
                }
                else {
                    state.data.FIDPosition = undefined;
                }
				
                return state;
            });
            
        });
    }
    setIdentityDiscount = () => {
        var { data } = this.state;
        var FIDIdentity = data.FIDIdentity;
        if (!FIDIdentity) {
            FIDIdentity = 0;
        }
        fetch(`/DOC/GetSupplierDiscount/${FIDIdentity}`).then(res => res.json()).then(data => {
            this.setState({ IdentityDiscount: data });
        });
    }
    loadData = (callback) => {
        if (this.props.match.params.id && parseInt(this.props.match.params.id, 10) > 0) {
            fetch('/DOC/GetDOC/' + this.props.match.params.id)
                .then(handleErrors)
                .then(res => res.json())
                .then(data => {
                    this.setState(state => {
                        state.data = data;
                        // if (data.FIDCycle === this.props.params.PassiveCycleId) {
                        //     defaultFields[9].Required = 2;
                        // }
                        // else {
                        //     defaultFields[9].Required = 3;
                        // }
                        // if(data.FIDDocumentSubtype === this.props.params.DOCPurchaseSalesOperationSubtypeId){
                        //     if (data.FIDCycle === this.props.params.PassiveCycleId) {
                        //         defaultFields[9].Required = 2;
                        //     }
                        //     else {
                        //         defaultFields[9].Required = 3;
                        //     }
                        // }
						if(!state.data.TransportDatetime)
							state.data.TransportDatetime = null;

                        state.data.FIDPricelist = this.props.params.STDFIDPricelist;
                        if (state.firstLoad) {
                            state.initialData = Object.assign({}, state.data);;
                        }
                        state.firstLoad = false;
                        return state;
                    }, async() => {
						await this.getVatData(true);
                        this.documentHasRows(callback);
                        this.checkIntentDeclaration();
                    });
                })
                .catch(error => { console.log(error) });
        }
        else {
            this.setState(state => {
				state.data.TransportDatetime = null;
                if (state.firstLoad) {
                    state.initialData = JSON.parse(JSON.stringify(state.data));
                }
                state.firstLoad = false;
                return state;
            });
        }
    }
    afterDelete = async() => {
        this.documentHasRows();
        if(this.props.params.DocumentAutosave){
            await this.doSaveData();
        }
    }
    documentHasRows = (callback) =>{
        var FIDDOC = parseInt(this.props.match.params.id, 10);
        if(!FIDDOC){
            FIDDOC = 0;
        }
        fetch(`DOC/HasRows/${FIDDOC}`).then(res => res.json()).then(data =>{
            this.setState({HasRows: data}, callback);
        });
        fetch(`/DOC/GetDOCInfo/${FIDDOC}`).then(res => res.json()).then(data =>{
            console.log(data);
            this.setState({
                rowCount:data.item1,
                qtySum:data.item2,
                taxableAmount:data.item3,
                totalAmount:data.item4,
            });
        });
    }

    clearSession = () => {
        fetch('/DOC/ClearSession', {
            method: 'POST'
        })
            .then(handleErrors).then(res => {
            })
            .catch(error => { console.log(error) });
    }

    checkForChanges = (event, caller, callbackNoChanges, callbackYes, callbackNo, callbackCancel) => {
        fetch('/DOC/HasChanges/', {
            method: 'POST'
        }).then(res => res.json())
            .then(data => {
                if (data.HasChanges || (JSON.stringify(this.state.data) !== JSON.stringify(this.state.initialData))) {
                    this.MessageBox.current && this.MessageBox.current.getWrappedInstance() && this.MessageBox.current.getWrappedInstance().showMessageYesNoCancel("Modifiche non salvate", "Continuando perderai le modifiche effettuate. Vuoi salvare prima di uscire da questa pagina?", callbackYes, callbackNo, callbackCancel);
                }
                else {
                    callbackNoChanges();
                }
            });
    }

    checkForChangesAsync = async (event, caller, callbackNoChanges, callbackYes, callbackNo, callbackCancel) => {
        var data = await fetch('/DOC/HasChanges/', {
            method: 'POST'
        }).then(res => res.json());
        if (data.HasChanges || (JSON.stringify(this.state.data) !== JSON.stringify(this.state.initialData))) {
            this.MessageBox.current && this.MessageBox.current.getWrappedInstance() && this.MessageBox.current.getWrappedInstance().showMessageYesNoCancel("Modifiche non salvate", "Continuando perderai le modifiche effettuate. Vuoi salvare prima di uscire da questa pagina?", callbackYes, callbackNo, callbackCancel);
            return true;
        }
        else {
            callbackNoChanges();
            return false;
        }
    }

    handleExit = (event) => {
        this.checkForChanges(event, this,
            //this.doExit,
            () => {

                this.exit = true;
                this.handleExitChecks(this.doExit)
            },
            () => {

                this.exit = true;
                this.handleSaveData(event, this, () => {
                    //this.doExit();
                    this.handleExitChecks(this.doExit);
                });
            },
            //this.doExit,
            () => {

                this.exit = true;
                this.handleExitChecks(this.doExit)
            },
            () => { }
        );
    }

    handleExitChecks = async (callback) => {
        var check = await this.checkTotals();
        if (check) {
            this.MessageBox.current && this.MessageBox.current.getWrappedInstance() && this.MessageBox.current.getWrappedInstance().showMessageYesNo('Verifica totali', <Fragment><p>Verificare i dati inseriti nella sezione 'Quadratura' nei seguenti campi:</p>{check}<p>Continuare mantenedo i dati non quadrati?</p></Fragment>, callback);
            return;
        }
        else {
            callback();
        }
    }
    handlePageExit = (location, action) => {
        var exitCallback = () => {
            this.justExit = true;
            this.exit = true;
            fetch('/DOC/ClearSession', {
                method: 'POST'
            }).then(res => {
                this.context.router.history.push(location.pathname);
            });
        }
        if(location.pathname === this.props.match.path){
            return true;;
        }
        
        if (!this.justExit) {
            var check = this.checkForChangesAsync(undefined, undefined,
                () => {
                    this.exit = true;
                    this.handleExitChecks(exitCallback)
                },
                () => {
                    this.exit = true;
                    this.handleSaveData(null, this, () => {
                        //this.doExit();
                        this.handleExitChecks(exitCallback);
                    });
                },
                () => {
                    this.exit = true;
                    this.handleExitChecks(exitCallback)
                },
                () => { });
            return false;
        }
        return true;
    }
    doExit = () => {
        this.justExit = true;
        this.exit = true;
        this.mounted = false;
        fetch('/DOC/ClearSession', {
            method: 'POST'
        }).then(res => {
            window.history.back();
            //this.context.router.history.push('/documents/');
            //this.context.router.history.goBack();
        });
    }
    isCompiled = (field) => {
        return (field !== undefined && field !== "" && field !== null)
    }
    checkTotals = async () => {
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }
        var { data } = this.state;
        var comp = [];
        if (data.FIDCycle === this.props.params.PassiveCycleId) {
            var taxableAmount = await fetch(`/DOC/GetCurrentDocTaxableAmount/${FIDDOC}`).then(res => res.json());
            var taxes = await fetch(`/DOC/GetCurrentDocTaxes/${FIDDOC}`).then(res => res.json());
            var totalAmount = await fetch(`/DOC/GetCurrentDocTotalAmount/${FIDDOC}`).then(res => res.json());
            if (this.isCompiled(data.Amount) && taxableAmount !== parseFloat(data.Amount)) {
                comp.push(<p> - Imponibile</p>);
            }
            if (this.isCompiled(data.Taxes) && taxes !== parseFloat(data.Taxes)) {
                comp.push(<p> - Imposte</p>);
            }
            if (this.isCompiled(data.TotalAmount) && totalAmount !== parseFloat(data.TotalAmount)) {
                comp.push(<p> - Totale</p>);
            }
        }
        if (comp.length > 0) {
            return <Fragment>{comp}</Fragment>;
        }
        return false;
    }
    handleSaveData = async (event, caller, callback) => {
        //verifico totali
        //var check = await this.checkTotals();
        //if (check) {
        //    this.MessageBox.current.showMessageOk('Verifica totali', <Fragment><p>Verificare i dati inseriti nella sezione 'Quadratura' nei campi:</p>{check}</Fragment>);
        //    return;
        //}
        if (this.validateTabGeneral(async() => {
            await this.doSaveData(callback);
        })) {
            await this.doSaveData(callback);
        }
        
    }
    doSaveData = async(callback) => {
        var { editRow, addRows} = this.state;
        if(editRow || addRows){
            if(editRow){
                await this.onSaveEditRow(async() =>{
                    await this.doSaveData(callback);
                });
            }
            else if (addRows){
                this.onSaveRow(async() => {
                    await this.doSaveData(callback);
                })
            }
            return;
        }
        var currentTab = this.tabs.current.getCurrentTab();
        this.setState({ isLoading: true, isSaving: true }, async() => {
            var { data } = this.state;
            var FIDDOC = this.props.match.params.id;
            if (!FIDDOC) {
                FIDDOC = 0;

                this.interval = setInterval(this.doCheckSaveDocument, 250);
                await fetch('/DOC/SaveDOC/' + FIDDOC, {
                    body: JSON.stringify(data),
                    method: 'POST'
                }).then(handleErrors).then(res => res.json()).then((data) => {
                    if (this.interval)
                        window.clearInterval(this.interval);
                    if (this.props.match.params.id === undefined || parseInt(this.props.match.params.id, 10) === 0) {
                        if (!this.exit) {
                            this.justExit = true;
                            this.context.router.history.replace('/doc/edit/' + data.ID);
                        }
                        if (callback) {
                            callback();
                        }
                    }
                    this.setState({ firstLoad: true }, () => {
                        this.loadData();
                        this.setState({ isLoading: false, isSaving: false, initialData: Object.assign({}, this.state.data) }, () => {
                            this.tabs.current.goToTab(currentTab);
                            if (callback) {
                                callback();
                            }
                        });
                    });
                })
                .catch(error => {
                    if (this.interval)
                        window.clearInterval(this.interval);
                    console.log(error);
                    this.setState({ isLoading: false }, () => {
                        this.tabs.current.goToTab(currentTab);
                    });
                });
            } else {
                fetch('/DOC/SaveDOC/' + FIDDOC, {
                    body: JSON.stringify(data),
                    method: 'POST'
                }).then(handleErrors).then(res => res.json()).then((data) => {
                    if (this.props.match.params.id === undefined || parseInt(this.props.match.params.id, 10) === 0) {
                        if (!this.exit) {
                            this.justExit = true;
                            this.context.router.history.replace('/doc/edit/' + data.ID);
                        }
                        if (callback) {
                            callback();
                        }
                    }
                    this.setState({ firstLoad: true }, () => {
                        this.loadData();
                        this.setState({ isLoading: false, isSaving: false, initialData: Object.assign({}, this.state.data) }, () => {
                            this.tabs.current.goToTab(currentTab);
                            if (callback) {
                                callback();
                            }
                        });
                    });

                })
                    .catch(error => {
                        console.log(error);
                        this.setState({ isLoading: false }, () => {
                            this.tabs.current.goToTab(currentTab);
                        });
                    });
            }
        });
    }

    handleDataChange = (event, value, key, dt, callback) => {
        this.setState(state => {
			if (["Number"].includes(key) && !state.data.Number)
				state.preventTabChange = true;
            state.data[key] = value;
            state.data = this.relationsFix(dt, state.data, key);
            if(value){
                if (["FIDCausal"].includes(key) /*&& !state.data.Description*/) {
                    state.data.Description = dt.Denomination;
                }
                if (["FIDCurrency"].includes(key)) {
                    state.data.CurrencyChange = dt.Change;
                }
                if (["FIDVat"].includes(key)) {
                    state.data.VatPerc = dt.Percentage;
                }
                if (["FIDIdentity"].includes(key)) {
                    if(value){
                        state.data.FIDDestination = dt.FIDSpedition;
                        if(dt.FIDVat){
                            state.data.FIDVat = dt.FIDVat;
                            
                        }
                        else {
                            state.data.FIDVat = this.props.params.STDFIDVat;    
                        }
                        if(dt.FIDPricelist) {
                            state.data.FIDPricelist = dt.FIDPricelist;
                        }
                        else {
                            state.data.FIDPricelist = this.props.params.STDFIDPricelist;
                        }
                        if(dt.DiscountDesc) {
                            state.data.DiscountDesc = dt.DiscountDesc;
                        }
                        else {
                            state.data.DiscountDesc = "";
                        }
                        if(dt.FIDPaymentMethod){
                            state.data.FIDPaymentMethod = dt.FIDPaymentMethod
                        }
                        
                        state.docIRFilter = 0;
                    }
                    else {
                        state.docIRFilter = 1;
                        state.data.FIDDestination = undefined;
                        state.data.FIDVat = this.props.params.STDFIDVat;
                        state.data.FIDPricelist = this.props.params.STDFIDPricelist;
                        state.data.DiscountDesc = "";
                    }

                }
                if(["FIDOwner"].includes(key)){
                    state.data.FIDOwnerDestination = value;
                    var isInternalDoc = state.data.FIDDocumentType === this.props.params.DOCInternalTypeId;
                    if(isInternalDoc) {
                        state.data.FIDIdentity = value;
                        state.data.FIDDestination = value;
                    }
                }
                if(["FIDOwnerDestination"].includes(key)){
                    var isInternalDoc = state.data.FIDDocumentType === this.props.params.DOCInternalTypeId;
                    if(isInternalDoc) {
                        state.data.FIDDestination = value;
                    }
                }
				if (key === "FIDMarkup") {
					if (value) {
						state.data.MarkupMultiplier = dt.Multiplier;
					} else {
						state.data.MarkupMultiplier = undefined;
            }
				}
            }
            return state;
        }, async() => {
            callback && callback();
            if (key === "FIDOwner") {
				if (this.state.data.FIDIdentity && this.state.data.FIDOwner) {
					fetch('/IR/GetIRData?ID='+this.state.data.FIDIdentity+'&FIDLicensee='+this.state.data.FIDOwner)
						.then(res => res.json())
						.then(res => {
							this.handleDataChange(event, this.state.data.FIDIdentity, "FIDIdentity", res);
						})
				}
			}
            if(["FIDIdentity","FIDDestination","FIDCausal"].includes(key)){
                this.setDocTitleTitle();
            }
            if(["FIDIdentity","FIDVat"].includes(key)){
                await this.getVatData()
            }
            if(["FIDIdentity"].includes(key)){
                this.checkIntentDeclaration();
            }
        });
    }
    checkIntentDeclaration = async () => {
        var { data } = this.state;
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }

        if(data.FIDIdentity && data.FIDCycle === 2 && data.FIDDocumentType === 3 && data.FIDDocumentSubtype === 5){
            var hasIntentDeclaration = await fetch(`/DOC/HasIntentDeclaration/${FIDDOC}?FIDIdentity=${data.FIDIdentity}`).then(res => res.json());
            this.setState({hasIntentDeclaration});
        }
        else {
            this.setState({ hasIntentDeclaration: false });
        }
    }
    relationsFix = (data, db, key) => {
        if (["FIDCausal", "FIDDocumentSubtype"].includes(key) && data && data.ID > 0) {
            db["FIDDocumentType"] = data.FIDDocumentType;
        }
        if (["FIDCausal"].includes(key) && data && data.ID > 0) {
            db["FIDDocumentSubtype"] = data.FIDDocumentSubtype;
        }
        if (["FIDDocumentType", "FIDDocumentSubtype"].includes(key) && db["FIDCausal"] >= 0) {
            db["FIDCausal"] = undefined;
        }
        if (["FIDDocumentType"].includes(key) && db["FIDDocumentSubtype"] >= 0) {
            db["FIDDocumentSubtype"] = undefined;
        }
        return db;
    }
    handleSelectChange = (key) => (event, value) => {
        var callback = () => {
            if (key === "FIDOwnerDestination") {
                this.setDefaultPosition();
            }
            if (key === "FIDOwner") {
                this.setDefaultPosition();
                this.setIRDefaultPosition();
            }
            if (key === "FIDIdentity") {
                this.setIdentityDiscount();
                this.setIRDefaultPosition();
            }
            if (key === "FIDDestination") {
                this.setIRDefaultPosition();
            }
            if(key === "FIDCausal"){
                var {data} = this.state;
                var isInternalDoc = data.FIDDocumentType === this.props.params.DOCInternalTypeId;
                this.resetFields(data.FIDCycle,isInternalDoc,data.FIDDocumentType,data.FIDDocumentSubtype,data.FIDCausal);
            }
        }
		if (key === "CalculationType") {
			this.setCalculationType((value.length > 0 ? value[0].ID : undefined));
        }
        else if (key === "PriceType") {
            this.setPriceType((value.length > 0 ? value[0].ID : 1));
        }
        if (value.length > 0) {
            this.handleDataChange(event, value[0].ID, key, value[0], callback);
        }
        else {
            this.handleDataChange(event, undefined, key, undefined, callback);
        }
        
    }
    handleFieldChange = (key) => (event, value) => {
        this.handleDataChange(event, value, key);
    }
    handleDataFieldChange = (key) => (value) => {
        this.handleDataChange(null, value, key);
    }

    handleImportFileChange = event => {
        this.setState({
            selectedImportFile: event.target.files[0],
            importFileLoaded: 0,
        })
    }
    handleImportFileCancel = () => {
        this.setState({
            showImportFileUpload: false,
            selectedImportFile: false,
            importFileLoaded: 0
        })
    }
    handleImportFileUpload = async() => {
        const data = new FormData()
        data.append('file', this.state.selectedImportFile);

        var docData = this.state.data;

        var docId = 0;
        if (docData.ID)
            docId = docData.ID;

        var urlPost = '/DOC/ImportRows/' + docId;

        var queryString = "";

        if (docData.FIDIdentity) {
            if (queryString.length === 0)
                queryString = '?FIDSupplier=' + docData.FIDIdentity;
            else
                queryString += '&FIDSupplier=' + docData.FIDIdentity;
        }
        if (docData.FIDOwner) {
            if (queryString.length === 0)
                queryString = '?FIDOwner=' + docData.FIDOwner;
            else
                queryString += '&FIDOwner=' + docData.FIDOwner;
        }
        if (docData.FIDOwnerDestination) {
            if (queryString.length === 0)
                queryString = '?FIDIdentityPos=' + docData.FIDOwnerDestination;
            else
                queryString += '&FIDIdentityPos=' + docData.FIDOwnerDestination;
        }
        if (docData.FIDOwnerPosition) {
            if (queryString.length === 0)
                queryString = '?FIDPosition=' + docData.FIDOwnerPosition;
            else
                queryString += '&FIDPosition=' + docData.FIDOwnerPosition;
        }
        if (docData.FIDCausal) {
            if (queryString.length === 0)
                queryString = '?FIDCausal=' + docData.FIDCausal;
            else
                queryString += '&FIDCausal=' + docData.FIDCausal;
        }
        if (docData.FIDCycle) {
            if (queryString.length === 0)
                queryString = '?FIDCycle=' + docData.FIDCycle;
            else
                queryString += '&FIDCycle=' + docData.FIDCycle;
        }
        if (docData.FIDVat) {
            if (queryString.length === 0)
                queryString = '?FIDVat=' + docData.FIDVat;
            else
                queryString += '&FIDVat=' + docData.FIDVat;
        } else if (this.props.params.STDFIDVat)
        {
            if (queryString.length === 0)
                queryString = '?FIDPricelist=' + this.props.params.STDFIDVat;
            else
                queryString += '&FIDPricelist=' + this.props.params.STDFIDVat;
        }
        if (docData.FIDPricelist) {
            if (queryString.length === 0)
                queryString = '?FIDPricelist=' + docData.FIDPricelist;
            else
                queryString += '&FIDPricelist=' + docData.FIDPricelist;
        } else if (this.props.params.DEF_PRICELIST)
        {
            if (queryString.length === 0)
                queryString = '?FIDPricelist=' + this.props.params.DEF_PRICELIST;
            else
                queryString += '&FIDPricelist=' + this.props.params.DEF_PRICELIST;
        }

		if (docData.CalculationType) {
			if (queryString.length === 0)
                queryString = '?CalculationType=' + docData.CalculationType;
            else
                queryString += '&CalculationType=' + docData.CalculationType;
		}
		
		if (docData.PricelistDiscountDesc) {
			if (queryString.length === 0)
                queryString = '?PricelistDiscount=' + docData.PricelistDiscountDesc;
            else
                queryString += '&PricelistDiscount=' + docData.PricelistDiscountDesc;
		}
		
		if (docData.DiscountDesc) {
			if (queryString.length === 0)
                queryString = '?Discount=' + docData.DiscountDesc;
            else
                queryString += '&Discount=' + docData.DiscountDesc;
		}
		
		if (docData.ExtraDiscountDesc) {
			if (queryString.length === 0)
                queryString = '?ExtraDiscount=' + docData.ExtraDiscountDesc;
            else
                queryString += '&ExtraDiscount=' + docData.ExtraDiscountDesc;
		}

        this.setState({ isImportingRows: true, progress: 0, total: 100, stopImport: false }, async () => {
            this.interval = setInterval(this.doCheckImportRows, 250);
            await axios
                .post(urlPost+queryString, data, {
                    onUploadProgress: ProgressEvent => {
                        this.setState({
                            importFileLoaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
                        })
                    },
                })
                .then(res => {
                    if (this.interval)
                        window.clearInterval(this.interval);

                    this.setState({ showImportFileUpload: false,
                        selectedImportFile: false,
                        importFileLoaded: 0, viewRows: "ROWS", isImportingRows: false, progress: 0, total: 100 }, () => {
                        this.grdDOCRows.current.getWrappedInstance().refresh();
                    });
                    console.log(res.statusText)
                });

            /*if (this.interval)
                window.clearInterval(this.interval);

            this.setState({ showImportFileUpload: false,
                selectedImportFile: false,
                importFileLoaded: 0, viewRows: "ROWS", isImportingRows: false, progress: 0, total: 100 }, () => {
                this.grdDOCRows.current.getWrappedInstance().refresh();
            });*/
        });
    }

    doCheckImportRows = () => {
        fetch('/DOC/CheckImportRowsProgress').then(res => res.json()).then(data => { this.setState({ progress: data.progress, total: data.count, stopImport: data.stopImporting }) })
    }
    doCheckSaveDocument = () => {
        fetch('/DOC/CheckSaveDocumentProgress').then(res => res.json()).then(data => {
            this.setState({
                progress: data.progress,
                total: data.total,
            });
        })
    }

    renderPanelGeneral = () => {
        var { params } = this.props;
        var { data, fields, HasRows } = this.state;

        var isInternalDoc = data.FIDDocumentType === params.DOCInternalTypeId;
        var identityChangeAllowed = params.DOCInternalAllowIdentityChange;
        var isMovement = data.FIDCausal === 8;
        var FIDRegister = undefined;
        if(!isInternalDoc) {
            switch (data.FIDCycle) {
                case 1:
                    FIDRegister = 3;
                    break;
                case 2:
                    FIDRegister = 1;
                    break;
                case 3:
                    FIDRegister = 2;
                    break;
                default:
                    break;
            }
        }
        var filterCausalOr = undefined;
		var filterCausalExclude = [];
        
		if (data.FIDDocumentSubtype === 4) {
			if (data.FIDIdentity != data.FIDOwner) {
				filterCausalExclude = [
					{
						key: 'ID',
						value: 40
					}
				];
			}
		}
        
        if(data.FIDDocumentType === 4) {
            FIDRegister = 3;
            if(data.FIDCycle === 2){//attivo - consegna
                filterCausalOr = [
                    {
                        key: 'ID',
                        value: 35
                    },
                    {
                        key: 'ID',
                        value: 36
                    }
                ];
            }
            else if(data.FIDCycle === 3){//passivo - ricezione
                filterCausalOr = [
                    {
                        key: 'ID',
                        value: 32
                    },
                    {
                        key: 'ID',
                        value: 33
                    },
                    {
                        key: 'ID',
                        value: 34
                    }
                ];
            }
        }
        if(data.FIDDocumentSubtype === 7){//cassa
            FIDRegister = 1;
        }
        // if(data.FIDCausal === 20){//carico

        //     filterCausalOr = [
        //         {
        //             key: 'ID',
        //             value: 20
        //         },
        //     ];
        // } else if (data.FIDCausal == 21) {//scarico
        //     filterCausalOr = [
        //         {
        //             key: 'ID',
        //             value: 21
        //         },
        //     ];
        // }else if (data.FIDCausal == 8) {//spostamento
        //     filterCausalOr = [
        //         {
        //             key: 'ID',
        //             value: 8
        //         },
        //     ];
        // }else if (data.FIDCausal == 6) {//inventario
        //     filterCausalOr = [
        //         {
        //             key: 'ID',
        //             value: 6
        //         },
        //     ];
        // }
        // else if (data.FIDCausal == 38) {//reso cassa
        //     filterCausalOr = [
        //         {
        //             key: 'ID',
        //             value: 38
        //         },
        //     ];
        // }else if (data.FIDCausal == 16) {//cassa
        //     filterCausalOr = [
        //         {
        //             key: 'ID',
        //             value: 16
        //         },
        //     ];
        // }

        var lblFIDOwner = "Intestatario documento";
        var lblFIDOwnerDestination = "Sede intestatario";
        var lblFIDOwnerPosition = "Magazino intestatario";
        var lblFIDIdentity = "Anagrafica";
        var lblFIDDestination ="Sede";
        var lblFIDIRRelation ="Relazione";
        var lblFIDPosition = "Magazzino";
        

        var destinationFilter = data.ShowAllDestinations ? { subtitleKey: "Address", clearable: !HasRows } : { filters: [{ key: "FIDIdentity", value: data.FIDIdentity }], subtitleKey: "Address", disabled: HasRows || (isInternalDoc && !isMovement && !identityChangeAllowed), clearable: !HasRows };
        var relationFilter ={ filters: [{ key: "FIDIdentity", value: data.FIDIdentity }], subtitleKey: "Address", disabled: HasRows || (isInternalDoc && !isMovement && !identityChangeAllowed), clearable: !HasRows };
        //if (data.FIDCycle === params.PassiveCycleId) {
            //destinationFilter = { filters: [{ key: "FIDIdentity", value: params.FIDLicensee }] };
        //}

        var licenseeDestinationFilter = { filters: [{ key: "FIDIdentity", value: data.FIDOwner }], subtitleKey: "Address", disabled: HasRows };
        //if (data.FIDCycle === params.PassiveCycleId) {
            //licenseeDestinationFilter = { filters: [{ key: "FIDIdentity", value: params.FIDLicensee }] };
        //}
        var positionFilter = { filters: [{ key: "FIDIdentity", value: data.FIDOwnerDestination }], disabled: HasRows };
        var positionIRFilter = { filters: [{ key: "FIDIdentity", value: data.FIDDestination }], disabled: HasRows };

        var fieldCycle = getXSelect(data, fields, 12, 6, "FIDCycle", "Ciclo", this.handleSelectChange, "/doc/cycle","",{ disabled: HasRows});
        var fieldDocumentType = getXSelect(data, fields, 12, 6, "FIDDocumentType", "Tipo", this.handleSelectChange, "/doc/documenttype", "", { filters: [{ key: "FIDCycles", value: data.FIDCycle, type: 'array' }],disabled: HasRows });
        var fieldDocumentSubtype = getXSelect(data, fields, 12, 6, "FIDDocumentSubtype", "Sottotipo", this.handleSelectChange, "/doc/documentsubtype", "", { filters: [{ key: "FIDDocumentType", value: data.FIDDocumentType }, { key: "FIDCycles", value: data.FIDCycle, type: 'array' }],disabled: HasRows });
        var fieldCausal = getXSelect(data, fields, 12, 3, "FIDCausal", "Causale", this.handleSelectChange, "/doc/causal", "", { filters: [{ key: "FIDDocumentType", value: data.FIDDocumentType }, { key: "FIDDocumentSubtype", value: data.FIDDocumentSubtype }, { key: "FIDCycles", value: data.FIDCycle, type: 'array' }],disabled: HasRows, filtersOr: filterCausalOr, filtersExclude: filterCausalExclude });
        var fieldDescription = getXField(data, fields, 12, 3, "Description", "Descrizione", this.handleFieldChange,{disabled: HasRows});
        var fieldRegister = getXSelect(data, fields, 12, 6, "FIDRegister", "Registro", this.handleSelectChange, "/doc/register", "", { filters: [{ key: "ID", value: FIDRegister }],disabled: HasRows });
        var fieldNumber = getXField(data, fields, 12, 2, "Number", "Numero", this.handleFieldChange);
        var fieldDate = getXDateField(data, fields, 12, 2, "DocumentDate", "Data documento", this.handleDataFieldChange);
        var fieldRegisterDate = getXDateField(data, fields, 12, 2, "RegisterDate", "Data registrazione", this.handleDataFieldChange);
        var fieldIdentity = getXSelect(data, fields, 12, 4, "FIDIdentity", lblFIDIdentity, this.handleSelectChange, `/IR/GetIRSelect/?FIDLicensee=${data.FIDOwner}`, "", { innerRef: this.cboIdentity, subtitleKey: "Address",disabled: HasRows || (isInternalDoc && !identityChangeAllowed), clearable: !HasRows });
        var fieldDestination = getXSelect(data, fields, 12, 3, "FIDDestination", lblFIDDestination, this.handleSelectChange, `/IR/GetIRDestinationSelect/`, "", destinationFilter);
        var fieldRelation = getXSelect(data, fields, 12, 3, "FIDIRRelation", lblFIDIRRelation, this.handleSelectChange, `/IR/GetIRRelationSelect/`, "", relationFilter);
        var fieldShowDestinations = getXSwitch(data, fields, 12, 2, "ShowAllDestinations", "Mostra tutte le destinazioni", this.handleFieldChange);
        var fieldPayments = getXSelect(data, fields, 12, 3, "FIDPaymentMethod", "Metodo di pagamento", this.handleSelectChange, "/DOC/paymentmethod");
        var fieldBank = getXSelect(data, fields, 12, 3, "FIDBank", "Banca", this.handleSelectChange, "/IR/GetBanksSelect");

        var fieldOwner = getXSelect(data, fields, 12, 4, "FIDOwner", lblFIDOwner, this.handleSelectChange, "/IR/GetIRLicenseesSelect", "", { subtitleKey: "Address", disabled: HasRows });
        var fieldOwnerDestination = getXSelect(data, fields, 12, 4, "FIDOwnerDestination", lblFIDOwnerDestination, this.handleSelectChange, "/IR/GetIRLicenseeDestinationsSelect", "", licenseeDestinationFilter);
        var fieldOwnerPosition = getXSelect(data, fields, 12, 4, "FIDOwnerPosition", lblFIDOwnerPosition, this.handleSelectChange, "/mag/position/GetSelect","", positionFilter);
        
        var fieldPosition = getXSelect(data, fields, 12, 4, "FIDPosition", lblFIDPosition, this.handleSelectChange, "/mag/position/GetSelect","", positionIRFilter);
        
        return (
            <Grid container spacing={2}>
                {/* {fieldDocumentType} */}
                {/* {fieldDocumentSubtype} */}
                {/* {fieldRegister} */}
                {/* {fieldCycle} */}
                {fieldOwner}
                {fieldOwnerDestination}
                {fieldOwnerPosition}

                {fieldIdentity}
                {fieldDestination}
                {fieldShowDestinations}
                {fieldRelation}
                {fieldPosition}
                {(!fieldShowDestinations && !fieldPosition) && <Grid item xs={12} md={2}></Grid>}

                {fieldCausal}
                {fieldDescription}
                
                {fieldNumber}
                {fieldDate}
                {fieldRegisterDate}

                {fieldRegister}
                {fieldPayments}
                {fieldBank}
                
                
            </Grid>
        );
    }

    renderPanelNotes = () => {
        var { fields, data } = this.state;

        var fieldInternalRemark = getXField(data, fields, 12, 6, "InternalRemarks", "Nota interna", this.handleFieldChange, { multiline: true });
        var fieldPublicRemark = getXField(data, fields, 12, 6, "PublicRemarks", "Nota pubblica", this.handleFieldChange, { multiline: true });
        return (
            <Grid container spacing={2}>
                {fieldInternalRemark}
                {fieldPublicRemark}
            </Grid>
        );
    }
    
    renderPanelChecks = () => {
        var { fields, data } = this.state;

        var fieldAmount = getXField(data, fields, 12, 3, "Amount", "Imponibile", this.handleFieldChange, { type: "number",decimalScale: 2 });
        var fieldTaxes = getXField(data, fields, 12, 3, "Taxes", "Imposta", this.handleFieldChange, { type: "number",decimalScale: 2 });
        var fieldTotalAmount = getXField(data, fields, 12, 3, "TotalAmount", "Totale", this.handleFieldChange, { type: "number",decimalScale: 2 });
        if(fieldAmount || fieldTaxes || fieldTotalAmount) {
            return (
                <Grid container spacing={2}>
                    {fieldAmount}
                    {fieldTaxes}
                    {fieldTotalAmount}
                </Grid>
            );
        }
    }
    renderTabGeneral = () => {
        var panels = [];
        panels.push({
            icon: <ClipboardTextPlayOutline />,
            label: "Dati generali",
            content: this.renderPanelGeneral(),
        });
        panels.push({
            icon: <ClipboardTextPlayOutline />,
            label: "Dati predefiniti",
            content: this.renderPanelDefaults(),
        });
        panels.push({
            icon: <ClipboardTextOutline />,
            label: "Note",
            content: this.renderPanelNotes(),
        });
        panels.push({
            icon: <Database />,
            label: "Dati quadratura",
            content: this.renderPanelChecks(),
        });

        var ui = <XExpansionPanel panels={panels} />;
        return (
            <TabContainer>
                {ui}
            </TabContainer>
        );
    }
	
	onParamLockChange = (id) => (value) => {
        this.setState(state => {
            state.paramsLocks[`${id}Lock`] = value;
			state.data[id] = undefined;
            return state;
        });
    }
	
    renderTabRowsPanelDefaults = () => {
        var { fields, data, paramsLocks } = this.state;

		var fieldCalculationType = getXSelect(data, fields, 12, 4, "CalculationType", "Tipo calcolo costo/prezzo", this.handleSelectChange, "/enums/boppricecalculationtype");
        var fieldPriceType = getXSelect(data, fields, 12, 4, "PriceType", "Tipo prezzo", this.handleSelectChange, "/enums/docpricetype");
		var fieldPricelistDiscountDesc = getXField(data, fields, 12, 4, "PricelistDiscountDesc", "Sconto % listino", this.handleFieldChange, {lockable: true, isLocked: paramsLocks.PricelistDiscountDescLock, readOnly: paramsLocks.PricelistDiscountDescLock, onLockChange: this.onParamLockChange});
		var fieldMarkup = getXSelect(data, fields, 12, 4, "FIDMarkup", "Formula di ricarico", this.handleSelectChange, "/gc/markup" );
        var fieldRechargePercentage = getXField(data, fields, 12, 4, "RechargePercentage", "Percentuale ricarico", this.handleFieldChange, {lockable: true, isLocked: paramsLocks.RechargePercentageLock, readOnly: paramsLocks.RechargePercentageLock, onLockChange: this.onParamLockChange});
        var fieldPricelist = getXSelect(data, fields, 12, 4, "FIDPricelist", "Listino", this.handleSelectChange, "/gc/pricelist");
        var fieldVat = getXSelect(data, fields, 12, 4, "FIDVat", "IVA", this.handleSelectChange, "/gc/vat", "", { innerRef: this.cboVat});
        var fieldDiscountDesc = getXField(data, fields, 12, 4, "DiscountDesc", "Sconto % riga", this.handleFieldChange, {lockable: true, isLocked: paramsLocks.DiscountDescLock, readOnly: paramsLocks.DiscountDescLock, onLockChange: this.onParamLockChange});
		var fieldExtraDiscountDesc = getXField(data, fields, 12, 4, "ExtraDiscountDesc", "Extra Sconto % riga", this.handleFieldChange, {lockable: true, isLocked: paramsLocks.ExtraDiscountDescLock, readOnly: paramsLocks.ExtraDiscountDescLock, onLockChange: this.onParamLockChange} );
        
		if (data.FIDCycle === (this.props.params.ActiveCycleId||2)) {
			fieldCalculationType = getXSelect(data, fields, 12, 4, "CalculationType", "Tipo calcolo prezzo", this.handleSelectChange, "/enums/boppricecalculationtype", "", { filtersExclude: [ { key: "ID", value: 1 }, { key: "ID", value: 2 } ] } );
		}
        
        return (
            <Grid container spacing={2}>
				{data.FIDCycle === (this.props.params.ActiveCycleId||2) && fieldPriceType}
                {fieldPricelist}
				{fieldCalculationType}
				{data.FIDCycle === (this.props.params.PassiveCycleId||3) && fieldPricelistDiscountDesc}
                {fieldRechargePercentage}
				{fieldMarkup}
                {fieldVat}
                {fieldDiscountDesc}
				{fieldExtraDiscountDesc}
            </Grid>
        );
    }
    renderTabRowsImport = () => {
        return (
            <Fragment>
                <Grid container spacing={2}>
                    <input type="file" name="" id="" onChange={this.handleImportFileChange} style={{paddingTop:"15px"}}/>
                    <XButton variant="container" onClick={this.handleImportFileUpload} disabled={!this.state.selectedImportFile}>Upload</XButton>
                    <div>{this.state.importFileLoaded > 0 && this.state.importFileLoaded < 100 && "Caricamento..."}</div>
                </Grid>
                <XProgress progress={this.state.progress} total={this.state.total} label="Importazione righe documento" show={this.state.isImportingRows} />
            </Fragment>
        )
    }
    renderPanelDefaults = () => {
        var { fields, data } = this.state;


        var fieldCurrency = getXSelect(data, fields, 12, 3, "FIDCurrency", "Valuta", this.handleSelectChange, "/gc/currency");
        var fieldCurrencyChange = getXField(data, fields, 12, 3, "CurrencyChange", "Cambio", this.handleFieldChange, { type: "number"});
        var fieldVat = getXSelect(data, fields, 12, 3, "FIDVat", "IVA", this.handleSelectChange, "/gc/vat", "", { innerRef: this.cboVat});
        // var fieldDiscountDesc = getXField(data, fields, 12, 3, "DiscountDesc", "Sconto", this.handleFieldChange);
        return (
            <Grid container spacing={2}>
                {fieldCurrency}
                {fieldCurrencyChange}
                {fieldVat}
                {/* {fieldDiscountDesc} */}
            </Grid>
        );
    }
    renderTabDefaults = () => {
        var panels = [];
        panels.push({
            icon: "code",
            label: "Dati predefiniti",
            content: this.renderPanelDefaults(),
        });

        var ui = <XExpansionPanel panels={panels} />;
        return (
            <TabContainer>
                {ui}
            </TabContainer>
        );
    }
    handleNewRow = () => {

        this.setState({ selectedRows: [], addNewRow: true, rowData: {}, selectedData: {} });
    }
    editRows = () => {
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }

		if (!this.checkParams()) {
			this.MessageBox.current && this.MessageBox.current.getWrappedInstance().showMessageOk("PARAMETRI INCOMPLETI", "Compilare i parametri obbligatori per proseguire con l'operazione");
			return;
		}
	
        var selectedRows = this.grdDOCBops.current.getWrappedInstance().getSelectedData();
        if (selectedRows.length === 1) {
            this.grdDOCBops.current.getWrappedInstance().clearSelection();
            this.getRowData(selectedRows[0]).then(async (data) => {
				var dataRow = Object.assign({}, data);
				if (data.ID) {
					dataRow.FIDBOP = data.ID;
				}
				
				dataRow = await this.applyDocParams(dataRow, this.state.data);
				this.setState({ selectedRows, addRows: true, selectedData: dataRow });
            });
            
        }
    }
    
    editRowsDblClk = (event,data) => {
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }
		
		if (!this.checkParams()) {
			this.MessageBox.current && this.MessageBox.current.getWrappedInstance().showMessageOk("PARAMETRI INCOMPLETI", "Compilare i parametri obbligatori per proseguire con l'operazione");
			return;
		}

        this.getRowData(data.ID).then(async (data) => {
			var dataRow = Object.assign({}, data);
			if (data.ID) {
				dataRow.FIDBOP = data.ID;
			}

			dataRow = await this.applyDocParams(dataRow, this.state.data);
			this.setState({ addRows: true, selectedData: dataRow });
        });
    }
    editRowsFromDoc = async() => {
        var FIDDOC = this.props.match.params.id;
		var docData = this.state.data;
        if (!FIDDOC) {
            FIDDOC = 0;
        }

		if (!docData) {
			docData = {
				FIDPricelist: this.props.params.DEF_PRICELIST,
				DiscountDesc: ""
			}
		}
		var isExternalRow = true;
        var selectedRows = this.grdDOCProcessRows.current.getWrappedInstance().getSelectedData();
		
		if (!this.checkParams()) {
			this.MessageBox.current && this.MessageBox.current.getWrappedInstance().showMessageOk("PARAMETRI INCOMPLETI", "Compilare i parametri obbligatori per proseguire con l'operazione");
			return;
		}
		
        if (selectedRows.length === 1) {
            var row = Object.assign({},this.grdDOCProcessRows.current.getWrappedInstance().getRowData(selectedRows[0]));

			if (docData.DiscountDesc) {
				row.DiscountDesc = docData.DiscountDesc;
			} else if (docData.FIDCycle === (this.props.params.PassiveCycleId||3) 
				&& docData.FIDDocumentType === (this.props.params.DOCPreliminaryTypeId||2)) {			
				// SE E' UN ORDINE/PREVENTIVO A FORNITORE, SBIANCO LO SCONTO DELL'ORDINE CLIENTE
				row.DiscountDesc = "";
			}
			row.Discount = getDiscount(row.DiscountDesc);
			if (docData.FIDVat) {
				row.FIDVat = docData.FIDVat;
				row.VatPerc = docData.VatPerc;
			}
			
			if (!this.checkParams()) {
				this.MessageBox.current && this.MessageBox.current.getWrappedInstance().showMessageOk("PARAMETRI INCOMPLETI", "Compilare i parametri obbligatori per proseguire con l'operazione");
				return;
			}
			
			if (row.FIDBOP) {
				this.getRowData(row.FIDBOP).then(async(data) => {
					row.UnitCost = data.SupplierCost||0;
				
					if (docData.FIDCycle === (this.props.params.PassiveCycleId||3) 
						&& docData.FIDDocumentType === (this.props.params.DOCPreliminaryTypeId||2)) {	
						isExternalRow = false;
						row.TaxablePrice = row.UnitCost;
						row.RetailDiscountDesc = data.SupplierDiscountDesc;
						row.RetailDiscount = data.SupplierDiscount;
						row.CalculationType = data.CalculationType;
						row.FIDMarkup = data.FIDMarkup;
						row.RetailDiscountDesc = data.SupplierDiscountDesc;
						row.FIDVatPricelist = data.FIDVatPricelist;
						row.VatPercPricelist = data.VatPercPricelist;
						row.DiscountDesc = "";
						row.ExtraDiscountDesc = "";
						row.Discount = 0;
					}
					
					if (!row.CalculationType) {
						row.CalculationType = data.CalculationType;
						row.FIDMarkup = data.FIDMarkup;
						row.RetailDiscountDesc = data.SupplierDiscountDesc;
						row.FIDVatPricelist = data.FIDVatPricelist;
						row.VatPercPricelist = data.VatPercPricelist;
					}
					
					row = await this.applyDocParams(row, docData, isExternalRow);
					
					this.grdDOCProcessRows.current.getWrappedInstance().clearSelection();
					this.setState(state => { 
						
						state.selectedRows = selectedRows;
						state.addRows = true;
						state.selectedData = row;						
					
						return state;
					});
				});
			} else {
				this.grdDOCProcessRows.current.getWrappedInstance().clearSelection();
				this.setState(state => { 
					
					state.selectedRows = selectedRows;
					state.addRows = true;
					state.selectedData = row;						
					return state;
				});
			}
		}
    }
    editRowsFromDocDblClk = async (event,data) => {
        var FIDDOC = this.props.match.params.id;
        var docData = this.state.data;
        if (!FIDDOC) {
            FIDDOC = 0;
        }

		if (!docData) {
			docData = {
				FIDPricelist: this.props.params.DEF_PRICELIST,
				DiscountDesc: ""
			}
		}

		var dataRow = Object.assign({}, data);
		var isExternalRow = true;
		if (docData.DiscountDesc) {
			dataRow.DiscountDesc = docData.DiscountDesc;
		} else if (docData.FIDCycle === (this.props.params.PassiveCycleId||3) 
			&& docData.FIDDocumentType === (this.props.params.DOCPreliminaryTypeId||2)) {		
			// SE E' UN ORDINE/PREVENTIVO A FORNITORE, SBIANCO LO SCONTO DELL'ORDINE CLIENTE
			dataRow.DiscountDesc = "";
		}
		dataRow.Discount = getDiscount(data.DiscountDesc);
		
		if (docData.FIDVat) {
			dataRow.FIDVat = docData.FIDVat
			dataRow.VatPerc = docData.VatPerc;
		}
		
		if (!this.checkParams()) {
			this.MessageBox.current && this.MessageBox.current.getWrappedInstance().showMessageOk("PARAMETRI INCOMPLETI", "Compilare i parametri obbligatori per proseguire con l'operazione");
			return;
		}
		
		if (data.FIDBOP) {
			this.getRowData(data.FIDBOP).then(async bopData => {
				dataRow.UnitCost = bopData.SupplierCost||0;
				
				if (docData.FIDCycle === (this.props.params.PassiveCycleId||3) 
					&& docData.FIDDocumentType === (this.props.params.DOCPreliminaryTypeId||2)) {	
					isExternalRow = false;
					dataRow.TaxablePrice = dataRow.UnitCost;
					dataRow.RetailDiscountDesc = bopData.SupplierDiscountDesc;
					dataRow.RetailDiscount = bopData.SupplierDiscount;
					dataRow.CalculationType = bopData.CalculationType;
					dataRow.FIDMarkup = bopData.FIDMarkup;
					dataRow.RetailDiscountDesc = bopData.SupplierDiscountDesc;
					dataRow.FIDVatPricelist = bopData.FIDVatPricelist;
					dataRow.VatPercPricelist = bopData.VatPercPricelist;
					dataRow.DiscountDesc = "";
					dataRow.ExtraDiscountDesc = "";
					dataRow.Discount = 0;
				}
				dataRow = await this.applyDocParams(dataRow, docData, isExternalRow);	
				this.setState({ addRows: true, selectedData: dataRow });
			});
		} else {
			dataRow = await this.applyDocParams(dataRow, docData, isExternalRow);	
			this.setState({ addRows: true, selectedData: dataRow });
		}
    }
    getRowData = async (id) => {
        var { data } = this.state;
        var url = `/DOC/GetBOPData/${id}?X=OX`;
        if(data.FIDDocumentType === this.props.params.DOCPreliminaryTypeId){
            url = `/DOC/GetBOPDataPreliminary/${id}?X=OX`;
        }
        if (data.FIDCycle) {
            url += `&FIDCycle=${data.FIDCycle}`;
        }
        if (data.FIDIdentity && data.FIDDocumentType !== 1 && data.FIDCycle === (this.props.params.PassiveCycleId||3)) {
            url += `&FIDSupplier=${data.FIDIdentity}`;
        }
        if (data.FIDOwner) {
            url += "&FIDOwner=" + data.FIDOwner;
        }
        if (data.FIDOwnerDestination) {
            url += "&FIDOwnerDestination=" + data.FIDOwnerDestination;
        }
        if (data.FIDOwnerPosition) {
            url += "&FIDOwnerPosition=" + data.FIDOwnerPosition;
        }
		if (data.FIDVat) {
			url += "&FIDVat=" + data.FIDVat;
		}
        if(data.FIDPricelist) {
            url += "&FIDPricelist=" + data.FIDPricelist;
        }
        if(data.DiscountDesc){
            url += "&DiscountDesc=" + encodeURIComponent(data.DiscountDesc);
        }
        if(data.PriceType){
            url += `&PriceType=${data.PriceType}`
        }
		if (data.CalculationType) {
			url += `&CalculationTypePrice=${data.CalculationType}`
		}
        if(data.RechargePercentage) {
            url += `&RechargePercentage=${data.RechargePercentage}`
        }
		if (data.FIDMarkup) {
			url += "&FIDMarkup=" + data.FIDMarkup;
		}
        return await fetch(url).then(res => res.json());
    }
    completeRowsFromDOc = async() => {
        var selectedRows = this.grdDOCProcessRows.current.getWrappedInstance().getSelectedData();
        for (var i = 0; i < selectedRows.length; i++) {
            var row = this.grdDOCProcessRows.current.getWrappedInstance().getRowData(selectedRows[i]);
            await fetch(`/DOC/CompleteProcessRow/${row.FIDDocumentRow}?processContext=${row.Context}`).then(res =>{
            });
        }
        this.grdDOCProcessRows.current.getWrappedInstance().clearSelection();
        this.grdDOCProcessRows.current.getWrappedInstance().refresh();
    }
	
	checkParams = () => {
		if (this.state.data.FIDCycle !== 3) {
			if (this.state.data.PriceType === 2) {
				if (this.state.data.CalculationType > 2 && this.state.data.CalculationType < 5) {
					if (!this.state.data.RechargePercentage)
						return false;
				}
				
				if (this.state.data.CalculationType >=5) {
					if (!this.state.data.FIDMarkup)
						return false;
				}
			}
		} else {
			if (this.state.data.CalculationType > 2 && this.state.data.CalculationType < 5) {
				if (!this.state.data.RechargePercentage)
					return false;
			}
			
			if (this.state.data.CalculationType >=5) {
				if (!this.state.data.FIDMarkup)
					return false;
			}
		}
		
		return true;
	}
	
	applyDocParams = async (dataRow, docData, isExternalRow = false) => {
		var amountsToChange = !isExternalRow;
        var vatChanged = false;
		if (docData.DiscountDesc) {
			dataRow.DiscountDesc = docData.DiscountDesc;
			dataRow.Discount = getDiscount(dataRow.DiscountDesc);				
		}
		
		if (docData.ExtraDiscountDesc) {
			dataRow.ExtraDiscountDesc = docData.ExtraDiscountDesc;
			
			if (!dataRow.Discount)
				dataRow.Discount = 0;
			
			dataRow.ExtraDiscount = getDiscount(dataRow.ExtraDiscountDesc);	
		}
		
		if (docData.FIDVat && dataRow.FIDVat != docData.FIDVat) {
			dataRow.FIDVat = docData.FIDVat;
			dataRow.VatPerc = docData.VatPerc;
		}
		var vatPerc = dataRow.VatPerc;
		if (docData.CalculationType && docData.CalculationType != dataRow.CalculationType) {
			dataRow.CalculationType = docData.CalculationType;
			
			if ([1,2].includes(docData.CalculationType) && !docData.PricelistDiscountDesc && !docData.PricelistDiscountDescLock) {
				docData.PricelistDiscountDesc = "";
				docData.PricelistDiscount = 0;
			}
			if ([3,4].includes(docData.CalculationType) && !docData.RechargePercentage && !docData.RechargePercentageLock) {
				docData.RechargePercentage = "";
			}
			if ([5,6].includes(docData.CalculationType) && !docData.FIDMarkup) {
				docData.MarkupMultiplier = 1;
			}
			
			//if (([1,2].includes(docData.CalculationType) && docData.PricelistDiscountDesc) || ([3,4].includes(docData.CalculationType) && docData.RechargePercentage) || ([5,6].includes(docData.CalculationType) && docData.FIDMarkup)) {
			amountsToChange = true;
			//}
		}
		dataRow.PriceType = docData.PriceType;
		
		if (docData.FIDPricelist && dataRow.FIDPricelist != docData.FIDPricelist) {
			amountsToChange = true;
			dataRow.FIDPricelist = docData.FIDPricelist;
		}
		
		if (docData.FIDCycle == (this.props.params.ActiveCycleId||2)) { 
			if ((!docData.PriceType || docData.PriceType == 1) && docData.FIDPricelist) {
				if (docData.FIDPricelist && dataRow.FIDPricelist === docData.FIDPricelist && (dataRow.FIDVat === dataRow.FIDVatPricelist)) {
					amountsToChange = false;
				}
				else {
					if (dataRow.FIDPricelist !== docData.FIDPricelist) {
						dataRow.FIDPricelist = docData.FIDPricelist;
						amountsToChange = true;
					}
					
					if (docData.FIDVat != dataRow.FIDVatPricelist) {
						dataRow.VatPerc = docData.VatPerc;
						amountsToChange = true;
					}
				}
				if (amountsToChange) {
					if((dataRow.FIDDOCRowType||1) === 1){
                        var pricelist = dataRow.FIDBOP ? await fetch(`/BOP/GetPricelistInfo/${dataRow.FIDPricelist}?FIDBOP=${dataRow.FIDBOP}`).then(res => res.json()) : {};
                        
                        dataRow.PriceType = 1;
                        dataRow.TaxablePrice = pricelist.discountedTaxablePrice||0;
                        //dataRow.TotalPrice = pricelist.discountedTotalPrice||0;
                        if (docData.FIDVat != dataRow.FIDVatPricelist) {
                            dataRow.TotalPrice = (dataRow.TaxablePrice * (100 + vatPerc) / 100).toFixed(2);
                        } else {
                            dataRow.TotalPrice = pricelist.discountedTotalPrice||0;
                        }
                    }   
					
					if(dataRow.TaxablePriceLock){
						dataRow.DiscountedTotalPrice = (dataRow.TotalPrice * (1 - dataRow.Discount)).toFixed(2);
						dataRow.DiscountedTaxablePrice = (dataRow.DiscountedTotalPrice / ((100 + parseFloat(dataRow.VatPerc))/100)).toFixed(2);
					}
					else {
						dataRow.DiscountedTaxablePrice = (dataRow.TaxablePrice * (1 - dataRow.Discount)).toFixed(2);
						dataRow.DiscountedTotalPrice = (dataRow.DiscountedTaxablePrice * ((100 + parseFloat(dataRow.VatPerc))/100)).toFixed(2);
						if(!dataRow.DiscountedTotalPrice){
							dataRow.DiscountedTotalPrice = 0;
						}
					}
				}
				
				//dataRow.DiscountedTaxablePrice = (dataRow.TaxablePrice * (1 - (discount||0))).toFixed(2);
				//dataRow.DiscountedTotalPrice = Math.round(dataRow.DiscountedTaxablePrice * (100 + dataRow.VatPerc)) / 100;								
			}
			else if (docData.PriceType == 2) {
				if ((docData.CalculationType && docData.CalculationType != dataRow.CalculationType) || (dataRow.PriceType != docData.PriceType) || (docData.FIDMarkup && docData.FIDMarkup != dataRow.FIDMarkup) || (docData.RechargePercentage && docData.RechargePercentage != dataRow.RechargePercentage) ) {
					amountsToChange = true;
				}
				if (docData.CalculationType > 2 && docData.CalculationType < 5) {									
					dataRow.RechargePercentage = docData.RechargePercentage;
					dataRow.FIDMarkup = undefined;
					dataRow.MarkupMultiplier = 1;
				} else if (docData.CalculationType >= 5) {
					dataRow.RechargePercentage = 0;
					dataRow.FIDMarkup = docData.FIDMarkup;
					dataRow.MarkupMultiplier = docData.MarkupMultiplier || await fetch("/gc/markup/GetMultiplier/"+dataRow.FIDMarkup).then(res => res.json());
					
					if (!dataRow.MarkupMultiplier)
						dataRow.MarkupMultiplier = 1;
				}
				
				if (amountsToChange) {
					var r = getRecharge(dataRow.RechargePercentage);
					var m = dataRow.MarkupMultiplier;
				
					if (!dataRow.SupplierCost)
						dataRow.SupplierCost = 0;
					
					var taxableCase = [4,6];
					
					if (taxableCase.includes(dataRow.CalculationType)) {
						dataRow.TaxablePrice = ((dataRow.SupplierCost||dataRow.UnitCost) * (1 + r) * m).toFixed(2);
						dataRow.DiscountedTaxablePrice = (dataRow.TaxablePrice * (1 - (dataRow.Discount||0)) * (1 - (dataRow.ExtraDiscount||0))).toFixed(2);
						dataRow.TotalPrice = Math.round(dataRow.TaxablePrice * (100 + vatPerc)) / 100;
						dataRow.DiscountedTotalPrice = Math.round(dataRow.DiscountedTaxablePrice * (100 + vatPerc)) / 100;
					} else {
						dataRow.TotalPrice = ((dataRow.SupplierCost||dataRow.UnitCost) * (1 + r) * m).toFixed(2);
						dataRow.DiscountedTotalPrice = (dataRow.TotalPrice * (1 - (dataRow.Discount||0)) * (1 - (dataRow.ExtraDiscount||0))).toFixed(2);
						dataRow.TaxablePrice = Math.round(dataRow.TotalPrice / (100 + vatPerc)) * 100;
						dataRow.DiscountedTaxablePrice = Math.round(dataRow.DiscountedTotalPrice / (100 + vatPerc)) * 100;
					}
				}
			}
			
			if (dataRow.PriceType > 1) {
				dataRow.PublicPriceLock = true;
				dataRow.PublicTaxablePriceLock = true;
				dataRow.TaxablePriceLock = false;
			} else {
				dataRow.TaxablePriceLock = true;
				dataRow.PublicPriceLock = false;
				dataRow.PublicTaxablePriceLock = true;
			}
			
		} else if (docData.FIDCycle == (this.props.params.PassiveCycleId||3) /*&& docData.FIDDocumentType !== (this.props.params.DOCPreliminaryTypeId||2)*/) {
			if (docData.FIDDocumentType === (this.props.params.DOCPreliminaryTypeId||2)) {
				if (docData.DiscountDesc) {
					dataRow.DiscountDesc = docData.DiscountDesc;
					dataRow.Discount = getDiscount(dataRow.DiscountDesc);				
				} else {
					dataRow.DiscountDesc = "";
					dataRow.Discount = 0;
				}
				
				if (docData.ExtraDiscountDesc) {
					dataRow.ExtraDiscountDesc = docData.ExtraDiscountDesc;
					dataRow.ExtraDiscount = getDiscount(dataRow.ExtraDiscountDesc);	
				} else {
					dataRow.ExtraDiscountDesc = "";
					dataRow.ExtraDiscount = 0;
				}
			}
			if (dataRow.CalculationType <= 2 && docData.PricelistDiscountDesc) {
				var pricelist = dataRow.FIDBOP ? await fetch(`/BOP/GetPricelistInfo/${dataRow.FIDPricelist}?FIDBOP=${dataRow.FIDBOP}`).then(res => res.json()) : {};
				
				dataRow.PriceType = 1;
				
				dataRow.PublicPrice = pricelist.discountedTotalPrice||0;
				dataRow.PublicTaxablePrice = pricelist.discountedTaxablePrice||0;	
				dataRow.RetailDiscountDesc = docData.PricelistDiscountDesc;
				dataRow.RetailDiscountDiscount = getDiscount(dataRow.RetailDiscountDesc);
				dataRow.SupplierDiscountDesc = docData.PricelistDiscountDesc;
				dataRow.SupplierDiscount = getDiscount(dataRow.SupplierDiscountDesc);
				
				dataRow.TaxablePriceLock = true;
				dataRow.RetailDiscountDescLock = false;
				
				if (dataRow.CalculationType === 1) {
					dataRow.SupplierCost = (dataRow.PublicPrice * (1 - (dataRow.SupplierDiscount||0))).toFixed(2);
				} else {
					dataRow.SupplierCost = (dataRow.PublicTaxablePrice * (1 - (dataRow.SupplierDiscount||0))).toFixed(2);
				}
				dataRow.UnitCost = dataRow.SupplierCost;
				dataRow.PublicTaxablePriceLock = true;
				dataRow.PublicPriceLock = false;
				dataRow.TaxablePrice = dataRow.SupplierCost;
				//} else {
				//	dataRow.SupplierCost = (dataRow.PublicTaxablePrice * (1 - (dataRow.SupplierDiscount||0))).toFixed(2);
				//	dataRow.UnitCost = dataRow.SupplierCost;
				//	dataRow.PublicTaxablePriceLock = false;
				//	dataRow.PublicPriceLock = true;
				//}
				
			} else if (dataRow.CalculationType > 2 && dataRow.CalculationType < 5 && docData.RechargePercentage) {
				dataRow.RetailDiscountDesc = docData.RechargePercentage;
				dataRow.RetailDiscount = getDiscount(dataRow.RetailDiscountDesc);
				dataRow.SupplierDiscountDesc = docData.RechargePercentage;
				dataRow.SupplierDiscount = getDiscount(dataRow.SupplierDiscountDesc);
				dataRow.PublicPriceLock = true;
				dataRow.PublicTaxablePriceLock = true;
				dataRow.TaxablePriceLock = false;
				dataRow.RetailDiscountDescLock = false;
				if (dataRow.CalculationType === 3) {
					dataRow.PublicPrice = (dataRow.SupplierCost * (1 + (dataRow.SupplierDiscount||0))).toFixed(2);
					dataRow.PublicTaxablePrice = (dataRow.PublicPrice / (100 + (dataRow.VatPercPricelist||0)) / 100).toFixed(2);
				} else {
					dataRow.PublicTaxablePrice = (dataRow.SupplierCost * (1 + (dataRow.SupplierDiscount||0))).toFixed(2);
					dataRow.PublicPrice = (dataRow.PublicTaxablePrice * (100 + (dataRow.VatPercPricelist||0)) / 100).toFixed(2);
				}
				dataRow.TaxablePrice = dataRow.SupplierCost;
			} else if (dataRow.CalculationType >= 5 && docData.FIDMarkup) {
				dataRow.SupplierDiscountDesc = "";
				dataRow.SupplierDiscount = 0;
				dataRow.RetailDiscountDesc = "";
				dataRow.RetailDiscount = 0;
				
				dataRow.PublicPriceLock = true;
				dataRow.PublicTaxablePriceLock = true;
				dataRow.TaxablePriceLock = false;
				dataRow.RetailDiscountDescLock = false;
				
				dataRow.FIDMarkup = docData.FIDMarkup;
				dataRow.MarkupMultiplier = docData.MarkupMultiplier;
				
				if (dataRow.CalculationType === 5) {
					dataRow.PublicPrice = (dataRow.SupplierCost * (dataRow.MarkupMultiplier||0)).toFixed(2);
					dataRow.PublicTaxablePrice = (dataRow.PublicPrice / (100 + (dataRow.VatPercPricelist||0)) / 100).toFixed(2);
				} else {
					dataRow.PublicTaxablePrice = (dataRow.SupplierCost * (dataRow.MarkupMultiplier||0)).toFixed(2);
					dataRow.PublicPrice = (dataRow.PublicTaxablePrice / (100 + (dataRow.VatPercPricelist||0)) / 100).toFixed(2);
				}
				dataRow.TaxablePrice = dataRow.SupplierCost;
			}
											
			//if (dataRow.PublicPrice) {
			//	//dataRow.SellPrice = dataRow.PublicPrice;
			//	dataRow.RetailDiscount = dataRow.SupplierDiscount || 0;
			//	dataRow.RetailDiscountDesc = dataRow.SupplierDiscountDesc;
			//}
			//else 
			dataRow.TotalPrice = (dataRow.TaxablePrice * (100 + vatPerc) / 100).toFixed(2);
			if (!dataRow.PublicPrice) {
				dataRow.SellPrice = dataRow.TaxablePrice;
				dataRow.RetailDiscount = this.state.IdentityDiscount;
				dataRow.TaxablePrice = (dataRow.SellPrice * (100 - dataRow.RetailDiscount) / 100).toFixed(2);
			}
			if(!dataRow.TaxablePrice){
				dataRow.TaxablePrice = 0;
			}
			if (!dataRow.TaxablePriceLock) {
				dataRow.TotalPrice = (dataRow.TaxablePrice * (100 + vatPerc) / 100).toFixed(2);
			} else {
				dataRow.TaxablePrice = (dataRow.TotalPrice / (100 + vatPerc) * 100).toFixed(2);
			}
			if(!dataRow.TotalPrice){
				dataRow.TotalPrice = 0;
			}
			//dataRow.DiscountedTaxablePrice = (dataRow.TaxablePrice * (100 - dataRow.Discount) / 100).toFixed(2);
			//dataRow.DiscountedTotalPrice = (dataRow.TotalPrice * (100 - dataRow.Discount) / 100).toFixed(2);
			//
			//if(dataRow.TaxablePriceLock){
			//	dataRow.DiscountedTotalPrice = (dataRow.TotalPrice * (1 - dataRow.Discount)).toFixed(2);
			//	dataRow.DiscountedTaxablePrice = (dataRow.DiscountedTotalPrice / ((100 + parseFloat(dataRow.VatPerc))/100)).toFixed(2);
			//}
			//else {
			//	dataRow.DiscountedTaxablePrice = (dataRow.TaxablePrice * (1 - dataRow.Discount)).toFixed(2);
			//	dataRow.DiscountedTotalPrice = (dataRow.DiscountedTaxablePrice * ((100 + parseFloat(dataRow.VatPerc))/100)).toFixed(2);
			//	if(!dataRow.DiscountedTotalPrice){
			//		dataRow.DiscountedTotalPrice = 0;
			//	}
			//}
		}
		
		if (dataRow.SupplierDiscountDesc && dataRow.SupplierDiscountDesc !== dataRow.RetailDiscountDesc)
		{
			dataRow.RetailDiscountDesc = dataRow.SupplierDiscountDesc;
		}
		
		if (!amountsToChange && !dataRow.TotalPrice) {
			dataRow.TotalPrice = dataRow.DiscountedTotalPrice;
		}
		
		if(dataRow.TaxablePriceLock){
			dataRow.DiscountedTotalPrice = (dataRow.TotalPrice * (1 - (dataRow.Discount||0)) * (1 - (dataRow.ExtraDiscount||0))).toFixed(2);
			dataRow.DiscountedTaxablePrice = (dataRow.DiscountedTotalPrice / ((100 + parseFloat(dataRow.VatPerc))/100)).toFixed(2);
		}
		else {
			dataRow.DiscountedTaxablePrice = (dataRow.TaxablePrice * (1 - (dataRow.Discount||0)) * (1 - (dataRow.ExtraDiscount||0))).toFixed(2);
			dataRow.DiscountedTotalPrice = (dataRow.DiscountedTaxablePrice * ((100 + parseFloat(dataRow.VatPerc))/100)).toFixed(2);
			if(!dataRow.DiscountedTotalPrice){
				dataRow.DiscountedTotalPrice = 0;
			}
		}
		
		dataRow.TotalTaxableAmount = (dataRow.TaxablePrice * (dataRow.Qty1||1)).toFixed(2);
		dataRow.TotalDiscountedTaxableAmount = (dataRow.DiscountedTaxablePrice * (dataRow.Qty1||1)).toFixed(2);
		//dataRow.TotalAmount = Math.round(dataRow.TotalTaxableAmount * (100 + (dataRow.VatPerc))) / 100;
		dataRow.TotalAmount = (dataRow.TotalPrice * (dataRow.Qty1||1)).toFixed(2);
		dataRow.TotalDiscountedAmount = (dataRow.TotalPrice * (dataRow.Qty1||1) * (1 - (dataRow.Discount||0)) * (1 - (dataRow.ExtraDiscount||0))).toFixed(2);

		return dataRow;
	}
	
    addRowsFromDoc = async() =>{
        var { data } = this.state;
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }
		
		if (!this.checkParams()) {
			this.MessageBox.current && this.MessageBox.current.getWrappedInstance().showMessageOk("PARAMETRI INCOMPLETI", "Compilare i parametri obbligatori per proseguire con l'operazione");
			return;
		}
		
        var selectedRows = this.grdDOCProcessRows.current.getWrappedInstance().getSelectedData();
        var hasInvalidRows = false;
        if(data.FIDCycle === 3){
            for (var i = 0; i < selectedRows.length; i++) {
                var row = this.grdDOCProcessRows.current.getWrappedInstance().getRowData(selectedRows[i]);
                if (!row.TaxablePrice && row.TaxablePrice !== 0) {
                    hasInvalidRows = true;
                    break;
                }
                //var dataRow = await this.getRowData(selectedRows[i]);
            }
        }
        if (hasInvalidRows) {
            if (selectedRows.length === 1) {
                this.editRowsFromDoc();
                return;
            }
            this.MessageBox.current && this.MessageBox.current.getWrappedInstance() && this.MessageBox.current.getWrappedInstance().showMessageOk("Distinte incomplete", "Alcune distinte selezionate risultano incomplete. Procedere aggiungendo una distinta per volta!");
            return;
        }

        var rows = [];
        for (var i = 0; i < selectedRows.length; i++) {
            var dataRow = Object.assign({},this.grdDOCProcessRows.current.getWrappedInstance().getRowData(selectedRows[i]));
				
            if(data.FIDCycle === (this.props.params.PassiveCycleId||3) /*&& data.FIDDocumentType == (this.props.params.DOCPreliminaryTypeId||2)*/) {			
                
                if (data.FIDDocumentType == (this.props.params.DOCPreliminaryTypeId||2)) {
                    // la tratto come una riga normale perché nell'ordine a fornitore gli importi derivano dalla distinta
                    isExternalRow = false;
                    var bopData = await this.getRowData(dataRow.FIDBOP);	
                    dataRow.CalculationType = bopData.CalculationType;
                    dataRow.UnitCost = bopData.SupplierCost||0;
                    dataRow.SupplierCost = bopData.SupplierCost;
                    dataRow.RetailDiscountDesc = bopData.SupplierDiscountDesc;
                    dataRow.FIDMarkup = bopData.FIDMarkup;
                    dataRow.SupplierDiscount = bopData.SupplierDiscount;
                    dataRow.PublicPrice = bopData.PublicPrice;
                    dataRow.PublicTaxablePrice = bopData.PublicTaxablePrice;
                    dataRow.FIDPricelist = bopData.FIDPricelist;
                    dataRow.FIDVatPricelist = bopData.FIDVatPricelist;
                    dataRow.VatPercPricelist = bopData.VatPercPricelist
                    dataRow.TaxablePrice = dataRow.UnitCost;
                    dataRow.DiscountDesc = "";
                    dataRow.ExtraDiscountDesc = "";
                    dataRow.Discount = 0;
                }
                
            }
            
            dataRow = await this.applyDocParams(dataRow, data, isExternalRow);
            
            if (dataRow.MeasureUnitQty2) {
                dataRow.Qty2 = dataRow.MeasureUnitQty2 * dataRow.Qty1 / dataRow.MeasureUnitQty1;
            }
            
            dataRow.Causal = undefined;
            //dataRow.FIDDocumentRow = dataRow.ID;
            dataRow.ID = 0;
            rows.push(dataRow);
        }

        if(data.FIDCycle === 2 && data.FIDDocumentType === 3 && data.FIDDocumentSubtype === 5)
        {//verifico plafond
            var canInsert = await fetch(`/DOC/VerifyPlafondRows/${FIDDOC}?FIDIdentity=${data.FIDIdentity}`,{
                method: 'POST',
                body: JSON.stringify(rows),
            }).then(res => res.json());
            if(!canInsert && this.MessageBox.current && this.MessageBox.current.getWrappedInstance()){
                var msgResponse = await this.MessageBox.current.getWrappedInstance().showMessageYesNoAsync("Superamento plafond", "Attenzione! Si sta superando il plafond disponibile. Procedere comunque con l'inserimento delle righe selezionate?");
                if(!msgResponse){
                    return;
                }
            }
        }
		var isExternalRow = true;
        this.setState({savingRow: true}, async () => {
            var added = 0;
            for (var i = 0; i < rows.length; i++) {
                var dataRow = rows[i];
                
                //console.log(dataRow);
                var result = await fetch(`/DOC/SaveDocumentRow/${FIDDOC}?FIDIdentity=${data.FIDIdentity}`, {
                    method: 'POST',
                    body: JSON.stringify(dataRow)
                }).then(handleErrors).then(res => res.json()).catch(error => {
                    console.log(error);
                });
                if(result){
                    if(this.props.params.DocumentAutosave){
                        await this.doSaveData();
                    }
                    added++;
                }
            }
            var msg = `Aggiunte ${added} distinte al documento`;
            if (added === 1) {
                msg = `Aggiunta una distinta al documento`;
            }
            else if(added === 0){
                msg = `Nessuna distinta aggiunta al documento`;
            }
    
            this.grdDOCProcessRows.current.getWrappedInstance().clearSelection();
            this.grdDOCProcessRows.current.getWrappedInstance().refresh();
            this.documentHasRows();
    
            this.setState({ savingRow: false, selectedRows, addRows: false, addNewRow: false, snackbar: { open: true, message: msg } });
        });
    }
    addRows = async () => {
        var { data } = this.state;
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }
		
		if (!this.checkParams()) {
			this.MessageBox.current && this.MessageBox.current.getWrappedInstance().showMessageOk("PARAMETRI INCOMPLETI", "Compilare i parametri obbligatori per proseguire con l'operazione");
			return;
		}
		
        var selectedRows = this.grdDOCBops.current.getWrappedInstance().getSelectedData();
        var hasInvalidRows = false;
        if(data.FIDCycle === 3){
            for (var i = 0; i < selectedRows.length; i++) {
                var row = this.grdDOCBops.current.getWrappedInstance().getRowData(selectedRows[i]);
                if (!row.SupplierCost || !row.CalculationType || ([5,6].includes(row.CalculationType) && !row.FIDMarkup && !row.RechargePercentage && !row.RetailDiscountDesc)) {
                    hasInvalidRows = true;
                    break;
                }
                //var dataRow = await this.getRowData(selectedRows[i]);
            }
        }
        if (hasInvalidRows) {
            if (selectedRows.length === 1) {
                this.editRows();
                return;
            }
            this.MessageBox.current && this.MessageBox.current.getWrappedInstance() && this.MessageBox.current.getWrappedInstance().showMessageOk("Distinte incomplete", "Alcune distinte selezionate risultano incomplete. Procedere aggiungendo una distinta per volta!");
            return;
        }

        var rows = [];
        for(var i=0;i<selectedRows.length;i++){
            var dataRow = await this.getRowData(selectedRows[i]); //this.grdDOCBops.current.getWrappedInstance().getRowData(selectedRows[i]);
            if(data.FIDCausal === 6) {//invetario
                dataRow.Qty1 = dataRow.MeasureUnitQty1;
                dataRow.Qty2 = dataRow.MeasureUnitQty2;
            }
            else {
                dataRow.Qty1 = 1;
            }
            dataRow.FIDBOP = dataRow.ID;
            dataRow.ID = 0;

            if (data.FIDVat) {
            dataRow.FIDVat = data.FIDVat;
            dataRow.VatPerc = data.VatPerc;
            }
            dataRow.FIDCausal = data.FIDCausal;
            dataRow.FIDCycle = data.FIDCycle;
            dataRow.FIDDOCRowType = 1;
            if (!dataRow.TaxablePrice) {
                dataRow.TaxablePrice = 0;
            }

            // if (!dataRow.Discount) {
            //     dataRow.Discount = 0;
            // }
            if (!data) {
                data = {
                    FIDPricelist: data.FIDCycle === (this.props.params.PassiveCycleId) ? this.props.params.DEF_PRICELIST : undefined,
                    DiscountDesc: "",
                    ExtraDiscountDesc: "",
                }
                }
            var vatPerc = dataRow.VatPerc; 
            if (data.DiscountDesc) {
                dataRow.DiscountDesc = data.DiscountDesc;
                }
            if (data.ExtraDiscountDesc) {
                dataRow.ExtraDiscountDesc = data.ExtraDiscountDesc;
                }
            
            var discount = getDiscount(dataRow.DiscountDesc);
            var extraDiscount = getDiscount(dataRow.ExtraDiscountDesc);
            dataRow.Discount = discount;
            dataRow.ExtraDiscount = extraDiscount;
            if (data.FIDCycle === (this.props.params.PassiveCycleId||3)) {
                if(!dataRow.UnitCost)
                    dataRow.UnitCost = 0;
                if (!dataRow.SupplierCost)
                    dataRow.SupplierCost = 0;
                dataRow.TaxablePrice = dataRow.SupplierCost||dataRow.UnitCost;
                dataRow.DiscountedTaxablePrice = (dataRow.TaxablePrice * (1 - (discount||0)) * (1 - (extraDiscount||0))).toFixed(2);
                dataRow.TotalPrice = Math.round(dataRow.TaxablePrice * (100 + vatPerc)) / 100;
                dataRow.DiscountedTotalPrice = Math.round(dataRow.DiscountedTaxablePrice * (100 + vatPerc)) / 100;
            }

            dataRow = await this.applyDocParams(dataRow, data);

            if (dataRow.MeasureUnitQty2) {
                dataRow.Qty2 = dataRow.MeasureUnitQty2 * dataRow.Qty1 / dataRow.MeasureUnitQty1;
            }
            rows.push(dataRow);
        }
        
        if(data.FIDCycle === 2 && data.FIDDocumentType === 3 && data.FIDDocumentSubtype === 5)
        {//verifico plafond
            var canInsert = await fetch(`/DOC/VerifyPlafondRows/${FIDDOC}?FIDIdentity=${data.FIDIdentity}`,{
                method: 'POST',
                body: JSON.stringify(rows),
            }).then(res => res.json());
            if(!canInsert && this.MessageBox.current && this.MessageBox.current.getWrappedInstance()){
                var msgResponse = await this.MessageBox.current.getWrappedInstance().showMessageYesNoAsync("Superamento plafond", "Attenzione! Si sta superando il plafond disponibile. Procedere comunque con l'inserimento delle righe selezionate?");
                if(!msgResponse){
                    return;
                }
            }
        }

        this.setState({savingRow : true}, async () => {
            var added = 0;
			var docData = this.state.data;
            for (var i = 0; i < rows.length; i++) {
                var dataRow = rows[i];
                //console.log(dataRow);
                var result = await fetch(`/DOC/SaveDocumentRow/${FIDDOC}?FIDIdentity=${data.FIDIdentity}`, {
                    method: 'POST',
                    body: JSON.stringify(dataRow)
                }).then(handleErrors).then(res => res.json()).catch(error => {
                    console.log(error);
                });
                this.documentHasRows();
                if(result){
                    added++;
                }
            }
            var msg = `Aggiunte ${added} distinte al documento`;
            if (added === 1) {
                msg = `Aggiunta una distinta al documento`;
            }
            else if (added === 0) {
                msg = `Nessuna distinta aggiunta al documento`;
            }
    
            this.grdDOCBops.current.getWrappedInstance().refresh();
            this.grdDOCBops.current.getWrappedInstance().clearSelection();
    
            this.setState({ savingRow: false, selectedRows, addRows: false, addNewRow: false, snackbar: { open: true, message: msg } });
            if(this.props.params.DocumentAutosave){
                await this.doSaveData();
            }
        });

    }
    doSaveRow = (callback) =>{
        var data = this.DocumentRowEditor.current.getData();
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }
        if(!data.ExtraDiscount){
            data.ExtraDiscount = 0;
        }
        data.ID = 0;
        this.setState({ selectedRows: [], addRows: false, addNewRow: false, savingRow: true }, () => {
            fetch(`/DOC/SaveDocumentRow/${FIDDOC}?FIDIdentity=${this.state.data.FIDIdentity}`, {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(handleErrors).then(res => res.json()).then(async(data) => {
                if(data){
                    this.grdDOCRows.current && this.grdDOCRows.current.getWrappedInstance().clearSelection();
                    this.grdDOCRows.current && this.grdDOCRows.current.getWrappedInstance().refresh();
                    this.grdDOCBops.current && this.grdDOCBops.current.getWrappedInstance().clearSelection();
                    this.grdDOCBops.current && this.grdDOCBops.current.getWrappedInstance().refresh();
                    this.grdDOCProcessRows.current && this.grdDOCProcessRows.current.getWrappedInstance().clearSelection();
                    this.grdDOCProcessRows.current && this.grdDOCProcessRows.current.getWrappedInstance().refresh();
                    this.documentHasRows();
                    this.setState({  savingRow: false, snackbar: { open: true, message: 'Aggiunta una distinta al documento' } });
                    if(this.props.params.DocumentAutosave && !callback){
                        await this.doSaveData();
                    }
                    callback && callback();
                }
                else {
                    this.setState({  savingRow: false, snackbar: { open: true, message: 'Nessuna distinta aggiunta al documento' } });
                }
            }).catch(error => {
                this.setState({  savingRow: false });
                console.log(error);
            });
        });
    }
    onSaveRow = (callback) => {
        var { isValid, requiredFields, optionalFields, extraMessages, fields } = this.DocumentRowEditor.current.validate();
        if(isValid){
            this.doSaveRow(callback);
        }
        else {
            this.showMessage(requiredFields,optionalFields,() => {this.doSaveRow(callback)}, fields,extraMessages);
        }
    }
    onCancelAddRow = () => {
        this.setState({ selectedRows:[], addRows: false });
    }
    onCodeSearch = () => {
        this.setState({ grdDOCBopsSearch: true, grdDOCBopsType: "code", grdDOCBopsSearchValue:"" });
    }
    onBarcodeSearch = () => {
        this.setState({ grdDOCBopsSearch: true, grdDOCBopsType: "barcode", grdDOCBopsSearchValue: "" });
    }
    onSerialSearch = () => {
        this.setState({ grdDOCBopsSearch: true, grdDOCBopsType: "serial", grdDOCBopsSearchValue: "" });
    }
    // onSearchCancel = () => {
    //     this.setState({ grdDOCBopsSearch: false, grdDOCBopsType: "", grdDOCBopsSearchValue: "" });
    // }
    // onSearchConfirm = () => {
    //     this.doSearchConfirm();
    // }
    // onSearchBlur = (id) => (event) => {
    //     console.log(this.grdDOCBopsSearch.current);
    //     this.grdDOCBopsSearch.current.focus();
    // }
    // onSearchChange = (id) => (event, value) => {
    //     this.setState({ grdDOCBopsSearchValue: value });
    // }
    // onSearchKeyDown = (id) => (event, value) => {
    //     if (event.keyCode === 13) {
    //         this.doSearchConfirm();
    //     }
    // }
    // doSearchConfirm = () => {
    //     var { grdDOCBopsType, grdDOCBopsSearchValue } = this.state;

    //     var key = "";
    //     switch (grdDOCBopsType) {
    //         case "code":
    //             key = "Code";
    //             break;
    //         case "barcode":
    //             key = "Barcode";
    //             break;
    //         case "serial":
    //             key = "SerialNumber";
    //             break;
    //         default:
    //             break;
    //     }

    //     this.grdDOCBops.current.getWrappedInstance().search(key, grdDOCBopsSearchValue, () => {
    //         var rows = this.grdDOCBops.current.getWrappedInstance().refresh();
    //         if (rows === 1) {
    //             this.grdDOCBops.current.setState(state => ({ selected: state.data.map(n => n.ID), filters: [] }), () => {
    //                 this.addRows();
    //                 this.grdDOCBops.current.getWrappedInstance().refresh();
    //             });
    //         }
    //     });
        
    //     this.setState({ grdDOCBopsSearch: false, grdDOCBopsType: "", grdDOCBopsSearchValue: "" });
    // }
    handleRowBOPData = () => {
        var { addRows, selectedData, data} = this.state;

        var rowData = {};
        if (selectedData) {
            rowData = Object.assign({}, selectedData);
            //console.log(rowData);
            if(!rowData.FIDCausal){
                rowData.FIDBOP = rowData.ID;
                rowData.ID = 0;


                rowData.FIDVat = data.FIDVat;
                rowData.VatPerc = data.VatPerc;
                rowData.FIDCausal = data.FIDCausal;
                rowData.FIDCycle = data.FIDCycle;
				
				if (data.PriceType) {
					rowData.PriceType = data.PriceType;
				
					if (data.PriceType == 1 && data.FIDPricelist) {
						rowData.FIDPricelist = data.FIDPricelist;
					} else if (data.PriceType == 2) {
						rowData.RechargePercentage = data.RechargePercentage;
					}
					else if (data.PriceType == 3 && data.FIDMarkup) {
						rowData.FIDMarkup = data.FIDMarkup;
						rowData.MarkupMultiplier = data.MarkupMultiplier;
					}
				}
                if (data.FIDCycle === this.props.params.PassiveCycleId) {//passivo
                    if (rowData.PublicPrice) {
                        //rowData.SellPrice = rowData.PublicPrice;
                        rowData.RetailDiscountDesc = rowData.SupplierDiscountDesc;
                        rowData.RetailDiscount = rowData.SupplierDiscount;
                        rowData.TaxablePrice = rowData.SupplierCost;
                    }
                    else {
                        rowData.SellPrice = rowData.TotalAmount;
                        rowData.RetailDiscountDesc = this.state.IdentityDiscount;
                        rowData.RetailDiscount = getDiscount(rowData.RetailDiscountDesc);
                        rowData.TaxablePrice = (rowData.SellPrice * (100 - rowData.RetailDiscount) / 100).toFixed(2);
                    }
                    
                }
                if(data.FIDCausal !== 6){//inventario
                    rowData.Qty1 = 1;
                }
                else {
                    rowData.Qty1 = rowData.MeasureUnitQty1;
                    rowData.Qty2 = rowData.MeasureUnitQty2;
                }
                if (rowData.MeasureUnitQty2) {
                    rowData.Qty2 = rowData.MeasureUnitQty2 * rowData.Qty1 / rowData.MeasureUnitQty1;
                }
            }
            else{
                //rowData.FIDDocumentRow = rowData.ID;
                rowData.Causal = undefined;
                rowData.ID = 0;
            }
        }
        else if (addRows) {
            rowData.FIDVat = data.FIDVat;
            rowData.VatPerc = data.VatPerc;
            rowData.FIDCausal = data.FIDCausal;
            rowData.FIDCycle = data.FIDCycle;
            if (data.FIDCycle === this.props.params.PassiveCycleId) {//passivo
                rowData.RetailDiscount = this.state.IdentityDiscount;
                rowData.RetailDiscount = getDiscount(rowData.RetailDiscountDesc);
            }
            rowData.FIDMeasureUnit1 = this.props.params.DEF_UM;
            rowData.FIDPricelist = this.props.params.DEF_PRICELIST;
            rowData.Qty1 = 1;
        }
         
		if (!rowData.TaxablePriceLock) {
			if(!rowData.TaxablePrice) {
				rowData.TaxablePrice = 0;
			}
			rowData.TotalPrice = Math.round(rowData.TaxablePrice * (100 + rowData.VatPerc) / 100);
			rowData.DiscountedTaxablePrice = Math.round(rowData.TaxablePrice * (1 - rowData.Discount) * (1 - rowData.ExtraDiscount));
			rowData.DiscountedTotalPrice = Math.round(rowData.DiscountedTaxablePrice * (100 + rowData.VatPerc) / 100);
		} else {
			if(!rowData.TotalPrice) {
				rowData.TotalPrice = 0;
			}
			rowData.TaxablePrice = Math.round(rowData.TotalPrice / (100 + rowData.VatPerc) * 100);
			rowData.DiscountedTaxablePrice = Math.round(rowData.TaxablePrice * (1 - rowData.Discount) * (1 - rowData.ExtraDiscount));
			rowData.DiscountedTotalPrice = Math.round(rowData.TotalPrice * (1 - rowData.Discount) * (1 - rowData.ExtraDiscount));
		}
		rowData.TotalTaxableAmount = Math.round(rowData.TaxablePrice * (rowData.Qty1||1));
		rowData.TotalDiscountedTaxableAmount = Math.round(rowData.TotalTaxableAmount * (1 - rowData.Discount) * (1 - rowData.ExtraDiscount));
        rowData.TotalAmount = Math.round(rowData.TotalTaxableAmount * (100 + rowData.VatPerc) / 100);
        rowData.TotalDiscountedAmount = Math.round(rowData.TotalDiscountedTaxableAmount * (100 + rowData.VatPerc) / 100);
        return rowData;
    }

    handleEditRow = (event, data) => {
        this.grdDOCRows.current.getWrappedInstance().clearSelection();
        this.setState({ editRow: true, rowData: data });
    }
    onCancelEditRow = () => {
        this.setState(state => { 
			state.editRow = false;
			state.rowData = {};
			return state;
		});
    }
    doSaveEditRow = async(callback) =>{
        var data = this.DocumentRowEditor.current.getData();
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }
		
		let docRows = this.grdDOCRows.current.getWrappedInstance().getData().filter(r => r != data.ID);
		var rows = [
			data
		];
		
		for (var i = 0; i < docRows.length; i++) {
            var dataRow = Object.assign({},this.grdDOCRows.current.getWrappedInstance().getRowData(docRows[i]));
            rows.push(dataRow);
        }
		
		if(this.state.data.FIDCycle === 2 && this.state.data.FIDDocumentType === 3 && this.state.data.FIDDocumentSubtype === 5)
        {//verifico plafond
            var canInsert = await fetch(`/DOC/VerifyPlafondRows/${FIDDOC}?FIDIdentity=${this.state.data.FIDIdentity}`,{
                method: 'POST',
                body: JSON.stringify(rows),
            }).then(res => res.json());
            if(!canInsert && this.MessageBox.current && this.MessageBox.current.getWrappedInstance()){
                var msgResponse = await this.MessageBox.current.getWrappedInstance().showMessageYesNoAsync("Superamento plafond", "Attenzione! Si sta superando il plafond disponibile. Procedere comunque con la modifica della riga?");
                if(!msgResponse){
                    return;
                }
            }
        }
		
        //data.ID = 0;
        this.setState({savingRow: true, editRow: false, addNewRow: false, rowData: {} }, () => {
            fetch(`/DOC/SaveDocumentRow/${FIDDOC}?FIDIdentity=${this.state.data.FIDIdentity}`, {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(handleErrors).then(res => res.json()).then(data => {
                this.setState({savingRow: false}, async() =>{
                    if(data) {
                        this.grdDOCRows.current && this.grdDOCRows.current.getWrappedInstance().refresh();
                        this.grdDOCBops.current && this.grdDOCBops.current.getWrappedInstance().refresh();
                        this.grdDOCProcessRows.current && this.grdDOCProcessRows.current.getWrappedInstance().refresh();
                        this.documentHasRows();
                        if(this.props.params.DocumentAutosave && !callback){
                            await this.doSaveData();
                        }
                        callback && callback();
                    }
                });
                
            }).catch(error => {
                console.log(error);
                this.setState({savingRow: false});
            });
        });
    }
    onSaveEditRow = async(callback) => {
      var { isValid, requiredFields, optionalFields, extraMessages, fields } = this.DocumentRowEditor.current && this.DocumentRowEditor.current.validate();
        if(isValid){
            await this.doSaveEditRow(callback);
        }
        else {
            this.showMessage(requiredFields,optionalFields, async() => await this.doSaveEditRow(callback), fields,extraMessages);
        }
    }

    onConfirm = () => {
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }
        fetch('/DOC/ConfirmDocument/' + FIDDOC, {
            method: 'POST'
        }).then(handleErrors).then(res => res.json()).then(data => {

        }).catch(error => {
            console.log(error);
        });
    }
    onReturnDocument = () => {
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }
        fetch('/DOC/ReturnDocumentFromDocument/', {
            method: 'POST',
            body: JSON.stringify([FIDDOC])
        }).then(handleErrors).then(res => res.json()).then(data => {

        }).catch(error => {
            console.log(error);
        });
    }
    onDDTDocument = () => {
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }
        fetch('/DOC/DDTFromDocument/', {
            method: 'POST',
            body: JSON.stringify([FIDDOC])
        }).then(handleErrors).then(res => res.json()).then(data => {
            if (data > 0) {
                this.setState({ snackbar: { open: true, message: "DDT Generato" } });
                //apro documento
                this.justExit = true;
                this.context.router.history.push('/documents/');
                setTimeout(() => {
                    this.context.router.history.push('/doc/edit/' + data);
                },100)
            }
            else {
                this.setState({ snackbar: { open: true, message: "Documento già processato" } });
            }
        }).catch(error => {
            console.log(error);
        });
    }
    onFTDocument = () => {
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }
        fetch('/DOC/FTFromDocument/', {
            method: 'POST',
            body: JSON.stringify([FIDDOC])
        }).then(handleErrors).then(res => res.json()).then(data => {
            if (data > 0) {
                this.setState({ snackbar: { open: true, message: "Fattura generata" } });
                //apro documento
                this.context.router.history.push('/documents/');
                setTimeout(() => {
                    this.context.router.history.push('/doc/edit/' + data);
                },10)
            }
            else {
                this.setState({ snackbar: { open: true, message: "Documento già processato" } });
            }
        }).catch(error => {
            console.log(error);
        });
    }
    showBOPS = () => {
        this.setState({ viewRows: "BOPS" });
    }
    showBOPSTOCK = () => {
        this.setState({ viewRows: "BOPSTOCK" });
    }
    showDOCBOPS = () => {
        this.setState({ viewRows: "DOCS" });
    }
    showDOCROWS = () => {
        this.setState({ viewRows: "ROWS" });
    }
    onDOCIRFilterChange = (step) => {
        this.setState({docIRFilter: step}, this.grdDOCProcessRows.current.getWrappedInstance().refresh);
    }
    onGridDestinationFilterChange = (values) =>{
        this.setState({gridDestinationFilter: values}, this.setDefaultPositionFilterGrid);
    }
    setDefaultPositionFilterGrid = async () => {
        var { gridDestinationFilter} = this.state;
        var positions = [];
        for (var i1 in gridDestinationFilter) {

            var FIDDestination = gridDestinationFilter[i1];
            var FIDPosition = await fetch(`/mag/position/GetDefaultPosition/${FIDDestination}`).then(res => res.json());
            if(FIDPosition){
                positions.push(FIDPosition);
            }
        }
        this.setState({gridPositionFilter: positions});
    }
    onGridPositionFilterChange = (values) =>{
        this.setState({gridPositionFilter: values});
    }
    search = () =>{
        this.setState({ showImportFileUpload: false, showSearch: true, bopSearchValue: ''});
    }
    onSearchBlur = (id) => (event) => {
        this.bopsSearch.current.focus();
    }
    onSearchKeyDown = (id) => (event, value) => {
        if (event.keyCode === 13) {
            this.doSearchConfirm();
        }
    }
    onSearchChange = (id) => (event, value) => {
        this.setState({ bopSearchValue: value });

    }
    onSearchCancel = () => {
        this.setState({ showSearch: false, bopSearchValue: '' });

    }
    onSearchConfirm = () => {
        this.doSearchConfirm();
    }
    
    doSearchConfirm = () => {
        this.setState(state => { 
            state.showSearch = false;
            state.bopsSearchTerm = state.bopSearchValue;
            state.bopSearchValue= '';
            state.openIfOnlyOne = true;
            return state;
        });
    }
    handleUploadImportFile = () => {
        this.setState({
            showImportFileUpload: true
        })
    }
    renderPanelRows = () => {
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }
        var { width } = this.props;
        var { editRow, rowData, addNewRow, newRowType, newBop, data, viewRows, docIRFilter, savingRow, showImportFileUpload, gridDestinationFilter, gridPositionFilter, addRows, selectedData, grdDOCBopsSearch, grdDOCBopsType, grdDOCBopsSearchValue } = this.state;

        var customContent = undefined;
        var showFilterActivator = !editRow && !addRows;
        var showColumnsSelector = !editRow && !addRows;
        var showChangeView = !editRow && !addRows;
        var actions = [];
        var handleEditRow = this.handleEditRow;
        var selectedActions = [];
        var customStyle = undefined;
        var handleDoubleClick = this.handleEditRow;

        var handleNewRow = this.handleNewRow;

        var label = undefined;
        if (!editRow && !addRows && !addNewRow) {
            var navActions = [];
            if(data.FIDDocumentSubtype === 3 && data.FIDCycle === 2) {
                navActions.push({ name: "Catalogo", icon: <Ring />, action: this.showBOPS, selected: viewRows === "BOPS" });
				if (data.FIDIdentity !== this.props.FIDLicensee)
                navActions.push({ name: "Magazzino", icon: <Ring />, action: this.showBOPSTOCK, selected: viewRows === "BOPSTOCK" });
            }
            else{
            navActions.push({ name: "Distinte", icon: <Ring />, action: this.showBOPS, selected: viewRows === "BOPS" });
            }
            if(data.FIDCausal !== 6){
                navActions.push({ name: "Documenti", icon: <TextBoxSearch />, action: this.showDOCBOPS, selected: viewRows === "DOCS" });
            }
            navActions.push({ name: "Righe documento", icon: <TextBox />, action: this.showDOCROWS, selected: viewRows === "ROWS" });



            var direction = "right";
            if (["xs"].includes(width)) {
                direction = "down";
            }

            label = <span><XNavigation actions={navActions} direction={direction} showLabel /></span>;
        }

        var gridName = "grdDOCRows";
        var gridRef = this.grdDOCRows;
        var url = `/DOC/GetDOCRows/${FIDDOC}?grid=${gridName}`;
        var deleteUrl = undefined;

        var key = gridName;
        if (data.FIDCycle) {
            url += "&FIDCycle=" + data.FIDCycle;
            key += "&FIDCycle=" + data.FIDCycle;
        }
        
        if (!addNewRow && !addRows && !editRow && !data.HasRows && (this.props.params.PassiveCycleId)) {
            actions.push({
                group: 'actions',
                name: 'Importa da Excel',
                icon: <FileExcelBox />,
                action: this.handleUploadImportFile,
            })
        }

        if (addNewRow) {
            if (newBop) {
                return this.renderNewBOP();
                //({ customContent } = this.renderNewBOP(customContent));
                //this.setState({ isLoading: true });
                //this.props.history.push({ pathname:'/BOP?fromDocument=true' });
                handleNewRow = undefined;               
            } else {
                ({ customContent, actions } = this.renderChooseNewRowType(customContent, actions));
                handleNewRow = undefined;
            }
        }
        else if (editRow) {

            ({ handleNewRow, rowData, customContent, actions } = this.renderEditor(editRow, handleNewRow, rowData, customContent, this.onSaveEditRow, this.onCancelEditRow));
        }
        else if (addRows) {
            rowData = this.handleRowBOPData();
            ({ handleNewRow, rowData, customContent, actions } = this.renderEditor(addRows, handleNewRow, rowData, customContent, this.onSaveRow, this.onCancelAddRow));
        }
        switch (viewRows) {
            case "BOPS":
            case "BOPSTOCK":
                customStyle = (style, data) => {
                    if(data.OtherPosition) {
                        style.backgroundColor = this.props.theme.palette.secondary.light;
                    }
                    if (data.InDoc) {
                        style.backgroundColor = this.props.theme.palette.primary.light;
                    }
                    return style;
                }
                deleteUrl = undefined;
                gridRef = this.grdDOCBops;
                handleEditRow = undefined;
                gridName = "tblDOCBops";
                if(data.FIDCycle === 2){
                    gridName = "tblDOCBops2";
                }
                url = `/DOC/GetBOPS/${FIDDOC}?grid=${gridName}`;
                if(data.FIDDocumentSubtype === this.props.params.DOCQuoteSubtypeId) {

                    url = `/DOC/GetBopsQuote/${FIDDOC}?grid=${gridName}`;
                }
                else if(data.FIDDocumentSubtype === this.props.params.DOCOrderSubtypeId) {
                    url = `/DOC/GetBopsOrder/${FIDDOC}?grid=${gridName}`;
                    if(viewRows === "BOPSTOCK"){
                        url += `&OnlyStock=true`;
                }
                    else {
                        url += `&OnlyCatalog=true`;
                    }
                }
                handleDoubleClick = this.editRowsDblClk;
                
                key = gridName;
                if(viewRows === "BOPSTOCK"){
                    key += 'STOCK';
                }
                if (data.FIDCycle) {
                    var isMovement = data.FIDCausal === 8;
                    if(isMovement) {
                        url += "&FIDCycle=" + this.props.params.ActiveCycleId;
                        key += "&FIDCycle=" + this.props.params.ActiveCycleId;
                    }
                    else {
                        url += "&FIDCycle=" + data.FIDCycle;
                        key += "&FIDCycle=" + data.FIDCycle;
                    }
                }
                if (data.FIDOwner) {
                    url += "&FIDOwner=" + data.FIDOwner;
                    key += "&FIDOwner=" + data.FIDOwner;
                }
                if(gridDestinationFilter){
                    for(var i in gridDestinationFilter){
                        var FIDDest = gridDestinationFilter[i];
                        url += "&FIDOwnerDestination=" + FIDDest;
                        key += "&FIDOwnerDestination=" + FIDDest;
                    }
                    
                    if (data.FIDOwnerDestination) {
                        url += "&FIDDOCDestination=" + data.FIDOwnerDestination;
                        key += "&FIDDOCDestination=" + data.FIDOwnerDestination;
                    }
                }
                else if (data.FIDOwnerDestination) {
                    url += "&FIDOwnerDestination=" + data.FIDOwnerDestination;
                    key += "&FIDOwnerDestination=" + data.FIDOwnerDestination;
                }
                if(gridPositionFilter){
                    for(var i in gridPositionFilter){
                        var FIDPos = gridPositionFilter[i];
                        url += "&FIDOwnerPosition=" + FIDPos;
                        key += "&FIDOwnerPosition=" + FIDPos;
                    }
                    if (data.FIDOwnerPosition) {
                        url += "&FIDDOCPosition=" + data.FIDOwnerPosition;
                        key += "&FIDDOCPosition=" + data.FIDOwnerPosition;
                    }
                }
                else if (data.FIDOwnerPosition) {
                    url += "&FIDOwnerPosition=" + data.FIDOwnerPosition;
                    key += "&FIDOwnerPosition=" + data.FIDOwnerPosition;
                }
                if (data.FIDCycle === this.props.params.PassiveCycleId && data.FIDDocumentType !== 1 && data.FIDDocumentType !== 4) {
                    url += "&FIDSupplier=" + data.FIDIdentity;
                    key += "&FIDSupplier=" + data.FIDIdentity;
                }
                if(data.FIDIdentity){
                    url += "&FIDIdentity=" + data.FIDIdentity;
                    key += "&FIDIdentity=" + data.FIDIdentity;
                }
                if(data.FIDPricelist){
                    url += "&FIDPricelist=" + data.FIDPricelist;
                    key += "&FIDPricelist=" + data.FIDPricelist;
                }
                if(data.FIDCausal === 6 ) {//inventario
                    url += `&OnlyStock=true&FIDCausal=${data.FIDCausal}`;
                    key += `&OnlyStock=true&FIDCausal=${data.FIDCausal}`;
                    if(data.DocumentDate){
                        var docDate = data.DocumentDate;
                        if(!docDate._isAMomentObject) {
                            docDate = moment(docDate);
                        }
                        url += `&DocumentDate=${docDate.format('YYYY-MM-DD')}`;
                        key += `&DocumentDate=${docDate.format('YYYY-MM-DD')}`;
                    }

                    if(!editRow && !addRows) {
                        var style= {
                            display: 'inline-block',
                            verticalAlign: 'top'
                        };
                        gridDestinationFilter = gridDestinationFilter || [data.FIDOwnerDestination];
                        gridPositionFilter = gridPositionFilter || [data.FIDOwnerPosition];
                        var fieldDestination = <XGridSelectorFilter label="Sede" source="/IR/GetIRLicenseeDestinationsSelect"  idKey="ID" labelKey="Denomination" selectedValues={gridDestinationFilter} onChange={this.onGridDestinationFilterChange} />;
                        var fieldPosition = <XGridSelectorFilter label="Posizione" source="/mag/position/GetSelect" filters={[{ key: "FIDIdentity", value: gridDestinationFilter, type: 'array' }]}  idKey="ID" labelKey="Denomination" selectedValues={gridPositionFilter} onChange={this.onGridPositionFilterChange} />;
                        label = <Fragment><span style={style}>{label}</span><span style={style}>{fieldDestination}</span><span style={style}>{fieldPosition}</span></Fragment>
                    }
                }
                else if(data.FIDCausal){
                    url += `&FIDCausal=${data.FIDCausal}`;
                    key += `&FIDCausal=${data.FIDCausal}`;
                } 
                if(this.state.bopsSearchTerm){
                    url += `&SearchTerm=${this.state.bopsSearchTerm}`;
                    key += `&SearchTerm=${this.state.bopsSearchTerm}`;
                }
                if(!editRow && !addRows && !addNewRow){
                    actions.push({
                        group: 'actions',
                        name: 'Cerca',
                        icon:<Magnify />,
                        action: this.search,
                        condition: r => r.length === 0
                    })
                }
                if(data.FIDCausal !== 6){
                    actions.push({
                        group: 'actions',
                        name: 'Aggiungi',
                        icon: <Check />,
                        action: this.addRows,
                        condition: r => r.length > 0,
                    });
                }
                else {
                    handleNewRow = undefined;
                }
                
                actions.push({
                    group: 'actions',
                    name: 'Modifica',
                    icon: <Pencil />,
                    action: this.editRows,
                    condition: r => r.length === 1,
                });
                if(this.state.showSearch && !addRows && !editRow && !addNewRow){
                    var result = getBOPSearchContent(this.bopsSearch, "all", this.state.bopSearchValue, this.onSearchBlur, this.onSearchKeyDown, this.onSearchChange, this.onSearchCancel, this.onSearchConfirm)
                    customContent = result.customContent;
                    actions = result.actions;
                    selectedActions=undefined;
                    label = undefined;
                    deleteUrl = undefined;
                    handleEditRow = undefined;
                    handleDoubleClick = undefined;
                    showColumnsSelector = false;
                    showFilterActivator = false;
                    handleNewRow = undefined;
                    showChangeView = false;
                }
                break;
            case "DOCS":
                gridRef = this.grdDOCProcessRows;
                deleteUrl = undefined;
                handleEditRow = undefined;
                gridName = "tblDOCProcessRows";
                url = `/DOC/GetDOCRowsToProcess/${data.FIDDocumentSubtype}?grid=${gridName}`;

                handleDoubleClick = this.editRowsFromDocDblClk;

                if(data.FIDCausal === 38) {
                    var style= {
                        display: 'inline-block',
                        verticalAlign: 'top'
                    };
                    var fieldShowAll = <XStepper label="Anagrafica" step={docIRFilter} steps={[{label:"Assegnata"},{label:"Non assegnata"},{label: "Tutti"}]} onStepClick={this.onDOCIRFilterChange} />;
                    label = <Fragment><span style={style}>{label}</span><span style={style}>{fieldShowAll}</span></Fragment>
                }
                if(data.FIDCausal === 20 || data.FIDCausal === 104){ // carico o evasione ordine
                    url += `&Context=2`;
                }

                actions.push({
                    group: 'actions',
                    name: 'Aggiungi',
                    icon: <Check />,
                    action: this.addRowsFromDoc,
                    condition: r => r.length > 0,
                });
                
                actions.push({
                    group: 'actions',
                    name: 'Modifica',
                    icon: <Pencil />,
                    action: this.editRowsFromDoc,
                    condition: r => r.length === 1,
                });
                
                actions.push({
                    group: 'actions',
                    name: 'Completa',
                    icon: <FileCheckOutline />,
                    action: this.completeRowsFromDOc,
                    condition: r => {
                        if(r.length > 0){
                            for(var i in r) {
                                var ID = r[i];
                                var data = this.grdDOCProcessRows.current.getWrappedInstance().getRowData(ID);
                                if(!data || data.FIDTypology !== this.props.params.ServiceTypologyId) {
                                    return false;
                                }
                            }
                            return true;
                        }
                        return false;
                        //r.length > 0,   
                    }
                });
                key = gridName;
                var filterIR = true;
                if(data.FIDCausal === 38) {
                    if(this.state.docIRFilter === 0){
                        if (data.FIDIdentity) {
                            url += "&FIDIdentity=" + data.FIDIdentity;
                            key += "&FIDIdentity=" + data.FIDIdentity;
                            if(data.FIDIRRelation){
                                url += "&FIDIRRelation=" + data.FIDIRRelation;
                                key += "&FIDIRRelation=" + data.FIDIRRelation;
                            }
                        }
                        else {
                            url+= "&WithIR=true";
                            key+= "&WithIR=true";
                        }
                    }
                    else if(this.state.docIRFilter === 1) {
                        url+= "&WithoutIR=true";
                        key+= "&WithoutIR=true";
                    }
                }
                else if(data.FIDCausal === 20 || data.FIDCausal === 104){//carico o evasione ordine

                }
                else {
                    if (data.FIDIdentity) {
                        url += "&FIDIdentity=" + data.FIDIdentity;
                        key += "&FIDIdentity=" + data.FIDIdentity;
                        if(data.FIDIRRelation){
                            url += "&FIDIRRelation=" + data.FIDIRRelation;
                            key += "&FIDIRRelation=" + data.FIDIRRelation;
                        }
                    }
					
					if (data.FIDDestination) {
						url += "&FIDDestination=" + data.FIDDestination;
                        key += "&FIDDestination=" + data.FIDDestination;
					}
                }
                if (data.FIDCycle) {
                    url += "&FIDCycle=" + data.FIDCycle;
                    key += "&FIDCycle=" + data.FIDCycle;
                }
				if (data.FIDDocumentType) {
					url += "&FIDDocumentType=" + data.FIDDocumentType;
					key += "&FIDDocumentType=" + data.FIDDocumentType;
				}
                if (data.FIDCausal) {
                    url += "&FIDDocumentCausal=" + data.FIDCausal;
                    key += "&FIDDocumentCausal=" + data.FIDCausal;
                }
                if(data.FIDOwner) {
                    url += "&FIDOwner=" + data.FIDOwner;
                    key += "&FIDOwner=" + data.FIDOwner;
                }
                if(data.FIDOwnerDestination) {
                    url += "&FIDOwnerDestination=" + data.FIDOwnerDestination;
                    key += "&FIDOwnerDestination=" + data.FIDOwnerDestination;
                }
                break;
            case "ROWS":
                deleteUrl = `/DOC/DeleteRows/${FIDDOC}?FIDIdentity=${data.FIDIdentity}`;
                if(data.FIDCausal === 6){
                    handleNewRow = undefined;
                }
                break;
        }

        if (showImportFileUpload) {
            var importRowsContent = (
                <Fragment>
                <Grid container spacing={2}>
                    <input type="file" name="" id="" onChange={this.handleImportFileChange} style={{paddingTop:"15px"}}/>
                    <XButton variant="container" onClick={this.handleImportFileUpload} disabled={!this.state.selectedImportFile}>Upload</XButton>
                    <div>{this.state.importFileLoaded > 0 && this.state.importFileLoaded < 100 && "Caricamento..."}</div>
                </Grid>
                </Fragment>
            );
            var panels = [{
                icon: <FileExcelBox />,
                label: "Importa righe da Excel",
                content: importRowsContent
            }]
            customContent = (<XExpansionPanel panels={panels} />);
            actions = [{
                group: 'actions',
                name: 'Annulla',
                icon: <ArrowLeft />,
                action: this.handleImportFileCancel
            }];
            handleNewRow=undefined;
            handleDoubleClick = undefined;
            deleteUrl = undefined;
            handleEditRow = undefined;
            selectedActions =[];
            showChangeView = false;
            showColumnsSelector = false;
            showFilterActivator = false;
            label = <span></span>;
        }

        if (addNewRow) {
            
        }

        if(savingRow){
            customContent = <XOXLoader />;
            actions = [];
            handleNewRow=undefined;
            handleDoubleClick = undefined;
            deleteUrl = undefined;
            handleEditRow = undefined;
            selectedActions =[];
            showChangeView = false;
            showColumnsSelector = false;
            showFilterActivator = false;
            label = <span></span>;
        }
        return (
            <XGrid
                containerID="pnlRows"
                key={key}
                ref={gridRef}
                dataRoute={url}
                dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                dataRouteDelete={deleteUrl}
                onEditButton={handleEditRow}
                selectedActions={selectedActions}
                customContent={customContent}
                showColumnsSelector={showColumnsSelector}
                showFilterActivator={showFilterActivator}
                showLoader
                actions={actions}
                onNewButton={handleNewRow}
                onDoubleClick={handleDoubleClick}
                //rowsVisible={window.innerHeight > 900 ? 15 : 10}
                cardWidth={4}
                cardHeight={4.5}
				showImgColumn={true}
                showChangeView={showChangeView}
                label={label}
                afterDelete={this.afterDelete}
                customStyleFunction={customStyle}
                onFinishLoad={this.onGridLoaded}
								displayTotalCount
            />
        );
    }
    onGridLoaded = (json) => {
        if(this.state.openIfOnlyOne){
            if(json.count === 1){
                this.getRowData(json.data[0].ID).then(data => {
                    this.setState({ addRows: true, selectedData: data, showSearch: true });
                });
                
                this.setState({openIfOnlyOne: false, bopsSearchTerm: undefined});
            }
            else if(json.count === 0){
                this.MessageBox.current && this.MessageBox.current.getWrappedInstance() && this.MessageBox.current.getWrappedInstance().showMessageOk("Ricerca","Non è stato trovato nessun prodotto con il termine indicato");
                this.setState({openIfOnlyOne: false, bopsSearchTerm: undefined});
            }
            else {
                this.setState({openIfOnlyOne: false});
            }
        }
    }
    onprintReceipt = () => {
        this.setState({printReceipt: true});
    }
    onPrint = () => {
        this.setState({print: true});
    }
    onPrintConfirm = async () => {
        // var result = await this.printReceipt.current.printReceipt();
        // if(result){
        //     this.setState({printReceipt: false});
        // }
        
        this.setState({printingReceipt: true});
        var {FIDDocument,FIDCashRegister,GiftReceipt} = this.printReceipt.current.getInfo();
        //this.props.printReceipt(FIDDocument,FIDCashRegister,GiftReceipt);
    }
    cancelprintReceipt = () => {
        this.setState({printReceipt: false});
    }
    cancelPrint = () => {
        
        this.setState({print: false});
    }
    renderTabRows = () => {
        //var { showImportFileUpload } = this.state;
        var panels = [];
        panels.push({
           icon: "code",
           label: "Parametri",
           content: this.renderTabRowsPanelDefaults(),
        });
        //if (showImportFileUpload) {
        //    panels.push({
        //        icon: <FileExcelBox />,
        //        label: "Importa righe da Excel",
        //        content: this.renderTabRowsImport(),
        //    })
        //}

        var ui = this.state.data.FIDCycle !== this.props.params.InternalCycleId && !this.state.addRows && !this.state.editRow && <XExpansionPanel panels={panels} />;
        var deltaH = 172;
        if(this.state.data.FIDCycle !== this.props.params.InternalCycleId && !this.state.addRows && !this.state.editRow) {
            deltaH += 183;
            //deltaH = 421;
        }
        return (
            <TabContainer id="pnlRows" style={{height: `calc(100vh - ${deltaH}px)`}}>
                {ui}
                {this.renderPanelRows()}
                <XProgress progress={this.state.progress} total={this.state.total} label="Importazione righe documento" show={this.state.isImportingRows} />
            </TabContainer>
        );    
	}
	
	renderPanelTransport = () => {
		var { data } = this.state;
		
		var identityVectorField = getXSelect(data, defaultFields, 12, 6, "FIDIdentityVector", "Anagrafica vettore", this.handleSelectChange, "/IR/GetIRSelect" );
		var transportPortField = getXSelect(data, defaultFields, 12, 3, "FIDTransportPort", "Porto", this.handleSelectChange, "/gc/transportport", "/gc/transportport" );
		var transportGoodsLookField = getXSelect(data, defaultFields, 12, 3, "FIDTransportGoodsLook", "Aspetto beni", this.handleSelectChange, "/gc/transportgoodslook", "/gc/transportgoodslook" );
		
		var transportReferenceField = getXField(data, defaultFields, 12, 4, "TransportRef", "Riferimento trasporto", this.handleFieldChange);
		var transportNoteField = getXField(data, defaultFields, 12, 8, "TransportNote", "Annotazioni trasporto", this.handleFieldChange);
		
		var transportPackagesField = getXField(data, defaultFields, 12, 3, "TransportPackages", "Numero colli", this.handleFieldChange, { type: "number",decimalScale: 0 });
		var transportSizeField = getXField(data, defaultFields, 12, 3, "TransportSize", "Misura", this.handleFieldChange );
		var transportWeightField = getXField(data, defaultFields, 12, 3, "TransportWeight", "Peso lordo", this.handleFieldChange);
		var transportDatetimeField = getXDateTimeField(data, defaultFields, 12, 3, "TransportDatetime", "Data/ora trasporto", this.handleDataFieldChange);
		
		return (
			<Grid container spacing={2} direction="row">
				{identityVectorField}{transportPortField}{transportGoodsLookField}
				{transportReferenceField}{transportNoteField}
				{transportPackagesField}{transportSizeField} 
				{transportWeightField}{transportDatetimeField}
			</Grid>
		);
    }
    
    onIntentDeclarationNewRow = () => {
        this.setState({editIntentDeclaration: true, intentDeclaration:{FIDIntentDeclaration: null, Amount: null}});
    }
    
    onIntentDeclarationEditRow = (event, data) => {
        this.grdIntentDeclarations.current.clearSelection();
        this.setState({ editIntentDeclaration: true, intentDeclaration: data });
    }
    onCancelIntentDeclarationEditRow = () => {
        this.setState({editIntentDeclaration: false, intentDeclaration:{FIDIntentDeclaration: null, Amount: null}});
    }
    handleIntentSelectChange = (key) => (event,value) => {
        if(value.length > 0){
            this.handleIntentChange(key,value[0].ID);
        }
        else{
            this.handleIntentChange(key,undefined);
        }
    }
    handleIntentFieldChange = (key) => (event, value) => {
        this.handleIntentChange(key,value);
    }

    handleIntentChange = (key,value) => {
        this.setState(state => {
            state.intentDeclaration[key] = value;
            return state;
        });
    }
	renderPanelIntentDeclaration = () => {
        var { data,editIntentDeclaration,intentDeclaration } = this.state;
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }
        var customContent = undefined;
		
        
        var gridName = "tblIntentDeclarations";
        var url = `/DOC/GetDOCIntentDeclarations/${FIDDOC}?grid=${gridName}`;
        var deleteUrl = undefined;//`/DOC/DeleteIntentDeclaration/${FIDDOC}`;
        var key = `${gridName}`;

        var handleEditRow = this.onIntentDeclarationEditRow;
        var handleNewRow = this.onIntentDeclarationNewRow;
        var handleDoubleClick = this.onIntentDeclarationEditRow;
        var showColumnsSelector = true;
        var showFilterActivator = true;
        var actions = [];
        
        handleEditRow = undefined;
        handleNewRow = undefined;
        handleDoubleClick = undefined;
        
		// if(editIntentDeclaration){
        //     var fieldIntentDeclaration = getXSelect(intentDeclaration, IntentDeclarationFields, 12, 4, "FIDIntentDeclaration", "Dichiarazione d'intento", this.handleIntentSelectChange);
        //     var fieldAmount = getXField(intentDeclaration, IntentDeclarationFields, 12, 4, "Amount", "Importo", this.handleIntentFieldChange);
            
        //     customContent = (
        //         <div style={{padding:8, width: '100%' }}>
        //             <Grid container spacing={2} direction="row">
        //                 {fieldIntentDeclaration}{fieldAmount}
        //             </Grid>
        //         </div>
        //     );

        //     showColumnsSelector = false;
        //     showFilterActivator = false;

        //     actions = [{
        //         group: 'actions',
        //         name:'Indietro',
        //         icon: <ArrowLeft />,
        //         action: this.onCancelEditRow,
        //     }]
        // }



        return <XGrid 
            innerRef={this.grdIntentDeclarations}
            containerID="intentDeclarations"
            key={key}
            dataRoute={url}
            dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
            dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
            dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
            dataRouteDelete={deleteUrl}
            onEditButton={handleEditRow}
            onNewButton={handleNewRow}
            onDoubleClick={handleDoubleClick}
            showColumnsSelector={showColumnsSelector}
            showFilterActivator={showFilterActivator}
            showLoader
            customContent={customContent}
            actions={actions}
            //label={label}
        />
    }
    onPaymentStepChange = (step) => {
        this.setState({ paymentStep: step});
    }
    
    onPaymentBack = () => {
        if(!this.CashPayment.current.onBack()){
            this.setState({ paymentStep: 0});
        }
    }
    onPaymentConfirm = () => {
        this.CashPayment.current && this.CashPayment.current.onCofirmPayment();
    }
	renderTabDueDate = () => {
        return <TabContainer>
            <CashPayment innerRef={this.CashPayment} doc={this.state.data} onStepChange={this.onPaymentStepChange} />
        </TabContainer>
    }
	renderTabTransport = () => {	
		var panels = [];
        panels.push({
            icon: <TruckDeliveryOutline />,
            label: "Dati trasporto",
            content: this.renderPanelTransport(),
        });

        var ui = <XExpansionPanel panels={panels} />;
        return (
            <TabContainer>
                {ui}
            </TabContainer>
        );
    }
    renderTabIntentDeclarations = () => {	
		// var panels = [];
        // panels.push({
        //     icon: <TruckDeliveryOutline />,
        //     label: "Dati dichiarazione di intento",
        //     content: this.renderPanelIntentDeclaration(),
        // });

        //var ui = <XExpansionPanel panels={panels} />;
        var ui = this.renderPanelIntentDeclaration();
        var deltaH = 172;
        return (
            <TabContainer id={"intentDeclarations"} style={{height: `calc(100vh - ${deltaH}px)`}}>
                {ui}
            </TabContainer>
        );
    }
	
    renderTabs = () => {
        var { data, paymentStep } = this.state;
        var tabs = [];

        var actionDDT = {
            group: 'actions',
            name:'Genera DDT',
            icon: <AlphaDcircle />,
            action: this.onDDTDocument,
        };
        var actionFT = {
            group: 'actions',
            name:'Genera Fattura',
            icon: <AlphaFcircle />,
            action: this.onFTDocument,
        };
        var actionRendi = {
            group: 'actions',
            name:'Rendi',
            icon: <RedoVariant />,
            action: this.onReturnDocument,
        };
        var actionConfirm = {
            group: 'Conferma',
            name:'Rendi',
            icon: <Check />,
            action: this.onConfirm,
        };
        var actionprintReceipt = {
            group: 'actions',
            name:'Stampa scontrino',
            icon: <PrinterPos />,
            action: this.onprintReceipt,
        };
        var actionPrint = {
            group: 'actions',
            name:'Stampa',
            icon: <Printer />,
            action: this.onPrint,
        };
        var actions = [];
        
        if(data.FIDStatus != this.props.params.DOCStatusConfirmedId && data.ID > 0) {
            actions.push(actionConfirm);
        }
        else {
            if (data.FIDDocumentType === 2 && data.FIDDocumentSubtype === 3 && data.ID > 0) {// ordine
                //actions.push(actionDDT);
                //actions.push(actionFT);
            }
            if (data.FIDDocumentType === 3 && data.FIDDocumentSubtype === 4 && data.ID > 0 && data.FIDCausal != 40) {// DDT
                actions.push(actionFT);
            }
        }
        if(data.FIDDocumentType === 3 && data.FIDDocumentSubtype === 7 && data.ID  > 0){//cassa
            actions.push(actionprintReceipt);
        }
        else if (data.ID > 0){
            actions.push(actionPrint);
        }
        tabs.push(
            {
                tabName: "Generale",
                tabContent: this.renderTabGeneral(),
                actions: actions
            }
        );
        tabs.push(
            {
                tabName: "Righe",
                tabContent: this.renderTabRows(),
                actions: actions
            }
        );
		
		if (data.FIDCycle === 2 && data.FIDDocumentType === 3 && (data.FIDDocumentSubtype === 4 || data.FIDDocumentSubtype === 5)) {
			tabs.push(
				{
					tabName: "Trasporto",
					tabContent: this.renderTabTransport()
				}
			);
        }
        this.state.hasIntentDeclaration && tabs.push({
            tabName: "Dichiarazioni di intento",
            tabContent: this.renderTabIntentDeclarations()
        });

        //tab scadenze
        var dueDateActions = [];
        if(paymentStep > 1){
            dueDateActions = [];

            var actionPaymentBack = 
            {
                group: 'actions',
                name: 'Indietro',
                icon: <ArrowLeft />,
                action: this.onPaymentBack
            };
            var actionPaymentConfirm = 
            {
                group: 'actions',
                name: 'Conferma pagamento',
                icon: <Check />,
                action: this.onPaymentConfirm
            };

            if(paymentStep === 3){
                dueDateActions.push(actionPaymentConfirm);
            }
            if(paymentStep > 1){ 
                dueDateActions.push(actionPaymentBack);
            }
        }
        if(data.FIDDocumentType === 3 && data.FIDDocumentSubtype === 5) {
            tabs.push({
                tabName: "Scadenze",
                tabContent: this.renderTabDueDate(),
                actions: dueDateActions
            });
        }

        return tabs;
    }

    onDeleteRow = (id)  => () => {
        this.MessageBox.current && this.MessageBox.current.getWrappedInstance() && this.MessageBox.current.getWrappedInstance().showMessageYesNo("CANCELLAZIONE.", "Confermi la cancellazione della riga", () => {
            this.doDeleteSingleRow(id)
        });
    }
    doDeleteSingleRow = (id) => {
        var FIDDOC = this.props.match.params.id;
        if (!FIDDOC) {
            FIDDOC = 0;
        }
        this.setState({savingRow: true, editRow: false, addNewRow: false, rowData: {} }, () => {
            fetch(`/DOC/DeleteRows/${FIDDOC}?FIDIdentity=${this.state.data.FIDIdentity}`, {
                body: JSON.stringify([id]),
                method: 'POST'
            }).then(res => {
                this.setState({savingRow: false},async() =>{
                    this.grdDOCRows.current && this.grdDOCRows.current.getWrappedInstance().refresh();
                    this.grdDOCBops.current && this.grdDOCBops.current.getWrappedInstance().refresh();
                    this.grdDOCProcessRows.current && this.grdDOCProcessRows.current.getWrappedInstance().refresh();
                    this.documentHasRows();
                    if(this.props.params.DocumentAutosave){
                        await this.doSaveData();
                    }
                });
                // this.refresh();
                // afterDelete && afterDelete();
            });
        });
    }

    renderNewBOP = () => {
        var { data } = this.state;
        return (<BOPEdit showSupplierSN={true} onExit={(bopID, supplierSN) => {
            if (bopID > 0) {
                this.setState({savingRow : true}, async () => {
                    var added = 0;
                    var dataRow = await this.getRowData(bopID); //this.grdDOCBops.current.getWrappedInstance().getRowData(selectedRows[i]);
                    if(data.FIDCausal === 6) {//invetario
                        dataRow.Qty1 = dataRow.MeasureUnitQty1;
                        dataRow.Qty2 = dataRow.MeasureUnitQty2;
                    }
                    else {
                        dataRow.Qty1 = 1;
                    }
                    dataRow.FIDBOP = dataRow.ID;
					dataRow.SupplierSN = supplierSN;
                    dataRow.ID = 0;
                    dataRow.FIDVat = data.FIDVat;
                    dataRow.VatPerc = data.VatPerc;
                    dataRow.FIDCausal = data.FIDCausal;
                    dataRow.FIDCycle = data.FIDCycle;
                    dataRow.FIDDOCRowType = 1;
                    if (!dataRow.TaxablePrice) {
                        dataRow.TaxablePrice = 0;
                    }
                    dataRow.Discount = getDiscount(dataRow.DiscountDesc) * 100;
    
                    // if (!dataRow.Discount) {
                    //     dataRow.Discount = 0;
                    // }
                    dataRow.TotalPrice = dataRow.TotalAmount;
                    if (data.FIDCycle === this.props.params.PassiveCycleId) {//passivo
                        if (dataRow.PublicPrice) {
                            //dataRow.SellPrice = dataRow.PublicPrice;
                            dataRow.RetailDiscount = dataRow.SupplierDiscount;
                            dataRow.RetailDiscountDesc = dataRow.SupplierDiscountDesc;
                            dataRow.TaxablePrice = dataRow.SupplierCost;
                        }
                        else {
                            dataRow.SellPrice = dataRow.TaxablePrice;
                            dataRow.RetailDiscount = this.state.IdentityDiscount;
                            dataRow.TaxablePrice = (dataRow.SellPrice * (100 - dataRow.RetailDiscount) / 100).toFixed(2);
                        }
                        if(!dataRow.TaxablePrice){
                            dataRow.TaxablePrice = 0;
                        }
                        dataRow.TotalPrice = (dataRow.TaxablePrice * (100 + dataRow.VatPerc) / 100).toFixed(2);
                        if(!dataRow.TotalPrice){
                            dataRow.TotalPrice = 0;
                        }
                        dataRow.DiscountedTaxablePrice = (dataRow.TaxablePrice * (100 - dataRow.Discount) / 100).toFixed(2);
                        dataRow.DiscountedTotalPrice = (dataRow.TotalPrice * (100 - dataRow.Discount) / 100).toFixed(2);
                    }
    
                    if (dataRow.MeasureUnitQty2) {
                        dataRow.Qty2 = dataRow.MeasureUnitQty2 * dataRow.Qty1 / dataRow.MeasureUnitQty1;
                    }
    
    
                    dataRow.TotalTaxableAmount = (dataRow.TaxablePrice * dataRow.Qty1).toFixed(2);
                    dataRow.TotalAmount = (dataRow.TotalPrice * dataRow.Qty1).toFixed(2);
                    dataRow.TotalDiscountedTaxableAmount = (dataRow.DiscountedTaxablePrice * dataRow.Qty1).toFixed(2);
                    dataRow.TotalDiscountedAmount = (dataRow.DiscountedTotalPrice * dataRow.Qty1).toFixed(2);
                    //console.log(dataRow);
                    var FIDDOC = parseInt(this.props.match.params.id, 10);
                    if(!FIDDOC){
                        FIDDOC = 0;
                    }

                    var result = await fetch(`/DOC/SaveDocumentRow/${FIDDOC}?FIDIdentity=${data.FIDIdentity}`, {
                        method: 'POST',
                        body: JSON.stringify(dataRow)
                    }).then(handleErrors).then(res => res.json()).catch(error => {
                        console.log(error);
                    });
                    this.documentHasRows();
                    if(result){
                        added++;
                    }
                    var msg = `Aggiunta una distinta al documento`;
                    
                    if (added === 0) {
                        msg = `Nessuna distinta aggiunta al documento`;
                    }
                
                    this.grdDOCBops.current && this.grdDOCBops.current.getWrappedInstance().refresh();
                    this.grdDOCBops.current && this.grdDOCBops.current.getWrappedInstance().clearSelection();
                
                    this.setState({ initialTab: 1, viewRows: "ROWS", savingRow: false, selectedRows: [], addRows: false, addNewRow: false,  newBop: false, snackbar: { open: true, message: msg } });
                    if(this.props.params.DocumentAutosave){
                        await this.doSaveData();
                    }
                });
            }
            else {
                this.setState({ initialTab: 1, savingRow: false, selectedRows: [], addRows: false, addNewRow: false, newBop: false });
            }
			this.setDocTitleTitle();
        }} />);
    }
    handleNewRowTypeChange = (id) => (event, data) => {
        this.setState({ newRowType: data[0].ID }) 
    }
    renderChooseNewRowType = (customContent, actions) => {
        var { newRowType } = this.state;
        var rowTypeComboData = [
            {
                ID: 1,
                Abbreviation: "",
                Denomination: "Distinta"
            },
            {
                ID: 6,
                Abbreviation: "",
                Denomination: "Descrizione"
            }
        ]
        var addNewRowContent = (
            <Grid container><Grid item xs={12} md={4}><XSelect field={{ Required: 2 }} id="newRowType" idKey="ID" valueKey="Denomination" value={newRowType} customData={rowTypeComboData} label="Tipo riga" onChange={this.handleNewRowTypeChange} /></Grid></Grid>
        );

        var panels = [{
            icon: <TextBox />,
            label: "Scegli tipo riga",
            content: addNewRowContent
        }];

        customContent = (<XExpansionPanel panels={panels} />);
        actions = [
            {
                group: 'actions',
                name: 'Avanti',
                icon: <ArrowRight />,
                action: newRowType === 1 ? () => { this.setState({ newBop: true }) } : () => { this.setState({ addNewRow: false, addRows: true, rowData: {}, selectedData: {} }) }
            },
            {
                group: 'actions',
                name: 'Annulla',
                icon: <ArrowLeft />,
                action: () => { this.setState({ addNewRow: false }) }
            }
        ];

        return { customContent, actions };
    }

    renderEditor(addRows, handleNewRow, rowData, customContent, onSave, onCancel) {
        var { data } = this.state;
        var actions = [];
        if (addRows) {
            handleNewRow = undefined;
            customContent = <DocumentRowEditor innerRef={this.DocumentRowEditor} data={rowData} onSave={onSave} onCancel={onCancel} FIDDOCIdentity={data.FIDIdentity} FIDCycle={data.FIDCycle} FIDDocumentType={data.FIDDocumentType} FIDDocumentSubtype={data.FIDDocumentSubtype} FIDCausal={data.FIDCausal} FIDPricelist={data.FIDPricelist} FIDVat={data.FIDVat} DiscountDesc={data.DiscountDesc} />;
            actions = [{
                group: 'actions',
                name: 'Indietro',
                action: onCancel,
                icon: <ArrowLeft />
            },{
                group: 'actions',
                name: 'Salva',
                action: onSave,
                icon: <Check />
            }];
            if(rowData.FIDBOP && rowData.ID){
                actions.push({
                    group: 'actions',
                    name: 'Elimina',
                    action: this.onDeleteRow(rowData.ID),
                    icon: <DeleteIcon />
                });
            }
            actions.reverse();
        }
        return { handleNewRow, rowData, customContent, actions };
    }
    validateTabGeneral = (callback) => {
        var fields = defaultFields;
        var { data } = this.state;
        var isValid = true;
        var requiredFields = [];
        var optionalFields = [];
        for (var i = 0; i < fields.length; i++) {
            var field = fields[i];
            if ((field.Required === 2 || (field.Required === 1 && !field.Validated)) && (!data[field.Field] || data[field.Field].length === 0)) {
                console.log(data, data[field.Field], field);
                isValid = false;
                if (field.Required === 1) {
                    fields[i].Validated = true;
                    //this.setState((state, props) => {
                    //    state.fields[i].Validated = true;
                    //    return state;
                    //});
                    optionalFields.push(field.Field);
                }
                else {
                    requiredFields.push(field.Field);
                }
            }
        }
        if (!isValid) {
            this.showMessage(requiredFields,optionalFields,callback,fields);
            // var msg = (
            //     <Fragment>
            //         {requiredFields.length > 0 && (<Typography variant="subtitle2">Campi obbligatori:</Typography>)}
            //         {requiredFields.map((item, index) => (<Typography key={index}>{FieldLabels[item]}</Typography>))}
            //         {optionalFields.length > 0 && (<Typography variant="subtitle2">Campi raccomandati (opzionali):</Typography>)}
            //         {optionalFields.map((item, index) => (<Typography key={index}>{FieldLabels[item]}</Typography>))}

            //         {requiredFields.length == 0 && optionalFields.length > 0 && (<Typography variant="subtitle2">Procedere senza compilare questi campi?</Typography>)}

            //         {requiredFields.length > 0 && (<Typography variant="subtitle2">Compilare i campi obbligatori prima di procedere.</Typography>)}

            //     </Fragment>
            // );
            // if (requiredFields.length > 0) {
            //     this.MessageBox.current.showMessageOk("Alcuni campi non sono stati compilati.", msg);
            // }
            // else {
            //     this.MessageBox.current.showMessageYesNo("Alcuni campi non sono stati compilati.", msg, () => {
            //         callback();
            //         for (var i = 0; i < fields.length; i++) {
            //             fields[i].Validated = false;
            //         }
            //     });
            // }
        }
        else {
            for (var i = 0; i < fields.length; i++) {
                fields[i].Validated = false;
            }
        }
        return isValid;
    }
    showMessage = (requiredFields,optionalFields, callback,fields, extraMessages) =>{
        if(!extraMessages){
            extraMessages =[];
        }
        var msg = (
            <Fragment>
                {requiredFields.length > 0 && (<Typography variant="subtitle2">Campi obbligatori:</Typography>)}
                {requiredFields.map((item, index) => (<Typography key={index}>{FieldLabels[item] || item}</Typography>))}
                {optionalFields.length > 0 && (<Typography variant="subtitle2">Campi raccomandati (opzionali):</Typography>)}
                {optionalFields.map((item, index) => (<Typography key={index}>{FieldLabels[item] || item}</Typography>))}

                {requiredFields.length == 0 && optionalFields.length > 0 && (<Typography variant="subtitle2">Procedere senza compilare questi campi?</Typography>)}

                {requiredFields.length > 0 && (<Typography variant="subtitle2">Compilare i campi obbligatori prima di procedere.</Typography>)}

                {extraMessages.map((item,index) => <Typography key={index}>{item}</Typography>)}
            </Fragment>
        );
        if (requiredFields.length > 0 || extraMessages.length > 0) {
            this.MessageBox.current && this.MessageBox.current.getWrappedInstance() && this.MessageBox.current.getWrappedInstance().showMessageOk("Alcuni campi non sono stati compilati.", msg);
        }
        else {
            this.MessageBox.current && this.MessageBox.current.getWrappedInstance() && this.MessageBox.current.getWrappedInstance().showMessageYesNo("Alcuni campi non sono stati compilati.", msg, () => {
                callback();
                for (var i = 0; i < fields.length; i++) {
                    fields[i].Validated = false;
                }
            });
        }
    }
    validate = (tab, newTab, callback) => {
        if (tab !== 0) {
            return true;
        }
        return this.validateTabGeneral(callback);
    }
    snackbarClose = () => {
        this.setState(state => {
            state.snackbar.open = false;
            return state;
        });
    }
    renderSnackbar() {
        var { snackbar } = this.state;
        return <Snackbar
            open={snackbar.open}
            onClose={this.snackbarClose}
            TransitionComponent={props => <Slide {...props} direction="up" />}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{snackbar.message}</span>}
            autoHideDuration={1500}
        />;
    }

    render() {
        if (this.state.isLoading) {
            //return (<div>Loading...</div>);
            var { progress, total, isSaving } = this.state;
            var addProgressLabel = "";
            if (isSaving) {
                if (progress && total)
                    addProgressLabel = ": riga " + progress + "/" + total; 
            }
            //return <XOXLoader />
            return <XGrid usePrimaryColor={true}
                customContent={(<Fragment><XOXLoader /><XProgress progress={progress} total={total} label={"Salvataggio documento"+addProgressLabel} show={isSaving && total} /></Fragment>)}
            />;
        }
        var { classes } = this.props;
        var { data,printReceipt, printingReceipt, print, showAmounts, hideDiscounts } = this.state;
        var initialTab = this.state.initialTab;
        if(data.HasRows && !!data.Number && !this.state.preventTabChange){
            initialTab = 1;
        }

        if (this.state.newBop)
            return this.renderNewBOP();



        var customContent = undefined;
        var actions = undefined;
        var label = undefined;
        if(printReceipt){
            customContent= <PrintReceipt innerRef={this.printReceipt} FIDLicensee={data.FIDOwner} FIDDestination={data.FIDOwnerDestination} FIDDocument={data.ID}  printing={printingReceipt}/>;
            actions=[ {
                    group: 'actions',
                    name: 'Indietro',
                    action: this.cancelprintReceipt,
                    icon: <ArrowLeft />
                },{
                    group: 'actions',
                    name: 'Conferma',
                    action: this.onPrintConfirm,
                    icon: <PrinterPos />
                }
            ];
            label="Stampa scontrino";
        }
        if(print){
            var key = `ID=${data.ID}&showAmounts=${showAmounts}`;
			var printUrl = `/DOC/PrintDocument/?ID=${data.ID}&showAmounts=${showAmounts}`;
			
			if (showAmounts) {
				printUrl += `&hideDiscounts=${hideDiscounts}`
			}
			
            customContent = <XPDFViewer key={key} url={printUrl}  width="100%" height={document.body.offsetHeight - 151} />;
            actions=[ {
                    group: 'actions',
                    name: 'Indietro',
                    action: this.cancelPrint,
                    icon: <ArrowLeft />
                }
            ];
            label=<Grid item xs={12} container>
                <Grid item xs={12} container direction="row" spacing={2}>
                    <Grid item xs={12} md={3}><XSwitch label="Mostra importi" color="default" id="ShowAmounts" value={showAmounts} onChange={this.onUpdateShowAmounts} /></Grid>
					{showAmounts ? <Grid item xs={12} md={3}><XSwitch label="Nascondi sconti" color="default" id="HideDiscounts" value={hideDiscounts} onChange={this.onUpdateHideDiscounts} /></Grid> : <Fragment />}
                </Grid>
            </Grid>;
        }

        return (
            <div className={classes.root}>
                <Prompt where={true} message={this.handlePageExit} />
                {!customContent && <Tabs
                    innerRef={this.tabs}
                    headerColor="primary"
                    plainTabs={true}
                    onExitClick={this.handleExit}
                    onSaveClick={this.handleSaveData}
                    tabs={this.renderTabs()}
                    validateTab={this.validate}
                    initialTab={initialTab}
                />}
                {customContent && <XGrid usePrimaryColor={true}
                    customContent={customContent}
                    actions={actions}
                    label={label}
                />}
                <XMessageBox ref={this.MessageBox} />
                {this.renderSnackbar()}
            </div>
        );
    }
}




const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(DOCEdit);