// ##############################
// // // Footer styles
// #############################

import {
  defaultFont,
  container,
  containerFluid,
} from "assets/jss/material-dashboard-pro-react.jsx";

const footerStyle = theme => ({
    block: {},
    left: {
        float: "left!important",
        display: "block"
    },
    right: {
        margin: "0",
        fontSize: 13,
        //float: "right!important",
        padding: "15px",
        position: 'absolute',
        display: 'block',
        top: '50%',
        right: 10,
        transform: 'translateY(-50%)',
        color: theme.palette.primary.contrastText
    },
    img: {
        height: 30,
    },
    footer: {
        bottom: "0",
        //borderTop: "1px solid #e7e7e7",
        padding: "0",
        ...defaultFont,
        zIndex: 1100,
        height: 32,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: "0px -2px 4px -1px rgba(0,0,0,0.2), 0px -4px 5px 0px rgba(0,0,0,0.14), 0px -1px 10px 0px rgba(0,0,0,0.12)",
        position:"relative",
    },
    container: {
        zIndex: 3,
        ...container,
        position: "relative"
    },
    containerFluid: {
        zIndex: 3,
        ...containerFluid,
        position: "relative",
        height: '100%',
    },
    a: {
        color: theme.palette.primary.contrastText,
        textDecoration: "none",
        backgroundColor: "transparent"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0",
        width: "auto"
    },
    whiteColor: {
        "&,&:hover,&:focus": {
            color: "#FFFFFF"
        }
    }
});
export default footerStyle;
