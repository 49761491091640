import React from 'react';
import { withTheme, makeStyles,Tooltip, IconButton } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';


const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.80)',
        marginBottom: 15,
    },
    tooltip: {
        position: 'absolute',
        fontSize: 8,
        width: '100%',
        bottom: -18,
        textTransform: 'uppercase',
        fontWeight: 800
    }
}));


const XRoundButton = (props) => {
    const classes = useStyles(props);
    const { toolTip, placement, children, onClick} = props;
    // return <Tooltip title={toolTip} placement={placement}>
    //     <IconButton onClick={onClick} className={classes.root}>{children}</IconButton>
    // </Tooltip>;
    return <>
        <IconButton onClick={onClick} className={classes.root}>
            {children}
            <span className={classes.tooltip}>{toolTip}</span>
        </IconButton>
    </>
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withTheme
);
export default enhance(XRoundButton);