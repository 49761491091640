import React, { useRef, useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Message from './Message';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Send from '@material-ui/icons/Send';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { createStyles, makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) =>
    createStyles({
        conversation: props => {
            return ({
                width: '300px',
                height: '450px',
                maxHeight: '450px',
                position: 'absolute',
                bottom: '0',
                right: (300 * props.position) || 0,
                zIndex: '5',
                flexDirection: 'column',
                boxSizing: 'border-box',
                transition: 'height 1s',
                overflow: 'hidden',
            });
        },
        messageList: {
            overflowY: 'auto',
            overflowX: 'hidden',
            scrollBehavior: 'smooth',
            boxSizing: 'content-box',
            marginBottom: '3px',
            width: '100%',
            height: '300px',
            maxHeight: '300px',
            background: 'white',
        },
        message: {
            position: 'relative',
            clear: 'both',
            padding: '0 15px',
        },
        conversationHeader: {
            backgroundColor: "#e2b231",
            height: '48px',
            display: 'grid',
            marginBottom: '2px',
            "&:hover": {
                cursor: 'pointer',
            }
        },
        conversationHeaderButton: {
            gridColumn: '1',
        },
        conversationHeaderName: {
            gridColumn: '2',
            margin: 'auto',
            alignItems: 'center',
        },
        sendFooter: {
            display: 'flex',
            height: '97px',
            bottom: '0',
        },
        sendFooterInput: {
            alignItems: 'flex-start',
            flexGrow: '1',
            borderRadius: '12px',
            resize: 'none',
            overflow: 'auto',
            height: '92px',
            maxHeight: '92px',
            marginBottom: '5px',
            lineHeight: '13px',
            padding: '9px 7px',
            marginLeft: '8px',
            marginRight: '8px',
            backgroundColor: '#f0f3f7',
            fontSize: '15px',
            fontWeight: '400',
            color: '#5a5a5a',
        },
        sendFooterButton: {
            margin: 'auto',
            maxHeight: '48px',
            alignItems: 'flex-end',
            backgroundColor: "#e2b231",
        }
    })
);

function Messages(props) {
    const classes = useStyles(props);
    const messageListRef = useRef();

    useEffect(() => {
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }, [props.messages]);

    return (<div className={classes.messageList}
        ref={messageListRef}>
        {props.messages
            && props.messages.map(message => {
                return (<div className={classes.message}
                    key={`${Math.random()}`}>
                    <Message
                        sent={message.sent}
                        content={message.message} />
                </div>)
            })
        }
    </div>)
}

export default function MessageChat(props) {
    const classes = useStyles(props);
    const [text, setText] = useState("")
    const [expanded, setExpanded] = useState(true)
    const textareaRef = useRef();

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            sendMessage(text);
            e.preventDefault();
        }
    };

    const sendMessage = (text) => {
        if (text.length > 0) {
            props.sendMessage(props.userId, text);
            setText("");
        }
        if (textareaRef.current)
            textareaRef.current.focus();
    }

    const chatRef = useRef();
    const toggleConversationSize = () => {
        chatRef.current.style.height = expanded ? '50px' : '450px'
        setExpanded(!expanded);
    }

    return (
        <div>
            <Paper className={classes.conversation}
                ref={chatRef}>
                <div className={classes.conversationHeader}
                    onClick={toggleConversationSize}>
                    <div className={classes.conversationHeaderButton}>
                        <IconButton onClick={() => { props.closeMessageChat(props.userId); }}>
                            <HighlightOff />
                        </IconButton>
                    </div>
                    <div className={classes.conversationHeaderName}>
                        <Typography variant="body1">{props.username}</Typography>
                    </div>
                </div>
                <Messages messages={props.messages} />
                <div className={classes.sendFooter}>
                    <textarea className={classes.sendFooterInput}
                        ref={textareaRef}
                        rows={1}
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Type Message"
                    />
                    <IconButton className={classes.sendFooterButton}
                        onClick={() => {
                            sendMessage(text);
                        }}>
                        <Send />
                    </IconButton>
                </div>
            </Paper>
        </div>
    )
}