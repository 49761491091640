import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
	primaryColor,
	primaryColorLight,
	colorForPrimaryBackground
} from "assets/jss/material-dashboard-pro-react.jsx";
import { Typography } from '@material-ui/core';
import { formatTime } from '../utils';

const styles = theme => ({
	timeLabelWrap: {
		width: "90%",
		margin: "auto",
		marginBottom: "10px",
		textAlign: "center",
		color: "white",
		textTransform: "uppercase"
	},
	timeLabelCaption: {
		backgroundColor: primaryColor,
		padding: "5px 0px"
	},
	timeLabelContent: {
		"& > p": {
			fontSize: "3.5vh"
		},
		backgroundColor: colorForPrimaryBackground,
		padding: "3vh 0px",
	}
})

class XProductionTimeLabel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			time: 0,
			running: true,
			standby: false
		};
	}

	componentDidMount = () => {
		var firstRender = true;
		this.interval = setInterval(() => {
			var { time, label, running } = this.props;

			//if (!time)
			//	time = 0;

			if (!running) {
				firstRender = true;
				this.setState({
					time: time,
					running: running
				})
				return;
			}

			if (firstRender)
				firstRender = false;
			else {
				time = this.state.time;
				running = this.state.running
			}
			//console.log(label + ": " + time);
			this.setState({
				time: time + 1,
				running: running
			})
		}, 1000);
	}

	componentWillUnmount = () => {
		clearInterval(this.interval);
	}

	render() {
		var { classes, label } = this.props;
		var { time, running } = this.state;

		var timeString = formatTime(time);

		return (
			<div className={classes.timeLabelWrap}>
				<div className={classes.timeLabelCaption}><Typography>{label}</Typography></div>
				<div className={classes.timeLabelContent}><Typography>{timeString}</Typography></div>
			</div>
		);
	}
}
export default withStyles(styles)(XProductionTimeLabel);