import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import XGrid from "../components/XGrid"
import XSwitch from "../components/XSwitch"
import XNavigation from "../components/XNavigation"

import "../components/XReactDataGrid.css";
import { Tooltip, IconButton,Grid } from '@material-ui/core';
import { AccountBadgeOutline, RedoVariant, ArrowLeft, Printer, RayEndArrow, RayStartArrow } from 'mdi-material-ui';

import { bindActionCreators, compose } from 'redux';

import { connect } from 'react-redux';
import { actionCreators as globalCreators } from 'store/Global';

import { actionCreators } from '../store/Auth';
import XPDFViewer from 'components/XPDFViewer';





class DocumentExpandRow extends Component {
    constructor(props){
        super(props);
        this.state = {
            view : "previous",
            showAmounts: true,
        }
    }
    static contextTypes = {
        router: PropTypes.object
    }

    handleGridDoubleClick = (event, data) => {
        var { view } = this.state;
        this.context.router.history.push(`/doc/edit/${data.ID}?${view}=true`);
    }

    showPrevious = () => {
        this.setState({view: "previous"});
    }
    showNext = () => {
        this.setState({view: "next"});
    }
    render () {
        var { width,data } = this.props;
        var { view } = this.state;
        var label = undefined;
        var actions = [];
        
        actions.push({ name: "Precedenti", icon: <RayEndArrow />, action: this.showPrevious, selected: view === "previous" });
        actions.push({ name: "Successivi", icon: <RayStartArrow />, action: this.showNext, selected: view === "next" });
        var direction = "right";
        if (["xs"].includes(width)) {
            direction = "down";
        }
        var label = <XNavigation actions={actions} direction={direction} showLabel={true} />;

        var gridName = "tblDOCProcessed";
        var dataRoute = `/DOC/GetProcessedDocs/${data.ID}?grid=${gridName}`;
        var key = `${gridName}_${view}`;
        if(view ==="next") {
             dataRoute = `/DOC/GetProcessedDocsExt/${data.ID}?grid=${gridName}`;
        }
        

        return <XGrid
            key={key}
            label={label}
            containerID={`gridExpandContainer_${data.ID}`}
            dataRoute={dataRoute}
            dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
            dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid==${gridName}`}
            dataRouteUpdateColumns={`/Base/UpdateColumns?grid==${gridName}`}
            onDoubleClick={this.handleGridDoubleClick}
            onEditButton={this.handleGridDoubleClick}
            showColumnsSelector={true}
            showFilterActivator={true}
        />;
    }
}
class Documents extends Component {
    constructor(props) {
        super(props);
        this.grdDocuments = React.createRef();
		this.embedPDFRef = React.createRef(); 
		this.state = {
            printDoc: undefined,
            showAmounts: true,
			hideDiscounts: false
		}
    }
    static contextTypes = {
        router: PropTypes.object
    }
    
    rowExpandFormat = (data) => {
        return <DocumentExpandRow data={data} />;
    }
    handleGridDoubleClick = (event, data) => {
        //var checkArray = [this.props.params.DOCCartCausalId, this.props.params.DOCPresetationCausalId];
        //checkArray = checkArray.concat(this.props.params.DOCCatalogCausals);
        //if (checkArray.includes(data.FIDCausal)) {
        if(data.FIDCausal === 16 || data.FIDCausal === 38 || data.FIDCausal === 43){// cassa
            this.context.router.history.push('/documents/issue/cash/' + data.ID);
        }
        else if(data.FIDDocumentType === 4) {
            if(data.FIDCycle === 3){
                this.context.router.history.push('/documents/receive/repair/' + data.ID);
            }
            else {
                this.context.router.history.push('/documents/issue/repair/' + data.ID);
            }
        }
        else if(data.HasFile) {
            this.doShowPresentation(data);
        }
        else {
            this.context.router.history.push('/doc/edit/' + data.ID);
        }
    }
    handleGridNew = (event) => {
        this.context.router.history.push('/documents');
    }
    handleShowPresentation = (event) => {
        var selectedRows = this.grdDocuments.current.getSelectedData();
        var data = this.grdDocuments.current.getRowData(selectedRows[0]);
        this.doShowPresentation(data);
    }
    doShowPresentation = (data) => {
        this.props.setCatalogCausal(data.FIDCausal);
        this.props.setCatalogIdentity(data.FIDIdentity);
        if (data.FIDCausal === this.props.params.DOCPresentationCausalId) {
            this.context.router.history.push('/presentation/' + data.ID);
        }
        else {
            this.context.router.history.push('/catalogue/' + data.ID);
        }
    }
    handleReturnDocuments = (event) => {
        var selectedRows = this.grdDocuments.current.getSelectedData();
        fetch('/DOC/ReturnDocumentFromDocument/', {
            method: 'POST',
            body: JSON.stringify(selectedRows)
        }).then(res => res.json()).then(data => {
            if (!data) {
                //nessun reso generato
            }
            this.grdDocuments.current.refresh();
        });
    }
	cancelPrint = () => {
		this.setState({
			printDoc: undefined
		});
	}
	printDocument = () => {
		var printDoc = this.grdDocuments.current.getSelectedData()[0];
		this.grdDocuments.current.clearSelection();
		if (printDoc) {
			this.setState({
				printDoc
			});
		}
    }
    
    onUpdateShowAmounts = (id) => (event,value) => {
        this.setState({showAmounts: value});
    }
	onUpdateHideDiscounts = (id) => (event, value) => {
		this.setState({ hideDiscounts: value});
	}
    render() {
        var { FIDDocumentType, FIDDocumentSubtype } = this.props;
	    var { printDoc, showAmounts, hideDiscounts } = this.state;
        var selectedActions = [];

        var handleGridNew = undefined;//this.handleGridNew;
        var handleGridEdit = this.handleGridDoubleClick;
        var gridDOubleClick = this.handleGridDoubleClick;
        var deleteRoute = '/DOC/DeleteDocument/';
        if (!this.props.menus.includes("docedit")) {
            //handleGridEdit = undefined;
        }
        if (!this.props.menus.includes("docnew")) {
            handleGridNew = undefined;
        }
        if (!this.props.menus.includes("docdelete")) {
            deleteRoute = undefined;
        }

        var url = "/DOC/GetDocuments?grid=tblDocuments";
        if(FIDDocumentType){
            url = `${url}&FIDDocumentType=${FIDDocumentType}`;
        }
        if(FIDDocumentSubtype){
            url = `${url}&FIDDocumentSubtype=${FIDDocumentSubtype}`;
        }

		var customContent = undefined;
        var actions = [];
        var label = undefined;
		if (printDoc) {
            var key = `ID=${printDoc}&showAmounts=${showAmounts}`;
			var printUrl = `/DOC/PrintDocument/?ID=${printDoc}&showAmounts=${showAmounts}`;
			
			if (showAmounts) {
				key += `&hideDiscounts=${hideDiscounts}`
				printUrl += `&hideDiscounts=${hideDiscounts}`
			}
            //customContent = <iframe key={key} src={`https://docs.google.com/gview?url=${window.location.origin}${printUrl}&embedded=true`} type="application/pdf" width="100%" height={document.body.offsetHeight - 151} />;
            customContent = <XPDFViewer key={key} url={printUrl} width="100%" height={document.body.offsetHeight - 151} />;
			actions = [
				{
					group: 'actions',
					name: 'Indietro',
					icon: <ArrowLeft />,
					action: this.cancelPrint
				}
            ];
            label=<Grid item xs={12} container>
                <Grid item xs={12} container direction="row" spacing={2}>
                    <Grid item xs={12} md={3}><XSwitch label="Mostra importi" color="default" id="ShowAmounts" value={showAmounts} onChange={this.onUpdateShowAmounts} /></Grid>
					{showAmounts ? <Grid item xs={12} md={3}><XSwitch label="Nascondi sconti" color="default" id="HideDiscounts" value={hideDiscounts} onChange={this.onUpdateHideDiscounts} /></Grid> : <Fragment />}
                </Grid>
            </Grid>;
		}
		else {
			actions.push(
				{
					group: 'actions',
					name: 'Stampa',
					icon: <Printer />,
					action: this.printDocument,
					condition: r => r.length === 1 && this.grdDocuments.current.getRowData(r[0]).FIDStatus === 3
				}
			);
			if(this.props.FIDProfile !== this.props.params.CustomerTypologyId){
			//     actions.push(<Tooltip title="Indietro" >
			//     <IconButton aria-label="Indietro" onClick={this.handleGridNew} >
			//         <ArrowLeft />
			//     </IconButton>
			// </Tooltip>);
				actions.push({
					group: 'actions',
					name: 'Indietro',
					icon: <ArrowLeft />,
					action: this.handleGridNew
				});
			}
		}
        return (
            <XGrid
                showLoader
                label={label}
                usePrimaryColor={true}
                innerRef={this.grdDocuments}
                dataRoute={url}
                dataRouteColumns="/Base/GetColumns?grid=tblDocuments"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblDocuments"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblDocuments"
                dataRouteDelete={deleteRoute}
                onDoubleClick={gridDOubleClick}
                onEditButton={handleGridEdit}
                onNewButton={handleGridNew}
                selectedActions={selectedActions}
                rowExpandFormat={this.rowExpandFormat}
                actions={actions}
				customContent={customContent}
            />
        );
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    )
);

export default enhance(Documents);