import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from "components/CustomTabs/CustomTabs.jsx";
import Typography from '@material-ui/core/Typography';

import Icon from "@material-ui/core/Icon";

import XButton from 'components/XButton';
import IRStandardFields from 'views/IR/IRStandardFields.jsx';
import IdentitySells from 'views/IR/IdentitySells.jsx';
import Profile from 'views/IR/Profile.jsx';
import GDPR from 'views/IR/GDPR.jsx';
import XGrid from 'components/XGrid';
import XDialog from 'components/XDialog';
import Grid from '@material-ui/core/Grid';

import XExpansionPanel from 'components/XExpansionPanel';
import XSelect from 'components/XSelect';
import XField from 'components/XField';
import XSwitch from 'components/XSwitch';
import XUpload from 'components/XUpload';
import XProgress from 'components/XProgress';
import XDateField from 'components/XDateField';
import XPDFViewer from 'components/XPDFViewer';
import { CustomField } from 'components/CustomField';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogContentText';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import axios, { CancelToken } from 'axios';

import { HomePlus, Close, Check, AccountMultiplePlus, ArrowLeft, ArrowRight, Download, TrashCan, Printer } from 'mdi-material-ui';
import { bindActionCreators, compose } from 'redux';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import { connect } from 'react-redux';
import { actionCreators as authCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators, getTranslation } from 'store/Localization';

import { getXSelect, getXField, getXSwitch, getXDateField, handleErrors, popupBOPSearch, getBOPSearchContent, parseParam, fixTagValues,getXTag } from 'utils';

import {
    primaryColor,
    primaryBoxShadow,
    primaryColorLight,
    dangerColor,
    colorForPrimaryBackground
} from "assets/jss/material-dashboard-pro-react.jsx";
import XOXLoader from 'components/XOXLoader';
import XTag from '../../components/XTag';
import XMessageBox from 'components/XMessageBox';
import { Save } from '@material-ui/icons';
import moment from 'moment';

import TabContainer from 'components/CustomTabs/TabContainer';
import OperatorDepartsPhases from 'views/Production/OperatorDepartsPhases';
//import moment = require('moment');


const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#fff",
        fontSize: '12px',
        position: 'relative',
    },
});
class SimpleDialog extends React.Component {
    handleClose = () => {
        this.props.onClose(this.props.selectedValue);
    };

    handleListItemClick = value => {
        this.props.onClose(value);
    };

    render() {
        const { classes, onClose, message, title, ...other } = this.props;

        return (
            <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
                <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

SimpleDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    selectedValue: PropTypes.string,
};

const SimpleDialogWrapped = withStyles(styles)(SimpleDialog);

const getExtras = async (FIDGroup,TypologyIds,CategoryIds) =>{
    var i = 0;
    var params = "";
    if (FIDGroup) {
        if (params !== "") {
            params += "&";
        }
        else {
            params += "?";
        }
        params += "FIDGroup=" + FIDGroup;
    }
    if (TypologyIds) {
        for (i = 0; i < TypologyIds.length; i++) {
            if (params !== "") {
                params += "&";
            }
            else {
                params += "?";
            }
            params += "TypologyIds[" + i + "]=" + TypologyIds[i];
        }
    }
    if (CategoryIds) {
        for (i = 0; i < CategoryIds.length; i++) {
            if (params !== "") {
                params += "&";
            }
            else {
                params += "?";
            }
            params += "CategoryIds[" + i + "]=" + CategoryIds[i];
        }
    }

    var extratabs = await fetch('/IR/GetIRExtraTabs' + params).then(res => res.json());
    var extragroups = await fetch('/IR/GetIRExtraGroups' + params).then(res => res.json());
    var extras = await fetch('/IR/GetIRExtras' + params).then(res => res.json());


    return { extratabs, extragroups, extras };


    //fetch('/IR/GetIRExtraTabs' + params).then(res => res.json()).then(extratabs => {
    //    fetch('/IR/GetIRExtraGroups' + params).then(res => res.json()).then(extragroups => {
    //        fetch('/IR/GetIRExtras' + params).then(res => res.json()).then(extras => {
    //            this.setState({ extratabs, extragroups, extras });
    //        });
    //    });
    //});
}

class IREdit extends Component {
	constructor(props) {
		super(props);

		this.grdDestinations = React.createRef();
		this.grdDestinationsRelation = React.createRef();
		this.grdExistingDestinations = React.createRef();
		this.grdExistingDestinationsRelation = React.createRef();
		this.grdExistingRelations = React.createRef();
		this.grdRelations = React.createRef();
		this.grdFiles = React.createRef();
		this.grdFilesRelation = React.createRef();
		this.grdAgreements = React.createRef();
		this.grdAgreementsRelation = React.createRef();
		this.grdUsers = React.createRef();
		this.grdDistributions = React.createRef();
		this.grdUserDestinations = React.createRef();
		this.grdIRPricelists = React.createRef();
		this.destination = React.createRef();
		this.destinationRelation = React.createRef();
		this.relation = React.createRef();
		this.standardFields = React.createRef();
		this.MessageBox = React.createRef();
		this.tabs = React.createRef();

		this.handleDataBlur = this.handleDataBlur.bind(this);
		this.handleSaveData = this.handleSaveData.bind(this);
		this.FieldLabels = {
			FIDGroup: getTranslation(4,"IR_GROUP",this.props.translations, "Gruppo"),
			TypologyIds: getTranslation(4,"IR_TYPOLOGY",this.props.translations, "Tipologia"),
			CategoryIds: getTranslation(4,"IR_TAG",this.props.translations, "Tag"),
			Code: getTranslation(4,"IR_CODE",this.props.translations, "Codice"),
			Denomination: getTranslation(4,"IR_DENOMINATION",this.props.translations, "Denominazione"),
			Alias: getTranslation(4,"IR_ALIAS",this.props.translations, "Alias"),
			Name: getTranslation(4,"IR_NAME",this.props.translations, "Nome"),
			Surname: getTranslation(4,"IR_SURNAME",this.props.translations, "Cognome"),
			Vatnumber: getTranslation(4,"IR_VATNUMBER",this.props.translations, "P.IVA"),
			Taxcode: getTranslation(4,"IR_TAXCODE",this.props.translations, "Codice fiscale"),
			Address: getTranslation(4,"IR_ADDRESS",this.props.translations, "Indirizzo"),
			FIDCity: getTranslation(4,"IR_CITY",this.props.translations, "Città"),
			FIDZip: getTranslation(4,"IR_ZIPCODE",this.props.translations, "CAP"),
			FIDProvince: getTranslation(4,"IR_PROVINCE",this.props.translations, "Provincia"),
			FIDRegion: getTranslation(4,"IR_REGION",this.props.translations, "Regione"),
			FIDCountry: getTranslation(4,"IR_COUNTRY",this.props.translations, "Nazione"),
			Remarkinternal: getTranslation(4,"IR_INTERNALREMARK",this.props.translations, "Nota interna"),
			Remarkpublic: getTranslation(4,"IR_PUBLICREMARK",this.props.translations, "Nota pubblica"),
			FIDRelation: getTranslation(4,"IR_RELATION",this.props.translations, "Relazione"),
			FIDDestinationType: getTranslation(4,"IR_ADDRESS_TYPE",this.props.translations, "Tipologia indirizzo"),
			FIDPriceList: getTranslation(4,"IR_PRICELIST",this.props.translations, "Listino"),
			Discount: getTranslation(4,"IR_DISCOUNT",this.props.translations, "Sconto %"),
			SDICode: getTranslation(4,"IR_SDICODE",this.props.translations, "Codice SDI"),
			FIDVat: getTranslation(4,"IR_VATRATE",this.props.translations, "Aliquota IVA"),
			FIDPayment: getTranslation(4,"IR_PAYMENTMETHOD",this.props.translations, "Modalità di pagamento"),
			FIDTitle: getTranslation(4,"IR_TITLE",this.props.translations, "Titolo"),
			FIDAppellation: getTranslation(4,"IR_APPELLATION",this.props.translations, "Appellativo"),
			DefaultInvoice: getTranslation(4,"IR_DEFAULTINVOICE",this.props.translations, "Predefinito fatturazione"),
			DefaultSpedition: getTranslation(4,"IR_DEFAULTSPEDITION",this.props.translations, "Predefinito spedizione"),
			FIDPricelistWeb: getTranslation(4,"IR_PRICELISTWEB",this.props.translations, "Listino Web"),
		};
		this.state = {
			value: 0,
			data: {
				IsIdentity: true,
				PricelistSource: 1,
				Datestart: new Date
			},
			initialData: {},
			firstLoad: true,
			office: {},
			officeRelation: {},
			relation: {},
			file: {},
			fileRelation: {},
			user: {},
			fields: [],
			showMessage: false,
			errorMessage: '',
			dialog: {},
			FIDIdentity: undefined,
			isUploading: false,
			isUploadingRelation: false,
			uploadProgress: 0,
			uploadProgressRelation: 0,
			usersRefreshed: false,
			userPrintBadge: false,
			userDestination: {},
			distribution: {},
			productionConfig: {
				trackFactoryTime: false,
				trackInterrupts: false,
				trackProductionTime: false,
				allowMultipleBOPS: false,
				autoReopenProductionBOPS: false,
				AllowComponentActivation: false,
				Autologout: false,
			},
			snackbar: {
				open: false,
				message: undefined,
			},
		};
	}
	static contextTypes = {
		router: PropTypes.object
	}

	cancelUpload = undefined;
	cancelUploadRelation = undefined;

	componentDidMount() {

		if (this.props.id > 0) {
			this.setState({ FIDIdentity: this.props.id }, () => {
				this.clearSession();
				this.loadData();
			});
		}
		else {
			this.clearSession();
			this.loadData();
		}
		if (this.isUsers() && this.grdUsers.current) {
            setTimeout(this.grdUsers.current.refresh, 2000);
		}
	}
	componentDidUpdate() {
		if (this.isUsers() && !this.state.usersRefreshed && this.grdUsers.current && this.state.data.ID) {
            this.setState({ usersRefreshed: true }, this.grdUsers.current.refresh);
		}
	}

	componentWillUnmount() {
		this.props.setTitle(undefined);
	}
	loadData = () => {
		if (this.state.FIDIdentity && parseInt(this.state.FIDIdentity, 10) > 0) {
			fetch('/IR/GetIR/' + this.state.FIDIdentity)
				.then(this.handleErrors)
				.then(res => res.json())
				.then(data => {
					var office = { Denomination: data.Denomination, Alias: data.Alias, Vatnumber: data.Vatnumber, FiscalNumber: data.FiscalNumber };
					this.setState((state) => {
						state.data = data;
						state.office = office;

						if (state.firstLoad) {
							state.initialData = JSON.parse(JSON.stringify(state.data));
						}
						state.firstLoad = false;
						return state;
					}, () => {
						if (data.IsLicensee) {
							fetch('/Base/GetProductionConfig').then(res => res.json())
								.then(data => {
									if (data) {
										this.setState({
											productionConfig: data
										})
									}
								})
						}

						this.loadExtras();
                        if (this.standardFields.current) {
                            this.standardFields.current.loadFields();
						}
					});
					this.props.setTitle(data.Denomination);
				}).catch(error => { console.log(error) });

		}
		else {
			this.setState(state => {

				if (state.firstLoad) {
					state.initialData = JSON.parse(JSON.stringify(state.data));
				}
				state.firstLoad = false;
				return state;
			});
		}
	}
	clearSession = (callback) => {
		fetch('/IR/ClearSession', {
			method: 'POST'
		}).then(res => {
			if (callback) {
				callback();
			}
		});
	}

	loadExtrasRelation = () => {
		var { relation } = this.state;
		getExtras(relation.FIDGroup, relation.TypologyIds, relation.CategoryIds).then(({ extratabs, extragroups, extras }) => {
			this.setState({ extratabsRelations: extratabs, extragroupsRelation: extragroups, extrasRelation: extras });
		});
	}

	loadExtras = () => {
		var { data } = this.state;
		getExtras(data.FIDGroup, data.TypologyIds, data.CategoryIds).then(({ extratabs, extragroups, extras }) => {
			console.log({ extratabs, extragroups, extras });
			this.setState({ extratabs, extragroups, extras });
		});
	}
	handleChange = (event, value) => {
		if (this.validate(this.state.value, value)) {
			this.setState({ value });
		}
	};
	handleUserPrintBadge = () => {
		this.setState({
			userPrintBadge: true
		});
	}
	validate = (tab, newTab, callback) => {
		if (tab !== 0) {
			return true;
		}

        var { isValid, requiredFields, optionalFields } = this.standardFields.current.validate();
		if (!isValid) {
			this.showErrorMessage(requiredFields, optionalFields, callback);
		}
		return isValid;
	}

	showErrorMessage = (requiredFields, optionalFields, callback) => {

		var buttons = [
			{
				label: "SI",
				action: () => {
					this.setState({ dialog: {} });
					callback();
				}
			},
			{
				label: "NO",
				action: () => {
					this.setState({ dialog: {} });
				}
			}
		];
		if (!callback || (requiredFields.length > 0)) {
			buttons = [
				{
					label: "OK",
					action: () => {
						this.setState({ dialog: {} });
					}
				}
			];
		}
		var msg = (
			<Fragment>
				{requiredFields.length > 0 && (<Typography variant="subtitle2">Campi obbligatori:</Typography>)}
				{requiredFields.map((item, index) => (<Typography key={index}>{this.FieldLabels[item]}</Typography>))}
				{optionalFields.length > 0 && (<Typography variant="subtitle2">Campi raccomandati (opzionali):</Typography>)}
				{optionalFields.map((item, index) => (<Typography key={index}>{this.FieldLabels[item]}</Typography>))}

				{requiredFields.length == 0 && optionalFields.length > 0 && (<Typography variant="subtitle2">Procedere senza compilare questi campi?</Typography>)}

				{requiredFields.length > 0 && (<Typography variant="subtitle2">Compilare i campi obbligatori prima di procedere.</Typography>)}

			</Fragment>
		);

		this.showDialog(buttons, "Alcuni campi non sono stati compilati.", msg);




		//this.setState({ showMessage: true, errorMessage: msg });
	}

	renderTab1 = () => {
		var { data, FIDIdentity } = this.state;
		return (
			<TabContainer>
				<IRStandardFields innerRef={this.standardFields} readOnly={this.props.userProfile} data={data} dataJson={JSON.stringify(data)} dataKey="data" id={parseInt(FIDIdentity, 10)} userID={this.props.userID} caller={this} showCodes={true} onChange={this.handleDataChange} onBlur={this.handleDataBlur} />
			</TabContainer>
		);
	}
	handleCancelOffice = (event, caller) => {

		var { data, office } = this.state;
		var props = Object.getOwnPropertyNames(office);
		for (var i = 0; i < props.length; i++) {
			if (typeof office[props[i]] === "string") {
				office[props[i]] = "";
			}
			else {
				office[props[i]] = undefined;
			}
		}
		this.setState({ office: office, isOfficeEdit: false }, () => {
			var newoffice = { Denomination: data.Denomination, Alias: data.Alias, Vatnumber: data.Vatnumber, FiscalNumber: data.FiscalNumber };
			this.setState({ office: newoffice }, () => {
				window.scrollTo(0, 0);
			});
		});

	}
	handleCancelOfficeRelation = (event, caller) => {

		var { relation, officeRelation } = this.state;
		var props = Object.getOwnPropertyNames(officeRelation);
		for (var i = 0; i < props.length; i++) {
			if (typeof officeRelation[props[i]] === "string") {
				officeRelation[props[i]] = "";
			}
			else {
				officeRelation[props[i]] = undefined;
			}
		}
		this.setState({ officeRelation: officeRelation, isOfficeEditRelation: false }, () => {
			var newoffice = { Denomination: relation.Denomination, Alias: relation.Alias, Vatnumber: relation.Vatnumber, FiscalNumber: relation.FiscalNumber };
			this.setState({ officeRelation: newoffice }, () => {
				window.scrollTo(0, 0);
			});
		});

	}
	doSaveOffice = (callback) => {

		var { data, office } = this.state;
		var FIDIdentity = this.state.FIDIdentity;
		if (!FIDIdentity) {
			FIDIdentity = 0;
		}

		if (office.TypologyIds && typeof office.TypologyIds === "string") {
			office.TypologyIds = office.TypologyIds.split(",").map(Number);
		}
		if (office.CategoryIds && typeof office.CategoryIds === "string") {
			office.CategoryIds = office.CategoryIds.split(",").map(Number);
		}
		if (office.DistributionListIds && typeof office.DistributionListIds === "string") {
			office.DistributionListIds = office.DistributionListIds.split(",").map(Number);
		}

		if (office.DefaultInvoice || office.DefaultSpedition) {
			//check if already exists
		}
		fetch('/IR/SaveDestination/' + FIDIdentity, {
			body: JSON.stringify(office),
			method: 'POST'
		}).then(res => res.json())
			.then(result => {
				var props = Object.getOwnPropertyNames(office);
				var defSped = office.DefaultSpedition;
				var defInv = office.DefaultInvoice;
				for (var i = 0; i < props.length; i++) {
					if (typeof office[props[i]] === "string")
						office[props[i]] = "";
					else
						office[props[i]] = undefined;
				}
				this.setState(state => {
					//{ office: office, isOfficeEdit: false }
					state.office = office;
					state.isOfficeEdit = false;
					if (defSped) {
						state.data.DefaultSpedition = false;
					}
					if (defInv) {
						state.data.DefaultInvoice = false;
					}
					return state;
				}, () => {
					var newoffice = { Denomination: data.Denomination, Alias: data.Alias, Vatnumber: data.Vatnumber, FiscalNumber: data.FiscalNumber };
					this.setState({ office: newoffice }, () => {
                        //this.grdDestinations.current.getWrappedInstance().refresh();
                        //this.destination.current.getWrappedInstance().refresh();
					});
					window.scrollTo(0, 0);
				});
				if (callback) {
					callback();
				}
			});
	}
	doSaveOfficeRelation = (callback) => {

		var { relation, officeRelation } = this.state;
		var FIDIdentity = relation.ID;
		if (!FIDIdentity) {
			FIDIdentity = 0;
		}

		if (officeRelation.TypologyIds && typeof officeRelation.TypologyIds === "string") {
			officeRelation.TypologyIds = officeRelation.TypologyIds.split(",").map(Number);
		}
		if (officeRelation.CategoryIds && typeof officeRelation.CategoryIds === "string") {
			officeRelation.CategoryIds = officeRelation.CategoryIds.split(",").map(Number);
		}
		if (officeRelation.DistributionListIds && typeof officeRelation.DistributionListIds === "string") {
			officeRelation.DistributionListIds = officeRelation.DistributionListIds.split(",").map(Number);
		}


		fetch('/IR/SaveDestination/' + FIDIdentity + '?isRelation=true', {
			body: JSON.stringify(officeRelation),
			method: 'POST'
		}).then(res => res.json())
			.then(result => {
				var props = Object.getOwnPropertyNames(officeRelation);
				for (var i = 0; i < props.length; i++) {
					if (typeof officeRelation[props[i]] === "string")
						officeRelation[props[i]] = "";
					else
						officeRelation[props[i]] = undefined;
				}
				this.setState({ officeRelation: officeRelation, isOfficeEditRelation: false }, () => {
					var newoffice = { Denomination: relation.Denomination, Alias: relation.Alias, Vatnumber: relation.Vatnumber, FiscalNumber: relation.FiscalNumber };
					this.setState({ officeRelation: newoffice }, () => {
                        //this.grdDestinations.current.getWrappedInstance().refresh();
                        //this.destination.current.getWrappedInstance().refresh();
					});
					window.scrollTo(0, 0);
				});
				if (callback) {
					callback();
				}
			});
	}
	handleSaveOffice = (event, caller, callback) => {
		if (this.destination.current) {
			var { isValid, requiredFields, optionalFields } = this.destination.current.validate();
			if (!this.state.office.FIDDestinationType) {
				isValid = false;
				requiredFields.unshift("FIDDestinationType");
			}
			if (!isValid) {
				this.showErrorMessage(requiredFields, optionalFields, () => {
					this.handleOfficeCheckDefault(() => {
						this.doSaveOffice(callback);
					});

				});
				return;
			}
			else {
				this.handleOfficeCheckDefault(() => {
					this.doSaveOffice(callback);
				});
			}
		}
	}
	handleOfficeCheckDefault = (callback) => {
		var { office } = this.state;
		var FIDIdentity = this.state.FIDIdentity;
		if (!FIDIdentity) {
			FIDIdentity = 0;
		}
		if (office.DefaultInvoice || office.DefaultSpedition) {
			//verifico
			if (office.TypologyIds && typeof office.TypologyIds === "string") {
				office.TypologyIds = office.TypologyIds.split(",").map(Number);
			}
			if (office.CategoryIds && typeof office.CategoryIds === "string") {
				office.CategoryIds = office.CategoryIds.split(",").map(Number);
			}
			if (office.DistributionListIds && typeof office.DistributionListIds === "string") {
				office.DistributionListIds = office.DistributionListIds.split(",").map(Number);
			}

			fetch('/IR/CheckDefaultDestination/' + FIDIdentity, {
				body: JSON.stringify(office),
				method: 'POST'
			}).then(res => res.json())
				.then(result => {
					if (result.HasDefaultSpedition || result.HasDefaultInvoice) {
						var msg = "";
						if (result.HasDefaultSpedition && !result.HasDefaultInvoice) {
							msg = getTranslation(4, "IR_EXISTING_DEF_SHIPMENT_ADDR", this.props.translation, "Esiste già un indirizzo di spedizione predefinito. Proseguire?");
						}
						else if (result.HasDefaultInvoice && !result.HasDefaultSpedition) {
							msg = getTranslation(4, "IR_EXISTING_DEF_INVOICE_ADDR", this.props.translation, "Esiste già un indirizzo di fatturazione predefinito. Proseguire?");
						}
						else {
							msg = getTranslation(4, "IR_EXISTING_DEF_INVSHIP_ADDRESS", this.props.translation, "Esistono già degli indirizzi di fatturazione e spedizione predefiniti. Proseguire?");
						}

                        this.MessageBox.current.showMessageYesNo(getTranslation(1, "IR_EXISTING_DEF_ADDRESSES", this.props.translation, "Esistono indirizzi predefiniti"),msg,callback);
					}
					else {
						callback && callback();
					}
				});
			return false;
		}
		callback && callback();
		return true;
	}
	handleSaveOfficeRelation = (event, caller, callback) => {
		if (this.destinationRelation.current) {
			var { isValid, requiredFields, optionalFields } = this.destinationRelation.current.validate();

			if (!this.state.officeRelation.FIDDestinationType) {
				isValid = false;
				requiredFields.unshift("FIDDestinationType");
			}
			if (!isValid) {
				this.showErrorMessage(requiredFields, optionalFields, () => {
					this.doSaveOfficeRelation(callback);
				});
				return;
			}
			else {
				this.doSaveOfficeRelation(callback);
			}
		}
	}
	handleOfficeDoubleClick = (event, data) => {
		this.setState({ office: data, isOfficeEdit: true }, () => {
            //this.grdDestinations.current.getWrappedInstance().refresh();
            //this.destination.current.getWrappedInstance().refresh();
		});
	}
	handleOfficeDoubleClickRelation = (event, data) => {
		this.setState({ officeRelation: data, isOfficeEditRelation: true }, () => {
            //this.grdDestinations.current.getWrappedInstance().refresh();
            //this.destination.current.getWrappedInstance().refresh();
		});
	}


	handleCancelRelation = (event, caller) => {

		var { relation } = this.state;
		var props = Object.getOwnPropertyNames(relation);
		for (var i = 0; i < props.length; i++) {
			if (typeof relation[props[i]] === "string") {
				relation[props[i]] = "";
			}
			else {
				relation[props[i]] = undefined;
			}
		}
		this.setState({ relation: relation, isRelationEdit: false, extratabsRelations: 0 }, () => {
			//var newoR = { Denomination: data.Denomination, Alias: data.Alias, Vatnumber: data.Vatnumber, FiscalNumber: data.FiscalNumber };
			//this.setState({ office: newoffice }, () => {
			//});
		});
	}
	handleErrors = (response) => {
		if (!response.ok) {
			response.body.getReader().read().then(({ done, value }) => { console.log([done, value]); });
			throw Error(response);
		}
		return response;
	};
	doSaveRelation = (callback) => {
		var { relation } = this.state;
		var FIDIdentity = this.state.FIDIdentity;
		if (!FIDIdentity) {
			FIDIdentity = 0;
		}
		if (relation.TypologyIds && typeof relation.TypologyIds === "string") {
			relation.TypologyIds = relation.TypologyIds.split(",").map(Number);
		}
		if (relation.CategoryIds && typeof relation.CategoryIds === "string") {
			relation.CategoryIds = relation.CategoryIds.split(",").map(Number);
		}
		if (relation.DistributionListIds && typeof relation.DistributionListIds === "string") {
			relation.DistributionListIds = relation.DistributionListIds.split(",").map(Number);
		}
		relation.TSUPD = moment();



		fetch('/IR/SaveRelation/' + FIDIdentity, {
			body: JSON.stringify(relation),
			method: 'POST'
		}).then(this.handleErrors).then(res => res.json())
			.then(result => {
				var props = Object.getOwnPropertyNames(relation);
				for (var i = 0; i < props.length; i++) {

					if (typeof relation[props[i]] === "string")
						relation[props[i]] = "";
					else
						relation[props[i]] = undefined;
				}
				this.setState({ relation: relation, isRelationEdit: false, relationTab: 0 }, () => {
					//var newoffice = { Denomination: data.Denomination, Alias: data.Alias, Vatnumber: data.Vatnumber, FiscalNumber: data.FiscalNumber };
					//this.setState({ office: newoffice }, () => {
                    //this.grdRelations.current.getWrappedInstance().refresh();
                    //this.relations.current.getWrappedInstance().refresh();
					//});
				});
				if (callback) {
					callback();
				}
			}).catch(result => {
				console.log(result);
			});
	}
	handleSaveRelation = (event, caller, callback) => {

		if ((!this.state.relationTab || this.state.relationTab == 0) && this.relation.current) {
			var { isValid, requiredFields, optionalFields } = this.relation.current.validate();
			if (!this.state.relation.FIDRelation) {
				isValid = false;
				requiredFields.unshift("FIDRelation");
			}
			if (!isValid) {
				this.showErrorMessage(requiredFields, optionalFields, () => {
					this.doSaveRelation(callback);
				});
				return;
			}
			else {
				this.doSaveRelation(callback);
			}
		}
		else {
			this.doSaveRelation(callback);
		}

	}
	handleSaveData = (event, caller, callback) => {
		//this.setState({ isLoading: true }, () => {
		if (this.state.isOfficeEdit) {
			this.handleSaveOffice(event, caller, callback);
		}
		else if (this.state.isRelationEdit) {
			this.handleSaveRelation(event, caller, callback);
		}
		else {
			var dataIsValid = true;
      if (this.standardFields.current) {
        var { isValid, requiredFields, optionalFields } = this.standardFields.current.validate();
				if (!isValid) {
					this.showErrorMessage(requiredFields, optionalFields, () => {
						this.doSaveData(callback, false);						
					});
					this.tabs.current.goToTab(0);
					return;
				}
			}

			/*fetch('/IR/CheckIncomplete?id=' + this.state.data.ID).then(res => res.json()).then(res => {
				if (res) {
					this.MessageBox.current.showMessageYesNo("INSERIMENTO/MODIFICA ANAGRAFICA", "Contratto GDPR non compilato, l'anagrafica sarà inserita nell'elenco delle anagrafiche incomplete. Continuare ugualmente?", () => {
						if (dataIsValid) {
							this.doSaveData(callback, true);
						}
						else {
							this.tabs.current.goToTab(0);
						}
					})
				}
				else {
					if (dataIsValid) {
						this.doSaveData(callback, false);
					}
					else {
						this.tabs.current.goToTab(0);
					}
				}
		 	});*/
			if (dataIsValid) {
				this.doSaveData(callback, false);
			}
			else {
				this.tabs.current.goToTab(0);
			}
		}
		//});
	}
	doSaveData = (callback, incomplete = false) => {
		var currentTab = this.tabs.current ? this.tabs.current.getCurrentTab() : undefined;
		this.setState({ isLoading: true }, () => {
			var { data, productionConfig } = this.state;

			if (productionConfig) {
				data.ConfigProduction = productionConfig;
			}

			var FIDIdentity = this.state.FIDIdentity;
			if (!FIDIdentity) {
				FIDIdentity = 0;
			}
			data.Incomplete = incomplete;

			if (data.TypologyIds && typeof data.TypologyIds === "string") {
				data.TypologyIds = data.TypologyIds.split(",").map(Number);
			}
			if (data.CategoryIds && typeof data.CategoryIds === "string") {
				data.CategoryIds = data.CategoryIds.split(",").map(Number);
			}
			if (data.DistributionListIds && typeof data.DistributionListIds === "string") {
				data.DistributionListIds = data.DistributionListIds.split(",").map(Number);
			}

			fetch('/IR/SaveIR/' + FIDIdentity, {
				body: JSON.stringify(data),
				method: 'POST'
			}).then(this.handleErrors).then(res => res.json()).then((data) => {
				this.props.setTitle(data.Denomination);
				if (this.state.FIDIdentity === undefined || parseInt(this.state.FIDIdentity, 10) === 0) {
					if (!this.props.noRedirect) {
						this.setState({snackbar: { open: true, message: "Salvataggio effettuato correttamente" } }, () => {
							setTimeout( () => { this.context.router.history.push('/IREdit/' + data.ID); }, 1500);
						});
						return;
					} else {
						this.setState({snackbar: { open: true, message: "Salvataggio effettuato correttamente" } });
					}
				}
				this.setState({ firstLoad: true }, () => {
					this.loadData();
					this.setState({ isLoading: false, snackbar: { open: true, message: "Salvataggio effettuato correttamente" } }, () => {
						if (currentTab)
							this.tabs.current.goToTab(currentTab);
						setTimeout(() => {
							if (this.state.snackbar.open) {
								this.setState({ snackbar: { open: false, message: "" } });
								if (callback) {
									callback(data);
								}
							}
						}, 1500);

						this.props.onSave && this.props.onSave();

						if (!this.state.snackbar.open) {
							if (callback) {
								callback(data);
							}
						}
					});
				});

			})
			.catch(error => {
				console.log(error);
				this.setState({ isLoading: false, snackbar: { open: true, message: "Salvataggio fallito" } }, () => {
					if (currentTab)
						this.tabs.current.goToTab(currentTab);
				});
			});
		});
	}
	handleCancelData = (event, caller) => {
		var FIDIdentity = this.state.FIDIdentity;
		if (!FIDIdentity) {
			FIDIdentity = 0;
		}
		var buttons = [
			{
				label: "SI",
				action: () => {
					this.setState({ dialog: {} });

					fetch('/IR/DeleteIR', {
						method: 'POST',
						body: JSON.stringify([FIDIdentity])
					}).then(res => res.json()).then(res => {
						if (res) {
							this.props.setTitle(undefined);
						this.context.router.history.push('/IR');
						}
						else {
							var btn = [
								{
									label: "OK",
									action: () => { this.setState({ dialog: {} }); }
								}
							]
							this.showDialog(btn, "Cancellazione", "Impossibile eliminare l'anagrafica");
						}
					});
				}
			},
			{
				label: "NO",
				action: () => {
					this.setState({ dialog: {} });
				}
			},
		];

		this.showDialog(buttons, "Cancellazione", "Confermi la cancellazione dell'anagrafica?");


	}

	doNewData = () => {
		if (this.context.router.route.location.pathname === '/IRNew') {
			fetch('/IR/ClearSession', {
				method: 'POST'
			}).then(res => {
				this.setState({ data: {} });
			});
		}
		else {
			this.clearSession(() => {
				this.props.setTitle(undefined);
				this.context.router.history.push('/IRNew');
			});
		}
	}

	checkForChanges = (event, caller, callbackNoChanges, callbackYes, callbackNo, callbackCancel) => {
		fetch('/IR/HasChanges/', {
			method: 'POST'
		}).then(res => res.json())
			.then(data => {
				if (data.HasChanges || (JSON.stringify(this.state.data) !== JSON.stringify(this.state.initialData))) {
					var buttons = [
						{
							label: "SI",
							action: () => {
								this.setState({ dialog: {} });
								callbackYes();
							}
						},
						{
							label: "NO",
							action: () => {
								this.setState({ dialog: {} });
								callbackNo();
							}
						},
						{
							label: "ANNULLA",
							action: () => {
								this.setState({ dialog: {} });
								callbackCancel();
							}
						},
					];

					this.showDialog(buttons, "Modifiche non salvate", "Continuando perderai le modifiche effettuate. Vuoi salvare prima di uscire da questa pagina?");
				}
				else {
					callbackNoChanges();
				}
			});
	}

	handleNewData = (event, caller) => {
		var {params} = this.props;
		var { CustomerTypologyId } = params;

		if (!CustomerTypologyId)
			CustomerTypologyId = 2;

		var TypologyIds = this.state.data.TypologyIds;

		if (TypologyIds && TypologyIds.includes(CustomerTypologyId)) {
			this.checkForChanges(event, caller,
				() => {
					if (params.GDPRRequired) {
						fetch('/IR/CheckIncomplete?id=' + this.state.data.ID).then(res => res.json()).then(res => {
							if (res) {
								this.MessageBox.current.showMessageYesNo("INSERIMENTO/MODIFICA ANAGRAFICA", "Contratto GDPR non compilato, l'anagrafica 	sarà inserita nell'elenco delle anagrafiche incomplete. Continuare ugualmente?", () => {
									this.doSaveData(this.doNewData, true);
								});
							}
							else
							this.doSaveData(this.doNewData, false);
						});
					} else {
						if (this.state.data.Incomplete)
							this.doSaveData(this.doNewData);
						else
							this.doNewData();
					}
				},
				() => {
					if (this.state.isOfficeEdit && this.state.isRelationEdit) {
						this.handleSaveData(event, this, () => {
							this.handleSaveData(event, this, () => {
								this.handleSaveData(event, this, () => {
									this.doNewData();
								});
							});
						});
					}
					else if (!this.state.isOfficeEdit && !this.state.isRelationEdit) {
						if (params.GDPRRequired) {
							fetch('/IR/CheckIncomplete?id=' + this.state.data.ID).then(res => res.json()).then(res => {
								if (res) {
									this.MessageBox.current.showMessageYesNo("INSERIMENTO/MODIFICA ANAGRAFICA", "Contratto GDPR non compilato, l'anagrafica 	sarà inserita nell'elenco delle anagrafiche incomplete. Continuare ugualmente?", () => {
										this.doSaveData(this.doNewData, true);
									});
								}
								else
									this.doSaveData(this.doNewData, false);
							});
						} else {
							this.doSaveData(this.doNewData);
						}
					}
					else {
						this.handleSaveData(event, this, () => {
							this.doNewData();
						});
					}
				}, () => {
					if (params.GDPRRequired) {
						fetch('/IR/CheckIncomplete?id=' + this.state.data.ID).then(res => res.json()).then(res => {
							if (res) {
								this.MessageBox.current.showMessageYesNo("INSERIMENTO/MODIFICA ANAGRAFICA", "Contratto GDPR non compilato, l'anagrafica 	sarà inserita nell'elenco delle anagrafiche incomplete. Continuare ugualmente?", () => {
									this.doSaveData(this.doNewData, true);
								});
							}
							else
							this.doSaveData(this.doNewData, false);
						});
					} else {
						if (this.state.data.Incomplete)
							this.doSaveData(this.doNewData, false);
						else
							this.doNewData();
					}
				},
				() => {
				}
			);
		}
		else {
			this.checkForChanges(event, caller,
				this.doNewData,
				() => {
					if (this.state.isOfficeEdit && this.state.isRelationEdit) {
						this.handleSaveData(event, this, () => {
							this.handleSaveData(event, this, () => {
								this.handleSaveData(event, this, () => {
									this.doNewData();
								});
							});
						});
					}
					else if (!this.state.isOfficeEdit && !this.state.isRelationEdit) {
						this.handleSaveData(event, this, () => {
							this.handleSaveData(event, this, () => {
								this.doNewData();
							});
						});
					}
					else {
						this.handleSaveData(event, this, () => {
							this.doNewData();
						});
					}
				}, this.doNewData,
				() => {
				}
			);
		}
	}
	handleRelationDoubleClick = (event, data) => {
		var FIDIdentity = this.state.FIDIdentity;
		if (!FIDIdentity) {
			FIDIdentity = 0;
		}
		fetch('/IR/GetIRRelation/' + data.ID + '?FIDIdentity=' + FIDIdentity).then(res => res.json()).then(relation => {
			this.setState({ relation, isRelationEdit: true }, () => {
                //this.grdDestinations.current.getWrappedInstance().refresh();
                //this.relation.current.getWrappedInstance().refresh();
				this.loadExtrasRelation();
			});
		});
	}
	handleOfficeNew = (event) => {
		this.setState(state => {
			var { data } = state;
			var office = { Denomination: data.Denomination, Alias: data.Alias, Vatnumber: data.Vatnumber, Taxcode: data.Taxcode, FIDGroup: data.FIDGroup, TypologyIds: data.TypologyIds, CategoryIds: data.CategoryIds, Name: data.Name, Surname: data.Surname };
			state.office = office;
			state.isOfficeEdit = true;
			return state;
		});
		//this.setState({ isOfficeEdit: true });
	}
	handleOfficeNewRelation = (event) => {
		this.setState(state => {
			var { relation } = state;
			var office = { Denomination: relation.Denomination, Alias: relation.Alias, Vatnumber: relation.Vatnumber, Taxcode: relation.Taxcode, FIDGroup: relation.FIDGroup, TypologyIds: relation.TypologyIds, CategoryIds: relation.CategoryIds, Name: relation.Name, Surname: relation.Surname };
			state.officeRelation = office;
			state.isOfficeEditRelation = true;
			return state;
		});
		//this.setState({ isOfficeEdit: true });
	}
	handleRelationNew = (event) => {
		this.setState({ isRelationEdit: true });
	}
	handleDataChange = (event, value, key, data, firstLoad = false) => {
		this.setState((state, props) => {
			state.data[key] = value;

			state.data = this.geographicAreaFix(data, state.data, key);

			if (["FIDLicenseeDestinationRef", "FIDLicenseeUserRef"].includes(key)) {
				state.data[key] = data && data.ID ? data.ID : null;
				if (!firstLoad)
					state.data = this.userDestinationRefFix(data, state.data, key);
			}
			//if (key === "Denomination" && !state.data["Alias"]) {
			//    state.data["Alias"] == value;
			//}

			if(key === "Vatnumber") {
				state.data.VatnumberChange = true;
			}
			return state;
		}, () => {
			if (["FIDGroup", "TypologyIds", "CategoryIds"].includes(key)) {
				this.loadExtras();
			}
			if (key === "DefaultInvoice" && value) {
				//
				var FIDIdentity = this.state.FIDIdentity;
				if (!FIDIdentity) {
					FIDIdentity = 0;
				}
				fetch(`/IR/SetDefaultInvoice/${FIDIdentity}`)
			}
			if (key === "DefaultSpedition" && value) {
				//
				var FIDIdentity = this.state.FIDIdentity;
				if (!FIDIdentity) {
					FIDIdentity = 0;
				}
				fetch(`/IR/SetDefaultSpedition/${FIDIdentity}`)
			}
		});
	}

	geographicAreaFix = (data, db, key) => {

		if (["FIDZip", "FIDCity", "FIDProvince", "FIDRegion"].includes(key) && data && data.ID > 0) {
			db["FIDCountry"] = data.FIDCountry;
		}
		if (["FIDZip", "FIDCity", "FIDProvince"].includes(key) && data && data.ID > 0) {
			db["FIDRegion"] = data.FIDRegion;
		}
		if (["FIDZip", "FIDCity"].includes(key) && data && data.ID > 0) {
			db["FIDProvince"] = data.FIDProvince;
		}
		if (["FIDZip"].includes(key) && data && data.ID > 0) {
			db["FIDCity"] = data.FIDCity;
		}
		if (["FIDCity", "FIDProvince", "FIDRegion", "FIDCountry"].includes(key)) {
			if (db["FIDZip"] >= 0)
				db["FIDZip"] = undefined;
		}
		if (["FIDProvince", "FIDRegion", "FIDCountry"].includes(key)) {
			if (db["FIDCity"] >= 0)
				db["FIDCity"] = undefined;
		}
		if (["FIDRegion", "FIDCountry"].includes(key)) {
			if (db["FIDProvince"] >= 0)
				db["FIDProvince"] = undefined;
		}
		if (["FIDCountry"].includes(key)) {
			if (db["FIDRegion"] >= 0)
				db["FIDRegion"] = undefined;
		}
		return db;
	}

	userDestinationRefFix = (data, db, key) => {
		if (key === "FIDLicenseeDestinationRef") {
				if (data) {
						if (db.FIDLicenseeUserRef && ((!!data.FIDUsers && !data.FIDUsers.includes(db.FIDLicenseeUserRef)) || !data.FIDUsers))
						{
								db.FIDLicenseeUserRef = undefined
						}

						if (data.FIDUsers) {
								var users = data.FIDUsers.split(",");

								if (users && users.length === 1) {
										db.FIDLicenseeUserRef = parseInt(users[0], 10);
								}
						}
				}

		} else {
				if (data) {
						if (db.FIDLicenseeDestinationRef && ((!!data.FIDDestinations && !data.FIDDestinations.includes(db.FIDLicenseeDestinationRef)) || !data.FIDDestinations))
						{
								db.FIDLicenseeDestinationRef = undefined
						}

						if (data.FIDDestinations) {
								var dests = data.FIDDestinations.split(",");

								if (dests && dests.length === 1) {
										db.FIDLicenseeDestinationRef = parseInt(dests[0], 10);
								}
						}
				}
		}
		return db;
	}

	handleDestinationChange = (event, value, key, data) => {
		this.setState((state, props) => {
			state.office[key] = value;
			state.office = this.geographicAreaFix(data, state.office, key);

			//if (key === "Denomination" && !state.data["Alias"]) {
			//    state.office["Alias"] == value;
			//}
			if(key === "Vatnumber") {
				state.office.VatnumberChange = true;
			}
			return state;
		});
	}
	handleDestinationChangeRelation = (event, value, key, data) => {
		this.setState((state, props) => {
			state.officeRelation[key] = value;
			state.officeRelation = this.geographicAreaFix(data, state.officeRelation, key);

			//if (key === "Denomination" && !state.data["Alias"]) {
			//    state.office["Alias"] == value;
			//}
			if(key === "Vatnumber") {
				state.officeRelation.VatnumberChange = true;
			}
			return state;
		});
	}
	handleRelationChange = (event, value, key, data) => {
		this.setState((state, props) => {
			state.relation[key] = value;

			state.relation = this.geographicAreaFix(data, state.relation, key);

			//if (key === "Denomination" && !state.data["Alias"]) {
			//    state.relation["Alias"] == value;
			//}
			if(key === "Vatnumber") {
				state.relation.VatnumberChange = true;
			}
			return state;
		}, () => {
			if (["FIDGroup", "TypologyIds", "CategoryIds"].includes(key)) {
				this.loadExtrasRelation();
			}
		});
	}
	handleDataBlur = (event, key) => {
		if (key === "Denomination" && !this.state.data.Alias) {
			this.setState((state) => {
				state.data.Alias = state.data.Denomination;
				return state;
			});
		}
		// if (key === "Vatnumber" && !this.state.data.Taxcode) {
		// 	this.setState((state) => {
		// 		state.data.Taxcode = state.data.Vatnumber;
		// 		return state;
		// 	});
		// }
		if(key === "Vatnumber" && this.state.data.VatnumberChange && this.state.data.FIDGroup === 2) {//Company
			this.MessageBox.current.showMessageYesNo("ATTENZIONE","Il codice fiscale è uguale alla P.IVA?", () => {
				this.setState(state => {
					state.data.Taxcode = state.data.Vatnumber;
					state.data.VatnumberChange = false;
					return state;
				})
			}, () => {
				this.setState(state => {
					state.data.VatnumberChange = false;
					return state;
				});
			});
		}
	}
	handleDestinationBlur = (event, key) => {
		if (key === "Denomination" && !this.state.office.Alias) {
			this.setState((state) => {
				state.office.Alias = state.office.Denomination;
				return state;
			});
		}
		// if (key === "Vatnumber" && !this.state.office.Taxcode) {
		// 	this.setState((state) => {
		// 		state.office.Taxcode = state.office.Vatnumber;
		// 		return state;
		// 	});
		// }
		
		if(key === "Vatnumber" && this.state.ofice.VatnumberChange && this.state.office.FIDGroup === 2) {//Company
			this.MessageBox.current.showMessageYesNo("ATTENZIONE","Il codice fiscale è uguale alla P.IVA?", () => {
				this.setState(state => {
					state.office.Taxcode = state.office.Vatnumber;
					state.office.VatnumberChange = false;
					return state;
				})
			}, () => {
				this.setState(state => {
					state.office.VatnumberChange = false;
					return state;
				});
			});
		}
	}
	handleDestinationBlurRelation = (event, key) => {
		if (key === "Denomination" && !this.state.officeRelation.Alias) {
			this.setState((state) => {
				state.officeRelation.Alias = state.officeRelation.Denomination;
				return state;
			});
		}
		// if (key === "Vatnumber" && !this.state.officeRelation.Taxcode) {
		// 	this.setState((state) => {
		// 		state.officeRelation.Taxcode = state.officeRelation.Vatnumber;
		// 		return state;
		// 	});
		// }
		
		if(key === "Vatnumber" && this.state.officeRelation.VatnumberChange && this.state.officeRelation.FIDGroup === 2) {//Company
			this.MessageBox.current.showMessageYesNo("ATTENZIONE","Il codice fiscale è uguale alla P.IVA?", () => {
				this.setState(state => {
					state.officeRelation.Taxcode = state.officeRelation.Vatnumber;
					state.officeRelation.VatnumberChange = false;
					return state;
				})
			}, () => {
				this.setState(state => {
					state.officeRelation.VatnumberChange = false;
					return state;
				});
			});
		}
	}
	handleRelationBlur = (event, key) => {
		if (key === "Denomination" && !this.state.relation.Alias) {
			this.setState((state) => {
				state.relation.Alias = state.relation.Denomination;
				return state;
			});
		}
		// if (key === "Vatnumber" && !this.state.relation.Taxcode) {
		// 	this.setState((state) => {
		// 		state.relation.Taxcode = state.relation.Vatnumber;
		// 		return state;
		// 	});
		// }
		if(key === "Vatnumber" && this.state.relation.VatnumberChange && this.state.relation.FIDGroup === 2) {//Company
			this.MessageBox.current.showMessageYesNo("ATTENZIONE","Il codice fiscale è uguale alla P.IVA?", () => {
				this.setState(state => {
					state.relation.Taxcode = state.relation.Vatnumber;
					state.relation.VatnumberChange = false;
					return state;
				})
			}, () => {
				this.setState(state => {
					state.relation.VatnumberChange = false;
					return state;
				});
			});
		}

	}
	handleDestinationAddExistingClick = (event) => {
		this.setState({ isOfficeAddExisting: true, officeAddStep1: true, officeAddStep2: false, office: {} });
	}
	handleDestinationAddExistingClickRelation = (event) => {
		this.setState({ isOfficeAddExistingRelation: true, officeRelationAddStep1: true, officeRelationAddStep2: false, officeRelation: {} });
	}
	handleDestinationAddExistingCancelClick = () => {
		this.setState({ isOfficeAddExisting: false, officeAddStep1: false, officeAddStep2: false, office: {} });
	}
	handleDestinationAddExistingCancelClickRelation = () => {
		this.setState({ isOfficeAddExistingRelation: false, officeRelationAddStep1: false, officeRelationAddStep2: false, officeRelation: {} });
	}
	handleOfficeAddExistingNextClick = () => {
		this.setState({ officeAddStep1: false, officeAddStep2: true });
	}
	handleOfficeAddExistingPreviousClick = () => {
		this.setState({ officeAddStep1: true, officeAddStep2: false });
	}
	handleOfficeRelationAddExistingNextClick = () => {
		this.setState({ officeRelationAddStep1: false, officeRelationAddStep2: true });
	}
	handleOfficeRelationAddExistingPreviousClick = () => {
		this.setState({ officeRelationAddStep1: true, officeRelationAddStep2: false });
	}
	handleDestinationAddExistingSelectDoubleClick = (_, selectedData) => {
		this.handleOfficeCheckDefault(() => {
			var { office } = this.state;
			var FIDIdentity = this.state.FIDIdentity;
			//var data = [selectedData.ID];
			var data = [{
				FIDIdentity: selectedData.ID,
				FIDDestinationType: office.FIDDestinationType,
				Annotation: office.Annotation,
				DefaultSpedition: office.DefaultSpedition,
			}];
			fetch('/IR/AddExistingDestination/' + FIDIdentity, {
				body: JSON.stringify(data),
				method: 'POST'
			}).then(res => res.json())
				.then(result => {
					this.setState({ isOfficeAddExisting: false, officeAddStep1: false, officeAddStep2: false, office: {} });
				});
		});
	}
	handleDestinationAddExistingSelectClick = (event) => {
		this.handleOfficeCheckDefault(() => {
			var { office } = this.state;
			var data = this.grdExistingDestinations.current.getWrappedInstance().getSelectedData();
			data = data.map(item => {
				var relItem = {
					FIDIdentity: item,
					FIDDestinationType: office.FIDDestinationType,
					Annotation: office.Annotation,
					DefaultSpedition: office.DefaultSpedition,
				}
				return relItem;
			});
			var FIDIdentity = this.state.FIDIdentity;
			fetch('/IR/AddExistingDestination/' + FIDIdentity, {
				body: JSON.stringify(data),
				method: 'POST'
			}).then(res => res.json())
				.then(result => {
					this.setState({ isOfficeAddExisting: false, officeAddStep1: false, officeAddStep2: false, office: {} });
				});
		});
	}
	handleDestinationAddExistingSelectDoubleClickRelation = (_, selectedData) => {
		var { officeRelation } = this.state;
		var FIDIdentity = this.state.relation.ID;
		if (!FIDIdentity) {
			FIDIdentity = 0;
		}
		//var data = [selectedData.ID];
		var data = [{
			FIDIdentity: selectedData.ID,
			FIDDestinationType: officeRelation.FIDDestinationType,
			Annotation: officeRelation.Annotation,
			DefaultSpedition: officeRelation.DefaultSpedition,
		}];
		fetch('/IR/AddExistingDestination/' + FIDIdentity + '?isRelation=true', {
			body: JSON.stringify(data),
			method: 'POST'
		}).then(res => res.json())
			.then(result => {
				this.setState({ isOfficeAddExistingRelation: false, officeRelationAddStep1: false, officeRelationAddStep2: false, officeRelation: {} });
			});
	}
	handleDestinationAddExistingSelectClickRelation = (event) => {
		var { officeRelation } = this.state;
		var FIDIdentity = this.state.relation.ID;
		if (!FIDIdentity) {
			FIDIdentity = 0;
		}
        var data = this.grdExistingDestinationsRelation.current.getWrappedInstance().getSelectedData();
        var data = this.grdExistingDestinations.current.getWrappedInstance().getSelectedData();
		data = data.map(item => {
			var relItem = {
				FIDIdentity: item,
				FIDDestinationType: officeRelation.FIDDestinationType,
				Annotation: officeRelation.Annotation,
				DefaultSpedition: officeRelation.DefaultSpedition,
			}
			return relItem;
		});
		fetch('/IR/AddExistingDestination/' + FIDIdentity + '?isRelation=true', {
			body: JSON.stringify(data),
			method: 'POST'
		}).then(res => res.json())
			.then(result => {
				this.setState({ isOfficeAddExistingRelation: false, officeRelationAddStep1: false, officeRelationAddStep2: false, officeRelation: {} });
			});
	}
	renderTab2 = () => {
		var { office, data } = this.state;
		var customActions = [
			{
				group: 'actions',
				name: getTranslation(4,"IR_ADD_EXISTING", this.props.translations,'Aggiungi esistenti'),
				icon: <HomePlus />,
				action: this.handleDestinationAddExistingClick
			},
		];
		var existingCustomActions = [
			{
				group: 'actions',
				name: getTranslation(5,"GC_SELECT", this.props.translations,'Seleziona'),
				icon: <Check />,
				action: this.handleDestinationAddExistingSelectClick,
				condition: rows => rows.length === 1
			},
			{
				group: 'actions',
				name: getTranslation(5,"GC_CANCEL", this.props.translations,'Annulla'),
				icon: <Close />,
				action: this.handleDestinationAddExistingCancelClick
			}
		];
		var existingCustomSelectedActions = [
		];
		if (this.state.officeAddStep1) {
			existingCustomActions.push(
				{
					group: 'actions',
					name: getTranslation(5,"GC_NEXT", this.props.translations,'Avanti'),
					icon: <ArrowRight />,
					action: this.handleOfficeAddExistingNextClick
				}
			);
		}
		else {
			existingCustomActions.push(
				{
					group: 'actions',
					name: getTranslation(5,"GC_BACK", this.props.translations,'Indietro'),
					icon: <ArrowLeft />,
					action: this.handleOfficeAddExistingPreviousClick
				}
			);
		}


		var destinationHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
			<Grid item xs={12} sm={6}>
				<img alt="" src="" width="100%" />
			</Grid>
			<Grid item xs={12} sm={6} container>
				<Grid item xs={12} container direction="row" spacing={2}>
					<Grid item xs={12} md={6}><XSelect field={{ Required: 1 }} label={getTranslation(4, "IR_ADDRESS_TYPE", this.props.translations, "Tipo indirizzo")} id="FIDDestinationType" urlData="/IR/DestinationType" urlCreate="/IR/DestinationType" value={office.FIDDestinationType} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.onDestinationTypeChange} /></Grid>
					<Grid item xs={12} md={6}><XField label={getTranslation(4, "IR_ANNOTATION", this.props.translations, "Annotazione")} id="Annotation" value={office.Annotation} caller={this} dataKey="office" onChange={this.onDestinationAnnotationChange} /></Grid>
                    {/* <Grid item xs={12} md={6}><XSwitch label="Predefinito fatturazione" id="DefaultInvoice" value={office.DefaultInvoice} caller={this} dataKey="office" onChange={this.onDestinationFieldChange} /></Grid> */}
					<Grid item xs={12} md={6}><XSwitch label={getTranslation(4, "IR_SHIPMENT_DEFAULT", this.props.translations, "Predefinito spedizione")} id="DefaultSpedition" value={office.DefaultSpedition} caller={this} dataKey="office" onChange={this.onDestinationFieldChange} /></Grid>
				</Grid>
			</Grid>
		</Grid>);

		var panels = [];
		panels.push({
			icon: "people",
			label: getTranslation(4, "IR_ADDRESS_TYPE", this.props.translations, "Tipo indirizzo"),
			content: destinationHeadFields,
		});

		var customContent = undefined;
		var showColumnsSelector = true;
		var showFilterActivator = true;
		var onNewButton = this.handleOfficeNew;
		var label = undefined;
		var key = "tblIRDestinations";
		if (this.state.isOfficeEdit) {
			customContent = (
				<Fragment>
					<XExpansionPanel panels={panels} />
					<IRStandardFields innerRef={this.destination} data={office} dataJson={JSON.stringify(office)} dataKey="office" id={office.ID} caller={this} showCodes={false} isDestination={true} onChange={this.handleDestinationChange} onBlur={this.handleDestinationBlur} />
				</Fragment>
			);

			showColumnsSelector = false;
			showFilterActivator = false;
			customActions = [
				{
					group: 'actions',
					name: getTranslation(5,"GC_SAVE", this.props.translations, "Salva"),
					icon: <Save />,
					action: this.handleSaveOffice
				},
				{
					group: 'actions',
					name: getTranslation(5,"GC_BACK", this.props.translations, "Indietro"),
					icon: <ArrowLeft />,
					action: this.handleCancelOffice
				}
			];
			key = "frmIRDestinations";
			onNewButton = undefined;
			label = getTranslation(4, "IR_ADDRESS_NEW", this.props.translations, "Nuovo indirizzo");
		}

		var ui = <XGrid
			containerID="irTab2Container"
			label={label}
			key={key}
            ref={this.grdDestinations}
			dataRoute={"/IR/GetIRDestinations/" + data.ID + "?grid=tblIRDestinations"}
			dataRouteColumns="/Base/GetColumns?grid=tblIRDestinations"
			dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRDestinations"
			dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRDestinations"
			onDoubleClick={this.handleOfficeDoubleClick}
			dataRouteDelete={"/IR/DeleteDestinations/" + data.ID}
			onNewButton={onNewButton}
			onEditButton={this.handleOfficeDoubleClick}
			customContent={customContent}
			actions={customActions}
			rowsVisible={10}
			showColumnsSelector={showColumnsSelector}
			showFilterActivator={showFilterActivator}
		/>;

		if (this.state.isOfficeAddExisting) {
			var customGrid = undefined;
			var label = getTranslation(4,"IR_SELECT_EXISTING_IR", this.props.translations,"Seleziona anagrafiche esistenti");
			if (this.state.officeAddStep1) {
				customGrid = destinationHeadFields;
				label = getTranslation(5,"GC_COMPILE_AND_CONTINUE", this.props.translations,"Compila i dati e prosegui");
			}
			ui = <XGrid
				containerID="irTab2Container"
				label={label}
				key="tblIRExistingDestinations"
                ref={this.grdExistingDestinations}
				dataRoute={"/IR/GetIdentities/" + data.ID + "?grid=tblIRExistingDestinations&filterParam=D"}
				dataRouteColumns="/Base/GetColumns?grid=tblIRExistingDestinations"
				dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRExistingDestinations"
				dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRExistingDestinations"
				onDoubleClick={this.handleDestinationAddExistingSelectDoubleClick}
				rowsVisible={10}
				actions={existingCustomActions}
				selectedActions={existingCustomSelectedActions}
				customContent={customGrid}
			/>;
		}

		return (
			<TabContainer id="irTab2Container">
				{ui}
			</TabContainer>
		);
	}
	renderTabDestinationsRelation = () => {
		var { officeRelation, relation } = this.state;
		var customActions = [
			{
				group: 'actions',
				name: getTranslation(4,"IR_ADD_EXISTING", this.props.translations,'Aggiungi esistenti'),
				icon: <HomePlus />,
				action: this.handleDestinationAddExistingClickRelation
			}];
		var existingCustomActions = [
			{
				group: 'actions',
				name: getTranslation(5,"GC_CANCEL", this.props.translations,'Annulla'),
				icon: <Close />,
				action: this.handleDestinationAddExistingCancelClickRelation
			},
			{
				group: 'actions',
				name: getTranslation(5,"GC_SELECT", this.props.translations,'Seleziona'),
				icon: <Check />,
				action: this.handleDestinationAddExistingSelectClickRelation,
				condition: rows => rows.length === 1
			}];
		var existingCustomSelectedActions = [];

		var destinationHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
			<Grid item xs={12} sm={6}>
				<img alt="" src="" width="100%" />
			</Grid>
			<Grid item xs={12} sm={6} container>
				<Grid item xs={12} container direction="row" spacing={2}>
					<Grid item xs={12} md={6}><XSelect field={{ Required: 1 }} label="Tipo indirizzo" id="FIDDestinationType" urlData="/IR/DestinationType" urlCreate="/IR/DestinationType" value={officeRelation.FIDDestinationType} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.onDestinationRelationTypeChange} /></Grid>
					<Grid item xs={12} md={6}><XField label="Annotazione" id="Annotation" value={officeRelation.Annotation} caller={this} dataKey="officeRelation" onChange={this.onDestinationRelationAnnotationChange} /></Grid>
					<Grid item xs={12} md={6}><XSwitch label="Predefinito fatturazione" id="DefaultInvoice" value={officeRelation.DefaultInvoice} caller={this} dataKey="office" onChange={this.onDestinationRelationFieldChange} /></Grid>
					<Grid item xs={12} md={6}><XSwitch label="Predefinito spedizione" id="DefaultSpedition" value={officeRelation.DefaultSpedition} caller={this} dataKey="office" onChange={this.onDestinationRelationFieldChange} /></Grid>

				</Grid>
			</Grid>
		</Grid>);

		var panels = [];
		panels.push({
			icon: "people",
			label: getTranslation(4, "IR_ADDRESS_TYPE", this.props.translations, "Tipo indirizzo"),
			content: destinationHeadFields,
		});

		var customContent = undefined;
		var showColumnsSelector = true;
		var showFilterActivator = true;
		var onNewButton = this.handleOfficeNewRelation;
		var label = undefined;
		var key = "tblIRDestinationsRelation";
		if (this.state.isOfficeEditRelation) {
			customContent = (
				<Fragment>
					<XExpansionPanel panels={panels} />
					<IRStandardFields innerRef={this.destinationRelation} data={officeRelation} dataJson={JSON.stringify(officeRelation)} dataKey="officeRelation" id={officeRelation.ID} caller={this} showCodes={false} isDestination={true} onChange={this.handleDestinationChangeRelation} onBlur={this.handleDestinationBlurRelation} />
				</Fragment>
			);
			showColumnsSelector = false;
			showFilterActivator = false;
			customActions = [
				{
					group: 'actions',
					name: getTranslation(5,"GC_SAVE", this.props.translations, 'Salva'),
					icon: <Save />,
					action: this.handleSaveOfficeRelation,
				},
				{
					group: 'actions',
					name: getTranslation(5,"GC_BACK", this.props.translations, "Indietro"),
					icon: <ArrowLeft />,
					action: this.handleCancelOfficeRelation,
				}
			];
			key = "frmIRDestinationsRelation";
			onNewButton = undefined;
			label = getTranslation(4,"IR_NEW_ADDRESS", this.props.translations,"Nuovo indirizzo");
		}

		var FIDIdentity = relation.ID;
		if (!FIDIdentity) {
			FIDIdentity = 0;
		}

		var ui = <XGrid
			label={label}
			key={key}
            ref={this.grdDestinationsRelation}
			dataRoute={"/IR/GetIRDestinations/" + FIDIdentity + "?grid=tblIRDestinations&isRelation=true"}
			dataRouteColumns="/Base/GetColumns?grid=tblIRDestinations"
			dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRDestinations"
			dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRDestinations"
			onDoubleClick={this.handleOfficeDoubleClickRelation}
			dataRouteDelete={"/IR/DeleteDestinations/" + FIDIdentity + "?isRelation=true"}
			onNewButton={onNewButton}
			onEditButton={this.handleOfficeDoubleClickRelation}
			customContent={customContent}
			actions={customActions}
			showColumnsSelector={showColumnsSelector}
			showFilterActivator={showFilterActivator}
		/>;

		ui = this.state.isOfficeAddExistingRelation
			?
			(
				<XGrid
					label={getTranslation(4,"IR_SELECT_EXISTING_IR", this.props.translations,"Seleziona anagrafiche esistenti")}
					key="tblIRExistingDestinations"
                    ref={this.grdExistingDestinationsRelation}
					dataRoute={"/IR/GetIdentities/" + FIDIdentity + "?grid=tblIRExistingDestinations&filterParam=D"}
					dataRouteColumns="/Base/GetColumns?grid=tblIRExistingDestinations"
					dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRExistingDestinations"
					dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRExistingDestinations"
					onDoubleClick={this.handleDestinationAddExistingSelectDoubleClick}
					actions={existingCustomActions}
					selectedActions={existingCustomSelectedActions}
				/>
			) : ui;

		return (
			<TabContainer padding={0}>
				{ui}
			</TabContainer>
		);
	}
	onAnnotationChange = (key) => (event, value) => {
		this.setState((state, props) => {
			state.relation.Annotation = value;
			return state;
		});
	}
	onRelationTypeChange = (key) => (event, data) => {
		if (data && data.length > 0) {
			this.setState((state, props) => {
				state.relation.FIDRelation = data[0].ID;
				return state;
			});
		}
		else {
			this.setState(state => {
				state.relation.FIDRelation = undefined;
				return state;
			});
		}
	}

	onDestinationTypeChange = (key) => (event, data) => {
		if (data && data.length > 0) {
			this.setState((state, props) => {
				state.office.FIDDestinationType = data[0].ID;
				return state;
			});
		}
		else {
			this.setState(state => {
				state.office.FIDDestinationType = undefined;
				return state;
			});
		}
	}

	onDestinationAnnotationChange = (key) => (event, value) => {
		this.setState((state, props) => {
			state.office.Annotation = value;
			return state;
		});
	}
	onDestinationFieldChange = (key) => (event, value) => {
		this.setState(state => {
			state.office[key] = value;
			return state;
		});
	}

	onDestinationRelationTypeChange = (key) => (event, data) => {
		if (data && data.length > 0) {
			this.setState((state, props) => {
				state.officeRelation.FIDDestinationType = data[0].ID;
				return state;
			});
		}
		else {
			this.setState(state => {
				state.officeRelation.FIDDestinationType = undefined;
				return state;
			});
		}
	}

	onDestinationRelationAnnotationChange = (key) => (event, value) => {
		this.setState((state, props) => {
			state.officeRelation.Annotation = value;
			return state;
		});
	}
	onDestinationRelationFieldChange = (key) => (event, value) => {
		this.setState(state => {
			state.officeRelation[key] = value;
			return state;
		});
	}

	handleRelationAddExistingClick = () => {
		this.setState({ isRelationAddExisting: true, relationAddStep1: true, relationAddStep2: false, relation: {} });

	}
	handleRelationAddExistingCancelClick = () => {
		this.setState({ isRelationAddExisting: false, relationAddStep1: false, relationAddStep2: false, relation: {} });

	}
	handleRelationAddExistingNextClick = () => {
		this.setState({ relationAddStep1: false, relationAddStep2: true });
	}
	handleRelationAddExistingPreviousClick = () => {
		this.setState({ relationAddStep1: true, relationAddStep2: false });
	}


	handleRelationAddExistingSelectDoubleClick = (_, selectedData) => {
		var { relation } = this.state;
		var FIDIdentity = this.state.FIDIdentity;
		var data = [{
			FIDIdentityRelation: selectedData.ID,
			FIDRelation: relation.FIDRelation,
			Annotation: relation.Annotation
		}];
		fetch('/IR/AddExistingRelation/' + FIDIdentity, {
			body: JSON.stringify(data),
			method: 'POST'
		}).then(res => res.json())
			.then(result => {
				this.setState({ isRelationAddExisting: false, relationAddStep1: false, relationAddStep2: false, relation: {} });
			});
	}

	handleRelationAddExistingSelectClick = () => {
		var { relation } = this.state;
        var data = this.grdExistingRelations.current.getWrappedInstance().getSelectedData();
		var FIDIdentity = this.state.FIDIdentity;

		data = data.map(item => {
			var relItem = {
				FIDIdentityRelation: item,
				FIDRelation: relation.FIDRelation,
				Annotation: relation.Annotation
			}
			return relItem;
		});

		fetch('/IR/AddExistingRelation/' + FIDIdentity, {
			body: JSON.stringify(data),
			method: 'POST'
		}).then(res => res.json())
			.then(result => {
				this.setState({ isRelationAddExisting: false, relationAddStep1: false, relationAddStep2: false, relation: {} });
			});

	}
	renderTab3 = () => {
		var { relation, data } = this.state;
		var customActions = [
			{
				group: 'actions',
				name: 'Aggiungi esistenti',
				icon: <AccountMultiplePlus />,
				action: this.handleRelationAddExistingClick,
			}];
		var existingCustomActions = [
			{
				group: 'actions',
				name: 'Seleziona',
				icon: <Check />,
				action: this.handleRelationAddExistingSelectClick,
				condition: rows => rows.length === 1,
			},
			{
				group: 'actions',
				name: 'Annulla',
				icon: <Close />,
				action: this.handleRelationAddExistingCancelClick,
			}
		];
		var existingCustomSelectedActions = [];
		if (this.state.relationAddStep1) {
			existingCustomActions.push(
				{
					group: 'actions',
					name: 'Avanti',
					icon: <ArrowRight />,
					action: this.handleRelationAddExistingNextClick,
				});
		}
		else {
			existingCustomActions.push(
				{
					group: 'actions',
					name: 'Indietro',
					icon: <ArrowLeft />,
					action: this.handleRelationAddExistingPreviousClick,
				});
		}

		var relationHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
			<Grid item xs={12} sm={6}>
				<img alt="" src="" width="100%" />
			</Grid>
			<Grid item xs={12} sm={6} container>
				<Grid item xs={12} container direction="row" spacing={2}>
					<Grid item xs={12} md={6}><XSelect field={{ Required: 2 }} label="Relazione" id="FIDRelation" urlData="/Base/GetIRRelations" urlCreate="/IR/createrelationtype" value={relation.FIDRelation} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.onRelationTypeChange} /></Grid>
					<Grid item xs={12} md={6}><XField label="Annotazione" id="Annotation" value={relation.Annotation} caller={this} dataKey="relation" onChange={this.onAnnotationChange} /></Grid>
				</Grid>
			</Grid>
		</Grid>);

		var panels = [];
		panels.push({
			icon: "people",
			label: "Relazione",
			content: relationHeadFields,
		});

		var tabs = undefined;
		var selectedTab = undefined;
		var onTabChange = undefined;

		var customContent = undefined;
		var showColumnsSelector = true;
		var showFilterActivator = true;
		var onNewButton = this.handleRelationNew;
		var label = undefined;
		var key = "tblIRRelations";
		if (this.state.isRelationEdit) {
			var { extratabsRelations, extragroupsRelation, extrasRelation } = this.state;
			showColumnsSelector = false;
			showFilterActivator = false;
			customActions = [
				{
					group: 'actions',
					name: getTranslation(5,"GC_SAVE", this.props.translations, "Salva"),
					icon: <Save />,
					action: this.handleSaveRelation,
				},
				{
					group: 'actions',
					name: 'Indietro',
					icon: <ArrowLeft />,
					action: this.handleCancelRelation,
				}
			];
			key = "frmIRRelations";
			onNewButton = undefined;
			label = "Nuova relazione";

			tabs = [
				{ tabName: 'Generale' },//0
				{ tabName: 'Indirizzi' },//1
				{ tabName: 'Files' },//2
				{ tabName: 'Privacy' },//3
				{ tabName: 'Profilo' },//4
			];
			if (extratabsRelations) {
				var FIDTabs = extrasRelation.map(m => m.FIDExtraTab).filter((value, index, self) => self.indexOf(value) === index);
				tabs = tabs.concat(extratabsRelations.filter(f => FIDTabs.includes(f.ID)).map((item, index) => ({
					tabName: item.Description,
					//tabContent: this.renderExtraTab(item),
				})));
				var extrasNoTab = extrasRelation.filter(m => !m.FIDExtraTab);
				if (extrasNoTab.length > 0) {
					tabs = tabs.concat({
						tabName: 'CUSTOM',
					});
				}
			}

			selectedTab = this.state.relationTab || 0;

			switch (selectedTab) {
				case 0:
					customContent = (<Fragment>
						<XExpansionPanel panels={panels} />
						<IRStandardFields innerRef={this.relation} data={relation} dataJson={JSON.stringify(relation)} dataKey="relation" id={relation.ID} caller={this} showCodes={false} isRelation={true} onChange={this.handleRelationChange} onBlur={this.handleRelationBlur} />
					</Fragment>);
					break;
				case 1:
					customContent = this.renderTabDestinationsRelation();
					break;
				case 2:
					customContent = this.renderTabFilesRelation();
					break;
				case 3:
					customContent = this.renderTabGDPRRelation();
					break;
				case 4:
					customContent = this.renderTabRelationProfile();
					break;
				default:
					var item = extratabsRelations.filter(f => FIDTabs.includes(f.ID))[selectedTab - 5];
					customContent = this.renderExtraTabRelation(item);
					break;
			}


			onTabChange = (_, value) => {
				if (!this.state.relationTab || this.state.relationTab === 0) {
					if (this.relation.current) {
						var { isValid, requiredFields, optionalFields } = this.relation.current.validate();
						if (!this.state.relation.FIDRelation) {
							isValid = false;
							requiredFields.unshift("FIDRelation");
						}
						if (!isValid) {
							this.showErrorMessage(requiredFields, optionalFields, () => {
								this.setState({ relationTab: value });
							});
							return;
						}
						else {
							this.setState({ relationTab: value });
						}
					}
				}
				else {
					this.setState({ relationTab: value });
				}
			};
		}
		var ui = <XGrid
			containerID="irTabContainer3"
			key={key}
            ref={this.grdRelations}
			dataRoute={"/IR/GetIRRelations/" + data.ID + "?grid=tblIRRelations"}
			dataRouteColumns="/Base/GetColumns?grid=tblIRRelations"
			dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRRelations"
			dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRRelations"
			onDoubleClick={this.handleRelationDoubleClick}
			dataRouteDelete={"/IR/DeleteRelation/" + data.ID}
			onNewButton={onNewButton}
			onEditButton={this.handleRelationDoubleClick}
			actions={customActions}
			customContent={customContent}
			actions={customActions}
			showColumnsSelector={showColumnsSelector}
			showFilterActivator={showFilterActivator}
			tabs={tabs}
			selectedTab={selectedTab}
			onTabChange={onTabChange}
			rowsVisible={10}
		/>;
		if (this.state.isRelationAddExisting) {
			var customGrid = undefined;
			var label = "Seleziona anagrafiche esistenti";
			if (this.state.relationAddStep1) {
				customGrid = relationHeadFields;
				label = "Compila i dati e prosegui";
			}
			ui = <XGrid
				containerID="irTabContainer3"
				label={label}
				key="tblIRExistingRelations"
                ref={this.grdExistingRelations}
				dataRoute={"/IR/GetIdentities/" + data.ID + "?grid=tblIRExistingRelations&filterParam=R"}
				dataRouteColumns="/Base/GetColumns?grid=tblIRExistingRelations"
				dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRExistingRelations"
				dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRExistingRelations"
				onDoubleClick={this.handleRelationAddExistingSelectDoubleClick}
				actions={existingCustomActions}
				selectedActions={existingCustomSelectedActions.map((item, key) => item)}
				customContent={customGrid}
				rowsVisible={10}
			/>

		}

		return (
			<TabContainer id="irTabContainer3">
				{ui}
			</TabContainer>
		);

	}

	onFileSelectChange = (key) => (event, data) => {
		//alert(key);
		//alert(JSON.stringify(data));

		this.setState(state => {
			if (data.length > 0) {
				state.file[key] = data[0].ID;
			}
			else {
				state.file[key] = undefined;
			}
			return state;
		});
	}
	onFileRelationSelectChange = (key) => (event, data) => {
		//alert(key);
		//alert(JSON.stringify(data));

		this.setState(state => {
			if (data.length > 0) {
				state.fileRelation[key] = data[0].ID;
			}
			else {
				state.fileRelation[key] = undefined;
			}
			return state;
		});
	}
	onFileChange = (key) => (event, data) => {
		this.setState(state => {
			if (data) {
				state.file[key] = data;
			}
			else {
				state.file[key] = undefined;
			}
			return state;
		});
	}
	onFileRelationChange = (key) => (event, data) => {
		this.setState(state => {
			if (data) {
				state.fileRelation[key] = data;
			}
			else {
				state.fileRelation[key] = undefined;
			}
			return state;
		});
	}
	handleFileNew = () => {
		this.setState({ isFileEdit: true, file: {} });
	}
	handleFileRelationNew = () => {
		this.setState({ isFileEditRelation: true, fileRelation: {} });
	}
	handleCancelFile = () => {
		this.setState({ isFileEdit: false, file: {} });
	}
	handleCancelFileRelation = () => {
		this.setState({ isFileEditRelation: false, fileRelation: {} });
	}
	saveFile = () => {

		var { file } = this.state;

		var FIDIdentity = this.state.FIDIdentity;
		if (!FIDIdentity) {
			FIDIdentity = 0;
		}

		this.setState({ isUploading: true, uploadProgress: 0 });

		const data = new FormData();

		data.append('file', file.File, file.File.name);
		data.append('FIDFileTypology', file.FIDFileTypology);
		data.append('Default', file.Default ? true : false);
		axios
			.post('/IR/UploadFile/' + FIDIdentity, data, {
				onUploadProgress: ProgressEvent => {
					this.setState({ uploadProgress: (ProgressEvent.loaded / ProgressEvent.total * 100) });
				},
				cancelToken: new CancelToken(c => {
					this.cancelUpload = c;
				})
			})
			.then(res => {
				this.setState({ isFileEdit: false, file: {}, isUploading: false });
                this.grdFiles.current.getWrappedInstance().refresh();
			})
			.catch(err => {
				this.setState({ isUploading: false });
			});

	}
	saveFileRelation = () => {

		var { fileRelation, relation } = this.state;

		var FIDIdentity = relation.ID;
		if (!FIDIdentity) {
			FIDIdentity = 0;
		}

		this.setState({ isUploadingRelation: true, uploadProgressRelation: 0 });

		const data = new FormData();

		data.append('file', fileRelation.File, fileRelation.File.name);
		data.append('FIDFileTypology', fileRelation.FIDFileTypology);
		data.append('Default', fileRelation.Default ? true : false);
		axios
			.post('/IR/UploadFile/' + FIDIdentity + '?isRelation=true', data, {
				onUploadProgress: ProgressEvent => {
					this.setState({ uploadProgressRelation: (ProgressEvent.loaded / ProgressEvent.total * 100) });
				},
				cancelToken: new CancelToken(c => {
					this.cancelUploadRelation = c;
				})
			})
			.then(res => {
				this.setState({ isFileEditRelation: false, fileRelation: {}, isUploadingRelation: false });
                this.grdFilesRelation.current.refresh();
			})
			.catch(err => {
				this.setState({ isUploadingRelation: false });
			});

	}

	showDialog = (buttons, title, message) => {
		var dialog = {
			message,
			title,
			buttons,
			show: true
		};
		this.setState({ dialog });
	}
	handleDownloadFile = () => {
        var FIDFIles = this.grdFiles.current.getWrappedInstance().getSelectedData();
		for (var i = 0; i < FIDFIles.length; i++) {
			window.open("/IR/DownloadFile/" + FIDFIles[i]);
		}
        this.grdFiles.current.getWrappedInstance().clearSelection();
	}
	handleDownloadAgreement = () => {
        var FIDAgreements = this.grdAgreements.current.getSelectedData();
		for (var i = 0; i < FIDAgreements.length; i++) {
			fetch("/IR/DownloadAgreement/" + FIDAgreements[i]).then(res => res.blob()).then(blob => {
				//blob.type = "application/pdf";
				window.open(window.URL.createObjectURL(blob));
			});
			//window.open("/IR/DownloadAgreement/" + FIDAgreements[i]);
		}
        this.grdAgreements.current.clearSelection();
	}
	handleNewGDPRAgreement = () => {
		this.setState({ newGDPR: true });
	}
	handleDownloadAgreementRelation = () => {
        var FIDAgreements = this.grdAgreementsRelation.current.getSelectedData();
		for (var i = 0; i < FIDAgreements.length; i++) {
			fetch("/IR/DownloadAgreement/" + FIDAgreements[i]).then(res => res.blob()).then(blob => {
				//blob.type = "application/pdf";
				window.open(window.URL.createObjectURL(blob));
			});
			//window.open("/IR/DownloadAgreement/" + FIDAgreements[i]);
		}
        this.grdAgreementsRelation.current.clearSelection();
	}
	handledeleteAgreement = () => {
        var FIDAgreements = this.grdAgreements.current.getSelectedData();
		var FIDIdentity = this.state.FIDIdentity;
		if (!FIDIdentity) {
			FIDIdentity = 0;
		}
		fetch('/IR/DeleteAgreement/' + FIDIdentity, {
			method: 'POST',
			body: JSON.stringify(FIDAgreements)
		}).then(res => {
			//this.context.router.history.push('/IR');
            this.grdAgreements.current.refresh();
		});
		//for (var i = 0; i < FIDAgreements.length; i++) {
		//    //window.open("/IR/DownloadAgreement/" + FIDAgreements[i]);
		//}
        this.grdAgreements.current.clearSelection();
	}
	handledeleteAgreementRelation = () => {
        var FIDAgreements = this.grdAgreementsRelation.current.getSelectedData();
		var FIDIdentity = this.state.relation.ID;
		if (!FIDIdentity) {
			FIDIdentity = 0;
		}
		fetch('/IR/DeleteAgreement/' + FIDIdentity, {
			method: 'POST',
			body: JSON.stringify(FIDAgreements)
		}).then(res => {
			//this.context.router.history.push('/IR');
            this.grdAgreementsRelation.current.refresh();
		});
		//for (var i = 0; i < FIDAgreements.length; i++) {
		//    //window.open("/IR/DownloadAgreement/" + FIDAgreements[i]);
		//}
        this.grdAgreementsRelation.current.clearSelection();
	}
	handleSaveFile = () => {
		var { file } = this.state;
		if (!file.File || file.File === {}) {
			return;
		}
		var FIDIdentity = this.state.FIDIdentity;
		if (!FIDIdentity) {
			FIDIdentity = 0;
		}

		if (file.Default) {
			var tempFile = {
				ID: file.ID,
				FIDFileTypology: file.FIDFileTypology
			};
			if (!tempFile.ID) {
				tempFile.ID = 0;
			}
			fetch('/IR/CheckDefaultFiles/' + FIDIdentity, {
				body: JSON.stringify(tempFile),
				method: 'POST'
			}).then(res => res.json())
				.then(result => {
					if (result.HasDefault) {
						var buttons = [
							{
								label: "SI",
								action: () => {
									this.setState({ dialog: {} });
									this.saveFile();
								}
							},
							{
								label: "NO",
								action: () => {
									this.setState({ dialog: {} });
									//this.saveContact(FIDIdentity, isDestination, isRelation, data);
								}
							},
						];

						this.showDialog(buttons, "File predefinito", "È già presente un record predefinito con questa tipologia. Confermi inserimento?");
					}
					else {
						this.saveFile();
					}
				});
		}
		else {
			this.saveFile();
		}

	}
	handleSaveFileRelation = () => {
		var { fileRelation, relation } = this.state;
		if (fileRelation.File === {}) {
			return;
		}
		var FIDIdentity = relation.ID;
		if (!FIDIdentity) {
			FIDIdentity = 0;
		}

		if (fileRelation.Default) {
			var tempFile = {
				ID: fileRelation.ID,
				FIDFileTypology: fileRelation.FIDFileTypology
			};
			if (!tempFile.ID) {
				tempFile.ID = 0;
			}
			fetch('/IR/CheckDefaultFiles/' + FIDIdentity + '?isRelation=true', {
				body: JSON.stringify(tempFile),
				method: 'POST'
			}).then(res => res.json())
				.then(result => {
					if (result.HasDefault) {
						var buttons = [
							{
								label: "SI",
								action: () => {
									this.setState({ dialog: {} });
									this.saveFileRelation();
								}
							},
							{
								label: "NO",
								action: () => {
									this.setStateRelation({ dialog: {} });
									//this.saveContact(FIDIdentity, isDestination, isRelation, data);
								}
							},
						];

						this.showDialog(buttons, "File predefinito", "È già presente un record predefinito con questa tipologia. Confermi inserimento?");
					}
					else {
						this.saveFileRelation();
					}
				});
		}
		else {
			this.saveFileRelation();
		}

	}
	handleFileDoubleClick = (event, data) => {
		this.setState({ file: data, isFileEdit: true });
	};
	handleFileRelationDoubleClick = (event, data) => {
		this.setState({ fileRelation: data, isFileEditRelation: true });
	};

	renderTabFilesRelation = () => {
		const { classes } = this.props;
		var { fileRelation, relation, isUploadingRelation, uploadProgressRelation } = this.state;


		var fileHeadFields = (
			<Grid container spacing={2} style={{ width: "100%" }}>
				<Grid item xs={12} container>
					<Grid item xs={12} container direction="row" spacing={2}>
						<Grid item xs={12} md={3}><XSelect label="Tipologia" id="FIDFileTypology" urlData="/Base/GetFileTypologies" urlCreate="/Base/CreateFileTypology" value={fileRelation.FIDFileTypology} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.onFileRelationSelectChange} /></Grid>
						<Grid item xs={12} md={3}><XSwitch label="Predefinito" id="Default" value={fileRelation.Default} onChange={this.onFileRelationChange} /></Grid>
						<Grid item xs={12} md={3}><XUpload label="File" id="File" value={fileRelation.File} onChange={this.onFileRelationChange} /></Grid>
					</Grid>
				</Grid>
			</Grid>
		);

		var panels = [];
		panels.push({
			icon: "attachment",
			label: "Files",
			content: fileHeadFields,
		});
		var customContent = undefined;

		var doubleClick = this.handleFileRelationDoubleClick;
		var newClick = this.handleFileRelationNew;
		var showColumnSelector = true;
		var showFilterActivator = true;
		var customActions = [
			{
				group: 'actions',
				name: getTranslation(5,"GC_DOWNLOAD", this.props.translations, "Scarica"),
				icon: <Download />,
				action: this.handleDownloadFileRelation,
				condition: r => r.length > 0
			}
		];
		//var selectedActions = [(
		//	<Tooltip title="Salva">
		//		<IconButton aria-label="Salva" onClick={this.handleDownloadFileRelation} >
		//			<Download />
		//		</IconButton>
		//	</Tooltip>
		//),];
		if (this.state.isFileEditRelation) {
			customContent = (<Fragment>
				<XExpansionPanel panels={panels} />
				{
					//<XButton color="primary" onClick={this.handleCancelFile}><Icon>cancel</Icon> Annulla</XButton>
					//<XButton color="primary" onClick={this.handleSaveFile}><Icon>done</Icon> Salva</XButton>
				}
			</Fragment>);
			doubleClick = undefined;
			newClick = undefined;
			showFilterActivator = false;
			showColumnSelector = false;
			//selectedActions = [];
			customActions = [
				{
					group: 'actions',
					name: getTranslation(5,"GC_SAVE", this.props.translations, "Salva"),
					icon: <Check />,
					action: this.handleSaveFileRelation
				},
				{
					group: 'actions',
					name: getTranslation(5,"GC_CANCEL", this.props.translations, "Annulla"),
					icon: <Close />,
					action: this.handleCancelFileRelation
				},
			];
		}
		var customCells = {
			Preview: (data, column) => {
				return <img style={{
					height: "auto", width: "auto", maxHeight: "103px", maxWidth: column.width - 10
				}} src={"data:image/png;base64, " + data.base64file} />
			}
		};
		var ui = (
			<XGrid
				containerID="tabIRFiles"
                innerRef={this.grdFilesRelation}
				dataRoute={"/IR/GetIRFiles/" + relation.ID + "?grid=tblFiles&isRelation=true"}
				dataRouteColumns="/Base/GetColumns?grid=tblFiles"
				dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblFiles"
				dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblFiles"
				//onDoubleClick={doubleClick}
				dataRouteDelete={"/IR/DeleteFile/" + relation.ID + "?isRelation=true"}
				onNewButton={newClick}
				//onEditButton={doubleClick}
				customContent={customContent}
				showColumnsSelector={showColumnSelector}
				showFilterActivator={showFilterActivator}
				actions={customActions}
				//selectedActions={selectedActions}
				customCells={customCells}
				rowHeight={105}
			/>
		)

		return (
			<TabContainer id="tabIRFiles" padding={0} style={{ height: "calc(100vh - 124px)" }}>
				<XProgress progress={uploadProgressRelation} total={100} label="Caricamento file in corso..." onCancel={() => {
					if (this.cancelUploadRelation) {
						this.cancelUploadRelation();
						this.setState({ isUploadingRelation: false });
					}
				}} cancelLabel="Annulla" show={isUploadingRelation} />
				{ui}
			</TabContainer>
		);
	}
	renderTab4 = () => {
		const { classes } = this.props;
		var { file, data, isUploading, uploadProgress } = this.state;


		var fileHeadFields = (
			<Grid container spacing={2} style={{ width: "100%" }}>
				<Grid item xs={12} container>
					<Grid item xs={12} container direction="row" spacing={2}>
						<Grid item xs={12} md={3}><XSelect label="Tipologia" id="FIDFileTypology" urlData="/Base/GetFileTypologies" urlCreate="/Base/CreateFileTypology" value={file.FIDFileTypology} idKey="ID" valueKey="Description" abbreviationKey="Abbreviation" onChange={this.onFileSelectChange} /></Grid>
						<Grid item xs={12} md={3}><XSwitch label="Predefinito" id="Default" value={file.Default} onChange={this.onFileChange} /></Grid>
						<Grid item xs={12} md={3}><XUpload label="File" id="File" value={file.File} onChange={this.onFileChange} /></Grid>
					</Grid>
				</Grid>
			</Grid>
		);

		var panels = [];
		panels.push({
			icon: "attachment",
			label: "Files",
			content: fileHeadFields,
		});
		var customContent = undefined;

		var doubleClick = this.handleFileDoubleClick;
		var newClick = this.handleFileNew;
		var showColumnSelector = true;
		var showFilterActivator = true;
		var customActions = [];
		var selectedActions = [(
			<Tooltip title="Salva">
				<IconButton aria-label="Salva" onClick={this.handleDownloadFile} >
					<Download />
				</IconButton>
			</Tooltip>
		),];
		if (this.state.isFileEdit) {
			customContent = (<Fragment>
				<XExpansionPanel panels={panels} />
				{
					//<XButton color="primary" onClick={this.handleCancelFile}><Icon>cancel</Icon> Annulla</XButton>
					//<XButton color="primary" onClick={this.handleSaveFile}><Icon>done</Icon> Salva</XButton>
				}
			</Fragment>);
			doubleClick = undefined;
			newClick = undefined;
			showFilterActivator = false;
			showColumnSelector = false;
			selectedActions = [];
			customActions = [
				{
					group: 'actions',
					name: getTranslation(5,"GC_SAVE", this.props.translations, "Salva"),
					icon: <Check />,
					action: this.handleSaveFile
				},
				{
					group: 'actions',
					name: getTranslation(5,"GC_CANCEL", this.props.translations, "Annulla"),
					icon: <Close />,
					action: this.handleCancelFile
				}
				//(
				//	<Tooltip title="Salva">
				//		<IconButton aria-label="Salva" onClick={this.handleSaveFile} >
				//			<Check />
				//		</IconButton>
				//	</Tooltip>
				//),
				//(
				//	<Tooltip title="Annulla">
				//		<IconButton aria-label="Annulla" onClick={this.handleCancelFile} >
				//			<Close />
				//		</IconButton>
				//	</Tooltip>
				//)
			];
		} else {
			customActions = [
				{
					group: 'actions',
					name: 'Download',
					icon: <Download />,
					action: this.handleDownloadFile,
					condition: r => r.length > 0
				}
			]
		}
		var customCells = {
			Preview: (data, column) => {
				return <img style={{
					height: "auto", width: "auto", maxHeight: "103px", maxWidth: column.width - 10
				}} src={"data:image/png;base64, " + data.base64file} />
			}
		};
		var ui = (
			<XGrid
				containerID="irTabContainer4"
                ref={this.grdFiles}
				dataRoute={"/IR/GetIRFiles/" + data.ID + "?grid=tblFiles"}
				dataRouteColumns="/Base/GetColumns?grid=tblFiles"
				dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblFiles"
				dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblFiles"
				//onDoubleClick={doubleClick}
				dataRouteDelete={"/IR/DeleteFile/" + data.ID}
				onNewButton={newClick}
				//onEditButton={doubleClick}
				customContent={customContent}
				showColumnsSelector={showColumnSelector}
				showFilterActivator={showFilterActivator}
				actions={customActions}
				customCells={customCells}
				rowHeight={105}
			/>
		)

		return (
			<TabContainer id="irTabContainer4" padding={0} style={{ height: "calc(100vh - 124px)" }}>
				<XProgress progress={uploadProgress} total={100} label="Caricamento file in corso..." onCancel={() => {
					if (this.cancelUpload) {
						this.cancelUpload();
					}
					this.setState({ isUploading: false });
				}} cancelLabel="Annulla" show={isUploading} />
				{ui}
			</TabContainer>
		);

	}
	renderTabGDPRRelation = () => {
		var { relation } = this.state;
		var actions = [
			{
				group: 'actions',
				name: 'Download',
				icon: <Download />,
				action: this.handleDownloadAgreementRelation,
				condition: r => r.length > 0,
			},
			{
				group: 'actions',
				name: 'Elimina',
				icon: <TrashCan />,
				action: this.handledeleteAgreementRelation,
				condition: r => r.length > 0,
			},
		];
		var ui = (
			<XGrid
				containerID="irTabContainerGDPRRelation"
        innerRef={this.grdAgreementsRelation}
				dataRoute={"/IR/GetIRAgreements/" + relation.ID + "?grid=tblIRAgreements"}
				dataRouteColumns="/Base/GetColumns?grid=tblIRAgreements"
				dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRAgreements"
				dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRAgreements"
				//onDoubleClick={doubleClick}
				//dataRouteDelete={"/IR/DeleteFile/" + data.ID}
				//onNewButton={newClick}
				//onEditButton={doubleClick}
				//customContent={customContent}
				//showColumnsSelector={showColumnSelector}
				//showFilterActivator={showFilterActivator}
				//actions={customActions}
				actions={actions}
			//customCells={customCells}
			//rowHeight={105}
			/>
		)
		return (
			<TabContainer id="irTabContainerGDPRRelation" padding={0} style={{ height: "calc(100vh - 124px)" }}>
				{ui}
			</TabContainer>
		);
	}
	renderTabGDPR = () => {
		var { data, newGDPR } = this.state;
		var newClick = this.handleNewGDPRAgreement;
		var selectedActions = [
			{
				group: 'actions',
				name: 'Elimina',
				action : this.handledeleteAgreement,
				icon: <TrashCan />,
				condition: r => r.length > 0
			},
			{
				group: 'actions',
				name: 'Download',
				action : this.handleDownloadAgreement,
				icon: <Download />,
				condition: r => r.length > 0
			},
		];

		var customContent = undefined;

		if (newGDPR) {
			customContent = (<GDPR step={3} data={data} onExit={() => { this.setState({ newGDPR: false }, this.grdAgreements.current.refresh)}} inIR />);
			newClick = undefined;
		}

		var ui = (
			<XGrid
				containerID="irTabContainerGDPR"
        innerRef={this.grdAgreements}
				dataRoute={"/IR/GetIRAgreements/" + data.ID + "?grid=tblIRAgreements"}
				dataRouteColumns="/Base/GetColumns?grid=tblIRAgreements"
				dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRAgreements"
				dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRAgreements"
				//onDoubleClick={doubleClick}
				//dataRouteDelete={"/IR/DeleteFile/" + data.ID}
				onNewButton={newClick}
				//onEditButton={doubleClick}
				customContent={customContent}
				//showColumnsSelector={showColumnSelector}
				//showFilterActivator={showFilterActivator}
				//actions={customActions}
				actions={selectedActions}
			//customCells={customCells}
			//rowHeight={105}
			/>
		)
		return (
			<TabContainer id="irTabContainerGDPR" padding={0} style={{ height: "calc(100vh - 145px)" }}>
				{ui}
			</TabContainer>
		);
	}
	renderTabProfile = () => {
		return (
			<TabContainer>
				<Profile id={this.state.FIDIdentity} translations={this.props.translations} />
			</TabContainer>
		);
	}
	renderTabRelationProfile = () => {
		return (
			<TabContainer>
				<Profile id={this.state.relation.ID} translations={this.props.translations} />
			</TabContainer>
		);
	}
	renderTabSells = () => {
		return (
			<TabContainer>
				<IdentitySells id={this.state.FIDIdentity} width={this.props.width} translations={this.props.translations} />
			</TabContainer>
		);
	}

	renderTabAgentPricelists = () => {
		return (
			<TabContainer>
				{this.renderPanelAgentPricelists()}
			</TabContainer>
		);
	}

	handleUserNew = () => {
		this.setState({ isUserNew: true, isUserEdit: true, user: { Datestart: new Date()} });
	}
	handleUserCancel = () => {
		this.setState({ isUserEdit: false, user: {} });
        this.grdUsers.current.refresh();
	}

	handleUserSave = () => {
		var { user } = this.state;
		var error = false;
		var msgs = [];
		if (!user.IsProductionOperator) {
			if (!user.PlatformIDs || !user.FIDUserType || !user.Email || !user.Username || !user.Password) {
				error = true;
				msgs.push(<Typography>Verificare e compilare tutti i campi obbligatori prima di procedere!</Typography>);
			}
			if (user.Password !== user.ConfirmPassword) {
				error = true;
				msgs.push(<Typography>La password di verifica non coincide con la password inserita!</Typography>);
			}
			if (user.PIN && user.PIN.length !== 8) {
				error = true;
				msgs.push(<Typography>Il PIN inserito non è valido!</Typography>);
			}
			if (user.PIN && user.PIN !== user.ConfirmPIN) {
				error = true;
				msgs.push(<Typography>Il PIN di verifica non coincide con il PIN inserito!</Typography>);
			}
		}
		if (!error) {
			this.saveUser();
		}
		else {
            this.MessageBox.current.showMessageOk('Inserimento/Modifica utente', <Fragment>{msgs.map((item, key) => item)}</Fragment>);
		}

	}

	handleUserGridDoubleClick = (event, data) => {
        this.grdUsers.current.clearSelection();
		this.setState({ user: data, isUserEdit: true });
	}

	saveUser = () => {

		var { user } = this.state;

		var FIDIdentity = this.state.FIDIdentity;
		if (!FIDIdentity) {
			FIDIdentity = 0;
		}

		if (user.PlatformIDs && typeof user.PlatformIDs === "string") {
			user.PlatformIDs = user.PlatformIDs.split(",").map(Number);
		}
		fetch('/IR/SaveUser/' + FIDIdentity, {
			body: JSON.stringify(user),
			method: 'POST'
		})
			.then(res => res.json()).then(data => {
				this.setState({ isUserNew: false, isUserEdit: false, user: {} });
          this.grdUsers.current.refresh();
			});

	}

	onUserSelectChange = (key) => (event, data) => {
		this.setState((state, props) => {
			if (data.length > 0) {
				state.user[key] = data[0].ID;

				if (key === "FIDTypology") {
					if (data[0].ID === props.params.EmployeeTypologyId)
						state.user.IsProductionOperator = true;
					else
						state.user.IsProductionOperator = false;
				}
			}
			else {
				state.user[key] = undefined;
			}
			return state;
		});

	}
	onUserChange = (key) => (event, data) => {
		this.setState(state => {
			if (data) {
				state.user[key] = data;
			}
			else {
				state.user[key] = undefined;
			}
			return state;
		});
	}
	onUserDataChange = (key) => (data) => {
		this.setState(state => {
			if (data) {
				state.user[key] = data;
			}
			else {
				state.user[key] = undefined;
			}
			return state;
		});
	}


	handleUserDestinationNew = () => {
		this.setState({ isUserDestinationEdit: true, userDestination: {} });
	}
	handleUserDestinationCancel = () => {
		this.setState({ isUserDestinationEdit: false, userDestination: {} });
        this.grdUserDestinations.current.getWrappedInstance().refresh();
	}

	handleUserDestinationSave = () => {
		var { userDestination } = this.state;
		var error = false;
		var msgs = [];
		if (!userDestination.FIDDestination || !userDestination.FIDArea || userDestination.Email) {
			error = true;
			msgs.push(<Typography>Verificare e compilare tutti i campi obbligatori prima di procedere!</Typography>);
		}

		if (!error) {
			this.saveUserDestination();
		}
		else {
            this.MessageBox.current.showMessageOk('Inserimento/Modifica destinazione', <Fragment>{msgs.map((item, key) => item)}</Fragment>);
		}

	}
	onUserDestinationSelectChange = (key) => (event, data) => {
		this.setState(state => {
			if (data.length > 0) {
				state.userDestination[key] = data[0].ID;
			}
			else {
				state.userDestination[key] = undefined;
			}
			return state;
		});

	}
	onUserDestinationChange = (key) => (event, data) => {
		this.setState(state => {
			if (data) {
				state.userDestination[key] = data;
			}
			else {
				state.userDestination[key] = undefined;
			}
			return state;
		});
	}
	handleUserDestinationGridDoubleClick = (event, data) => {
        this.grdUserDestinations.current.getWrappedInstance().clearSelection();
		this.setState({ userDestination: data, isUserDestinationEdit: true });
	}

	saveUserDestination = () => {

		var { userDestination, user } = this.state;

		var FIDUser = user.ID;
		if (!FIDUser) {
			FIDUser = 0;
		}


		fetch('/IR/SaveUserDestination/' + FIDUser, {
			body: JSON.stringify(userDestination),
			method: 'POST'
		})
			.then(res => res.json()).then(data => {
				this.setState({ isUserDestinationEdit: false, userDestination: {} });
                this.grdUserDestinations.current.getWrappedInstance().refresh();
			});

	}

	onIRPricelistChange = (id) => (event, data) => {
		var { irPricelist } = this.state;
		
		irPricelist = data && data.length > 0 ? data[0].ID : undefined;
		
		this.setState({ irPricelist });
	}
	handleIRPricelistNew = () => {
		this.setState({
			irPricelist: undefined,
			isAddingPricelist: true
		});
	}
	handleIRPricelistSave = () => {
		var { irPricelist, data } = this.state;
		
		var error = false;
		var msgs = [];
		if (!irPricelist) {
			error = true;
			msgs.push(<Typography>Verificare e compilare tutti i campi obbligatori prima di procedere</Typography>);
		}
		if (error) {
            this.MessageBox.current.showMessageOk('Abbinamento listino', <Fragment>{msgs.map((item, key) => item)}</Fragment>);
			return;
		}
		
		var irPricelistObj = {
			FIDPriceList: irPricelist,
		};
		
		if (data && data.ID > 0) {
			irPricelistObj.FIDIdentity = data.ID;
		}
		
		fetch("/IR/SaveIRPricelist", {
			method: 'POST',
			body: JSON.stringify(irPricelistObj)
		}).then(res => res.json()).then(res => {
			this.setState({ irPricelist: undefined, isAddingPricelist: false}, () => {
				
				this.grdIRPricelists.current && this.grdIRPricelists.current.refresh()
				
			})
		});
	}
	handleIRPricelistCancel = () => {
		this.setState({
			irPricelist: undefined,
			isAddingPricelist: false
		});
	}
	
	renderPanelAgentPricelists = () => {
		var { irPricelist, data, isAddingPricelist } = this.state;
		
		var customContent = undefined;
		
		var newClick = this.handleIRPricelistNew;
		var showColumnSelector = true;
		var showFilterActivator = true;
		var customActions = [];
		if (isAddingPricelist) {
			var alreadyAddedPLs = []; 
			
			if (this.grdIRPricelists.current) { 
				var grdPLData = this.grdIRPricelists.current.getData();
				
				if (grdPLData.length > 0) {
					grdPLData.forEach((row) => { 
						var pl = this.grdIRPricelists.current.getRowData(row);
						alreadyAddedPLs.push({ key: "ID", value: pl.FIDPricelist });
					});
				}
			}
			
			var newIRPricelistContent = <Grid item xs={12} sm={6} md={3}><XSelect id="new_pricelist" field={ { Required: 2 }} label={getTranslation(5,"GC_PRICELIST", this.props.translations, "Listino")} urlData="/GC/Pricelist" idKey="ID" valueKey="Denomination" value={irPricelist} onChange={this.onIRPricelistChange} filtersExclude={alreadyAddedPLs} /></Grid>
			var panels = [{
				icon: "money",
				label: getTranslation(5,"IR_PRICELIST_NEW", this.props.translations, "Nuovo abbinamento listino"),
				content: newIRPricelistContent,
			}];
			customContent = <XExpansionPanel panels={panels} />;
			newClick = undefined;
			showFilterActivator = false;
			showColumnSelector = false;
			customActions = [
				{
					group: 'actions',
					name: getTranslation(5,"GC_SAVE", this.props.translations, "Salva"),
					icon: <Check />,
					action: this.handleIRPricelistSave
				},
				{
					group: 'actions',
					name: getTranslation(5,"GC_CANCEL", this.props.translations, "Annulla"),
					icon: <Close />,
					action: this.handleIRPricelistCancel
				}
			];
		}
		
		var ui = (
			<XGrid
                containerID="containerIRPricelists"
				label={getTranslation(5,"IR_PRICELISTS", this.props.translations, "Listini abbinati")}
                innerRef={this.grdIRPricelists}
				dataRoute={"/IR/GetIRPricelists/" + data.ID + "?grid=tblIRPricelists"}
				dataRouteColumns="/Base/GetColumns?grid=tblIRPricelists"
				dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblIRPricelists"
				dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblIRPricelists"
				onDoubleClick={undefined}
				dataRouteDelete="/IR/DeleteIRPricelists"
				onNewButton={newClick}
				onEditButton={undefined}
				customContent={customContent}
				showColumnsSelector={showColumnSelector}
				showFilterActivator={showFilterActivator}
				actions={customActions}
			/>
		)
		
		return (
            <Grid id="containerIRPricelists" container style={{ width: "100%", height: 300 }}>
				{ui}
			</Grid>
		);
	}

	renderPanelUserDestinations = () => {
		var { userDestination, user, data, isUserDestinationEdit } = this.state;


		var fields = [
			{ Field: "FIDDestination", Visibility: true, Required: 2 },
			{ Field: "FIDArea", Visibility: true, Required: 2 },
			{ Field: "Responsible", Visibility: true, Required: 2 },
		];

		var fieldDestination = getXSelect(userDestination, fields, 12, 3, "FIDDestination", "Destinazione", this.onUserDestinationSelectChange, "/IR/GetIRDestinationSelect", "", { filters: [{ key: "FIDIdentity", value: data.ID }] });
		var fieldArea = getXSelect(userDestination, fields, 12, 3, "FIDArea", "Reparto", this.onUserDestinationSelectChange, "/ir/area", "/ir/area");
		var fieldResponsible = getXSwitch(userDestination, fields, 12, 3, "Responsible", "Responsabile", this.onUserDestinationChange);

		var userHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
			<Grid item xs={12} container>
				<Grid item xs={12} container direction="row" spacing={2}>
					{fieldDestination}
					{fieldArea}
					{fieldResponsible}
				</Grid>
			</Grid>
		</Grid>);

		var customContent = undefined;

		var doubleClick = this.handleUserDestinationGridDoubleClick;
		var newClick = this.handleUserDestinationNew;
		var showColumnSelector = true;
		var showFilterActivator = true;
		var customActions = [];
		if (isUserDestinationEdit) {
			customContent = userHeadFields;
			doubleClick = undefined;
			newClick = undefined;
			showFilterActivator = false;
			showColumnSelector = false;
			customActions = [
				{
					group: 'actions',
					name: 'Salva',
					icon: <Check />,
					action: this.handleUserDestinationSave
				},
				{
					group: 'actions',
					name: 'Annulla',
					icon: <Close />,
					action: this.handleUserDestinationCancel
				}
			];
		}
		var ui = (
			<XGrid
                containerID="containerUserDestinations"
                ref={this.grdUserDestinations}
				dataRoute={"/IR/GetIRUserDestinations/" + user.ID + "?grid=tblUserDestinations"}
				dataRouteColumns="/Base/GetColumns?grid=tblUserDestinations"
				dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=tblUserDestinations"
				dataRouteUpdateColumns="/Base/UpdateColumns?grid=tblUserDestinations"
				onDoubleClick={doubleClick}
				dataRouteDelete={"/IR/DeleteUserDestination/" + user.ID}
				onNewButton={newClick}
				onEditButton={doubleClick}
				customContent={customContent}
				showColumnsSelector={showColumnSelector}
				showFilterActivator={showFilterActivator}
				actions={customActions}
				rowsVisible={5}
			/>
		)

		return (
            <Grid id="containerUserDestinations" container spacing={2} style={{ width: "100%", height: 300 }}>
				{ui}
			</Grid>
		);
	}
	renderPanelOperatorDepartsPhases = () => {
		var { user } = this.state;
		return (<OperatorDepartsPhases user={user} />);
	}
    renderTabAssignments = () => {
        var { data} = this.state;

        var ui = (
            <XGrid
                containerID="tabIRAssignments"
                ref={this.grdAssignments}
                dataRoute={"/BOP/GetBOPSStock/?FIDIRAssigned=" + data.ID + "&grid=grdIRAssignments"}
                dataRouteColumns="/Base/GetColumns?grid=grdIRAssignments"
                dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=grdIRAssignments"
                dataRouteUpdateColumns="/Base/UpdateColumns?grid=grdIRAssignments"
                rowsVisible={10}
            />
        )

        return (
            <TabContainer id="tabIRAssignments" padding={0} style={{ height: "calc(100vh - 124px)" }}>
                {ui}
            </TabContainer>
        );
    }
	renderTabUsers = () => {
		var { user, data, isUserNew, isUserEdit, userPrintBadge } = this.state;
		var Datestart = user.Datestart;
		if (!Datestart) {
			Datestart = new Date();
		}
		var pwdError = user.Password !== user.ConfirmPassword;
		var pinCError = user.PIN !== user.ConfirmPIN;
		var pinError = false;
		if (user.PIN && user.PIN.length !== 8) {
			pinError = true;
		}

		var fields = [
			{ Field: "PlatformIDs", Visibility: true, Required: 2 },
			{ Field: "FIDUserType", Visibility: true, Required: 2 },
			{ Field: "FIDTypology", Visibility: true, Required: 2 },
			{ Field: "Email", Visibility: true, Required: 2 },
			{ Field: "Username", Visibility: true, Required: 2 },
			{ Field: "Password", Visibility: true, Required: 2 },
			{ Field: "ConfirmPassword", Visibility: true, Required: 2 },
			{ Field: "ChangePassword", Visibility: false, Required: 1 },
			{ Field: "PIN", Visibility: true, Required: 2 },
			{ Field: "ConfirmPIN", Visibility: true, Required: 2 },
			{ Field: "ChangePIN", Visibility: false, Required: 1 },
			{ Field: "SecretQuestion", Visibility: false, Required: 1 },
			{ Field: "SecretAnswer", Visibility: false, Required: 1 },
			{ Field: "Datestart", Visibility: true, Required: 2 },
			{ Field: "Dateend", Visibility: true, Required: 3 },
		];

		if (user.IsProductionOperator) {
			fields = [
				{ Field: "FIDUserType", Visibility: true, Required: 2 },
				{ Field: "FIDTypology", Visibility: true, Required: 2 },
				{ Field: "Username", Visibility: true, Required: 2 },
				{ Field: "Datestart", Visibility: true, Required: 2 },
				{ Field: "Dateend", Visibility: true, Required: 3 },
			];
		}

		console.log(user.Email);
		var fieldPlatformsID = getXTag(user, fields, 12, 3, "PlatformIDs", "Piattaforme", this.onUserChange, "/GC/platform");
		var fieldFIDUserType = getXSelect(user, fields, 12, 3, "FIDUserType", "Autorizzazione", this.onUserSelectChange, "/GC/usertype");
		var fieldFIDProfile = getXSelect(user, fields, 12, 3, "FIDTypology", "Profilo", this.onUserSelectChange, "/Base/GetIRTypologies");
		var fieldEmail = getXField(user, fields, 12, 3, "Email", "Email", this.onUserChange);
		var fieldUsername = !user.IsProductionOperator ? getXField(user, fields, 12, 3, "Username", "Username", this.onUserChange) : getXField(user, fields, 12, 3, "Username", "Denominazione", this.onUserChange);

		var fieldChangePassword = getXSwitch(user, fields, 12, 3, "ChangePassword", "Cambio prossimo accesso", this.onUserChange);
		var fieldChangePin = getXSwitch(user, fields, 12, 3, "ChangePIN", "Cambio prossimo accesso", this.onUserChange);
		var fieldSecretQuestion = getXField(user, fields, 12, 4, "SecretQuestion", "Domanda segreta", this.onUserChange);
		var fieldSecretAnswer = getXField(user, fields, 12, 4, "SecretAnswer", "Risposta", this.onUserChange);

		var userHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
			<Grid item xs={12} container>
				<Grid item xs={12} container direction="row" spacing={2}>
					{!user.IsProductionOperator && fieldPlatformsID}
					{fieldFIDUserType}
					{fieldFIDProfile}
					{user.IsProductionOperator > 0 && fieldUsername}

					<Grid item xs={12} md={3}></Grid>
					{!user.IsProductionOperator && fieldEmail}
					{!user.IsProductionOperator && fieldUsername}
					<Grid item xs={12} md={6}></Grid>

					{!user.IsProductionOperator && <Grid item xs={12} md={4} xl={3}><XField field={{ Field: "Password", Visibility: true, Required: 2 }} label="Password" type="password" id="Password" value={user.Password} onChange={this.onUserChange} /></Grid>}
					{!user.IsProductionOperator && <Grid item xs={12} md={4} xl={3}><XField field={{ Field: "ConfirmPassword", Visibility: true, Required: 2 }} label="Verifica password" type="password" id="ConfirmPassword" value={user.ConfirmPassword} onChange={this.onUserChange} hasError={pwdError} /></Grid>}
					{fieldChangePassword || <Grid item xs={12} md={3}></Grid>}
					<Grid item xs={12} md={12} xl={3}></Grid>

					{!user.IsProductionOperator && <Grid item xs={12} md={4} xl={3}><XField label="PIN" type="password" id="PIN" value={user.PIN} onChange={this.onUserChange} hasError={pinError} /></Grid>}
					{!user.IsProductionOperator && <Grid item xs={12} md={4} xl={3}><XField label="Verifica PIN" type="password" id="ConfirmPIN" value={user.ConfirmPIN} onChange={this.onUserChange} hasError={pinCError} /></Grid>}
					{!user.IsProductionOperator && (fieldChangePin || <Grid item xs={12} md={3}></Grid>)}
					{!user.IsProductionOperator && <Grid item xs={12} md={12} xl={3}></Grid>}

					{!user.IsProductionOperator && fieldSecretQuestion}
					{!user.IsProductionOperator && fieldSecretAnswer}
					<Grid item xs={12} md={user.IsProductionOperator ? 3 : 2}><XDateField field={{ Field: "Datestart", Visibility: true, Required: 2 }} label="Data inizio attività" type="date" id="Datestart" value={Datestart} onChange={this.onUserDataChange} /></Grid>
					<Grid item xs={12} md={user.IsProductionOperator ? 3 : 2}><XDateField label="Data fine attività" type="date" id="Dateend" value={user.Dateend} onChange={this.onUserDataChange} /></Grid>
				</Grid>
			</Grid>
		</Grid>);
		var panels = [];
		panels.push({
			icon: "attachment",
			label: "Utenti",
			content: userHeadFields,
		});

		if (!user.IsProductionOperator) {
			panels.push({
				icon: "attachment",
				label: "Destinazioni",
				content: this.renderPanelUserDestinations()
			});
		}

		if (user.IsProductionOperator) {
			panels.push({
				icon: "work",
				label: "Reparti e fasi di lavorazione",
				content: this.renderPanelOperatorDepartsPhases()
			})
		}

		var customContent = undefined;

		var doubleClick = this.handleUserGridDoubleClick;
		var newClick = this.handleUserNew;
		var showColumnSelector = true;
		var showFilterActivator = true;
		var customActions = [];

		var selectedActions = [<Tooltip title="Stampa badge">
			<IconButton aria-label="Stampa" onClick={this.handleUserPrintBadge} >
				<Printer />
			</IconButton>
		</Tooltip>];

		customActions.push({
			group: 'actions',
			name: 'Stampa',
			icon: <Printer />,
			action: this.handleUserPrintBadge,
			condition: r => r.length > 0
		});

		if (userPrintBadge) {
			var printRows = this.grdUsers.current.getSelectedData();
			var s = "";
			for (var i = 0; i < printRows.length; i++) {
				var rowData = this.grdUsers.current.getRowData(printRows[i]);
				if (s.length === 0)
					s= "operators=" + rowData.FIDUser;
				else
				s += "&operators=" + rowData.FIDUser;
			}
			var url = "/Production/PrintOperatorBadge/?operators=" + user.FIDUser;

			if (s) {
				url = "/Production/PrintOperatorBadge/?" + s;
			}
			this.grdUsers.current.clearSelection();
			customContent = <XPDFViewer key="print_prod_operators" url={url} width="100%" height={document.body.offsetHeight - 350} />;
			customActions = [<Tooltip title="Indietro">
				<IconButton aria-label="Indietro" onClick={() => { this.setState({ userPrintBadge: false }) }}>
					<ArrowLeft />
				</IconButton>
			</Tooltip>];
		}
		else if (isUserEdit) {
			customContent = (<XExpansionPanel panels={panels} />);
			doubleClick = undefined;
			newClick = undefined;
			showFilterActivator = false;
			showColumnSelector = false;
			customActions = [
				(
					<Tooltip title="Stampa badge">
						<IconButton aria-label="Stampa" onClick={this.handleUserPrintBadge} >
							<Printer />
						</IconButton>
					</Tooltip>
				),
				(
					<Tooltip title="Salva">
						<IconButton aria-label="Salva" onClick={this.handleUserSave} >
							<Check />
						</IconButton>
					</Tooltip>
				),
				(
					<Tooltip title="Annulla">
						<IconButton aria-label="Annulla" onClick={this.handleUserCancel} >
							<Close />
						</IconButton>
					</Tooltip>
				)
			];

			if (isUserNew) {
				customActions.shift();
			}
		}
		var ui = (
			<XGrid
        containerID="tabUsersContainer"
        innerRef={this.grdUsers}
				dataRoute={"/IR/GetIRUsers/" + data.ID + "?grid=grdIRUsers"}
				dataRouteColumns="/Base/GetColumns?grid=grdIRUsers"
				dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=grdIRUsers"
				dataRouteUpdateColumns="/Base/UpdateColumns?grid=grdIRUsers"
				//selectedActions={selectedActions}
				onDoubleClick={doubleClick}
				dataRouteDelete={"/IR/DeleteUser/" + data.ID}
				onNewButton={newClick}
				onEditButton={doubleClick}
				customContent={customContent}
				showColumnsSelector={showColumnSelector}
				showFilterActivator={showFilterActivator}
				actions={customActions}
				rowsVisible={10}
			/>
		)

		return (
			<TabContainer id="tabUsersContainer" padding={0} style={{ height: "calc(100vh - 124px)" }}>
				{ui}
			</TabContainer>
		);
	}

	handleDistributionNew = () => {
		this.setState({ isDistributionEdit: true, distribution: {} });
	}
	handleDistributionCancel = () => {
		this.setState({ isDistributionEdit: false, distribution: {} });
        this.grdDistributions.current.getWrappedInstance().refresh();
	}

	handleDistributionSave = () => {
		var { distribution } = this.state;
		var error = false;
		var msgs = [];
		if ((!distribution.FIDFormula && !distribution.FIDMarkup) || !distribution.CalculationType) {
			error = true;
			msgs.push(<Typography>Verificare e compilare tutti i campi obbligatori prima di procedere!</Typography>);
		}
		if (!error) {
			this.saveDistribution();
		}
		else {
            this.MessageBox.current.showMessageOk('Inserimento/Modifica distribuzione', <Fragment>{msgs.map((item, key) => item)}</Fragment>);
		}

	}

	handleDistributionGridDoubleClick = (event, data) => {
        this.grdDistributions.current.getWrappedInstance().clearSelection();
		this.setState({ distribution: data, isDistributionEdit: true });
	}

	saveDistribution = () => {

		var { distribution } = this.state;

		var FIDIdentity = this.state.FIDIdentity;
		if (!FIDIdentity) {
			FIDIdentity = 0;
		}

		distribution.LicenseeIDs = fixTagValues(distribution.LicenseeIDs);
		distribution.GroupIDs = fixTagValues(distribution.GroupIDs);
		distribution.BrandIDs = fixTagValues(distribution.BrandIDs);
		distribution.LineIDs = fixTagValues(distribution.LineIDs);
		distribution.SeriesIDs = fixTagValues(distribution.SeriesIDs);

		fetch('/IR/SaveDistribution/' + FIDIdentity, {
			body: JSON.stringify(distribution),
			method: 'POST'
		})
			.then(res => res.json()).then(data => {
				this.setState({ isDistributionEdit: false, distribution: {} });
                this.grdDistributions.current.getWrappedInstance().refresh();
			});

	}


	onDistributionSelectChange = (key) => (event, data) => {
		this.setState(state => {
			if (data.length > 0) {
				state.distribution[key] = data[0].ID;
				
				if (key === "FIDFormula") {
					state.distribution.FIDMarkup = undefined;
					state.distribution.Markup = undefined;
				} else if (key === "FIDMarkup") {
					state.distribution.FIDFormula = undefined;
					state.distribution.Formula = undefined;
				}
			}
			else {
				state.distribution[key] = undefined;
			}
			return state;
		});

	}
	onDistributionChange = (key) => (event, data) => {
		this.setState(state => {
			if (data) {
				state.distribution[key] = data;
			}
			else {
				state.distribution[key] = undefined;
			}
			return state;
		});
	}
	renderTabDistributions = () => {
		var { distribution, data, isDistributionEdit } = this.state;

		var fields = [
			{ Field: "LicenseeIDs", Visibility: true, Required: 1 },
			{ Field: "BrandIDs", Visibility: true, Required: 1 },
			{ Field: "LineIDs", Visibility: true, Required: 1 },
			{ Field: "SeriesIDs", Visibility: true, Required: 1 },
			{ Field: "GroupIDs", Visibility: true, Required: 1 },
			{ Field: "FIDFormula", Visibility: true, Required: 2 },
			{ Field: "CalculationType", Visibility: true, Required: 2 },
			{ Field: "FIDMarkup", Visibility: true, Required: 2 },
		];

		var formulaLabel = "Sc %";
		if ([3, 4].includes(distribution.CalculationType)) {
			formulaLabel = "Ricarico %";
		}
		var fieldLicensees = getXTag(distribution, fields, 12, 3, "LicenseeIDs", "Licenziatario", this.onDistributionChange, "/IR/GetIRLicenseesSelect");
		var fieldBrands = getXTag(distribution, fields, 12, 3, "BrandIDs", "Marca", this.onDistributionChange, "/BOP/Brand");
		var fieldLines = getXTag(distribution, fields, 12, 3, "LineIDs", "Linea", this.onDistributionChange, "/BOP/Line");
		var fieldSeries = getXTag(distribution, fields, 12, 3, "SeriesIDs", "Serie", this.onDistributionChange, "/BOP/Series");
		var fieldGroups = getXTag(distribution, fields, 12, 3, "GroupIDs", "Gruppo", this.onDistributionChange, "/BOP/Group");
		var fieldFormula = getXSelect(distribution, fields, 12, 3, "FIDFormula", formulaLabel, this.onDistributionSelectChange, "/GC/formula", "/GC/formula");
		var fieldMarkup = getXSelect(distribution, fields, 12, 3, "FIDMarkup", "Formula di ricarico", this.onDistributionSelectChange, "/gc/markup" );
		var fieldCostCalculationType = getXSelect(distribution, fields, 12, 3, "CalculationType", "Tipo calcolo costo", this.onDistributionSelectChange, "/enums/boppricecalculationtype");

		var userHeadFields = (<Grid container spacing={2} style={{ width: "100%" }}>
			<Grid item xs={12} container>
				<Grid item xs={12} container direction="row" spacing={2}>
					{fieldLicensees}
					{fieldBrands}
					{fieldLines}
					{fieldSeries}
					{fieldGroups}
					{fieldCostCalculationType}
					{distribution.CalculationType === 5 ? fieldMarkup : fieldFormula}
				</Grid>
			</Grid>
		</Grid>);
		var panels = [];
		panels.push({
			icon: "attachment",
			label: "Distribuzioni",
			content: userHeadFields,
		});
		var customContent = undefined;

		var doubleClick = this.handleDistributionGridDoubleClick;
		var newClick = this.handleDistributionNew;
		var showColumnSelector = true;
		var showFilterActivator = true;
		var customActions = [];
		if (isDistributionEdit) {
			customContent = (<XExpansionPanel panels={panels} />);
			doubleClick = undefined;
			newClick = undefined;
			showFilterActivator = false;
			showColumnSelector = false;
			customActions = [
				{
					group: 'actions',
					name: getTranslation(5, 'GC_SAVE', this.props.translations, 'Salva'),
					icon: <Check />,
					action: this.handleDistributionSave
				},
				{
					group: 'actions',
					name: getTranslation(5, 'GC_CANCEL', this.props.translations, 'Annulla'),
					icon: <Close />,
					action: this.handleDistributionCancel
				}
			];
		}
		var ui = (
			<XGrid
				containerID="tabDistributionsContainer"
                ref={this.grdDistributions}
				dataRoute={"/IR/GetIRDistributions/" + data.ID + "?grid=grdIRDistributions"}
				dataRouteColumns="/Base/GetColumns?grid=grdIRDistributions"
				dataRouteAvailableColumns="/Base/GetAvailableColumns?grid=grdIRDistributions"
				dataRouteUpdateColumns="/Base/UpdateColumns?grid=grdIRDistributions"
				onDoubleClick={doubleClick}
				dataRouteDelete={"/IR/DeleteDistribution/" + data.ID}
				onNewButton={newClick}
				onEditButton={doubleClick}
				customContent={customContent}
				showColumnsSelector={showColumnSelector}
				showFilterActivator={showFilterActivator}
				actions={customActions}
				rowsVisible={10}
			/>
		)

		return (
			<TabContainer id="tabDistributionsContainer" padding={0} style={{ height: "calc(100vh - 124px)" }}>
				{ui}
			</TabContainer>
		);
	}

	doExit = () => {

		fetch('/IR/ClearSession', {
			method: 'POST'
		}).then(res => {
			this.props.setTitle(undefined);
			var url = '/IR/';
			if (this.isUsers()) {
				url = '/Users';
			}

			this.context.router.history.push(url);
		});
	}
	save = (event, callback) => {
		return new Promise(resolve => {
			if (this.state.isOfficeEdit && this.state.isRelationEdit) {
				this.handleSaveData(event, this, () => {
					this.handleSaveData(event, this, () => {
						this.handleSaveData(event, this, (data) => {
							callback();
							resolve(data);
						});
					});
				});
			}
			else if (!this.state.isOfficeEdit ^ !this.state.isRelationEdit) {
				this.handleSaveData(event, this, () => {
					this.handleSaveData(event, this, (data) => {
						callback();
						resolve(data);
					});
				});
			}
			else {
				this.handleSaveData(event, this, (data) => {
					callback();
					resolve(data);
				});
			}
		});

	}
	handleExit = (event) => {
		if (this.props.onExit) {
			this.props.onExit();
			return;
		}

		var {params} = this.props;
		var { CustomerTypologyId } = params;

		if (!CustomerTypologyId)
			CustomerTypologyId = 2;

		var TypologyIds = this.state.data.TypologyIds;

		if (TypologyIds && TypologyIds.includes(CustomerTypologyId)) {
			this.checkForChanges(event, this,
				() => {
					if (params.GDPRRequired) {
						fetch('/IR/CheckIncomplete?id=' + this.state.data.ID).then(res => res.json()).then(res => {
							if (res) {
								this.MessageBox.current.showMessageYesNo("INSERIMENTO/MODIFICA ANAGRAFICA", "Contratto GDPR non compilato, l'anagrafica 	sarà inserita nell'elenco delle anagrafiche incomplete. Continuare ugualmente?", () => {
									this.doSaveData(this.doExit, true);
								});
							}
							else
								this.doExit();
						});
					} else {
						if (this.state.data.Incomplete)
							this.doSaveData(this.doExit, false);
						else
							this.doExit();
					}
				},
				() => {
					if (params.GDPRRequired) {
						fetch('/IR/CheckIncomplete?id=' + this.state.data.ID).then(res => res.json()).then(res => {
							if (res) {
								this.MessageBox.current.showMessageYesNo("INSERIMENTO/MODIFICA ANAGRAFICA", "Contratto GDPR non compilato, l'anagrafica 	sarà inserita nell'elenco delle anagrafiche incomplete. Continuare ugualmente?", () => {
									this.doSaveData(this.doExit, true);
								});
							}
							else
								this.save(event, this.doExit);
						});
					}
				},
				this.doExit,
				() => { }
			);
		} else {
			this.checkForChanges(event, this,
				(this.doExit),
				() => {
					this.save(event, this.doExit);
				},
				this.doExit,
				() => { }
			);
		}
	}
	handeExtraFieldChangeGeneric = (data, extras, FIDExtra, value) => {

		var extraField = extras.filter(f => f.ID === FIDExtra)[0];

		var extra = {
			BoolValue: null,
			ComboValue: null,
			DateTimeValue: null,
			ExtraID: FIDExtra,
			ExtraType: extraField.Type,
			GroupValue: null,
			IntValue: null,
			StringValue: null,
			TagValue: null
		};
		if (data.Extras) {
			var extraFieldVal = data.Extras.filter(f => f.ExtraID === FIDExtra);
			if (extraFieldVal.length > 0) {
				extra = extraFieldVal[0];
			}
		}
		var index = -1;
		if (data.Extras) {
			index = data.Extras.indexOf(extra);
		}
		else {
			data.Extras = [];
		}
		switch (extra.ExtraType) {
			case 0://string
				extra.StringValue = value;
				break;
			case 1://integer
				extra.IntValue = value;
				break;
			case 2://decimal
				extra.DecimalValue = value;
				break;
			case 3://datetime
				if (value) {
					//extra.DateTimeValue = value.toDate();
					extra.DateTimeValue = value;
				}
				else {
					extra.DateTimeValue = value;
				}
				break;
			case 4://boolean
				extra.BoolValue = value;
				break;
			case 5://combobox
				if (value.length > 0) {
					extra.ComboValue = value[0].ID;
				}
				else {
					extra.ComboValue = null;
				}
				break;
			case 6://tagbox
				extra.TagValue = value;
				break;
			case 7://component
				//extra.StringValue = value;
				break;
			case 8://componentcustomfields
				//extra.StringValue = value;
				break;
			default:
				break;
		}
		return { extra, index };
	}
	handleExtraFieldChange = (FIDExtra, isData) => (id) => (event, value) => {
		this.setState((state, props) => {
			var { data, extras } = state;
			var { extra, index } = this.handeExtraFieldChangeGeneric(data, extras, FIDExtra, value);
			if (index >= 0) {
				state.data.Extras[index] = extra;
			}
			else {
				if (!state.data.Extras) {
					state.data.Extras = [];
				}
				state.data.Extras = state.data.Extras.concat(extra);
			}
			return state;
		});
	}
	handleExtraFieldChangeRelation = (FIDExtra, isData) => (id) => (event, value) => {
		this.setState((state, props) => {
			var { relation, extrasRelation } = state;
			var { extra, index } = this.handeExtraFieldChangeGeneric(relation, extrasRelation, FIDExtra, value);
			if (index >= 0) {
				state.relation.Extras[index] = extra;
			}
			else {
				if (!state.relation.Extras) {
					state.relation.Extras = [];
				}
				state.relation.Extras = state.relation.Extras.concat(extra);
			}
			return state;
		});
	}
	renderExtraField = (field, index, saveUrl, loadGridUrl, loadDataUrl, deleteDataUrl) => {
		var { data } = this.state;
		var id = this.state.FIDIdentity;

		var extra = [];
		if (data.Extras) {
			extra = data.Extras.filter(f => f.ExtraID === field.ID);
		}
		return (<CustomField key={index} field={field} extra={extra} saveUrl={saveUrl} mainId={id} loadGridUrl={loadGridUrl} loadDataUrl={loadDataUrl} deleteDataUrl={deleteDataUrl} handleExtraFieldChange={this.handleExtraFieldChange} />);
	}
	renderExtraFieldRelation = (field, index, saveUrl, loadGridUrl, loadDataUrl, deleteDataUrl) => {
		var { relation } = this.state;
		var id = relation.ID;

		var extra = [];
		if (relation.Extras) {
			extra = relation.Extras.filter(f => f.ExtraID === field.ID);
		}
		return (<CustomField key={index} field={field} extra={extra} saveUrl={saveUrl} mainId={id} loadGridUrl={loadGridUrl} loadDataUrl={loadDataUrl} deleteDataUrl={deleteDataUrl} handleExtraFieldChange={this.handleExtraFieldChangeRelation} />);
	}
	renderExtraGroup = (tab, group) => {
		var { extras } = this.state;
		return (
			<Grid container spacing={2} >
				{extras.filter(f => ((tab && f.FIDExtraTab === tab.ID) || (!tab && !f.FIDExtraTab)) && ((group && f.FIDExtraGroup === group.ID) || (!group && !f.FIDExtraGroup))).map((item, index) => {
					return this.renderExtraField(item, index, '/IR/SaveTableCF', '/IR/GetIRTableCFData/', '/IR/GetIRTableCFRecords/', '/IR/DeleteIRTableCFRecords/');
				})}
			</Grid>
		);
	}
	renderExtraGroupRelation = (tab, group) => {
		var { extrasRelation } = this.state;
		return (
			<Grid container spacing={2} >
				{extrasRelation.filter(f => ((tab && f.FIDExtraTab === tab.ID) || (!tab && !f.FIDExtraTab)) && ((group && f.FIDExtraGroup === group.ID) || (!group && !f.FIDExtraGroup))).map((item, index) => {
					return this.renderExtraFieldRelation(item, index, '/IR/SaveTableCF?isRelation=true', '/IR/GetIRTableCFData/?isRelation=true', '/IR/GetIRTableCFRecords/?isRelation=true', '/IR/DeleteIRTableCFRecords/?isRelation=true');
				})}
			</Grid>
		);
	}
	renderExtraTab = (tab) => {
		var { extragroups, extras } = this.state;
		var FIDGroups = extras.filter(m => ((tab && m.FIDExtraTab === tab.ID) || (!tab && !m.FIDExtraTab)) && m.FIDExtraGroup).map(m => m.FIDExtraGroup).filter((value, index, self) => self.indexOf(value) === index);
		var panels = extragroups.filter(f => FIDGroups.includes(f.ID)).map((item, index) => (
			{
				icon: "people",
				label: item.Description,
				content: this.renderExtraGroup(tab, item),
			}
		));

		var extrasNoGroup = extras.filter(m => ((tab && m.FIDExtraTab === tab.ID) || (!tab && !m.FIDExtraTab)) && !m.FIDExtraGroup);
		if (extrasNoGroup.length > 0) {
			panels = panels.concat({
				icon: "people",
				label: 'Custom',
				content: this.renderExtraGroup(tab, null),
			});
		}

		return (<TabContainer><XExpansionPanel panels={panels} /></TabContainer>);
	};
	renderExtraTabRelation = (tab) => {
		var { extragroupsRelation, extrasRelation } = this.state;
		var FIDGroups = extrasRelation.filter(m => ((tab && m.FIDExtraTab === tab.ID) || (!tab && !m.FIDExtraTab)) && m.FIDExtraGroup).map(m => m.FIDExtraGroup).filter((value, index, self) => self.indexOf(value) === index);
		var panels = extragroupsRelation.filter(f => FIDGroups.includes(f.ID)).map((item, index) => (
			{
				icon: "people",
				label: item.Description,
				content: this.renderExtraGroupRelation(tab, item),
			}
		));

		var extrasNoGroup = extrasRelation.filter(m => ((tab && m.FIDExtraTab === tab.ID) || (!tab && !m.FIDExtraTab)) && !m.FIDExtraGroup);
		if (extrasNoGroup.length > 0) {
			panels = panels.concat({
				icon: "people",
				label: 'Custom',
				content: this.renderExtraGroupRelation(tab, null),
			});
		}

		return (<TabContainer padding={0}><XExpansionPanel panels={panels} /></TabContainer>);
	};
	handleClose = value => {
		this.setState({ showMessage: false });
	}
	isUsers = () => {
		var url_string = window.location.href;
		var url = new URL(url_string);
		var Users = url.searchParams.get('Users');
		return Users;
	}
	isDestinations = () => {
		var url_string = window.location.href;
		var url = new URL(url_string);
		var Destinations = url.searchParams.get('destinations');
		return Destinations;
	}
	isRelations = () => {
		var url_string = window.location.href;
		var url = new URL(url_string);
		var Relations = url.searchParams.get('relations');
		return Relations;
	}
	changeProductionConfig = (key) => (event, data) => {
		this.setState(state => {
			if (data) {
				state.productionConfig[key] = data;
			}
			else {
				state.productionConfig[key] = undefined;
			}
			return state;
		});
	}
	renderTabProductionConfig = () => {
		var { productionConfig } = this.state;
		var panels = [{
			icon: "code",
			label: 'Configurazione rilevazione tempi',
			content: (<Grid item xs={12} sm={6} container>
				<Grid item xs={12} container direction="row" spacing={2}>
				<Grid item xs={6} sm={3}><XSwitch labelFixedHeight label="Rilevazione ingressi/uscite" id="trackFactoryTime" value={productionConfig.trackFactoryTime} onChange={this.changeProductionConfig} /></Grid>
				<Grid item xs={6} sm={3}><XSwitch labelFixedHeight label="Rilevazione interruzioni (pause/riunioni)" id="trackInterrupts" value={productionConfig.trackInterrupts} onChange={this.changeProductionConfig} /></Grid>
				<Grid item xs={6} sm={3}><XSwitch labelFixedHeight label="Rilevazione tempi produzione" id="trackProductionTime" value={productionConfig.trackProductionTime} onChange={this.changeProductionConfig} /></Grid>
					{productionConfig.trackProductionTime && <Grid item xs={6} sm={3}><XSwitch labelFixedHeight label="Consenti presa in carico multipla" id="allowMultipleBOPS" value={productionConfig.allowMultipleBOPS} onChange={this.changeProductionConfig} /></Grid>}
					{productionConfig.trackProductionTime && <Grid item xs={6} sm={3}><XSwitch labelFixedHeight label="Riapertura automatica commesse dopo fine pausa" id="autoReopenProductionBOPS" value={productionConfig.autoReopenProductionBOPS} onChange={this.changeProductionConfig} /></Grid>}
					<Grid item xs={6} sm={3}><XSwitch labelFixedHeight label="Riconsegna solo in logistica" id="returnBopLogisticsOnly" value={productionConfig.returnBopLogisticsOnly} onChange={this.changeProductionConfig} /></Grid>
					<Grid item xs={6} sm={3}><XSwitch labelFixedHeight label="Consenti attivazione componenti" id="AllowComponentActivation" value={productionConfig.AllowComponentActivation} onChange={this.changeProductionConfig} /></Grid>
					<Grid item xs={10} sm={5}><XSwitch labelFixedHeight label="Autologout" id="Autologout" value={productionConfig.Autologout} onChange={this.changeProductionConfig} />
					{productionConfig.Autologout && <XField labelAlignTop style={{
						width: "70%", 
					}} customFormatNumber preventNegativeValue decimalScale={0} type="number" label="Effettua logout dopo" id="AutologoutTime" value={productionConfig.AutologoutTime} onChange={this.changeProductionConfig} caption="secondi"/>}</Grid>
				</Grid>
			</Grid>),
		}];
		return (<TabContainer><XExpansionPanel panels={panels} /></TabContainer>);
	}
	
	renderSnackbar() {
		var { snackbar } = this.state;
		return <Snackbar
				open={snackbar.open}
				onClose={this.snackbarClose}
				TransitionComponent={props => <Slide {...props} direction="up" />}
				ContentProps={{
						'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">{snackbar.message}</span>}
				autoHideDuration={1500}
		/>;
	}
	
	render() {
		if (this.state.isLoading) {
			//return (<div>Loading...</div>);

			//return <XOXLoader />
			return <XGrid usePrimaryColor={true}
				customContent={<>
					<XOXLoader />
					{this.renderSnackbar()}
				</>}
			/>;
		}
		const { classes, headerColor, showSave, showNew, showDelete, showExit, topMargin, params } = this.props;
		const { data, extratabs, extras, isUploading, uploadProgress } = this.state;
		//var id = this.state.FIDIdentity;

		var supplierTypologyId = params && params.SupplierTypologyId ? params.SupplierTypologyId : 3;
		var agentTypologyId = params && params.AgentTypologyId ? params.AgentTypologyId : 8;

		var loctabs = [{
				tabName: getTranslation(4,"IR_GENERAL",this.props.translations, "Generale"),
				tabContent: this.renderTab1(),
			},
			{
				tabName: getTranslation(4,"IR_DESTINATIONS",this.props.translations, "Indirizzi"),
				tabContent: this.renderTab2(),
			},
			{
				tabName: getTranslation(4,"IR_RELATIONS",this.props.translations, "Relazioni"),
				tabContent: this.renderTab3(),
			},
			{
				tabName: getTranslation(4,"IR_FILES",this.props.translations, "Files"),
				tabContent: this.renderTab4(),
			}
		];

		//if(!this.props.isAgent){
			if (data && data.TypologyIds && data.TypologyIds.includes(supplierTypologyId)) {
				loctabs.push({
					tabName: getTranslation(4,"IR_DISTRIBUTIONS",this.props.translations, "Distribuzioni"),
					tabContent: this.renderTabDistributions(),
				});
			}
			loctabs.push({
				tabName: getTranslation(4,"IR_AGREEMENTS",this.props.translations, "Privacy"),
				tabContent: this.renderTabGDPR(),
			});
			loctabs.push({
				tabName: getTranslation(4,"IR_USERS",this.props.translations, "Utenti"),
				tabContent: this.renderTabUsers(),
			});
		//}
		
		if (!params.HideIRProfile) {
			loctabs.push({
				tabName: getTranslation(4,"IR_PROFILE",this.props.translations, "Profilo"),
				tabContent: this.renderTabProfile()
			});
		}
		if (data && data.TypologyIds) {
			if (!data.TypologyIds.includes(agentTypologyId)) {
				if (!params.HideIRAssignments) {
					loctabs.push({
						tabName: getTranslation(4,"IR_ASSIGNMENTS",this.props.translations, "Assegnazioni"),
						tabContent: this.renderTabAssignments(),
					});
				}
			} else {
				loctabs.push({
					tabName: getTranslation(4,"IR_PRICELISTS",this.props.translations, "Listini abbinati"),
					tabContent: this.renderTabAgentPricelists(),
				});
			}
		}
		
		if (extratabs) {
			var FIDTabs = extras.map(m => m.FIDExtraTab).filter((value, index, self) => self.indexOf(value) === index);
			loctabs = loctabs.concat(extratabs.filter(f => FIDTabs.includes(f.ID)).map((item, index) => ({
				tabName: item.Description,
				tabContent: this.renderExtraTab(item),
			})));
			var extrasNoTab = extras.filter(m => !m.FIDExtraTab);
			if (extrasNoTab.length > 0) {
				loctabs = loctabs.concat({
					tabName: 'CUSTOM',
					tabContent: this.renderExtraTab(null)
				});
			}
		}

		if (this.state.data.IsLicensee) {
			loctabs = loctabs.concat({
				tabName: 'Produzione',
				tabContent: this.renderTabProductionConfig()
			});
		}

		if (this.props.userProfile) {
			loctabs = [loctabs[0]];
			//extratabs = [];
		}
		var initialTab = undefined;

		if (this.isUsers()) {
			initialTab = 5;
		}
		if (this.isDestinations()) {
			initialTab = 1;
		}
		if (this.isRelations()) {
			initialTab = 2;
		}
		if(this.props.showPrivacy){
			initialTab = 4;
		}
		return (
			<div className={classes.root}>

				<Tabs
                    innerRef={this.tabs}
					headerColor={headerColor}
					plainTabs={true}
					onExitClick={showExit && this.handleExit}
					onSaveClick={showSave && !this.props.userProfile && this.handleSaveData}
					onDeleteClick={showDelete && !this.props.userProfile && this.handleCancelData}
					onNewClick={showNew && !this.props.userProfile && this.handleNewData}
					validateTab={this.validate}
					tabs={loctabs}
					topMargin={topMargin}
					isSecondary={this.props.isSecondary}
					initialTab={initialTab}
				/>
				<SimpleDialogWrapped message={this.state.errorMessage} title="Alcuni campi non sono stati compilati." open={this.state.showMessage} onClose={this.handleClose} />
				<XDialog message={this.state.dialog.message} title={this.state.dialog.title} open={this.state.dialog.show} buttons={this.state.dialog.buttons} />
				<XMessageBox innerRef={this.MessageBox} />
				{this.renderSnackbar()}
			</div>
		);
	}
}

IREdit.propTypes = {
    classes: PropTypes.object.isRequired,
};
IREdit.defaultProps = {
    headerColor: "primary",
    showExit: true,
    showSave: true,
    showDelete: true,
    showNew: true,
    noRedirect: false,
    topMargin: "72.5px",
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(authCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withStyles(styles)
);

export default enhance(IREdit);
