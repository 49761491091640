import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, styled } from '@material-ui/core/styles';
import { Button, Tooltip, Grid } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import Notifications from '@material-ui/icons/Notifications';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Message from '@material-ui/icons/Message';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Participant from '../../videochat/components/Participant/Participant';
import useParticipants from '../../videochat/hooks/useParticipants/useParticipants';
import useSelectedParticipant from '../../videochat/components/VideoProvider/useSelectedParticipant/useSelectedParticipant';
import ParticipantBox from './ParticipantBox';

const useStyles = makeStyles((theme) =>
    createStyles({
        bar: {
            width: '100%',
            height: '100%',
            backgroundColor: "white",
        },
        button: {
            position: 'absolute',
            right: '0',
            bottom: '0',
        },
        controlButtons: {
            display: 'flex',
        }
    })
);

function LicenseeList(props) {
    const [openUser, setOpenUser] = useState(null);
    const user = props.user
    const labelId = `checkbox-list-label-${user.id}`;
    const participants = useParticipants();
    const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();

    useEffect(() => {
        if (props.forcePin === undefined) {
            setSelectedParticipant(null)
        }
        else {
            participants.forEach(participant => {
                if (participant.identity === props.forcePin.username) {
                    setSelectedParticipant(participant)
                }
            });
        }
    }, [props.forcePin])

    var participant = participant = participants.find(part => part.identity === user.username);
    return (
        <ListItem key={user.id}
            dense
            divider
            style={{ paddingLeft: 2, paddingRight: 2 }}>
            {/* <ListItemText id={labelId} primary={user.username} />
            <IconButton onClick={() => { props.openMessageChat(user) }}><Message /></IconButton> */}
            
            <ParticipantBox
                participant={participant}
                user={user}
                isSelected={selectedParticipant === participant}
                isOpen={openUser===user.id}
                selectParticipant={() => setOpenUser(openUser === user.id ? null : user.id)}
                handleUnmuteMic={props.handleUnmuteMic}
                handleMuteMic={props.handleMuteMic}
                handleEnableMic={props.handleEnableMic}
                handleDisableMic={props.handleDisableMic}
                handleUnmuteVideo={props.handleUnmuteVideo}
                handleMuteVideo={props.handleMuteVideo}
                handleEnableVideo={props.handleEnableVideo}
                handleDisableVideo={props.handleDisableVideo}
                handleForceQuit={props.handleForceQuit}
                handleFixPartecipant={() => setSelectedParticipant(participant)}
                handleUnfixPartecipant={() => setSelectedParticipant(null)}
                isFixed={selectedParticipant === participant}

            />
            {/* {participant &&
                <Participant
                    key={participant.sid}
                    participant={participant}
                    isSelected={selectedParticipant === participant}
                //onClick={() => setSelectedParticipant(participant)}
                //onClick={() => { setOpenUser(openUser === user.id ? null : user.id) }}
                />
            } */}
        </ListItem>
    )
}
function NonLicenseeList(props) {
    const [openUser, setOpenUser] = useState(null);
    const classes = useStyles();

    const participants = useParticipants();
    const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();

    useEffect(() => {
        if (props.forcePin === undefined) {
            setSelectedParticipant(null)
        }
        else {
            participants.forEach(participant => {
                if (participant.identity === props.forcePin.username) {
                    setSelectedParticipant(participant)
                }
            });
        }
    }, [props.forcePin])


    const onFix = (participant) => {
        if(selectedParticipant !== participant){
            setSelectedParticipant(participant);
        }
    }
    const onUnfix = (participant) => {
        if(selectedParticipant === participant){
            setSelectedParticipant(null);
        }
    }

    return (<div>
        {props.users.map(user => {
            const labelId = `checkbox-list-label-${user.id}`;
            const showUserRequest = props.showControlButton
                && props.usersWithRequest
                && (props.usersWithRequest.findIndex(req => req.id === user.id) >= 0);
            var participant = undefined;
            if (props.showControlButton)//????????????????
            {
                participant = participants.find(part => part.identity === user.username);
            }
            return (
                <div>
                    <ListItem key={user.id}
                        dense
                        divider
                        style={{ paddingLeft: 2, paddingRight: 2 }}>
                        {/* {(props.showControlButton) && //????????????????
                            <IconButton
                                onClick={() => { setOpenUser(openUser === user.id ? null : user.id) }}>
                                {openUser === user.id ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        } */}
                        <ParticipantBox
                            participant={participant}
                            user={user}
                            isSelected={selectedParticipant === participant}
                            isOpen={openUser===user.id}
                            selectParticipant={() => setOpenUser(openUser === user.id ? null : user.id)}
                            handleUnmuteMic={props.handleUnmuteMic}
                            handleMuteMic={props.handleMuteMic}
                            handleEnableMic={props.handleEnableMic}
                            handleDisableMic={props.handleDisableMic}
                            handleUnmuteVideo={props.handleUnmuteVideo}
                            handleMuteVideo={props.handleMuteVideo}
                            handleEnableVideo={props.handleEnableVideo}
                            handleDisableVideo={props.handleDisableVideo}
                            handleForceQuit={props.handleForceQuit}
                            handleFixPartecipant={() => onFix(participant)}
                            handleUnfixPartecipant={() => onUnfix(participant)}
                            isFixed={selectedParticipant === participant}
                            showUserRequest={showUserRequest}

                        />
                        {/* {!participant && <><ListItemText id={labelId} primary={user.username} />
                            <Tooltip title={'Open chat'} placement="bottom">
                                <IconButton onClick={() => { props.openMessageChat(user) }}><Message /></IconButton>
                            </Tooltip>
                        </>}
                        {participant &&
                            <Participant
                                key={participant.sid}
                                participant={participant}
                                isSelected={selectedParticipant === participant}
                                //onClick={() => setSelectedParticipant(participant)}
                                onClick={() => { setOpenUser(openUser === user.id ? null : user.id) }}
                            />
                        } */}


                    </ListItem>
                    {/* {(props.showControlButton) && //????????????????
                        <Collapse in={openUser === user.id} timeout="auto">
                            <div className={classes.controlButtons}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <Tooltip title={'Unmute Audio'} placement="bottom">
                                            <IconButton onClick={() => props.handleUnmuteMic(user)}><Mic /></IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tooltip title={'Mute Audio'} placement="bottom">
                                            <IconButton onClick={() => props.handleMuteMic(user)}><MicOff /></IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tooltip title={'Enable Audio'} placement="bottom">
                                            <IconButton onClick={() => props.handleEnableMic(user)}><Mic style={{ color: 'green' }} /></IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tooltip title={'Disable Audio'} placement="bottom">
                                            <IconButton onClick={() => props.handleDisableMic(user)}><MicOff style={{ color: 'red' }} /></IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tooltip title={'Unmute Video'} placement="bottom">
                                            <IconButton onClick={() => props.handleUnmuteVideo(user)}><Videocam /></IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tooltip title={'Mute Video'} placement="bottom">
                                            <IconButton onClick={() => props.handleMuteVideo(user)}><VideocamOff /></IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tooltip title={'Enable Video'} placement="bottom">
                                            <IconButton onClick={() => props.handleEnableVideo(user)}><Videocam style={{ color: 'green' }} /></IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tooltip title={'Disable Video'} placement="bottom">
                                            <IconButton onClick={() => props.handleDisableVideo(user)}><VideocamOff style={{ color: 'red' }} /></IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tooltip title={'Kick user'} placement="bottom">
                                            <IconButton onClick={() => props.handleForceQuit(user)}><ExitToApp /></IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {showUserRequest &&
                                            <IconButton disabled={true}><Notifications /></IconButton>
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </Collapse>
                    } */}
                </div>
            )
        })}
    </div>)
}

export default function ParticipantSidebar(props) {
    const classes = useStyles();

    const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
    const participants = (props.roomParticipants && props.users) ?
        props.users.filter(user => props.roomParticipants.includes(user.id))
        : undefined
    const licensee = participants ? participants.filter(user => user.isLicensee === true) : undefined
    const nonLicensee = participants ? participants.filter(user => user.isLicensee === false) : undefined
    const showLicensee = licensee && licensee.length > 0;
    const showLonLicensee = nonLicensee && nonLicensee.length > 0;
    return (
        <div className={classes.bar}>
            {participants &&
                (<div>
                    <List dense>
                        {/* {showLicensee && <p>Licensee:</p>} */}
                        {showLicensee && licensee.map(user => {
                            return (<LicenseeList user={user}
                                openMessageChat={props.openMessageChat}
                            />)
                        })
                        }
                        {(showLicensee && showLonLicensee) &&
                            <ListItem key='divider'
                                dense
                                divider />
                        }
                        {/* {showLonLicensee && <p>Non Licensee:</p>} */}
                        {showLonLicensee && (<NonLicenseeList
                            visible={props.visible}
                            users={nonLicensee}
                            showControlButton={props.showControlButton}
                            roomParticipants={props.roomParticipants}
                            handleEnableMic={props.handleEnableMic}
                            handleDisableMic={props.handleDisableMic}
                            handleEnableVideo={props.handleEnableVideo}
                            handleDisableVideo={props.handleDisableVideo}
                            handleMuteMic={props.handleMuteMic}
                            handleUnmuteMic={props.handleUnmuteMic}
                            handleMuteVideo={props.handleMuteVideo}
                            handleUnmuteVideo={props.handleUnmuteVideo}
                            handleUnmuteVideo={props.handleUnmuteVideo}
                            usersWithRequest={props.usersWithRequest}
                            handleForceQuit={props.handleForceQuit}
                            openMessageChat={props.openMessageChat}
                            forcePin={props.forcePin}
                            forcePinChange={props.forcePinChange}
                        />)
                        }
                    </List>
                </div>)
            }
        </div>
    )
}