import React, { useRef, useState, useEffect } from 'react';
import { FormControl, MenuItem, Typography, Select } from '@material-ui/core';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DeviceInput from 'views/Videochat/DeviceInput';
import useSelectedParticipant from 'videochat/components/VideoProvider/useSelectedParticipant/useSelectedParticipant';

const useStyles = makeStyles({
  preview: {
    width: '160px',
    height: '90px',
    margin: '0.5em 0',
  },
  video: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  selectedPreview: {
    width: '160px',
    height: '90px',
    margin: '0.5em 0',
    borderRadius: '10px',
    border: '3px solid red',
  },
});

export default function VideoInputList(props) {
  const classes = useStyles();
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
  } = useVideoContext();


  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();

  console.log(localTracks);
  const localVideoTrack = localTracks.find(track => track.kind === 'video');

  const localVideoInputDeviceId = localVideoTrack ? localVideoTrack.mediaStreamTrack.getSettings().deviceId : undefined;

  function replaceTrack(newDeviceId) {
    if (localVideoInputDeviceId !== newDeviceId) {
      const prevVideoTrack = localVideoTrack;
      if (localVideoTrack)
        localVideoTrack.stop();

      getLocalVideoTrack({ deviceId: { exact: newDeviceId } })
        .then(newTrack => {
          if (localParticipant) {
            if (localVideoTrack) {
              const localTrackPublication = localParticipant.unpublishTrack(localVideoTrack);
              // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
              localParticipant.emit('trackUnpublished', localTrackPublication);
            }
            console.log(JSON.stringify(newTrack));
            localParticipant.publishTrack(newTrack, { priority: "low" })
              .catch(() => {
                console.log('unable to publish new track for device: ' + newDeviceId)
                // localParticipant.publishTrack(prevVideoTrack);
              });
          }
        })
        .catch(() => {
          console.log('unable to start video track for device: ' + newDeviceId)
          // if(localParticipant)
          //   localParticipant.publishTrack(prevVideoTrack);
        });
    }
  }

  useEffect(() => {
    const clearMediaStream = () => setMediaStream(null);
    navigator.mediaDevices.addEventListener('devicechange', clearMediaStream);

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', clearMediaStream);
    }
  }, []);

  const [mediaStream, setMediaStream] = useState(null);

  useEffect(() => {
    async function enableStream() {
      async function getStream(device) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: { aspectRatio: 1.777, deviceId: { exact: device.deviceId } } })
          if (stream) {
            return { stream, device };
          }
        }
        catch (err) {
          return undefined;
        }
      }


      try {
        const devices = (await navigator.mediaDevices.enumerateDevices()).filter(device => device.kind === "videoinput");
        const streams = await Promise.all(devices.map(getStream))

        const setstreams = streams.filter(stream => stream && stream.stream && stream.device);
        setMediaStream(setstreams);
      }
      catch (err) {
        setMediaStream([]);
        // Removed for brevity
      }

    }

    if (!mediaStream) {
      enableStream();
    }
    else {
      return function cleanup() {
        mediaStream.map(stream => {
          if (stream && stream.stream) {
            stream.stream.getTracks().forEach(track => {
              track.stop();
            });
          }
        });
        setMediaStream([]);
      }
    }
  }, [mediaStream]);
  const onDeviceFix = (selected, device) => {
    if(!selected){
      replaceTrack(device.deviceId);
    }
    props.forcePinChange(true);
    if(localParticipant !== selectedParticipant){
      setSelectedParticipant(localParticipant);
    }

  }
  const onDeviceUnfix = (device) => {
    props.forcePinChange(false);
    setSelectedParticipant(null);
  }
  const stopVideoTrack = (device) => {
    if (localVideoInputDeviceId === device.deviceId) {
      props.handleDisableDevice(false);
    }
  }
  const onDeviceSelected = (device) => {
    replaceTrack(device.deviceId);
  }
  const onDeviceOff = (device) => {
    if (device.deviceId === localVideoInputDeviceId) {
      props.handleDisableDevice(false);
    }
  }
  let content = <Typography>Dispositivi video non disponibili</Typography>;
  if (props.disableVideo) {
    content = <Typography>Controlli video disabilitati</Typography>;
  }
  else if (mediaStream && mediaStream.length > 0) {
    content = (mediaStream.map(stream => {
      if (stream && stream.stream && stream.device) {
        return (
          <DeviceInput key={stream.stream.id} device={stream.device} stream={stream} type="VIDEO_INPUT" onDeviceSelected={onDeviceSelected} onDeviceOff={onDeviceOff} onDeviceFix={onDeviceFix} onDeviceUnfix={onDeviceUnfix} selected={stream.device.deviceId === localVideoInputDeviceId} isFixed={localParticipant === selectedParticipant}  iAmSuperUser={props.iAmSuperUser}/>
        )
      }
    }));
  }
  return (
    <div>
      {content}
    </div>
  );
}
