import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';

import GenericEditor from 'layouts/GenericEditor';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators, getCookie } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';

const style = () => ({
});

class Platform extends Component {
    constructor(props) {
        super(props);
        this.Editor = React.createRef();
    }
    render() {
		
		var fields = [
			{
				key: "IsECommerce",
				label: "Piattaforma eCommerce",
				type: "XSwitch"
			},
            {
                key: 'Url',
                label: 'Sito WEB',
                type: 'XField',
				visibleOnly: r => r["IsECommerce"]
            },
			{
                key: 'PublicKey',
                label: 'Chiave pubblica',
                type: 'XField',
				visibleOnly: r => r["IsECommerce"]
            },
			{
                key: 'SecretKey',
                label: 'Chiave segreta',
                type: 'XField',
				visibleOnly: r => r["IsECommerce"]
            },
		];
		
        return <GenericEditor
            innerRef={this.Editor}
            id={this.props.match.params.id}
			fields={fields}
            urlSave='/gc/platform/Save/'
            urlRedirect='/platform/'
            urlExit='/platforms/'
            urlNew="/platform/new"
            urlGetData='/gc/platform/'
        />;
    }
}

const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    withStyles(style)
);
export default enhance(Platform);
