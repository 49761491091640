import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        width: '100%',
        height: 'calc(100vh - 151px)'
    }
});



class ViewXml extends Component {

    render(){
        var { fileName, classes } = this.props;
        var url = `/api/ElectronicInvoice/ViewXml?fileName=${fileName}`;
        return (
            <iframe id="InvoiceFromFrame" className={classes.root} src={`https://docs.google.com/gview?url=${window.location.origin}${url}&embedded=true`}></iframe> 
        );
    }
}


export default withStyles(styles)(ViewXml);
