import React, { useState, useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as userSignalCreators } from './UserSignaler';
import { actionCreators as userAuthCreators } from '../../store/Auth';
import UserList from './UserList';
import Meetings from './Meetings';
import { makeStyles } from '@material-ui/core';
import XExpansionPanel from 'components/XExpansionPanel';
import { CodeBrackets } from 'mdi-material-ui';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    users: {
        flex: 1,
        maxWidth: 400
    },
    chat: {
        flex: 1
    }
}));


const OnlineUsers = (props) => {
    const [meetings, setMeetings] = useState([]);
    const [panels, setPanels] = useState([]);
    const classes = useStyles(props);

    const { userID, isLicensee, onlineUsers } = props;
    let { roomName } = props;

    useEffect(() => {
        fetch(`/XLIVE/GetMeetings?userId=${userID}&isLicensee=${!!isLicensee}`)
            .then(res => res.json()
                .then(data => {
                    setMeetings(data);
                })
            );
    }, []);

    useEffect(() => {
        console.log(onlineUsers);
        var panels = [];
        
        meetings && meetings.length > 0 && panels.push({
            icon: <CodeBrackets />,
            label: 'Meetings',
            content: renderMeetingPanel()
        });
        onlineUsers && onlineUsers.filter(i => i.isLicensee).length > 0 && panels.push({
            icon: <CodeBrackets />,
            label: 'Company users',
            content: renderCompanyPanel()
        });
        onlineUsers && onlineUsers.filter(i => !i.isLicensee).length > 0 && panels.push({
            icon: <CodeBrackets />,
            label: 'Online users',
            content: renderUsersPanel()
        });
        setPanels(panels);
    }, [onlineUsers]);


    const joinMeeting = (meeting) => {
        props.acceptCall(meeting.RoomName);
    }
    const callUser = (callingList) => {
        if (roomName === undefined) {
            var timestamp = new Date().getTime();
            roomName = userID + "_" + timestamp;
        }
        props.callUsers(callingList, roomName);
    }
    const notifyBusyUser = (user) => {
        props.notifyBusyUser(user);
    }
    const openMessageChat = (user) => {
        props.openMessageChat(user);
    }

    const renderMeetingPanel = () => {
        return <>

            <h6>Meetings:</h6>
            <Meetings meetings={meetings} joinMeeting={joinMeeting} />
        </>;
    }

    const renderCompanyPanel = () => {
        return <UserList
            users={onlineUsers || []}
            callUser={callUser}
            notifyBusyUser={notifyBusyUser}
            openMessageChat={openMessageChat}
            companyUsers={true}
        />;
    }

    const renderUsersPanel = () => {
        return <UserList
            users={onlineUsers || []}
            callUser={callUser}
            notifyBusyUser={notifyBusyUser}
            openMessageChat={openMessageChat}
            companyUsers={false}
        />;
    }
    return (
        <div>
            <div className={classes.root}>
                <div className={classes.users}>
                    <XExpansionPanel panels={panels} />
                    {/* <UserList
                        users={onlineUsers || []}
                        callUser={callUser}
                        notifyBusyUser={notifyBusyUser}
                        openMessageChat={openMessageChat}
                    /> */}
                </div>
                <div className={classes.chat}></div>
                {/* <ChatContainer /> */}
            </div>

        </div>
    );
}

const enhance = compose(
    connect(
        state => state.userSignaler,
        dispatch => bindActionCreators(userSignalCreators, dispatch)
    ),
    connect(
        state => state.auth,
        dispatch => bindActionCreators(userAuthCreators, dispatch)
    )
);
export default enhance(OnlineUsers);