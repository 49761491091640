import React, { Component,Fragment } from 'react';
import { withStyles, withTheme, Grid } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';
import CashRow from './CashRow';
import CashCell from './CashCell';
import moment from 'moment';

const styles = theme => ({
    root: {
        width: '100%'
    }
});


class CashReportDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openAmount:0,
            inAmount:0,
            returnAmount:0,
            outAmount:0,
            total:0
        };
    }

    componentDidMount() {
        this.loadData();

    }
    componentDidUpdate(props) {
        if(props != this.props){
            this.loadData();
        }
    }

    loadData = async () => {
        var { FIDOwner,FIDOwnerDestination, FIDCashRegister, FIDPaymentType, DateFrom,DateTo } = this.props;
        if(DateFrom && !DateFrom._isAMomentObject){
            DateFrom = moment(DateFrom);
        }
        if(DateTo && !DateTo._isAMomentObject){
            DateTo = moment(DateTo);
        }
        
        var filters = `?FromDate=${DateFrom.format("YYYY-MM-DD")}&ToDate=${DateTo.format("YYYY-MM-DD")}`;
        if(FIDPaymentType){
            filters += `&FIDPaymentType=${FIDPaymentType}`
        }
        if(FIDCashRegister){
            filters += `&FIDCashRegister=${FIDCashRegister}`
        }
        
        
        if(FIDOwner){
            filters += FIDOwner.map((item,index) => `&FIDLicensee[${index}]=${item}`).join("");// `&FIDLicensee=${parameters.FIDLicensee}`
        }
        if(FIDOwnerDestination){
            filters += FIDOwnerDestination.map((item,index) => `&FIDLicenseeDestination[${index}]=${item}`).join("");// `&FIDLicenseeDestination=${parameters.FIDLicenseeDestination}`
        }
        var openAmount = await fetch(`/Cash/GetOpenAmount/${filters}`).then(res => res.json());
        var inAmount = await fetch(`/Cash/GetInAmount/${filters}`).then(res => res.json());
        var returnAmount = await fetch(`/Cash/GetReturnAmount/${filters}`).then(res => res.json());
        var outAmount = await fetch(`/Cash/GetOutAmount/${filters}`).then(res => res.json());

        var total = openAmount + inAmount - returnAmount - outAmount;

        this.setState({openAmount,inAmount,returnAmount,outAmount,total});
    }

    render() {
        var { classes } = this.props;
        var { openAmount,inAmount,returnAmount,outAmount,total } = this.state;
        return (
            <Fragment className={classes.root}>
                {openAmount !== 0 && <CashRow>
                    <CashCell width={"35%"}>Apertura</CashCell>
                    <CashCell width={"65%"}>{openAmount.toFixed(2)} €</CashCell>
                </CashRow>}
                <CashRow>
                    <CashCell width={"35%"}>Incassi</CashCell>
                    <CashCell width={"65%"}>{inAmount.toFixed(2)} €</CashCell>
                </CashRow>
                <CashRow>
                    <CashCell width={"35%"}>Resi</CashCell>
                    <CashCell width={"65%"}>{returnAmount.toFixed(2)} €</CashCell>
                </CashRow>
                {outAmount !== 0 && <CashRow>
                    <CashCell width={"35%"}>Uscite</CashCell>
                    <CashCell width={"65%"}>{outAmount.toFixed(2)} €</CashCell>
                </CashRow>}
                <CashRow>
                    <CashCell width={"35%"} bold>Totale</CashCell>
                    <CashCell width={"65%"} bold>{total.toFixed(2)} €</CashCell>
                </CashRow>
            </Fragment>
        );
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(CashReportDetail);