import React, { Component } from 'react';
import { withStyles, withTheme, Grid } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';

import XNumpadKeyboard from '../../../components/XNumpadKeyboard';
import XField from '../../../components/XField';
import CashRow from './CashRow';
import CashCell from './CashCell';
const styles = theme => ({
    root: {

    },
    numpad: {
        position: 'relative',
        left: '50%',
        transform: 'translate(-50%)'
    },
    center: {
        maxWidth: 600,
        position: 'relative',
        left: '50%',
        transform: 'translate(-50%)',
    }
});


class CashNumberEditor extends Component {
    constructor(props) {
        super(props);
        this.state= {
            value: '',
            firstPress: true,
        }
    }
    componentDidMount() {
        this.setState({fields: this.props.fields, id: this.props.id},() => {
            this.setFocus(true);
        });
    }
    setFocus = (selectAll) => {
        var el = document.getElementById(this.state.id);
        el.focus();
        if(selectAll){
            el.setSelectionRange(0,el.value.length);
        }
    }
    onBlur = (key) => (event) => {
        this.setFocus();
		//document.getElementById(key).focus();
    }
    onNumpadChange = (_, newChar) => {
        var { id } = this.state;
        this.setState(state => {
            var { fields } = state;
            var currVal = fields.filter(f => f.id === id)[0];

            if(state.firstPress){
                currVal.value = "";
                state.firstPress = false;
            }
            if (currVal.value === null || currVal.value === undefined)
            {
                currVal.value = "";
            }
            if(typeof currVal.value !== "string"){
                currVal.value = currVal.value.toString();
            }

            if (isNaN(newChar)) {
                if ("CA" === newChar)
                {
                    currVal.value = "";
                }
                else if ("C" === newChar) {
                    currVal.value = currVal.value.substring(0, currVal.value.length - 1);
                }
            }
            else {
                currVal.value += newChar;
            }
            if(this.props.onRecalculate ){
                state.fields = this.props.onRecalculate(state.fields, id);
            }
            return state;
        })
    }
    onNumpadSubmit = () => {
        var { onSubmit, onNegativeAmounts } = this.props;
        var { fields } = this.state;
        var hasNegatives = fields.filter(item => item.value < 0).length > 0;
        if(hasNegatives){
            onNegativeAmounts && onNegativeAmounts();
            return;
        }
        onSubmit && onSubmit(fields);
    }
    onFocusChange = (id) => () => {
        this.setState({ id, firstPress: true },() => {
            this.setFocus(true);
        });
    }
    onChange = (id) => (event,value) => {
        this.setState(state => {
            var field = state.fields.filter(item => item.id === id)[0];
            field.value = value.replace(",",".");

            if(this.props.onRecalculate ){
                state.fields = this.props.onRecalculate(state.fields, id);
            }
            return state;
        })
    }

    render() {
        var { classes, fixSpacing } = this.props;
        var { fields } = this.state;


        var width = fields ? `${(100 / fields.length)}%` : '100%';


        var style={};
        if(fixSpacing){
            style.margin = '0 -16px';
        }

        return <div>
            
            <Grid container spacing={2} style={style}>
                    <Grid item xs={12}>
                        <div className={classes.center}>
                            {fields && <CashRow>
                                {fields.map(item => <CashCell width={width}><XField fontSize={18} disabled={item.readOnly} labelFontSize={16} label={item.label} id={item.id} value={item.value} onClick={!item.readOnly && this.onFocusChange(item.id)} onChange={this.onChange} onBlur={this.onBlur} onKeyDown={this.onKeyDown} onChange={this.onChange}/></CashCell>)}
                            </CashRow>}
                            
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <XNumpadKeyboard className={classes.numpad} onChange={this.onNumpadChange} onSubmit={this.onNumpadSubmit}/>
                    </Grid>
                </Grid>
            
        </div>;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(CashNumberEditor);