import React, { Component, Fragment } from 'react';
import { withStyles, withTheme, Grid } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';

import XButton from '../../../components/XButton';
import CashRow from './CashRow';
import CashCell from './CashCell';

const styles = theme => ({
    root: {

    },
    denomination: {
        fontSize: 32,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    button: {
        width: '100%',
    }
});


class CashIR extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: undefined
        }
    }
    componentDidMount() {
        if(this.props.FIDIR) {
            this.loadData();
        }
        else {
            this.setState({ data: undefined });
        }
    }
    componentDidUpdate(props){
        if((this.props.FIDIR && props.FIDIR !== this.props.FIDIR) || (this.props.doc.FIDIRRelation !== props.doc.FIDIRRelation)) {
            this.loadData();
        }
        else if(!this.props.FIDIR && props.FIDIR !== this.props.FIDIR) {
            this.setState({ data: undefined });
        }
    }

    loadData = async () => {
        var { FIDIR, onDataLoaded, isRepair,doc } = this.props;
        var data = await fetch(`IR/GetIR/${FIDIR}`).then(res => res.json());
        var url = `/DOC/IRHasDeposits/${data.ID}`;
        if(doc.FIDIRRelation){
            url+= `?FIDIRRelation=${doc.FIDIRRelation}`
        }
        var hasDeposits = !isRepair && await fetch(url).then(res => res.json());
        data.hasDeposits = hasDeposits;
        this.setState({ data, hasDeposits },() => {
            onDataLoaded && onDataLoaded(data);
        });
    }

    isValidIR = () => {
        return this.state.data.Incomplete !== 1;
    }

    render() {
        var { classes, onChangeRequest,onClearIR,onEditIR,onEditPrivacyIR, confirmed, label, genericLabel, clearLabel } = this.props;
        var { data } = this.state;

        var actionChange = <XButton className={classes.button} onClick={onChangeRequest}>CAMBIA</XButton>;
        var actionOff = <XButton className={classes.button} onClick={onClearIR}>{clearLabel || "GENERICO"}</XButton>;
        var actionEdit = <XButton className={classes.button} onClick={onEditIR}>MODIFICA</XButton>;
        var actionPrivacy = <XButton className={classes.button} onClick={onEditPrivacyIR}>PRIVACY</XButton>;

        var geographicArea = "";
        if(data){
            if(data.Zip){
                geographicArea = data.Zip;
            }
            if(data.City){
                if(geographicArea){
                    geographicArea += " - "
                }
                geographicArea += data.City
            }
            if(data.Province){
                if(geographicArea){
                    geographicArea += " "
                }
                geographicArea += `(${data.Province})`;
            }
            if(data.Country) {
                if(geographicArea){
                    geographicArea += " - "
                }
                geographicArea += data.Country
            }
        }
        var half = "50%";
        var full = "100%";

        var actionsCount = 0;
        if(onChangeRequest) actionsCount++;
        if(onClearIR) actionsCount++;
        if(onEditIR) actionsCount++;
        if(onEditPrivacyIR) actionsCount++;

        var actionsWidth = `${100 / actionsCount}%`;

        return <div>
            {data && <CashRow><CashCell label={label} width={100} totalWidth={100}>{data.Denomination}</CashCell></CashRow>}
            {!data && <CashRow><CashCell label={label} width={100} totalWidth={100}>{genericLabel || "Cliente generico"}</CashCell></CashRow>}
            {data && data.Address && <CashRow><CashCell width={100} totalWidth={100} label={"Indirizzo"}>{data.Address}</CashCell></CashRow>}
            {data && geographicArea && <CashRow><CashCell width={100} totalWidth={100} label={"Localita"}>{geographicArea}</CashCell></CashRow>}
            {data && (data.Taxcode || data.Vatnumber) && <CashRow>
                {data.Taxcode && <CashCell width={data.Vatnumber ? half : full} totalWidth={"100%"} label={"Codice fiscale"}>{data.Taxcode}</CashCell>}
                {data.Vatnumber && <CashCell width={data.Taxcode ? half : full} totalWidth={"100%"} label={"Partita iva"}>{data.Vatnumber}</CashCell>}
            </CashRow>}
            {data && (data.Pricelist || data.DiscountDesc) && <CashRow>
                {data.Pricelist && <CashCell width={data.DiscountDesc ? half : full} totalWidth={"100%"} label={"Listino"}>{data.Pricelist}</CashCell>}
                {data.DiscountDesc && <CashCell width={data.Pricelist ? half : full} totalWidth={"100%"} label={"Sc. %"}>{data.DiscountDesc} %</CashCell>}
            </CashRow>}
            {!confirmed && data && <CashRow>
                {onClearIR && <CashCell width={actionsWidth} totalWidth={"100%"} padding={5}>{actionOff}</CashCell>}
                {onChangeRequest && <CashCell width={actionsWidth} totalWidth={"100%"} padding={5}>{actionChange}</CashCell>}
                {onEditIR && <CashCell width={actionsWidth} totalWidth={"100%"} padding={5}>{actionEdit}</CashCell>}
                {onEditPrivacyIR && <CashCell width={actionsWidth} totalWidth={"100%"} padding={5}>{actionPrivacy}</CashCell>}
            </CashRow>}
            {!confirmed && !data && <CashRow>
                <CashCell width={"100%"} totalWidth={"100%"}>{actionChange}</CashCell>
            </CashRow>}
            {data && data.Incomplete && <CashRow><CashCell width={"100%"} error>Trattamento dati privacy mancante</CashCell></CashRow>}
            {data && data.hasDeposits && <CashRow><CashCell width={"100%"} error>Sono presenti acconti non utilizzati</CashCell></CashRow>}
        </div>;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(CashIR);