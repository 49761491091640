import React, {useState, useEffect} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRight  from '@material-ui/icons/KeyboardArrowRight';
import Collapse from '@material-ui/core/Collapse';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles(theme =>
createStyles({
    nested: {
      paddingLeft: theme.spacing(4),
    },
}));

export default function TwilioUsageList(props){
    const classes = useStyles();
    const [openUrl, setOpenUrl] = useState(undefined);
    const [urlData, setUrlData] = useState(undefined);
    const [fetching, setFetching] = useState(false)	
    
    useEffect(() => {
        if(openUrl)
        {
            setFetching(true);
            fetch(`/XLIVE/GetParticipants?url=${openUrl}`)
            .then(res => res.json())
            .then(jdata => {
                setFetching(false);
                setUrlData(jdata);
            })
        }
    }, [openUrl])


    if(props.data)
    {
        if(props.data.length <= 0)
        {
            return <Typography>No cost retrivied for this month.</Typography>;
        }
        else
        return (
            <List dense>
                <ListItem divider>
                    <ListItemText id="price-label" primary={`Total price: ${props.data.price}$   Total Time: ${props.data.totalTime} minutes`}/>
                </ListItem>
                <ListItem divider>
                    <IconButton disabled={true}><KeyboardArrowRight/></IconButton>
                    <ListItemText id="start-header" primary="Start"/>
                    <ListItemText id="end-header" primary="End"/>
                    <ListItemText id="duration-header" primary="Duration"/>
                </ListItem>
                {props.data.rooms.map(room => {
                    return(
                    <div key={`div-${room.sid}`}>
                        <ListItem key={room.sid}>
                            <IconButton onClick={() => {
                                    if(openUrl === room.participantUrl)
                                        setOpenUrl(undefined)
                                    else
                                        setOpenUrl(room.participantUrl)
                                }}>
                                {openUrl === room.participantUrl ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                            <ListItemText id="" primary={room.start}/>
                            {(room.duration && room.end) ? 
                                (<> 
                                <ListItemText id="" primary={room.end}/>
                                <ListItemText id="" primary={`${room.duration} seconds`}/>
                                </>)
                                : <ListItemText id="" primary="In Progress"/>
                            }
                        </ListItem>
                        <Collapse in={openUrl === room.participantUrl} timeout="auto" unmountOnExit>
                            {fetching ? 
                                (<CircularProgress className={classes.loadingSpinner} />)
                                :
                                urlData ? 
                                    (<List dense component="div" disablePadding>
                                        {urlData.map(participant => {
                                            return (
                                            <ListItem className={classes.nested} key={participant.sid}>
                                                <ListItemText id="participant.sid" primary={participant.sid}/> 
                                                <ListItemText id="participant.start" primary={participant.joinTime}/> 
                                                <ListItemText id="participant.end" primary={participant.leftTime}/> 
                                                <ListItemText id="participant.duration" primary={`${participant.duration} seconds`}/> 
                                            </ListItem>
                                            )}
                                        )}
                                    </List>)
                                    : null
                            }
                            
                        </Collapse>
                    </div>
                    )
                })}
            </List>
        );
    }

    return null;
}