import MainParticipantInfo from '../MainParticipantInfo/MainParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import React from 'react';
import useMainSpeaker from '../../hooks/useMainSpeaker/useMainSpeaker';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import useParticipants from 'videochat/hooks/useParticipants/useParticipants';
import usePublications from 'videochat/hooks/usePublications/usePublications';
import {
  AudioTrack as IAudioTrack, RemoteParticipant
} from 'twilio-video';
import useTrack from 'videochat/hooks/useTrack/useTrack';
import AudioTrack from '../AudioTrack/AudioTrack';
import Publication from '../Publication/Publication';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      position: 'relative',
      height: '100%',
      width: '100%',
      float: 'right',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  })
);


const ParticipantAudio = (props: any) => {
  debugger;
  const publications = usePublications(props.participant as RemoteParticipant);
  const audioPublications = publications.filter(p => p.kind.includes('audio'))

  return <>
    {audioPublications.map(publication => (
      <Publication
        key={`${publication.kind}-${Math.random()}`}
        publication={publication}
        participant={props.participant}
        isLocal={false}
        //disableAudio={disableAudio}
        //videoPriority={videoPriority}
        isMain={false}
      />
    ))}
  </>
}

export default function MainParticipant(props: any) {
  const classes = useStyles();
  const mainParticipant = useMainSpeaker();
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();

  const participants = useParticipants();

  //const videoPriority = mainParticipant === selectedParticipant || mainParticipant === screenShareParticipant ? 'high' : null;
  const videoPriority = "high";




  return (
    /* audio is disabled for this participant component because this participant's audio 
       is already being rendered in the <ParticipantStrip /> component.  */
    <div className={classes.container}>
      <MainParticipantInfo participant={mainParticipant} users={props.users}>
        <ParticipantTracks participant={mainParticipant} disableAudio enableScreenShare videoPriority={videoPriority} isMain={true}/>
        {/* <ParticipantTracks participant={mainParticipant} disableAudio videoPriority={videoPriority} isMain={true}/> */}
        {participants.filter(participant => participant.audioTracks.size > 0).map((participant, index) => <ParticipantAudio key={index} participant={participant} />)}
      </MainParticipantInfo>
    </div>
  );
}
