import React, { Component } from 'react';
import { withStyles, withTheme, makeStyles, ListItemIcon, Checkbox, IconButton, Fab } from '@material-ui/core';


import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';
import { Video,Message } from 'mdi-material-ui';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
        width: '100%',
        padding: 5,
        background: '#fff'
    },
    checkbox: {
        flex: 1,
        maxWidth: 40,
        minWidth: 40,
        padding: '5px 0'
    },
    image: {
        flex: 1,
        maxWidth: 60,
        minWidth: 60,
        padding: 5,
        position: 'relative',
        '&> img': {
            maxHeight: '100%',
            maxWidth: '100%',
            top: '50%',
            position: 'relative',
            transform: 'translateY(-50%)'
        }
    },
    info: {
        flex: 1,
        margin: '5px 0'
    },
    actions: {
        flex: 1,
        maxWidth: 116,
        minWidth: 116,
    },
    username: {
        display: 'block',
        fontSize: 15,
        fontWeight: 'bold',
    },
    company: {
        display: 'block',
        fontSize: 12,
        color: '#999'
    },

    available: {
        width: 14,
        height: 14,
        borderRadius: '50%',
        backgroundColor: '#81f542',
        display: 'inline-block',
        // margin: 12,
        position: 'absolute',
        bottom: 0,
        right: 0
    },
    busy: {
        width: 24,
        height: 24,
        borderRadius: '50%',
        backgroundColor: '#f5e042',
        display: 'inline-block',
        margin: 12
    },
    status: {
        display: 'inline-block',
        verticalAlign: 'middle',
    },
    chat: {
        display: 'inline-block',
        verticalAlign: 'middle',
        margin: '0 5px'
    },
    call: {
        display: 'inline-block',
        verticalAlign: 'middle',
        margin: '0 5px'
    },

}));


const UserBox = (props) => {
    const classes = useStyles(props);

    const { user, checked, onToggle, openMessageChat, callUser, inCall } = props;

    return <div className={classes.root}>
        {!inCall && <span className={classes.checkbox}>
            <ListItemIcon onClick={onToggle(user)}>
                <Checkbox
                    //edge="start"
                    checked={checked.findIndex(check => check.id === user.id) !== -1}
                    inputProps={{ 'aria-labelledby': `checkbox-list-label-${user.id}` }}
                    disabled={user.availability === 1}
                />
            </ListItemIcon>
        </span>}
        <span className={classes.image}>
            <img src={`/Base/Image/${user.fidImage}`} />
            <span className={user.availability === 0 ? classes.available : classes.busy} />
        </span>
        <span className={classes.info}>
            <span className={classes.username}>{user.userDenomination}</span>
            <span className={classes.company}>{user.denomination}</span>
        </span>
        <span className={classes.actions}>
            <span className={classes.chat}><Fab size="medium" onClick={() => { openMessageChat(user) }}><Message /></Fab></span>
            <span className={classes.call}><Fab size="medium" onClick={() => { callUser([user.id]) }}><Video /></Fab></span>
        </span>
    </div>;
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withTheme
);
export default enhance(UserBox);