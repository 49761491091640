import React, { Component } from 'react';
import { withStyles, withTheme, AppBar, Toolbar, IconButton, Tooltip } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';

import MoreVert from "@material-ui/icons/MoreVert";

import logoLicensee from "assets/img/logo-licensee.png"

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        boxShadow: "none",
        borderBottom: "0",
        marginBottom: "0",
        position: "sticky",
        width: "100%",
        zIndex: "1150",
        border: "0",
        borderRadius: "0px",
        transition: "all 150ms ease 0s",
        minHeight: "50px",
        display: "block",
        height: "60px",
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
    },
    grow: {
        flexGrow: 1,
    },
    logo: {
        height: 40,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    }
});


class VideochatHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var { classes, onLeftSidebarChange,onRightSidebarChange,iAmSuperUser } = this.props;
        return <AppBar className={classes.root} position="sticky">
            <Toolbar>
                {iAmSuperUser && <Tooltip title="Azioni">
                    <IconButton onClick={onLeftSidebarChange} style={{color: '#fff'}}>
                        <MoreVert className={classes.sidebarMiniIcon} />
                    </IconButton>
                </Tooltip>}
                <div className={classes.grow}><img className={classes.logo} src={logoLicensee} /></div>
                <Tooltip title="Mixer">
                    <IconButton onClick={onRightSidebarChange} style={{color: '#fff'}}>
                        <MoreVert className={classes.sidebarMiniIcon} />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        </AppBar>;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(VideochatHeader);