import React, { Component } from 'react';
import { withStyles, withTheme, Grid } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';

import XNumpadKeyboard from '../../../components/XNumpadKeyboard';
import XField from '../../../components/XField';
import XButton from '../../../components/XButton';
import CashRow from './CashRow';
import CashCell from './CashCell';
const styles = theme => ({
    root: {
        overflow: 'hidden auto',
        height: 'calc(100vh - 238px)'
    },
    numpad: {
        position: 'relative',
        left: '50%',
        transform: 'translate(-50%)'
    },
    center: {
        maxWidth: 600,
        position: 'relative',
        left: '50%',
        transform: 'translate(-50%)',
    },
    rows: {
        zIndex: 1,
    },
    sticky: {
        top: 0,
        position: 'sticky',
        zIndex: 2,
        background: '#fff !important'
    }
});


class CashSelectEditor extends Component {
    constructor(props) {
        super(props);
        this.state= {
            value: '',
            firstPress: true,
            data:[],
            searchTerm: '',
        }
    }
    componentDidMount() {
        this.loadData();
    }
    loadData = async () => {
        var { urlData } = this.props;
        var data = await fetch(urlData).then(res => res.json());
        this.setState({ data });
    }
    onSelectionChange = (id) => () => {
        this.props.onSubmit && this.props.onSubmit(id);
    }
    onSearch = (id) => (event,value) => {
        this.setState({searchTerm: value});
    }

    render() {
        var { classes, idKey,valueKey } = this.props;
        var { data,searchTerm } = this.state;
        var regex = new RegExp(searchTerm,"i");
        return <div className={classes.root}>
            <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={classes.center}>
                            <CashRow className={classes.sticky}><CashCell width={"100%"}><XField value={searchTerm} onChange={this.onSearch} /></CashCell></CashRow>
                            {data && data.filter(item => regex.test(item[valueKey])).map(item => <CashRow className={classes.rows} key={item[idKey]} onClick={this.onSelectionChange(item[idKey])}><CashCell width={"100%"}>{item[valueKey]}</CashCell></CashRow>)}
                        </div>
                    </Grid>
                </Grid>
        </div>;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(CashSelectEditor);