import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import XGrid from "components/XGrid"

import XGallery from "components/XGallery"


import "components/XReactDataGrid.css";
import XNavigation from 'components/XNavigation';
import Ring from 'components/Icons/Ring';

import { withStyles, Tooltip, IconButton, Grid } from '@material-ui/core';
import IdentitySelector from 'components/IdentitySelector';

import XMessageBox from 'components/XMessageBox';
import XSelect from 'components/XSelect';

import { Star, StarOutline, LockOpenOutline, LockOutline, CartOutline, BellRingOutline, BellOffOutline, StarOff, AccountMultiple, ArrowLeft, ArrowRight, Check, Close } from 'mdi-material-ui';
import { LockOffOutline, CartOffOutline } from 'components/Icons';
import { format, getXField } from 'utils';

import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withWidth } from '@material-ui/core';
import AssignFromStock from './AssignFromStock';


import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';

const styles = theme => ({
    row: {
        padding: 10,
        borderBottom: '1px solid',
    },
    priceAction: {
        // position: 'relative',
        // top: '50%',
        // transform: 'translateY(-50%)',
        textAlign: 'center',
    }
});

class PrenotationsExpandRow extends Component {
    constructor(props){
        super(props);
        this.state = {
            view : "prenotations",
            assign: false,
            rows:[],
            currentPos: undefined,
            assignData:[],
            qty: 0,
        }
        this.grd = React.createRef();
        
    }
    static contextTypes = {
        router: PropTypes.object
    }

    handleGridDoubleClick = (event, data) => {
        var { view } = this.state;
        //this.context.router.history.push(`/IREdit/${this.props.data.ID}?${view}=true`);
    }

    showPrenotations = () => {
        this.setState({view: "prenotations"});
    }
    onBook = () =>{
        var selectedRows = this.grd.current.getWrappedInstance().getSelectedData();
        this.grd.current.getWrappedInstance().clearSelection();
        var data = this.grd.current.getWrappedInstance().getRowData(selectedRows[0]);
        this.props.onBook && this.props.onBook(data.ID,data.Qty1Booked > 0, this.grd.current.getWrappedInstance().refresh, false, this.props.FIDIdentity, undefined);
    }
    onReserve = () =>{
        var selectedRows = this.grd.current.getWrappedInstance().getSelectedData();
        this.grd.current.getWrappedInstance().clearSelection();
        this.props.onReserve && this.props.onReserve(selectedRows[0], this.grd.current.getWrappedInstance().refresh, this.props.FIDIdentity, this.props.ID);
    }
    onNotice = () =>{
        var selectedRows = this.grd.current.getWrappedInstance().getSelectedData();
        this.grd.current.getWrappedInstance().clearSelection();
        this.props.onNotice && this.props.onNotice(selectedRows[0], this.grd.current.getWrappedInstance().refresh, this.props.FIDIdentity, this.props.ID);
    }
    render () {
        var { width, Code, ID, FIDCausal } = this.props;
        var { view } = this.state;

        var actions = [];
        var label = undefined;

        var gridName = "grdAssignStock";//"tblBOPStockP";
        //var dataRoute = `/BOP/GetBopsStockByCode/?Code=${Code}&grid=${gridName}`;
        var dataRoute = `/BOP/GetBopsStock/?FIDBOP=${ID}&grid=${gridName}`;
        var actions = [];
        var showBOOK = true;
        var showRESERVE = false;
        var showNOTICE = false;
        if(!!FIDCausal) {
            switch(FIDCausal){
                case 13://BOOK
                    showBOOK = true;
                    showRESERVE = false;
                    showNOTICE = false;
                    break;
                case 11://NOTICE
                    showBOOK = false;
                    showRESERVE = false;
                    showNOTICE = true;
                    break;
                case 12://RESERVE
                    showBOOK = false;
                    showRESERVE = true;
                    showNOTICE = false;
                    break;
            }
        }
        if(showBOOK){
            actions.push({
                group: 'actions',
                name:'Togli prenotazione',
                icon: <LockOffOutline />,
                action: this.onBook,
                condition: rows => {
                    if(rows.length === 1){
                        var data = this.grd.current.getWrappedInstance().getRowData(rows[0]);
                        if(!!data.Booked && parseInt(data.FIDIRBooked) === this.props.FIDIdentity) {
                            return true;
                        }
                    }
                    return false;
                } 
            });
            actions.push({
                group: 'actions',
                name:'Assegna',
                icon: <LockOutline />,
                action: this.onBook,
                condition: rows => {
                    if(rows.length === 1){
                        var data = this.grd.current.getWrappedInstance().getRowData(rows[0]);
                        if(!data.Booked || parseInt(data.FIDIRBooked) !== this.props.FIDIdentity) {
                            return true;
                        }
                    }
                    return false;
                } 
            });
        }
        if(showRESERVE){
            
            actions.push({
                group: 'actions',
                name:'Togli riservato',
                icon: <LockOffOutline />,
                action: this.onReserve,
                condition: rows => {
                    if(rows.length === 1){
                        var data = this.grd.current.getWrappedInstance().getRowData(rows[0]);
                        if (typeof data.FIDIRReserved === "string") {
                            data.FIDIRReserved = data.FIDIRReserved.split(',').map(Number);
                        }
                        if(!data.FIDIRReserved){
                            data.FIDIRReserved = [];
                        }
                        if(!!data.Reserved && data.FIDIRReserved.includes(this.props.FIDIdentity)) {
                            return true;
                        }
                    }
                    return false;
                } 
            });
            actions.push({
                group: 'actions',
                name:'Riserva',
                icon: <LockOpenOutline />,
                action: this.onReserve,
                condition: rows => {
                    if(rows.length === 1){
                        var data = this.grd.current.getWrappedInstance().getRowData(rows[0]);
                        if (typeof data.FIDIRReserved === "string") {
                            data.FIDIRReserved = data.FIDIRReserved.split(',').map(Number);
                        }
                        if(!data.FIDIRReserved){
                            data.FIDIRReserved = [];
                        }
                        if(!data.Reserved || !data.FIDIRReserved.includes(this.props.FIDIdentity)) {
                            return true;
                        }
                    }
                    return false;
                } 
            });
        }
        if(showNOTICE){
            actions.push({
                group: 'actions',
                name:'Togli avviso',
                icon: <BellOffOutline />,
                action: this.onNotice,
                condition: rows => {
                    if(rows.length === 1){
                        var data = this.grd.current.getWrappedInstance().getRowData(rows[0]);
                        if (typeof data.FIDIRNoticed === "string") {
                            data.FIDIRNoticed = data.FIDIRNoticed.split(',').map(Number);
                        }
                        if(!data.FIDIRNoticed){
                            data.FIDIRNoticed = [];
                        }
                        if(!!data.Noticed && data.FIDIRNoticed.includes(this.props.FIDIdentity)) {
                            return true;
                        }
                    }
                    return false;
                } 
            });
            actions.push({
                group: 'actions',
                name:'Avviso',
                icon: <BellRingOutline />,
                action: this.onNotice,
                condition: rows => {
                    if(rows.length === 1){
                        var data = this.grd.current.getWrappedInstance().getRowData(rows[0]);
                        if (typeof data.FIDIRNoticed === "string") {
                            data.FIDIRNoticed = data.FIDIRNoticed.split(',').map(Number);
                        }
                        if(!data.FIDIRNoticed){
                            data.FIDIRNoticed = [];
                        }
                        if(!data.Noticed || !data.FIDIRNoticed.includes(this.props.FIDIdentity)) {
                            return true;
                        }
                    }
                    return false;
                } 
            });
        }

        return <XGrid
            ref={this.grd}
            key={gridName}
            label={label}
            containerID={`gridExpandContainer_${ID}`}
            dataRoute={dataRoute}
            dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
            dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid==${gridName}`}
            dataRouteUpdateColumns={`/Base/UpdateColumns?grid==${gridName}`}
            actions={actions}
            showColumnsSelector={true}
            showFilterActivator={true}
        />;
    }
}


class Prenotations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: "IR",
            stockRows:[],
            orderRows:[],
            supplierRow:undefined,
            stockRowsInfo:{},
            orderRowsInfo:{},
            supplierRowInfo:{},
            assignData:[],
        };
        this.grdBops = React.createRef();
        this.grdPresentationDocInfo = React.createRef();
        this.MessageBox = React.createRef();
        this.grdStock = React.createRef();
        this.grdOrders = React.createRef();
        this.grdSuppliers = React.createRef();
    }
    static contextTypes = {
        router: PropTypes.object
    }
    handleGridDoubleClick = (event, data) => {
        this.context.router.history.push('/BOPEdit/' + data.ID);
    }
    handleGridNew = (event) => {
        this.context.router.history.push('/BOP');
    }
    onBook = (FIDBOP, isBooked, callback, bypass, FIDIdentity, CURRFIDBOP) => {
        if(isBooked && !bypass) {
            this.MessageBox.current.showMessageYesNo("Distinta già prenotata", "Confermi il cambio della prenotazione?", () =>{
                this.onBookConfirm(FIDBOP, callback, bypass, FIDIdentity, CURRFIDBOP);
            });
        }
        else {
            this.onBookConfirm(FIDBOP, callback, bypass, FIDIdentity, CURRFIDBOP);
        }
    }
    onBookConfirm = (FIDBOP, callback, bypass, FIDIdentity,CURRFIDBOP) => {
        if(FIDIdentity){
            this.doBook(FIDBOP,FIDIdentity, callback, bypass,CURRFIDBOP);
        }
        else {
            this.setState({
                FIDBOPToPrenotate: FIDBOP,selectIdentity: true, callback, bypass,CURRFIDBOP
            });
        }
    }

    onBookItem = () =>{
        var selectedRows = this.grdBops.current.getSelectedData();
        this.grdBops.current.clearSelection();
        var data = this.grdBops.current.getRowData(selectedRows[0]);
        this.onBook(data.ID,!!data.Booked, this.grdBops.current.refresh, true);
    }
    onReserveItem = () =>{
        var selectedRows = this.grdBops.current.getSelectedData();
        this.grdBops.current.clearSelection();
        this.onReserve(selectedRows[0], this.grdBops.current.refresh);
    }
    
    onReserve = (FIDBOP, callback, FIDIdentity,CURRFIDBOP) => {
        if(FIDIdentity){
            this.doReserve(FIDBOP,FIDIdentity, callback, CURRFIDBOP);
        }
        else {
            this.setState({
                FIDBOPToReserve: FIDBOP,selectIdentity: true, callback,CURRFIDBOP
            });
        }
    }
    
    onNoticeItem = () =>{
        var selectedRows = this.grdBops.current.getSelectedData();
        this.grdBops.current.clearSelection();
        this.onNotice(selectedRows[0], this.grdBops.current.refresh);
    }
    
    onNotice = (FIDBOP, callback, FIDIdentity,CURRFIDBOP) => {
        if(FIDIdentity){
            this.doNotice(FIDBOP,FIDIdentity, callback, CURRFIDBOP);
        }
        else {
            this.setState({
                FIDBOPToNotice: FIDBOP,selectIdentity: true, callback,CURRFIDBOP
            });
        }
    }
    onIdentitySelect = (FIDIdentity) => {
        var { FIDBOPToPrenotate, FIDBOPToReserve,FIDBOPToNotice } = this.state;
        this.setState({selectIdentity: false },() => {
            if(FIDBOPToPrenotate){
                this.doBook(FIDBOPToPrenotate,FIDIdentity, this.state.callback, this.state.bypass, this.state.CURRFIDBOP);
            }
            else if(FIDBOPToReserve){
                this.doReserve(FIDBOPToReserve,FIDIdentity, this.state.callback, this.state.CURRFIDBOP);
            }
            else if(FIDBOPToNotice) {
                this.doNotice(FIDBOPToNotice,FIDIdentity, this.state.callback, this.state.CURRFIDBOP);
            }
        });
        
    }

    doBook = (FIDBOP,FIDIdentity, callback, bypass,CURRFIDBOP) => {
        this.setState({ FIDBOPToNotice: false, FIDBOPToReserve: undefined, FIDBOPToPrenotate: undefined, selectIdentity: false }, () => {
            fetch(`/BOP/Book/${FIDBOP}?FIDIdentity=${FIDIdentity}&bypass=${bypass ? "true":"false"}&CURRFIDBOP=${CURRFIDBOP}`, {
                method: 'POST'
            }).then(res => {
                callback && callback();
                if(!!CURRFIDBOP) {
                    this.grdBops && this.grdBops.current && this.grdBops.current.refresh();
                }
                //this.onDocumentComplete(FIDIdentity, stars, FIDCausal, callback);
            });
        });
        
    }
    doReserve = (FIDBOP,FIDIdentity, callback,CURRFIDBOP) => {
        this.setState({ FIDBOPToNotice: false, FIDBOPToReserve: undefined, FIDBOPToPrenotate: undefined, selectIdentity: false }, () => {
            fetch(`/BOP/Reserve/${FIDBOP}?FIDIdentity=${FIDIdentity}&CURRFIDBOP=${CURRFIDBOP}`, {
                method: 'POST'
            }).then(res => {
                callback && callback();
                if(!!CURRFIDBOP) {
                    this.grdBops && this.grdBops.current && this.grdBops.current.refresh();
                }
                //this.onDocumentComplete(FIDIdentity, stars, FIDCausal, callback);
            });
        });
    }
    doNotice = (FIDBOP,FIDIdentity, callback,CURRFIDBOP) => {
        this.setState({ FIDBOPToNotice: false, FIDBOPToReserve: undefined, FIDBOPToPrenotate: undefined, selectIdentity: false }, () => {
            fetch(`/BOP/Notice/${FIDBOP}?FIDIdentity=${FIDIdentity}&CURRFIDBOP=${CURRFIDBOP}`, {
                method: 'POST'
            }).then(res => {
                callback && callback();
                if(!!CURRFIDBOP) {
                    this.grdBops && this.grdBops.current && this.grdBops.current.refresh();
                }
                //this.onDocumentComplete(FIDIdentity, stars, FIDCausal, callback);
            });
        });
    }
    
    changeView = view => () =>{
        this.setState({view});
    }
    
    rowExpandFormat = (data) => {
        return <PrenotationsExpandRow ID={data.ID} Code={data.Code} onReserve={this.onReserve} onBook={this.onBook} onNotice={this.onNotice} FIDIdentity={this.state.FIDIdentity} />;
    }
    rowExpandFormatIR = (data) => {
        return <PrenotationsExpandRow ID={data.ID} Code={data.Code} onReserve={this.onReserve} onBook={this.onBook} onNotice={this.onNotice} FIDIdentity={data.FIDIdentity} FIDCausal={data.FIDCausal} />;
    }
    loadCurrentData = () => {

    }
    beginAssign = () => {
        var rows = this.grdBops.current.getSelectedData().map(r => this.grdBops.current.getRowData(r));
        
        this.setState({assign: true, rows, currentPos: 0},this.loadCurrentData);
    }
    validateData = () => {
        var residualQty = this.getResidualQty();
        if(residualQty < 0){
            this.MessageBox.current.showMessageOk("Verifica dati","La quantità assegnata è maggiore della quantità in ordine. Verificare i dati prima di proseguire.");
            return false;
        }
        return true;
    }
    nextPos = async () =>{
        if(!this.validateData()){
            return;
        }
        //salvo nell'application state le informazioni
        var data = this.buildData();

        await fetch('/DOC/SaveAssign', {
            method: 'POST',
            body: JSON.stringify(data)
        });
        this.setState(state =>{
            state.currentPos++;
            state.stockRows=[];
            state.stockRowsInfo=[];
            state.orderRows=[];
            state.orderRowsInfo=[];
            state.supplierRow= undefined;
            state.supplierRowInfo={};
            return state;
        });
    }
    buildData = () => {
        var { rows, currentPos } = this.state;
        var row = rows[currentPos];
        var { rows, currentPos, stockRowsInfo, orderRowsInfo, supplierRowInfo, supplierRow } = this.state;
        var data = {
            FIDDocumentRow: row.ID,
            stock:[],
            orders:[],
            supplier:{}
        };
        var stockKeys = Object.keys(stockRowsInfo).map(Number);
        for(var i in stockKeys) {
            var key = stockKeys[i];
            data.stock.push({
                FIDBOP: key,
                Qty1: stockRowsInfo[key]
            });
        }
        var orderKeys = Object.keys(orderRowsInfo).map(Number);
        for(var i in orderKeys) {
            var key = orderKeys[i];
            data.orders.push({
                FIDDocumentRow: key,
                Qty1: orderRowsInfo[key]
            });
        }
        if(supplierRowInfo){
            data.supplier = {
                FIDSupplier: supplierRow,
                Qty1: supplierRowInfo.Qty
            }
        }

        return data;
    }
    previousPos = () =>{
        //cancello gli eventuali dati già salvati nello state per questa riga d'ordine
        this.setState(state =>{
            state.currentPos--;
            state.stockRows=[];
            state.stockRowsInfo=[];
            state.orderRows=[];
            state.orderRowsInfo=[];
            state.supplierRow= undefined;
            state.supplierRowInfo={};
            return state;
        });
    }
    cancelAssign = async () =>{
        await fetch('/DOC/ClearAssign', {
            method: 'POST',
        });
        this.setState({assign: false, rows:[], currentPos: undefined, stockRows:[], stockRowsInfo:[], orderRows:[], orderRowsInfo:[], supplierRow: undefined, supplierRowInfo:{}});
    }
    confirmAssign = async () =>{
        if(!this.validateData()){
            return;
        }
        var data = this.buildData();

        await fetch('/DOC/SaveAssign', {
            method: 'POST',
            body: JSON.stringify(data)
        });
        fetch('DOC/ConfirmAssign',{
            method: 'POST'
        }).then(res => res.json()).then(data => {
            if(data){
                this.setState({assign: false, rows:[], currentPos: undefined, stockRows:[], stockRowsInfo:[], orderRows:[], orderRowsInfo:[], supplierRow: undefined, supplierRowInfo:{}});
            }
        })
    }
    onStockChange = (data, newSelection) => {
        this.setState(state => {
            state.stockRows = this.grdStock.current.getSelectedData();
            if(newSelection){
                if(Array.isArray(newSelection)){
                    for(var i in newSelection){
                        var FIDRow = newSelection[i];
                        var row = this.grdStock.current.getRowData(FIDRow);
                        if(row && !state.stockRowsInfo[FIDRow]) {
                            state.stockRowsInfo[FIDRow] = row.Qty1Disp;
                        }
                    }
                }
                else {
                    var row = this.grdStock.current.getRowData(newSelection);
                    if(row) {
                        state.stockRowsInfo[newSelection] = row.Qty1Disp;
                    }
                }
            }
            var inforR = Object.keys(state.stockRowsInfo).map(Number);
            for(var i in inforR){
                if(!state.stockRows.includes(inforR[i])) {
                    delete state.stockRowsInfo[inforR[i]];
                }
            }
            return state;
        });
    }
    onOrderChange = (data,newSelection) => {
        this.setState(state => {
            state.orderRows = this.grdOrders.current.getSelectedData();
            
            if(newSelection){
                if(Array.isArray(newSelection)){
                    for(var i in newSelection){
                        var FIDRow = newSelection[i];
                        var row = this.grdOrders.current.getRowData(FIDRow);
                        if(row && !state.orderRowsInfo[FIDRow]) {
                            state.orderRowsInfo[FIDRow] = row.Qty1;
                        }
                    }
                }
                else {
                    var row = this.grdOrders.current.getRowData(newSelection);
                    if(row){
                        state.orderRowsInfo[newSelection] = row.Qty1;
                    }
                }
            }
            var inforR = Object.keys(state.orderRowsInfo).map(Number);
            for(var i in inforR){
                if(!state.orderRows.includes(inforR[i])) {
                    delete state.orderRowsInfo[inforR[i]];
                }
            }
            return state;
        });
    }
    onSupplierChange = () => {
        this.setState(state => {
            var data = this.grdSuppliers.current.getSelectedData();
            if(data.length > 0){
                state.supplierRow = data[0];
                state.supplierRowInfo = {
                    Qty: this.getResidualQty(false)
                };
            }
            else{
                state.supplierRow = undefined;
                state.supplierRowInfo = {};
            }
            return state;
        });
    }
    onStockQtyChange = (stockId) =>(key) => (event, value) => {
        console.log(stockId,value);
        this.setState(state => {
            state.stockRowsInfo[stockId] = value;
            return state;
        });
    }
    onOrderQtyChange = (orderId) =>(key) => (event, value) => {
        this.setState(state => {
            state.orderRowsInfo[orderId] = value;
            return state;
        });
    }
    onSupplierQtyChange = (stockId) =>(key) => (event, value) => {
        this.setState(state => {
            state.supplierRowInfo.Qty = value;
            return state;
        });
    }
    renderFromStock = () =>{
        var { stockRows, stockRowsInfo } = this.state;
        if(!this.grdStock.current) {
            return <Fragment></Fragment>;
        }
        return <Fragment>
            {stockRows.map((r,i) => {
                var row = this.grdStock.current.getRowData(r);
                var data = {};
                data[`stock_${r}`] = stockRowsInfo[r];
                return <Grid container item xs={12}>
                    <Grid item xs={12} md={4}>
                        <p>Barcode {row.Barcode}</p>
                        <p>Q.tà disponibile {row.Qty1Disp} {row.MeasureUnit1}</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {getXField(data,[{Field:`stock_${r}`,Visibility: true, Required: 1}],12,6,`stock_${r}`,"Q.tà da prenotare",this.onStockQtyChange(r),{type:'number', max: row.Qty1Disp})}
                    </Grid>
                </Grid>;
            })}
        </Fragment>;
    }
    renderFromOrders = () =>{
        var { orderRows,orderRowsInfo } = this.state;
        
        if(!this.grdOrders.current) {
            return <Fragment></Fragment>;
        }
        return <Fragment>
            {orderRows.map((r,i) => {
                var row = this.grdOrders.current.getRowData(r);
                if(typeof row.DocumentDate === "string") {
                    row.DocumentDate = new Date(row.DocumentDate);
                }
                var data = {};
                data[`order_${r}`] = orderRowsInfo[r];
                return <Grid container item xs={12}>
                    <Grid item xs={12} md={4}>
                        <p>{row.Causal} n. {row.Number} del {row.DocumentDate.toLocaleDateString()}</p>
                        <p>Q.tà ordinata {row.Qty1} {row.UM1}</p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {getXField(data,[{Field:`order_${r}`,Visibility: true, Required: 1}],12,6,`order_${r}`,"Q.tà da prenotare",this.onOrderQtyChange(r),{type:'number', max: row.Qty1})}
                    </Grid>
                </Grid>;
            })}
        </Fragment>;
    }
    renderFromSupplier = () =>{
        var { supplierRow, supplierRowInfo } = this.state;
        if(!supplierRow){
            return <Fragment></Fragment>;
        }
        var row = this.grdSuppliers.current.getRowData(supplierRow);
        var data = {};
        data[`supplier_${supplierRow}`] = supplierRowInfo.Qty;
        return <Grid container item xs={12}>
            <Grid item xs={12} md={4}>
                <p>{row.Denomination}</p>
                <p>Costo fornitore {format(row.SupplierCost)}</p>
            </Grid>
            <Grid item xs={12} md={8}>
                {getXField(data,[{Field:`supplier_${supplierRow}`,Visibility: true, Required: 1}],12,6,`supplier_${supplierRow}`,"Q.tà da ordinare",this.onSupplierQtyChange(supplierRow),{type:'number'})}
            </Grid>
        </Grid>;
    }

    getResidualQty = (includeSupplier = true) => {
        var { rows, currentPos, stockRowsInfo, orderRowsInfo, supplierRowInfo } = this.state;
        var row = rows[currentPos];
        var residualQty= row.Qty1ToAssign;
        if(stockRowsInfo && Object.values(stockRowsInfo).length > 0){
            residualQty -= Object.values(stockRowsInfo).reduce((a,b) => a+b);
        }
        if(orderRowsInfo && Object.values(orderRowsInfo).length > 0){
            residualQty -= Object.values(orderRowsInfo).reduce((a,b) => a+b);
        }
        if(includeSupplier && supplierRowInfo.Qty){
            residualQty -= supplierRowInfo.Qty;
        }

        return residualQty;
    }
    renderAssign = () => {
        var { rows, currentPos } = this.state;
        var row = rows[currentPos];
        if(typeof row.DocumentDate === "string") {
            row.DocumentDate = new Date(row.DocumentDate);
        }
        var files = row.Files ? row.Files.split(',').map(m => parseInt(m, 10)) : [];

        var residualQty = this.getResidualQty();
        return (
            <Fragment>
                <Grid className={this.props.classes.row} container xs={12} spacing={2}>
                    <Grid item container spacing={2} xs={12} sm={2}>
                        <Grid item xs={12} sm={9}>
                            <XGallery imageIds={files} hideThumbs={true} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <p>{row.Code}</p>
                        <p>{row.Description}</p>
                        <p>{row.ExtendedDescription}</p>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <p>{format(row.DiscountedTotalPrice)}</p>
                        <p>{row.Qty1} {row.UM1}</p>
                        <p>{format(row.TotalDiscountedAmount)}</p>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <p>{row.Identity}</p>
                        <p>Ord n. {row.Number}</p>
                        <p>{row.DocumentDate && row.DocumentDate.toLocaleDateString && row.DocumentDate.toLocaleDateString()}</p>
                    </Grid>
                </Grid>
                <Grid className={this.props.classes.row} container xs={12} spacing={2}>
                    <Grid item xs={12}>
                        {this.renderFromStock()}
                        {this.renderFromOrders()}
                        {this.renderFromSupplier()}
                        <Grid container item xs={12}>
                            <Grid item xs={12} md={4}>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                {residualQty} {row.UM1} da assegnare
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid id="containerStock" style={{height: 300, padding: 10}} className={this.props.classes.row} container xs={12} spacing={2}>
                    <XGrid
                        key={`grdStock_${row.ID}`}
                        innerRef={this.grdStock}
                        containerID="containerStock"
                        usePrimaryColor={false}
                        dataRoute={`/BOP/GetBopsStock/?FIDBOP=${row.FIDBOP}&grid=grdAssignStock`}
                        dataRouteColumns={`/Base/GetColumns?grid=${"grdAssignStock"}`}
                        dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${"grdAssignStock"}`}
                        dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${"grdAssignStock"}`}
                        //rowExpandFormat={rowExpand}
                        //actions={gridActions}
                        label={"Magazzino"}
                        visibleRows={5}
                        onRowChangeSelection={this.onStockChange}
						showImgColumn={true}
                        //customContent={customContent}
                    />
                </Grid>
                <Grid id="containerOrders" style={{height: 300, padding: 10}} className={this.props.classes.row} container xs={12} spacing={2}>
                    <XGrid
                        key={`grdOrders_${row.ID}`}
                        innerRef={this.grdOrders}
                        containerID="containerOrders"
                        usePrimaryColor={false}
                        dataRoute={`/DOC/GetOrdersToReceive/?code=${row.Code}&grid=grdOrdersToReceive`}
                        dataRouteColumns={`/Base/GetColumns?grid=${"grdOrdersToReceive"}`}
                        dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${"grdOrdersToReceive"}`}
                        dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${"grdOrdersToReceive"}`}
                        //rowExpandFormat={rowExpand}
                        //actions={gridActions}
                        label={"Ordini in arrivo"}
                        visibleRows={5}
                        onRowChangeSelection={this.onOrderChange}
                        //customContent={customContent}
                    />
                </Grid>
                <Grid id="containerNewOrder" style={{height: 300, padding: 10}} className={this.props.classes.row} container xs={12} spacing={2}>
                    <XGrid
                        key={`grdSuppliers_${row.ID}`}
                        innerRef={this.grdSuppliers}
                        containerID="containerNewOrder"
                        usePrimaryColor={false}
                        dataRoute={`/DOC/GetAllBOPSuppliers/?id=${row.FIDBOP}&grid=grdBOPSuppliersForOrder`}
                        dataRouteColumns={`/Base/GetColumns?grid=${"grdBOPSuppliersForOrder"}`}
                        dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${"grdBOPSuppliersForOrder"}`}
                        dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${"grdBOPSuppliersForOrder"}`}
                        //rowExpandFormat={rowExpand}
                        //actions={gridActions}
                        label={"Nuovo ordine"}
                        visibleRows={5}
                        onlyOneSelection={true}
                        onRowChangeSelection={this.onSupplierChange}
                        //customContent={customContent}
                    />
                </Grid>
            </Fragment>
        );
    }
    render() {
        var { width } = this.props;
        var { view,selectIdentity, assign,rows,currentPos,changeAvailStep } = this.state;
        var key = "tblIRAssignmentsNew";
        var gridName = key;
        var dataRoute = `/DOC/GetOrdersToComplete?grid=${gridName}&prenotations=true`;
        var rowExpand = undefined;//this.rowExpandFormatIR;
        var customContent = undefined;
        var customCells = undefined;
        var viewType = undefined;
        var rowHeight = 42;
        var onlyOneSelection = undefined;
        var hideExpandButton = false;
        var onRowExpand = undefined;
        var gridActions = [{
            group: 'actions',
            name: 'Assegna',
            action: this.beginAssign,
            icon: <LockOutline />,
            condition: r => r.length > 0
        }];
        
        var actions = [];
        actions.push({ name: "Anagrafiche", icon: <AccountMultiple />, action: this.changeView("IR"), selected: view === "IR" });
        //actions.push({ name: "Distinte", icon: <Ring />, action: this.changeView("BOPS"), selected: view === "BOPS" });
        actions.push({ name: "Magazzino", icon: <Ring />, action: this.changeView("STOCK"), selected: view === "STOCK" });
        var direction = "right";
        if (["xs"].includes(width)) {
            direction = "down";
        }
        var label = <span><XNavigation actions={actions} direction={direction} showLabel /></span>;
        if (view === "BOPS") {
            key = "tblBOPP";
            gridName = "tblBOPP";
            dataRoute = "/BOP/GetData?grid=tblBOP&prenotations=true";
            rowExpand = this.rowExpandFormat;
            gridActions = [
                {
                    component: (
                        <Tooltip title="Prenota">
                            <IconButton aria-label="Prenota" onClick={this.onBookItem}>
                                <LockOutline />
                            </IconButton>
                        </Tooltip>
                    ),
                    condition: rows => {
                        return false;
                    } 
                },
                {
                    component: (
                        <Tooltip title="Togli prenotazione">
                            <IconButton aria-label="Togli prenotazione" onClick={this.onBookItem}>
                                <LockOffOutline />
                            </IconButton>
                        </Tooltip>
                    ),
                    condition: rows => {
                        return false;
                    } 
                },
                {
                    component: (
                        <Tooltip title="Avviso">
                            <IconButton aria-label="Avviso" onClick={this.onNoticeItem}>
                                <BellRingOutline />
                            </IconButton>
                        </Tooltip>
                    ),
                    condition: rows => {
                        return false;
                    } 
                },
                {
                    component: (
                        <Tooltip title="Togli avviso">
                            <IconButton aria-label="Togli avviso" onClick={this.onNoticeItem}>
                                <BellOffOutline />
                            </IconButton>
                        </Tooltip>
                    ),
                    condition: rows => {
                        return false;
                    } 
                },
            ];
        }
        else if (view === "STOCK") {
            key = "tblAssignStock";
            gridName = key;

            customContent = <AssignFromStock />;

            
        }


        if( assign && rows && rows.length > 0){
            key = "ASSIGN";
            gridActions = [
                {
                    group: 'actions',
                    name: 'Annulla',
                    action: this.cancelAssign,
                    icon: <Close />
                }
            ];
            if(currentPos > 0){
                gridActions.push({
                    group: 'actions',
                    name: 'Indietro',
                    action: this.previousPos,
                    icon: <ArrowLeft />
                })
            }
            if(currentPos < rows.length-1){
                gridActions.push({
                    group: 'actions',
                    name: 'Avanti',
                    action: this.nextPos,
                    icon: <ArrowRight />
                })
            }
            if(currentPos === rows.length-1){
                gridActions.push({
                    group: 'actions',
                    name: 'Conferma',
                    action: this.confirmAssign,
                    icon: <Check />
                })
            }
            gridActions.reverse();
            label = `Assegnazione riga ${currentPos+1} di ${rows.length}`;
            customContent = this.renderAssign();
        }
        var style = {};
        if(selectIdentity){
            //style.display = "none";
            style.position= "absolute";
            style.left = "100000px";
        }

        return (
            <Fragment>
                <XGrid
                    innerRef={this.grdBops}
                    style={style}
                    usePrimaryColor={true}
                    key={key}
                    dataRoute={dataRoute}
                    dataRouteColumns={`/Base/GetColumns?grid=${gridName}`}
                    dataRouteAvailableColumns={`/Base/GetAvailableColumns?grid=${gridName}`}
                    dataRouteUpdateColumns={`/Base/UpdateColumns?grid=${gridName}`}
                    rowExpandFormat={rowExpand}
                    actions={gridActions}
                    label={label}
                    customContent={customContent}
                    customCells={customCells}
                    viewType={viewType}
                    rowHeight={rowHeight}
                    onlyOneSelection={onlyOneSelection}
                    hideExpandButton={hideExpandButton}
                    onRowExpand={onRowExpand}
					showImgColumn={true}
                />
                {selectIdentity && <IdentitySelector onIdentitySelected={this.onIdentitySelect} onCancel={() => { this.setState({ selectIdentity: false }) }} />}
                <XMessageBox innerRef={this.MessageBox} />
            </Fragment>
        );
    }
}


// export default withStyles(styles)(Prenotations);



const enhance = compose(
    withStyles(styles),
    withWidth(),
    connect(
        state => state.global,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    )
);
export default enhance(Prenotations);