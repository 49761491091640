import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import { withTheme, makeStyles } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';
import CashRow from './Cash/CashRow';
import CashCell from './Cash/CashCell';
import { Pencil, Delete } from 'mdi-material-ui';
import XButton from '../../components/XButton';
import moment from 'moment';
import classNames from 'classnames';
import CashNumberEditor from './Cash/CashNumberEditor';
import CashDateEditor from './Cash/CashDateEditor';
import XMessageBox from 'components/XMessageBox';

const useStyles = makeStyles(theme => ({
    root: {

    },
    payMethod: {
        display: 'inline-block',
        margin: 5,
        padding: 20,
        background: theme.palette.secondary.main,
        color: '#fff',
        cursor: 'pointer',
    },
    payMethodSelected: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    headerRow: {
        fontWeight: 'bold'
    }
}));


const DOCDeadLineEdit = forwardRef((props, ref) => {
    const classes = useStyles(props);

    const amountEditor = useRef(null);
    const dateEditor = useRef(null);
    const messageBox = useRef(null);

    const { data } = props;

    const [registerRows, setRegisterRows] = useState([]);
    const [totalDocument, setTotalDocument] = useState(0);
    const [totalPayed, setTotalPayed] = useState(0);
    const [totalToPay, setTotalToPay] = useState(0);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [payment, setPayment] = useState(undefined)
    const [paymentMethod, setPaymentMethod] = useState(undefined)
    const [editPayment, setEditPayment] = useState(false);

    const [editPaymentDate, setEditPaymentDate] = useState(false);
    const [editPaymentAmount, setEditPaymentAmount] = useState(false);
    const [maxAmount, setMaxAmount] = useState(0);

    useEffect(() => {
        clearSession(loadData);
    }, []);
    useEffect(() => {
        setTotalToPay(parseFloat((totalDocument - totalPayed).toFixed(2)));
    }, [totalPayed, totalDocument]);

    useImperativeHandle(ref, () => ({
        confirm: async () => {
            if (editPaymentDate) {
                dateEditor.current.onSubmit();
                return false;
            }
            else if (editPaymentAmount) {
                amountEditor.current.onNumpadSubmit();
                return false;
            }
            else if (editPayment) {
                let result = await saveDueRow();
                if (result) {
                    loadData();
                    setEditPayment(false);
                    setPayment(undefined);
                }

                return false;
            }
            else {
                return await confirmPayment();
            }
        },
        back: () => {
            if (editPaymentDate) {
                setEditPaymentDate(false);
                return true;
            }
            else if (editPaymentAmount) {
                setEditPaymentAmount(false);
                return true;
            }
            else if (editPayment) {
                setEditPayment(false);
                setPayment(undefined);
                return true;
            }
            clearSession();
            return false;
        },
        restore: async () => {
            return await restoreDueRegister();
        },
        canRestore: () => {
            return !!data.FIDDueRegisterOLD
        }
    }));

    const loadData = async () => {
        var params = `${data.FIDDocumentHeader}`;

        await Promise.all([
            fetch(`/DOCDeadLine/GetDueRegisterRows/${data.FIDDueRegister}`),
            fetch(`/DOC/GetCashTotalFinalDiscounted/${params}`),
            fetch(`/DOCDeadLine/GetTotalDueRegisters/${data.FIDDueRegister}`),
            fetch(`/DOC/GetCashPaymentMethods`)
        ]).then(([jdueRows, jtotalDocument, jtotalToPay, jpaymentMethods]) => {
            return Promise.all([jdueRows.json(), jtotalDocument.json(), jtotalToPay.json(), jpaymentMethods.json()])
        }).then(([dDueRows, dtotalDocument, dtotalToPay, dpaymentMethods]) => {
            setRegisterRows(dDueRows);
            setTotalDocument(dtotalDocument);
            setTotalPayed(dtotalToPay);
            setPaymentMethods(dpaymentMethods);
        });
    }
    const saveDueRow = async () => {
        if (!paymentMethod.ID) {
            messageBox.current.showMessageOk("Modifica scadenzario", "Metodo di pagamento non selezionato");
            return false;
        }

        var dueRow = {
            ID: payment.ID,
            FIDDueRegister: payment.FIDDueRegister,
            FIDDocumentHeader: payment.FIDDocumentHeader,
            TotalAmount: payment.TotalAmount,
            //TotalAmountEquivalent: payment.TotalAmountEquivalent,
            FIDCurrency: payment.FIDCurrency,
            DueDate: payment.DueDate,
            FIDPaymentMethod: paymentMethod.ID,
            FIDDocumentDeadline: payment.FIDDocumentDeadline
        }
        let result = fetch(`/DOCDeadLine/SaveDueRegisterRow/${data.FIDDueRegister}`, {
            method: 'POST',
            body: JSON.stringify(dueRow)
        }).then(res => res.json());
        return result;
    }
    const confirmPayment = async () => {
        if (totalToPay > 0) {
            messageBox.current.showMessageOk("Modifica scadenzario", "Importo sopseso non gestito");
            return false;
        }
        return await fetch(`/DOCDeadLine/SaveDueRegister/${data.FIDDueRegister}`, {
            method: 'POST'
        }).then(res => res.json());
    }
    const restoreDueRegister = async () => {
        return await fetch(`/DOCDeadLine/RestoreDueRegister/${data.FIDDueRegister}`, {
            method: 'POST'
        }).then(res => res.json());
    }
    const clearSession = (callback) => {
        fetch('/DOC/ClearSession', { method: 'POST' }).then(res => {
            callback && callback()
        });
    }

    const onEditPayment = (item) => () => {
        setPayment(Object.assign({}, item));
        setMaxAmount((totalToPay + item.TotalAmount).toFixed(2));
        let payM = paymentMethods.find(p => p.ID === item.FIDPaymentMethod) || {};
        setPaymentMethod(payM);
        setEditPayment(true);
    }
    const onDeletePayment = (item) => () => {
        fetch(`/DOCDeadLine/DeleteDueRegisterRow/${item.ID}`, { method: 'POST' }).then(res => {
            loadData();
        })
    }
    const onAddPayment = () => {
        setPayment({
            DueDate: moment(new Date()),
            TotalAmount: totalToPay
        });

        setMaxAmount(totalToPay);
        setPaymentMethod({});
        setEditPayment(true);
    }
    const onPaymentMethodSelect = (paymentMethod) => () => {
        setPaymentMethod(paymentMethod);
    }
    const onEditorDateClick = () => {
        setEditPaymentDate(true);
    }
    const onEditorClick = () => {
        setEditPaymentAmount(true);
    }

    const onEditorSubmit = (fields) => {
        var value = parseFloat(fields[1].value);
        if (value > maxAmount) {
            messageBox.current.showMessageOk("Modifica scadenzario", "L'importo inserito è maggiore del totale residuo da pagare");
            return;
        }
        let p = payment;
        p.TotalAmount = value;
        setPayment(p);
        setEditPaymentAmount(false);
    }

    const onDateEditorSubmit = (_, value) => {
        if (value && !value._isAMomentObject) {
            value = moment(new Date(value));
        }
        let p = payment;
        p.DueDate = value;
        setPayment(p);
        setEditPaymentDate(false);
    }

    const onEditorRecalculate = (fields) => {
        //keys
        var TotToPay = "MaxAmount";
        var paymentAmount = "TotalAmount";
        var Suspended = "Suspended";

        var elTotToPay = fields.filter(item => item.id === TotToPay)[0];
        var elpaymentAmount = fields.filter(item => item.id === paymentAmount)[0];
        var elSuspended = fields.filter(item => item.id === Suspended)[0];

        elSuspended.value = (elTotToPay.value - elpaymentAmount.value).toFixed(2);

        fields = [elTotToPay, elpaymentAmount, elSuspended];
        return fields;
    }
    const onNegativeAmounts = () => {
        messageBox.current.showMessageOk("Modifica scadenzario", "L'importo inserito è maggiore del totale residuo da pagare");
    }



    const now = moment(new Date());
    if (editPaymentAmount) {

        var fields = [
            {
                label: "Totale da pagare",
                id: "MaxAmount",
                value: maxAmount,
                readOnly: true
            },
            {
                label: "Importo pagamento",
                id: "TotalAmount",
                value: payment.TotalAmount,
            },
            {
                label: "Sospeso",
                id: "Suspended",
                value: (maxAmount - payment.TotalAmount),
                readOnly: true
            },
        ];
        return <div>
            <CashNumberEditor innerRef={amountEditor} id={"TotalAmount"} fields={fields} onSubmit={onEditorSubmit} onRecalculate={onEditorRecalculate} onNegativeAmounts={onNegativeAmounts} fixSpacing />
            <XMessageBox innerRef={messageBox} />
        </div>
    }
    else if (editPaymentDate) {
        return <div>
            <CashDateEditor innerRef={dateEditor} fieldLabel={"Data pagamento"} id={"DueDate"} value={payment.DueDate} onSubmit={onDateEditorSubmit} hideConfirmButton />
            <XMessageBox innerRef={messageBox} />
        </div>
    }




    if (editPayment) {
        if (payment.DueDate && !payment.DueDate._isAMomentObject) {
            payment.DueDate = moment(new Date(payment.DueDate));
        }
        return <div>
            {paymentMethods.map(item => (<span className={classNames(classes.payMethod, { [classes.payMethodSelected]: paymentMethod.ID === item.ID })} onClick={onPaymentMethodSelect(item)}>{item.Description}</span>))}
            {paymentMethod && <div>
                <CashRow>
                    <CashCell label={"Pagamento"} width={300} totalWidth={701}>{paymentMethod.Description}</CashCell>
                    <CashCell label={"Data"} width={200} totalWidth={701} onClick={onEditorDateClick}>{payment.DueDate && payment.DueDate.format('DD/MM/YYYY')}</CashCell>
                    <CashCell label={"Importo"} width={200} totalWidth={701} onClick={onEditorClick}>{payment.TotalAmount}</CashCell>
                    <CashCell width={1} totalWidth={701}></CashCell>
                </CashRow>
            </div>}
            <XMessageBox innerRef={messageBox} />
        </div>;
    }

    return <div>
        <CashRow>
            <CashCell width={250} totalWidth={750} label={"Totale documento"}>{totalDocument}</CashCell>
            <CashCell width={250} totalWidth={750} label={"Gestito"}>{totalPayed}</CashCell>
            <CashCell width={250} totalWidth={750} label={"Sospeso"}>{totalToPay}</CashCell>
        </CashRow>
        {registerRows && <CashRow className={classes.headerRow}>
            <CashCell width={250} totalWidth={1250}>{"Modalità di pagamento"}</CashCell>
            <CashCell width={250} totalWidth={1250}>{"Data"}</CashCell>
            <CashCell width={250} totalWidth={1250}>{"Importo"}</CashCell>
            <CashCell width={250} totalWidth={1250}>{"Importo già pagato"}</CashCell>
            <CashCell width={250} totalWidth={1250}></CashCell>
        </CashRow>}
        {registerRows && registerRows.map(item => {
            if (item.DueDate && !item.DueDate._isAMomentObject) {
                item.DueDate = moment(new Date(item.DueDate));
            }
            return (<CashRow>
                <CashCell width={250} totalWidth={1250}>{item.PaymentMethod}</CashCell>
                <CashCell width={250} totalWidth={1250}>{item.DueDate.format('DD/MM/YYYY')}</CashCell>
                <CashCell width={250} totalWidth={1250}>{item.TotalAmount}</CashCell>
                <CashCell width={250} totalWidth={1250}>{item.PayedAmount}</CashCell>
                <CashCell width={250} totalWidth={1250}>
                    {item.PayedAmount === 0 && <><XButton onClick={onEditPayment(item)}><Pencil /></XButton><XButton onClick={onDeletePayment(item)}><Delete /></XButton></>}
                    {item.PayedAmount > 0 && ""}
                </CashCell>
            </CashRow>)
        })}

        {totalToPay > 0 && <CashRow>
            <CashCell width={250} totalWidth={1000}>{now.format('DD/MM/YYYY')}</CashCell>
            <CashCell width={250} totalWidth={1000}>{"SOSPESO"}</CashCell>
            <CashCell width={250} totalWidth={1000}>{totalToPay}</CashCell>
            <CashCell width={250} totalWidth={1000}><XButton onClick={onAddPayment}>Gestisci</XButton></CashCell>
        </CashRow>}
        <XMessageBox innerRef={messageBox} />
    </div>;
})



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withTheme
);
export default enhance(DOCDeadLineEdit);