import React from 'react';
import AudioInputList from './AudioInputList/AudioInputList';
import AudioOutputList from './AudioOutputList/AudioOutputList';
import VideoInputList from './VideoInputList/VideoInputList';
import { Button, Switch, FormControlLabel } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100vw - 32px)',
      },
      '& .inputSelect': {
        width: 'calc(100% - 35px)',
      },
      // zIndex: '5',
      // transition: '0.5s', /* 0.5 second transition effect to slide in the sidenav */
      // overflowX: 'hidden', /* Disable horizontal scroll */
      // position: 'fixed', /* Stay in place */
      backgroundColor: "white",
    },
    button: {
      position: 'absolute',
      right: '0',
      bottom: '0',
    },
    paper: {
      [theme.breakpoints.down('xs')]: {
        margin: '16px',
      },
    },
  })
);

export function DeviceSelector(props) {
  const classes = useStyles();
  const [force, setForce] = React.useState(false);

  const handleForceChange = (event) => {
    setForce(event.target.checked);
  };

  const close = () => {
    if(props.showForceSwitch)
      props.forcePinChange(force)
    props.onClose()
  }

  return (
	<div className={classes.container}>
        <AudioInputList />
        <AudioOutputList />
        <VideoInputList />
        {props.showForceSwitch &&
			<FormControlLabel
				label="Force Participants Focus"
				control={<Switch
							disabled = {props.forcePin}
							checked = {force && props.forcePin === undefined}//se qualcuno ha già fatto force non posso più farlo io
							onChange = {handleForceChange}
							name = "checkedForce"
							inputProps = {{ 'aria-label': 'primary checkbox' }}
						/>}
			/>
        }
	</div>
  );
}
