import React, { Component, Fragment } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import { Ring } from 'components/Icons';
import XNavigation from 'components/XNavigation';
import XGrid from "components/XGrid";

import { getXSelect } from 'utils';


import { ArrowLeft, ArrowRight, Check, TextBox, Package } from 'mdi-material-ui';
import ProductionPositionSelector from './ProductionPositionSelector';

const styles = theme =>({
    root: {

    }
});

class ProductionStart extends Component{
    constructor(props){
        super(props);
        this.grdOrderRows = React.createRef();
        this.grdBOPG = React.createRef();
        this.grdBOPS = React.createRef();
        this.state = {
            showProductionPositionSelector: false, 
            productionBOPEditors:[], 
            bopEditIndex:0, 
            data:[], 
            fields:[], 
            viewRows:undefined, 
            selectedRows:[]
        };
    }
	showBOPG = () => {
		this.setState({
			viewRows: "BOPG"
		});
	}
	showBOPS = () => {
		this.setState({
			viewRows: "BOPS"
		});
	}
	showOrderRows = () => {
		this.setState({
			viewRows: "ORDERROWS"
		});
    }
    sendInProduction = () => {
        this.setState({
            showProductionPositionSelector: true
        });
    }
    cancelSendInProduction = () => {
        this.setState({
            showProductionPositionSelector: false
        });

    }
    render() {
        var { classes, fields } = this.props;
		var { showProductionPositionSelector, productionBOPEditors, bopEditIndex, data, viewRows, selectedRows } = this.state;

		if (!viewRows)
			viewRows = "ORDERROWS";

		var gridRef = this.grdOrderRows;
		var gridName = "grdProductionOrderRows";
		var getDataURI = "/Production/GetProductionOrderRows";
		if (viewRows === "BOPG") {
			gridRef = this.grdBOPG;
			gridName = "grdProductionBOPG";
			getDataURI = "/Production/GetProductionBOPG";
		}
		if (viewRows === "BOPS") {
			gridRef = this.grdBOPS;
			gridName = "grdProductionBOPStock";
			getDataURI = "/BOP/GetBopsStock";
		}

		var grdDataRoute = `${getDataURI}?grid=${gridName}`;
		var grdDataRouteColumns = `/Base/GetColumns?grid=${gridName}`;
		var grdDataRouteAvailCols = `/Base/GetAvailableColumns?grid=${gridName}`;
		var grdDataRouteUpdateCols = `/Base/UpdateColumns?grid=${gridName}`;

		var customContent = undefined;
		var customActions = [];
		customActions.push(
			{
				group: 'actions',
				name: 'Metti in produzione',
				icon: <ArrowRight />,
				action: this.sendInProduction,
				condition: r => r.length > 0
			}
		);
		var label = "Righe ordini";
		var addLabel = "";
		if (showProductionPositionSelector) {
			// var divStyle = { width: 'calc(100% - 16px)', padding: 16 };
			// var operatorFilters = [];
			// if (data.FIDDepartment)
			// 	operatorFilters.push({ key: "FIDDepartments", value: data.FIDDepartment, type: "array" });
			// if (data.FIDPhase)
			// 	operatorFilters.push({ key: "FIDPhases", value: data.FIDPhase, type: "array" });
			// customContent = <div style={divStyle}><Grid item container spacing={2} direction="row" sx={12}>
			// 	<Grid item xs={12} sm={6} md={3}>{getXSelect(data, fields, 12, 12, "FIDPosition", "Posizione di produzione", this.handleBOPProdFieldChange, "/Production/GetProductionPositions", "", { readOnly: false, filters: [], sm: 6, valueKey: "Position", /*linkedFields: [ "FIDDepartment", "FIDPhase", "FIDOperator" ]*/ })}</Grid>
            //     <Grid item xs = { 12} sm = { 6} md = { 3} > { getXSelect(data, fields, 12, 12, "FIDDepartment", "Reparto", this.handleBOPProdFieldChange, "/Production/GetProductionDepartments", "", { readOnly: false, filters: [{ key: "FIDPosition", value: data.FIDPosition }], sm: 6, valueKey: "Department", /*linkedFields: ["FIDPhase", "FIDOperator"]*/ })}</Grid>
			// 	<Grid item xs={12} sm={6} md={3}>{getXSelect(data, fields, 12, 12, "FIDPhase", "Fase di lavorazione", this.handleBOPProdFieldChange, "/Production/GetProductionPhases", "", { readOnly: false, filters: [{ key: "FIDPosition", value: data.FIDPosition }, { key: "FIDDepartment", value: data.FIDDepartment }], sm: 6, valueKey: "Phase", /*linkedFields: [ "FIDOperator" ]*/ })}</Grid>
			// 	<Grid item xs={12} sm={6} md={3}>{getXSelect(data, fields, 12, 12, "FIDOperator", "Operatore", this.handleBOPProdFieldChange, "/Production/GetEmployees", "", { readOnly: false, filters: operatorFilters, sm: 6, valueKey: "Operator" })}</Grid>
            // </Grid></div>;
            
            customContent = <ProductionPositionSelector data={data} fields={fields} />;

			customActions = [];
			customActions.push(
				{
					group: 'actions',
					name: 'Q.ta e componenti',
					icon: <ArrowRight />,
					action: () => { this.toggleProductionBOPEditor() }
				}
			);
			customActions.push(
				{
					group: 'actions',
					name: 'Indietro',
					icon: <ArrowLeft />,
					action: this.cancelSendInProduction
				}
			);
			addLabel = "Seleziona posizione, reparto, fase e operatore";
		}

		if (productionBOPEditors.length > 0) {
			var ttLabel = "Metti in produzione";

			if (bopEditIndex < (selectedRows.length - 1))
				ttLabel = "Avanti";

			addLabel = "" + (bopEditIndex + 1) + " / " + selectedRows.length;
			customActions = [];
			customActions.push({
				group: 'actions',
				name: ttLabel,
				icon: bopEditIndex === (selectedRows.length - 1) ? <Check /> : <ArrowRight />,
				action: bopEditIndex === (selectedRows.length - 1) ? this.addProductionBOPs : this.increaseEditIndex
			});
			customActions.push({
				group: 'actions',
				name: 'Indietro',
				icon: <ArrowLeft />,
				action: bopEditIndex === 0 ? this.toggleProductionBOPEditor : this.decreaseEditIndex
			});
			customContent = productionBOPEditors[bopEditIndex];
			//var editorContent = selectedRows.map((rowData, index) => {
				//var rowData = gridRef.current.getRowData(index);
			//if (viewRows === "ORDERROWS" || viewRows === "BOPG")
			//	customContent = <ProductionComponentsSelector bop={productionBOPEditors[bopEditIndex]} mode="activation" onChange={this.onChangeBop(bopEditIndex) } />;
			//else 
			//	customContent = <ProductionComponentsSelector bopPosition={productionBOPEditors[bopEditIndex]} mode="activation" onChange={this.onChangeBop(bopEditIndex)} />;
			//})
			//customContent = <Fragment>{editorContent}</Fragment>;
		}

		var direction = "right";
		var navActions = [];
		navActions.push({ name: "Ordini", icon: <TextBox />, action: this.showOrderRows, selected: viewRows === "ORDERROWS" });
		navActions.push({ name: "Modelli", icon: <Ring />, action: this.showBOPG, selected: viewRows === "BOPG" });
		navActions.push({ name: "Matricole", icon: <Package />, action: this.showBOPS, selected: viewRows === "BOPS" })
		label = <span><XNavigation actions={navActions} direction={direction} showLabel /></span>

		return (<Grid container>	
				<XGrid
					key={viewRows}
					containerID="OrderRowsManagement"
					label={label}
					additionalLabel={addLabel}
					innerRef={gridRef}
					usePrimaryColor={false}
					useSecondaryColor={false}
					dataRoute={grdDataRoute}
					dataRouteColumns={grdDataRouteColumns}
					dataRouteAvailableColumns={grdDataRouteAvailCols}
					dataRouteUpdateColumns={grdDataRouteUpdateCols}
					customContent={customContent}
					actions={customActions}
					showImgColumn={true}
				/>
		</Grid>
		);
    }
}

export default withStyles(styles)(ProductionStart);