import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
	createStyles({        
        recvMessage: {
            width: 'fit-content',
            marginLeft: '3px',
            marginBottom: '3px',
            boxSizing: 'border-box',
            position: 'relative',
            padding: '6px 6px',
            borderRadius: '10px',
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            fontFamily: 'Roboto',
        },    
        sentMessage: {
            width: 'fit-content',
            float: 'right',
            marginRight: '3px',
            marginBottom: '3px',
            boxSizing: 'border-box',
            position: 'relative',
            padding: '6px 6px',
            borderRadius: '10px',
            fontFamily: 'Roboto',
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
        },
	})
);

export default function Message(props){
    const classes = useStyles();
    return(
    <div className={props.sent ? classes.sentMessage : classes.recvMessage}>
        {props.content}
    </div>
    )
}