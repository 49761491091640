import React, { Component } from 'react';
import { withStyles, withTheme } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';

const styles = theme => ({
    root: {
        cursor: 'text',
        textAlign: 'center',
        width: '100%',
        padding: 5,
        border: '1px solid #000',
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
        height: 33
    }
});


class CashField extends Component {
    constructor(props) {
        super(props);
    }
    onClick = () => {
        this.props.onActivate && this.props.onActivate();
    }
    render() {
        var { classes,value } = this.props;
        return <div className={classes.root} onClick={this.onClick}>{value}</div>;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(CashField);