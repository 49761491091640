import React, {useState, useEffect} from 'react';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles(theme =>
createStyles({
    input: {
        display: 'flex',
        margin: '8px 0px 8px 8px',
    },
}));



export default function MonthYearSelector(props) {
    const MONTHS = [1,2,3,4,5,6,7,8,9,10,11,12]
    const monthDesc = ["January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"]
    var YEARS = [2020]
    
    const currentYear= new Date().getFullYear();
    while(currentYear > YEARS[YEARS.length-1])
    {
        const lastYear = YEARS[YEARS.length-1];
        YEARS.push(lastYear+1);
    }

    const classes = useStyles();
    const [month, setMonth] = useState(new Date().getMonth()+1)	
    const [year, setYear] = useState(new Date().getFullYear())
    
	return (
        <div className={classes.input}>
            <FormControl className={classes.formControl}>
                <Select
                    id={`period-month-selection-${month}`}
                    value={month}
                    onChange={e => setMonth(e.target.value)}>
                    {MONTHS.map(month=> (<MenuItem key={month} id={month} value={month}>{monthDesc[month-1]}</MenuItem>))}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <Select
                    id={`period-year-selection-${year}`}
                    value={year}
                    onChange={e => setYear(e.target.value)}>
                    {YEARS.map(year=> (<MenuItem key={year} id={year} value={year}>{year}</MenuItem>))}
                </Select>
            </FormControl>
            <Button onClick={() => props.onOK(month, year)}>OK</Button>
        </div>
    )
}