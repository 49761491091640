import React, { Component, Fragment } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles, Paper, Grid, CardMedia, IconButton, TextareaAutosize } from '@material-ui/core';
import { DotsHorizontal, Close, Check } from 'mdi-material-ui';

import SwipeableViews from 'react-swipeable-views';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as localizationCreators,getTranslation } from 'store/Localization';
import { format } from 'utils';
import {List, WindowScroller} from 'react-virtualized';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import XButton from 'components/XButton';
import XField from 'components/XField';
import XSelect from 'components/XSelect';
import XMessageBox from 'components/XMessageBox';
import XOXLoader from 'components/XOXLoader';
import Typography from '@material-ui/core/Typography';

const styles = (theme) =>( {
    root: {

    },
    headerCaption: {
        width: '100%',
        textAlign: 'center',
        padding: 10,
        margin: 5,
		background: '#383b3d',
        color: '#E2B231',
    },
    field: {
        width: '100%',
        textAlign: 'center',
        '&:after': {
            display: 'block',
            content: '""',
            width: '100%',
            height: 1,
            backgroundColor: '#ccc',
        }
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        backgroundSize: 'contain',
    },
    slider: {
        //maxHeight: 220,
        //minHeight: 220,
        overflow: "hidden !important",
        position: "relative",
        width: "100%",
        cursor: "pointer",
    },
    row: {
        width: '100%',
        padding: 5,
		paddingRight: 30,
		margin: "15px 0",
        '&:after': {
            display: 'block',
            content: '""',
            width: '100%',
            height: 1,
            backgroundColor: '#ccc',
        }
    },
    headerBlock :{
        width: '100%',
        position: 'sticky',
        top: 73,
        background: '#fff',
        zIndex: 1000,
    },
	remarkField: {
		WebkitLineClamp: 2,
		textOverflow: "ellipsis",
		width: "60%",
		marginLeft: "10%",
		marginRight: "5%",
		marginTop: -5,
		color:"#383b3d",
		"& .MuiInputBase-input": {
		color:"#383b3d",
		}
	},
	cartTotals: {
		margin: "5px 10%",
		width: "80%",
		"& .MuiInputBase-input": {
			color:"#383b3d",
		}
	},
	cartBtn: {
		padding: "12px 15px", "& svg": { marginRight: "0 !important" }
    },
	cartBtn2: {
		padding: "10px 15px", marginTop: 25, position: "absolute", "& svg": { marginRight: "0 !important" }, "&:last-of-type": { right: 0 }
	},
	alignLeft: {
		left: 0
	},
	alignRight: {
		right: -5,
		textAlign: 'right'
	},
	popup: {
        position: "fixed",
        maxWidth: 350,
        width: "100%",
        left: "50%",
        top: "50%",
        zIndex: "10000",
        background: "#fff",
        boxShadow: "0px 1px 7px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
        //border: "1px solid",
        padding: "10px",
        transform: "translate(-50%,-50%)",
        
    },
    popupBack: {
        position: "fixed",
        display: "block",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        background: "#999",
        opacity: 0.8,
        zIndex: "9999",
    },
	popupRemark: { position: "fixed", width: "87.5%", height: "85%", top: "15.5%", zIndex: 10000, background: "rgba(255,255,255,.75)", textAlign: "center"}
});

class CatalogueCartItem extends Component {
    constructor(props) {
        super(props);
		this.Cart = React.createRef();
		this.MessageBox = React.createRef();
        this.state = {
            item: this.props.data,
			extras: [],
            isLoading: false,
            imageIndex: 0,
        };
    }
    //componentDidMount() {
    //    this.loadData();
    //}
    componentDidUpdate(props) {
        if(props !== this.props) {
            this.loadData();
        }
    }
    loadData = async () =>{
        var { data, DocInfo } = this.props;
		var { IdentityInfo,DestinationInfo } = this.state;
        this.setState({ item: data, isLoading: false }, this.getItemStatus);
        // if (data.ID > 0) {
        //     var result1 = await fetch('/BOP/GetCatalogueData/' + data.ID + '?FIDCausal=' +  DocInfo.FIDCausal + '&FIDIdentity=' + IdentityInfo.ID).then(res => res.json());
        //     this.setState({ item: result1 },this.getItemStatus);
        // }
        // else if (data.Code || data.Position) {
        //     var url = '/BOP/GetCatalogueData/?Code=' + data.Code;
        //     if (DocInfo.FIDCausal) {
        //         url += '&FIDCausal=' + DocInfo.FIDCausal + '&FIDIdentity=' + IdentityInfo.ID;
        //     }
        //     var result2 = await fetch(url).then(res => res.json());
        //     this.setState({ item: result2 }, this.getItemStatus);
        // }
    }
    getItemStatus = async () => {
        var { DocInfo, extras } = this.props;
		var { IdentityInfo,DestinationInfo } = this.state;
        var { item } = this.state;
		
		let extrasIds = extras.map(e => e.ID);
		let extrasJson = JSON.stringify(extrasIds);

        if (!item) return;
        var filters = "";
        if(IdentityInfo){
            filters = `&FIDIdentity=${IdentityInfo.ID}`;
        }
        await Promise.all([
			fetch(`/IR/BOPStatus/${item.ID}?Code={(item.Code||"")}FIDDocument=${DocInfo.ID}${filters}`), 
			fetch(`/Catalog/GetCartRowExtras?RowID=${item.ID}&&jsonExtras=${extrasJson}`)
		]).then(([docData, extrasData]) => {
			return Promise.all([docData.json(), extrasData.json()])
		}).then(([docData, extrasData]) => {
			this.setState(state => {
				//state.IsBooked = result.IsBooked;
				//state.IsReserved = result.IsReserved;
				//state.IsBookedOther = result.IsBookedOther;
				//state.IsReservedOther = result.IsReservedOther;
				state.documents = docData.documents;
				var catalogOrder = state.documents.filter(f => f.FIDCausal === DocInfo.FIDCausal)[0];
				if(catalogOrder){
					state.qty = catalogOrder.Qty;
				}
				
				if (docData.documents && docData.documents.length > 0) {
					state.internalRemark = docData.documents[0].InternalRemarks;
					state.publicRemark = docData.documents[0].PublicRemarks;
				}
				
				if (extrasData && extrasData.Extras && extrasData.Extras.length > 0) {
					state.extras = extrasData.Extras;
				}
				
				state.isLoading = false;
				return state;
			});
		});
    }
    
    handleNext = () => {

        var { item, imageIndex } = this.state;
        var files = item.Files ? item.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = files.length;
        if (imageIndex < imagesCount - 1) {
            this.setState({ imageIndex: imageIndex + 1 });
        }
        else {
            this.setState({ imageIndex: 0 });
        }
    }
    handlePrevious = () => {
        var { item, imageIndex } = this.state;
        var files = item.Files ? item.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = files.length;
        if (imageIndex > 0) {
            this.setState({ imageIndex: imageIndex - 1 });
        }
        else {
            this.setState({ imageIndex: imagesCount - 1 });
        }
    }
    handleChangeIndex = (index) => {
        this.setState({ imageIndex: index });
    }
    editRemark = (rowID, isInternal, remark) => {
		this.props.editRemark && this.props.editRemark(rowID, isInternal, remark)
	}
	increaseRowQty = async() => {
		var { item } = this.state;
		var { onRowUpdated } = this.props;
		
		item.Qty++;
		
		//this.setState({
		//	isLoadingActions: true
		//});
		
		try {
			var rowUpdated = await fetch(`/Catalog/UpdateRowQty?RowID=${item.ID}`, {
				body: JSON.stringify(item.Qty),
				method: 'POST'
			}).then(res => res.json());
			
			item.TotalAmount = rowUpdated.rowAmount;
			item.TotalDiscountedAmount = rowUpdated.rowDiscAmount;
			
			onRowUpdated && onRowUpdated(item);
			
			this.setState({
				//isLoadingActions: false,
				item
			})
		}
		catch(err) {
			console.error(err);
			//this.setState({
			//	isLoadingActions: false
			//});
		}
	}
	decreaseRowQty = async() => {
		var { item } = this.state;
		var { DocInfo, onRowUpdated, onRowDeleted, onRemoveItem } = this.props;
		
		item.Qty--;
		
		//this.setState({
		//	isLoadingActions: true
		//});
		
		try {
			if (item.Qty === 0) {
				if (onRemoveItem) {
					onRemoveItem(item, DocInfo.FIDCausal, undefined, false, item.ID);
					return;
				}
			}
			var rowUpdated = await fetch(`/Catalog/UpdateRowQty?RowID=${item.ID}`, {
				body: JSON.stringify(item.Qty),
				method: 'POST'
			}).then(res => res.json());
			
			if (rowUpdated.redirect) {
				onRowDeleted && onRowDeleted();
				//this.context.router.history.push('/catalogue');
				return;
			}
			
			item.TotalAmount = rowUpdated.rowAmount;
			item.TotalDiscountedAmount = rowUpdated.rowDiscAmount;
			
			onRowUpdated && onRowUpdated(item);
			
			this.setState({
				//isLoadingActions: false,
				item
			})
		}
		catch(err) {
			console.error(err);
			//this.setState({
			//	//isLoadingActions: false
			//});
		}
	}
    updateDocument = () => {
        var { onBuyItem, onRemoveItem, DocInfo } = this.props;
        var { item, totalPrice } = this.state;

        var price = item.Price;
        if(item.PriceB2B){
            price = item.PriceB2B;
        }
        item.TotalPrice = price;
        if (item.Qty === 0) {
            if (onRemoveItem) {
                onRemoveItem(item, DocInfo.FIDCausal, undefined, true, item.ID);
            }
        }
        else {
            //data.TotalPrice = totalPrice;
            if (onBuyItem) {
                onBuyItem(item, item.Qty, true, false);
            }
        }
    }
	discountChange = (id) => (event, value) => {
		var { item } = this.state;
        this.setState((state, props) => {
            state.item.Discount = value;
            state.item.TotalDiscountedAmount = (state.item.TotalAmount * (100 - state.item.Discount) / 100).round(2);
            return state;
        });
    }
    priceChange = (id) => (event, value) => {
		var { item } = this.state;
        this.setState((state, props) => {
            state.item.TotalDiscountedAmount = value;
            state.item.Discount = (100 - (state.item.TotalDiscountedAmount * 100 / state.item.TotalAmount)).round(4);
			
            return state;
        });
    }
	renderEditPrice = (discount, totalPrice) => {
        var { classes } = this.props;
		var { item } = this.state;
		
        return (
            <Fragment>
                {this.state.editPrices && <span className={classes.popupBack}></span>}
                {this.state.editPrices &&
                    <div className={classes.popup}>
                        <Grid container item xs={12}>
                            <Grid item xs={12}><XField labelFontSize={16} label="Sc. %" id="discount" value={discount} onChange={this.discountChange} /></Grid>
                            <Grid item xs={12}><XField labelFontSize={16} label="Totale" id="totalPrice" type="number" value={item.TotalDiscountedAmount} onChange={this.priceChange} /></Grid>
                            <Fragment>
                                <IconButton onClick={() => this.setState({ editPrices: false }, () => {
										fetch('/Catalog/UpdateRowAmounts?RowID='+item.ID, {
											body: JSON.stringify({ Discount: item.Discount, DiscountedAmount: item.TotalDiscountedAmount }),
											method: 'POST'
										}).then(res => res.json()).then(res => {
											var { onRowUpdated } = this.props;										
											onRowUpdated && onRowUpdated(this.state.item);
										});
									})}>
                                    <Check />
                                </IconButton>
                                <IconButton onClick={() => {
										var { item } = this.state;
										this.setState(async(state) => { 
											state.editPrices = false; 
											try {
												await fetch('/IR/UpdateRowAmounts?RowID='+item.ID, {
													body: JSON.stringify({ Discount: 0, DiscountedAmount: item.TotalAmount }),
													method: 'POST'
												});
												
												state.item.Discount = 0;
												state.item.TotalDiscountedAmount = item.TotalAmount;
											} catch {}
											return state;
										}, () => {
											var { onRowUpdated } = this.props;
											
											onRowUpdated && onRowUpdated(this.state.item);
										})
									}
								}>
                                    <Close />
                                </IconButton>
                            </Fragment>
                        </Grid>
                    </div>}
            </Fragment>
        );
    }
    renderQtyButtons = () => {
		var { classes, style, userProfile, gcParams } = this.props;
        var { item, isLoading, isLoadingActions } = this.state;
		
		var onPriceClick = undefined;
		
		if (!userProfile || userProfile === gcParams.UserTypologyId) {
			onPriceClick = () => {
				this.setState({ editPrices: true });
			}
		}
		
		var priceContent = <p>{getTranslation(1,"CAT_ROW_TOTAL",this.props.translations, 'Totale ivato')+" "}<span style={ !userProfile || userProfile === gcParams.UserTypologyId ? {cursor:"pointer"} : { cursor: "default" }} onClick={onPriceClick}>{`${format(item.TotalAmount,item.PriceCurrency)}`}</span></p>;
		
		if (item.TotalDiscountedAmount < item.TotalAmount) {
			priceContent = <p>{getTranslation(1,"CAT_ROW_TOTAL",this.props.translations, 'Totale ivato')+" "}<span style={{cursor:"pointer"}} onClick={onPriceClick}><span style={{ textDecoration: "line-through" }}>{`${format(item.TotalAmount,item.PriceCurrency)}`}</span> {`${format(item.TotalDiscountedAmount,item.PriceCurrency)}`}</span></p>
		}
		
        return <div style={{ width: "100%", marginTop: -15, "& svg": { marginRight: "0 !important" } }}>
			{(!userProfile || userProfile === gcParams.UserTypologyId) && this.renderEditPrice(item.Discount, item.TotalDiscountedAmount)}
			<Grid container xs={12}>
				<Grid item xs={12}>{isLoadingActions && <XOXLoader transparent />}</Grid>
				<Grid container item xs={12} style={{marginTop: -5}}>
					<Grid item lg={3} xs={4} style={{position: "relative"}}>{this.props.IsCatalog &&<XButton className={classes.cartBtn2 + " " + classes.alignLeft} color="gray" onClick={this.decreaseRowQty}>-</XButton>}</Grid>
					<Grid item lg={6} xs={4}><XField labelFontSize={16} type="number" readOnly={true} value={this.state.item.Qty} 
					caption="nr" field={{ Required: 1 }} label={getTranslation(1,"CAT_QTY",this.props.translations, 'Quantit�')} id="qty" type="number" value={this.state.item.Qty} /></Grid>
					<Grid item lg={3} xs={4} style={{position: "relative"}}>{this.props.IsCatalog && <XButton className={classes.cartBtn2 + " " + classes.alignRight} color="gray" onClick={this.increaseRowQty}>+</XButton>}</Grid>
				</Grid>
				<Grid item xs={12} style={{marginTop:10}} classes={{root:classes.alignRight}}>{isLoading ? <Skeleton variant="text" /> : priceContent}</Grid>
				<Grid item xs={12} style={{ margin: "10px 0", textAlign: "center" }}>
					{!this.props.IsCatalog &&<XButton className={classes.cartBtn} color="gray" onClick={this.updateDocument}><Edit /></XButton>}
					<XButton className={classes.cartBtn} color="gray" onClick={() => {
						this.MessageBox.current.showMessageYesNo("ELIMINAZIONE", "Sei sicuro di voler rimuovere il prodotto dal carrello?", () => {
							this.setState(state => {
								state.item.Qty = 0;
								return state;
							}, this.updateDocument)
						});
					}}><Delete /></XButton>
				</Grid>
			</Grid>
        </div>;
    }
    render() {
        var { classes, style } = this.props;
        var { item, imageIndex, qty, isLoading } = this.state;

        var files = item.Files ? item.Files.split(',').map(m => parseInt(m, 10)) : [];
        if (files.length > 1) {
            files = [files[0]];
        }
        var mediaFiles = files.length > 0 ? files.map((file, index) => (<CardMedia key={index} className={classes.media} image={"/Base/Image/" + file + "?size=S"} />)) : (<CardMedia className={classes.media} image="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E" />);
        if (item.File64) {
            mediaFiles = [(<CardMedia className={classes.media} image={item.File64} />)];
        }
        var price = item.Price;
        var currency = item.PriceCurrency;
        if(item.PriceB2B){
            price = item.PriceB2B;
            if (item.PriceCurrencyB2B)
                currency = item.PriceCurrencyB2B;
        }
		let extrasCount = 0;
        return (
            <div style={style} className={classes.row}>
                <Grid container xs={12}>
                    <Grid item xs={12} md={2}>
                        {isLoading ? (
                            <Skeleton variant="rect" width="100%" height="100%" />
                        ) : (
                            <div className={classes.slider}>
                                <SwipeableViews xis="x" resistance={false} index={imageIndex} onChangeIndex={this.handleChangeIndex}>
                                    {mediaFiles}
                                </SwipeableViews>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} style={{padding:20}}>
                        {isLoading ? <Skeleton variant="text" />  : <p>{`${item.Code} - ${item.Description}`}</p>}
                        {isLoading ? <Skeleton variant="text" />  : <p>{`${getTranslation(4, "IR_OFFICIALPRICELIST", this.props.translations, "Listino ufficiale")} ${item.Price} ${item.PriceCurrency}`}</p>}
                        {isLoading ? <Skeleton variant="text" />  : item.PriceB2B && <p>{`${getTranslation(4, "IR_PRICELIST", this.props.translations, "Listino STD")} ${item.PriceB2B} ${item.CurrencyB2B}`}</p>}
						{isLoading ? <Skeleton variant="text" /> : <p>{this.state.extras.map(extra => {
							var style = { margin: "0 10px" };
							
							if (extrasCount === 0)
								style.marginLeft = 0;
							
							extrasCount++;
							return <span style={style}>{extra.CustomField}: {extra.Value}</span>
						})}</p>}
                    </Grid>
					<Grid container item xs={12} md={3} lg={4}>
						<Grid item xs={12}>{isLoading ? <Skeleton variant="text" /> : <Fragment><XField labelAlignTop labelFontSize={16} label={getTranslation(3,"BOP_INTERNALREMARK",this.props.translations, 'Nota interna')} value={item.IntRemarks} readOnly={true} className={classes.remarkField} /><IconButton size="small" onClick={() => {this.editRemark(item.ID, true, item.IntRemarks)}}><DotsHorizontal /></IconButton></Fragment>}</Grid>
						<Grid item xs={12}>{isLoading ? <Skeleton variant="text" /> : <Fragment><XField labelAlignTop labelFontSize={16} label={getTranslation(3,"BOP_PUBLICREMARK",this.props.translations, 'Nota pubblica')} value={item.PublicRemarks} readOnly={true} className={classes.remarkField} /><IconButton size="small" onClick={() => { this.editRemark(item.ID, false, item.PublicRemarks) }}><DotsHorizontal /></IconButton></Fragment>}</Grid>
					</Grid>
                    <Grid item xs={12} md={3} lg={3}>{isLoading ? <Skeleton variant="text" />  : this.renderQtyButtons()}</Grid>
                </Grid>
				<XMessageBox innerRef={this.MessageBox} />
            </div>
        );
    }
}
class CatalogueCart extends Component {
	constructor(props) {
        super(props);
		this.state = {
			isLoading: true,
			IdentityInfo: props.IdentityInfo||{},
			DestinationInfo: props.DestinationInfo||{},
			DocPaymethod: undefined,
			TotalDocument: props.TotalDocument,
			TotalDocumentDisc: props.TotalDocumentDisc,
			TotalDocumentTaxable: props.TotalDocumentTaxable,
			TotalDocumentDiscTaxable: props.TotalDocumentDiscTaxable,
			DocumentRemarks: {
				Public: "",
				Internal: ""
			},
			variationExtras: [],
			rows: [],
			isempty: false,
			editRemark: {
				rowID: undefined,
				isInternal: false,
				remark: ""
			},
			editDocRemark: {
				Public: false,
				Internal: false
			}
		}
	}
	componentDidMount = async() => {
		await this.loadVariationExtras();
		this.loadRows();
	}
	loadVariationExtras = async() => {
		let extras = await fetch(`/BOP/GetVariationExtras?FIDTypology=${this.props.params.FinishedGoodTypologyId}`).then(res => res.json());
		
		let variationExtras = extras.map(e => {
			return {
				ID: e.ID,
				Desc: e.Description
			}
		});
		
		this.setState({ variationExtras });
	}
	loadRows = () => {
		var { DocInfo, CatalogFIDPricelist } = this.props;
		
		var { IdentityInfo,DestinationInfo, variationExtras } = this.state;
		
		var url = "/Catalog/GetCartRows";
		
		if (DocInfo.ID) {
			url += "?FIDDocument="+DocInfo.ID;
		} else {
			if (IdentityInfo && IdentityInfo.ID) {
			url += "?FIDIdentity="+IdentityInfo.ID;
			
			if (DestinationInfo && DestinationInfo.ID) {
				url += "&FIDDestination=" + DestinationInfo.ID;
			}
				
				if (DocInfo.FIDCausal) {
					url +="&FIDCausal=" + DocInfo.FIDCausal;
		}
			} else if (DocInfo.FIDCausal) {
				url +="?FIDCausal=" + DocInfo.FIDCausal;	
			}
		}
		
		if (variationExtras && variationExtras.length > 0) {
			url += "&jsonExtras=" + JSON.stringify(variationExtras);
		}
		
		fetch(`/Catalog/GetDocRemarks?FIDDocument=${DocInfo.ID}&FIDCausal=${DocInfo.FIDCausal}&FIDIdentity=${IdentityInfo.ID}`).then(res => res.json())
		.then(data => {
			var { DocumentRemarks } = this.state;
			
			DocumentRemarks.Public = data.PublicRemarks;
			DocumentRemarks.Internal = data.InternalRemarks;
			
			this.setState({
				DocumentRemarks
			});
		})
		
		fetch(`/Catalog/GetDocPaymethod?FIDDocument=${DocInfo.ID}&FIDCausal=${DocInfo.FIDCausal}&FIDIdentity=${IdentityInfo.ID}`).then(res => res.json())
		.then(data => {
			this.setState({
				DocPaymethod: data.PayMethodID
			})
		})
		fetch(url).then(res => res.json()).then(res => {
			this.setState({
				isLoading: false,
				rows: res.rows,
				isEmpty: res.rows.length === 0
			}, () => {
				if (res.rows.length > 0) {
					fetch(`/BOP/GetTOTDoc?FIDDocument=${DocInfo.ID}&FIDCausal=${DocInfo.FIDCausal}&FIDIdentity=${IdentityInfo.ID}`+ (CatalogFIDPricelist ? `&FIDPricelist=${CatalogFIDPricelist}` : "")).then(res => res.json()).then(data =>{
						this.setState({ TotalDocument: data.totDocument, TotalDocumentDisc: data.totDocumentDisc, TotalDocumentTaxable: data.totDocumentTaxable, TotalDocumentDiscTaxable: data.totDocumentDiscTaxable });
						});
				}
			});
		})
	}
    getGeographicArea = (IR) => {
        if(!IR){
            return "";
        }
        var result = "";
        if(IR.Zip){
            result = IR.Zip
        };
        if(IR.City){
            if(result){
                result += " - ";
            }
            result += IR.City;
        }
        if(IR.Province) {
            if(result) {
                result += " ";
            }
            result += `(${IR.Province})`;
        }
        if(IR.Country){
            if(result){
                result += " ";
            }
            result += IR.Country;
        }
        return result;
    }
	clearDocRemarksEdit = () => {
		this.setState({
			editDocRemark: {
				Public: false,
				Internal: false
			}
		});
	}
	saveDocRemarks = async() => {
		var { editDocRemark, DocumentRemarks } = this.state;
		var { DocInfo } = this.props;
		var { IdentityInfo,DestinationInfo } = this.state;
		
		var remark = "";
		
		if (editDocRemark.Public) {
			remark = DocumentRemarks.Public;
		} else {
			remark = DocumentRemarks.Internal;
		}
		
		await fetch(`/Catalog/UpdateDocRemarks?FIDDocument=${DocInfo.ID}&IsInternal=${editDocRemark.Internal}`, {
            body: JSON.stringify(remark),
            method: 'POST'
        }).then(res => res.json());
		
		this.clearDocRemarksEdit();
	}
	openDocRemarkEditor = (isInternal) => {
		var { editDocRemark } = this.state;
		
		if (isInternal) {
			editDocRemark.Public = false;
			editDocRemark.Internal = true;
		} else {
			editDocRemark.Public = true;
			editDocRemark.Internal = false;
		}
		
		this.setState({ editDocRemark });
	}
	onDocRemarkChange = (event) => {
		var { editDocRemark, DocumentRemarks } = this.state;
		
		var remark = event.target.value;
		
		if (editDocRemark.Public) {
			DocumentRemarks.Public = remark;
		} else {
			DocumentRemarks.Internal = remark;
		}
		
		this.setState({ DocumentRemarks });
	}
	clearRowRemarksEdit = () => { 
		this.setState({
			editRemark: {
				rowID: undefined,
				isInternal: false,
				remark: ""
			}
		}); 
	}
	saveRowRemarks = async() => {
		var { editRemark, rows } = this.state;
		
		await fetch(`/Catalog/UpdateRowRemarks?RowID=${editRemark.rowID}&IsInternal=${editRemark.isInternal}`, {
            body: JSON.stringify(editRemark.remark),
            method: 'POST'
        }).then(res => res.json());
		
		var currRow = undefined;
		var currIndex = -1;
		rows.forEach((row, index) => {
			if (row.ID === editRemark.rowID)
			{
				if (editRemark.isInternal) {
					row.IntRemarks = editRemark.remark;
				} else {
					row.PublicRemarks = editRemark.remark;
				}
				currRow = row;
				currIndex = index;
			}
		});
		
		rows[currIndex] = currRow;
		
		this.setState(
			{ 
				rows,
				editRemark: {
					rowID: undefined,
					isInternal: false,
					remark: ""
				}
			}
		);
	}
	onRowRemarkChange = (event) => {
		var { editRemark } = this.state;
		
		editRemark.remark = event.target.value;
		
		this.setState({ editRemark });
	}
	onRowUpdate = async (row) => {
		var { DocInfo, CatalogFIDPricelist } = this.props;
		var { IdentityInfo,DestinationInfo } = this.state;
		
		fetch(`/BOP/GetTOTDoc?FIDDocument=${DocInfo.ID}&FIDCausal=${DocInfo.FIDCausal}&FIDIdentity=${IdentityInfo.ID}`+ (this.props.CatalogFIDPricelist ? `&FIDPricelist=${CatalogFIDPricelist}` : "")).then(res => res.json()).then(data =>{
            this.setState({ TotalDocument: data.totDocument, TotalDocumentDisc: data.totDocumentDisc, TotalDocumentTaxable: data.totDocumentTaxable, TotalDocumentDiscTaxable: data.totDocumentDiscTaxable });
        });
	}
	onRowDeleted = () => {
		var { onRedirectEmpty } = this.props;
		
		onRedirectEmpty && onRedirectEmpty();
	}
    rowRender = ({key, index, isScrolling, isVisible, style}) => {
        var { IdentityInfo,DocInfo, classes,onBuyItem, onRemoveItem,CatalogFIDCausal, IsCatalog } = this.props;
		var { rows, variationExtras } = this.state;
        DocInfo.FIDCausal = CatalogFIDCausal;
        return <CatalogueCartItem key={key} style={style} data={rows[index]} extras={variationExtras} IsCatalog={IsCatalog} userProfile={this.props.FIDProfile} gcParams={this.props.params} DocInfo={DocInfo} IdentityInfo={IdentityInfo} classes={classes} onBuyItem={onBuyItem} onRemoveItem={onRemoveItem} translations={this.props.translations} editRemark={(rowID, isInternal, remark) => { this.setState({
			editRemark: {
				rowID,
				isInternal,
				remark
			}
		}) }} onRowUpdated={this.onRowUpdate} onRowDeleted={this.onRowDeleted} />;
    }
	renderRemarkEditor = (rowID, isInternal, remark) => {
		var onCloseEditor = undefined;
		var onSaveEditor = undefined;
		var onChangeRemark = undefined;
		if (rowID) {
			onCloseEditor = this.clearRowRemarksEdit;
			onSaveEditor = this.saveRowRemarks;
			onChangeRemark = this.onRowRemarkChange;
		} else {
			onCloseEditor = this.clearDocRemarksEdit;
			onSaveEditor = this.saveDocRemarks;
			onChangeRemark = this.onDocRemarkChange;
							}
		
		return <Grid container xs={12} spacing={2} style={{margin:0, marginTop:"7.5%"}}>
			<Grid item md={3} xs={0} />
			<Grid container item md={6} xs={12} style={{background: "#fff", boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.5)"}}>
				<Grid container item xs={12} style={{background: "#383b3d", margin: "-8px -8px 4px -8px", width: "calc(100% + 16px)", maxWidth: "calc(100% + 16px)", flexBasis: "unset"}}>
					<Grid item xs={10}><p style={{textAlign:"left", color: "#E2B231", paddingLeft: 30, marginTop:10}}>{isInternal ? getTranslation(3,"BOP_INTERNALREMARK",this.props.translations, 'Nota interna') : getTranslation(3,"BOP_PUBLICREMARK",this.props.translations, 'Nota pubblica')}</p></Grid>
					<Grid container direction="row" alignItems="center" item xs={2} style={{position: "relative"}}>
						<IconButton color="primary" size="small" style={{ position: "absolute", right:30 }} onClick={onCloseEditor}>
							<Close />
						</IconButton>
					</Grid>
				</Grid>
				<Grid item xs={12}><TextareaAutosize style={{ minHeight: 300, maxHeight: 300, minWidth: "100%", maxWidth: "100%", resize: "none", padding: 10 }} value={remark} rowsMin={10} onChange={onChangeRemark} /></Grid>
				<Grid item md={9} xs={6} />
				<Grid item md={3} xs={6}><XButton color="gray" onClick={onSaveEditor}>{getTranslation(5,"GC_SAVE", this.props.translations, "Salva")}</XButton></Grid>
			</Grid>
			<Grid item md={3} xs={0} />
		</Grid>
	}
	updateDocPaymethod = (id) => (event, data) => {
		var { DocInfo } = this.props;
		var { DocPaymethod, IdentityInfo,DestinationInfo } = this.state;
		
		var oldPM = DocPaymethod;
		
		DocPaymethod = data && data.length > 0 ? data[0].ID : undefined;
		
		this.setState({ DocPaymethod });
		
		try {
			fetch(`/Catalog/UpdateDocPaymethod?FIDDocument=${DocInfo.ID}&FIDCausal=${DocInfo.FIDCausal}&FIDIdentity=${IdentityInfo.ID}`, {
				body: JSON.stringify(DocPaymethod),
				method: 'POST'
			});
		}
		catch {
			this.setState({ DocPaymethod: oldPM });
		}
	}
	
    render() {
        var { classes,DocInfo, contentWidth, Currency } = this.props;
		var { DocumentRemarks, editRemark, editDocRemark } = this.state;
		var { IdentityInfo,DestinationInfo } = this.state;
        DestinationInfo = DestinationInfo || IdentityInfo;
		
		var rows = this.state.rows;
		
		var totCartContent = <p style={{marginBottom:0}}>{`${getTranslation(1, "CAT_CART", this.props.translations, "Carrello")} ${format(this.state.TotalDocument, Currency)}`}</p>;
		
		if (this.state.TotalDocumentDisc < this.state.TotalDocument) {
			totCartContent = <p style={{marginBottom:0}}>{`${getTranslation(1, "CAT_CART", this.props.translations, "Carrello")}`} <span style={{ textDecoration: "line-through" }}>{format(this.state.TotalDocument, Currency)}</span> {format(this.state.TotalDocumentDisc, Currency)}</p>;
		}
		
		var totTaxable = this.state.TotalDocumentDiscTaxable||0;
		var totAmount = this.state.TotalDocumentDisc||0;
		var taxes = Math.round(100*(totAmount - totTaxable))/100;
		
		var loaderHeight = window.innerHeight - 255;
		
		var totQty = rows.length > 0 ? rows.map(r => r.Qty).reduce((a,b) => { return a+b }) : 0;
		
        return (
            <Paper className={classes.root} id="cart" elevation={0}>
				{this.state.isLoading && <Grid container item md={9} xs={12}><XOXLoader height={loaderHeight} style={{ position: "absolute", maxHeight: "calc(100vh - 255px) !important", width: (window.innerWidth > 960 ? "75%" : "100%"), zIndex: 1000 }} transparent /></Grid>}
				{editRemark.rowID && <div className={classes.popupRemark}>
					{this.renderRemarkEditor(editRemark.rowID, editRemark.isInternal, editRemark.remark)}
				</div>}
				{editDocRemark.Public && <div className={classes.popupRemark}>
					{this.renderRemarkEditor(undefined, false, DocumentRemarks.Public)}
				</div>}
				{editDocRemark.Internal && <div className={classes.popupRemark}>
					{this.renderRemarkEditor(undefined, true, DocumentRemarks.Internal)}
				</div>}
				<Grid container>
                <Grid container item md={9} xs={12} spacing={2} style={{display: "block"}}>
                    <Grid container xs={12} spacing={2} className={classes.headerBlock}>
                        {/*<Grid item xs={12} md={2}></Grid>*/}
                        <Grid item  xs={6} md={6}>
                            <div className={classes.headerCaption}>{getTranslation(2, "DOC_CUSTOMER", this.props.translations, "Cliente")+":"}</div>
                            <div className={classes.field}>{(IdentityInfo && IdentityInfo.Denomination) || '\u00A0'}</div>
                            <div className={classes.field}>{(IdentityInfo && IdentityInfo.Address) || '\u00A0'}</div>
                            <div className={classes.field}>{this.getGeographicArea(IdentityInfo) || '\u00A0'}</div>
                            <div className={classes.field}>{(IdentityInfo && IdentityInfo.Vatnumber) || '\u00A0'}</div>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <div className={classes.headerCaption}>{getTranslation(2, "DOC_DESTINATIONADDRESS", this.props.translations, "Destinazione")+":"}</div>
                            <div className={classes.field}>{(DestinationInfo && DestinationInfo.Denomination) || '\u00A0'}</div>
                            <div className={classes.field}>{(DestinationInfo && DestinationInfo.Address) || '\u00A0'}</div>
                            <div className={classes.field}>{this.getGeographicArea(DestinationInfo) || '\u00A0'}</div>
                            <div className={classes.field}>{(DestinationInfo && DestinationInfo.Vatnumber) || '\u00A0'}</div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <div className={classes.headerCaption}>{totCartContent}</div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {rows.length > 0 && rows.map((item,index) => this.rowRender({key: item.ID,index, style: {height: 170}}))}
                    </Grid>
                </Grid>
				<Grid container item md={3} xs={12} spacing xs={2} className={classes.headerBlock} style={{boxShadow:"0px 0px 10px 0px rgba(0,0,0,0.5)",width:"22%",marginLeft:"0.85%",paddingBottom:5,display: "block",
    height: "calc(100vh - 166px)", position: "fixed", overflowY: "auto", top: 145, right: 0}}>
					<Grid item xs={12}><div className={classes.headerCaption} style={{marginTop:1, marginLeft:0}}>{getTranslation(2, "DOC_REMARKS", this.props.translations, "Note documento")}</div></Grid>
					<Grid item xs={12} style={{marginTop:20}}><Fragment><XField labelAlignTop labelFontSize={16} multiline label={getTranslation(3,"BOP_INTERNALREMARK",this.props.translations, 'Nota interna')} value={DocumentRemarks.Internal} readOnly={true} className={classes.remarkField} /><IconButton size="small" onClick={() => { this.openDocRemarkEditor(true) }}><DotsHorizontal /></IconButton></Fragment></Grid>
					<Grid item xs={12} style={{marginTop:20}}><Fragment><XField labelAlignTop labelFontSize={16} multiline label={getTranslation(3,"BOP_PUBLICREMARK",this.props.translations, 'Nota pubblica')} value={DocumentRemarks.Public} readOnly={true} className={classes.remarkField} /><IconButton size="small" onClick={() => { this.openDocRemarkEditor(false) }}><DotsHorizontal /></IconButton></Fragment></Grid>
					<Grid container item xs={12} style={{ marginTop: 25 }}>
						<Grid item xs={12}><div className={classes.headerCaption} style={{marginLeft:0}}>{getTranslation(2, "DOC_PAYMETHOD", this.props.translations, "Modalita' di pagamento")}</div></Grid>
						<Grid item xs={1}/><Grid item xs={10}><XSelect clearable id="paymethod_id" urlData="/DOC/paymentmethod" idKey="ID" valueKey="Denomination" value={this.state.DocPaymethod} onChange={this.updateDocPaymethod}/></Grid><Grid item xs={1}/>
					</Grid>
					<Grid container item xs={12} style={{ marginTop: 25 }}>
						<Grid item xs={12}><div className={classes.headerCaption} style={{marginLeft:0}}>{getTranslation(1, "CAT_DOCTOTAL", this.props.translations, "Totale documento")}</div></Grid>
						<Grid item xs={12}><XField labelFontSize={16} decimalScale={0} className={classes.cartTotals} label={getTranslation(4,"IR_NPIECES",this.props.translations, 'Numero pezzi')} caption="nr" value={totQty} readOnly={true} /></Grid>
						<Grid item xs={12}><XField labelFontSize={16} decimalScale={2} className={classes.cartTotals} label={getTranslation(2,"DOC_TAXABLE",this.props.translations, 'Imponibile')} caption={Currency} value={totTaxable} readOnly={true} /></Grid>
						<Grid item xs={12}><XField labelFontSize={16} decimalScale={2} className={classes.cartTotals} label={getTranslation(2,"DOC_TAXES",this.props.translations, 'Imposte')} caption={Currency} value={taxes} readOnly={true} /></Grid>
						<Grid item xs={12}><XField labelFontSize={16} decimalScale={2} className={classes.cartTotals} label={getTranslation(2,"DOC_TOTAL",this.props.translations, 'Totale')} caption={Currency} value={totAmount} readOnly={true} /></Grid>
					</Grid>
				</Grid>
				</Grid>
            </Paper>
        );
    }
}



const enhance = compose(
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.localization,
        dispatch => bindActionCreators(localizationCreators, dispatch)
    ),
    withStyles(styles)
);


export default enhance(CatalogueCart);