import React, { Component } from 'react';
import { withStyles, withTheme, Grid } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';


import CashRow from './CashRow';
import CashCell from './CashCell';
import CashNumberEditor from './CashNumberEditor';

const styles = theme => ({
    root: {

    },
    item: {
        display: 'inline-block',
        verticalAlign: 'top',
        padding: '0 15px',
        '&::before': {
            display: 'block',
            content: 'attr(data-label)',
        }
    }
});


class CashTotals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totRows: 0,
            total: 0,
            discountedTotal: 0,
            totalDiscount: 0,
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        var { doc } = this.props;
        var params = `${doc.ID}?FIDCausal=${doc.FIDCausal}`;
        var totRows = await fetch(`/DOC/GetCashRowsCount/${params}`).then(res => res.json());
        var total = await fetch(`/DOC/GetCashTotal/${params}`).then(res => res.json());
        var discountedTotal = await fetch(`/DOC/GetCashDiscountedTotal/${params}`).then(res => res.json());
        var totalDiscount = await fetch(`/DOC/GetCashDiscount/${params}`).then(res => res.json());

        var taxableAmount = await fetch(`/DOC/GetCashTaxableAmount/${params}`).then(res => res.json());
        var taxes = await fetch(`/DOC/GetCashTaxes/${params}`).then(res => res.json());

        var extraDiscount = await fetch(`/DOC/GetCashExtraDiscount/${params}`).then(res => res.json());
        var totalFinalDiscounted = await fetch(`/DOC/GetCashTotalFinalDiscounted/${params}`).then(res => res.json());

        var totalDeposit = await fetch(`/DOC/GetCashTotalDeposit/${params}`).then(res => res.json());

        this.setState({ totRows,total,discountedTotal,totalDiscount,extraDiscount,totalFinalDiscounted,taxableAmount,taxes,totalDeposit });
    }
    onEditorShow = (key) => () => {
        this.setState({ showEditor: true, editKey: key});
    }
    onEditorSubmit = (fields) => {
        //update extra discount
        
        var keys =["extraDiscPerc","totalFinalDiscounted"];
        this.setState(state => {
            for(var k in keys){
                var key = keys[k];
                var el = fields.filter(item => item.id === key)[0];
                if(el){
                    state[key] = el.value;
                    if(key === "extraDiscPerc") {
                        state.extraDiscount = (state.discountedTotal * el.value / 100).toFixed(2);
                    }
                }
            }
            state.showEditor = false;
            state.editKey = undefined;
            return state;
        },() => {
            var { doc } = this.props;
            fetch(`/DOC/UpdateExtraDiscount/${doc.ID}?extraDiscount=${this.state.extraDiscount}`);
        });
        

        this.setState({ showEditor: false, editKey: undefined});
    }
    onEditorRecalculate = (fields,changedField) => {
        var discountedTotal = "discountedTotal";
        var extraDiscPerc = "extraDiscPerc";
        var totalFinalDiscounted = "totalFinalDiscounted";

        var eldiscountedTotal = fields.filter(item => item.id === discountedTotal)[0];
        var elextraDiscPerc = fields.filter(item => item.id === extraDiscPerc)[0];
        var eltotalFinalDiscounted = fields.filter(item => item.id === totalFinalDiscounted)[0];
        

        if(changedField === extraDiscPerc){
            eltotalFinalDiscounted.value = (eldiscountedTotal.value * (100 - elextraDiscPerc.value) / 100).toFixed(2);
        }
        else if(changedField === totalFinalDiscounted){
            elextraDiscPerc.value = ((1 - (eltotalFinalDiscounted.value / eldiscountedTotal.value)) * 100).toFixed(4);
        }

        fields = [eldiscountedTotal,elextraDiscPerc,eltotalFinalDiscounted];
        return fields;
    }
    render() {
        var { classes, onlyTotalPieces, confirmed } = this.props;
        var { totRows, total, discountedTotal,totalDiscount, extraDiscount,taxableAmount,taxes, totalFinalDiscounted, showEditor, editKey,totalDeposit } = this.state;
        var extraDiscPerc = ((extraDiscount||0) / (discountedTotal||1) * 100).toFixed(4);
        if(showEditor){
            
            var fields = [
                {
                    label: "Totale",
                    id: "discountedTotal",
                    value: discountedTotal || 0,
                    readOnly: true,
                },
                {
                    label: "Extra sc. %",
                    id: "extraDiscPerc",
                    value: extraDiscPerc || 0
                },
                {
                    label: "Totale scontato",
                    id: "totalFinalDiscounted",
                    value: totalFinalDiscounted || 0
                }
            ];
            return <div className={classes.editor}>
                    <CashNumberEditor fields={fields} id={editKey} onSubmit={this.onEditorSubmit} onRecalculate={this.onEditorRecalculate}/>
                </div>;
        }
        
        return <div>
            <CashRow>
                <CashCell width="100%" label={"Totale pezzi"}>{totRows}</CashCell>
            </CashRow>
            {!onlyTotalPieces && <CashRow>
                <CashCell width="33%" label={"Listino"}>{total}</CashCell>
                <CashCell width="33%" label={"Sconto"}>{totalDiscount}</CashCell>
                <CashCell width="33%" label={"Totale"}>{discountedTotal}</CashCell>
            </CashRow>}
            {!onlyTotalPieces && <CashRow>
                <CashCell width="33%" label={"Imponibile"}>{taxableAmount}</CashCell>
                <CashCell width="33%" label={"Imposta"}>{taxes}</CashCell>
                <CashCell width="33%" label={"Totale"}>{discountedTotal}</CashCell>
            </CashRow>}
            {!onlyTotalPieces && <CashRow>
                <CashCell width="50%" label={"Extra sc. %"} onClick={!confirmed && this.onEditorShow("extraDiscPerc")}>{extraDiscPerc}</CashCell>
                <CashCell width="50%" label={"Totale scontato"} onClick={!confirmed && this.onEditorShow("totalFinalDiscounted")}>{totalFinalDiscounted}</CashCell>
            </CashRow>}
            {!!totalDeposit && <CashRow>
                <CashCell width="100%" label={"Acconto"}>{totalDeposit * -1}</CashCell>
            </CashRow>}
        </div>;
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(CashTotals);