import React, { Component, Fragment } from 'react';
import { withStyles, withTheme, CardMedia, Button, Grid } from '@material-ui/core';

import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from 'store/Auth';
import { actionCreators as globalCreators } from 'store/Global';
import { actionCreators as cashRegisterCreators } from 'store/CashRegister';

import SwipeableViews from 'react-swipeable-views';
import { CartOutline, ArrowLeft } from 'mdi-material-ui';
import { MoreVert, KeyboardArrowLeft, KeyboardArrowRight, Delete } from '@material-ui/icons';

import XButton from '../../../components/XButton';
import XOXLoader from '../../../components/XOXLoader';
import { CartOffOutline } from 'components/Icons';
import classNames from 'classnames';
import CashNumberEditor from './CashNumberEditor';
import CashTextEditor from './CashTextEditor';
import CashSelectEditor from './CashSelectEditor';
import CashRow from './CashRow';
import CashCell from './CashCell';
import RepairRowEditor from '../Repair/RepairRowEditor';
import color from 'color';
import { getDiscount } from 'utils';
import XSwitch from 'components/XSwitch';
import XMessageBox from '../../../components/XMessageBox';
import IdentitySelector from '../../../components/IdentitySelector';
import XUpload from '../../../components/XUpload';
import axios from 'axios';
import moment from 'moment';


const styles = theme => ({
    isInDoc:{
        backgroundColor: `${theme.palette.primary.light} !important`,
    },
    root: {
        minHeight: 128,
        position: 'relative',
        '&:nth-child(odd)': {
            backgroundColor: color(theme.palette.secondary.light).hsl().alpha(0.05).string()
        },
        '&:after': {
            content: '" "',
            display: "block",
            width: "100%",
            height: "1px",
            backgroundColor: color(theme.palette.secondary.light).hsl().alpha(0.2).string(),
            position: 'absolute',
            bottom: 0,
        },
    },
    mainGrid: {
        margin: 0,
        width: '100%',
    },
    rowCell: {
        display: 'inline-block',
        verticalAlign: 'top',
        padding: '0 15px',
        '&::before': {
            display: 'block',
            content: 'attr(data-label)',
        }
    },
    editable: {
        cursor: 'pointer',
        border: '1px solid'
    },
    editor: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: '100%',
        zIndex: 2000,
        background: '#fff'
    },
    slider: {
        //maxHeight: 220,
        //minHeight: 220,
        overflow: "hidden !important",
        position: "relative",
        width: "100%",
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        backgroundSize: 'contain',
    },
    button: {
        height: '100%',
    },
    mediaButtonLeft: {
        position: 'absolute',
        left: 0,
        top: 0,
    },
    mediaButtonRight: {
        position: 'absolute',
        right: 0,
        top: 0,
    },
    uploadButton: {
        position: 'absolute',
        bottom: 0,
        left: 0,
    }


});


class CashProductRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageIndex: 0,
            data: {},
            checkInDoc: false,
            isLoading: true,
            isRunning: false,
        }
        this.controller = undefined;

        this.XMessage = React.createRef();
    }

    componentDidMount() {
        this.loadData();
    }
    componentWillUnmount() {
        this.controller && this.controller.abort && this.controller.abort();
    }

    loadData = async () => {
        var { data, isBop, view } = this.props;
        var url = `/DOC/GetCashRow/${data}`;
        if(isBop){
            switch(view){
                case "BOPS":
                case "BOPSTOCK":
                    url = `/DOC/GetBopRow/${data}`;
                    break;
                case "DOCS":
                    url = `/DOC/GetDocProcessRow/${data}`;
                    break;
                case "DEPOSIT":
                    url = `/DOC/GetAccountRow/${data}`;
                    break;
                default:
                    url = `/DOC/GetBopRow/${data}`;
                    break;
            }
            this.checkIfIsInDoc();
            // var checkInDoc = await fetch(`/DOC/BopInCash/${data}?FIDDocument=${data.ID}&view=${view}`).then(res => res.json());
            // this.setState({checkInDoc});
        }
        console.time(`LOAD DATA BOP ${data}`);
        this.controller && this.controller.abort && this.controller.abort();
        this.controller = new AbortController();
        var signal = this.controller.signal;
        
        var result = await fetch(url,{ signal }).then(res => res.json()).catch(e => {
            if(e.name === "AbortError"){

            }
        });
        console.timeEnd(`LOAD DATA BOP ${data}`);
        this.setState({ data: result, isLoading: false, isRunning: false });
    }
    checkIfIsInDoc = async () => {
        var { data, isBop, view } = this.props;
        var checkInDoc = await fetch(`/DOC/BopInCash/${data}?FIDDocument=${data.ID}&view=${view}`).then(res => res.json());
        this.setState({checkInDoc});
    }
    onBuyClick = () => {
        var { data } = this.state;
        var { onBuy } = this.props;
        this.setState({isRunning: true});
        onBuy && onBuy(data.ID, this.loadData);
    }
    onRemoveClick = () => {
        var { data } = this.state;
        var { onRemove } = this.props;
        this.XMessage.current.showMessageYesNo('Cancellazione riga','Confermi la cancellazione della riga',() => {
            this.setState({isRunning: true});
            onRemove && onRemove(data.ID);
        });
    }
    onEditRepairSupplier = (key) => () => {
        this.setState({
            showSupplierSelector: true
        }, this.props.onEditorOpen);
    }
    onEditRepairDestination = (key) => () => {
        this.setState({
            showSupplierDestinationSelector: true
        }, this.props.onEditorOpen);
    }
    onEditorClick = (key, label) => () => {
        var { confirmed } = this.props;
        if(confirmed){
            return;
        }
        this.setState({
            showEditor: true, editKey: key, editLabel: label
        },this.requestActions);
    }
    onEditNote = (key) => () => {
        var { confirmed } = this.props;
        if(confirmed){
            return;
        }
        this.setState({
            showEditorNote: true, editKey: key
        },this.requestActions);
    }
    onEditDescription = (key) => () => {
        var { confirmed } = this.props;
        if(confirmed){
            return;
        }
        this.setState({
            showEditorDescription: true, editKey: key
        },this.requestActions);
    }
    onEditQty = (key) => () => {
        var { confirmed } = this.props;
        if(confirmed){
            return;
        }
        this.setState({
            showEditorQty: true, editKey: key
        },this.requestActions);
    }
    onEditInsurance = (key) => () => {
        var { confirmed } = this.props;
        if(confirmed){
            return;
        }
        this.setState({
            showEditorInsurance: true, editKey: key
        },this.requestActions);
    }
    onEditUM = (key) => () => {
        var { confirmed } = this.props;
        if(confirmed){
            return;
        }
        this.setState({
            showEditorUM: true, editKey: key
        },this.requestActions);
    }
    onEditCategory = (key) => () => {
        var { confirmed } = this.props;
        if(confirmed){
            return;
        }
        this.setState({
            showEditorCategory: true, editKey: key
        },this.requestActions);
    }
    onEditRepair = (key) => () => {
        var { confirmed } = this.props;
        if(confirmed){
            return;
        }
        this.setState({
            showRepairEditor: true, editKey: key
        },this.requestActions);
    }
    requestActions = () => {
        var actions = [];
        if(this.state.showEditor){
            var actionProductsBack = 
            {
                group: 'actions',
                name: 'Indietro',
                icon: <ArrowLeft />,
                action: this.onProductsBack
            };
            actions.push(actionProductsBack);
        }
        else if(this.state.showEditorNote || this.state.showEditorQty || this.state.showEditorUM || this.state.showEditorInsurance || this.state.showEditorDescription
            || this.state.showRepairEditor || this.state.showEditorCategory){
            var actionProductsBack = 
            {
                group: 'actions',
                name: 'Indietro',
                icon: <ArrowLeft />,
                action: this.onEditorBack
            };
            actions.push(actionProductsBack);
        }
        this.props.onRequestActions && this.props.onRequestActions(actions);
        if(actions.length > 0){
            this.props.onEditorOpen && this.props.onEditorOpen();
        }
        else {
            this.props.onEditorClose && this.props.onEditorClose();
        }
    }
    onProductsBack = () => {
        this.setState({
            showEditor: false, editKey: undefined, editLabel: undefined
        }, this.requestActions);
        
    }
    onEditorBack = () => {
        this.setState({
            showEditorNote: false,showEditorQty: false, showEditorUM: false, showEditorInsurance: false,showEditorDescription: false,showRepairEditor: false,showEditorCategory: false, editKey: undefined
        }, this.requestActions);
    }
    onProductsConfirm = () => {
        this.requestActions();
    }
    onEditorSubmit = async (fields) => {
        var { onUpdate } = this.props;
        var { data } = this.state;

        var url = `/DOC/UpdateCashRow/${data.ID}`;

        var keys =["TotalAmount","DiscountDesc","ExtraDiscountDesc","TotalDiscountedAmount"];

        var first = true;
        for(var k in keys){
            var key = keys[k];
            var el = fields.filter(item => item.id === key)[0];
            if(el){
                if(first){
                    url += '?';
                    first = false;
                }
                else {
                    url += '&';
                }
                url += `${key}=${el.value}`;
            }
        }

        await fetch(url,{
            method: 'POST',
            body: JSON.stringify(fields)
        });
        this.loadData();
        this.setState({
            showEditor: false, editKey: undefined, editLabel: undefined
        },this.requestActions);
        onUpdate && onUpdate();
        
    }
    onEditorRecalculate = (fields, changedField) => {
        //keys
        var TotalAmount = "TotalAmount";
        var DiscountDesc = "DiscountDesc";
        var ExtraDiscountDesc = "ExtraDiscountDesc";
        var TotalDiscountedAmount = "TotalDiscountedAmount";

        var elTotalAmount = fields.filter(item => item.id === TotalAmount)[0];
        var elDiscountDesc = fields.filter(item => item.id === DiscountDesc)[0];
        var elExtraDiscountDesc = fields.filter(item => item.id === ExtraDiscountDesc)[0];
        var elTotalDiscountedAmount = fields.filter(item => item.id === TotalDiscountedAmount)[0];
        

        if(changedField === TotalAmount){
            elTotalDiscountedAmount.value = (elTotalAmount.value * (100 - elDiscountDesc.value) * (100 - elExtraDiscountDesc.value) / 10000).toFixed(2);
        }
        else if(changedField === DiscountDesc){
            elTotalDiscountedAmount.value = (elTotalAmount.value * (100 - elDiscountDesc.value) * (100 - elExtraDiscountDesc.value)/ 10000).toFixed(2);
        }
        else if(changedField === ExtraDiscountDesc){
            elTotalDiscountedAmount.value = (elTotalAmount.value * (100 - elDiscountDesc.value) * (100 - elExtraDiscountDesc.value) / 10000).toFixed(2);
        }
        else if(changedField === TotalDiscountedAmount){
            var discAmountPreExtra = (elTotalAmount.value * (100 - elDiscountDesc.value) / 100).toFixed(2)
            elExtraDiscountDesc.value = ((1 - (elTotalDiscountedAmount.value / discAmountPreExtra)) * 100).toFixed(4);
        }
        fields = [elTotalAmount,elDiscountDesc,elExtraDiscountDesc,elTotalDiscountedAmount];
        return fields;
    }
    onEditorNoteSubmit = async (fields) => {
        var { onUpdate } = this.props;
        var { data } = this.state;

        var url = `/DOC/UpdateRowRemarks/${data.ID}`;

        var keys =["InternalRemarks","PublicRemarks"];

        var first = true;
        for(var k in keys){
            var key = keys[k];
            var el = fields.filter(item => item.id === key)[0];
            if(el){
                if(first){
                    url += '?';
                    first = false;
                }
                else {
                    url += '&';
                }
                url += `${key}=${el.value}`;
            }
        }

        await fetch(url,{
            method: 'POST',
            body: JSON.stringify(fields)
        });
        this.loadData();
        this.setState({
            showEditorNote: false, editKey: undefined, editLabel: undefined
        },this.requestActions);
        onUpdate && onUpdate();
    }
    onEditorDescriptionSubmit = async (fields) => {
        var { onUpdate } = this.props;
        var { data } = this.state;

        var url = `/DOC/UpdateRowDescription/${data.ID}`;

        var keys =["Description"];

        var first = true;
        for(var k in keys){
            var key = keys[k];
            var el = fields.filter(item => item.id === key)[0];
            if(el){
                if(first){
                    url += '?';
                    first = false;
                }
                else {
                    url += '&';
                }
                url += `${key}=${el.value}`;
            }
        }

        await fetch(url,{
            method: 'POST',
            body: JSON.stringify(fields)
        });
        this.loadData();
        this.setState({
            showEditorDescription: false, editKey: undefined, editLabel: undefined
        },this.requestActions);
        onUpdate && onUpdate();
    }
    onEditorQtySubmit = async (fields) => {
        var { onUpdate } = this.props;
        var { data } = this.state;

        var url = `/DOC/UpdateRowQty/${data.ID}`;

        var keys =["Qty1"];

        var first = true;
        for(var k in keys){
            var key = keys[k];
            var el = fields.filter(item => item.id === key)[0];
            if(el){
                if(first){
                    url += '?';
                    first = false;
                }
                else {
                    url += '&';
                }
                url += `${key}=${el.value}`;
            }
        }

        await fetch(url,{
            method: 'POST',
            body: JSON.stringify(fields)
        });
        this.loadData();
        this.setState({
            showEditorQty: false, editKey: undefined, editLabel: undefined
        },this.requestActions);
        onUpdate && onUpdate();
    }
    onEditorUMSubmit = async (ID) => {
        var { onUpdate } = this.props;
        var { data } = this.state;

        var url = `/DOC/UpdateRowUM/${data.ID}?FIDMeasureUnit1=${ID}`;
        await fetch(url,{
            method: 'POST',
        });
        this.loadData();
        this.setState({
            showEditorUM: false, editKey: undefined, editLabel: undefined
        },this.requestActions);
        onUpdate && onUpdate();
    }
    onEditorCategorySubmit = async (ID) => {
        var { onUpdate } = this.props;
        var { data } = this.state;

        var url = `/DOC/UpdateRowCategory/${data.ID}?FIDCategory=${ID}`;
        await fetch(url,{
            method: 'POST',
        });
        this.loadData();
        this.setState({
            showEditorCategory: false, editKey: undefined, editLabel: undefined
        },this.requestActions);
        onUpdate && onUpdate();
    }
    onEditorInsuranceSubmit = async (fields) => {
        var { onUpdate } = this.props;
        var { data } = this.state;

        var url = `/DOC/UpdateRowInsurance/${data.ID}`;
        var keys =["InsuranceValue"];

        var first = true;
        for(var k in keys){
            var key = keys[k];
            var el = fields.filter(item => item.id === key)[0];
            if(el){
                if(first){
                    url += '?';
                    first = false;
                }
                else {
                    url += '&';
                }
                url += `${key}=${el.value}`;
            }
        }

        await fetch(url,{
            method: 'POST',
            body: JSON.stringify(fields)
        });
        this.loadData();
        this.setState({
            showEditorInsurance: false, editKey: undefined, editLabel: undefined
        },this.requestActions);
        onUpdate && onUpdate();
    }
    
    onSupplierSelect = (FIDIdentity) => {
        var { onUpdate } = this.props;
        var { data } = this.state;
        fetch(`/DOC/UpdateRepairSupplier/${data.ID}?FIDSupplier=${FIDIdentity}`).then(res => {
            this.loadData();
            this.setState({
                showSupplierSelector: false,
            },this.requestActions);
            
        onUpdate && onUpdate();
        })
    }
    onSupplierDestinationSelect = (FIDIdentity) => {
        var { onUpdate } = this.props;
        var { data } = this.state;
        fetch(`/DOC/UpdateRepairDestination/${data.ID}?FIDSupplier=${FIDIdentity}`).then(res => {
            this.loadData();
            this.setState({
                showSupplierDestinationSelector: false,
            },this.requestActions);
            
        onUpdate && onUpdate();
        })
    }
    onWarrantyChange = (id) => async (event,value) => {
        var { onUpdate } = this.props;
        var { data } = this.state;

        var url = `/DOC/UpdateRowWarranty/${data.ID}?IsInWarranty=${value}`;

        await fetch(url,{
            method: 'POST',
        });
        this.setState({
            showEditorInsurance: false, editKey: undefined, editLabel: undefined
        },() => {
            if(!value){
                this.loadData();
                this.requestActions();
                onUpdate && onUpdate();
            }
            else{
                this.onEditorSubmit([
                    {
                        id:'TotalAmount',
                        value: 0
                    },
                    {
                        id:'DiscountDesc',
                        value: 0
                    },
                    {
                        id:'ExtraDiscountDesc',
                        value: 0
                    },
                    {
                        id:'TotalDiscountedAmount',
                        value: 0
                    }
                ])
            }
        });
    }
    onUploadImage = (id) => (event, file) => {
        var { onUpdate } = this.props;
        var { data } = this.state;

        const formData = new FormData()
        formData.append('file', file, file.name)

        axios
            .post(`/DOC/UploadRepairImage/${data.ID}`, formData, {
                onUploadProgress: ProgressEvent => {
                    console.log(ProgressEvent.loaded / ProgressEvent.total * 100);
                },
            })
            .then(res => res.data).then(data => {
                this.loadData();
                onUpdate && onUpdate();
            });
    }
    renderBop = () => {
        var { classes } = this.props;
        var { imageIndex, data, checkInDoc, isRunning } = this.state;if(!data){
            data={};
        }
        var files = (data && data.Files) ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        if (files.length > 1) {
            //files = [files[0]];
        }
        var mediaFiles = files.length > 0 ? files.map((file, index) => (<CardMedia key={index} className={classes.media} image={"/Base/Image/" + file + "?fileSize=Medium"} />)) : (<CardMedia className={classes.media} image="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E" />);
        if (data && data.File64) {
            mediaFiles = [(<CardMedia className={classes.media} image={data.File64} />)];
        }

        if(data.SellDate && !data.SellDate._isAMomentObject){
            data.SellDate = moment(new Date(data.SellDate));
        }
        return <div className={classNames(classes.root,{[classes.isInDoc]: checkInDoc})}>
            <Grid className={classes.mainGrid} container spacing={2}>
                <Grid item xs={2}>
                    <div className={classes.slider}>
                        <SwipeableViews axis="x" resistance={false} index={imageIndex} onChangeIndex={this.handleChangeIndex}>
                            {mediaFiles}
                        </SwipeableViews>
                        {files.length > 1 &&
                            (
                                <Fragment>
                                    <Button className={classes.mediaButtonLeft} size="small" onClick={this.handlePrevious} ><KeyboardArrowLeft /></Button>
                                    <Button className={classes.mediaButtonRight} size="small" onClick={this.handleNext}><KeyboardArrowRight /></Button>
                                </Fragment>
                            )
                        }
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <p>{data.Code} - {data.Description}</p>
                    <p>{data.Brand} {data.Line && ` - ${data.Line}`} {data.Series && ` - ${data.Series}`}</p>
                    <p>{data.Group} {data.Typology && ` - ${data.Typology}`} {data.Category && ` - ${data.Category}`}</p>
                    <p>{data.Barcode} {data.SupplierSN && ` - ${data.SupplierSN}`}</p>
                    {data.SellDate && <p>{data.SellCausal} n. {data.SellNumber} del {data.SellDate.format('DD/MM/YYYY')} - {data.SellIdentity}</p>}
                </Grid>
                <Grid item xs={4}>
                    <span className={classes.rowCell} data-label="Listino">{data.TotalPrice && data.TotalPrice.toFixed(2)} €</span>
                    <span className={classes.rowCell} data-label="Sc%">{data.DiscountDesc || "0"} %</span>
                    <span className={classes.rowCell} data-label="Extra Sc%">{data.ExtraDiscountDesc || "0"} %</span>
                    <span className={classes.rowCell} data-label="Prezzo di vendita">{data.DiscountedTotalPrice && data.DiscountedTotalPrice.toFixed(2)} €</span>
                </Grid>
                <Grid item xs={2}>
                    {!checkInDoc && <XButton className={classes.button} disabled={isRunning} onClick={this.onBuyClick}><CartOutline /></XButton>}
                </Grid>
            </Grid>
        </div>;
    }
    renderProcessRow = () => {
        var { classes } = this.props;
        var { imageIndex, data,checkInDoc, isRunning } = this.state;
        if(!data){
            data={};
        }
        var files = (data && data.Files) ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        if (files.length > 1) {
            //files = [files[0]];
        }
        var mediaFiles = files.length > 0 ? files.map((file, index) => (<CardMedia key={index} className={classes.media} image={"/Base/Image/" + file + "?fileSize=Medium"} />)) : (<CardMedia className={classes.media} image="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E" />);
        if (data && data.File64) {
            mediaFiles = [(<CardMedia className={classes.media} image={data.File64} />)];
        }

        return <div className={classNames(classes.root,{[classes.isInDoc]: checkInDoc})}>
            <Grid className={classes.mainGrid} container spacing={2}>
                <Grid item xs={2}>
                    <div className={classes.slider}>
                        <SwipeableViews axis="x" resistance={false} index={imageIndex} onChangeIndex={this.handleChangeIndex}>
                            {mediaFiles}
                        </SwipeableViews>
                        {files.length > 1 &&
                            (
                                <Fragment>
                                    <Button className={classes.mediaButtonLeft} size="small" onClick={this.handlePrevious} ><KeyboardArrowLeft /></Button>
                                    <Button className={classes.mediaButtonRight} size="small" onClick={this.handleNext}><KeyboardArrowRight /></Button>
                                </Fragment>
                            )
                        }
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <p>{data.Code} - {data.Description}</p>
                    <p>{data.Brand} {data.Line && ` - ${data.Line}`} {data.Series && ` - ${data.Series}`}</p>
                    <p>{data.Barcode} {data.SupplierSN && ` - ${data.SupplierSN}`}</p>
                    {data.DocumentDate && <p>{data.DocumentDescription} n. {data.Number} del {new Date(data.DocumentDate).toLocaleDateString()}</p>}
                    {data.Denomination && <p>{data.Denomination}</p>}
                    {!data.Denomination && <p>Cliente generico</p>}
                </Grid>
                <Grid item xs={4}>
                    <span className={classes.rowCell} data-label="Listino">{data.TotalAmount && data.TotalAmount.toFixed(2)} €</span>
                    <span className={classes.rowCell} data-label="Sc%">{data.DiscountDesc || "0"} %</span>
                    <span className={classes.rowCell} data-label="Extra Sc%">{data.ExtraDiscountDesc || "0"} %</span>
                    <span className={classes.rowCell} data-label="Prezzo di vendita">{data.TotalDiscountedAmount && data.TotalDiscountedAmount.toFixed(2)} €</span>
                </Grid>
                <Grid item xs={2}>
                    {!checkInDoc && <XButton className={classes.button} disabled={isRunning} onClick={this.onBuyClick}><CartOutline /></XButton>}
                </Grid>
            </Grid>
        </div>;
    }
    
    renderDepositRow = () => {
        var { classes } = this.props;
        var { data,checkInDoc, isRunning } = this.state;
        if(!data){
            data={};
        }
       

        return <div className={classNames(classes.root,{[classes.isInDoc]: checkInDoc})}>
            <Grid className={classes.mainGrid} container spacing={2}>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={4}>
                    <p>{data.Description}</p>
                    {data.DocumentDate && <p>{data.DocumentDescription} n. {data.Number} del {new Date(data.DocumentDate).toLocaleDateString()}</p>}
                    {data.Denomination && <p>{data.Denomination}</p>}
                    {!data.Denomination && <p>Cliente generico</p>}
                </Grid>
                <Grid item xs={4}>
                    <span className={classes.rowCell} data-label="Importo">{data.TotalDiscountedAmount && data.TotalDiscountedAmount.toFixed(2)} €</span>
                </Grid>
                <Grid item xs={2}>
                    {!checkInDoc && <XButton className={classes.button} disabled={isRunning} onClick={this.onBuyClick}><CartOutline /></XButton>}
                </Grid>
            </Grid>
        </div>;
    }
    renderRow = () => {
        var { classes, confirmed, isRepair } = this.props;
        var { imageIndex, data, isRunning } = this.state;
        if(!data){
            data={};
        }
        var files = (data && data.Files) ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        if (files.length > 1) {
            //files = [files[0]];
        }
        var mediaFiles = files.length > 0 ? files.map((file, index) => (<CardMedia key={index} className={classes.media} image={"/Base/Image/" + file + "?fileSize=Medium"} />)) : (<CardMedia className={classes.media} image="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E" />);
        if (data && data.File64) {
            mediaFiles = [(<CardMedia className={classes.media} image={data.File64} />)];
        }

        return <div className={classes.root}>
            <Grid className={classes.mainGrid} container spacing={2}>
                <Grid item xs={2}>
                    <div className={classes.slider}>
                        <SwipeableViews axis="x" resistance={false} index={imageIndex} onChangeIndex={this.handleChangeIndex}>
                            {mediaFiles}
                        </SwipeableViews>
                        {files.length > 1 &&
                            (
                                <Fragment>
                                    <Button className={classes.mediaButtonLeft} size="small" onClick={this.handlePrevious} ><KeyboardArrowLeft /></Button>
                                    <Button className={classes.mediaButtonRight} size="small" onClick={this.handleNext}><KeyboardArrowRight /></Button>
                                </Fragment>
                            )
                        }
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <p>{data.Code} - {data.Description}</p>
                    <p>{data.Brand} {data.Line && ` - ${data.Line}`} {data.Series && ` - ${data.Series}`}</p>
                    <p>{data.Group} {data.Typology && ` - ${data.Typology}`} {data.Category && ` - ${data.Category}`}</p>
                    <p>{data.Barcode} {data.SupplierSN && ` - ${data.SupplierSN}`}</p>
                </Grid>
                <Grid item xs={5}>
                    <CashRow>
                        <CashCell labelFontSize={10} fontSize={14} width={"25%"} totalWidth={"100%"} label={"Listino"} onClick={!confirmed && this.onEditorClick("TotalAmount","Listino")}>{data.TotalAmount.toFixed(2)} €</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"25%"} totalWidth={"100%"} label={"Sc %"} onClick={!confirmed && this.onEditorClick("DiscountDesc","Sc %")}>{data.DiscountDesc || "0"} %</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"25%"} totalWidth={"100%"} label={"Extra Sc %"} onClick={!confirmed && this.onEditorClick("ExtraDiscountDesc","Extra Sc %")}>{data.ExtraDiscountDesc || "0"} %</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"25%"} totalWidth={"100%"} label={"Prezzo di vendita"} onClick={!confirmed && this.onEditorClick("TotalDiscountedAmount","Prezzo di vendita")}>{data.TotalDiscountedAmount.toFixed(2)} €</CashCell>
                    </CashRow>
                    {isRepair && <CashRow>
                        <CashCell labelFontSize={10} fontSize={14} width={"50%"} totalWidth={"100%"} label={"Descrizione interna"} onClick={!confirmed && this.onEditNote("InternalRemarks")}>{data.InternalRemarks || "..."}</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"50%"} totalWidth={"100%"} label={"Descrizione riparazione"} onClick={!confirmed && this.onEditNote("PublicRemarks")}>{data.PublicRemarks || "..."}</CashCell>
                    </CashRow>}
                    {isRepair && <CashRow>
                        <CashCell labelFontSize={10} fontSize={14} width={"33%"} totalWidth={"100%"} label={"U.M."} onClick={!confirmed && this.onEditUM("FIDMeasureUnit1")}>{data.MeasureUnit1 || "..."}</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"33%"} totalWidth={"100%"} label={"Q.tà"} onClick={!confirmed && this.onEditQty("Qty1")}>{data.Qty1 || 1}</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"33%"} totalWidth={"100%"} label={"Valore assicurativo"} onClick={!confirmed && this.onEditInsurance("InsuranceValue")}>{data.InsuranceValue || 0} €</CashCell>
                    </CashRow>}
                    {isRepair && <CashRow>
                        <CashCell labelFontSize={10} fontSize={14} width={"100%"} totalWidth={"100%"} label={"Prodotto in garanzia"}><XSwitch id="IsInWarranty" onChange={this.onWarrantyChange} value={data.IsInWarranty} /></CashCell>
                    </CashRow>}
                    {/* <span className={classNames(classes.rowCell,{ [classes.editable]: !confirmed })} onClick={this.onEditorClick("TotalAmount","Listino")} data-label="Listino">{data.TotalAmount.toFixed(2)} €</span>
                    <span className={classNames(classes.rowCell,{ [classes.editable]: !confirmed })} onClick={this.onEditorClick("DiscountDesc","Sc %")} data-label="Sc%">{data.DiscountDesc || "0"} %</span>
                    <span className={classNames(classes.rowCell,{ [classes.editable]: !confirmed })} onClick={this.onEditorClick("ExtraDiscountDesc","Extra Sc %")} data-label="Extra Sc%">{data.ExtraDiscountDesc || "0"} %</span>
                    <span className={classNames(classes.rowCell,{ [classes.editable]: !confirmed })} onClick={this.onEditorClick("TotalDiscountedAmount","Prezzo di vendita")} data-label="Prezzo di vendita">{data.TotalDiscountedAmount.toFixed(2)} €</span> */}
                </Grid>
                <Grid item xs={1}>
                    {!confirmed && <XButton className={classes.button} disabled={isRunning} onClick={this.onRemoveClick}><Delete /></XButton>}
                </Grid>
            </Grid>
            
            <XMessageBox innerRef={this.XMessage} />
        </div>;
    }
    
    renderRepairRow = () => {
        var { classes, confirmed, isRepair, doc } = this.props;
        var { imageIndex, data, isRunning } = this.state;
        if(!data){
            data={};
        }
        var xuploadACtion = false;
        if(!confirmed){
            xuploadACtion = <div className={classes.uploadButton}><XUpload key="filRepair" id="filRepair" onChange={this.onUploadImage} /></div>;
        }
        var files = (data && data.Files) ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        if (files.length > 1) {
            //files = [files[0]];
        }
        var mediaFiles = files.length > 0 ? files.map((file, index) => (<CardMedia key={index} className={classes.media} image={"/Base/Image/" + file + "?fileSize=Medium"} />)) : (<CardMedia className={classes.media} image="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E" />);
        if (data && data.File64) {
            mediaFiles = [(<CardMedia className={classes.media} image={data.File64} />)];
        }
        var serialNumbers = `${data.Barcode} ${data.SupplierSN && ` - ${data.SupplierSN}`}`;

        return <div className={classes.root}>
            <Grid className={classes.mainGrid} container spacing={2}>
                <Grid item xs={2}>
                    <div className={classes.slider}>
                        <SwipeableViews axis="x" resistance={false} index={imageIndex} onChangeIndex={this.handleChangeIndex}>
                            {mediaFiles}
                        </SwipeableViews>
                        {files.length > 1 &&
                            (
                                <Fragment>
                                    <Button className={classes.mediaButtonLeft} size="small" onClick={this.handlePrevious} ><KeyboardArrowLeft /></Button>
                                    <Button className={classes.mediaButtonRight} size="small" onClick={this.handleNext}><KeyboardArrowRight /></Button>
                                </Fragment>
                            )
                        }
                        {xuploadACtion}
                    </div>
                </Grid>
                <Grid item xs={9}>
                    <CashRow>
                        <CashCell labelFontSize={10} fontSize={14} width={"25%"} totalWidth={"100%"} label={"Codice"}>{data.Code}</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"75%"} totalWidth={"100%"} label={"Descrizione"}>{data.Description}</CashCell>
                    </CashRow>
                    <CashRow>
                        <CashCell labelFontSize={10} fontSize={14} width={"100%"} label={"Tag principale"} onClick={!confirmed && data.Incomplete && this.onEditCategory("FIDCategory")}>{data.Category || '...'}</CashCell>
                    </CashRow>
                    <CashRow>
                        <CashCell labelFontSize={10} fontSize={14} width={"100%"} totalWidth={"100%"} label={"Descrizione riparazione"} onClick={!confirmed && this.onEditNote("PublicRemarks")}>{data.PublicRemarks || "..."}</CashCell>
                    </CashRow>
                    <CashRow>
                        <CashCell labelFontSize={10} fontSize={14} width={"100%"} totalWidth={"100%"} label={"Descrizione interna"} onClick={!confirmed && this.onEditNote("InternalRemarks")}>{data.InternalRemarks || "..."}</CashCell>
                    </CashRow>
                    {/* <CashRow>
                        <CashCell labelFontSize={10} fontSize={14} width={"100%"} totalWidth={"100%"} label={"Collezione"}>{data.Brand} {data.Line && ` - ${data.Line}`} {data.Series && ` - ${data.Series}`}</CashCell>
                    </CashRow>
                    <CashRow>
                        <CashCell labelFontSize={10} fontSize={14} width={"100%"} totalWidth={"100%"} label={"Tipologia"}>{data.Group} {data.Typology && ` - ${data.Typology}`} {data.Category && ` - ${data.Category}`}</CashCell>
                    </CashRow>
                    {!serialNumbers.trim() && <CashRow>
                        <CashCell labelFontSize={10} fontSize={14} width={"100%"} totalWidth={"100%"} label={"Matricola"}>{serialNumbers}</CashCell>
                    </CashRow>} */}
                    <CashRow>
                        <CashCell labelFontSize={10} fontSize={14} width={"12.5%"} totalWidth={"100%"} label={"U.M."} onClick={!confirmed && this.onEditUM("FIDMeasureUnit1")}>{data.MeasureUnit1 || "..."}</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"12.5%"} totalWidth={"100%"} label={"Q.tà"} onClick={!confirmed && this.onEditQty("Qty1")}>{data.Qty1 || 1}</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"12.5%"} totalWidth={"100%"} label={"Valore assicurativo"} onClick={!confirmed && this.onEditInsurance("InsuranceValue")}>{data.InsuranceValue || 0} €</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"12.5%"} totalWidth={"100%"} label={"Prodotto in garanzia"}><XSwitch id="IsInWarranty" onChange={this.onWarrantyChange} value={data.IsInWarranty} /></CashCell>
                        
                        <CashCell labelFontSize={10} fontSize={14} width={"12.5%"} totalWidth={"100%"} label={"Listino"} onClick={!confirmed && !data.IsInWarranty && this.onEditorClick("TotalAmount","Listino")}>{data.TotalAmount.toFixed(2)} €</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"12.5%"} totalWidth={"100%"} label={"Sc %"} onClick={!confirmed && !data.IsInWarranty && this.onEditorClick("DiscountDesc","Sc %")}>{data.DiscountDesc || "0"} %</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"12.5%"} totalWidth={"100%"} label={"Extra Sc %"} onClick={!confirmed && !data.IsInWarranty && this.onEditorClick("ExtraDiscountDesc","Extra Sc %")}>{data.ExtraDiscountDesc || "0"} %</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"12.5%"} totalWidth={"100%"} label={"Prezzo di vendita"} onClick={!confirmed && !data.IsInWarranty && this.onEditorClick("TotalDiscountedAmount","Prezzo di vendita")}>{data.TotalDiscountedAmount.toFixed(2)} €</CashCell>
                    </CashRow>
                    {doc.FIDCausal !== 36 && <CashRow>
                        <CashCell  labelFontSize={10} fontSize={14} width={"50%"} totalWidth={"100%"} label={"Riparatore"} onClick={!confirmed && this.onEditRepairSupplier("FIDRepairSupplier")}>{data.RepairSupplierDenomination || "..."}</CashCell>
                        <CashCell  labelFontSize={10} fontSize={14} width={"50%"} totalWidth={"100%"} label={"Sede riparatore"} onClick={!confirmed && this.onEditRepairDestination("FIDRepairDestination")}>{data.RepairDestinationDenomination || "..."}</CashCell>
                    </CashRow>}
                </Grid>
                <Grid item xs={1}>
                    {!confirmed && <XButton className={classes.button} disabled={isRunning} onClick={this.onRemoveClick}><Delete /></XButton>}
                </Grid>
            </Grid>
            <XMessageBox innerRef={this.XMessage} />
        </div>;
    }
    handleChangeIndex = (index) => {
        this.setState({ imageIndex: index });
    }
    handleNext = () => {

        var { data, imageIndex } = this.state;
        var files = (data && data.Files) ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = files.length;
        if (imageIndex < imagesCount - 1) {
            this.setState({ imageIndex: imageIndex + 1 });
        }
        else {
            this.setState({ imageIndex: 0 });
        }
    }
    handlePrevious = () => {
        var { data, imageIndex } = this.state;
        var files = (data && data.Files) ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        var imagesCount = files.length;
        if (imageIndex > 0) {
            this.setState({ imageIndex: imageIndex - 1 });
        }
        else {
            this.setState({ imageIndex: imagesCount - 1 });
        }
    }
    renderNewRow = () => {
        var { classes, confirmed, isRepair } = this.props;
        var { imageIndex, data, isRunning } = this.state;
        if(!data){
            data={};
        }
        var xuploadACtion = false;
        if(!confirmed){
            xuploadACtion = <div className={classes.uploadButton}><XUpload key="filRepair" id="filRepair" onChange={this.onUploadImage} /></div>;
        }
        var files = (data && data.Files) ? data.Files.split(',').map(m => parseInt(m, 10)) : [];
        if (files.length > 1) {
            //files = [files[0]];
        }
        var mediaFiles = files.length > 0 ? files.map((file, index) => (<CardMedia key={index} className={classes.media} image={"/Base/Image/" + file + "?fileSize=Medium"} />)) : (<CardMedia className={classes.media} image="data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%209'%2F%3E" />);
        if (data && data.File64) {
            mediaFiles = [(<CardMedia className={classes.media} image={data.File64} />)];
        }

        return <div className={classes.root}>
            <Grid className={classes.mainGrid} container spacing={2}>
                <Grid item xs={2}>
                    <div className={classes.slider}>
                        <SwipeableViews axis="x" resistance={false} index={imageIndex} onChangeIndex={this.handleChangeIndex}>
                            {mediaFiles}
                        </SwipeableViews>
                        {files.length > 1 &&
                            (
                                <Fragment>
                                    <Button className={classes.mediaButtonLeft} size="small" onClick={this.handlePrevious} ><KeyboardArrowLeft /></Button>
                                    <Button className={classes.mediaButtonRight} size="small" onClick={this.handleNext}><KeyboardArrowRight /></Button>
                                </Fragment>
                            )
                        }
                        {xuploadACtion}
                    </div>
                </Grid>
                <Grid item xs={9}>
                    <CashRow>
                        <CashCell labelFontSize={10} fontSize={14} width={"100%"} label={"Descrizione"} onClick={!confirmed && this.onEditDescription("Description")}>{data.Description || '...'}</CashCell>
                    </CashRow>
                    <CashRow>
                        <CashCell labelFontSize={10} fontSize={14} width={"100%"} label={"Tag principale"} onClick={!confirmed && this.onEditCategory("FIDCategory")}>{data.Category || '...'}</CashCell>
                    </CashRow>
                    <CashRow>
                        <CashCell labelFontSize={10} fontSize={14} width={"100%"} label={"Descrizione riparazione"} onClick={!confirmed && this.onEditNote("PublicRemarks")}>{data.PublicRemarks || "..."}</CashCell>
                    </CashRow>
                    <CashRow>
                        <CashCell labelFontSize={10} fontSize={14} width={"100%"} label={"Descrizione interna"} onClick={!confirmed && this.onEditNote("InternalRemarks")}>{data.InternalRemarks || "..."}</CashCell>
                    </CashRow>
                    <CashRow>
                        <CashCell labelFontSize={10} fontSize={14} width={"12.5%"} totalWidth={"100%"} label={"U.M."} onClick={!confirmed && this.onEditUM("FIDMeasureUnit1")}>{data.MeasureUnit1 || "..."}</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"12.5%"} totalWidth={"100%"} label={"Q.tà"} onClick={!confirmed && this.onEditQty("Qty1")}>{data.Qty1 || 1}</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"12.5%"} totalWidth={"100%"} label={"Valore assicurativo"} onClick={!confirmed && this.onEditInsurance("InsuranceValue")}>{data.InsuranceValue || 0} €</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"12.5%"} totalWidth={"100%"} label={"Prodotto in garanzia"}><XSwitch id="IsInWarranty" onChange={this.onWarrantyChange} value={data.IsInWarranty} /></CashCell>
                        
                        <CashCell labelFontSize={10} fontSize={14} width={"12.5%"} totalWidth={"100%"} label={"Listino"} onClick={!confirmed && !data.IsInWarranty && this.onEditorClick("TotalAmount","Listino")}>{data.TotalAmount.toFixed(2)} €</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"12.5%"} totalWidth={"100%"} label={"Sc %"} onClick={!confirmed && !data.IsInWarranty && this.onEditorClick("DiscountDesc","Sc %")}>{data.DiscountDesc || "0"} %</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"12.5%"} totalWidth={"100%"} label={"Extra Sc %"} onClick={!confirmed && !data.IsInWarranty && this.onEditorClick("ExtraDiscountDesc","Extra Sc %")}>{data.ExtraDiscountDesc || "0"} %</CashCell>
                        <CashCell labelFontSize={10} fontSize={14} width={"12.5%"} totalWidth={"100%"} label={"Prezzo di vendita"} onClick={!confirmed && !data.IsInWarranty && this.onEditorClick("TotalDiscountedAmount","Prezzo di vendita")}>{data.TotalDiscountedAmount.toFixed(2)} €</CashCell>
                    </CashRow>
                    <CashRow>
                        <CashCell  labelFontSize={10} fontSize={14} width={"50%"} totalWidth={"100%"} label={"Riparatore"} onClick={!confirmed && this.onEditRepairSupplier("FIDRepairSupplier")}>{data.RepairSupplierDenomination || "..."}</CashCell>
                        <CashCell  labelFontSize={10} fontSize={14} width={"50%"} totalWidth={"100%"} label={"Sede riparatore"} onClick={!confirmed && this.onEditRepairDestination("FIDRepairDestination")}>{data.RepairDestinationDenomination || "..."}</CashCell>
                    </CashRow>
                </Grid>
                <Grid item xs={1}>
                    {!confirmed && <XButton className={classes.button} disabled={isRunning} onClick={this.onRemoveClick}><Delete /></XButton>}
                </Grid>
            </Grid>
            <XMessageBox innerRef={this.XMessage} />
        </div>;
    }
    render() {
        var { isBop,isRepair, classes,view } = this.props;
        var { data, isLoading, showEditor, editKey, editLabel, showEditorNote,showEditorQty,showEditorUM, showEditorInsurance, 
            showEditorDescription,showRepairEditor, showSupplierSelector,showSupplierDestinationSelector,showEditorCategory } = this.state;
        
        if(isLoading){
            return <div className={classes.root}><XOXLoader onlyO={true} height="100%" /></div>;
        }
        if(showEditor){
            var fields = [
                {
                    label: "Listino",
                    id: "TotalAmount",
                    value: data.TotalAmount || 0
                },
                {
                    label: "Sc %",
                    id: "DiscountDesc",
                    value: data.DiscountDesc || 0
                },
                {
                    label: "Extra Sc %",
                    id: "ExtraDiscountDesc",
                    value: data.ExtraDiscountDesc || 0
                },
                {
                    label: "Prezzo di vendita",
                    id: "TotalDiscountedAmount",
                    value: data.TotalDiscountedAmount || 0
                }
            ];
            return <div className={classes.editor}>
                    <CashNumberEditor fieldLabel={editLabel} id={editKey} value={data[editKey]} fields={fields} onSubmit={this.onEditorSubmit} onRecalculate={this.onEditorRecalculate}/>
                </div>;
        }
        else if(showEditorNote){
            var fields = [
                {
                    label: "Descrizione interna",
                    id: "InternalRemarks",
                    value: data.InternalRemarks || ""
                },
                {
                    label: "Descrizione riparazione",
                    id: "PublicRemarks",
                    value: data.PublicRemarks || ""
                },
            ];
            return <div className={classes.editor}>
                    <CashTextEditor fieldLabel={editLabel} id={editKey} value={data[editKey]} fields={fields} onSubmit={this.onEditorNoteSubmit} />
                </div>;
        }
        else if(showEditorDescription){
            var fields = [
                {
                    label: "Descrizione",
                    id: "Description",
                    value: data.Description || ""
                },
            ];
            return <div className={classes.editor}>
                    <CashTextEditor fieldLabel={editLabel} id={editKey} value={data[editKey]} fields={fields} onSubmit={this.onEditorDescriptionSubmit} />
                </div>;
        }
        else if(showEditorQty){
            var fields = [
                {
                    label: "Q.tà",
                    id: "Qty1",
                    value: data.Qty1 || 0
                }
            ];
            return <div className={classes.editor}>
                    <CashNumberEditor fieldLabel={editLabel} id={editKey} value={data[editKey]} fields={fields} onSubmit={this.onEditorQtySubmit} />
                </div>;
        }
        else if(showEditorUM){
            return <div className={classes.editor}>
                    <CashSelectEditor idKey={"ID"} valueKey={"Abbreviation"} value={data.FIDMeasureUnit1} urlData="/Base/GetGCMeasureUnits" onSubmit={this.onEditorUMSubmit} />
                </div>;
        }
        else if(showEditorCategory){
            return <div className={classes.editor}>
                    <CashSelectEditor idKey={"ID"} valueKey={"Denomination"} value={data.FIDCategory} urlData="/BOP/Category" onSubmit={this.onEditorCategorySubmit} />
                </div>;
        }
        else if(showEditorInsurance){
            var fields = [
                {
                    label: "Valore assicurativo",
                    id: "InsuranceValue",
                    value: data.InsuranceValue || 0
                }
            ];
            return <div className={classes.editor}>
                    <CashNumberEditor fieldLabel={editLabel} id={editKey} value={data[editKey]} fields={fields} onSubmit={this.onEditorInsuranceSubmit} />
                </div>;
        }
        else if(showRepairEditor) {
            var fields = [
                {
                    row: 1,
                    width: '100%',
                    label: "Descrizione",
                    id: "Description",
                    value: data.Description || '',
                    type: 'full',
                },
                {
                    row: 2,
                    width: '100%',
                    label: 'Descrizioen riparazione',
                    id: 'PublicRemarks',
                    value: data.PublicRemarks || '',
                    type: 'full',
                },
                {
                    row: 3,
                    width: '100%',
                    label: 'Descrizione interna',
                    id: 'InternalRemarks',
                    value: data.InternalRemarks || '',
                    type: 'full',
                },
                {
                    row:4,
                    width: '12.5%',
                    label: 'U.M.',
                    id: 'FIDMeasureUnit1',
                    value: data.FIDMeasureUnit1,
                    type: 'full',
                },

                {
                    row:4,
                    width: '12.5%',
                    label: 'Q.tà',
                    id: 'Qty1',
                    value: data.Qty1,
                    type: 'numeric',
                },
                {
                    row:4,
                    width: '12.5%',
                    label: 'Valore assicurativo',
                    id: 'InsuranceValue',
                    value: data.InsuranceValue,
                    type: 'numeric',
                },
                {
                    row:4,
                    width: '12.5%',
                    label: 'Prodotto in garanzia',
                    id: 'IsInWarranty',
                    value: data.IsInWarranty,
                    type: 'full',
                },
                {
                    row:4,
                    width: '12.5%',
                    label: 'Listino',
                    id: 'TotalPrice',
                    value: data.TotalPrice,
                    type: 'numeric',
                },
                {
                    row:4,
                    width: '12.5%',
                    label: 'Sc %',
                    id: 'Discount',
                    value: data.Discount,
                    type: 'numeric',
                },
                {
                    row:4,
                    width: '12.5%',
                    label: 'Extra Sc %',
                    id: 'ExtraDiscount',
                    value: data.ExtraDiscount,
                    type: 'numeric',
                },
                {
                    row:4,
                    width: '12.5%',
                    label: 'Prezzo di vendita',
                    id: 'DiscountedTotalPrice',
                    value: data.DiscountedTotalPrice,
                    type: 'numeric',
                },
            ];
            return <div className={classes.editor}>
                    <RepairRowEditor id={editKey} value={data[editKey]} fields={fields} onSubmit={this.onEditorInsuranceSubmit} />
                </div>;
        }
        else if(showSupplierSelector) {
            return <div className={classes.editor}>
               <IdentitySelector
                containerID="leftPanel"
                onIdentitySelected={this.onSupplierSelect} 
                onCancel={() => { this.setState({ showSupplierSelector: false }, this.props.onEditorClose) }} 
                showIncompleteSelector={false} 
            />
            </div>;
        }
        else if(showSupplierDestinationSelector) {
            return <div className={classes.editor}>
               <IdentitySelector 
                containerID="leftPanel"
                onIdentitySelected={this.onSupplierDestinationSelect} 
                onCancel={() => { this.setState({ showSupplierDestinationSelector: false }, this.props.onEditorClose) }}
                showDestinations={true}
                FIDIdentity={data.FIDRepairSupplier}
                showIncompleteSelector={false} 
            />
            </div>;
        }
        if(isBop){
            if(view === "DOCS"){
                return this.renderProcessRow();
            }
            else if(view === "DEPOSIT") {
                return this.renderDepositRow();
            }
            else {
                return this.renderBop();
            }
        }
        if(isRepair){
            if(data.FIDBOP){
                return this.renderRepairRow();
            }
            else {
                return this.renderNewRow();
            }
        }
        else{
            return this.renderRow();
        }
    }
}



const enhance = compose(
    connect(
        state => state.auth,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
    connect(
        state => state.global,
        dispatch => bindActionCreators(globalCreators, dispatch)
    ),
    connect(
        state => state.cashRegister,
        dispatch => bindActionCreators(cashRegisterCreators, dispatch)
    ),
    withStyles(styles),
    withTheme
);
export default enhance(CashProductRow);