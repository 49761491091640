import React, {useState, useEffect} from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme =>
    createStyles({
        container: {
            width: '50%',
            margin: 'auto',
            display: 'flex',
        },
        costContainer: {
            margin: '8px',
            alignContent: 'center',
            alignItems: 'center',
            border: '1px solid gray',
            borderRadius: '12px',
            padding: '12px',
        },
        relevantWhiteSpaces: {
            whiteSpace: 'pre',
        },
    }));

export default function CostPreview(props){
    const classes = useStyles();
    const [data, setData] = useState({});

    useEffect(() => {
        fetch("/Payments/GetSubscriptionPrices")
        .then(res => res.json())
        .then(result => {console.log(result); setData(result)})
    }, [])

    return (
        <div className={classes.container}>
            {data.products && data.products.map(product => {
                return (<div key={product.name} className={classes.costContainer}>
                    <Typography variant="h6">{product.name}</Typography>
                    <Typography variant="body1">{product.priceDesc}</Typography>
                    <Typography variant="body1">Billed monthly (1<sup>st</sup> of the next month)</Typography>
                </div>)
            })}
        </div>
    )
}